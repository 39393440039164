import { IArrayResponse, DefaultPlaceholdersLabels } from "core/models";
import {
  IDefaultPlaceholders,
  IPlaceholderKey,
} from "modules/Placeholder/models";

export enum MyNetworkTabs {
  "contacts" = "contacts",
  "invitations" = "pending invitations",
  "placeholders" = "placeholders",
}

export enum ContactDetailFilters {
  "email" = "email",
}

export enum ContactDetailMatchFilterLabel {
  "email" = "Email collected",
}

export enum ContactConnectionFilters {
  "connected" = "connected",
  "not_connected" = "not_connected",
}

export enum ContactConnectionMatchFilterLabel {
  "connected" = "Connected",
  "not_connected" = "Not connected",
}

type IPositionItemString = string | null;
type IPositionItemNumber = number | null;
export interface IPosition {
  platform: string;
  company: {
    platform: string;
    name: IPositionItemString;
    website: IPositionItemString;
    domain: IPositionItemString;
    description: IPositionItemString;
    extra_info: {
      locations: [];
      industries: [];
      headquarters: IPositionItemString;
      employee_count: IPositionItemNumber;
      employee_count_range: IPositionItemNumber;
    };
  };
  company_name: IPositionItemString;
  title: IPositionItemString;
  description: IPositionItemString;
  industry: IPositionItemString;
  start?: {
    year: number;
    month: number;
  };
  end?: {
    year: number;
    month: number;
  };
  tenure_at_company?: {
    num_years: number;
    num_months: number;
  };
  tenure_at_position?: {
    num_years: number;
    num_months: number;
  };
}

// Person is each individual LinkedIn account
export interface IPerson {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  address: string;
  image_base64: string;
  created: string;
  geo_region: string;
  location: string;
  public_identifier?: string;
  id_entity_urn?: string;
  occupation: string;
  job_title: string | null;
  company_name: string | null;
  current_position: IPosition | null;
  default_placeholders: IDefaultPlaceholders;
  placeholders: IPersonPlaceholders;
}

export interface IPersons extends IArrayResponse {
  results: IPerson[];
}

export interface ITag {
  id: number;
  name: string;
}

export interface ITags extends IArrayResponse {
  results: ITag[];
}

export interface ITagsArray {
  tags: string[];
}

// Contact is a person relative to an account
export interface IContact extends ITagsArray {
  id: number;
  created: string;
  account: number;
  person: IPerson;
  active_connection: boolean;
}

export interface IContacts extends IArrayResponse {
  results: IContact[];
}

export interface IPersonPlaceholder {
  id: number | null;
  key: IPlaceholderKey;
  label: DefaultPlaceholdersLabels | string;
  value: string;
}

export type IPersonPlaceholders = IPersonPlaceholder[];

export enum PersonAvatarVariants {
  "account" = "account",
  "contact" = "contact",
}
