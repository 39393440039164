import useCreateCampaign from "modules/Campaign/hooks/useCreateCampaign";
import React from "react";
import Loader from "ui-kit/components/Loader";

const StartCampaign = (): React.ReactElement => {
  const { mutate: createCampaign } = useCreateCampaign();

  React.useEffect(() => {
    createCampaign();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Loader minHeight={200} />;
};

export default StartCampaign;
