import AddIcon from "@mui/icons-material/Add";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import DeleteIcon from "@mui/icons-material/Delete";
import PostAddIcon from "@mui/icons-material/PostAdd";
import { Tooltip } from "@mui/material";
import { GlobalContext } from "core/context";
import { GlobalActionType } from "core/reducers";
import RouterConstants from "core/routes/constants";
import { errorHandler, IErrorResponse } from "core/utils/errorHandler";
import { snackbarHandler } from "core/utils/snackbarHandler";
import {
  CampaignDetailTabs,
  CampaignQuery,
  CampaignSequenceVariant,
  CreateCampaignSteps,
} from "modules/Campaign/models";
import { ISequenceMissingAction } from "modules/Sequence/models";
import SequenceService from "modules/Sequence/services";
import React, { useContext } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import Button from "ui-kit/atoms/Button";
import AlphabetBadge from "ui-kit/components/AlphabetBadge";
import PageHeader from "ui-kit/components/PageHeader";

interface CampaignSequenceHeaderProps {
  campaignId: number;
  sequenceId: number | undefined;
  count: number | undefined;
  handleCopyTemplateToggle: () => void;
  handleSaveTemplateToggle: () => void;
  handleCreateNewSequence: () => void;
  selectedVariant: number;
  variant: CampaignSequenceVariant;
  isMissingAction: ISequenceMissingAction;
}

const CampaignSequenceHeader = ({
  campaignId,
  sequenceId,
  count,
  handleCopyTemplateToggle,
  handleSaveTemplateToggle,
  handleCreateNewSequence,
  selectedVariant,
  variant,
  isMissingAction,
}: CampaignSequenceHeaderProps): React.ReactElement | null => {
  const queryClient = useQueryClient();
  const history = useHistory();

  const {
    dispatch,
    global: { selected },
  } = useContext(GlobalContext);

  // Delete sequence
  const mutateDeleteSequence = useMutation(
    (id: number) => SequenceService.deleteSequence(id),
    {
      onSuccess: (_, credentialSequenceId) => {
        queryClient.invalidateQueries(["sequences-all"]);
        queryClient.invalidateQueries([CampaignQuery.sequences, campaignId]);

        // Go back to main sequences
        let link;
        if (variant === CampaignSequenceVariant.draft) {
          link = RouterConstants.CAMPAIGN.newTab(
            campaignId,
            CreateCampaignSteps.steps
          );
        } else {
          link = RouterConstants.CAMPAIGN.detail(
            campaignId,
            CampaignDetailTabs.sequences
          );
        }
        history.push(link);
      },
      onError: (errorMutation: IErrorResponse) => {
        errorHandler(errorMutation.response);
      },
    }
  );

  const handleRemoveSequence = (index: number) => {
    if (selected.length === 0) {
      return snackbarHandler.warning("Please select at least 1 variant.");
    }

    if (
      // eslint-disable-next-line no-alert
      window.confirm(
        `Do you really want to remove selected ${
          selected.length === 1 ? "variant" : "variants"
        }?`
      )
    ) {
      selected.forEach(({ name }) => {
        mutateDeleteSequence.mutate(Number(name));
      });

      // De-select selected
      dispatch({
        type: GlobalActionType.SET_GLOBAL,
        payload: { selected: [] },
      });
    }
  };

  const getTitle = () => {
    return !!sequenceId ? (
      <AlphabetBadge index={selectedVariant} />
    ) : (
      `${count} variants`
    );
  };

  return (
    <PageHeader
      title={getTitle()}
      {...(!sequenceId &&
        !!count &&
        count > 1 && {
          helperTooltip: {
            title: "Prospects will be equally split between multiple variants.",
          },
        })}
      titleProps={{ variant: "h6" }}
      customAction={
        <>
          {/* Only show when sequence selected && the content is empty */}
          {!!sequenceId && isMissingAction && (
            <Tooltip title="Pre-compose from a template">
              <div>
                <Button
                  variant="outlined"
                  color="inherit"
                  size="small"
                  type="button"
                  startIcon={<PostAddIcon />}
                  onClick={handleCopyTemplateToggle}
                >
                  Start from template
                </Button>
              </div>
            </Tooltip>
          )}

          {/* Only show when sequence selected & it's not empty */}
          {!!sequenceId && !isMissingAction && (
            <Tooltip title="Save steps to use in future campaign">
              <div>
                <Button
                  variant="outlined"
                  color="inherit"
                  size="small"
                  type="button"
                  startIcon={<SaveAsIcon />}
                  onClick={handleSaveTemplateToggle}
                >
                  Save as template
                </Button>
              </div>
            </Tooltip>
          )}

          {/* Only allow A/B test if existing action or multiple sequences */}
          {((!!count && count > 1) || !isMissingAction) && (
            <Tooltip title="Add new variant for A/B test">
              <div>
                <Button
                  variant="outlined"
                  color="inherit"
                  size="small"
                  type="button"
                  startIcon={<AddIcon />}
                  onClick={handleCreateNewSequence}
                >
                  Add A/B test
                  {/* Add A/{!!count && SequenceConstants.ALPHABET[count].label} test */}
                </Button>
              </div>
            </Tooltip>
          )}
        </>
      }
      // Only enable removal if there are multiple
      selectedActions={
        !sequenceId && count && count > 1 ? (
          <Button
            variant="outlined"
            color="inherit"
            size="small"
            type="button"
            startIcon={<DeleteIcon />}
            onClick={() => handleRemoveSequence(count)}
          >
            Remove
          </Button>
        ) : undefined
      }
      dividerProps={{
        light: true,
      }}
      // Show only when campaign detail
      // hiddenTitle={!sequenceId}
    />
  );
};

export default CampaignSequenceHeader;
