import React from "react";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import { IWebhookResponse } from "modules/Webhook/models";
import Button from "ui-kit/atoms/Button";
import DialogHeader from "ui-kit/components/DialogHeader";

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    overflowY: "hidden",
  },
  box: {
    overflowY: "scroll",
    maxHeight: "calc(100vh - 400px)",
    ...theme.app.constants.scrollbar,
    margin: theme.spacing(2, 0, 4),
    border: "1px solid #ddd",
    borderLeft: `3px solid ${theme.palette.secondary.dark}`,
  },
  pre: {
    background: "#f4f4f4",
    color: "#666",
    pageBreakInside: "avoid",
    fontFamily: "monospace",
    fontSize: 13,
    lineHeight: 1.6,
    maxWidth: "100%",
    overflow: "auto",
    padding: theme.spacing(2, 4),
    display: "block",
    wordWrap: "break-word",
    margin: 0,
  },
}));

interface TestResponseDialogProps {
  handleClose: () => void;
  data: IWebhookResponse | null;
}

const TestResponseDialog = ({
  handleClose,
  data,
}: TestResponseDialogProps): React.ReactElement => {
  const classes = useStyles();
  return (
    <Dialog
      open={!!data}
      keepMounted
      onClose={handleClose}
      aria-labelledby="webhook-test-response-dialog"
      maxWidth="sm"
      PaperProps={{ sx: { width: "100%" } }}
    >
      <DialogHeader title="Webhook response" onHandleClose={handleClose} />
      <DialogContent dividers className={classes.content}>
        <Typography variant="body2" paragraph>
          Your endpoint receives a similar data as the example below.
        </Typography>
        <div className={classes.box}>
          <pre className={classes.pre}>{JSON.stringify(data, null, 2)}</pre>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          type="button"
          variant="contained"
          color="primary"
          onClick={handleClose}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TestResponseDialog;
