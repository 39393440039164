import React from "react";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import Container from "@mui/material/Container";
import { useLocation } from "react-router-dom";
interface IContentProps {
  children: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    "& > *": {
      display: "flex",
      alignItems: "flex-start",
    },
  },
}));

const Content = ({ children }: IContentProps): React.ReactElement => {
  const classes = useStyles();
  const location = useLocation();
  const prevLocation = React.useRef(location);

  React.useEffect(() => {
    prevLocation.current = location;
  }, [location]);

  React.useEffect(() => {
    if (prevLocation?.current?.pathname !== location.pathname) {
      // Always scroll to top on the page change
      window?.scrollTo(0, 0);
    }
  }, [location]);

  return (
    <div className={classes.root}>
      <Container maxWidth="xl">{children}</Container>
    </div>
  );
};

export default Content;
