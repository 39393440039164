import axios, { AxiosResponse } from "axios";
import {
  ICreateSequenceFormPost,
  ICreateSequenceNew,
  ICreateSequenceCopyFormValue,
  IUpdateSequenceFormValues,
  ISequence,
  ISequences,
} from "../models";

const fetchAllSequences = (): Promise<AxiosResponse<ISequences>> => {
  return axios.get(`/inevitable/sequences/?page=1&page_size=1000`);
};

const fetchSequences = (
  page: number,
  pageSize: number,
  search = ""
): Promise<AxiosResponse<ISequences>> => {
  return axios.get(
    `/inevitable/sequences/?page=${page}&page_size=${pageSize}&search=${search}`
  );
};

const fetchAllTemplateSequences = (): Promise<AxiosResponse<ISequences>> => {
  return axios.get(
    `/inevitable/sequences/?is_template=0&page=1&page_size=1000`
  );
};

const fetchTemplateSequences = (
  page: number,
  pageSize: number,
  search = ""
): Promise<AxiosResponse<ISequences>> => {
  return axios.get(
    `/inevitable/sequences/?is_template=0&page=${page}&page_size=${pageSize}&search=${search}`
  );
};

const fetchSequence = (id: number): Promise<AxiosResponse<ISequence>> => {
  return axios.get(`/inevitable/sequences/${id}/`);
};

const fetchExistingSequences = (): Promise<AxiosResponse<ISequences>> =>
  axios.get(`/inevitable/sequences/?page=1&page_size=1`);

const createSequence = (
  data: ICreateSequenceFormPost
): Promise<AxiosResponse<ISequence>> => {
  return axios.post("/inevitable/sequences/", data);
};

const copySequence = (
  data: ICreateSequenceFormPost
): Promise<AxiosResponse<ISequence>> => {
  return axios.post(`/inevitable/sequences/${data.sequence}/make_copy/`, data);
};

const updateSequenceCopy = (
  data: ICreateSequenceCopyFormValue
): Promise<AxiosResponse<ISequence>> => {
  return axios.post(`/inevitable/sequences/${data.sequence}/make_copy/`, data);
};

const updateSequence = (
  data: IUpdateSequenceFormValues
): Promise<AxiosResponse<ISequence>> => {
  return axios.patch(`/inevitable/sequences/${data.id}/`, data);
};

const deleteSequence = (id: number): Promise<AxiosResponse<ISequence>> => {
  return axios.delete(`/inevitable/sequences/${id}/`);
};

const createBlankSequence = (
  data: ICreateSequenceNew
): Promise<AxiosResponse<ISequence>> => {
  return axios.post("/inevitable/sequences/", data);
};

const SequenceService = {
  fetchAllSequences,
  fetchSequences,
  fetchAllTemplateSequences,
  fetchTemplateSequences,
  fetchSequence,
  fetchExistingSequences,
  createSequence,
  createBlankSequence,
  copySequence,
  updateSequenceCopy,
  updateSequence,
  deleteSequence,
};

export default SequenceService;
