import React from "react";
import { useQuery } from "react-query";
import AutocompleteSingleFilter from "core/filters/atoms/AutocompleteSingleFilter";
import AutocompleteMultipleFilter from "core/filters/atoms/AutocompleteMultipleFilter";
import PersonService from "modules/Person/services";
import FilterButton from "ui-kit/components/FilterButton";

interface TagsFilterProps {
  multiple?: boolean;
}

const TagsFilter = ({
  multiple = false,
}: TagsFilterProps): React.ReactElement => {
  const fetchTags = async () => {
    try {
      const { data } = await PersonService.fetchUniqueTags();
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };

  const { data, isLoading } = useQuery(["tags-all"], () => fetchTags(), {
    keepPreviousData: true,
  });

  const props = {
    id: "tagId",
    options: data?.results,
    isLoading,
    label: "Tags",
  };

  return (
    <FilterButton {...props} data={data}>
      {multiple ? (
        <AutocompleteMultipleFilter {...props} />
      ) : (
        <AutocompleteSingleFilter {...props} />
      )}
    </FilterButton>
  );
};

TagsFilter.defaultProps = {
  multiple: false,
};

export default TagsFilter;
