import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

const usePersonPlaceholderStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
  },
  content: {
    padding: theme.spacing(4, 6),
    overflowY: "scroll",
    ...theme.app.constants.scrollbar,
  },
  label: {
    fontSize: "0.8rem",
    color: theme.app.palette.action.placeholder,
  },
  value: {
    color: theme.palette.text.primary,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "100%",
  },
  placeholder: {
    marginBottom: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1, 0),
    borderRadius: 16,
    border: `1px solid ${theme.app.palette.shadow.secondary}`,
    color: theme.app.palette.action.main,
    fontSize: "0.775rem",
  },
}));

const useProfileStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "calc(100% - 55px)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  header: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1, 5, 3),
    borderBottom: `1px solid ${theme.app.palette.shadow.secondary}`,
  },
  content: {
    overflowY: "scroll",
    height: "100%",
  },
  person: {
    display: "flex",
    flexFlow: "column nowrap",
    width: "calc(100% - 40px)",
  },
  subtitle: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: 13,
    color: theme.app.palette.action.color,
  },
  avatar: {
    marginRight: theme.spacing(2.5),
  },
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  name: {
    fontSize: 17,
    fontWeight: 500,
    color: theme.palette.text.primary,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "100%",
  },
  connection: {
    marginLeft: theme.spacing(1.5),
    fontSize: 13,
    color: theme.app.palette.action.color,
  },
  tabs: {
    padding: theme.spacing(0, 6),
  },
  component: {
    height: "100%",
    overflowY: "hidden",
    marginBottom: theme.spacing(0.25),
  },
}));

export { usePersonPlaceholderStyles, useProfileStyles };
