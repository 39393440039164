import React from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    group: {
      margin: theme.spacing(1),
      width: "20ch",
      height: 54,
      "& .MuiButton-disableElevation.Mui-disabled": {
        border: `1px solid ${theme.app.palette.shadow.secondary}`,
      },
    },
    btnCount: {
      width: 40,
      minWidth: 40,
      overflow: "hidden",
      fontSize: 16,
      borderColor: `${theme.app.palette.shadow.secondary} !important`,
    },
    btnInput: {
      width: "100%",
      backgroundColor: `${theme.palette.common.white} !important`,
      border: `1px solid ${theme.app.palette.shadow.secondary} !important`,
      borderRight: "none !important",
      borderLeft: "none !important",
      fontSize: 16,
      color: `${theme.palette.text.primary} !important`,
      fontWeight: 400,
      padding: 0,
      position: "relative",
      "& label": {
        position: "absolute",
        left: 0,
        top: -1,
        color: "rgba(121, 129, 153, 0.7)",
        transform: "scale(.65)",
      },
    },
    value: {
      padding: "22px 14px 9px",
      lineHeight: 1,
      width: "100%",
    },
  })
);

interface ICounterField {
  value: number;
  label: string;
  handleIncrement: () => void;
  handleDecrement: () => void;
}

const CounterField = ({
  value,
  label,
  handleIncrement,
  handleDecrement,
}: ICounterField): React.ReactElement => {
  const classes = useStyles();
  return (
    <div>
      <ButtonGroup
        className={classes.group}
        disableElevation
        variant="contained"
        color="primary"
        aria-label="outlined primary button group"
      >
        <Button
          className={classes.btnCount}
          type="button"
          onClick={handleIncrement}
        >
          +
        </Button>
        <Button
          className={classes.btnInput}
          type="button"
          value={value}
          disabled
        >
          <label htmlFor={label}>{label}</label>
          <span id={label} className={classes.value}>
            {value}
          </span>
        </Button>

        <Button
          className={classes.btnCount}
          type="button"
          disabled={value === 0}
          onClick={handleDecrement}
        >
          -
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default CounterField;
