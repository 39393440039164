import * as React from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import { makeStyles, createStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import DialogContentText from "@mui/material/DialogContentText";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { errorHandler, IErrorResponse } from "core/utils/errorHandler";
import { snackbarHandler } from "core/utils/snackbarHandler";
import PlaceholderService from "modules/Placeholder/services";
import PlaceholderValidation from "modules/Placeholder/validations";
import {
  IPlaceholderCreateInput,
  IPlaceholderCreateValues,
} from "modules/Placeholder/models";
import TextField from "ui-kit/atoms/TextField";
import Button from "ui-kit/atoms/Button";
import DialogHeader from "ui-kit/components/DialogHeader";
import { AccountContext } from "modules/Account/context";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    label: {
      color: theme.app.palette.action.color,
    },
    dialogInline: {
      position: "absolute",
    },
    limit: {
      fontSize: 11,
      color: theme.app.palette.action.placeholder,
    },
  })
);

interface NewPlaceholderDialogProps {
  disablePortal?: boolean;
  open: boolean;
  personId: number;
  setOpen: (v: boolean) => void;
}

function NewPlaceholderDialog({
  disablePortal = false,
  open,
  personId,
  setOpen,
}: NewPlaceholderDialogProps): React.ReactElement {
  const classes = useStyles();
  const schema = PlaceholderValidation.newPlaceholderSchema();

  const {
    account: { id: currentAccountId },
  } = React.useContext(AccountContext);

  const { register, errors, handleSubmit, setError, reset } = useForm({
    resolver: yupResolver(schema),
  });

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  const queryClient = useQueryClient();
  const mutateNewPlaceholder = useMutation(
    (data: IPlaceholderCreateValues) =>
      PlaceholderService.createPlaceholder(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["contact", personId]);
        queryClient.invalidateQueries(["placeholders"]);
        snackbarHandler.success("Placeholder added!");
        handleClose();
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response, setError);
      },
    }
  );

  const onSubmit = (data: IPlaceholderCreateInput) => {
    mutateNewPlaceholder.mutate({
      value: data.value,
      // Enforce lower-case https://gitlab.scommand.com/snetwork/snetwork_app/-/issues/173
      key_string: data.key.toLowerCase(),
      person: personId,
      account: currentAccountId,
    });
  };

  return (
    <div className={classes.root}>
      <Dialog
        disablePortal={disablePortal}
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        PaperProps={{ sx: { width: "100%" } }}
        keepMounted
        aria-labelledby="new-placeholder-dialog-title"
        {...(disablePortal && { className: classes.dialogInline })}
        {...(disablePortal && {
          BackdropProps: {
            className: classes.dialogInline,
          },
        })}
      >
        <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <DialogHeader title="Add placeholder" onHandleClose={handleClose} />
          <DialogContent dividers>
            <DialogContentText>
              New placeholder adds a new value for this person.
            </DialogContentText>
            <Typography
              variant="body2"
              className={classes.label}
              gutterBottom
              paragraph
            >
              ex. Name - Education, Value - Cambridge
            </Typography>
            <TextField
              id="key"
              fullWidth
              name="key"
              inputRef={register}
              label="Placeholder name"
              variant="outlined"
              error={!!errors.key}
              helperText={errors.key?.message}
            />
            <TextField
              id="value"
              fullWidth
              name="value"
              inputRef={register}
              label="Placeholder value"
              variant="outlined"
              error={!!errors.value}
              helperText={errors.value?.message}
            />
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" type="submit">
              Save placeholder
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
NewPlaceholderDialog.defaultProps = {
  disablePortal: false,
};

export default NewPlaceholderDialog;
