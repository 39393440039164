import React, { createContext, useReducer } from "react";
import { getUser, setToken } from "../utils";
import { IUser } from "../models";
import { UserActions, userReducer } from "../reducers";

interface IProps {
  children: React.ReactElement;
}

interface IUserContext {
  user: IUser;
  dispatch: React.Dispatch<UserActions>;
}

const initialState = {
  user: getUser(),
  dispatch: () => null,
};

if (initialState.user.token) {
  setToken(initialState.user.token);
}

export const UserContext = createContext<IUserContext>(initialState);

const UserContextProvider = (props: IProps): React.ReactElement => {
  const [user, dispatch] = useReducer(userReducer, initialState.user);
  const { children } = props;
  return (
    <UserContext.Provider value={{ user, dispatch }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
