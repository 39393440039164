import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import CompanyValidation from "modules/Company/validations";
import NewCompanyDialog from "../NewCompanyDialog";

interface CompanyDialogContainerProps {
  open: boolean;
  toggleOpen: (open: boolean) => void;
}

const CompanyDialogContainer = ({
  open,
  toggleOpen,
}: CompanyDialogContainerProps): React.ReactElement => {
  const [dialogValue, setDialogValue] = React.useState({
    name: "",
  });

  const schema = CompanyValidation.companyFormSchema("companyName");
  const { register, errors, handleSubmit, setError } = useForm({
    resolver: yupResolver(schema),
  });

  const handleClose = () => {
    toggleOpen(false);
  };

  return (
    <NewCompanyDialog
      open={open}
      dialogValue={dialogValue}
      setDialogValue={setDialogValue}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      register={register}
      errors={errors}
      setError={setError}
    />
  );
};

export default CompanyDialogContainer;
