import axios, { AxiosResponse } from "axios";
import { INote, INotes, INoteFormInput } from "../models";

const createNote = (
  contactId: number,
  data: INoteFormInput
): Promise<AxiosResponse<INote>> =>
  axios.post(`/inevitable/contacts/${contactId}/notes/`, data);

const fetchNotes = (contactId: number): Promise<AxiosResponse<INotes>> =>
  axios.get(`/inevitable/contacts/${contactId}/notes_all/`);

const deleteNote = (id: number): Promise<AxiosResponse<INote>> =>
  axios.delete(`/notes/${id}/`);

const NoteService = {
  createNote,
  fetchNotes,
  deleteNote,
};

export default NoteService;
