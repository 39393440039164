import { Box } from "@mui/material";
import { IPerson } from "modules/Person/models";
import { getContactName } from "modules/Person/utils";
import React from "react";
import BlacklistIcon from "ui-kit/components/BlacklistIcon";
import PersonAvatar from "../PersonAvatar";

interface ExternalPersonProps {
  personIds: Set<number>;
  person: IPerson;
}

const ExternalPerson = ({
  personIds,
  person,
}: ExternalPersonProps): React.ReactElement => {
  const name = getContactName(person);
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 2.5 }}>
      <PersonAvatar name={name} src={person?.image_base64} size="mini" />
      <div>{name}</div>
      <Box
        sx={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          marginLeft: 1.5,
        }}
      >
        {personIds.has(person.id) && <BlacklistIcon />}
      </Box>
    </Box>
  );
};

export default ExternalPerson;
