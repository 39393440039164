import RouterUtils from "core/routes/utils";
import { UserContext } from "modules/User/context";
import { UserActionType } from "modules/User/reducers";
import React from "react";
import { useLocation } from "react-router-dom";

const useSaveUser = () => {
  const location = useLocation();
  const { d: token } = RouterUtils.getQueryParams(location);
  const { user, dispatch } = React.useContext(UserContext);

  React.useEffect(() => {
    if (!!token && user.anonymous) {
      dispatch({
        type: UserActionType.SET_ONLY_TOKEN,
        payload: { token },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, user]);
};

export default useSaveUser;
