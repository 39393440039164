import ProfileDetail from "core/components/ProfileDetail";
import KeywordFilter from "core/filters/atoms/KeywordFilter";
import CampaignFilter from "core/filters/components/CampaignFilter";
import { IProfileClicked } from "core/models";
import { createTableCell } from "core/utils/tableHandler";
import InteractionPlaceholderCell from "modules/Interaction/components/InteractionPlaceholderCell";
import ExternalProfile from "modules/Person/components/ExternalProfile";
import { DefaultPlaceholdersKeys } from "modules/Placeholder/models";
import { ISearchResults } from "modules/Search/models";
import React from "react";
import Table, { TableProps, TableVariant } from "ui-kit/components/Table";
import TableBodyCell from "ui-kit/components/TableBodyCell";
import SearchResultsActions from "../SearchResultsActions";

const pageSize = 20;
const title = "Search results";
const heads = [
  {
    id: "name",
    percentage: true,
    width: 22,
    label: "Name",
  },
  {
    id: "job_title",
    percentage: true,
    width: 25,
    label: "Job title",
  },
  {
    id: "company",
    percentage: true,
    width: 21,
    label: "Company",
  },
  {
    id: "location",
    percentage: true,
    width: 20,
    label: "Location",
  },
  {
    id: "action",
    percentage: true,
    width: 12,
    label: "Actions",
  },
];

interface LinkedInSearchListProps {
  data: ISearchResults | undefined;
  isLoading: TableProps["isLoading"];
  isFetching: TableProps["isFetching"];
  page: TableProps["page"];
  setPage: TableProps["setPage"];
  dataClicked: IProfileClicked | undefined;
  handleClose: () => void;
  handleClicked: (n: IProfileClicked) => void;
  searchId: number;
}

const LinkedInSearchList = ({
  data,
  isLoading,
  isFetching,
  page,
  setPage,
  dataClicked,
  handleClose,
  handleClicked,
  searchId,
}: LinkedInSearchListProps): React.ReactElement | null => {
  return (
    <Table
      title={title}
      heads={heads}
      filters={
        <>
          <KeywordFilter />
          <CampaignFilter multiple />
        </>
      }
      rows={
        data?.results
          ? data.results?.map((searchResult) => {
              const handleOnClick = () => {
                const newDataClicked = searchResult.person
                  ? { personId: searchResult.person.id }
                  : { searchResultId: searchResult.id };
                handleClicked(newDataClicked);
              };

              const cellProps = {
                person: searchResult.person,
                searchResult,
              };

              return {
                name: searchResult?.id.toString(),
                data: [
                  createTableCell(
                    <ExternalProfile {...cellProps} />,
                    handleOnClick,
                    "main",
                    TableBodyCell
                  ),
                  createTableCell(
                    <InteractionPlaceholderCell
                      {...cellProps}
                      placeholderKey={DefaultPlaceholdersKeys.job_title}
                    />,
                    handleOnClick,
                    "default",
                    TableBodyCell
                  ),
                  createTableCell(
                    <InteractionPlaceholderCell
                      {...cellProps}
                      placeholderKey={DefaultPlaceholdersKeys.company_name}
                    />,
                    handleOnClick,
                    "default",
                    TableBodyCell
                  ),
                  createTableCell(
                    <InteractionPlaceholderCell
                      {...cellProps}
                      placeholderKey={DefaultPlaceholdersKeys.location}
                    />,
                    handleOnClick,
                    "default",
                    TableBodyCell
                  ),
                  createTableCell(
                    <SearchResultsActions
                      onHandleEdit={handleOnClick}
                      person={searchResult.person}
                      searchResult={searchResult}
                      searchId={searchId}
                    />,
                    null,
                    "action",
                    TableBodyCell
                  ),
                ],
              };
            })
          : []
      }
      count={data?.count || 0}
      rowsPerPage={pageSize}
      // INITIAL_PAGE starts at 1, but Pagination starts at 0
      page={data?.page || page - 1}
      setPage={setPage}
      isLoading={isLoading}
      isFetching={isFetching}
      variant={[TableVariant.checkable]}
      panel={<ProfileDetail {...dataClicked} onHandleClose={handleClose} />}
    />
  );
};

export default LinkedInSearchList;
