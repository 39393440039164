import React from "react";
import Box from "@mui/material/Box";
import { Theme } from "@mui/material/styles";
import { useTheme } from "@mui/system";

interface ProfileActionProps {
  label: string;
  onClick: () => void;
}

const ProfileAction = ({
  label,
  ...rest
}: ProfileActionProps): React.ReactElement => {
  const theme: Theme = useTheme();
  return (
    <Box
      sx={{
        color: theme.palette.secondary.main,
        mr: 2,
        borderBottom: `1px solid currentColor`,
        "&:hover": {
          cursor: "pointer",
          color: theme.palette.primary.dark,
        },
      }}
      {...rest}
    >
      {label}
    </Box>
  );
};

export default ProfileAction;
