import LinkedIn from "@mui/icons-material/LinkedIn";
import Skeleton from "@mui/material/Skeleton";
import { Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import ProfileCampaignList from "core/components/ProfileCampaignList";
import { generateProfileLink } from "core/utils/profileHandler";
import get from "lodash/get";
import { AccountContext } from "modules/Account/context";
import ContactNotesList from "modules/Note/components/ContactNotesList";
import PersonService from "modules/Person/services";
import { getContactName } from "modules/Person/utils";
import React from "react";
import { useQuery } from "react-query";
import FoldableContent from "ui-kit/components/FoldableContent";
import PersonAvatar from "../PersonAvatar";
import TagsAutocomplete from "../PersonDetail/components/TagsAutocomplete";
import DetailFoldableContent from "./components/DetailFoldableContent";

interface PersonSidebarProps {
  personId: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    padding: theme.spacing(4),
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    borderBottom: `1px solid ${theme.app.palette.shadow.secondary}`,
  },
  content: {
    overflowY: "scroll",
    height: `calc(100% - ${theme.app.constants.personSidebar.header}px)`,
  },
  avatar: {
    marginBottom: theme.spacing(1.5),
  },
  person: {
    display: "flex",
    flexFlow: "column nowrap",
    width: "calc(100% - 40px)",
  },
  subtitle: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: 13,
    color: theme.app.palette.action.color,
  },
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  name: {
    color: theme.palette.text.primary,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    "&:hover": {
      color: theme.app.palette.link,
    },
  },
  inIcon: {
    color: theme.app.palette.link,
    fontSize: 15,
    marginRight: theme.spacing(0.75),
    marginTop: theme.spacing(0.25),
  },
  box: {
    padding: theme.spacing(2, 4),
  },
  contactItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
}));

const PersonSidebar = ({
  personId,
}: PersonSidebarProps): React.ReactElement => {
  const classes = useStyles();

  const { account } = React.useContext(AccountContext);
  const { id: accountId } = account;

  const fetchPerson = async () => {
    try {
      const { data } = await PersonService.fetchPerson(personId);
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };

  const { data: dataPerson } = useQuery(
    ["person", personId],
    () => fetchPerson(),
    {
      keepPreviousData: true,
      enabled: !!personId,
    }
  );

  const fetchContact = async () => {
    try {
      const { data } = await PersonService.fetchContact(accountId, personId);
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };

  const { data: dataContact } = useQuery(
    ["contact", personId, accountId],
    () => fetchContact(),
    {
      keepPreviousData: true,
      enabled: !!personId,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  const contact = get(dataContact, "results.[0]");
  const personName = getContactName(contact ? contact.person : dataPerson);

  return (
    <>
      <div className={classes.header}>
        <div className={classes.avatar}>
          <PersonAvatar
            src={dataPerson?.image_base64}
            size="large"
            name={personName}
          />
        </div>
        <a
          href={generateProfileLink(dataPerson)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Typography variant="body1" className={classes.name}>
            <LinkedIn className={classes.inIcon}></LinkedIn>{" "}
            {personName || <Skeleton width={80} />}
          </Typography>
        </a>
      </div>

      <div className={classes.content}>
        <ProfileCampaignList personId={personId} name={personName} />
        <FoldableContent title="Details">
          <DetailFoldableContent person={dataPerson} personId={personId} />
        </FoldableContent>
        {contact && (
          <FoldableContent title="Tags">
            <div className={classes.box}>
              <TagsAutocomplete contact={contact} />
            </div>
          </FoldableContent>
        )}
        {contact && (
          <FoldableContent title="Notes">
            <ContactNotesList contactId={contact.id} />
          </FoldableContent>
        )}
      </div>
    </>
  );
};

export default PersonSidebar;
