import ProfileDetail from "core/components/ProfileDetail";
import ConfigConstant from "core/constants/ConfigConstant";
import AutocompleteSingleFilter from "core/filters/atoms/AutocompleteSingleFilter";
import KeywordFilter from "core/filters/atoms/KeywordFilter";
import CampaignFilter from "core/filters/components/CampaignFilter";
import SearchFilter from "core/filters/components/SearchFilter";
import TagsFilter from "core/filters/components/TagsFilter";
import { IProfileClicked } from "core/models";
import RouterUtils from "core/routes/utils";
import { createTableCell } from "core/utils/tableHandler";
import { AccountContext } from "modules/Account/context";
import AddressBookService from "modules/AddressBook/services";
import ConnectionChip from "modules/Person/components/ConnectionChip";
import ExternalPerson from "modules/Person/components/ExternalPerson";
import {
  CONTACT_CONNECTION_FILTER_OPTIONS,
  CONTACT_DETAIL_FILTER_OPTIONS,
} from "modules/Person/constants";
import PersonService from "modules/Person/services";
import { DefaultPlaceholdersKeys } from "modules/Placeholder/models";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import FilterButton from "ui-kit/components/FilterButton";
import Table, { TableVariant } from "ui-kit/components/Table";
import TableBodyCell from "ui-kit/components/TableBodyCell";
import ContactPlaceholderCell from "../ContactPlaceholderCell";

const rowsPerPage = ConfigConstant.PAGE_SIZE.MEDIUM;
const title = "Contacts";
const heads = [
  {
    id: "name",
    percentage: true,
    width: 18,
    label: "Name",
  },
  {
    id: "job_title",
    percentage: true,
    width: 23,
    label: "Job title",
  },
  {
    id: "company",
    percentage: true,
    width: 23,
    label: "Company",
  },
  {
    id: "location",
    percentage: true,
    width: 22,
    label: "Location",
  },
  {
    id: "connection",
    percentage: true,
    width: 14,
    label: "Connection",
  },
];

const ContactList = (): React.ReactElement => {
  const [page, setPage] = useState<number>(ConfigConstant.INITIAL_PAGE);
  const [personIds, setPersonIds] = useState<Set<number>>(new Set([]));

  const {
    account: { id: accountId },
  } = React.useContext(AccountContext);

  const [dataClicked, setDataClicked] = React.useState<
    IProfileClicked | undefined
  >(undefined);
  const handleClose = () => {
    setDataClicked(undefined);
  };
  const handleClicked = (newDataClicked: IProfileClicked) => {
    setDataClicked(newDataClicked);
  };

  const location = useLocation();
  const { search } = RouterUtils.getQueryParams(location);
  const { searchId, campaignId, tagId, detail, connection } =
    RouterUtils.parseQueryParams(location);

  const [detailQuery, setDetailQuery] = React.useState(
    RouterUtils.generateFilterOptionQuery(detail, CONTACT_DETAIL_FILTER_OPTIONS)
  );

  React.useEffect(() => {
    const newDetailQuery = RouterUtils.generateFilterOptionQuery(
      detail,
      CONTACT_DETAIL_FILTER_OPTIONS
    );
    setDetailQuery(newDetailQuery);
  }, [detail]);

  const [connectionQuery, setConnectionQuery] = React.useState(
    RouterUtils.generateFilterOptionQuery(
      connection,
      CONTACT_CONNECTION_FILTER_OPTIONS
    )
  );

  React.useEffect(() => {
    const newConnectionQuery = RouterUtils.generateFilterOptionQuery(
      connection,
      CONTACT_CONNECTION_FILTER_OPTIONS
    );
    setConnectionQuery(newConnectionQuery);
  }, [connection]);

  // Fetch all blacklisted personIds
  const fetchBlacklistedPersonIds = async () => {
    try {
      const { data } = await AddressBookService.fetchAddressBookPersonIds(
        accountId
      );
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };
  const { data: dataPersonIds } = useQuery(
    ["blacklisted-persons", accountId],
    () => fetchBlacklistedPersonIds()
  );

  React.useEffect(() => {
    if (dataPersonIds) {
      const newPersonIds = new Set(dataPersonIds.person_ids);
      setPersonIds(newPersonIds);
    }
  }, [dataPersonIds]);

  // Fetch only people relative to the current account
  const fetchContacts = async (p: number) => {
    try {
      const { data } = await PersonService.fetchContacts(
        accountId,
        p,
        rowsPerPage,
        search,
        RouterUtils.generateFilterNameQuery(
          campaignId,
          "interaction__campaign_id__in"
        ),
        RouterUtils.generateFilterNameQuery(
          searchId,
          "interaction__search_id__in"
        ),
        RouterUtils.generateFilterNameQuery(tagId, "tags__in"),
        detailQuery,
        connectionQuery
      );
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };
  const { data, isFetching } = useQuery(
    [
      "contacts",
      page,
      search,
      campaignId,
      searchId,
      tagId,
      detailQuery,
      connectionQuery,
    ],
    () => fetchContacts(page),
    { keepPreviousData: true, refetchOnWindowFocus: false }
  );

  return (
    <>
      <Table
        title={title}
        filters={
          <>
            <KeywordFilter />
            <CampaignFilter multiple />
            <SearchFilter multiple />
            <TagsFilter multiple />
            <FilterButton label="Email" id="detail">
              <AutocompleteSingleFilter
                id="detail"
                options={CONTACT_DETAIL_FILTER_OPTIONS}
                label="Email"
              />
            </FilterButton>
            <FilterButton label="Connection" id="connection">
              <AutocompleteSingleFilter
                id="connection"
                options={CONTACT_CONNECTION_FILTER_OPTIONS}
                label="Connection"
              />
            </FilterButton>
          </>
        }
        heads={heads}
        rows={
          data
            ? data.results?.map(({ id, person, active_connection }) => {
                const handleOnClick = () =>
                  handleClicked({
                    contactId: id,
                    personId: person.id,
                    connection: active_connection,
                  });

                return {
                  name: person.id.toString(),
                  data: [
                    createTableCell(
                      <ExternalPerson person={person} personIds={personIds} />,
                      handleOnClick,
                      "main",
                      TableBodyCell
                    ),
                    createTableCell(
                      <ContactPlaceholderCell
                        person={person}
                        placeholderKey={DefaultPlaceholdersKeys.job_title}
                      />,
                      handleOnClick,
                      "default",
                      TableBodyCell
                    ),
                    createTableCell(
                      <ContactPlaceholderCell
                        person={person}
                        placeholderKey={DefaultPlaceholdersKeys.company_name}
                      />,
                      handleOnClick,
                      "default",
                      TableBodyCell
                    ),
                    createTableCell(
                      <ContactPlaceholderCell
                        person={person}
                        placeholderKey={DefaultPlaceholdersKeys.location}
                      />,
                      handleOnClick,
                      "default",
                      TableBodyCell
                    ),
                    createTableCell(
                      <ConnectionChip
                        personId={person.id}
                        accountId={accountId}
                        active_connection={active_connection}
                      />,
                      handleOnClick,
                      "element",
                      TableBodyCell
                    ),
                  ],
                };
              })
            : []
        }
        count={data?.count || 0}
        // INITIAL_PAGE starts at 1, but Pagination starts at 0
        page={(data?.current || page) - 1}
        rowsPerPage={rowsPerPage}
        setPage={setPage}
        isFetching={isFetching}
        variant={[TableVariant.checkable]}
        panel={<ProfileDetail {...dataClicked} onHandleClose={handleClose} />}
      />
    </>
  );
};

export default ContactList;
