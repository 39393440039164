import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import { IFormProps, IOptionProps } from "core/models";
import {
  formatPlaceholder,
  matchAllPlaceholders,
} from "modules/Placeholder/utils";
import { get } from "lodash";
import SearchUtils from "modules/Search/utils";

const useStyles = makeStyles((theme: Theme) => ({
  item: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  divider: {
    borderBottom: `1px solid ${theme.app.palette.shadow.primary}`,
  },
  input: {
    fontSize: "0.825rem",
    color: theme.app.palette.text.secondary,
    width: "100%",
    "&:focus": {
      backgroundColor: theme.palette.common.white,
    },
    padding: theme.spacing(1, 2),
  },
  inputError: {
    color: theme.palette.error.main,
  },
  inputIgnore: {
    color: theme.app.palette.action.color,
  },
  placeholderInput: {
    fontSize: "0.825rem",
  },
  select: {
    color: theme.app.palette.action.placeholder,
  },
}));

interface SheetFieldSelectProps extends IFormProps {
  columnKey: string;
  options: IOptionProps[];
}

const SheetFieldSelect = ({
  columnKey,
  options,
  control,
  errors,
}: SheetFieldSelectProps): React.ReactElement => {
  const classes = useStyles();
  const name = `column_to_rename.${columnKey}`;

  const { setValue } = useFormContext();

  const [value, setStateValue] = React.useState("");
  const [disableCustom, setDisableCustom] = React.useState(false);

  const updateValue = (newValue: string) => {
    setValue(name, newValue);
    setStateValue(newValue);
  };

  const handleChange = (event: SelectChangeEvent) => {
    const newValue = event.target.value as string;
    updateValue(newValue);
  };

  // Only on the first run
  const firstUpdate = React.useRef(true);
  React.useEffect(() => {
    if (!firstUpdate.current) {
      return;
    }
    const defaultOption = matchAllPlaceholders(
      columnKey,
      options.map((o) => o.label)
    );
    if (defaultOption) {
      updateValue(String(defaultOption));
      setDisableCustom(true);
    } else {
      updateValue(SearchUtils.ignoreKey);
    }

    firstUpdate.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  const error = value ? false : get(errors, name);

  return (
    <FormControl sx={{ m: 0, maxWidth: 300, width: "100%" }}>
      <Controller
        render={() => (
          <Select
            variant="standard"
            inputProps={{
              className: clsx(classes.input, {
                [classes.inputError]: !!error,
                [classes.inputIgnore]: value === SearchUtils.ignoreKey,
              }),
            }}
            id="match-placeholders"
            value={value}
            onChange={handleChange}
            displayEmpty
            className={clsx({ [classes.select]: value === "" })}
            renderValue={(value) => {
              if (value !== "") {
                return formatPlaceholder(value);
              }
              if (error) {
                return error.message;
              }
              return "Select placeholder";
            }}
            error={!!error}
          >
            {!disableCustom && (
              <MenuItem className={classes.item} value={columnKey}>
                {formatPlaceholder(columnKey)}
              </MenuItem>
            )}
            <Divider />
            {options.map((option, index) => {
              return (
                <MenuItem
                  key={option.id}
                  value={option.label}
                  className={classes.item}
                >
                  {option.id}
                </MenuItem>
              );
            })}
            <Divider />
            <MenuItem className={classes.item} value={SearchUtils.ignoreKey}>
              {SearchUtils.ignoreKey}
            </MenuItem>
          </Select>
        )}
        name={name}
        control={control}
      />
    </FormControl>
  );
};

export default SheetFieldSelect;
