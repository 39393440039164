import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import RouterConstants from "core/routes/constants";
import { errorHandler, IErrorResponse } from "core/utils/errorHandler";
import { AccountContext } from "modules/Account/context";
import { SHORT_NEW_SEARCH_TABS } from "modules/Search/constants";
import { emptySearch, SearchContext } from "modules/Search/context";
import {
  ISearch,
  ISearchFormInput,
  ISearchFormValues,
  ISearchSheetResponse,
  SearchStrategy,
  SearchTabIndex,
} from "modules/Search/models";
import { SearchActionType } from "modules/Search/reducers";
import SearchService from "modules/Search/services";
import SearchUtils from "modules/Search/utils";
import SearchValidations from "modules/Search/validations";
import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import DialogHeader from "ui-kit/components/DialogHeader";
import SearchItem from "../SearchItem";

interface CreateSearchProps {
  open: boolean;
  toggleOpen: (open: boolean) => void;
}

const initialValues = { searchName: "", url: "" };

const CreateSearch = ({
  open,
  toggleOpen,
}: CreateSearchProps): React.ReactElement => {
  const classes = SearchUtils.useCreateSearchStyles();
  const [selectedTab, setSelectedTab] = React.useState(0);
  const history = useHistory();
  const queryClient = useQueryClient();

  const [defaultValues, setDefaultValues] = React.useState(initialValues);

  React.useEffect(() => {
    setDefaultValues(initialValues);
  }, [selectedTab]);

  const {
    account: { id: accountId },
  } = React.useContext(AccountContext);

  const { dispatch, search } = React.useContext(SearchContext);
  const { columns } = search;

  const [isLoading, setLoading] = React.useState(false);
  const submitButtonRef = React.useRef<HTMLButtonElement | null>(null);

  const handleLoading = (bool: boolean) => {
    setLoading(bool);
    bool
      ? submitButtonRef.current?.setAttribute("disabled", "true")
      : submitButtonRef.current?.removeAttribute("disabled");
  };

  const handleTabClean = () => {
    dispatch({
      type: SearchActionType.SET_COLUMNS,
      payload: emptySearch,
    });
  };

  const handleClose = () => {
    toggleOpen(false);
    history.push(RouterConstants.SEARCH.ALL);

    // Stop loading
    handleLoading(false);

    handleTabClean();
  };

  const mutateAddGoogleSheet = useMutation(
    (data: ISearchFormValues) => SearchService.createSearch(data),
    {
      onSuccess: (response) => {
        handleSubmit(response.data?.id);
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);
      },
      onSettled: () => {
        // Stop loading
        handleLoading(false);
      },
    }
  );

  const handleSubmit = (id: number) => {
    queryClient.invalidateQueries(["searches"]);
    toggleOpen(false);
    history.push(RouterConstants.SEARCH.detail(id));

    dispatch({
      type: SearchActionType.SET_COLUMNS,
      payload: emptySearch,
    });
  };

  const loadingProps = {
    isLoading,
    setLoading,
    submitButtonRef,
    handleLoading,
  };

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleClose}
      aria-labelledby="new-search-dialog"
      classes={{ paper: classes.paper }}
    >
      <DialogHeader
        title="New search"
        helperProps={{ link: RouterConstants.DOCS.SEARCH }}
        onHandleClose={handleClose}
      />
      <DialogContent dividers sx={{ p: 0 }}>
        {SearchUtils.LINKEDIN_SEARCH_VARIANTS.includes(selectedTab) && (
          <SearchItem
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            setDefaultValues={setDefaultValues}
            tabs={SHORT_NEW_SEARCH_TABS}
            FormProps={{
              func: SearchService.createSearch,
              format: ({ searchName: name, url }: ISearchFormInput) => {
                return {
                  name,
                  strategy_data: {
                    url,
                  },
                  strategy: SearchUtils.getLinkedSearchStrategy(url),
                  account: accountId,
                };
              },
              onSuccess: (d: ISearch) => {
                handleSubmit(d.id);
              },
              defaultValues,
              schema: SearchValidations.createSearchSchema,
              ...loadingProps,
              handleTabClean,
            }}
            SubmitButtonProps={{
              value: "Save search",
            }}
          />
        )}
        {selectedTab === SearchTabIndex.google_sheet && (
          <SearchItem
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            setDefaultValues={setDefaultValues}
            tabs={SHORT_NEW_SEARCH_TABS}
            FormProps={{
              func: columns
                ? SearchService.createSearch
                : SearchService.fetchSheetFields,
              format: (d: ISearchFormInput) => {
                dispatch({
                  type: SearchActionType.SET_COLUMNS,
                  payload: columns ? { ...search } : { ...emptySearch },
                });

                return SearchUtils.formatGoogleSheetData(
                  d,
                  search,
                  accountId,
                  undefined
                );
              },
              onError: () => {
                dispatch({
                  type: SearchActionType.SET_COLUMNS,
                  payload: emptySearch,
                });
              },
              onSuccess: (
                d: ISearchSheetResponse,
                credentials: ISearchFormValues
              ) => {
                const newData = {
                  columns: d.columns,
                  searchName: credentials.name,
                  url: credentials.strategy_data.url,
                };

                dispatch({
                  type: SearchActionType.SET_COLUMNS,
                  payload: columns ? emptySearch : newData,
                });

                if (columns) {
                  handleSubmit(d.id);
                } else {
                  // If only profile_url present, no need to match columns
                  if (newData.columns?.length === 0) {
                    mutateAddGoogleSheet.mutate({
                      name: newData.searchName,
                      account: accountId,
                      strategy:
                        SearchStrategy[
                          "inevitable.strategy.search.GoogleSheetsStrategy"
                        ],
                      strategy_data: {
                        url: newData.url,
                      },
                    });
                  } else {
                    // Stop loading
                    handleLoading(false);
                  }
                }
              },
              schema: columns
                ? SearchValidations.matchPlaceholderSchema(columns)
                : SearchValidations.createSearchSchema,
              defaultValues,
              ...(!columns && { schema: SearchValidations.createSearchSchema }),
              ...loadingProps,
              handleTabClean,
            }}
            SubmitButtonProps={{
              value: columns ? "Save search" : "Next: Match placeholders",
              endIcon: <ArrowForwardIcon />,
            }}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default CreateSearch;
