import { Box, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useRef, useState } from "react";

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    fontSize: "2.25rem",
    padding: theme.spacing(2.5, 1.5),
    textAlign: "center",
    borderRadius: 4,
    border: `1px solid ${theme.app.palette.action.check}`,
    width: "calc((100% / 6) - 10px)",
  },
}));

interface PinInputProps {
  value: string;
  onChange: (v: string) => void;
  name: string;
}

const PinInput = React.forwardRef<HTMLDivElement, PinInputProps>(
  function PinInput({ value, onChange }: PinInputProps, ref) {
    const classes = useStyles();
    const [digits, setDigits] = useState<string[]>(["", "", "", "", "", ""]);
    const inputsRef = useRef<(HTMLInputElement | null)[]>([]);

    useEffect(() => {
      inputsRef.current = inputsRef.current.slice(0, 6); // Make sure we only have 6 inputs in the array
    }, []);

    // Focus on the first input
    useEffect(() => {
      inputsRef?.current?.[0]?.focus();
    }, []);

    // Update react-hook-form
    useEffect(() => {
      const newDigits = digits.join("")?.padStart(6, "0");
      if (newDigits !== value) {
        onChange(newDigits);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [digits]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const inputIndex = Number(event.target.name);
      const inputValue = event.target.value;

      // Make sure the input value is a digit
      if (/^\d$/.test(inputValue)) {
        setDigits((prevDigits) => {
          const newDigits = [...prevDigits];
          newDigits[inputIndex] = inputValue;
          return newDigits;
        });

        // Move focus to the next input box
        if (inputIndex < 5 && inputValue !== "") {
          const nextInput = inputsRef.current[inputIndex + 1];
          nextInput?.focus();
        }
      } else if (inputValue === "") {
        // Move focus to the previous input box if the current input is empty
        if (inputIndex > 0) {
          const prevInput = inputsRef.current[inputIndex - 1];
          prevInput?.focus();
        }
      }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      const inputIndex = Number((event.target as HTMLInputElement).name);

      if (event.key === "Backspace" && digits[inputIndex] !== "") {
        event.preventDefault(); // Prevent default Backspace behavior

        // Remove the digit from the current input box
        setDigits((prevDigits) => {
          const newDigits = [...prevDigits];
          newDigits[inputIndex] = "";
          return newDigits;
        });

        // Move focus to the previous input box
        if (inputIndex > 0) {
          const prevInput = inputsRef.current[inputIndex - 1];
          prevInput?.focus();
        }
      }
    };

    const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
      const clipboardData =
        event.clipboardData || (window as any).clipboardData;
      const pastedText = clipboardData
        .getData("text")
        .replace(/[^\d]/g, "")
        .slice(0, 6); // Keep only digits and limit to 6
      const newDigits = Array.from(pastedText.padEnd(6, " ").slice(0, 6)); // Convert pasted text to an array of 6 characters, padded with spaces

      setDigits(newDigits);

      inputsRef.current.forEach((input) => input?.blur()); // Remove focus from all input boxes after pasting
    };

    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: 2,
        }}
      >
        {digits.map((digit, index) => (
          <input
            key={index}
            type="text"
            name={String(index)}
            maxLength={1}
            value={digit}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            onPaste={handlePaste}
            ref={(input) => (inputsRef.current[index] = input)}
            className={classes.input}
          />
        ))}
      </Box>
    );
  }
);

export default PinInput;
