import React from "react";
import { styled } from "@mui/material/styles";
import MuiSlider, {
  SliderValueLabel,
  SliderMarkLabel,
  SliderProps,
} from "@mui/material/Slider";
import { get } from "lodash";

interface ValueLabelProps {
  index: number;
  ownerState: {
    min: number;
    max: number;
    value: [number, number];
  };
}

const getValue = (props: ValueLabelProps) => {
  const value = get(props, "ownerState.value");
  if (value) {
    return {
      start: value[0],
      end: value[1],
    };
  }
  return {
    start: 0,
    end: 0,
  };
};

const getShowCombined = (props: ValueLabelProps): boolean => {
  const value = get(props, "ownerState.value");
  if (value) {
    const { min, max } = props.ownerState;
    if (max - min < 10) {
      return false;
    }
    const [start, end] = value;
    if (start === end) {
      return false;
    }
    return end - start < 7;
  }
  return false;
};

const wrapString = (str: string) => {
  const raw = str.match(/^['"].*?['"]$/) ? JSON.parse(str) : str;
  return JSON.stringify(raw);
};

const StyledValueLabel = styled(SliderValueLabel)<ValueLabelProps>((props) => {
  const showCombined = getShowCombined(props);
  const value = getValue(props);
  return {
    ...(!!showCombined && {
      left: props.index === 0 ? `calc(-50% - 3px)` : "",
    }),
    opacity: showCombined && props.index === 1 ? 0 : 1,
    "& .MuiSlider-valueLabelCircle": {
      "&::after": {
        ...(!!showCombined && {
          content: props.index === 0 ? wrapString(` - ${value.end}`) : '"',
        }),
      },
    },
  };
});

interface MarkLabelProps {
  children: number;
  "data-index": number;
  ownerState: {
    min: number;
    max: number;
    value: [number, number];
  };
}

const getHideMark = (props: MarkLabelProps): boolean => {
  const value = get(props, "ownerState.value");
  if (value) {
    const { min, max } = props.ownerState;
    if (max - min < 10) {
      return false;
    }
    const [start, end] = value;
    const relevantValue = props["data-index"] === 0 ? start : end;
    const mark = props.children;
    return Math.abs(relevantValue - mark) < 6;
  }
  return false;
};

const StyledMarkLabel = styled(SliderMarkLabel)<MarkLabelProps>((props) => {
  return {
    opacity: getHideMark(props) ? 0 : 1,
  };
});

interface RangeSliderProps extends SliderProps {
  maxDistance: number; // Maximum distance between two thumbs
}

function RangeSlider(props: RangeSliderProps): React.ReactElement {
  const triggerUpdate = (event: any, finalValue: [number, number]) => {
    if (props.onChange) {
      props.onChange(event, finalValue, 0);
    }
  };

  const { maxDistance } = props;

  const handleChange = (event: any, newValue: any, activeThumb: number) => {
    let updatedValue = newValue;

    // If difference between handles less than coefficient
    // Update without custom code
    if (newValue[1] - newValue[0] < maxDistance) {
      return triggerUpdate(event, updatedValue);
    }

    // If difference higher than maximum distance, drag right-handle
    if (activeThumb === 0) {
      if (newValue[0] + maxDistance <= newValue[1]) {
        updatedValue = [newValue[0], newValue[0] + maxDistance];
      }
    }

    // If difference higher than maximum distance, drag left-handle
    if (activeThumb === 1) {
      if (newValue[0] <= newValue[1] - maxDistance) {
        updatedValue = [newValue[1] - maxDistance, newValue[1]];
      }
    }

    triggerUpdate(event, updatedValue);
  };

  return (
    <MuiSlider
      {...props}
      valueLabelDisplay="on"
      onChange={handleChange}
      components={{
        ValueLabel: StyledValueLabel,
        MarkLabel: StyledMarkLabel,
      }}
      aria-labelledby="range-slider"
    />
  );
}

export default RangeSlider;
