import { Alert, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import clsx from "clsx";
import ElementField from "core/components/ElementField";
import SliderField from "core/components/SliderField";
import SwitchField from "core/components/SwitchField";
import React from "react";
import { Control, FieldError, useWatch } from "react-hook-form";
import HelperTypography from "ui-kit/components/HelperTypography";
import PaperHeader from "ui-kit/components/PaperHeader";
import RangeSlider from "ui-kit/components/RangeSlider";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hidden: {
      display: "none",
    },
    input: {
      display: "block",
      maxWidth: 120,
    },
    switch: {
      marginBottom: theme.spacing(4),
    },
  })
);

interface IControllerProps {
  control: Control;
  error: FieldError | undefined;
  name: string;
  label: string | [string, string];
}

const WarmUpField = ({
  control,
  error,
  name,
  label,
}: IControllerProps): React.ReactElement => {
  const classes = useStyles();

  const warmUpInteractions: [number, number] | undefined = useWatch({
    name: "restrictions.warm_up.interactions_range",
    control,
  });
  const warmUpIncreaseRate: [number, number] | undefined = useWatch({
    name: "restrictions.warm_up.increase_rate_range",
    control,
  });

  return (
    <>
      <PaperHeader
        title={
          <ElementField
            name={name}
            component={SwitchField}
            control={control}
            label={label}
            ElementProps={{ header: true, positionRight: true }}
          />
        }
        body="Steadily increase the number of connections requests send out each work day."
      />
      <div
        className={clsx({
          [classes.hidden]: !useWatch({
            name: "restrictions.warm_up.enabled",
            control,
          }),
        })}
      >
        {!!warmUpInteractions && !!warmUpIncreaseRate && (
          <Alert
            variant="standard"
            icon={false}
            severity="info"
            sx={{ mb: 4, width: "fit-content" }}
          >
            <Typography variant="body2" paragraph>
              Send <b>{warmUpInteractions[0]} invitations</b> on the first day
              and increase it by <b>{warmUpIncreaseRate.join("-")}</b> each work
              day.
            </Typography>
            <Typography variant="body2">
              When you reach <b>{warmUpInteractions[1]} invitations</b> per day,
              warm-up automatically turns off.
            </Typography>
          </Alert>
        )}
        <ElementField
          component={SliderField}
          name="restrictions.warm_up.interactions_range"
          control={control}
          error={error}
          label={
            <HelperTypography
              text="Start and End limit"
              typographyProps={{ variant: "subtitle2", color: "textPrimary" }}
              helperProps={{
                title:
                  "Warmup starts sending connection requests based on your Start limit and deactivates automatically when your account reaches the End limit per day.",
              }}
            />
          }
          ElementProps={{
            min: 1,
            max: 100,
            marks: [1, 100],
            maxDistance: 30,
            component: RangeSlider,
          }}
        />
        <ElementField
          component={SliderField}
          name="restrictions.warm_up.increase_rate_range"
          control={control}
          error={error}
          label={
            <HelperTypography
              text="Increase rate per day"
              typographyProps={{ variant: "subtitle2", color: "textPrimary" }}
              helperProps={{
                title:
                  "Warmup increases by a random number selected from the range each work day.",
              }}
            />
          }
          ElementProps={{
            min: 1,
            max: 5,
            marks: [1, 5],
            maxDistance: 10,
            component: RangeSlider,
          }}
        />
      </div>
    </>
  );
};

export default WarmUpField;
