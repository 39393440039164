import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Badge from "@mui/material/Badge";
import { Link, useHistory } from "react-router-dom";
import { errorHandler, IErrorResponse } from "core/utils/errorHandler";
import RouterConstants from "core/routes/constants";
import RouterUtils from "core/routes/utils";
import { formatTimeAgo } from "core/utils/dateHandler";
import { AccountContext } from "modules/Account/context";
import { INotification } from "modules/Notification/models";
import { handleNotificationClick } from "modules/Notification/utils";
import NotificationService from "modules/Notification/services";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    width: 360,
    border: `1px solid ${theme.app.palette.shadow.secondary}`,
    boxShadow: theme.shadows[6],
  },
  list: {
    padding: 0,
  },
  item: {
    padding: theme.spacing(3, 3, 3.5, 4.5),
    borderRadius: 0,
    whiteSpace: "normal",
  },
  mainText: {
    fontSize: 13,
  },
  subText: {
    fontSize: 11,
    color: theme.app.palette.action.color,
  },
  targetText: {
    backgroundColor: theme.app.palette.shadow.primary,
    color: theme.app.palette.action.main,
    padding: theme.spacing(0.25, 1.25),
    borderRadius: 4,
    fontSize: 11,
    fontWeight: 500,
  },
  emptyText: {
    padding: theme.spacing(6, 4),
    fontSize: 13,
  },
  header: {
    padding: theme.spacing(4),
    borderBottom: `1px solid ${theme.app.palette.shadow.primary}`,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  content: {
    backgroundColor: theme.app.palette.background.light,
  },
  footer: {
    padding: theme.spacing(2, 3),
    textAlign: "center",
    borderTop: `1px solid ${theme.app.palette.shadow.primary}`,
  },
  badge: {
    marginRight: theme.spacing(3),
  },
  mark: {
    fontWeight: 500,
    color: theme.app.palette.action.placeholder,
    "&:hover": {
      cursor: "pointer",
      color: theme.app.palette.action.color,
    },
  },
}));

interface NotificationMenuProps {
  data: INotification[];
  anchorEl: null | HTMLElement;
  handleClose: () => void;
}

const NotificationMenu = ({
  data,
  anchorEl,
  handleClose,
}: NotificationMenuProps): React.ReactElement => {
  const classes = useStyles();
  const history = useHistory();
  const {
    dispatch,
    account: { id: currentAccountId },
  } = React.useContext(AccountContext);
  const queryClient = useQueryClient();

  const { accountId } = RouterUtils.parseQueryParams(history.location);
  const accountQuery = RouterUtils.generateFilterNameQuery(
    accountId,
    "actor_object_id"
  );

  // Mark all notification as read (unread: false)
  const mutateMarkAllAsRead = useMutation(
    () => NotificationService.updateAllNotificationsMarkAsRead(accountQuery),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["notifications"]);
        queryClient.invalidateQueries(["notifications-recent"]);
        queryClient.invalidateQueries(["notifications-unread-all"]);
        handleClose();
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);
      },
    }
  );

  // Mark notification as read (unread: false)
  const mutateOnClick = useMutation(
    (newData: INotification) =>
      NotificationService.updateNotification({ ...newData, unread: false }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["notifications"]);
        queryClient.invalidateQueries(["notifications-recent"]);
        queryClient.invalidateQueries(["notifications-unread-all"]);
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);
      },
    }
  );

  const handleMarkAllAsRead = () => mutateMarkAllAsRead.mutate();

  const notificationMenuId = "notification-menu";
  return (
    <Menu
      anchorEl={anchorEl}
      id={notificationMenuId}
      keepMounted
      autoFocus={false}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      open={!!anchorEl}
      onClose={handleClose}
      PaperProps={{
        elevation: 12,
        className: classes.paper,
      }}
      MenuListProps={{
        className: classes.list,
      }}
    >
      <div className={classes.header}>
        <Typography variant="subtitle2">Notifications</Typography>
        <Typography
          className={classes.mark}
          variant="caption"
          onClick={handleMarkAllAsRead}
        >
          Mark all as read
        </Typography>
      </div>
      <div className={classes.content}>
        {!data.length && (
          <Typography
            variant="body2"
            component="p"
            color="textSecondary"
            className={classes.emptyText}
          >
            You have no unread notifications.
          </Typography>
        )}
        {data.map((notification: INotification, index) => {
          const handleOnClick = () => {
            mutateOnClick.mutate(notification);
            handleNotificationClick(
              notification,
              history,
              dispatch,
              currentAccountId
            );
          };
          const divider = index < data.length - 1;
          return (
            <MenuItem
              key={notification.id}
              className={classes.item}
              onClick={() => {
                handleOnClick();
                handleClose();
              }}
              divider={divider}
            >
              <Badge
                className={classes.badge}
                color="secondary"
                variant="dot"
              />
              <div>
                <Typography variant="caption" className={classes.subText}>
                  {formatTimeAgo(notification.timestamp)}
                </Typography>
                <Typography
                  variant="body2"
                  component="p"
                  color="textPrimary"
                  className={classes.mainText}
                  gutterBottom
                >
                  {notification.description}
                </Typography>
              </div>
            </MenuItem>
          );
        })}
      </div>
      <Link
        to={{
          pathname: RouterConstants.NOTIFICATION.ALL,
          search: `?accountId=${currentAccountId}`,
        }}
        onClick={handleClose}
      >
        <Typography
          component="div"
          className={classes.footer}
          variant="caption"
          style={{ fontWeight: 500 }}
          color="inherit"
        >
          View all
        </Typography>
      </Link>
    </Menu>
  );
};

export default NotificationMenu;
