import React from "react";
import { useQuery } from "react-query";
import { useParams, useHistory } from "react-router-dom";
import RouterConstants from "core/routes/constants";
import InteractionService from "modules/Interaction/services";
import Loader from "ui-kit/components/Loader";

type ParamTypes = {
  interactionId: string;
};

const InteractionRedirect = (): React.ReactElement => {
  const history = useHistory();
  const params = useParams<ParamTypes>();
  const interactionId: number = +params.interactionId;

  const fetchInteraction = async () => {
    try {
      const { data } = await InteractionService.fetchInteraction(interactionId);
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };
  const { data } = useQuery(["interaction", interactionId], () =>
    fetchInteraction()
  );

  React.useEffect(() => {
    if (interactionId && data?.person) {
      history.push(RouterConstants.INBOX.detail(data.person.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interactionId, data?.person?.id]);

  return <Loader />;
};

export default InteractionRedirect;
