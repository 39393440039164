import React from "react";
import { useQueryClient } from "react-query";
import { createComponents } from "core/utils/componentsHandler";
import SettingsItem from "core/components/SettingsItem";
import {
  formatNumberToTimeDeltaDays,
  formatTimeDeltaDaysToNumber,
} from "core/utils/dateHandler";
import AccountService from "modules/Account/services";
import {
  AccountQuery,
  IAccount,
  IAccountDetailItemProps,
} from "modules/Account/models";
import PendingInvitationsField from "modules/Account/components/PendingInvitationsField";

interface IFormInput {
  withdraw_time_delta: number;
}

const ActionSettings = ({
  accountId,
}: IAccountDetailItemProps): React.ReactElement | null => {
  const queryClient = useQueryClient();
  const [account, setAccount] = React.useState<IAccount | undefined>(undefined);
  const data: IAccount | undefined = queryClient.getQueryData([
    AccountQuery.account,
    accountId,
  ]);
  React.useEffect(() => {
    if (data) {
      setAccount(data);
    }
  }, [data]);

  if (!account) return null;

  const defaultValues = {
    withdraw_time_delta: account.withdraw_time_delta
      ? formatTimeDeltaDaysToNumber(account.withdraw_time_delta)
      : null,
    withdraw_time_delta_enabled: !!account.withdraw_time_delta,
  };

  return (
    <>
      <SettingsItem
        FormProps={{
          id: accountId,
          name: [AccountQuery.account, accountId],
          func: AccountService.updateAccount,
          format: (d: IFormInput) => ({
            withdraw_time_delta: formatNumberToTimeDeltaDays(
              d.withdraw_time_delta
            ),
          }),
          defaultValues,
        }}
        components={[
          createComponents(
            PendingInvitationsField,
            "withdraw_time_delta_enabled",
            {
              label: "Auto-withdraw pending invitations",
            }
          ),
        ]}
        // Last item in the list
        hiddenDivider
      />
    </>
  );
};

export default ActionSettings;
