import { Theme, useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/styles";
import ConfigConstant from "core/constants/ConfigConstant";
import { BacklinkButtonVariant, IFormProps } from "core/models";
import { createTableCell } from "core/utils/tableHandler";
import {
  decorateStaticPlaceholder,
  DEFAULT_PLACEHOLDERS_ARR,
} from "modules/Placeholder/utils";
import { emptySearch, SearchContext } from "modules/Search/context";
import { SearchActionType } from "modules/Search/reducers";
import React from "react";
import BacklinkButton from "ui-kit/components/BacklinkButton";
import Loader from "ui-kit/components/Loader";
import Table, { TableVariant } from "ui-kit/components/Table";
import TableBodyCell from "ui-kit/components/TableBodyCell";
import SheetFieldSelect from "../SearchItem/components/SheetFieldSelect";

const heads = [
  {
    id: "key",
    percentage: true,
    width: 50,
    label: "Your columns",
  },
  {
    id: "placeholder",
    percentage: true,
    width: 50,
    label: "Match placeholder",
  },
];

export interface MatchSheetFieldsProps extends IFormProps {
  isLoading: boolean | undefined;
}

const MatchSheetFields = ({
  control,
  errors,
  isLoading,
}: MatchSheetFieldsProps): React.ReactElement => {
  const theme = useTheme();
  const [page, setPage] = React.useState<number>(ConfigConstant.INITIAL_PAGE);

  const {
    dispatch,
    search: { columns },
  } = React.useContext(SearchContext);

  const handleOnBackButton = () => {
    dispatch({
      type: SearchActionType.SET_COLUMNS,
      payload: emptySearch,
    });
  };

  const options = DEFAULT_PLACEHOLDERS_ARR.map(({ id, name }) => ({
    id: String(id || name),
    label: decorateStaticPlaceholder(name),
  }));

  const desktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));

  return (
    <>
      {isLoading && <Loader minHeight={200} position="absolute" />}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mb: 4,
          [theme.breakpoints.down("md")]: {
            flexDirection: "column",
          },
        }}
      >
        <Typography variant="subtitle1" gutterBottom>
          Match column names to placeholders
        </Typography>
        <BacklinkButton
          variant={BacklinkButtonVariant.back}
          text="Change Google Sheet"
          marginPosition={desktop ? "left" : "bottom"}
          onClick={handleOnBackButton}
        />
      </Box>
      {columns?.length ? (
        <Table
          tableProps={{ variant: "outlined" }}
          count={columns?.length}
          disableToolbar
          disableEmptyRows
          disableRowHover
          disablePagination
          definedInfiniteHeight="calc(100vh - 420px)"
          variant={[TableVariant.infinite]}
          page={page - 1}
          setPage={setPage}
          isFetching={false}
          heads={heads}
          rows={
            columns
              ? columns.map((column) => ({
                  name: column,
                  data: [
                    createTableCell(column, null, "main", TableBodyCell),
                    createTableCell(
                      <SheetFieldSelect
                        columnKey={column}
                        options={options}
                        control={control}
                        errors={errors}
                      />,
                      null,
                      "default",
                      TableBodyCell
                    ),
                  ],
                }))
              : []
          }
        />
      ) : (
        <Typography variant="body2" sx={{ mt: 6, mb: 2 }}>
          Creating your search, just few seconds...
        </Typography>
      )}
    </>
  );
};

export default MatchSheetFields;
