import * as Yup from "yup";

export const ERROR_KEYS = {
  EMAIL: {
    REQUIRED: "Email address is required.",
    INVALID: "Email address is invalid.",
  },
  PASSWORD: {
    REQUIRED: "Password is required.",
    CONFIRM: "Passwords must match",
  },
  COMPANY: {
    REQUIRED: "Company is required.",
  },
  COUNTRY: {
    REQUIRED: "Country is required.",
  },
  TIME_ZONE: {
    REQUIRED: "Time zone is required.",
  },
  INACTIVE_DAYS: {
    START_DATE: {
      REQUIRED: "Beginning of inactive days is required.",
    },
    END_DATE: {
      REQUIRED: "End of inactive days is required.",
    },
  },
};

const company = Yup.object()
  .shape({
    id: Yup.string(),
    name: Yup.string(),
  })
  .nullable()
  .required(ERROR_KEYS.COMPANY.REQUIRED);

function accountFormSchema(activeAccount: boolean): Yup.AnyObjectSchema {
  return Yup.object().shape({
    ...(!activeAccount && {
      login: Yup.string()
        .required(ERROR_KEYS.EMAIL.REQUIRED)
        .email(ERROR_KEYS.EMAIL.INVALID),
    }),
    ...(!activeAccount && {
      password: Yup.string().required(ERROR_KEYS.PASSWORD.REQUIRED),
    }),
  });
}

function countryFormSchema(): Yup.AnyObjectSchema {
  return Yup.object().shape({
    country: Yup.object()
      .shape({
        id: Yup.string().required(ERROR_KEYS.COUNTRY.REQUIRED),
        name: Yup.string().required(ERROR_KEYS.COUNTRY.REQUIRED),
      })
      .required(ERROR_KEYS.COUNTRY.REQUIRED),
  });
}

function updateCompanySchema(): Yup.AnyObjectSchema {
  return Yup.object().shape({
    company,
  });
}

function updatePassword(): Yup.AnyObjectSchema {
  return Yup.object().shape({
    password: Yup.string().required(ERROR_KEYS.PASSWORD.REQUIRED),
    password_confirm: Yup.string().test(
      "passwords-match",
      ERROR_KEYS.PASSWORD.CONFIRM,
      function test(value) {
        return this.parent.password === value;
      }
    ),
  });
}

function inactiveDaysSchema(): Yup.AnyObjectSchema {
  return Yup.object().shape({
    start_date: Yup.string()
      .required(ERROR_KEYS.INACTIVE_DAYS.START_DATE.REQUIRED)
      .nullable(),
    end_date: Yup.string()
      .required(ERROR_KEYS.INACTIVE_DAYS.END_DATE.REQUIRED)
      .nullable(),
  });
}

const AccountValidations = {
  accountFormSchema,
  countryFormSchema,
  updateCompanySchema,
  inactiveDaysSchema,
  updatePassword,
};

export default AccountValidations;
