import React from "react";
import Box from "@mui/material/Box";
import Button from "ui-kit/atoms/Button";
interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}

const TablePaginationActions = (
  props: TablePaginationActionsProps
): React.ReactElement => {
  const { count, page, rowsPerPage, onPageChange } = props;

  // const handleFirstPageButtonClick = (
  //   event: React.MouseEvent<HTMLButtonElement>
  // ) => {
  //   onPageChange(event, 0);
  // };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  // const handleLastPageButtonClick = (
  //   event: React.MouseEvent<HTMLButtonElement>
  // ) => {
  //   onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  // };

  return (
    <Box sx={{ flexShrink: 0 }}>
      <Button
        variant="outlined"
        color="inherit"
        size="small"
        type="button"
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
        sx={{ mr: 2, py: 1 }}
      >
        Previous
      </Button>
      <Button
        variant="outlined"
        color="inherit"
        size="small"
        type="button"
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
        sx={{ py: 1 }}
      >
        Next
      </Button>
    </Box>
  );
};

export default TablePaginationActions;
