import ConfigConstant from "core/constants/ConfigConstant";
import { AccountContext } from "modules/Account/context";
import { AccountQuery } from "modules/Account/models";
import PaymentService from "modules/Payment/services";
import { UserContext } from "modules/User/context";
import React from "react";
import { useQuery } from "react-query";

const useVerifySubscription = () => {
  const { user } = React.useContext(UserContext);
  const { account } = React.useContext(AccountContext);
  const { id: accountId } = account;

  const { data } = useQuery(
    [AccountQuery.account_subscription, accountId],
    async () => {
      try {
        const response = await PaymentService.fetchAccountSubscription(
          accountId
        );
        return response.data;
      } catch (err) {
        throw new Error(String(err));
      }
    },
    {
      // If synced
      enabled: !user.anonymous && !!accountId && !!account?.person,
    }
  );

  const ltd = ConfigConstant.LTD_ACCOUNTS.includes(accountId);

  const exception = ConfigConstant.EXCEPTION_COMPANIES.includes(
    account?.company?.id
  );

  return {
    ltd,
    exception,
    is_subscribed:
      data?.is_subscribed !== undefined ? data?.is_subscribed : true,
  };
};

export default useVerifySubscription;
