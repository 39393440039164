import AccountBoxIcon from "@mui/icons-material/AccountBox";
import GroupsIcon from "@mui/icons-material/Groups";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import WebhookIcon from "@mui/icons-material/Webhook";
import { ActionStrategy, IAction } from "modules/Action/models";
import {
  InteractionCategories,
  InteractionCategoryNames,
} from "modules/Interaction/models";

export const MATCH_CATEGORY_TO_VARIANT_OBJ = {
  1: InteractionCategoryNames.message,
  2: InteractionCategoryNames.profile_view,
  3: InteractionCategoryNames.connection_request,
  5: InteractionCategoryNames.sequence_paused,
  6: InteractionCategoryNames.sequence_resumed,
  8: InteractionCategoryNames.connected,
  9: InteractionCategoryNames.disconnected,
  10: InteractionCategoryNames.message_attempt,
  11: InteractionCategoryNames.connection_request_attempt,
  12: InteractionCategoryNames.sequence_stopped,
  14: InteractionCategoryNames.person_in_campaign_queue,
  15: InteractionCategoryNames.inMail,
  18: InteractionCategoryNames.connection_request_withdraw,
  19: InteractionCategoryNames.system_message,
  100: InteractionCategoryNames.add_to_campaign,
};

interface IActionLabel {
  account_is_actor: string;
  account_is_not_actor: string;
}

const createLabelData = (
  account_is_actor: string,
  account_is_not_actor: string
): IActionLabel => ({
  account_is_actor,
  account_is_not_actor,
});

interface IActivityLabel {
  intro: string;
  campaign: string;
}

const createActivityData = (
  intro: IActivityLabel["intro"],
  campaign: IActivityLabel["campaign"]
): IActivityLabel => ({
  intro,
  campaign,
});

export interface IActionData {
  id: InteractionCategories;
  label: IActionLabel;
  activity: IActivityLabel;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon: React.FunctionComponent<any>;
  strategy: IAction["execution_strategy"];
  color: string;
}

const createActionData = (
  id: IActionData["id"],
  label: IActionData["label"],
  activity: IActionData["activity"],
  icon: IActionData["icon"],
  strategy: IActionData["strategy"],
  color: string
): IActionData => ({
  id,
  label,
  activity,
  icon,
  strategy,
  color,
});

const STRATEGY_OBJ = {
  message: createActionData(
    InteractionCategories.message,
    createLabelData("Send a message", "Replied a message"),
    createActivityData(" to ", " in "),
    MailOutlineIcon,
    ActionStrategy["inevitable.strategy.action.LinkedInMessageStrategy"],
    "#9c27b0"
  ),
  profile_view: createActionData(
    InteractionCategories.profile_view,
    createLabelData("View profile", "Viewed profile"),
    createActivityData(" of ", " in "),
    AccountBoxIcon,
    ActionStrategy["inevitable.strategy.action.LinkedInViewProfileStrategy"],
    "#f3b600"
  ),
  connection_request: createActionData(
    InteractionCategories.connection_request,
    createLabelData("Invite to connect", "Invited to connect"),
    createActivityData(" ", " in "),
    PersonAddIcon,
    ActionStrategy[
      "inevitable.strategy.action.LinkedInConnectionRequestStrategy"
    ],
    "#8dc559"
  ),
  group_message: createActionData(
    InteractionCategories.message,
    createLabelData("Send a message to group members", "Replied a message"),
    createActivityData(" by ", " in "),
    GroupsIcon,
    ActionStrategy["inevitable.strategy.action.LinkedInGroupMessageStrategy"],
    "#1DA1F1"
  ),
  sequence_paused: createActionData(
    InteractionCategories.sequence_paused,
    createLabelData("Pause sequence", "Paused sequence"),
    createActivityData(" for ", " in "),
    PersonAddIcon,
    ActionStrategy.sequence_paused,
    "#ED6C02"
  ),
  sequence_resumed: createActionData(
    InteractionCategories.sequence_resumed,
    createLabelData("Resume sequence", "Resumed sequence"),
    createActivityData(" for ", " in "),
    PersonAddIcon,
    ActionStrategy.sequence_resumed,
    "#5073B8"
  ),
  connected: createActionData(
    InteractionCategories.connected,
    createLabelData(
      "Accept an invitation to connect",
      "Accepted your invitation"
    ),
    createActivityData(" by ", " in "),
    PersonAddIcon,
    ActionStrategy.connected,
    // "#5073B8"
    // "#D6EADF"
    // "#B388EB"
    "#2F8559"
  ),
  disconnected: createActionData(
    InteractionCategories.disconnected,
    createLabelData("Remove connection", "Remove connection"),
    createActivityData(" by ", " in "),
    PersonAddIcon,
    ActionStrategy.disconnected,
    "#d32f2f"
  ),

  message_attempt: createActionData(
    InteractionCategories.message_attempt,
    createLabelData("Message fail", "Message fail to sent"),
    createActivityData(" for ", " in "),
    PersonAddIcon,
    ActionStrategy.message_attempt,
    "#d32f2f"
  ),
  connection_request_attempt: createActionData(
    InteractionCategories.connection_request_attempt,
    createLabelData("Invite to connect fail", "Invite to connect fail to sent"),
    createActivityData(" for ", " in "),
    PersonAddIcon,
    ActionStrategy.connection_request_attempt,
    "#d32f2f"
  ),
  sequence_stopped: createActionData(
    InteractionCategories.sequence_stopped,
    createLabelData("Finish campaign for person", "Finish campaign for person"),
    createActivityData(" for ", " in "),
    PersonAddIcon,
    ActionStrategy.sequence_stopped,
    "#ff3246"
  ),
  person_in_campaign_queue: createActionData(
    InteractionCategories.person_in_campaign_queue,
    createLabelData("Schedule person", "Schedule person"),
    createActivityData(" for ", " in "),
    PersonAddIcon,
    ActionStrategy.person_in_campaign_queue,
    "#C5B558"
  ),
  inMail: createActionData(
    InteractionCategories.inMail,
    createLabelData("Send a inMail message", "Replied a inMail message"),
    createActivityData(" to ", " in "),
    MailOutlineIcon,
    ActionStrategy.inMail,
    // "#f3b600"
    "#9c27b0"
  ),
  connection_request_withdraw: createActionData(
    InteractionCategories.connection_request_withdraw,
    createLabelData("Auto-withdraw invitation", "Auto-withdraw invitation"),
    createActivityData(" for ", " in "),
    PersonRemoveIcon,
    ActionStrategy.person_in_campaign_queue,
    "#002765"
  ),
  system_message: createActionData(
    InteractionCategories.system_message,
    createLabelData("LinkedIn note", "LinkedIn note"),
    createActivityData(" for ", " in "),
    LinkedInIcon,
    ActionStrategy.system_message,
    "#2F8559"
  ),
  add_to_campaign: createActionData(
    InteractionCategories.add_to_campaign,
    createLabelData(
      "Add to campaign via webhook",
      "Added to campaign via webhook"
    ),
    createActivityData(" by ", " in "),
    WebhookIcon,
    ActionStrategy.add_to_campaign,
    "#1DA1F1"
  ),
};

// Only these are strategies
const STRATEGY_ARR = [
  STRATEGY_OBJ.profile_view,
  STRATEGY_OBJ.connection_request,
  STRATEGY_OBJ.message,
  STRATEGY_OBJ.group_message,
];

const STRATEGY_EDITABLE = [
  STRATEGY_OBJ.message.strategy,
  STRATEGY_OBJ.group_message.strategy,
  STRATEGY_OBJ.connection_request.strategy,
];

const STRATEGY_AFTER_CONNECTION = [STRATEGY_OBJ.message.strategy];

const STRATEGY_OPTIONS = STRATEGY_ARR.map(
  ({ label, strategy, icon, color }) => ({
    id: strategy,
    label: label.account_is_actor,
    icon,
    color,
  })
);

const StrategyConstant = {
  MATCH_CATEGORY_TO_VARIANT_OBJ,
  STRATEGY_OBJ,
  STRATEGY_EDITABLE,
  STRATEGY_AFTER_CONNECTION,
  STRATEGY_OPTIONS,
};

export default StrategyConstant;
