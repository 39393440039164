/* eslint-disable @typescript-eslint/naming-convention */
import Alert from "@mui/material/Alert";
import ElementField from "core/components/ElementField";
import SettingsItem from "core/components/SettingsItem";
import SliderField from "core/components/SliderField";
import { SliderVariant } from "core/components/SliderField/component";
import { formatRangeArrayToObject } from "core/utils/commonHandler";
import { createComponents } from "core/utils/componentsHandler";
import WarmUpField from "modules/Account/components/WarmUpField";
import {
  AccountQuery,
  IAccount,
  IAccountDetailItemProps,
  IAccountRestrictionFormInput,
} from "modules/Account/models";
import AccountService from "modules/Account/services";
import React from "react";
import { useQueryClient } from "react-query";
import RangeSlider from "ui-kit/components/RangeSlider";
import ActionSettings from "./components/ActionSettings";

interface IFormInput {
  restrictions: IAccountRestrictionFormInput;
}

const LimitSettings = ({
  accountId,
}: IAccountDetailItemProps): React.ReactElement | null => {
  const queryClient = useQueryClient();
  const [account, setAccount] = React.useState<IAccount | undefined>(undefined);
  const data: IAccount | undefined = queryClient.getQueryData([
    AccountQuery.account,
    accountId,
  ]);
  React.useEffect(() => {
    if (data) {
      setAccount(data);
    }
  }, [data]);

  if (!account) return null;

  const { warm_up, connection_requests_daily_range, follow_up_messages } =
    account.restrictions;

  const defaultValues = {
    restrictions: {
      warm_up: {
        enabled: warm_up.enabled,
        interactions_range: [warm_up.start, warm_up.end],
        increase_rate_range: [
          warm_up.increase_rate_range.start,
          warm_up.increase_rate_range.end,
        ],
      },
      connection_requests_daily_range: [
        connection_requests_daily_range.start,
        connection_requests_daily_range.end,
      ],
      follow_up_messages: [follow_up_messages.start, follow_up_messages.end],
    },
  };

  return (
    <>
      <SettingsItem
        FormProps={{
          id: accountId,
          name: [AccountQuery.account, accountId],
          func: AccountService.updateAccount,
          format: ({ restrictions }: IFormInput) => {
            return {
              restrictions: {
                warm_up: {
                  enabled: restrictions.warm_up.enabled,
                  increase_rate_range: formatRangeArrayToObject(
                    restrictions.warm_up.increase_rate_range
                  ),
                  ...formatRangeArrayToObject(
                    restrictions.warm_up.interactions_range
                  ),
                },
              },
            };
          },
          defaultValues,
        }}
        components={[
          createComponents(WarmUpField, "restrictions.warm_up.enabled", {
            label: "Auto warm-up",
          }),
        ]}
      />
      <SettingsItem
        FormProps={{
          id: accountId,
          name: [AccountQuery.account, accountId],
          func: AccountService.updateAccount,
          format: ({ restrictions }: IFormInput) => {
            return {
              restrictions: {
                connection_requests_daily_range: formatRangeArrayToObject(
                  restrictions.connection_requests_daily_range
                ),
                follow_up_messages: formatRangeArrayToObject(
                  restrictions.follow_up_messages
                ),
              },
            };
          },
          defaultValues,
        }}
        PaperHeaderProps={{
          title: "Maximum limits",
          body: "Update the maximum amount of LinkedIn actions sent per day.",
        }}
        components={[
          createComponents(
            ElementField,
            "restrictions.connection_requests_daily_range",
            {
              component: SliderField,
              label: "Max. connection requests per day",
              ElementProps: {
                min: 1,
                max: 100,
                marks: [1, 100],
                maxDistance: 20,
                component: RangeSlider,
                alert: {
                  component: (
                    <Alert
                      variant="standard"
                      icon={false}
                      severity="warning"
                      sx={{ mb: 4, width: "fit-content" }}
                    >
                      <b>Note</b>: LinkedIn has set limits on the connection
                      requests between 100-200 per week.
                    </Alert>
                  ),
                  count: 60,
                  variant: SliderVariant.toValue,
                },
              },
            }
          ),
          createComponents(ElementField, "restrictions.follow_up_messages", {
            component: SliderField,
            label: "Max. messages per day",
            ElementProps: {
              min: 1,
              max: 100,
              marks: [1, 100],
              maxDistance: 20,
              component: RangeSlider,
            },
          }),
        ]}
      />
      <ActionSettings accountId={accountId} />
    </>
  );
};

export default LimitSettings;
