import Box from "@mui/material/Box";
import { Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import ConfigConstant from "core/constants/ConfigConstant";
import first from "lodash/first";
import { AccountContext } from "modules/Account/context";
import {
  AccountQuery,
  IAccountDetailItemProps,
  IAccountLog,
} from "modules/Account/models";
import ActionLogStepper from "modules/Account/pages/CreateAccount/components/ActionLogStepper";
import AccountService from "modules/Account/services";
import { showSyncLoader } from "modules/Account/utils";
import React from "react";
import { useQuery } from "react-query";
import Loader from "ui-kit/components/Loader";
import PaperHeader from "ui-kit/components/PaperHeader";

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    padding: theme.spacing(3.5, 2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(4, 0, 6),
    },
  },
}));

const AccountLogList = ({
  accountId,
}: IAccountDetailItemProps): React.ReactElement => {
  const classes = useStyles();
  const [recentLog, setRecentLog] = React.useState<IAccountLog | undefined>(
    undefined
  );

  const {
    account: { login },
  } = React.useContext(AccountContext);

  const fetchLastAccountLog = async () => {
    try {
      const { data } = await AccountService.fetchLastAccountLog(accountId);
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };

  const { data, isLoading } = useQuery(
    [AccountQuery.last_log, accountId],
    () => fetchLastAccountLog(),
    {
      keepPreviousData: true,
      enabled: !!accountId,
      refetchInterval: 10000,
    }
  );

  React.useEffect(() => {
    if (data?.results) {
      setRecentLog(first(data.results));
    }
  }, [data?.results]);

  const steps = data ? data.results : [];
  const displaySteps = !(isLoading || steps.length === 0);

  return (
    <>
      <div className={classes.box}>
        <PaperHeader
          title="Account sync"
          body={`Here you can monitor and fix problems syncing your LinkedIn account with ${ConfigConstant.APP_NAME}.`}
        />
        {showSyncLoader(recentLog, false) && (
          <Box sx={{ pt: 8, pb: 2 }}>
            <Loader text="We're syncing your LinkedIn account, please wait..." />
          </Box>
        )}
        {displaySteps ? (
          <ActionLogStepper accountId={accountId} login={login} steps={steps} />
        ) : (
          <Typography variant="body2">No account activity.</Typography>
        )}
      </div>
    </>
  );
};

export default AccountLogList;
