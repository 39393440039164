import { TypographyOptions } from "@mui/material/styles/createTypography";

const typography: TypographyOptions = {
  fontFamily: [
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  body2: {
    fontSize: "0.85rem",
  },
  subtitle1: {
    fontWeight: 500,
    fontSize: "1.05rem",
  },
  h5: {
    fontWeight: 500,
  },
  h4: {
    fontSize: "1.6rem",
    fontWeight: 600,
  },
};

export default typography;
