import { Box, Skeleton, Theme, useTheme } from "@mui/material";
import SwitchField from "core/components/SwitchField";
import { errorHandler, IErrorResponse } from "core/utils/errorHandler";
import { snackbarHandler } from "core/utils/snackbarHandler";
import { AccountContext } from "modules/Account/context";
import CampaignProgress from "modules/Campaign/components/CampaignProgress";
import {
  CampaignQuery,
  ExecutionKeys,
  ExecutionLabels,
  IUpdateCampaignFormValues,
} from "modules/Campaign/models";
import CampaignService from "modules/Campaign/services";
import { getStatusChipData } from "modules/Campaign/utils";
import { GetStartedLabels } from "modules/Dashboard/models";
import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { IStatusChipData } from "ui-kit/atoms/Chip";

interface CampaignStatusFieldProps {
  campaignId: number;
  execution: ExecutionKeys | undefined;
  disableHeader?: boolean;
}

const getChecked = (v: ExecutionKeys | undefined) => v === ExecutionKeys.RU;
const parseChecked = (v: boolean) => (v ? ExecutionKeys.RU : ExecutionKeys.ST);

const CampaignStatusField = ({
  campaignId,
  execution,
  disableHeader,
}: CampaignStatusFieldProps): React.ReactElement | null => {
  const queryClient = useQueryClient();
  const theme: Theme = useTheme();

  const [value, setValue] = React.useState<boolean>(getChecked(execution));
  const [disabled, setDisabled] = React.useState<boolean>(false);
  const {
    account: { id: accountId },
  } = React.useContext(AccountContext);

  const mutateOnChange = useMutation(
    (data: IUpdateCampaignFormValues) =>
      CampaignService.updateCampaign(campaignId, data),
    {
      onSuccess: ({ data }) => {
        queryClient.invalidateQueries([CampaignQuery.campaign, campaignId]);
        queryClient.invalidateQueries([
          "get-started",
          accountId,
          GetStartedLabels["launch-campaign"],
        ]);
        snackbarHandler.success(
          `Campaign status changed to ${ExecutionLabels[data.execution]}!`
        );
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);
      },
    }
  );

  const handleDisabled = () => {
    setDisabled(true);
    setTimeout(() => {
      setDisabled(false);
    }, 200);
  };

  const handleOnChange = (newValue: boolean) => {
    if (!campaignId) {
      return;
    }

    setValue(newValue);
    handleDisabled();

    const newExecution = parseChecked(newValue);
    mutateOnChange.mutate({ execution: newExecution });
  };

  React.useEffect(() => {
    const newValue = getChecked(execution);
    if (value !== newValue) {
      setValue(newValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [execution]);

  const skeleton = <Skeleton width={50} />;

  if (!execution) {
    return skeleton;
  }

  const data: IStatusChipData = getStatusChipData(execution);

  if (!data || !data.color) {
    return skeleton;
  }

  const { color, label } = data;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: disableHeader ? "flex-start" : "flex-end",
        marginRight: theme.spacing(3),
      }}
    >
      <SwitchField
        name="execution"
        value={value}
        onChange={handleOnChange}
        label={
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              color: `${theme.palette[color]?.main} !important`,
            }}
          >
            {/* <Icon sx={{ mr: 1, fontSize: 15 }} /> */}
            {label} (
            <CampaignProgress
              campaignId={campaignId}
              hideProgressBar
              disableColor
            />
            )
          </Box>
        }
        ElementProps={{ positionRight: true, color: "secondary", disabled }}
      />
    </Box>
  );
};

CampaignStatusField.defaultProps = {
  disableHeader: false,
};

export default CampaignStatusField;
