import { snackbarHandler } from "core/utils/snackbarHandler";
import { getPersonName } from "modules/Person/utils";
import { getUser } from "modules/User/utils";
import { IAccountCurrent } from "../models";
import { EMPTY_CURRENT_ACCOUNT } from "modules/Account/constants";
import { storeCurrentAccount } from "../utils";

export enum AccountActionType {
  SET_ACCOUNT,
  UPDATE_ACCOUNT,
  INITIAL_LOAD_ACCOUNT,
  LOGOUT,
}

export type AccountActions =
  | {
      type:
        | AccountActionType.SET_ACCOUNT
        | AccountActionType.UPDATE_ACCOUNT
        | AccountActionType.INITIAL_LOAD_ACCOUNT;
      payload: IAccountCurrent;
    }
  | { type: AccountActionType.LOGOUT };

export const accountReducer = (
  state: IAccountCurrent,
  action: AccountActions
) => {
  switch (action.type) {
    case AccountActionType.UPDATE_ACCOUNT: {
      const newState = action.payload;
      storeCurrentAccount(newState);
      return newState;
    }
    case AccountActionType.SET_ACCOUNT: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const {
        id,
        company,
        person,
        login,
        time_zone,
        proxy_id,
        meta_data,
        created,
      } = action.payload;
      const newState = {
        id,
        company,
        person,
        login,
        time_zone,
        username: getUser().username,
        proxy_id,
        meta_data,
        created,
      };
      // Set default LinkedIn account only if connected
      if (person) {
        const name = getPersonName(person);
        snackbarHandler.success(`Account switched to ${name}.`);
      }

      storeCurrentAccount(newState);
      return newState;
    }
    case AccountActionType.INITIAL_LOAD_ACCOUNT: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const {
        id,
        company,
        person,
        login,
        time_zone,
        proxy_id,
        meta_data,
        created,
      } = action.payload;
      const newState = {
        id,
        company,
        person,
        login,
        time_zone,
        username: getUser().username,
        proxy_id,
        meta_data,
        created,
      };
      storeCurrentAccount(newState);

      return newState;
    }

    case AccountActionType.LOGOUT: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      storeCurrentAccount(undefined);

      return EMPTY_CURRENT_ACCOUNT;
    }

    default:
      return state;
  }
};
