import ConfigConstant from "core/constants/ConfigConstant";

export interface CookieInfo {
  value: string;
  expires: Date | null;
}

export function getCookie(name: string): CookieInfo | null {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(name + "=")) {
      const [, value] = cookie.split("=");
      return {
        value,
        expires: getCookieExpirationDate(name),
      };
    }
  }
  return null;
}

function getCookieExpirationDate(name: string): Date | null {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(name + "=")) {
      // This is a hack to get the expiration date
      // We set the cookie again with a known expiration date
      const originalValue = cookie.split("=")[1];
      const testDate = new Date();
      testDate.setTime(testDate.getTime() + 1000); // 1 second in the future
      document.cookie = `${name}=${originalValue};expires=${testDate.toUTCString()};path=/`;

      // Now we check if the cookie still exists
      if (document.cookie.indexOf(name + "=") === -1) {
        // Cookie has expired
        return null;
      } else {
        // Cookie hasn't expired, so we return the test date
        return testDate;
      }
    }
  }
  return null;
}

export function getAffiliateId(): CookieInfo | null {
  return getCookie(ConfigConstant.AFFILIATE_ID_NAME);
}
