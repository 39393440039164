import RouterConstants from "core/routes/constants";
import AccountNameWithLogin from "modules/Account/components/AccountNameWithLogin";
import { AccountQuery, AccountTabsLabels } from "modules/Account/models";
import LimitSettings from "modules/Account/pages/AccountDetail/components/LimitSettings";
import AccountService from "modules/Account/services";
import AddressBookList from "modules/AddressBook/pages/AddressBookList";
import React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import CardTabs, { createTabs } from "ui-kit/components/CardTabs";
import Loader from "ui-kit/components/Loader";
import PageHeader from "ui-kit/components/PageHeader";
import AccountLogList from "../AccountLogList";
import AccountPlaceholderSettings from "./components/AccountPlaceholderSettings";
import PrivacySettings from "./components/PrivacySettings";
import ProfileSettings from "./components/ProfileSettings";
import ScheduleSettings from "./components/ScheduleSettings";

type ParamTypes = {
  accountId: string;
};

const ACCOUNT_TABS = [
  createTabs(AccountTabsLabels.limits, LimitSettings),
  createTabs(AccountTabsLabels.schedule, ScheduleSettings),
  createTabs(AccountTabsLabels.placeholders, AccountPlaceholderSettings),
  createTabs(AccountTabsLabels.credentials, ProfileSettings),
  createTabs(AccountTabsLabels.privacy, PrivacySettings),
  createTabs(AccountTabsLabels.blacklist, AddressBookList),
  createTabs(AccountTabsLabels.activity, AccountLogList),
];

const AccountDetail = (): React.ReactElement | null => {
  const params = useParams<ParamTypes>();
  const accountId: number = +params.accountId;

  const { data, isLoading } = useQuery(
    [AccountQuery.account, accountId],
    async () => {
      try {
        const response = await AccountService.fetchAccount(accountId);
        return response.data;
      } catch (err) {
        throw new Error(String(err));
      }
    },
    {
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: !!accountId,
    }
  );

  return (
    <>
      <PageHeader
        title="Account settings"
        body={
          <>
            Here you can edit settings
            {data ? (
              <>
                {" "}
                for <AccountNameWithLogin account={data} />
              </>
            ) : null}
            .
          </>
        }
        helperProps={{
          link: RouterConstants.DOCS.ACCOUNT.SETTINGS,
        }}
        hiddenDivider
      />
      <CardTabs
        variant="small"
        tabs={ACCOUNT_TABS}
        isLoading={isLoading}
        accountId={accountId}
      />
      {isLoading && <Loader />}
    </>
  );
};

export default AccountDetail;
