import { Theme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/styles";
import ConfigConstant from "core/constants/ConfigConstant";
import KeywordFilter from "core/filters/atoms/KeywordFilter";
import CampaignFilter from "core/filters/components/CampaignFilter";
import SearchFilter from "core/filters/components/SearchFilter";
import { DeviceVariant } from "core/models";
import RouterUtils from "core/routes/utils";
import { AccountContext } from "modules/Account/context";
import PlaceholderService from "modules/Placeholder/services";
import { generateAndMergeHeads, generateRows } from "modules/Placeholder/utils";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import Table, { TableVariant } from "ui-kit/components/Table";

const rowsPerPage = ConfigConstant.PAGE_SIZE.MEDIUM;
const title = "Placeholders";

const PlaceholderList = (): React.ReactElement => {
  const theme: Theme = useTheme();
  const [page, setPage] = useState<number>(ConfigConstant.INITIAL_PAGE);
  const location = useLocation();
  const { search } = RouterUtils.getQueryParams(location);
  const { searchId, campaignId } = RouterUtils.parseQueryParams(location);

  const { account } = React.useContext(AccountContext);

  const fetchPlaceholders = async (p: number) => {
    try {
      const { data } = await PlaceholderService.fetchPlaceholders(
        account.id,
        account.person?.id,
        p,
        rowsPerPage,
        search,
        RouterUtils.generateFilterNameQuery(
          campaignId,
          "interaction__campaign_id__in"
        ),
        RouterUtils.generateFilterNameQuery(
          searchId,
          "searchresult__search_id__in"
        )
      );
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };
  const { data, isFetching, isLoading } = useQuery(
    ["placeholders", page, search, campaignId, searchId],
    () => fetchPlaceholders(page),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const deviceSize = useMediaQuery(theme.breakpoints.up("md"))
    ? DeviceVariant.desktop
    : DeviceVariant.mobile;

  return (
    <Table
      title={title}
      subtitle="Here you can manage your placeholders. Scroll to the right to view all placeholders."
      filters={
        <>
          <KeywordFilter />
          <SearchFilter multiple />
          <CampaignFilter multiple />
        </>
      }
      heads={generateAndMergeHeads(data?.results.placeholder_keys, deviceSize)}
      rows={generateRows(data?.results.persons, data?.results.placeholder_keys)}
      count={data?.count || 0}
      // INITIAL_PAGE starts at 1, but Pagination starts at 0
      page={(data?.current || page) - 1}
      setPage={setPage}
      isFetching={isFetching}
      isLoading={isLoading}
      variant={[TableVariant.scrollable]}
    />
  );
};

export default PlaceholderList;
