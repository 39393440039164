import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

interface FormFieldProps {
  children: React.ReactElement;
  text: string;
  disableFieldBottom?: boolean;
  disableTextBottom?: boolean;
}

const FormField = ({
  children,
  text,
  disableFieldBottom,
  disableTextBottom,
}: FormFieldProps): React.ReactElement => {
  return (
    <Box sx={{ mb: disableFieldBottom ? 0 : 4 }}>
      <Typography variant="subtitle2" sx={{ mb: disableTextBottom ? 0 : 2.5 }}>
        {text}
      </Typography>
      {children}
    </Box>
  );
};

export default FormField;
