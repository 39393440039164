import React from "react";
import { Control, FieldError, useWatch } from "react-hook-form";
import clsx from "clsx";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import SliderField from "core/components/SliderField";
import ElementField from "core/components/ElementField";
import SwitchField from "core/components/SwitchField";
import PaperHeader from "ui-kit/components/PaperHeader";
import HelperTypography from "ui-kit/components/HelperTypography";
import SingleSlider from "ui-kit/components/SingleSlider";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hidden: {
      display: "none",
    },
    input: {
      display: "block",
      maxWidth: 120,
    },
    switch: {
      marginBottom: theme.spacing(4),
    },
  })
);

interface IControllerProps {
  control: Control;
  error: FieldError | undefined;
  name: string;
  label: string | [string, string];
  setValue: (n: string, v: number) => void;
}

const PendingInvitationsField = ({
  control,
  error,
  name,
  label,
  setValue,
}: IControllerProps): React.ReactElement => {
  const classes = useStyles();
  const sliderName = "withdraw_time_delta";

  return (
    <>
      <PaperHeader
        title={
          <ElementField
            name={name}
            component={SwitchField}
            control={control}
            label={label}
            ElementProps={{
              header: true,
              positionRight: true,
              onChangeHandler: (checked: boolean) => {
                const v = checked === true ? 30 : 0;
                setValue(sliderName, v);
                return !!v;
              },
            }}
          />
        }
        body="Automatically withdraw not accepted connection requests after selected amount of days."
      />
      <div
        className={clsx({
          [classes.hidden]: !useWatch({
            name,
            control,
          }),
        })}
      >
        <ElementField
          component={SliderField}
          name={sliderName}
          control={control}
          error={error}
          label={
            <HelperTypography
              text="After days:"
              typographyProps={{ variant: "subtitle2", color: "textPrimary" }}
            />
          }
          ElementProps={{
            min: 1,
            max: 50,
            marks: [1, 50],
            component: SingleSlider,
          }}
        />
      </div>
    </>
  );
};

export default PendingInvitationsField;
