import ConfigConstant from "core/constants/ConfigConstant";
import { UserContext } from "modules/User/context";
import { IUser } from "modules/User/models";
import React, { useEffect, useRef } from "react";
import { CustomerlyProvider, useCustomerly } from "react-live-chat-customerly";

const getUserData = (user: IUser | undefined) =>
  user
    ? {
        user_id: user.id,
        name: user.first_name
          ? `${user.first_name} ${user.last_name}`
          : user.name,
        email: user.email,
      }
    : {};

const CustomerlyLoader = (): null => {
  const { load, update } = useCustomerly();
  const { user } = React.useContext(UserContext);
  const isUserLoaded = useRef<boolean>(false);

  useEffect(() => {
    const userLoaded = !!user;

    if (!!userLoaded) {
      isUserLoaded.current = true;
    }

    load({
      ...getUserData(user),
      autodetectLocale: false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isUserLoaded.current) {
      return;
    }

    update({
      ...getUserData(user),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isUserLoaded.current]);

  return null;
};

interface CustomerlyLiveChatProps {
  children: React.ReactElement | React.ReactElement[];
}

const LiveChatProvider = ({
  children,
}: CustomerlyLiveChatProps): React.ReactElement => {
  return (
    <CustomerlyProvider appId={String(ConfigConstant.CUSTOMERLY_ID)}>
      {children}
      <CustomerlyLoader />
    </CustomerlyProvider>
  );
};

export default LiveChatProvider;
