import { BoxProps } from "@mui/material/Box";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import RouterUtils from "core/routes/utils";
import { Location } from "history";
import debounce from "lodash/debounce";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import TextField from "ui-kit/atoms/TextField";

const id = "search";

interface TextSearchFilterProps {
  sx?: BoxProps["sx"];
}

const useStyles = makeStyles((theme: Theme) => ({
  inputRoot: {
    marginBottom: 0,
  },
  input: {
    padding: `${theme.spacing(1.5, 2.5)} !important`,
    fontSize: "0.85rem",
  },
}));

const TextSearchFilter = ({
  sx,
}: TextSearchFilterProps): React.ReactElement => {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();

  const textField = React.useRef<HTMLInputElement>(null);
  const { [id]: query } = RouterUtils.getQueryParams(location);

  const defaultValue = query || "";
  const [value, setValue] = React.useState<string>(defaultValue);

  const updateQuery = (newValue: string, loc: Location) => {
    if (value !== newValue) {
      return RouterUtils.addQuery({ [id]: newValue }, history);
    }
    return RouterUtils.removeQuery([id], history);
  };

  const changeHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    loc: Location
  ) => {
    const newValue = event.target.value;
    newValue.trim();
    setValue(newValue);
    updateQuery(newValue, loc);
  };

  const debouncedChangeHandler = React.useMemo(
    () => debounce(changeHandler, 500),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onHandleClose = () => {
    setValue("");
    RouterUtils.removeQuery([id], history);
    if (textField.current?.value) {
      textField.current.value = "";
    }
  };

  React.useEffect(() => {
    if (!defaultValue) {
      onHandleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  // Set autofocus
  React.useEffect(() => {
    setTimeout(() => {
      if (!!textField.current) {
        textField.current.focus();
      }
    }, 100);
  }, []);

  return (
    <TextField
      fullWidth
      key={1}
      defaultValue={defaultValue}
      autoComplete="off"
      onChange={(e) =>
        debouncedChangeHandler(
          e as React.ChangeEvent<HTMLInputElement>,
          location // Have to pass location here, as debounce causes location to be outdated
        )
      }
      inputRef={textField}
      name="filter"
      variant="outlined"
      sx={sx}
      placeholder="Keyword"
      InputProps={{
        classes: {
          input: classes.input,
        },
      }}
      classes={{
        root: classes.inputRoot,
      }}
    />
  );
};

TextSearchFilter.defaultProps = {
  sx: {},
};

export default TextSearchFilter;
