import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Popover, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import RouterUtils from "core/routes/utils";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import Button from "ui-kit/atoms/Button";

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    border: `1px dashed ${theme.app.palette.action.check}`,
    borderRadius: 50,
    lineHeight: "22px",
    cursor: "pointer",
    padding: 0,
    backgroundColor: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.app.palette.background.default,
    },
    display: "flex",
    alignItems: "center",
  },
  content: {
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing(1.5),
    paddingRight: theme.spacing(2.25),
    fontSize: "0.75rem",
    fontWeight: 600,
  },
  iconBtn: {
    display: "flex",
    paddingLeft: theme.spacing(1.5),
  },
  iconActive: {
    transform: "rotate(45deg)",
  },
  iconSearch: {
    padding: 1,
  },
  icon: {
    borderRadius: 50,
    color: theme.palette.common.white,
    backgroundColor: theme.app.palette.action.placeholder,
    fontSize: 14,
    "&:hover": {
      backgroundColor: theme.app.palette.action.main,
    },
  },
  expandIcon: {
    color: theme.app.palette.action.placeholder,
    fontSize: 16,
    marginRight: theme.spacing(-1),
    marginLeft: theme.spacing(-0.5),
  },
  label: {
    color: theme.app.palette.action.color,
    paddingLeft: theme.spacing(1.5),
  },
  query: {
    color: theme.palette.primary.main,
  },
  divider: {
    height: 12,
    width: 1,
    backgroundColor: theme.app.palette.shadow.secondary,
  },
  popover: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(4),
    width: 300,
    border: `1px solid ${theme.app.palette.shadow.secondary}`,
    overflow: "visible",
    borderRadius: 6,
  },
  arrow: {
    "&::before": {
      backgroundColor: "white",
      content: '""',
      display: "block",
      position: "absolute",
      width: 11,
      height: 11,
      top: -6,
      transform: "rotate(45deg)",
      left: "20px",
      borderRadius: 2,
      borderLeft: `1px solid ${theme.app.palette.shadow.secondary}`,
      borderTop: `1px solid ${theme.app.palette.shadow.secondary}`,
    },
  },
}));

interface FilterButtonProps {
  id: string;
  label: string;
  enableSearchIcon?: boolean;
  children: React.ReactElement;
  data?: { results: any[] } | undefined;
  queryName?: string;
}

const FilterButton = ({
  id,
  label,
  enableSearchIcon,
  children,
  data,
  queryName = "name",
}: FilterButtonProps): React.ReactElement => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const history = useHistory();
  const location = useLocation();
  const queryParams = RouterUtils.parseQueryParams(location);
  const query = RouterUtils.generateFilterFormattedName(
    queryParams[id],
    data?.results,
    queryName
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const removeFilter = () => {
    RouterUtils.removeQuery([id], history);
  };

  const handleOnRemove = (event: React.MouseEvent<SVGSVGElement>) => {
    if (query) {
      event.stopPropagation();
      removeFilter();
    }
  };

  // If popover closed without clicking on apply, remove filter
  const handlePopoverClose = () => {
    // removeFilter();
    handleClose();
  };

  const open = Boolean(anchorEl);
  const popoverId = open ? "simple-popover" : undefined;

  return (
    <>
      <Box component="span" className={classes.button} onClick={handleClick}>
        <span className={classes.iconBtn}>
          {!!enableSearchIcon && query && (
            <AddIcon
              onClick={handleOnRemove}
              className={clsx(classes.icon, classes.iconActive)}
            />
          )}
          {!!enableSearchIcon && !query && (
            <SearchIcon
              onClick={handleOnRemove}
              className={clsx(classes.icon, classes.iconSearch)}
            />
          )}
          {!enableSearchIcon && (
            <AddIcon
              onClick={handleOnRemove}
              className={clsx(classes.icon, {
                [classes.iconActive]: !!query,
              })}
            />
          )}
        </span>
        <div className={classes.content}>
          <div className={classes.label}>{label}</div>
          {!!query && (
            <>
              <div className={classes.divider} />
              <div className={classes.query}>{query}</div>
              <ExpandMoreIcon className={classes.expandIcon} />
            </>
          )}
        </div>
      </Box>
      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          elevation: 24,
          className: classes.popover,
        }}
      >
        <Box className={classes.arrow} />
        <Typography variant="subtitle2" sx={{ fontWeight: 600, mb: 2 }}>
          Filter by {label}
        </Typography>
        <Box mb={3}>{children}</Box>
        <Button
          color="primary"
          variant="contained"
          fullWidth
          size="small"
          sx={{ paddingTop: 0.75, paddingBottom: 0.75 }}
          onClick={handleClose}
        >
          Apply
        </Button>
      </Popover>
    </>
  );
};

export default FilterButton;
