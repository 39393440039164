import React from "react";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { IAccount, IAccountCurrent } from "modules/Account/models";
import { getPersonName } from "modules/Person/utils";

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    fontWeight: 500,
    color: theme.palette.text.primary,
    fontSize: "0.825rem",
  },
  sub: {
    color: theme.app.palette.action.main,
    fontSize: "0.825rem",
  },
}));

interface AccountNameWithLoginProps {
  account: IAccount | IAccountCurrent | undefined;
}

const AccountNameWithLogin = ({
  account,
}: AccountNameWithLoginProps): React.ReactElement => {
  const classes = useStyles();
  return account?.person ? (
    <>
      <Typography component="span" className={classes.main}>
        {getPersonName(account.person)}
      </Typography>{" "}
      <Typography className={classes.sub} component="span">
        ({account.login})
      </Typography>
    </>
  ) : (
    <>{account?.login || ""}</>
  );
};

export default AccountNameWithLogin;
