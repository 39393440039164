import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ProfileCampaignList from "core/components/ProfileCampaignList";
import get from "lodash/get";
import { AccountContext } from "modules/Account/context";
import ContactNotesList from "modules/Note/components/ContactNotesList";
import DetailFoldableContent from "modules/Person/components/PersonSidebar/components/DetailFoldableContent";
import { IPerson } from "modules/Person/models";
import PersonService from "modules/Person/services";
import { getContactName } from "modules/Person/utils";
import { getPersonSubText } from "modules/Placeholder/utils";
import React from "react";
import { useQuery } from "react-query";
import FoldableContent from "ui-kit/components/FoldableContent";
import PersonAvatar from "../PersonAvatar";
import TagsAutocomplete from "./components/TagsAutocomplete";
import { useProfileStyles } from "./utils";

interface PersonDetailContentProps {
  personId: number;
  contactId: number | undefined;
  data: IPerson | undefined;
  connection: boolean | undefined;
}

const PersonDetail = ({
  personId,
  contactId: contactPropsId,
  data: dataPerson,
  connection,
}: PersonDetailContentProps): React.ReactElement => {
  const classes = useProfileStyles();

  const { account } = React.useContext(AccountContext);
  const { id: accountId } = account;

  const fetchContact = async () => {
    try {
      const { data } = await PersonService.fetchContact(accountId, personId);
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };

  const { data: dataContact } = useQuery(
    ["contact", personId, accountId],
    () => fetchContact(),
    {
      keepPreviousData: true,
      enabled: !!personId,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  const contact = get(dataContact, "results.[0]");
  const personName = getContactName(contact ? contact.person : dataPerson);
  const personSubText = getPersonSubText(dataPerson?.default_placeholders);

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.avatar}>
          <PersonAvatar
            src={dataPerson?.image_base64}
            size="large"
            name={personName}
          />
        </div>
        <div className={classes.person}>
          <div className={classes.title}>
            <Typography variant="h6" className={classes.name} color="inherit">
              {personName}
            </Typography>
            {!!connection && (
              <Typography className={classes.connection} variant="body1">
                • 1nd
              </Typography>
            )}
          </div>
          {!!personSubText && (
            <Typography className={classes.subtitle}>
              {personSubText}
            </Typography>
          )}
        </div>
      </div>
      <>
        <div className={classes.content}>
          <ProfileCampaignList personId={personId} name={personName} />
          <FoldableContent title="Details">
            <DetailFoldableContent person={dataPerson} personId={personId} />
          </FoldableContent>
          {contact && (
            <FoldableContent title="Tags">
              <Box sx={{ py: 2, px: 4 }}>
                <TagsAutocomplete contact={contact} />
              </Box>
            </FoldableContent>
          )}
          {contact && (
            <FoldableContent title="Notes">
              <ContactNotesList contactId={contact.id} />
            </FoldableContent>
          )}
        </div>
      </>
    </div>
  );
};

export default PersonDetail;
