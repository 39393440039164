import { Alert } from "@mui/material";
import { AccountContext } from "modules/Account/context";
import { getAccountName } from "modules/Account/utils";
import { ITeamInvite, TeamRoles } from "modules/Team/models";
import TeamService from "modules/Team/services";
import { UserContext } from "modules/User/context";
import React, { useContext } from "react";
import { useQuery } from "react-query";

const InviteCheckAlert = (): React.ReactElement | null => {
  const { account } = useContext(AccountContext);
  const {
    user: { id: userId },
  } = useContext(UserContext);
  const { id: accountId } = account;

  const { data } = useQuery(
    ["invites", accountId],
    async () => {
      try {
        const response = await TeamService.fetchAccountInvitesFromNotMyAccount(
          Number(accountId),
          userId
        );
        return response.data;
      } catch (err) {
        throw new Error(String(err));
      }
    },
    {
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: !!accountId,
    }
  );

  const accountName = getAccountName(account);

  const hasManagerRole = (data?.results || []).some(
    (invite: ITeamInvite) => invite.role === TeamRoles.MA
  );

  if (!data || data?.count === 0 || hasManagerRole) {
    return null;
  }

  return (
    <Alert severity="warning" sx={{ marginBottom: 6 }}>
      You only have <b>Viewer access</b> to the <b>{accountName}</b> account. In
      order to add or edit data, please request <b>Manager access</b> from the
      account owner.
    </Alert>
  );
};

export default InviteCheckAlert;
