import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import RecentActivityList from "modules/Report/components/RecentActivityList";
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderBottom: `1px solid ${theme.app.palette.shadow.secondary}`,
    paddingBottom: theme.spacing(3),
  },
  header: {
    padding: theme.spacing(0, 0, 4),
  },
  action: {
    marginTop: theme.spacing(-1.25),
    marginBottom: theme.spacing(-1.25),
  },
}));

interface RecentActivityReportProps {
  campaignId?: string;
  personId?: string;
}

const RecentActivityReport = (
  props: RecentActivityReportProps
): React.ReactElement => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CardHeader
        className={classes.header}
        classes={{
          action: classes.action,
        }}
        title="Activity"
        titleTypographyProps={{
          variant: "h4",
        }}
      />
      <Divider />
      <RecentActivityList {...props} />
    </div>
  );
};

RecentActivityReport.defaultProps = {
  campaignId: "",
  personId: "",
};

export default RecentActivityReport;
