import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

interface LoaderProps {
  text?: string;
  minHeight?: number;
  position?: "relative" | "absolute";
}

const Loader = ({
  text,
  minHeight = 40,
  position = "relative",
}: LoaderProps): React.ReactElement => (
  <Box
    sx={{
      width: "100%",
      height: "100%",
      minHeight: minHeight,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      position,
      ...(position === "absolute" && {
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: "rgba(0, 0, 0, 0.075)",
        zIndex: 10,
      }),
    }}
  >
    <CircularProgress color="secondary" sx={text ? { mb: 5 } : {}} />
    {text && <Typography variant="body2">{text}</Typography>}
  </Box>
);

Loader.defaultProps = {
  text: "",
  minHeight: 40,
  position: "relative",
};

export default Loader;
