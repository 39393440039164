import React from "react";
import { useMutation, useQueryClient } from "react-query";
import Box from "@mui/material/Box";
import { errorHandler, IErrorResponse } from "core/utils/errorHandler";
import { snackbarHandler } from "core/utils/snackbarHandler";
import CampaignService from "modules/Campaign/services";
import { IPerson } from "modules/Person/models";
import { ISearchResult } from "modules/Search/models";
import { getProfileName } from "core/utils/profileHandler";
import { CampaignQuery } from "modules/Campaign/models";
import ProfileAction from "ui-kit/components/ProfileAction";

interface InteractionStatusActionsProps {
  onHandleEdit: () => void;
  person: IPerson | undefined;
  searchResult: ISearchResult | undefined;
  campaignId: number;
}

const InteractionActions = ({
  onHandleEdit,
  person,
  searchResult,
  campaignId,
}: InteractionStatusActionsProps): React.ReactElement => {
  const queryClient = useQueryClient();

  const mutateRemovePersonFromCampaign = useMutation(
    (persons: number[]) =>
      CampaignService.deleteCampaignPersons({
        persons,
        campaign: campaignId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([CampaignQuery.interactions, campaignId]);
        snackbarHandler.success("Successfully removed!");
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);
      },
    }
  );

  const mutateRemoveSearchResultFromCampaign = useMutation(
    (search_results: number[]) =>
      CampaignService.deleteCampaignSearchResults({
        search_results,
        campaign: campaignId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([CampaignQuery.interactions, campaignId]);
        snackbarHandler.success("Successfully removed!");
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);
      },
    }
  );

  const handleOnRemove = () => {
    const profileName = getProfileName(searchResult, person);
    if (
      window.confirm(
        `Do you really want to remove ${profileName} from the audience?`
      )
    ) {
      if (person?.id) {
        return mutateRemovePersonFromCampaign.mutate([person.id]);
      }
      if (searchResult?.id) {
        return mutateRemoveSearchResultFromCampaign.mutate([searchResult.id]);
      }
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <ProfileAction
        label={!!person ? "Edit" : "View"}
        onClick={onHandleEdit}
      />
      <ProfileAction label="Remove" onClick={handleOnRemove} />
    </Box>
  );
};

export default InteractionActions;
