import { Box, Divider } from "@mui/material";
import LocaleAutocomplete from "core/components/LocaleAutocomplete";
import SettingsItem from "core/components/SettingsItem";
import RouterConstants from "core/routes/constants";
import { createComponents } from "core/utils/componentsHandler";
import {
  endOfTimezoneDay,
  formatDateToStr,
  isInThePast,
} from "core/utils/dateHandler";
import DisplaySchedule from "modules/Account/components/DisplaySchedule";
import { AccountContext } from "modules/Account/context";
import { AccountTabs } from "modules/Account/models";
import {
  CampaignLocale,
  CampaignLocaleNames,
  CampaignQuery,
  ICampaignParams,
  ICreateCampaignFormInput,
} from "modules/Campaign/models";
import CampaignService from "modules/Campaign/services";
import React from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import Loader from "ui-kit/components/Loader";
import PaperHeader from "ui-kit/components/PaperHeader";
import CampaignStartDate from "../CampaignStartDate";

const getStartDate = (startDate: string | null | undefined) => {
  const today = formatDateToStr(endOfTimezoneDay());
  if (!startDate) {
    return today;
  }

  if (isInThePast(startDate)) {
    return today;
  }

  return startDate;
};

const CreateReview = ({
  campaignId,
}: ICampaignParams): React.ReactElement | null => {
  const {
    account: { id: accountId },
  } = React.useContext(AccountContext);

  const { data, error, isLoading } = useQuery(
    [CampaignQuery.campaign, campaignId],
    async () => {
      try {
        const response = await CampaignService.fetchCampaign(campaignId);
        return response.data;
      } catch (err) {
        throw new Error(String(err));
      }
    }
  );

  const defaultValues = {
    start_date: {
      enabled: !!data?.start_date,
      date: getStartDate(data?.start_date),
    },
    locale: {
      id: data?.locale || CampaignLocale.en,
      name: data?.locale
        ? CampaignLocaleNames[data?.locale as CampaignLocale]
        : CampaignLocale.en,
    },
  };

  if (isLoading) {
    return <Loader minHeight={300} />;
  }

  if (!data || error) {
    return null;
  }

  return (
    <>
      <Box py={4}>
        <PaperHeader
          title="Sending schedule"
          body={
            <>
              Schedule when your account sends campaigns. You can{" "}
              <Link
                to={RouterConstants.ACCOUNT.detail(
                  accountId,
                  AccountTabs.schedule
                )}
              >
                edit your schedule
              </Link>
              .
            </>
          }
        />
        <DisplaySchedule />
      </Box>
      <Divider light />

      <SettingsItem
        FormProps={{
          id: campaignId,
          name: [CampaignQuery.campaign, campaignId],
          func: CampaignService.updateCampaign,
          format: (d: ICreateCampaignFormInput) => {
            return { start_date: d.start_date?.date || null };
          },
          defaultValues,
        }}
        components={[
          createComponents(CampaignStartDate, "start_date.enabled", {
            label: "Schedule start",
          }),
        ]}
      />

      <SettingsItem
        FormProps={{
          id: campaignId,
          name: [CampaignQuery.campaign, campaignId],
          func: CampaignService.updateCampaign,
          format: (d: ICreateCampaignFormInput) => {
            return { locale: d?.locale?.id };
          },
          defaultValues,
        }}
        PaperHeaderProps={{
          title: "Language",
          body: "Select language of your campaign for extra personalization in your messages.",
          helperProps: {
            title: `Example: You can utilize "Day of the week" custom placeholder, to write "Happy {weekday}" => "Happy Monday" in your message.`,
          },
        }}
        components={[createComponents(LocaleAutocomplete, "locale")]}
        hiddenDivider
      />
    </>
  );
};

export default CreateReview;
