import { Box } from "@mui/material";
import { Theme } from "@mui/material/styles";
import Typography, { TypographyProps } from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
  dividerBox: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    width: "100%",
    margin: theme.spacing(2, 0),
    "&::before, &::after": {
      content: '""',
      flex: 1,
      borderBottom: `1px solid ${theme.app.palette.action.disabled}`,
    },
    "&::before": {
      marginRight: theme.spacing(2),
    },
    "&::after": {
      marginLeft: theme.spacing(2),
    },
  },
}));

interface DividerTextProps {
  text: TypographyProps["children"];
}

const DividerText = ({ text }: DividerTextProps): React.ReactElement => {
  const classes = useStyles();

  return (
    <Box className={classes.dividerBox}>
      <Typography variant="body2" color="action.color" my={3}>
        {text}
      </Typography>
    </Box>
  );
};

export default DividerText;
