import React from "react";
import {
  ISearchParams,
  SearchStatusMatchToVariant,
} from "modules/Search/models";
import SearchResultList from "modules/Search/components//SearchResultList";
import SearchInfo from "modules/Search/components/SearchInfo";

const SearchDetail = ({ search }: ISearchParams): React.ReactElement | null => {
  if (!search) {
    return null;
  }

  if (search.status === SearchStatusMatchToVariant.queue) {
    return (
      <SearchInfo
        title="Collecting people from the search..."
        body="This can take a while. You can leave this page and we will send you a notification when the search is processed."
        loader
      />
    );
  }

  return <SearchResultList search={search} />;
};

export default SearchDetail;
