import { Box } from "@mui/material";
import { getProfileName } from "core/utils/profileHandler";
import { IInteraction } from "modules/Interaction/models";
import { IPerson } from "modules/Person/models";
import React from "react";
import PersonAvatar from "../PersonAvatar";

interface ExternalProfileProps {
  person?: IPerson | null;
  searchResult: IInteraction["search_result"];
}

const ExternalProfile = ({
  person,
  searchResult,
}: ExternalProfileProps): React.ReactElement => {
  const name = getProfileName(searchResult, person);
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 2.5 }}>
      <PersonAvatar name={name} src={person?.image_base64} size="mini" />
      <div>{name}</div>
    </Box>
  );
};

export default ExternalProfile;
