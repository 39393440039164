import * as React from "react";
import { makeStyles } from "@mui/styles";
import { SnackbarProvider, useSnackbar } from "notistack";
import IconClose from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { forceStyle } from "core/utils/commonHandler";

function SnackbarCloseButton({ snackbarKey }: any) {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton onClick={() => closeSnackbar(snackbarKey)} size="small">
      <IconClose fontSize="small" />
    </IconButton>
  );
}

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    "& > div": {
      backgroundColor: forceStyle("rgba(0, 0, 0, 0.8)"),
      "& > .SnackbarItem-message": {
        fontSize: "0.9rem",
        color: palette.common.white,
        marginRight: forceStyle(spacing(5)),
        fontWeight: 500,
        "& > svg": {
          marginInlineEnd: forceStyle(spacing(3)),
        },
      },
      "& > .SnackbarItem-action": {
        position: "absolute",
        right: 10,
        top: 8,
      },
      "& > .SnackbarItem-action > button": {
        color: palette.common.white,
      },
    },
  },
  variantSuccess: {
    "& > .SnackbarItem-message > svg": {
      color: forceStyle(palette.success.light),
    },
  },
  variantError: {
    "& > .SnackbarItem-message > svg": {
      color: forceStyle(palette.error.light),
    },
  },
  variantWarning: {
    "& > .SnackbarItem-message > svg": {
      color: forceStyle(palette.warning.light),
    },
  },
  variantInfo: {
    "& > .SnackbarItem-message > svg": {
      color: forceStyle(palette.info.light),
    },
  },
}));

export const NotistackProvider = (props: any): React.ReactElement => {
  const classes = useStyles();
  return (
    <SnackbarProvider
      maxSnack={3}
      classes={classes}
      action={(snackbarKey) => (
        <SnackbarCloseButton snackbarKey={snackbarKey} />
      )}
      {...props}
    />
  );
};
