import * as Yup from "yup";
import { CreateSequenceKeys } from "../models";

export const ERROR_KEYS = {
  NAME: {
    REQUIRED: "Template name is required.",
    INVALID: "Template name needs to be at least 3 characters long.",
  },
  SEQUENCE: {
    REQUIRED: "Selecting a sequence as a template is required.",
  },
  CAMPAIGN_SEQUENCE: {
    REQUIRED: "Template name is required",
  },
};

const sequence = Yup.object()
  .shape({
    id: Yup.number().required(ERROR_KEYS.SEQUENCE.REQUIRED),
    label: Yup.string().required(ERROR_KEYS.SEQUENCE.REQUIRED),
  })
  .required(ERROR_KEYS.SEQUENCE.REQUIRED)
  .nullable();

function createSequenceSchema(
  checked: CreateSequenceKeys
): Yup.AnyObjectSchema {
  const name = Yup.string()
    .required(ERROR_KEYS.NAME.REQUIRED)
    .min(3, ERROR_KEYS.NAME.INVALID);
  return Yup.object().shape({
    ...([
      CreateSequenceKeys.createNew,
      CreateSequenceKeys.copyExisting,
    ].includes(checked) && { name }),
    ...([CreateSequenceKeys.copyExisting].includes(checked) && { sequence }),
  });
}

function sequenceFormSchema(name: string): Yup.AnyObjectSchema {
  return Yup.object().shape({
    [name]: Yup.string().required(ERROR_KEYS.CAMPAIGN_SEQUENCE.REQUIRED),
  });
}

const copySequenceSchema = Yup.object().shape({
  sequence,
});

const SequenceValidations = {
  sequenceFormSchema,
  createSequenceSchema,
  copySequenceSchema,
};

export default SequenceValidations;
