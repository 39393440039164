import React from "react";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import Paper from "@mui/material/Paper";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import StopIcon from "@mui/icons-material/Stop";
import HelperTooltip from "ui-kit/components/HelperTooltip";

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    width: "100%",
    overflow: "visible",
    [theme.breakpoints.up("md")]: {
      width: 350,
    },
  },
  item: {
    "&:before": {
      padding: 0,
    },
    marginBottom: theme.spacing(2),
  },
  avatar: {
    backgroundColor: theme.palette.error.main,
  },
  arrow: {
    color: theme.palette.grey[400],
    margin: theme.spacing(-2, "auto", -1),
    position: "relative",
    zIndex: 4,
    fontSize: 14,
    lineHeight: 1,
  },
  separator: {
    flex: "auto",
    width: "100%",
  },
  action: {
    marginRight: theme.spacing(1),
  },
}));

const LastStaticAction = (): React.ReactElement => {
  const classes = useStyles();
  return (
    <TimelineItem className={classes.item}>
      <TimelineSeparator classes={{ root: classes.separator }}>
        <TimelineConnector />
        <div className={classes.arrow}>▼</div>
        <Paper variant="outlined" className={classes.card}>
          <CardHeader
            avatar={
              <Avatar className={classes.avatar}>
                <StopIcon />
              </Avatar>
            }
            title="Last step"
            titleTypographyProps={{
              variant: "subtitle2",
            }}
            subheader="Stop campaign"
            subheaderTypographyProps={{ variant: "body1" }}
            action={
              <HelperTooltip
                title="Last action stops the campaign for the prospect. Prospect becomes available for outreach by a different campaign."
                placement="top"
              />
            }
            classes={{
              action: classes.action,
            }}
          />
        </Paper>
      </TimelineSeparator>
    </TimelineItem>
  );
};

export default LastStaticAction;
