import Typography from "@mui/material/Typography";
import RouterConstants from "core/routes/constants";
import RouterUtils from "core/routes/utils";
import { errorHandler } from "core/utils/errorHandler";
import { snackbarHandler } from "core/utils/snackbarHandler";
import { IVerifyRegistrationInput } from "modules/User/models";
import UserService from "modules/User/services";
import React from "react";
import { useQueryClient } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import Loader from "ui-kit/components/Loader";

const UpdateRegistrationEmail = (): React.ReactElement | null => {
  const location = useLocation();
  const history = useHistory();
  const queryClient = useQueryClient();
  const credentials: IVerifyRegistrationInput =
    RouterUtils.getQueryParams(location);

  const [isLoading, setIsLoading] = React.useState(true);

  // TODO: currently not available

  // React.useEffect(() => {
  //   if (credentials.user_id && credentials.signature && credentials.timestamp) {
  //     UserService.verifyEmail(credentials)
  //       .then((response) => {
  //         snackbarHandler.success(response?.data?.detail);
  //         queryClient.invalidateQueries(["user"]);
  //         setIsLoading(false);
  //         history.push(RouterConstants.USER.DETAIL);
  //       })
  //       .catch((error) => {
  //         errorHandler(error.response);
  //         setIsLoading(false);
  //         history.push(RouterConstants.USER.DETAIL);
  //       });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  if (isLoading) {
    return <Loader minHeight={300} />;
  }

  return <Typography>Failed to verify the email.</Typography>;
};

export default UpdateRegistrationEmail;
