import CloseIcon from "@mui/icons-material/Close";
import {
  DialogActions,
  DialogContent,
  FormGroup,
  IconButton,
} from "@mui/material";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/styles";
import ConfigConstant from "core/constants/ConfigConstant";
import { GlobalContext } from "core/context";
import { GlobalActionType } from "core/reducers";
import RouterConstants from "core/routes/constants";
import { IErrorResponse, errorHandler } from "core/utils/errorHandler";
import { snackbarHandler } from "core/utils/snackbarHandler";
import { AccountContext } from "modules/Account/context";
import { AccountQuery } from "modules/Account/models";
import AccountService from "modules/Account/services";
import PaymentConstants from "modules/Payment/constants";
import {
  CancelationIds,
  CancelationSteps,
  ICancelReasonFormInput,
} from "modules/Payment/models";
import PaymentService from "modules/Payment/services";
import PaymentUtils from "modules/Payment/utils";
import React, { useEffect, useMemo, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import Button from "ui-kit/atoms/Button";
import Checkbox from "ui-kit/atoms/Checkbox";
import TextField from "ui-kit/atoms/TextField";

type ParamTypes = {
  subscriptionId: string;
};

const STEPS = [
  CancelationSteps.reason,
  CancelationSteps.offer,
  CancelationSteps.feedback,
  CancelationSteps.confirm,
];

const feedbackMinReq = 5;

const CancelationDialog = (): React.ReactElement => {
  const theme = useTheme();
  const { subscriptionId } = useParams<ParamTypes>();

  const {
    dispatch,
    global: { cancelDialog },
  } = React.useContext(GlobalContext);

  const [reasonField, setReason] = useState<CancelationIds | undefined>(
    undefined
  );
  // const [offerField, setOffer] = useState(undefined);
  const [feedbackField, setFeedback] = useState<string>("");

  const [currentStep, setCurrentStep] = React.useState<CancelationSteps>(
    CancelationSteps.reason
  );

  const queryClient = useQueryClient();
  const history = useHistory();
  const handleClose = () => {
    setReason(undefined);
    setFeedback("");
    setCurrentStep(CancelationSteps.reason);
    dispatch({
      type: GlobalActionType.SET_GLOBAL,
      payload: { cancelDialog: false },
    });
  };

  const {
    account: { id: accountId },
  } = React.useContext(AccountContext);

  const mutateCancelSubscription = useMutation(
    () => PaymentService.cancelSubscription(subscriptionId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["subscription", subscriptionId]);
        history.push(RouterConstants.BILLING.detail(subscriptionId));

        snackbarHandler.success("Your subscription canceled successfully.");

        handleClose();
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);
      },
    }
  );

  const mutateUpdateAccount = useMutation(
    (newValue: ICancelReasonFormInput) =>
      AccountService.updateAccountMetaData(Number(accountId), {
        meta_data: newValue,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([AccountQuery.account, accountId]);
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);
      },
    }
  );

  const onFinishCancellation = () => {
    if (!reasonField) {
      return;
    }

    const values: ICancelReasonFormInput = {
      options: reasonField,
      reason: feedbackField,
    };
    // Save cancelation option and reason
    mutateUpdateAccount.mutate(values);

    // Cancel subscription
    mutateCancelSubscription.mutate();
  };

  const handlePrevious = () => {
    const currentIndex = STEPS.indexOf(currentStep);
    if (currentIndex > 0) {
      setCurrentStep(STEPS[currentIndex - 1]);
    } else {
      // No previous step, return null or handle as needed
      handleClose();
      // setCurrentStep(null);
    }
  };

  const handleNext = () => {
    const currentIndex = STEPS.indexOf(currentStep);
    if (currentIndex < STEPS.length - 1) {
      if (currentStep === CancelationSteps.reason && !reasonField) {
        // Fail if no value selected
        snackbarHandler.warning(
          "Please select 1 reason to continue cancelation."
        );
        return;
      }

      if (
        currentStep === CancelationSteps.feedback &&
        feedbackField.length < 5
      ) {
        // Fail if no feedback is written
        snackbarHandler.warning(
          "Please least us know in few words why are you canceling."
        );
        return;
      }

      setCurrentStep(STEPS[currentIndex + 1]);
    } else {
      // No next step, return null or handle as needed
      onFinishCancellation();
    }
  };

  const item = useMemo(
    () => PaymentUtils.getCancelItem(currentStep, reasonField),
    [currentStep, reasonField]
  );

  return (
    <Dialog
      open={cancelDialog}
      onClose={handleClose}
      keepMounted
      maxWidth="xs"
      PaperProps={{ sx: { width: "100%" } }}
    >
      <Box
        py={4}
        px={4}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 1.5,
          position: "relative",
        }}
      >
        <>
          <Box
            component="img"
            sx={{ height: 25, width: "auto" }}
            src={`/${ConfigConstant.DOMAIN_NAME}/logo.svg`}
            alt={`${ConfigConstant.APP_NAME} logo`}
          />
          <Typography color="textPrimary" variant="h6" fontWeight={700}>
            {ConfigConstant.APP_NAME}
          </Typography>
          <IconButton
            sx={{ position: "absolute", right: 16 }}
            aria-label="close"
            color="inherit"
            size="medium"
            onClick={handleClose}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </>
      </Box>
      <DialogContent
        dividers
        sx={{
          backgroundColor: theme.palette.common.white,
          py: 6,
          px: 10,
        }}
      >
        {!!item && (
          <>
            <Typography
              variant="h5"
              fontWeight={600}
              mb={2.5}
              align="center"
              // lineHeight={1.25}
            >
              {item.title}
            </Typography>
            <Typography
              align="center"
              variant="body2"
              paragraph
              color="action.main"
            >
              {item.subtitle}
            </Typography>
          </>
        )}

        <Box sx={{ mt: 6, mb: 2 }}>
          {currentStep === CancelationSteps.reason && (
            <>
              <FormGroup>
                {PaymentConstants.CANCEL_OPTIONS.map((option) => {
                  const checked = reasonField === option.id;
                  return (
                    <Box
                      onClick={() => setReason(option.id as CancelationIds)}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        mb: 2,
                        cursor: "pointer",
                      }}
                    >
                      <Checkbox
                        size="small"
                        onMouseDown={(e) => e.preventDefault()}
                        checked={checked}
                      />
                      <Typography variant={checked ? "subtitle2" : "body2"}>
                        {option.label}
                      </Typography>
                    </Box>
                  );
                })}
              </FormGroup>
            </>
          )}

          {currentStep === CancelationSteps.offer && (
            <Box display="flex" justifyContent="center">
              {!!item && item.component}
            </Box>
          )}

          {currentStep === CancelationSteps.feedback && reasonField?.length && (
            <Box position="relative">
              <TextField
                multiline
                rows={4}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                  setFeedback(e.target.value);
                }}
                // Set focus instead of autoFocus
                // autoFocus didn't focus on first render
                inputRef={(input) =>
                  input &&
                  setTimeout(() => {
                    input.focus();
                  }, 0)
                }
                value={feedbackField}
                sx={{
                  width: "100%",
                  "& > .MuiOutlinedInput-root": {
                    padding: 0,
                  },
                }}
                placeholder="We read every answer..."
              />
              <Typography
                variant="caption"
                color="action.main"
                sx={{ position: "absolute", bottom: 13, right: 5 }}
              >
                {`${feedbackField?.length || 0}/${feedbackMinReq}`}
              </Typography>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between", py: 4 }}>
        <Button color="inherit" variant="outlined" onClick={handlePrevious}>
          Go Back
        </Button>
        {currentStep === CancelationSteps.offer ? (
          <Button
            color="dark"
            variant="contained"
            onClick={handleNext}
            // disabled={currentStep === CancelationSteps.reason && !value || }
          >
            Decline offer
          </Button>
        ) : (
          <Button
            color="primary"
            variant="contained"
            onClick={handleNext}
            // disabled={currentStep === CancelationSteps.reason && !value || }
          >
            {!!item ? item.btn : "Next"}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CancelationDialog;
