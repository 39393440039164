import { ChartType, IChartValues, IDateReport } from "modules/Report/models";
import { getPercentage, getValues } from "modules/Report/utils";
import React from "react";
import ReportElement from "../ReportElement";

interface ReportCountGeneratorProps {
  label: string;
  isLoading: boolean;
  count1: number | undefined;
  count2: number | undefined;
  values1: number[] | undefined;
  values2: number[] | undefined;
  DateProps: IDateReport;
  index: number;
  total: number;
  chartType?: ChartType;
  itemsPerLine: number;
}

const ReportPercentageGenerator = ({
  values1,
  values2,
  count1,
  count2,
  ...props
}: ReportCountGeneratorProps): React.ReactElement => {
  const [values, setValues] = React.useState<IChartValues>([]);
  const [count, setCount] = React.useState<string | undefined>(undefined);

  React.useEffect(() => {
    if (values1 && values2) {
      const newValues = getValues(values1, values2);
      setValues(newValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values1, values2]);

  React.useEffect(() => {
    if (count1 !== undefined && count2 !== undefined) {
      const newCount = getPercentage(count2, count1);
      setCount(newCount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count1, count2]);

  return <ReportElement {...props} count={count} values={values} />;
};

ReportPercentageGenerator.defaultProps = {
  chartType: ChartType.integer,
};

export default ReportPercentageGenerator;
