import { errorHandler, IErrorResponse } from "core/utils/errorHandler";
import { AccountContext } from "modules/Account/context";
import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { AccountQuery } from "../models";
import { AccountActionType } from "../reducers";
import AccountService from "../services";

const useUpdateAccount = () => {
  const { dispatch, account } = React.useContext(AccountContext);
  const { id: accountId } = account;
  const queryClient = useQueryClient();

  const mutateAccount = useMutation(
    (data: any) => {
      dispatch({
        type: AccountActionType.UPDATE_ACCOUNT,
        payload: {
          ...account,
          ...data,
        },
      });

      return AccountService.updateAccount(accountId, data);
    },
    {
      onSuccess: ({ data }) => {
        queryClient.invalidateQueries([AccountQuery.account, accountId]);
        // queryClient.invalidateQueries([variant, accountId]);
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);
      },
    }
  );

  return mutateAccount;
};

export default useUpdateAccount;
