import ConfigConstant from "core/constants/ConfigConstant";
import { createTableCell, TableCellProps } from "core/utils/tableHandler";
import { IPerson } from "modules/Person/models";
import { getPersonName } from "modules/Person/utils";
import { ISearchResult } from "modules/Search/models";
import SearchUtils from "modules/Search/utils";
import TableBodyCell from "ui-kit/components/TableBodyCell";

const generateProfileLink = (
  person: IPerson | undefined
): string | undefined => {
  if (!person) {
    return undefined;
  }
  if (person.public_identifier) {
    return `${ConfigConstant.LINKEDIN_ROOT}in/${person.public_identifier}`;
  }
  if (person.id_entity_urn) {
    return `${ConfigConstant.LINKEDIN_ROOT}in/${person.id_entity_urn}`;
  }
  return undefined;
};

const getProfileName = (
  search_result: ISearchResult | undefined,
  person: IPerson | null | undefined
) => {
  let name = SearchUtils.getSearchResultOnlyName(search_result);

  // Make search result name a default, if exists
  if (!!name) {
    return name;
  }

  // If can't create a name from search result, try person
  if (!!person) {
    return getPersonName(person);
  }

  // Last resort, display profile url
  if (!!search_result?.id_profile_url) {
    return search_result.id_profile_url;
  }

  return name;
};

const getProfileCell = (
  search_result: ISearchResult | undefined,
  person: IPerson | null | undefined,
  handleOnClick: () => void
): TableCellProps => {
  const name = getProfileName(search_result, person);
  return createTableCell(name, handleOnClick, "main", TableBodyCell);
};

export { getProfileCell, getProfileName, generateProfileLink };
