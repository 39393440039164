import axios from "axios";
import { ITokenInput, IUser } from "../models";
import UserConstants from "../constants";

export const anonymousUser = {
  anonymous: true,
  token: "",
  email: "",
  name: "",
  username: "",
  frontend_state: {},
  id: 0,
};

export const storeUser = (user?: IUser): void => {
  if (!user) {
    localStorage.removeItem(UserConstants.STORAGE_KEY);
    return;
  }
  if (!window.localStorage) return;
  localStorage.setItem(UserConstants.STORAGE_KEY, JSON.stringify(user));
};

export const getUser = (): IUser => {
  const storedUser =
    window &&
    window.localStorage &&
    localStorage.getItem(UserConstants.STORAGE_KEY);
  if (storedUser) return JSON.parse(storedUser);
  return anonymousUser;
};

export const getFirstName = (name: string): string => {
  return name.split(" ")?.[0];
};

export const setToken = (token: ITokenInput): void => {
  axios.defaults.headers.common.Authorization = `Token ${token}`;
};

export const removeToken = (): void => {
  axios.defaults.headers.common.Authorization = "";
};
