import { Avatar } from "@mui/material";
import SequenceConstants from "modules/Sequence/constants";
import React from "react";

interface AlphabetBadgeProps {
  index: number;
  size?: number;
}

const defaultSize = 34.75;

const AlphabetBadge = ({
  index,
  size = defaultSize,
}: AlphabetBadgeProps): React.ReactElement | null => {
  const item = SequenceConstants.ALPHABET[index];

  if (!item) return null;

  return (
    <Avatar
      sx={{
        bgcolor: item.color,
        width: size,
        height: size,
        fontSize: size - 4,
      }}
      variant="rounded"
    >
      {item.label}
    </Avatar>
  );
};

export default AlphabetBadge;
