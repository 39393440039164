import { yupResolver } from "@hookform/resolvers/yup";
import Grid from "@mui/material/Grid";
import { Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import ElementField from "core/components/ElementField";
import ConfigConstant from "core/constants/ConfigConstant";
import RouterConstants from "core/routes/constants";
import RouterUtils from "core/routes/utils";
import { errorHandler } from "core/utils/errorHandler";
import GoogleSignIn from "modules/User/components/GoogleSignIn";
import { ISignUpInput } from "modules/User/models";
import UserService from "modules/User/services";
import UserValidation from "modules/User/validations";
import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link, useHistory, useLocation } from "react-router-dom";
import Button from "ui-kit/atoms/Button";
import BackgroundOverlay from "ui-kit/components/BackgroungOverlay";
import ClientLogoItem from "ui-kit/components/ClientLogoItem";
import DividerText from "ui-kit/components/DividerText";
import PasswordField from "ui-kit/components/PasswordField";

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    marginBottom: theme.spacing(6),
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(4),
    },
    width: "100%",
  },
  heading: {
    fontWeight: 700,
  },
  dialogContent: {
    display: "flex",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.up("md")]: {
      flexDirection: "row-reverse",
    },
  },
  form: {
    padding: theme.spacing(9),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flex: 1,
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(6, 2),
    },
  },
  fields: {
    width: "100%",
  },
  footer: {
    margin: theme.spacing(5, 0),
    color: theme.app.palette.action.color,
    width: "100%",
    fontSize: 12,
    [theme.breakpoints.down("md")]: {
      fontSize: 11,
    },
    zIndex: 2,
  },
  footerBox: {
    textAlign: "center",
    marginTop: theme.spacing(6),
    color: theme.app.palette.action.color,
    width: "100%",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  hiddenLink: {
    color: "inherit",
    textDecoration: "underline",
  },
  banner: {
    [theme.breakpoints.up("md")]: {
      backgroundColor: theme.palette.background.default,
      backgroundImage: "linear-gradient(90deg,#ebeeff 97%,#e1e7fe 100%)",
      width: 460,
      padding: theme.spacing(11, 10, 9),
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    position: "relative",
  },
  bannerImg: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  logoParent: {
    marginBottom: theme.spacing(2),
  },
  logoImg: {
    height: 20,
  },
  bannerBox: {
    width: "100%",
    textAlign: "center",

    [theme.breakpoints.up("md")]: {
      position: "absolute",
      bottom: 80,
    },
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(2, 0, 6),
    },
  },
  bannerText: {
    fontSize: "0.825rem",
    lineHeight: 1.5,
    fontWeight: 700,
    margin: "auto",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      maxWidth: 280,
    },
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
  },
  flexField: {
    display: "flex",
    gap: 8,
  },
  reviewAuthor: {
    margin: theme.spacing(4, 0),
    display: "flex",
    alignItems: "center",
    gap: 20,
  },
  reviewAuthorImg: {
    width: 50,
    height: 50,
    borderRadius: 50,
    backgroundColor: theme.palette.action.focus,
  },
  reviewName: {
    display: "flex",
    flexDirection: "column",
  },
  clientImg: {
    filter: "brightness(0)",
    opacity: ".6",
    width: "100%",
  },
}));

const sizeCoefficient = 5;

const SignUp = (): React.ReactElement => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const params = RouterUtils.parseQueryParams(location);
  const schema = UserValidation.signUpSchema();

  const { control, errors, handleSubmit, setError } = useForm<ISignUpInput>({
    resolver: yupResolver(schema),
    defaultValues: params,
  });

  const onSubmit: SubmitHandler<ISignUpInput> = ({
    password,
    ...credentials
  }) => {
    const fullCredentials = {
      ...credentials,
      name: `${credentials.first_name} ${credentials.last_name}`,
      password1: password,
      password2: password,
      username: credentials.email,
    };

    UserService.signUp(fullCredentials)
      .then(() => {
        history.push(
          `${RouterConstants.USER.REGISTER_EMAIL}?email=${fullCredentials.email}`
        );
      })
      .catch((error) => {
        errorHandler(error.response, setError);
      });
  };

  const inputProps = {
    control,
    fullWidth: true,
    InputProps: {
      autoComplete: "new-password",
      style: {
        backgroundColor: "white",
      },
    },
  };

  const text = params.email
    ? `Join your team on ${ConfigConstant.APP_NAME}`
    : `Try ${ConfigConstant.APP_NAME} for free`;

  const subtext = params.email
    ? "Creating an account using your invite email."
    : "No risk. No obligations. No card required.";

  return (
    <BackgroundOverlay>
      <div className={classes.dialogContent}>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.header}>
            <Typography
              color="textPrimary"
              sx={{ textAlign: "center", fontWeight: 700, fontSize: 16 }}
              mb={2}
            >
              {text}
            </Typography>
            <Typography
              color="textPrimary"
              sx={{ textAlign: "center", fontSize: 12 }}
            >
              {subtext}
            </Typography>
          </div>

          <GoogleSignIn>Start free with Google</GoogleSignIn>

          <DividerText text="OR" />
          <div className={classes.fields}>
            {/* If defaultEmail, disable email input */}
            {params.email && (
              <ElementField
                error={errors.email}
                label="Email address"
                name="email"
                {...inputProps}
                ElementProps={{ type: "email" }}
              />
            )}
            <div className={classes.flexField}>
              <ElementField
                error={errors.first_name}
                label="First name"
                name="first_name"
                {...inputProps}
              />
              <ElementField
                error={errors.last_name}
                label="Last name"
                name="last_name"
                {...inputProps}
              />
            </div>

            {/* If defaultEmail, disable email input */}
            {!params.email && (
              <ElementField
                error={errors.email}
                label="Email address"
                name="email"
                {...inputProps}
                ElementProps={{ type: "email" }}
              />
            )}
            <ElementField
              component={PasswordField}
              error={errors.password}
              label="Password"
              name="password"
              {...inputProps}
              ElementProps={{
                validation: true,
              }}
            />
          </div>

          <Button
            fullWidth
            variant="contained"
            color="primary"
            type="submit"
            size="large"
            sx={{ mt: 2 }}
          >
            Start free with email
          </Button>
          <Typography
            className={classes.footer}
            component="p"
            color="textSecondary"
          >
            By signing up, you agree to{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              className={classes.hiddenLink}
              href={RouterConstants.LANDING.TERMS}
            >
              Terms of Service
            </a>{" "}
            and{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              className={classes.hiddenLink}
              href={RouterConstants.LANDING.GDPR}
            >
              Privacy Policy
            </a>
            .
          </Typography>
          <div className={classes.footerBox}>
            <Typography color="inherit" variant="body2">
              Already user?{" "}
              <Link to={RouterConstants.USER.SIGN_IN}>Sign in</Link>
            </Typography>
          </div>
        </form>
        <div className={classes.banner}>
          <div>
            <Typography sx={{ fontStyle: "italic", lineHeight: "30px" }}>
              {ConfigConstant.APP_NAME} allows us to save time on LinkedIn
              outreach. Their support capabilities are excellent. <br />I
              recommend using this software.
            </Typography>
            <div className={classes.reviewAuthor}>
              <img
                src="/maxime.png"
                alt="quote author"
                className={classes.reviewAuthorImg}
              />
              <div className={classes.reviewName}>
                <Typography variant="subtitle2" color="textPrimary">
                  Maxime B.
                </Typography>
                <Typography variant="body2" color="action.color">
                  Founder, Digital Agency
                </Typography>
              </div>
            </div>
          </div>
          <div>
            <Typography variant="subtitle2" color="textPrimary">
              Trusted by top agencies, sales teams and recruiters.
            </Typography>

            <Grid container spacing={1}>
              <ClientLogoItem>
                <img
                  className={classes.clientImg}
                  src="/marketstar.svg"
                  alt="MarketStar"
                  style={{ width: "100%", maxHeight: 68 - sizeCoefficient }}
                />
              </ClientLogoItem>
              <ClientLogoItem>
                <img
                  className={classes.clientImg}
                  src="/wynter.svg"
                  alt="Wynter"
                  style={{ width: "100%", maxHeight: 41 - sizeCoefficient }}
                />
              </ClientLogoItem>

              <ClientLogoItem>
                <img
                  className={classes.clientImg}
                  src="/uptimerobot.svg"
                  alt="Uptime Robot"
                  style={{ width: "100%", height: 48 }}
                />
              </ClientLogoItem>

              <ClientLogoItem>
                <img
                  className={classes.clientImg}
                  src="/modernoutbound.svg"
                  alt="Modern Outbound"
                  style={{ width: "100%", maxHeight: 31 - sizeCoefficient }}
                />
              </ClientLogoItem>

              <ClientLogoItem>
                <img
                  className={classes.clientImg}
                  src="/asora.svg"
                  alt="Asora"
                  style={{ width: "100%", maxHeight: 20 - sizeCoefficient }}
                />
              </ClientLogoItem>

              <ClientLogoItem>
                <img
                  className={classes.clientImg}
                  src="/datamole.svg"
                  alt="Datamole"
                  style={{ width: "100%", maxHeight: 17 - sizeCoefficient }}
                />
              </ClientLogoItem>
            </Grid>
          </div>
        </div>
      </div>
    </BackgroundOverlay>
  );
};

export default SignUp;
