import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import Badge from "@mui/material/Badge";
import ConfigConstant from "core/constants/ConfigConstant";
import { GlobalContext } from "core/context";
import ButtonGroupFilter from "core/filters/atoms/ButtonGroupFilter";
import AccountFilter from "core/filters/components/AccountFilter";
import { GlobalActionType } from "core/reducers";
import RouterConstants from "core/routes/constants";
import RouterUtils from "core/routes/utils";
import { formatTimeAgo } from "core/utils/dateHandler";
import { errorHandler, IErrorResponse } from "core/utils/errorHandler";
import { createTableCell } from "core/utils/tableHandler";
import { AccountContext } from "modules/Account/context";
import { AccountQuery, AccountTabs } from "modules/Account/models";
import AccountService from "modules/Account/services";
import { getAccountName } from "modules/Account/utils";
import NotificationService from "modules/Notification/services";
import { handleNotificationClick } from "modules/Notification/utils";
import React, { useContext, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import Button from "ui-kit/atoms/Button";
import PageHeader from "ui-kit/components/PageHeader";
import Table, { TableVariant } from "ui-kit/components/Table";
import TableBodyCell from "ui-kit/components/TableBodyCell";

const title = "Notifications";
const heads = [
  {
    id: "unread",
    percentage: true,
    width: 1.5,
    label: "",
  },
  {
    id: "description",
    percentage: true,
    width: 64.5,
    label: "Description",
  },
  {
    id: "account",
    percentage: true,
    width: 18,
    label: "Account",
  },
  {
    id: "timestamp",
    percentage: true,
    width: 16,
    label: "Date",
  },
];

const NotificationList = (): React.ReactElement => {
  const {
    dispatch,
    account: { id: currentAccountId },
  } = useContext(AccountContext);
  const {
    dispatch: dispatchGlobal,
    global: { selected },
  } = React.useContext(GlobalContext);

  const resetSelected = () => {
    dispatchGlobal({
      type: GlobalActionType.SET_GLOBAL,
      payload: { selected: [] },
    });
  };

  const queryClient = useQueryClient();
  const [page, setPage] = useState<number>(ConfigConstant.INITIAL_PAGE);
  const history = useHistory();
  const location = useLocation();

  const { unread } = RouterUtils.getQueryParams(location);
  const { accountId } = RouterUtils.parseQueryParams(location);
  const accountQuery = RouterUtils.generateFilterNameQuery(
    accountId,
    "actor_object_id"
  );

  const fetchNotifications = async (p: number) => {
    try {
      const { data } = await NotificationService.fetchNotifications(
        p,
        unread,
        accountQuery
      );
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };
  const { data, isFetching } = useQuery(
    ["notifications", page, unread, accountId],
    () => fetchNotifications(page),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  // Mark notification as read (unread: false)
  const mutateMarkAllAsRead = useMutation(
    () => NotificationService.updateAllNotificationsMarkAsRead(accountQuery),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["notifications"]);
        queryClient.invalidateQueries(["notifications-recent"]);
        queryClient.invalidateQueries(["notifications-unread-all"]);
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);
      },
    }
  );

  // Mark notification as read (unread: false)
  const mutateMarkIndividualAsRead = useMutation(
    (notificationId: number) =>
      NotificationService.updateNotification({
        id: notificationId,
        unread: false,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["notifications", page]);
        queryClient.invalidateQueries(["notifications-recent"]);
        queryClient.invalidateQueries(["notifications-unread-all"]);
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);
      },
    }
  );

  const handleNotificationAction = () => {
    if (
      selected.length === 0 &&
      // eslint-disable-next-line no-alert
      window.confirm("Mark all unread notifications as read?")
    ) {
      return mutateMarkAllAsRead.mutate();
    }
    return selected.forEach(async ({ name }) => {
      await mutateMarkIndividualAsRead.mutate(Number(name));
      resetSelected();
    });
  };

  const { data: accountData } = useQuery(
    AccountQuery.all,
    async () => {
      try {
        const response = await AccountService.fetchAllAccounts();
        return response.data;
      } catch (err) {
        throw new Error(String(err));
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  // Only show account select if more accounts connected
  const accountCount = accountData?.count || 1;

  return (
    <>
      <PageHeader
        title={title}
        body="Here you can view all notifications for added LinkedIn accounts."
        selectedActions={
          <Badge
            sx={{ mr: 2.5 }}
            color="secondary"
            badgeContent={selected.length || "All"}
          >
            <Button
              variant="outlined"
              color="inherit"
              size="small"
              type="button"
              startIcon={<MarkEmailReadIcon />}
              onClick={handleNotificationAction}
            >
              Mark as read
            </Button>
          </Badge>
        }
        filterProps
      />
      <Table
        title={title}
        filters={
          <>
            <ButtonGroupFilter
              id="unread"
              options={[
                { id: "false", name: "All", query: {} },
                { id: "true", name: "Unread", query: { unread: true } },
              ]}
            />
            {accountCount > 1 && <AccountFilter multiple />}
          </>
        }
        heads={heads}
        rows={
          data
            ? data.results.map((notification) => {
                const account = accountData?.results.find(
                  ({ login }) => login === notification?.actor?.login
                );
                const handleOnClick = () => {
                  mutateMarkIndividualAsRead.mutate(notification.id);
                  handleNotificationClick(
                    notification,
                    history,
                    dispatch,
                    currentAccountId
                  );
                };

                return {
                  name: notification.id.toString(),
                  data: [
                    createTableCell(
                      notification.unread ? (
                        <Badge color="secondary" variant="dot" />
                      ) : undefined,
                      "",
                      "element",
                      TableBodyCell
                    ),
                    createTableCell(
                      notification.description,
                      handleOnClick,
                      notification.unread ? "main" : "default",
                      TableBodyCell
                    ),
                    createTableCell(
                      getAccountName(account),
                      account
                        ? () => {
                            handleOnClick();
                            history.push(
                              RouterConstants.ACCOUNT.detail(
                                account.id,
                                AccountTabs.limits
                              )
                            );
                          }
                        : null,
                      "internal",
                      TableBodyCell
                    ),
                    createTableCell(
                      formatTimeAgo(notification.timestamp),
                      handleOnClick,
                      "default",
                      TableBodyCell
                    ),
                  ],
                };
              })
            : []
        }
        count={data?.count || 0}
        // INITIAL_PAGE starts at 1, but Pagination starts at 0
        page={(data?.current || page) - 1}
        setPage={setPage}
        isFetching={isFetching}
        variant={[TableVariant.checkable]}
      />
    </>
  );
};

export default NotificationList;
