import React from "react";
import { useQueryClient, useMutation } from "react-query";
import { errorHandler, IErrorResponse } from "core/utils/errorHandler";
import { snackbarHandler } from "core/utils/snackbarHandler";
import {
  IAddressBookItemFormInput,
  IAddressBookItemsFormValues,
} from "modules/AddressBook/models";
import AddressBookService from "modules/AddressBook/services";
import NewAddressBookItemsDialog from "modules/AddressBook/components/NewAddressBookItemsDialog";

interface CreateAccountAddressBookProps {
  open: boolean;
  handleClose: () => void;
  addressBookId: number;
}

const CreateAddressBookItems = ({
  open,
  handleClose,
  addressBookId,
}: CreateAccountAddressBookProps): React.ReactElement => {
  const queryClient = useQueryClient();

  const mutateBulkAddressBookItems = useMutation(
    (data: IAddressBookItemsFormValues) =>
      AddressBookService.createAddressBookItems(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["addressBook-items"]);
        snackbarHandler.success("Successfully added!");
        handleClose();
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);
      },
    }
  );

  const onSubmit = ({ people }: IAddressBookItemFormInput) => {
    mutateBulkAddressBookItems.mutate({
      persons: people.map((person) => person.id),
      address_book: addressBookId,
    });
  };

  return (
    <NewAddressBookItemsDialog
      open={open}
      handleClose={handleClose}
      handleOnSubmit={onSubmit}
    />
  );
};

export default CreateAddressBookItems;
