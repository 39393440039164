import React, { useContext } from "react";
import { useQuery } from "react-query";
import AutocompleteSingleFilter from "core/filters/atoms/AutocompleteSingleFilter";
import AutocompleteMultipleFilter from "core/filters/atoms/AutocompleteMultipleFilter";
import CampaignService from "modules/Campaign/services";
import { AccountContext } from "modules/Account/context";
import FilterButton from "ui-kit/components/FilterButton";
import { CampaignQuery } from "modules/Campaign/models";

interface CampaignFilterProps {
  multiple?: boolean;
}

const CampaignFilter = ({
  multiple = false,
}: CampaignFilterProps): React.ReactElement => {
  const {
    account: { id: accountId },
  } = useContext(AccountContext);

  const fetchAllCampaigns = async () => {
    try {
      const { data } = await CampaignService.fetchAllAccountCampaigns(
        accountId
      );
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };
  const { data, isLoading } = useQuery([CampaignQuery.campaigns_all], () =>
    fetchAllCampaigns()
  );

  const props = {
    id: "campaignId",
    options: data?.results,
    isLoading,
    label: "Campaign",
  };

  return (
    <FilterButton {...props} data={data}>
      {multiple ? (
        <AutocompleteMultipleFilter {...props} />
      ) : (
        <AutocompleteSingleFilter {...props} />
      )}
    </FilterButton>
  );
};

CampaignFilter.defaultProps = {
  multiple: false,
};

export default CampaignFilter;
