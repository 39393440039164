import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import { Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import DateConstant from "core/constants/DateConstant";
import StrategyConstant, {
  MATCH_CATEGORY_TO_VARIANT_OBJ,
} from "core/constants/StrategyConstant";
import RouterConstants from "core/routes/constants";
import { formatDateTimeToTime } from "core/utils/dateHandler";
import { getProfileName } from "core/utils/profileHandler";
import { CampaignDetailTabs } from "modules/Campaign/models";
import { IInteraction } from "modules/Interaction/models";
import { getInteractionErrorMessage } from "modules/Interaction/utils";
import React from "react";
import { Link } from "react-router-dom";

const DATE_LOCALE_WIDTH_DIFF = DateConstant.LOCALE_24H ? 0 : 25;

const useStyles = makeStyles((theme: Theme) => ({
  item: {
    alignItems: "baseline",
    justifyContent: "flex-start",
  },
  content: {
    display: "flex",
    flexDirection: "column",
  },
  oppositeContent: {
    flex: "none",
    padding: theme.spacing(1.5, 2.5),
  },
  time: {
    fontSize: 12,
    width: 35 + DATE_LOCALE_WIDTH_DIFF,
    color: theme.app.palette.action.color,
  },
  title: {
    fontSize: 13,
  },
  message: {
    padding: theme.spacing(2, 4),
    display: "inline-block",
    fontSize: 12,
    color: theme.app.palette.action.color,
    textAlign: "left",
    backgroundColor: theme.app.palette.background.default,
    margin: theme.spacing(1, 0),
    borderRadius: theme.spacing(0, 5, 5, 5),
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "fit-content",
    maxWidth: 450 - DATE_LOCALE_WIDTH_DIFF,
    [theme.breakpoints.only("lg")]: {
      maxWidth: 275 - DATE_LOCALE_WIDTH_DIFF,
    },
    [theme.breakpoints.only("md")]: {
      maxWidth: 180 - DATE_LOCALE_WIDTH_DIFF,
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  separator: {
    height: "100%",
  },
  connector: {
    backgroundColor: theme.app.palette.shadow.secondary,
  },
}));

interface RecentActivityItemProps {
  interaction: IInteraction;
  isLast?: boolean;
}

const RecentActivityItem = ({
  interaction,
  isLast,
}: RecentActivityItemProps): React.ReactElement | null => {
  const classes = useStyles();

  const variantName = MATCH_CATEGORY_TO_VARIANT_OBJ[interaction.category];
  const strategyObj = StrategyConstant.STRATEGY_OBJ[variantName];
  const name = getProfileName(interaction.search_result, interaction.person);

  if (!strategyObj) return null;
  let actorElement: null | React.ReactElement | string = null;

  if (interaction.person) {
    actorElement = (
      <Link to={RouterConstants.INBOX.detail(interaction.person?.id)}>
        {name}
      </Link>
    );
  }

  if (!interaction.person && interaction.search_result) {
    actorElement = name;
  }

  if (!actorElement) return null;

  return (
    <TimelineItem className={classes.item}>
      <TimelineOppositeContent className={classes.oppositeContent}>
        <Typography className={classes.time}>
          {formatDateTimeToTime(interaction.platform_created_at)}
        </Typography>
      </TimelineOppositeContent>
      <TimelineSeparator className={classes.separator}>
        <TimelineDot
          sx={{
            backgroundColor: strategyObj.color,
          }}
        />
        {!isLast && <TimelineConnector className={classes.connector} />}
      </TimelineSeparator>
      <TimelineContent className={classes.content}>
        <Typography className={classes.title} color="textSecondary">
          {interaction.account_is_actor ? (
            <>
              {strategyObj.label.account_is_actor}
              {strategyObj.activity.intro}
              {actorElement}
              {interaction.campaign ? (
                <>
                  {strategyObj.activity.campaign}
                  <Link
                    to={RouterConstants.CAMPAIGN.detail(
                      interaction.campaign.id,
                      CampaignDetailTabs.audience
                    )}
                  >
                    {interaction.campaign.name}
                  </Link>
                </>
              ) : null}
              .
            </>
          ) : (
            <>
              {actorElement}{" "}
              {strategyObj.label.account_is_not_actor.toLowerCase()}
              {interaction.campaign ? (
                <>
                  {strategyObj.activity.campaign}
                  <Link
                    to={RouterConstants.CAMPAIGN.detail(
                      interaction.campaign.id,
                      CampaignDetailTabs.audience
                    )}
                  >
                    {interaction.campaign.name}
                  </Link>
                </>
              ) : null}
              .
            </>
          )}
        </Typography>
        {interaction.data?.message && (
          <Typography className={classes.message}>
            {interaction.data.message}
          </Typography>
        )}
        {(interaction.failed || interaction.other_issue?.category) && (
          <Typography variant="caption" color="error">
            {getInteractionErrorMessage(interaction)}
          </Typography>
        )}
      </TimelineContent>
    </TimelineItem>
  );
};

RecentActivityItem.defaultProps = {
  isLast: false,
};

export default RecentActivityItem;
