import { Box, Button, ButtonProps } from "@mui/material";

const buttonSx = {
  paddingTop: 0,
  paddingBottom: 0,
  paddingRight: 0.5,
  paddingLeft: 0.5,
  marginLeft: -0.5,
  minWidth: "auto",
  lineHeight: 1.43,
};

const boxSx = { display: "flex", alignItems: "center", gap: 0 };

interface TextButtonBoxProps {
  startText?: string;
  endText?: string;
  buttonProps: ButtonProps;
}

const TextButtonBox = ({
  startText,
  endText,
  buttonProps: { children, ...rest },
}: TextButtonBoxProps) => {
  return (
    <Box sx={boxSx}>
      {startText}
      <Button sx={buttonSx} variant="text" color="primary" {...rest}>
        {children}
      </Button>{" "}
      {endText}
    </Box>
  );
};

export default TextButtonBox;
