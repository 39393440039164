import { Alert, AlertTitle } from "@mui/material";
import Box from "@mui/material/Box";
import ElementField from "core/components/ElementField";
import SettingsItem from "core/components/SettingsItem";
import RouterConstants from "core/routes/constants";
import { createComponents } from "core/utils/componentsHandler";
import first from "lodash/first";
import { AccountContext } from "modules/Account/context";
import {
  AccountLogStrategyInputName,
  AccountQuery,
  IAccountLog,
  IAccountLogStatuses,
  IAccountLogStrategy,
  ScrapingStatusValues,
} from "modules/Account/models";
import { AccountActionType } from "modules/Account/reducers";
import AccountService from "modules/Account/services";
import { getLogContent } from "modules/Account/utils";
import React, { useContext, useMemo } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";

const getSubmitValue = (currentLogContent: IAccountLogStrategy): string => {
  if (
    currentLogContent.disableInput &&
    typeof currentLogContent.key === "string"
  ) {
    return currentLogContent.key;
  }

  return "Confirm";
};

// If queried less than 20 = 5 secs refetch
// If queried more or 20 = 30 secs
const getQueryRefetchTime = (count: number) => (count < 20 ? 5000 : 30000);

const SyncAccount = (): React.ReactElement | null => {
  const {
    dispatch,
    account: { id: accountId, person: isSyncedAccount },
  } = useContext(AccountContext);

  const queryClient = useQueryClient();
  const history = useHistory();
  const [recentLog, setRecentLog] = React.useState<IAccountLog | undefined>(
    undefined
  );

  const [refetchValidator, setRefetchValidator] =
    React.useState<boolean>(false);

  const [open, setOpen] = React.useState<boolean>(false);

  const fetchAccount = React.useCallback(async () => {
    try {
      const { data } = await AccountService.fetchAccount(accountId);
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  }, [accountId]);

  // If synced account + recent log is successful login action, we don't need to refetch
  // If anything fails, keep reloading
  React.useEffect(() => {
    const newRefetchValidator = !!(
      isSyncedAccount &&
      !!recentLog?.status &&
      [IAccountLogStatuses.lo].includes(recentLog.status)
    );

    if (refetchValidator !== newRefetchValidator) {
      setRefetchValidator(newRefetchValidator);
    }
  }, [refetchValidator, isSyncedAccount, recentLog?.status]);

  const { data: account } = useQuery(
    [AccountQuery.new, accountId],
    async () => fetchAccount(),
    {
      keepPreviousData: true,
      enabled: !!accountId && !isSyncedAccount,
      refetchInterval: (_data, query) =>
        refetchValidator
          ? false
          : getQueryRefetchTime(query.state.dataUpdateCount),
    }
  );

  const fetchLastAccountLog = async () => {
    try {
      const { data } = await AccountService.fetchLastAccountLog(accountId);
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };

  const { data, isLoading } = useQuery(
    [AccountQuery.last_log, accountId],
    () => fetchLastAccountLog(),
    {
      keepPreviousData: true,
      enabled: !!accountId,
      refetchInterval: (_data, query) =>
        refetchValidator
          ? false
          : getQueryRefetchTime(query.state.dataUpdateCount),
    }
  );

  // When synced account, navigate to dashboard
  React.useEffect(() => {
    if (!!account?.person && recentLog?.status === IAccountLogStatuses.ls) {
      // Update current account
      dispatch({
        type: AccountActionType.SET_ACCOUNT,
        payload: account,
      });

      queryClient.invalidateQueries(AccountQuery.all);
      queryClient.invalidateQueries(AccountQuery.last_log);

      // Go to dashboard
      history.push(RouterConstants.ROOT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recentLog, account?.person]);

  React.useEffect(() => {
    if (data?.results) {
      setRecentLog(first(data.results));
    }
  }, [data?.results]);

  const currentLogContent = useMemo(
    () => getLogContent(recentLog, account?.login),
    [recentLog, account?.login]
  );

  const isShowLoader = useMemo(() => {
    return (
      !currentLogContent &&
      !!recentLog?.status &&
      [
        IAccountLogStatuses.lf,
        IAccountLogStatuses.ls,
        IAccountLogStatuses.lo,
      ].includes(recentLog.status)
    );
  }, [currentLogContent, recentLog?.status]);

  // Only on the first run
  const firstUpdate = React.useRef(true);

  // Run only when accountID changes to refresh firstUpdate
  // Not on the first update
  const firstRefresh = React.useRef(true);
  React.useEffect(() => {
    if (!firstRefresh.current) {
      return;
    }

    firstUpdate.current = true;

    // Update firstRefresh to not run again
    firstRefresh.current = false;
  }, [accountId]);

  // Show dialog if recentLog is valid for current account and one of the following is true:
  // - action is loading
  // - action needs to be input
  // - login failed && account.stopped
  React.useEffect(() => {
    if (!firstUpdate.current) {
      return;
    }

    const isShowDialog =
      recentLog?.account === accountId &&
      (!!currentLogContent ||
        account?.scraping_status === ScrapingStatusValues.stopped);

    if (open !== isShowDialog) {
      setOpen(isShowDialog);

      // Update firstUpdate to not run again
      firstUpdate.current = false;
    }
  }, [
    open,
    currentLogContent,
    recentLog?.status,
    recentLog?.account,
    account?.scraping_status,
    accountId,
  ]);

  // Do not show if just loading data
  if (isLoading) {
    return null;
  }

  // Do not show if it's not supposed to be shown
  if (!open) {
    return null;
  }

  let title = "";
  let subtitle: string | React.ReactElement = "";

  if (isShowLoader) {
    title = "Setting things up for you...";
    subtitle =
      "Please wait while we sync your LinkedIn account. It usually takes only a few minutes.";
  }

  if (!!currentLogContent) {
    title = currentLogContent.label;
    subtitle = currentLogContent.description;
  }

  return (
    <Alert severity="error" sx={{ marginBottom: 6 }}>
      <AlertTitle>{title}</AlertTitle>
      {subtitle}
      {!!currentLogContent && (
        <Box sx={{ maxWidth: 400 }}>
          <SettingsItem
            FormProps={{
              id: recentLog?.id,
              name: [AccountQuery.last_log, accountId],
              func: AccountService.updateAccountLog,
              format: (d: IAccountLog) => d,
              defaultValues: {
                user_input: {
                  [AccountLogStrategyInputName[currentLogContent.strategy]]:
                    recentLog?.user_input || "", // empty string makes the input controlled
                },
              },
            }}
            {...(!currentLogContent.disableInput && {
              components: Array.isArray(currentLogContent.key)
                ? currentLogContent.key.map((item) =>
                    createComponents(ElementField, `user_input.${item.id}`, {
                      ...(currentLogContent.input && {
                        component: currentLogContent.input,
                      }),
                      InputProps: {
                        autoFocus: true,
                        placeholder: item.label,
                        fullWidth: true,
                        sx: { backgroundColor: "#FFF" },
                      },
                    })
                  )
                : [
                    createComponents(
                      ElementField,
                      `user_input.${
                        AccountLogStrategyInputName[currentLogContent.strategy]
                      }`,
                      {
                        ...(currentLogContent.input && {
                          component: currentLogContent.input,
                        }),
                        InputProps: {
                          autoFocus: true,
                          placeholder: currentLogContent.key,
                          fullWidth: true,
                          sx: { backgroundColor: "#FFF" },
                        },
                      }
                    ),
                  ],
            })}
            SubmitButtonProps={{
              color: "primary",
              value: getSubmitValue(currentLogContent),
              removeDisabled: currentLogContent.disableInput,
              ButtonProps: {
                // fullWidth: true,
                size: "medium",
                sx: { paddingLeft: 6, paddingRight: 6 },
              },
            }}
            hiddenDivider
          />
        </Box>
      )}
    </Alert>
  );
};

export default SyncAccount;
