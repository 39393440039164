import React from "react";
import slugify from "slugify";
import clsx from "clsx";
import { Link, useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Button from "@mui/material/Button";
import { Badge } from "@mui/material";

export const useTabStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: theme.app.constants.filter.topMargin,
  },
  tab: {
    textTransform: "capitalize",
    fontSize: 14,
    fontWeight: 500,
    color: theme.app.palette.action.color,
    borderRadius: 0,
    "&:hover": {
      color: theme.app.palette.text.secondary,
    },
  },
  tabBtn: {
    paddingRight: 0,
    paddingLeft: 0,
    minWidth: 0,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  tabActive: {
    color: theme.palette.primary.main,
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    paddingBottom: 7,
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  tabBtnBadge: {
    paddingRight: theme.spacing(8),
  },
  badge: {
    top: 12,
    right: -17,
  },
  badgeDisabled: {
    background: theme.palette.action.focus,
  },
}));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type IComponent = any;

export interface CardTabPropsInput {
  name: string;
  content: React.FunctionComponent<IComponent> | undefined;
  slug: string;
  badgeContent?: number;
}

export interface CardTabsProps extends IComponent {
  tabs: CardTabPropsInput[];
  isLoading: boolean;
  variant?: "small" | "medium";
  showBadge?: boolean;
}

export const createTabs = (
  name: CardTabPropsInput["name"],
  content: CardTabPropsInput["content"] | undefined,
  badgeContent?: number
): CardTabPropsInput => ({
  name,
  content,
  slug: slugify(name.toLowerCase()),
  badgeContent,
});

const defaultTabIndex = 0;

type ParamTypes = {
  tabName: string;
};

const CardTabs = ({
  tabs,
  getBaseUrl,
  isLoading,
  variant,
  showBadge = false,
  ...rest
}: CardTabsProps): React.ReactElement => {
  const classes = useTabStyles();
  const { tabName } = useParams<ParamTypes>();

  const activeTab = tabs.find((tab) => tabName === tab.slug);
  const Component = activeTab?.content;
  const currentTabIndex = tabName
    ? tabs.map((tab) => tab.slug).indexOf(activeTab?.slug || "")
    : defaultTabIndex;

  const currentTab = tabs[currentTabIndex];

  return (
    <>
      <div className={classes.root}>
        <Tabs
          value={currentTabIndex}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
        >
          {tabs.map((tab, index) => {
            const current = index === currentTabIndex;
            const badgeActive = showBadge && tab.badgeContent !== undefined;
            const redirectLink = getBaseUrl ? getBaseUrl(tab.slug) : tab.slug;
            return (
              <Tab
                disableRipple
                key={tab.name}
                label={
                  <Button
                    className={clsx(classes.tab, classes.tabBtn, {
                      [classes.tabActive]: current,
                      [classes.tabBtnBadge]: !!badgeActive,
                    })}
                    disableTouchRipple
                  >
                    {badgeActive ? (
                      <Badge
                        classes={{
                          badge: clsx(classes.badge, {
                            [classes.badgeDisabled]: !current,
                          }),
                        }}
                        variant="standard"
                        badgeContent={tab.badgeContent}
                        color={current ? "primary" : "default"}
                        showZero
                      >
                        {tab.name}
                      </Badge>
                    ) : (
                      tab.name
                    )}
                  </Button>
                }
                component={Link}
                to={redirectLink}
              />
            );
          })}
        </Tabs>
      </div>
      {isLoading || (!!currentTab && Component && <Component {...rest} />)}
    </>
  );
};

export default CardTabs;
