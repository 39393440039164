import ProfileDetail from "core/components/ProfileDetail";
import { IProfileClicked } from "core/models";
import { createTableCell } from "core/utils/tableHandler";
import InteractionPlaceholderCell from "modules/Interaction/components/InteractionPlaceholderCell";
import { IInteractions } from "modules/Interaction/models";
import ExternalProfile from "modules/Person/components/ExternalProfile";
import { DefaultPlaceholdersKeys } from "modules/Placeholder/models";
import React from "react";
import Table, { TableProps, TableVariant } from "ui-kit/components/Table";
import TableBodyCell from "ui-kit/components/TableBodyCell";
import InteractionActions from "../InteractionActions";

const pageSize = 20;
const title = "Prospects";
const heads = [
  {
    id: "name",
    percentage: true,
    width: 22,
    label: "Name",
  },
  {
    id: "job_title",
    percentage: true,
    width: 25,
    label: "Job title",
  },
  {
    id: "company",
    percentage: true,
    width: 21,
    label: "Company",
  },
  {
    id: "location",
    percentage: true,
    width: 20,
    label: "Location",
  },
  {
    id: "action",
    percentage: true,
    width: 12,
    label: "Actions",
  },
];

interface LinkedInInteractionListProps {
  data: IInteractions | undefined;
  isLoading: TableProps["isLoading"];
  isFetching: TableProps["isFetching"];
  page: TableProps["page"];
  setPage: TableProps["setPage"];
  dataClicked: IProfileClicked | undefined;
  handleClose: () => void;
  handleClicked: (n: IProfileClicked) => void;
  campaignId: number;
  filters: React.ReactElement;
}

const LinkedInInteractionList = ({
  data,
  isLoading,
  isFetching,
  page,
  setPage,
  dataClicked,
  handleClose,
  handleClicked,
  campaignId,
  filters,
}: LinkedInInteractionListProps): React.ReactElement | null => {
  return (
    <Table
      title={title}
      heads={heads}
      filters={filters}
      rows={
        data
          ? data.results?.map((interaction) => {
              const handleOnClick = () => {
                const newDataClicked = interaction.person
                  ? {
                      personId: interaction?.person?.id,
                      contactId: interaction?.contact?.id,
                    }
                  : { searchResultId: interaction.search_result?.id };
                handleClicked({
                  ...newDataClicked,
                  connection: interaction.contact?.active_connection,
                });
              };

              const cellProps = {
                person: interaction.person,
                searchResult: interaction.search_result,
              };

              return {
                name: interaction?.id.toString(),
                data: [
                  createTableCell(
                    <ExternalProfile {...cellProps} />,
                    handleOnClick,
                    "main",
                    TableBodyCell
                  ),
                  createTableCell(
                    <InteractionPlaceholderCell
                      {...cellProps}
                      placeholderKey={DefaultPlaceholdersKeys.job_title}
                    />,
                    handleOnClick,
                    "default",
                    TableBodyCell
                  ),
                  createTableCell(
                    <InteractionPlaceholderCell
                      {...cellProps}
                      placeholderKey={DefaultPlaceholdersKeys.company_name}
                    />,
                    handleOnClick,
                    "default",
                    TableBodyCell
                  ),
                  createTableCell(
                    <InteractionPlaceholderCell
                      {...cellProps}
                      placeholderKey={DefaultPlaceholdersKeys.location}
                    />,
                    handleOnClick,
                    "default",
                    TableBodyCell
                  ),
                  createTableCell(
                    <InteractionActions
                      onHandleEdit={handleOnClick}
                      person={interaction.person}
                      searchResult={interaction.search_result}
                      campaignId={campaignId}
                    />,
                    null,
                    "action",
                    TableBodyCell
                  ),
                ],
              };
            })
          : []
      }
      count={data?.count || 0}
      rowsPerPage={pageSize}
      // INITIAL_PAGE starts at 1, but Pagination starts at 0
      page={(data?.current || page) - 1}
      setPage={setPage}
      isLoading={isLoading}
      isFetching={isFetching}
      variant={[TableVariant.checkable]}
      panel={<ProfileDetail {...dataClicked} onHandleClose={handleClose} />}
    />
  );
};

export default LinkedInInteractionList;
