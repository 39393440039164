import React from "react";
import { useLocation } from "react-router-dom";
import { useUpdateCheck } from "react-update-notification";

const CHECK_INTERVAL = 1000 * 30; // 30 seconds
const RESET_INTERVAL = 1000 * 60 * 5; // 5 minutes

const VersionUpdater = (): React.ReactElement | null => {
  const location = useLocation();
  const timer: { current: NodeJS.Timeout | null } = React.useRef(null);

  const { status, reloadPage } = useUpdateCheck({
    type: "interval",
    interval: CHECK_INTERVAL,
    ignoreServerCache: true,
  });

  // When new version found, reload on location change to make less disruption
  React.useEffect(() => {
    if (status === "available") {
      reloadPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // Alternatively, when no location changed for RESET_INTERVAL time, reload automatically
  React.useEffect(() => {
    if (status === "available") {
      timer.current = setTimeout(() => {
        reloadPage();
      }, RESET_INTERVAL);
    }

    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return null;
};

export default VersionUpdater;
