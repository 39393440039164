import ActionService from "modules/Action/services";
import {
  CampaignQuery,
  CampaignSequenceVariant,
  ICampaignParams,
} from "modules/Campaign/models";
import CampaignService from "modules/Campaign/services";
import { ISequenceMissingAction } from "modules/Sequence/models";
import React from "react";
import { useQuery } from "react-query";
import CampaignSequenceView from "../CampaignSequenceView";

const CampaignSequences = (props: ICampaignParams): React.ReactElement => {
  const { campaignId } = props;

  const { data: dataSequence } = useQuery(
    [CampaignQuery.sequences, campaignId],
    async () => {
      try {
        const response = await CampaignService.fetchAllCampaignSequences(
          campaignId
        );
        return response.data;
      } catch (err) {
        throw new Error(String(err));
      }
    },
    {
      enabled: !!campaignId,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  const [sequenceIds, setSequenceIds] = React.useState<undefined | number[]>();

  React.useEffect(() => {
    const newSequenceIds = dataSequence?.results.map(
      (campaignSequence) => campaignSequence.sequence.id
    );
    if (newSequenceIds !== sequenceIds) {
      setSequenceIds(newSequenceIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSequence]);

  const { data: dataActions } = useQuery(
    [CampaignQuery.actions, campaignId, sequenceIds],
    async () => {
      try {
        const response = await ActionService.fetchAllActions(sequenceIds);
        return response.data;
      } catch (err) {
        throw new Error(String(err));
      }
    },
    {
      enabled: !!dataSequence && dataSequence.count > 0 && !!sequenceIds,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  // Check if for each sequence there is at least 1 action
  // Start by default as undefined, to know when it's finished checking
  const [isMissingAction, setIsMissingAction] =
    React.useState<ISequenceMissingAction>(undefined);

  React.useEffect(() => {
    let isNewMissingAction = false;

    if (!!dataSequence && dataSequence.count === 0) {
      setIsMissingAction(true);
    }

    if (dataSequence && dataActions) {
      dataSequence?.results.forEach((campaignSequenceId, index) => {
        const actionExists = !!dataActions?.results.find(
          (action) => action.sequence === campaignSequenceId.sequence.id
        );

        if (!actionExists) {
          isNewMissingAction = true;
          return;
        }
      });

      setIsMissingAction(isNewMissingAction);
    }
  }, [dataSequence, dataActions]);

  return (
    <CampaignSequenceView
      {...props}
      isMissingAction={isMissingAction}
      variant={CampaignSequenceVariant.finished}
    />
  );
};

export default CampaignSequences;
