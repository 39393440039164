import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { BaseTextFieldProps, Box, Button, InputAdornment } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/styles";
import {
  hasLowercase,
  hasNumber,
  hasUppercase,
} from "core/utils/validationHandler";
import React from "react";
import MuiTextField from "../atoms/TextField";

const createValidation = (id: number, text: string, active: boolean) => ({
  id,
  text,
  active,
});

const getValidations = (str: string) => [
  createValidation(1, "One uppercase character", hasUppercase(str)),
  createValidation(2, "One lowercase character", hasLowercase(str)),
  createValidation(3, "One numerical character", hasNumber(str)),
  createValidation(4, "At minimum 8 characters", str.length >= 8),
];

const handleColor = (active: boolean) => (active ? "success.main" : "error");

interface PasswordFieldProps extends BaseTextFieldProps {
  ElementProps?: {
    validation?: boolean;
  };
  InputProps: any;
}

const PasswordField = React.forwardRef<HTMLDivElement, PasswordFieldProps>(
  function TextField(props: PasswordFieldProps, ref) {
    const theme = useTheme();

    const [showPassword, setShowPassword] = React.useState(false);

    const handleFunc = (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      setShowPassword(!showPassword);
    };

    const { InputProps, ElementProps } = props;
    return (
      <>
        <MuiTextField
          {...props}
          type={showPassword ? "text" : "password"}
          InputProps={{
            ...InputProps,
            // notched: false,
            endAdornment: (
              <InputAdornment
                position="end"
                sx={{ marginRight: -2, width: 72 }}
              >
                <Button
                  size="small"
                  aria-label="toggle password visibility"
                  onClick={handleFunc}
                  color="primary"
                  // variant="outlined"
                >
                  {showPassword ? "Hide" : "Show"}
                </Button>
              </InputAdornment>
            ),
          }}
          ref={ref}
        />

        {!!ElementProps?.validation && (
          <Box
            sx={{
              height: !!props.value ? 58 : 0,
              opacity: !!props.value ? 1 : 0,
              transition: theme.transitions.create("all", {
                duration: theme.transitions.duration.shorter,
              }),
            }}
          >
            <Typography
              sx={{
                fontSize: "0.6rem",
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              Password must contain:
            </Typography>
            <Grid container>
              {getValidations(String(props.value)).map((validation) => (
                <Grid item xs={6} sx={{ paddingTop: 1 }} key={validation.id}>
                  <Typography
                    sx={{
                      fontSize: "0.65rem",
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                    }}
                    color={handleColor(validation.active)}
                  >
                    <HighlightOffIcon sx={{ fontSize: 16 }} />
                    {validation.text}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
      </>
    );
  }
);

export default PasswordField;
