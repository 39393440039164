import Typography from "@mui/material/Typography";
import AccountService from "modules/Account/services";
import * as React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import InactiveList from "../InactiveList";

type ParamTypes = {
  accountId: string;
};

const InactiveDaysList = (): React.ReactElement | null => {
  const params = useParams<ParamTypes>();
  const accountId: number = +params.accountId;
  const { data } = useQuery(["inactive_periods", accountId], async () => {
    try {
      const response = await AccountService.fetchInactivePeriods(accountId);
      return response.data;
    } catch (err) {
      throw new Error(String(err));
    }
  });

  const items = data?.results;

  if (!items?.length) return null;

  return (
    <>
      <Typography>Inactive Periods</Typography>
      <InactiveList data={items} accountId={accountId} />
    </>
  );
};

export default InactiveDaysList;
