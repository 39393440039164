import { useEffect } from "react";
import { hotjar } from "react-hotjar";
import ConfigConstant from "core/constants/ConfigConstant";

const trackingId = 0;
const hotjarId = Number(ConfigConstant.HOTJAR_ID);

const SessionTracking = (): null => {
  useEffect(() => {
    if (hotjarId) {
      hotjar.initialize(hotjarId, trackingId);
    }
  }, []);

  return null;
};

export default SessionTracking;
