import * as React from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import { makeStyles, createStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import DialogContentText from "@mui/material/DialogContentText";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import ConfigConstant from "core/constants/ConfigConstant";
import { errorHandler, IErrorResponse } from "core/utils/errorHandler";
import { snackbarHandler } from "core/utils/snackbarHandler";
import AccountService from "modules/Account/services";
import {
  IAccountPlaceholders,
  IAccountPlaceholder,
  AccountQuery,
} from "modules/Account/models";
import PlaceholderValidation from "modules/Placeholder/validations";
import TextField from "ui-kit/atoms/TextField";
import Button from "ui-kit/atoms/Button";
import DialogHeader from "ui-kit/components/DialogHeader";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    inputRoot: {
      "&:not(.Mui-focused)": {
        "& > .MuiInputAdornment-root": {
          visibility: "hidden",
        },
      },
    },
    label: {
      color: theme.app.palette.action.color,
    },
    dialogInline: {
      position: "absolute",
    },
    limit: {
      fontSize: 11,
      color: theme.app.palette.action.placeholder,
    },
  })
);

interface NewPlaceholderDialogProps {
  open: boolean;
  setOpen: (v: boolean) => void;
  accountId: number;
  placeholders: IAccountPlaceholders;
}

function NewAccountPlaceholderDialog({
  open,
  setOpen,
  accountId,
  placeholders,
}: NewPlaceholderDialogProps): React.ReactElement {
  const classes = useStyles();
  const schema = PlaceholderValidation.newPlaceholderSchema();

  const { register, errors, handleSubmit, setError, reset } = useForm({
    resolver: yupResolver(schema),
  });

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  const queryClient = useQueryClient();
  const mutateOnSubmit = useMutation(
    (newData: IAccountPlaceholders) =>
      AccountService.updateAccountPlaceholders(accountId, newData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([AccountQuery.account, accountId]);
        snackbarHandler.success("Successfully updated!");
        handleClose();
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response, setError);
      },
    }
  );

  const onSubmit = ({ key, value }: IAccountPlaceholder) => {
    mutateOnSubmit.mutate({
      ...placeholders,
      // Enforce lower-case https://gitlab.scommand.com/snetwork/snetwork_app/-/issues/173
      [key.toLowerCase()]: value.toLowerCase(),
    });
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        PaperProps={{ sx: { width: "100%" } }}
        keepMounted
        aria-labelledby="new-account-placeholder-dialog"
      >
        <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <DialogHeader title="Add a placeholder" onHandleClose={handleClose} />
          <DialogContent dividers>
            <DialogContentText>
              Create a new <b>&quot;my placeholder&quot;</b> for your account.
            </DialogContentText>
            <TextField
              id="key"
              fullWidth
              name="key"
              inputRef={register}
              label="Name - ex. website"
              variant="outlined"
              error={!!errors.key}
              helperText={errors.key?.message}
            />
            <TextField
              id="value"
              fullWidth
              name="value"
              inputRef={register}
              label={`Value - ex. ${ConfigConstant.DOMAIN_NAME}.com`}
              variant="outlined"
              error={!!errors.value}
              helperText={errors.value?.message}
              InputProps={{
                classes: {
                  root: classes.inputRoot,
                },
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" type="submit">
              Save placeholder
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

export default NewAccountPlaceholderDialog;
