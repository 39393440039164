import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import Autocomplete, {
  AutocompleteRenderInputParams,
} from "@mui/material/Autocomplete";
import { Theme, ListItem, ListItemText } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";
import RouterUtils from "core/routes/utils";
import { IOption } from "core/models";
import TextField from "ui-kit/atoms/TextField";

interface AutocompleteFilterProps {
  id: string;
  label: string;
  options: IOption[] | undefined;
  isLoading?: boolean;
  disableCloseOnSelect?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      transform: "translate(14px, 13px) scale(1)",
    },
    shrink: {
      transform: "translate(12px, 5px) scale(.65) !important",
    },
    control: {
      marginBottom: 0,
    },
    item: {
      padding: theme.spacing(0, 1),
    },
    itemText: {
      ...theme.app.constants.textOneLiner,
    },
  })
);

const getDefaultValue = (
  query: string | string[],
  options: IOption[] | undefined
) => {
  const v = options?.find((option) => option.id === query);
  return v;
};

const AutocompleteFilter = ({
  options,
  id,
  label: defaultLabel,
  isLoading = false,
  disableCloseOnSelect = false,
}: AutocompleteFilterProps): React.ReactElement => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { [id]: query } = RouterUtils.parseQueryParams(location);

  const [value, setValue] = React.useState<IOption | null>(null);

  // Run only on the first update
  const firstUpdateQuery = React.useRef(true);
  React.useEffect(() => {
    if (options && query) {
      if (!firstUpdateQuery.current) {
        return;
      }

      const defaultValue = getDefaultValue(query, options);
      if (defaultValue) {
        setValue(defaultValue);
      }

      firstUpdateQuery.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, query]);

  return (
    <Autocomplete
      value={value}
      autoHighlight
      options={options || []}
      disableCloseOnSelect={disableCloseOnSelect}
      onChange={(_, newData) => {
        setValue(newData);
        if (newData) {
          return RouterUtils.addQuery({ [id]: String(newData?.id) }, history);
        }
        return RouterUtils.removeQuery([id], history);
      }}
      disabled={isLoading}
      loading={isLoading}
      getOptionLabel={(option: IOption) => option.name}
      isOptionEqualToValue={(option, v) => {
        return option.id === v.id;
      }}
      renderOption={(props, option, { selected }) => (
        <ListItem
          {...props}
          selected={selected}
          classes={{ root: classes.item }}
        >
          <ListItemText
            className={classes.itemText}
            primary={option.name}
            classes={{
              primary: classes.itemText,
            }}
            primaryTypographyProps={{
              variant: "body2",
              className: classes.itemText,
            }}
          />
        </ListItem>
      )}
      renderInput={(params: AutocompleteRenderInputParams) => {
        return (
          <TextField
            {...params}
            placeholder={isLoading ? "Loading..." : defaultLabel}
            disabled={false}
            fullWidth
            // Set focus instead of autoFocus
            // autoFocus didn't focus on first render
            inputRef={(input) =>
              input &&
              setTimeout(() => {
                input.focus();
              }, 0)
            }
            variant="outlined"
            classes={{ root: classes.control }}
            InputLabelProps={{
              classes: {
                root: classes.label,
              },
            }}
            InputProps={{
              ...params.InputProps,
              className: "Mui-TextField-filter",
              endAdornment: isLoading ? (
                <InputAdornment position="start">
                  <CircularProgress size={20} color="secondary" />
                </InputAdornment>
              ) : (
                params.InputProps.endAdornment
              ),
            }}
          />
        );
      }}
    />
  );
};

AutocompleteFilter.defaultProps = {
  isLoading: false,
  disableCloseOnSelect: false,
};

export default AutocompleteFilter;
