import React from "react";
import { AxiosResponse } from "axios";
import { useQuery } from "react-query";
import { AccountContext } from "modules/Account/context";
import { ReportNames, IDateReport } from "modules/Report/models";
import ReportCountGenerator from "../ReportCountGenerator";

export interface ReportItemProps {
  label: string;
  name: ReportNames;
  index: number;
  total: number;
  DateProps: IDateReport;
  QueryProps: {
    query: string;
    func: (accountId: number, queryString: string) => Promise<AxiosResponse>;
  };
  itemsPerLine: number;
}

const ReportCountItem = ({
  label,
  name,
  index,
  total,
  DateProps,
  QueryProps,
  itemsPerLine,
}: ReportItemProps): React.ReactElement => {
  const {
    account: { id: accountId },
  } = React.useContext(AccountContext);

  const fetchReports = async () => {
    try {
      const { data } = await QueryProps.func(accountId, QueryProps.query);
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };
  const { data, isLoading } = useQuery(
    ["reports", name, accountId, { query: QueryProps.query }],
    () => fetchReports(),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  return (
    <ReportCountGenerator
      label={label}
      DateProps={DateProps}
      isLoading={isLoading}
      count={data?.count}
      results={data?.results}
      index={index}
      total={total}
      itemsPerLine={itemsPerLine}
    />
  );
};

export default ReportCountItem;
