import { IAccountDetailItemProps } from "modules/Account/models";
import React from "react";
import InactiveDaySettings from "../InactiveDaySettings";
import TimeZoneSettings from "../TimeZoneSettings";
import WorkHourSettings from "../WorkHourSettings";

const ScheduleSettings = (
  props: IAccountDetailItemProps
): React.ReactElement | null => {
  return (
    <>
      <TimeZoneSettings {...props} />
      <WorkHourSettings {...props} />
      <InactiveDaySettings {...props} />
    </>
  );
};

export default ScheduleSettings;
