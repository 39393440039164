import React, { useEffect, useRef } from "react";
import { Chart } from "chart.js";
import { useTheme } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import Chartjs, { options } from "core/utils/chartHandler";
import { IChartValues, ChartType } from "modules/Report/models";

interface CustomLineChartProps {
  label: string;
  labels: string[];
  type: ChartType;
  values: IChartValues;
}

const CustomLineChart = ({
  type,
  label,
  labels,
  values: data,
}: CustomLineChartProps): React.ReactElement => {
  const theme: Theme = useTheme();
  const chartColor = theme.palette.secondary.main;
  const chartContainer = useRef<HTMLCanvasElement | null>(null);
  const [chartInstance, setChartInstance] = React.useState<null | Chart>(null);

  useEffect(() => {
    if (chartContainer && chartContainer.current) {
      const newChartInstance = new Chartjs(chartContainer.current, {
        type: "LineWithLine",
        data: {
          labels: [],
          datasets: [
            {
              label: "",
              data: [],
              borderColor: chartColor,
              fill: false,
            },
          ],
        },
        ...options,
      });
      setChartInstance(newChartInstance);
    }
  }, [chartContainer, chartColor]);

  useEffect(() => {
    if (
      chartInstance &&
      chartInstance.data &&
      chartInstance.data.datasets &&
      chartInstance.data.datasets[0]
    ) {
      chartInstance.data.datasets[0].data = data;
      chartInstance.data.datasets[0].label = label;
      chartInstance.data.labels = labels;
      chartInstance.data.datasets[0].barPercentage = type;
      chartInstance.update();
    }
  }, [chartInstance, data, label, labels, type]);

  return (
    <>
      <canvas ref={chartContainer} />
    </>
  );
};

export default CustomLineChart;
