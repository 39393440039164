import React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import HelperTooltip from "./HelperTooltip";

const TableBodyCellPlaceholder = (): React.ReactElement => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <span>-</span>
      <HelperTooltip
        title={
          <>
            <Typography variant="body2" gutterBottom component="div">
              Field is empty. Please keep this in mind while creating
              personalized messages.
            </Typography>
            {/* <Typography variant="body2" component="div">
              You can click on the person and enter the value manually.
            </Typography> */}
            <Typography variant="body2" component="div">
              You can fill it in by hand or use dynamic placeholder (IF/ELSE) in
              your messages.
            </Typography>
          </>
        }
        placement="top"
      />
    </Box>
  );
};

export default TableBodyCellPlaceholder;
