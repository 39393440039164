import envs from "../../envs";

const API_VERSION = "/api";
const APP_NAME = envs.APP_NAME;
const DOMAIN_NAME = envs.DOMAIN_NAME;
const EMAIL = `hello@${DOMAIN_NAME}.com`;
const LANDING_URL = `https://${DOMAIN_NAME}.com`;

const ConfigConstant = {
  API_URL: envs.API_URL,
  API_VERSION,
  API_ROOT: `${envs.API_URL}${API_VERSION}`,
  APP_ID: Number(envs.APP_ID),
  APP_NAME,
  EMAIL,
  OLD_APP_NAME: envs.OLD_APP_NAME,
  DOMAIN_NAME,
  BASE_URL: envs.BASE_URL,
  CRISP_ID: envs.CRISP_ID,
  HOTJAR_ID: envs.HOTJAR_ID,
  GOOGLE_OAUTH_CLIENT_ID: envs.GOOGLE_OAUTH_CLIENT_ID,
  GOOGLE_OAUTH_REDIRECT_URI: envs.GOOGLE_OAUTH_REDIRECT_URI,
  GOOGLE_ANALYTICS_ID: envs.GOOGLE_ANALYTICS_ID,
  GOOGLE_TAG_MANAGER_ID: envs.GOOGLE_TAG_MANAGER_ID,
  GOOGLE_TAG_MANAGER_AUTH: envs.GOOGLE_TAG_MANAGER_AUTH,
  GOOGLE_TAG_MANAGER_PREVIEW: envs.GOOGLE_TAG_MANAGER_PREVIEW,
  CUSTOMERLY_ID: envs.CUSTOMERLY_ID,
  ENV: envs.ENV,
  INITIAL_PAGE: 1,
  PAGE_SIZE: {
    SHORT: 10,
    MEDIUM: 20,
    LONG: 25,
  },
  EMAIL_LINK: `mailto:${EMAIL}?subject=Question%20about%20${APP_NAME}&body=Hi there,`,
  LANDING_URL,
  HELP_URL: `${LANDING_URL}/help`,
  CALENDY_URL: `https://calendly.com/${DOMAIN_NAME}/meet`,
  SEARCH: {
    BASIC_SEARCH: "https://www.linkedin.com/search/results/people/",
    SALES_NAV_SEARCH: "https://www.linkedin.com/sales/search/people/",
    RECRUITER_SEARCH: "https://www.linkedin.com/talent/search",
    GOOGLE_SHEET:
      "https://docs.google.com/spreadsheets/d/1dx58L_aFJjzBqJNgXibUsLFPxVGn--wr-d828M0PwGI/edit?usp=sharing",
    GROUP_SEARCH: "https://www.linkedin.com/groups/",
  },
  TRELLO_URL: `https://trello.com/b/43gCRMNF/%F0%9F%9A%80-${DOMAIN_NAME}-public-roadmap`,
  SEAT_CONTENT_TYPE_ID: envs.SEAT_CONTENT_TYPE_ID,
  INTERACTION_CONTENT_TYPE_ID: envs.INTERACTION_CONTENT_TYPE_ID,
  SENTRY_URL: envs.SENTRY_URL,
  STRIPE_PUB_KEY: envs.STRIPE_PUB_KEY,
  CHARACTER_COUNT: 300,
  PLACEHOLDER_INPUT_WIDTH: 190,
  EMPTY_SEARCH: "",
  LINKEDIN_ROOT: "https://www.linkedin.com/",
  PRICE: {
    DISCOUNT: {
      ALL: 100,
      NICKNAME: "discount",
    },
    FULL: {
      NICKNAME: "full",
    },
    TRIAL: {
      NICKNAME: "trial",
    },
  },
  HOURS: {
    FUSE_LIMIT: 24,
    FAILED_WITHOUT_REASON_INTERACTION: 48,
  },
  MINUTES: {
    TIME_SAVED: 5,
  },
  LTD_ACCOUNTS: [3, 9, 35, 36, 2483],
  EXCEPTION_COMPANIES: [65],
  DISCOUNT_CODE_ID: envs.DISCOUNT_CODE_ID,
  DISCOUNT_CODE_LABEL: envs.DISCOUNT_CODE_LABEL,
  AFFILIATE_ID_NAME: "affiliate_id",
};

export default ConfigConstant;
