import React from "react";
import { SearchDefaultFormProps } from "modules/Search/models";
import { SearchContext } from "modules/Search/context";
import MatchSheetFields from "modules/Search/components/MatchSheetFields";
import SearchDefaultForm from "../SearchDefaultForm";

interface SearchGoogleSheetFormProps extends SearchDefaultFormProps {}

const SearchGoogleSheetForm = (
  props: SearchGoogleSheetFormProps
): React.ReactElement => {
  const {
    search: { columns },
  } = React.useContext(SearchContext);

  return columns ? (
    <MatchSheetFields
      control={props.control}
      errors={props.errors}
      isLoading={props.isLoading}
    />
  ) : (
    <SearchDefaultForm {...props} />
  );
};

export default SearchGoogleSheetForm;
