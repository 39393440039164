import { IArrayResponse } from "core/models";

export enum CompanyFamily {
  "CO" = "CO",
  "AG" = "AG",
}

export interface ICompanyFormValues {
  inputValue?: string;
  name: string;
  family?: CompanyFamily;
}

export interface ICompany extends ICompanyFormValues {
  id: number;
}

export interface ICompanies extends IArrayResponse {
  results: ICompany[];
}
