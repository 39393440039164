import { dayjs, differenceInUnits } from "core/utils/dateHandler";
import { AccountContext } from "modules/Account/context";
import AccountCheckout from "modules/Account/pages/AccountCheckout";
import AccountDetail from "modules/Account/pages/AccountDetail";
import AccountPermission from "modules/Account/pages/AccountPermission";
import AccountRemoved from "modules/Account/pages/AccountRemoved";
import AccountSelect from "modules/Account/pages/AccountSelect";
import CreateAccount from "modules/Account/pages/CreateAccount";
import ResumeAccount from "modules/Account/pages/ResumeAccount";
import AddressBookList from "modules/AddressBook/pages/AddressBookList";
import CampaignDetail from "modules/Campaign/pages/CampaignDetail";
import CampaignList from "modules/Campaign/pages/CampaignList";
import NewCampaign from "modules/Campaign/pages/NewCampaign";
import StartCampaign from "modules/Campaign/pages/StartCampaign";
import CompanyDetail from "modules/Company/pages/CompanyDetail";
import CompanyList from "modules/Company/pages/CompanyList";
import Dashboard from "modules/Dashboard/pages/Dashboard";
import InteractionList from "modules/Interaction/pages/InteractionList";
import InteractionRedirect from "modules/Interaction/pages/InteractionRedirect";
import NotificationList from "modules/Notification/pages/NotificationList";
import BillingDetail from "modules/Payment/pages/BillingDetail";
import BillingList from "modules/Payment/pages/BillingList";
// import CancelationPage from "modules/Payment/pages/CancelationForm";
import UpgradePage from "modules/Payment/pages/UpgradePage";
import MyNetwork from "modules/Person/pages/MyNetwork";
import SearchDetail from "modules/Search/pages/SearchDetail";
import SearchList from "modules/Search/pages/SearchList";
import SequenceDetail from "modules/Sequence/pages/SequenceDetail";
import SequenceList from "modules/Sequence/pages/SequenceList";
import TeamList from "modules/Team/pages/TeamList";
import { UserContext } from "modules/User/context";
import AuthLoading from "modules/User/pages/AuthLoading";
import ForgotPassword from "modules/User/pages/ForgotPassword";
import ResetPassword from "modules/User/pages/ResetPassword";
import SignIn from "modules/User/pages/SignIn";
import SignUp from "modules/User/pages/SignUp";
import UpdateRegistrationEmail from "modules/User/pages/UpdateRegistrationEmail";
import UserDetail from "modules/User/pages/UserDetail";
import VerifyEmail from "modules/User/pages/VerifyEmail";
import VerifyRegistration from "modules/User/pages/VerifyRegistration";
import IntegrationApi from "modules/Webhook/pages/IntegrationApi";
import IntegrationPage from "modules/Webhook/pages/IntegrationPage";
import WebhookDetail from "modules/Webhook/pages/WebhookDetail";
import WebhookList from "modules/Webhook/pages/WebhookList";
import React, { ReactElement, useContext } from "react";
import { Route, Switch } from "react-router-dom";
import ProtectedRoute, {
  ProtectedRouteProps,
} from "./components/ProtectedRoute";
import RouterConstants from "./constants";
import Notice from "modules/User/pages/Notice";

function Routes(): ReactElement {
  const { user } = useContext(UserContext);
  const { account } = useContext(AccountContext);
  const accountId = account.id;

  const isAuthenticated = user.anonymous !== true;
  const isCurrentAccount = !!accountId;

  // Require subscription check if the account is older than 60 seconds
  const isRequiredCheck =
    differenceInUnits(dayjs(), account?.created, "s") > 60;

  const defaultAuthRouteProps: ProtectedRouteProps = {
    isAuthenticated,
    isCurrentAccount,
    isRequiredCheck,
    authenticationPath: RouterConstants.USER.SIGN_IN,
    activeAccountPath: RouterConstants.ACCOUNT.SELECT,
  };

  const baseDefaultRestrictedRouteProps: ProtectedRouteProps = {
    isAuthenticated,
    restrictedPath: RouterConstants.ROOT,
    isAllowed: false,
  };

  const defaultRestrictedRouteProps: ProtectedRouteProps = {
    ...baseDefaultRestrictedRouteProps,
    noticePath: RouterConstants.USER.NOTICE,
  };

  return (
    <Switch>
      <ProtectedRoute
        title="Dashboard"
        {...defaultAuthRouteProps}
        exact
        path={RouterConstants.ROOT}
        component={Dashboard}
      />

      {/* Campaign */}
      <ProtectedRoute
        title="Campaigns"
        exact
        {...defaultAuthRouteProps}
        path={RouterConstants.CAMPAIGN.ALL}
        component={CampaignList}
      />
      <ProtectedRoute
        title="Campaign detail"
        exact
        {...defaultAuthRouteProps}
        path={RouterConstants.CAMPAIGN.DETAIL}
        component={CampaignDetail}
      />
      <ProtectedRoute
        title="Sequence detail"
        exact
        {...defaultAuthRouteProps}
        path={RouterConstants.CAMPAIGN.DETAIL_SEQUENCE}
        component={CampaignDetail}
      />
      <ProtectedRoute
        title="New campaign"
        exact
        {...defaultAuthRouteProps}
        path={RouterConstants.CAMPAIGN.NEW_START}
        component={StartCampaign}
      />
      <ProtectedRoute
        title="New campaign"
        exact
        {...defaultAuthRouteProps}
        path={RouterConstants.CAMPAIGN.NEW}
        component={NewCampaign}
      />
      <ProtectedRoute
        title="New campaign"
        exact
        {...defaultAuthRouteProps}
        path={RouterConstants.CAMPAIGN.NEW_TAB}
        component={NewCampaign}
      />
      <ProtectedRoute
        title="New campaign"
        exact
        {...defaultAuthRouteProps}
        path={RouterConstants.CAMPAIGN.NEW_SEQUENCE}
        component={NewCampaign}
      />

      {/* Sequence */}
      <ProtectedRoute
        title="Templates"
        exact
        {...defaultAuthRouteProps}
        path={RouterConstants.SEQUENCE.ALL}
        component={SequenceList}
      />
      <ProtectedRoute
        title="New sequence"
        exact
        {...defaultAuthRouteProps}
        path={RouterConstants.SEQUENCE.NEW}
        component={SequenceList}
      />
      <ProtectedRoute
        title="Sequence detail"
        exact
        {...defaultAuthRouteProps}
        path={RouterConstants.SEQUENCE.DETAIL}
        component={SequenceDetail}
      />

      {/* My network */}
      <ProtectedRoute
        title="My network"
        exact
        {...defaultAuthRouteProps}
        path={RouterConstants.MY_NETWORK.ALL}
        component={MyNetwork}
      />

      {/* Search */}
      <ProtectedRoute
        title="Searches"
        exact
        {...defaultAuthRouteProps}
        path={RouterConstants.SEARCH.ALL}
        component={SearchList}
      />
      <ProtectedRoute
        title="New search"
        exact
        {...defaultAuthRouteProps}
        path={RouterConstants.SEARCH.NEW}
        component={SearchList}
      />
      <ProtectedRoute
        title="Search detail"
        exact
        {...defaultAuthRouteProps}
        path={RouterConstants.SEARCH.DETAIL}
        component={SearchDetail}
      />

      {/* Blacklist */}
      <ProtectedRoute
        title="Blacklist"
        exact
        {...defaultAuthRouteProps}
        path={RouterConstants.BLACKLIST.ALL}
        component={AddressBookList}
      />

      {/* Inbox */}
      <ProtectedRoute
        title="Inbox"
        exact
        {...defaultAuthRouteProps}
        path={RouterConstants.INBOX.ALL}
        component={InteractionList}
      />
      <ProtectedRoute
        title="Inbox detail"
        exact
        {...defaultAuthRouteProps}
        path={RouterConstants.INBOX.DETAIL}
        component={InteractionList}
      />

      {/* Webhook */}
      <ProtectedRoute
        title="Interactions"
        exact
        {...defaultAuthRouteProps}
        path={RouterConstants.INTEGRATION.ROOT}
        component={IntegrationPage}
      />
      <ProtectedRoute
        title="API key"
        exact
        {...defaultAuthRouteProps}
        path={RouterConstants.INTEGRATION.API}
        component={IntegrationApi}
      />
      <ProtectedRoute
        title="Webhook list"
        exact
        {...defaultAuthRouteProps}
        path={RouterConstants.INTEGRATION.WEBHOOK.ALL}
        component={WebhookList}
      />
      <ProtectedRoute
        title="New webhook"
        exact
        {...defaultAuthRouteProps}
        path={RouterConstants.INTEGRATION.WEBHOOK.NEW}
        component={WebhookList}
      />
      <ProtectedRoute
        title="Webhook detail"
        exact
        {...defaultAuthRouteProps}
        path={RouterConstants.INTEGRATION.WEBHOOK.DETAIL}
        component={WebhookDetail}
      />

      {/* Interaction */}
      <ProtectedRoute
        title="Interaction detail"
        exact
        {...defaultAuthRouteProps}
        path={RouterConstants.INTERACTION.DETAIL}
        component={InteractionRedirect}
      />

      {/* Notification */}
      <ProtectedRoute
        title="Notifications"
        exact
        {...defaultAuthRouteProps}
        path={RouterConstants.NOTIFICATION.ALL}
        component={NotificationList}
      />

      {/* Company */}
      <ProtectedRoute
        title="Companies"
        exact
        {...defaultAuthRouteProps}
        path={RouterConstants.COMPANY.ALL}
        component={CompanyList}
      />
      <ProtectedRoute
        title="Company detail"
        exact
        {...defaultAuthRouteProps}
        path={RouterConstants.COMPANY.DETAIL}
        component={CompanyDetail}
      />

      {/* Team management */}
      <ProtectedRoute
        title="Team settings"
        exact
        {...defaultAuthRouteProps}
        path={RouterConstants.TEAM.ALL}
        component={TeamList}
      />

      {/* Billing */}
      <ProtectedRoute
        title="Billing"
        exact
        {...defaultAuthRouteProps}
        path={RouterConstants.BILLING.ALL}
        component={BillingList}
        isCurrentAccount
        disableAccountValidation
        activeAccountPath={RouterConstants.ROOT}
        ContentProps={{
          disabledNavBar: !isCurrentAccount,
          disableOnboarding: true,
        }}
      />
      <ProtectedRoute
        title="Payment"
        exact
        {...defaultAuthRouteProps}
        path={RouterConstants.BILLING.PAYMENT}
        component={BillingList}
        isCurrentAccount
        disableAccountValidation
        activeAccountPath={RouterConstants.BILLING.PAYMENT}
        ContentProps={{
          disabledNavBar: !isCurrentAccount,
          disableOnboarding: true,
        }}
      />
      <ProtectedRoute
        title="No active plan"
        exact
        {...defaultAuthRouteProps}
        path={RouterConstants.BILLING.PLAN}
        component={UpgradePage}
        isCurrentAccount
        activeAccountPath={RouterConstants.ROOT}
        ContentProps={{
          disabledNavBar: !isCurrentAccount,
          disableOnboarding: true,
        }}
      />
      <ProtectedRoute
        title="Billing detail"
        exact
        {...defaultAuthRouteProps}
        path={RouterConstants.BILLING.DETAIL}
        component={BillingDetail}
        isCurrentAccount
        disableAccountValidation
        activeAccountPath={RouterConstants.ROOT}
        ContentProps={{
          disabledNavBar: !isCurrentAccount,
          disableOnboarding: true,
        }}
      />
      {/* <ProtectedRoute
        title="Cancel subscription"
        exact
        {...defaultAuthRouteProps}
        path={RouterConstants.BILLING.CANCEL}
        component={CancelationPage}
        isCurrentAccount
        disableAccountValidation
        activeAccountPath={RouterConstants.ROOT}
        ContentProps={{
          disabledNavBar: !isCurrentAccount,
          disableOnboarding: true,
        }}
      /> */}

      {/* Account */}
      <ProtectedRoute
        title="Create account"
        exact
        {...defaultAuthRouteProps}
        path={RouterConstants.ACCOUNT.NEW_ROOT}
        disableAccountValidation
        isCurrentAccount
        component={CreateAccount}
        activeAccountPath={RouterConstants.ROOT}
        ContentProps={{
          disabledNavBar: !isCurrentAccount,
          disableOnboarding: true,
        }}
      />

      {/* Account - already new */}
      <ProtectedRoute
        title="Create account"
        exact
        {...defaultAuthRouteProps}
        path={RouterConstants.ACCOUNT.NEW}
        disableAccountValidation
        component={CreateAccount}
        activeAccountPath={RouterConstants.ROOT}
      />

      <ProtectedRoute
        title="Resume account"
        exact
        {...defaultAuthRouteProps}
        path={RouterConstants.ACCOUNT.RESUME}
        // disableAccountValidation
        component={ResumeAccount}
        activeAccountPath={RouterConstants.ROOT}
      />

      <ProtectedRoute
        title="Select account"
        exact
        {...defaultAuthRouteProps}
        isCurrentAccount={!isCurrentAccount}
        disableAccountValidation
        activeAccountPath={RouterConstants.ROOT}
        path={RouterConstants.ACCOUNT.SELECT}
        component={AccountSelect}
        ContentProps={{
          disabledNavBar: !isCurrentAccount,
          disableOnboarding: true,
        }}
      />

      <ProtectedRoute
        title="Payment successful"
        exact
        {...defaultAuthRouteProps}
        isCurrentAccount
        path={RouterConstants.ACCOUNT.CHECKOUT.SUCCESS}
        component={AccountCheckout}
      />
      <ProtectedRoute
        title="Payment failed"
        exact
        {...defaultAuthRouteProps}
        isCurrentAccount
        path={RouterConstants.ACCOUNT.CHECKOUT.CANCEL}
        component={AccountCheckout}
      />

      <ProtectedRoute
        title="Account settings"
        exact
        {...defaultAuthRouteProps}
        path={RouterConstants.ACCOUNT.DETAIL}
        component={AccountDetail}
      />

      <ProtectedRoute
        title="Account permission"
        exact
        {...defaultAuthRouteProps}
        path={RouterConstants.ACCOUNT.NO_PERMISSION}
        component={AccountPermission}
        isCurrentAccount
        activeAccountPath={RouterConstants.ROOT}
        disableAccountValidation
        ContentProps={{
          disabledNavBar: !isCurrentAccount,
          disableOnboarding: true,
        }}
      />

      <ProtectedRoute
        title="Account removed"
        exact
        {...defaultAuthRouteProps}
        path={RouterConstants.ACCOUNT.REMOVED}
        component={AccountRemoved}
        isCurrentAccount
        activeAccountPath={RouterConstants.ROOT}
        disableAccountValidation
        ContentProps={{
          disabledNavBar: !isCurrentAccount,
          disableOnboarding: true,
        }}
      />

      {/* User */}
      <ProtectedRoute
        title="Sign up"
        {...defaultRestrictedRouteProps}
        path={RouterConstants.USER.SIGN_UP}
        component={SignUp}
      />

      {/* Google Login redirect */}
      <ProtectedRoute
        title="Sign up"
        {...defaultRestrictedRouteProps}
        path={RouterConstants.USER.LOGIN.GOOGLE.REDIRECT}
        component={AuthLoading}
      />
      <ProtectedRoute
        title="Sign in"
        {...defaultRestrictedRouteProps}
        path={RouterConstants.USER.SIGN_IN}
        component={SignIn}
      />
      <ProtectedRoute
        title="Forgot password"
        {...defaultRestrictedRouteProps}
        path={RouterConstants.USER.FORGOT_PASSWORD}
        component={ForgotPassword}
      />
      <ProtectedRoute
        title="Reset password"
        {...defaultRestrictedRouteProps}
        path={RouterConstants.USER.RESET_PASSWORD}
        component={ResetPassword}
      />
      <ProtectedRoute
        title="Verify registration"
        {...defaultRestrictedRouteProps}
        exact
        path={RouterConstants.USER.VERIFY_USER}
        component={VerifyRegistration}
      />

      <ProtectedRoute
        title="Notice"
        {...baseDefaultRestrictedRouteProps}
        exact
        path={RouterConstants.USER.NOTICE}
        component={Notice}
      />

      <ProtectedRoute
        title="Verify email change"
        {...defaultAuthRouteProps}
        exact
        path={RouterConstants.USER.VERIFY_EMAIL}
        component={UpdateRegistrationEmail}
      />

      <ProtectedRoute
        title="Verify email"
        {...defaultRestrictedRouteProps}
        exact
        path={RouterConstants.USER.REGISTER_EMAIL}
        component={VerifyEmail}
      />
      <ProtectedRoute
        title="User detail"
        {...defaultAuthRouteProps}
        path={RouterConstants.USER.DETAIL}
        component={UserDetail}
        disableAccountValidation
        isCurrentAccount
        ContentProps={{
          disabledNavBar: !isCurrentAccount,
          disableOnboarding: true,
        }}
      />

      <Route path="*" render={() => <h1>404: page not found</h1>} />
    </Switch>
  );
}

export default Routes;
