import React from "react";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import { Chip } from "ui-kit/atoms/Chip";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderRadius: 50,
    cursor: "pointer",
  },
  label: {
    padding: theme.spacing(0, 1.75),
  },
}));

const BlacklistIcon = (): React.ReactElement => {
  const classes = useStyles();
  return (
    <Tooltip title="Blacklisted person" placement="top" arrow>
      <Chip
        size="small"
        variant="outlined"
        color="error"
        className={classes.root}
        label="B"
        classes={{
          label: classes.label,
        }}
      />
    </Tooltip>
  );
};

export default BlacklistIcon;
