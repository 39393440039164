import ConfigConstant from "core/constants/ConfigConstant";
import RouterConstants from "core/routes/constants";
import RouterUtils from "core/routes/utils";
import { createTableCell } from "core/utils/tableHandler";
import { AccountContext } from "modules/Account/context";
import CreateWebhookDialog from "modules/Webhook/components/CreateWebhookDialog";
import { WebhookEventLabels, WebhookQuery } from "modules/Webhook/models";
import WebhookService from "modules/Webhook/services";
import WebhookUtils from "modules/Webhook/utils";
import React from "react";
import { useQuery } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import { Chip } from "ui-kit/atoms/Chip";
import PageHeader from "ui-kit/components/PageHeader";
import Table from "ui-kit/components/Table";
import TableBodyCell from "ui-kit/components/TableBodyCell";

const rowsPerPage = ConfigConstant.PAGE_SIZE.MEDIUM;
const title = "Webhooks";
const heads = [
  {
    id: "name",
    percentage: true,
    width: 18,
    label: "Name",
  },
  {
    id: "event",
    percentage: true,
    width: 32,
    label: "Event",
  },
  {
    id: "endpoint",
    percentage: true,
    width: 38,
    label: "Endpoint",
  },
  {
    id: "status",
    percentage: true,
    width: 12,
    label: "Status",
  },
];

const WebhookList = (): React.ReactElement => {
  const location = useLocation();
  const history = useHistory();
  const [page, setPage] = React.useState<number>(ConfigConstant.INITIAL_PAGE);
  const [openDialog, toggleDialog] = React.useState(false);

  const {
    account: { id: accountId },
  } = React.useContext(AccountContext);

  // Route - if goes to search/new, open dialog
  React.useEffect(() => {
    const isNewWebhookRoute = RouterUtils.isCurrentRoute(
      location.pathname,
      RouterConstants.INTEGRATION.WEBHOOK.NEW
    );
    if (isNewWebhookRoute && openDialog === false) {
      toggleDialog(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const fetchWebhooks = async (p: number) => {
    try {
      const { data } = await WebhookService.fetchWebhooks(
        accountId,
        p,
        rowsPerPage
      );
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };
  const { data, isFetching } = useQuery(
    [WebhookQuery.webhooks, page, accountId],
    () => fetchWebhooks(page),
    {
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const actionProps = {
    text: "Add webhook",
    onClick: () => {
      history.push(RouterConstants.INTEGRATION.WEBHOOK.NEW);
    },
  };

  return (
    <>
      <PageHeader
        title={title}
        body="Webhooks allow you to easily push your prospects data to your CRM or marketing platform like HubSpot, Pipedrive or Salesforce."
        actionProps={actionProps}
        helperProps={{
          link: RouterConstants.DOCS.INTEGRATIONS.WEBHOOKS,
        }}
        backlinkProps={{
          text: "Integrations",
          link: RouterConstants.INTEGRATION.ROOT,
        }}
      />
      <Table
        title={title}
        heads={heads}
        rows={
          data?.results
            ? data.results.map((webhook) => {
                const link = RouterConstants.INTEGRATION.WEBHOOK.detail(
                  webhook.id
                );
                const { icon: Icon, ...chipData } =
                  WebhookUtils.getWebhookActiveChipData(webhook.active);
                return {
                  name: webhook.id.toString(),
                  data: [
                    createTableCell(webhook.name, link, "main", TableBodyCell),
                    createTableCell(
                      WebhookEventLabels[webhook.event],
                      link,
                      "default",
                      TableBodyCell
                    ),
                    createTableCell(
                      webhook.endpoint,
                      link,
                      "default",
                      TableBodyCell
                    ),
                    createTableCell(
                      <Chip
                        variant="outlined"
                        size="small"
                        {...chipData}
                        icon={<Icon />}
                      />,
                      link,
                      "element",
                      TableBodyCell
                    ),
                  ],
                };
              })
            : []
        }
        count={data?.count || 0}
        // INITIAL_PAGE starts at 1, but Pagination starts at 0
        page={data?.page || page - 1}
        setPage={setPage}
        isFetching={isFetching}
        rowsPerPage={rowsPerPage}
      />
      <CreateWebhookDialog open={openDialog} toggleOpen={toggleDialog} />
    </>
  );
};

export default WebhookList;
