/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "ui-kit/atoms/Switch";
import FormGroup from "@mui/material/FormGroup";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import clsx from "clsx";
import { SwitchProps } from "@mui/material";

const useStyles = makeStyles((theme: Theme) => ({
  switch: {
    marginLeft: theme.spacing(-2),
  },
  positionRight: {
    flexDirection: "row-reverse",
    justifyContent: "flex-end",
    marginLeft: 0,
    marginRight: 0,
  },
  label: {
    width: "fit-content",
  },
}));

interface IControllerProps {
  value: boolean | number;
  onChange: (v: boolean) => void;
  name: string;
  label: string | [string, string] | React.ReactElement;
  sx?: SwitchProps["sx"];
  ElementProps?: {
    color?: SwitchProps["color"];
    header?: boolean;
    positionRight?: boolean;
    onChangeHandler?: (v: any) => boolean;
    onCheckedHandler?: (v: any) => boolean;
    disabled?: SwitchProps["disabled"];
  };
}

const getLabel = (value: boolean, label: IControllerProps["label"]) => {
  if (label instanceof Array) {
    return value ? label[0] : label[1];
  }
  return label;
};

const SwitchField = React.forwardRef<HTMLButtonElement, IControllerProps>(
  function TextField(
    { value, onChange, name, label, sx, ElementProps }: IControllerProps,
    ref
  ): React.ReactElement {
    const classes = useStyles();
    return (
      <FormGroup sx={sx}>
        <FormControlLabel
          classes={{
            root: classes.label,
          }}
          aria-labelledby={name}
          control={
            <Switch
              ref={ref}
              onChange={(e) =>
                onChange(
                  ElementProps?.onChangeHandler
                    ? ElementProps.onChangeHandler(e.target.checked)
                    : Boolean(e.target.checked)
                )
              }
              checked={
                ElementProps?.onCheckedHandler
                  ? ElementProps.onCheckedHandler(value)
                  : Boolean(value)
              }
              color={ElementProps?.color || "secondary"}
            />
          }
          className={clsx(classes.switch, {
            [classes.positionRight]: ElementProps?.positionRight,
          })}
          label={
            <Typography
              variant={ElementProps?.header ? "h6" : "subtitle2"}
              component={ElementProps?.header ? "h6" : "span"}
              sx={
                ElementProps?.positionRight
                  ? { mr: ElementProps?.header ? 2 : 0.5 }
                  : { ml: 1 }
              }
            >
              {getLabel(Boolean(value), label)}
            </Typography>
          }
          disableTypography
          disabled={ElementProps?.disabled}
        />
      </FormGroup>
    );
  }
);

SwitchField.defaultProps = {
  ElementProps: {},
};

export default SwitchField;
