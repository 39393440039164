import { yupResolver } from "@hookform/resolvers/yup";
import { Box, DialogActions, DialogContent, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import ConfigConstant from "core/constants/ConfigConstant";
import RouterConstants from "core/routes/constants";
import { errorHandler, IErrorResponse } from "core/utils/errorHandler";
import get from "lodash/get";
import AccountSeatlessAutocomplete from "modules/Account/components/AccountSeatlessAutocomplete";
import { AccountQuery } from "modules/Account/models";
import CountryAutocomplete from "modules/Account/pages/CreateAccount/components/CountryAutocomplete";
import AccountService from "modules/Account/services";
import { ISeatFormInput, ISeatFormValues } from "modules/Payment/models";
import PaymentService from "modules/Payment/services";
import PaymentValidations from "modules/Payment/validations";
import React from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import Button from "ui-kit/atoms/Button";
import DialogHeader from "ui-kit/components/DialogHeader";

type ParamTypes = {
  subscriptionId: string;
};

interface TransferSubscriptionDialogProps {
  open: boolean;
  setToggle: () => void;
}

const defaultValues = { account: null, country: null };

const TransferSubscriptionDialog = ({
  open,
  setToggle,
}: TransferSubscriptionDialogProps): React.ReactElement => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const { subscriptionId } = useParams<ParamTypes>();
  const handleClose = () => {
    setToggle();
  };

  const [proxyExists, setProxyExists] = React.useState<boolean | undefined>(
    undefined
  );

  const schema = PaymentValidations.transferAccountSchema(proxyExists);

  const { control, errors, handleSubmit, setError, reset, watch } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const accountId = get(watch("account"), "id");

  const { data: dataAccount } = useQuery(
    [AccountQuery.account, accountId],
    async () => {
      try {
        const response = await AccountService.fetchAccount(Number(accountId));
        return response.data;
      } catch (err) {
        throw new Error(String(err));
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: !!accountId,
    }
  );

  React.useEffect(() => {
    if (!!dataAccount) {
      setProxyExists(!!dataAccount.proxy_id);
    }
  }, [dataAccount]);

  React.useEffect(() => {
    if (!accountId) {
      setProxyExists(undefined);
    }
  }, [accountId]);

  const { data: dataProxy } = useQuery(
    ["proxy", { proxyId: dataAccount?.proxy_id, accountId }],
    async () => {
      try {
        const response = await AccountService.fetchProxy(dataAccount?.proxy_id);
        return response.data;
      } catch (err) {
        throw new Error(String(err));
      }
    },
    {
      enabled: !!dataAccount?.proxy_id,
    }
  );

  const mutateChangeAccount = useMutation(
    (newData: ISeatFormValues) =>
      PaymentService.changeSubscription(subscriptionId, newData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["subscription", subscriptionId]);
        history.push(RouterConstants.BILLING.detail(subscriptionId));
        reset(defaultValues);
        setToggle();
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response, setError);
      },
    }
  );

  const onSubmit = (data: ISeatFormInput) => {
    if (
      window.confirm(
        "Are you sure you want to transfer this subscription to another LinkedIn account? You won't be allowed to transfer the subscription for the next 2 weeks."
      )
    ) {
      mutateChangeAccount.mutate({
        country:
          proxyExists && dataProxy?.country
            ? dataProxy?.country
            : data.country.id,
        seat_object_id: data.account.id,
        seat_content_type: Number(ConfigConstant.SEAT_CONTENT_TYPE_ID),
      });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      keepMounted
      PaperProps={{ sx: { width: "100%" } }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogHeader
          title="Transfer subscription"
          onHandleClose={handleClose}
        />
        <DialogContent dividers>
          <Typography variant="body2" paragraph>
            Select from the list of available accounts without a subscription.
            Be aware that you will lose access to the data of the account from
            which the subscription will be transferred.
          </Typography>
          <AccountSeatlessAutocomplete control={control} errors={errors} />
          {proxyExists === false && (
            <Box mt={3}>
              <Typography variant="body2" mb={3}>
                Select a country from where your account usually connects
              </Typography>
              <CountryAutocomplete control={control} errors={errors} />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button type="submit" variant="contained" color="primary">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default TransferSubscriptionDialog;
