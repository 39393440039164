import axios, { AxiosResponse } from "axios";
import {
  IWebhook,
  IWebhooks,
  IWebhookFormValues,
  IWebhookResponse,
} from "../models";

const fetchWebhooks = (
  accountId: number,
  page: number,
  pageSize: number
): Promise<AxiosResponse<IWebhooks>> =>
  axios.get(
    `/inevitable/webhook_settings/?page=${page}&page_size=${pageSize}&account_id=${accountId}`
  );

const fetchCampaignWebhooks = (
  page: number,
  pageSize: number,
  campaignId: number
): Promise<AxiosResponse<IWebhooks>> =>
  axios.get(
    `/inevitable/webhook_settings/?page=${page}&page_size=${pageSize}&campaign_id=${campaignId}`
  );

const fetchWebhook = (id: number): Promise<AxiosResponse<IWebhook>> =>
  axios.get(`/inevitable/webhook_settings/${id}`);

const createWebhook = (
  data: IWebhookFormValues
): Promise<AxiosResponse<IWebhook>> =>
  axios.post("/inevitable/webhook_settings/", data);

const updateWebhook = (
  id: number,
  data: IWebhookFormValues
): Promise<AxiosResponse<IWebhook>> =>
  axios.patch(`/inevitable/webhook_settings/${id}`, data);

const testWebhook = (id: number): Promise<AxiosResponse<IWebhookResponse>> =>
  axios.get(`/inevitable/webhook_settings/${id}/test/`);

const deleteWebhook = (id: number): Promise<AxiosResponse<IWebhook>> =>
  axios.delete(`/inevitable/webhook_settings/${id}/`);

const WebhookService = {
  fetchWebhooks,
  fetchCampaignWebhooks,
  fetchWebhook,
  createWebhook,
  updateWebhook,
  testWebhook,
  deleteWebhook,
};

export default WebhookService;
