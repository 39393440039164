import CheckCircle from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { Box, ListItemIcon, Theme, Typography } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import clsx from "clsx";
import { IGetStartedListItem } from "modules/Dashboard/models";
import React from "react";
import Button from "ui-kit/atoms/Button";

const useStyles = makeStyles((theme: Theme) => ({
  item: {},
  icon: {
    minWidth: 30,
  },
  successIcon: {
    color: theme.palette.success.main,
  },
  incompleteIcon: {
    color: theme.palette.primary.main,
  },
  tag: {
    fontWeight: 500,
  },
  successTag: {
    textDecoration: "line-through",
    color: theme.app.palette.action.color,
  },
  incompleteTag: {},
}));

interface GetStartedListItemProps {
  item: IGetStartedListItem;
  index: number;
  selected: boolean;
  isLast: boolean;
  // total: number;
  // accountId: number;
  // selected: boolean;
}

const GetStartedListItem = ({
  item,
  index,
  selected,
  isLast,
}: // total,
// accountId,
// selected,
// setSelectedItem,
// incompleteItemLabels,
// setIncompleteItemLabel,
GetStartedListItemProps): React.ReactElement | null => {
  const {
    title,
    body,
    done,
    buttonLabel,
    id,
    skipEnabled,
    skipHandler,
    handler,
  } = item;
  const classes = useStyles();
  const theme = useTheme();

  // const fetchGetStarted = async () => {
  //   try {
  //     const { data } = await func(accountId);
  //     return data;
  //   } catch (err) {
  //     throw new Error(err);
  //   }
  // };
  // const { data, isLoading } = useQuery(
  //   [`getStarted-${label.toLowerCase()}`, accountId],
  //   () => fetchGetStarted(),
  //   { keepPreviousData: true }
  // );

  // React.useEffect(() => {
  //   if (!finishedItem && !isLoading && !incompleteItemLabels.includes(label)) {
  //     setIncompleteItemLabel([...incompleteItemLabels, label]);
  //   }
  // }, [
  //   setIncompleteItemLabel,
  //   incompleteItemLabels,
  //   finishedItem,
  //   label,
  //   isLoading,
  // ]);

  const listItem = (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
      }}
    >
      <ListItemIcon
        className={clsx(
          classes.icon,
          classes[done ? "successIcon" : "incompleteIcon"]
        )}
      >
        {done ? (
          <CheckCircle
            sx={{ color: theme.palette.success.light, fontSize: 21 }}
          />
        ) : (
          <RadioButtonUncheckedIcon
            sx={{ color: theme.app.palette.action.check, fontSize: 21 }}
          />
        )}
      </ListItemIcon>
      <Typography
        variant="body2"
        className={clsx(
          classes.tag,
          classes[done ? "successTag" : "incompleteTag"]
        )}
        sx={{ fontWeight: 600 }}
      >
        {index + 1}. {title}
      </Typography>
    </Box>
  );

  if (selected) {
    return (
      <Box
        py={4}
        px={5}
        mb={isLast ? 0 : 3}
        mt={index === 0 ? 0 : 3}
        sx={{
          backgroundColor: theme.palette.common.white,
          border: `1px solid ${theme.app.palette.shadow.secondary}`,
          borderRadius: 3,
        }}
      >
        {listItem}
        <Box mt={3}>
          <Typography variant="body2">{body}</Typography>
        </Box>
        {!done && (
          <Box
            mt={3}
            mb={1}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: 4,
            }}
          >
            <Button
              size="medium"
              variant="contained"
              color="primary"
              onClick={handler}
            >
              {buttonLabel}
            </Button>
            {skipEnabled && (
              <Typography
                variant="body2"
                color="action.color"
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
                onClick={skipHandler}
              >
                Skip it
              </Typography>
            )}
          </Box>
        )}
      </Box>
    );
  }

  if (done) {
    return (
      <Box
        sx={{
          padding: theme.spacing(0, 5),
        }}
        mb={2}
      >
        {listItem}
      </Box>
    );
  }

  return null;
};

export default GetStartedListItem;
