import React from "react";
import { useLocation } from "react-router-dom";
import PageAlert from "ui-kit/components/PageAlert";

interface ErrorFallbackProps {
  error: Error;
  resetError(): void;
}

const ErrorFallback = ({ error, resetError }: ErrorFallbackProps) => {
  const location = useLocation();

  // Fail on the first update
  const firstUpdateState = React.useRef(true);
  React.useEffect(() => {
    // Fail when only error shown
    if (firstUpdateState.current) {
      firstUpdateState.current = false;
      return undefined;
    }

    // Reset on actual navigation away from the page

    resetError();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.pathname]);

  return (
    <PageAlert
      title="Even the things we love break sometimes 💔"
      body="Our team was notified of your issue and we're working on fixing it 🧑🏽‍💻 Thanks for your patience."
    />
  );
};

export default ErrorFallback;
