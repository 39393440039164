import React from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import get from "lodash/get";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQueryClient, useMutation } from "react-query";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import ElementField from "core/components/ElementField";
import RouterConstants from "core/routes/constants";
import { GlobalContext } from "core/context";
import { GlobalActionType } from "core/reducers";
import { snackbarHandler } from "core/utils/snackbarHandler";
import {
  errorHandler,
  handleNonFieldErrors,
  IErrorResponse,
} from "core/utils/errorHandler";
import {
  ITeamInviteFormInput,
  ITeamInviteFormValues,
  ITeamInvitesFormValues,
  TeamRoles,
} from "modules/Team/models";
import TeamService from "modules/Team/services";
import AccountTableSelect from "modules/Account/components/AccountTableSelect";
import TeamValidations from "modules/Team/validations";
import DialogHeader from "ui-kit/components/DialogHeader";
import Button from "ui-kit/atoms/Button";
import FormField from "ui-kit/components/FormField";
import MemberRolesActionSelector from "../MemberRolesActionSelector";

interface CreateInvitationProps {
  open: boolean;
  toggleOpen: (open: boolean) => void;
}

const defaultValues = {
  invitee_by_email: "",
  accounts: [],
  note: "",
};

const CreateInvitation = ({
  open,
  toggleOpen,
}: CreateInvitationProps): React.ReactElement => {
  const history = useHistory();
  const queryClient = useQueryClient();

  const [checked, setChecked] = React.useState<TeamRoles>(TeamRoles.MA);

  const {
    dispatch,
    global: { selected },
  } = React.useContext(GlobalContext);

  const schema = TeamValidations.inviteFormSchema();

  const { control, errors, handleSubmit, setError, reset, setValue } =
    useForm<ITeamInviteFormInput>({
      resolver: yupResolver(schema),
      defaultValues,
    });

  const resetSelected = () => {
    dispatch({
      type: GlobalActionType.SET_GLOBAL,
      payload: { selected: [] },
    });
  };

  const handleClose = () => {
    toggleOpen(false);
    history.push(RouterConstants.TEAM.ALL);
    reset();
    resetSelected();
  };

  // Set selected accounts from table
  React.useEffect(() => {
    setValue(
      "accounts",
      selected.map((x) => x.name)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const mutateInvites = useMutation(
    (data: ITeamInvitesFormValues) => TeamService.createTeamInvites(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["invites"]);
        snackbarHandler.success("Invite sent!");
        resetSelected();
        handleClose();
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response, setError);

        // Custom error handling for non_field_errors
        const nonFieldErrors = get(
          error.response,
          "data.items[0].non_field_errors"
        );
        handleNonFieldErrors(nonFieldErrors);
      },
    }
  );

  const onSubmit = (data: ITeamInviteFormInput) => {
    const emails = data?.invitee_by_email.replace(/ /g, "").split(",");
    const accounts = data?.accounts;

    // Create invites from all emails, accounts
    const invites: ITeamInvitesFormValues = {
      items: [],
    };

    // Iterate over each email and every selected account
    emails.forEach((email) => {
      accounts.forEach((account) => {
        // Generate invites

        const invitation: ITeamInviteFormValues = {
          invitee_by_email: email,
          account: Number(account),
          role: checked,
          notify_by_email: true,
          note: data.note,
        };

        invites.items.push(invitation);
      });
    });

    // Bulk create invites
    mutateInvites.mutate(invites);
  };

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleClose}
      aria-labelledby="create-invitation"
      maxWidth="lg"
      PaperProps={{ sx: { width: "100%" } }}
    >
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <DialogHeader title="Invite team members" onHandleClose={handleClose} />

        <DialogContent dividers sx={{ backgroundColor: "white", padding: 0 }}>
          <Grid container>
            <Grid item md={6} xs={12} sx={{ pt: 8, pb: 2, px: 10 }}>
              <FormField text="1. Select accounts to whom you want to give access">
                <ElementField
                  component={AccountTableSelect}
                  name="accounts"
                  control={control}
                  error={errors?.accounts}
                />
              </FormField>
            </Grid>
            <Divider
              orientation="vertical"
              flexItem
              style={{ marginRight: "-1px" }}
            />
            <Grid item md={6} xs={12} sx={{ pt: 8, pb: 2, px: 10 }}>
              <FormField text="2. Enter team member emails (comma separated)">
                <ElementField
                  InputProps={{
                    fullWidth: true,
                    id: "invitee_by_email",
                    label: "Email address",
                    placeholder: "adi@example.com, jon@example, etc.",
                    variant: "outlined",
                    autoComplete: "new-password",
                  }}
                  name="invitee_by_email"
                  control={control}
                  error={errors.invitee_by_email}
                />
              </FormField>
              <FormField text="3. Select team member role" disableTextBottom>
                <MemberRolesActionSelector
                  checked={checked}
                  setChecked={setChecked}
                />
              </FormField>
              <FormField text="4. Add note (optional)">
                <ElementField
                  InputProps={{
                    fullWidth: true,
                    id: "note",
                    label: "Note",
                    placeholder: "Hey Adi, giving you access to my account...",
                    variant: "outlined",
                    autoComplete: "new-password",
                  }}
                  name="note"
                  control={control}
                  error={errors.note}
                />
              </FormField>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button type="submit" variant="contained" color="primary">
            Send invites
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateInvitation;
