import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import axios from "axios";
import ConfigConstant from "core/constants/ConfigConstant";
import RouterConstants from "core/routes/constants";
import { handleInvalidToken } from "core/utils/errorHandler";
import { createBrowserHistory } from "history";
import "index.css";
import { get } from "lodash";
import { getUser } from "modules/User/utils";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

// Initialize Sentry
Sentry.init({
  dsn: ConfigConstant.SENTRY_URL,
  autoSessionTracking: true,
  integrations: [new BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  environment: ConfigConstant.ENV,
});

// Define Global Axios
axios.defaults.baseURL = ConfigConstant.API_ROOT;
axios.defaults.headers.common["Content-Type"] = "application/json";

// Logout user if the token is invalid
if (ConfigConstant.ENV === "production" || ConfigConstant.ENV === "staging") {
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      const errData = get(error, "response.data");

      // Handle if account doesn't have access - moved owner / viewer access
      if (
        get(errData, "code") === "permission_denied" ||
        get(errData, "account.0.code") === "does_not_exist"
      ) {
        const history = createBrowserHistory();
        history.push(RouterConstants.ACCOUNT.NO_PERMISSION);
        // eslint-disable-next-line no-restricted-globals
        location.reload();
      }

      const user = getUser();
      const data = {
        extra: { user, headers: error.config.headers },
      };

      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx

        Sentry.captureException(error.response, data);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        Sentry.captureException(error.request, data);
      } else {
        // Something happened in setting up the request that triggered an Error
        Sentry.captureException(error.message, data);
      }

      // Unauthorized - most likely token expired or is invalid
      if (error.response?.status === 401) {
        handleInvalidToken(error);
      }

      return Promise.reject(error);
    }
  );
}

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<>An error has occurred</>}>
      <App />
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();
