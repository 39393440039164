import React from "react";
import { FieldError, Control } from "react-hook-form";
import CompanyService from "modules/Company/services";
import CompanyValidation from "modules/Company/validations";
import AutocompleteCreateField from "core/components/AutocompleteCreateField/component";
import NewCompanyDialog from "../NewCompanyDialog";

interface CompanyAutocompleteProps {
  control: Control;
  error: FieldError | undefined;
}

const CompanyAutocomplete = ({
  control,
  error,
}: CompanyAutocompleteProps): React.ReactElement => {
  const schema = CompanyValidation.companyFormSchema("companyName");

  return (
    <AutocompleteCreateField
      control={control}
      error={error}
      name="company"
      queryName="companies"
      schema={schema}
      queryFunc={CompanyService.fetchAllCompanies}
      dialog={NewCompanyDialog}
      defaultValues={{
        company: "",
      }}
      disableCreateButton
    />
  );
};

export default CompanyAutocomplete;
