import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Box, Skeleton } from "@mui/material";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/styles";
import StepHandler from "core/components/StepHandler";
import RouterConstants from "core/routes/constants";
import RouterUtils from "core/routes/utils";
import { getDisabledStep } from "core/utils/commonHandler";
import { differenceInUnits } from "core/utils/dateHandler";
import { errorHandler, IErrorResponse } from "core/utils/errorHandler";
import { snackbarHandler } from "core/utils/snackbarHandler";
import { get } from "lodash";
import { AccountContext } from "modules/Account/context";
import useUpdateOnboarding from "modules/Account/hooks/useUpdateOnboarding";
import { IAccountOnboardingIds } from "modules/Account/models";
import ActionService from "modules/Action/services";
import CampaignHeader from "modules/Campaign/components/CampaignHeader";
import CreateAudience from "modules/Campaign/components/CreateAudience";
import CreateReview from "modules/Campaign/components/CreateReview";
import CreateSteps from "modules/Campaign/components/CreateSteps";
import {
  CampaignCreateStepLabels,
  CampaignDetailTabs,
  CampaignQuery,
  CreateCampaignStepIndex,
  CreateCampaignSteps,
  ExecutionKeys,
  ICreateCampaignProps,
} from "modules/Campaign/models";
import CampaignService from "modules/Campaign/services";
import {
  CAMPAIGN_STEPS,
  useCreateCampaignStyles,
} from "modules/Campaign/utils";
import SequenceConstants from "modules/Sequence/constants";
import { ISequenceMissingAction } from "modules/Sequence/models";
import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Link, useHistory, useParams } from "react-router-dom";
import Loader from "ui-kit/components/Loader";
import NotFound from "ui-kit/components/NotFound";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ISteps = [string, React.FunctionComponent<any>];

function createStep(...step: ISteps) {
  return {
    title: step[0],
    component: step[1],
  };
}

const STEPS = [
  createStep(CampaignCreateStepLabels.steps, CreateSteps),
  createStep(CampaignCreateStepLabels.audience, CreateAudience),
  createStep(CampaignCreateStepLabels.review, CreateReview),
];

function getSteps(): string[] {
  return STEPS.map((s) => s.title);
}

function getStepContent(
  props: ICreateCampaignProps
): React.ReactElement | null {
  const StepComponent = STEPS[props.activeStep]?.component;
  return StepComponent ? (
    <StepComponent {...props} />
  ) : (
    <Typography variant="body2">
      This page doesn&apos;t exist. Go back to -{" "}
      <Link to={RouterConstants.CAMPAIGN.ALL}>Go back to campaigns.</Link>
    </Typography>
  );
}

type ParamTypes = {
  campaignId: string;
  tabName: string;
};

const NewCampaign = (): React.ReactElement => {
  const classes = useCreateCampaignStyles();
  const theme = useTheme();
  const history = useHistory();
  const queryClient = useQueryClient();
  const params = useParams<ParamTypes>();
  const { mutate: updateOnboarding } = useUpdateOnboarding();

  const campaignId: number = +params.campaignId;
  // Enabled step marks which steps is the highest available
  const [enabledStep, setEnabledStep] = React.useState(
    CreateCampaignStepIndex.steps
  );

  const activeStepLabel =
    (params.tabName as CreateCampaignSteps) || CreateCampaignSteps.steps;
  const activeStep = CreateCampaignStepIndex[activeStepLabel];

  const steps = getSteps();
  const { account } = React.useContext(AccountContext);
  const { id: accountId } = account;

  const mutateLaunchCampaign = useMutation(
    () =>
      CampaignService.updateCampaign(campaignId, {
        execution: ExecutionKeys.RU,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([CampaignQuery.campaign, campaignId]);

        if (!get(account, "meta_data.onboarding.campaign_launched")) {
          updateOnboarding({
            variant: IAccountOnboardingIds.campaign_launched,
            value: true,
          });
        }

        history.push(
          RouterConstants.CAMPAIGN.detail(
            campaignId,
            CampaignDetailTabs.audience
          )
        );
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);
      },
    }
  );

  // Goes one level further & is disabled if next step isn't fulfilled
  const onHandleNext = () => {
    // Review - if last step, update campaign as active
    if (activeStep === CreateCampaignStepIndex.review) {
      return mutateLaunchCampaign.mutate();
    }

    // If next step is disabled
    if (enabledStep <= activeStep) {
      if (activeStep === CreateCampaignStepIndex.audience) {
        return snackbarHandler.error(
          "Please add at least 1 prospect to continue."
        );
      }

      if (activeStep === CreateCampaignStepIndex.steps) {
        if (dataSequence?.count === 1) {
          return snackbarHandler.error(
            `Please add at least 1 step to continue.`
          );
        }

        dataSequence?.results.forEach((campaignSequence, index) => {
          const actionExists = !!dataActions?.results.find((action) => {
            return action.sequence === campaignSequence.sequence.id;
          });

          if (!actionExists) {
            const variantLabel = SequenceConstants.ALPHABET[index].label;
            return snackbarHandler.error(
              `Please add at least 1 step to variant ${variantLabel} to continue.`
            );
          }
        });

        return;
      }
    }

    if (
      activeStep === CreateCampaignStepIndex.steps &&
      !get(account, "meta_data.onboarding.campaign_sequence")
    ) {
      updateOnboarding({
        variant: IAccountOnboardingIds.campaign_sequence,
        value: true,
      });
    }

    if (
      activeStep === CreateCampaignStepIndex.audience &&
      !get(account, "meta_data.onboarding.campaign_search")
    ) {
      updateOnboarding({
        variant: IAccountOnboardingIds.campaign_search,
        value: true,
      });
    }

    const route = RouterConstants.CAMPAIGN.newTab(
      campaignId,
      String(CAMPAIGN_STEPS[activeStep + 1].name)
    );
    history.push(route);
  };

  // Goes one level back & is disabled if no previous steps
  const onHandleBack = () => {
    const route = RouterConstants.CAMPAIGN.newTab(
      campaignId,
      String(CAMPAIGN_STEPS[activeStep - 1].name)
    );
    history.push(route);
  };

  const {
    data: dataCampaign,
    error,
    isLoading: isLoadingCampaign,
  } = useQuery(
    [CampaignQuery.campaign, campaignId],
    async () => {
      try {
        const response = await CampaignService.fetchCampaign(campaignId);
        return response.data;
      } catch (err) {
        throw new Error(String(err));
      }
    },
    {
      enabled: !!campaignId,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  const { data: dataSearch, isLoading: isLoadingSearch } = useQuery(
    [CampaignQuery.searches_exist, campaignId],
    async () => {
      try {
        const response = await CampaignService.fetchExistingCampaignSearches(
          campaignId
        );
        return response.data;
      } catch (err) {
        throw new Error(String(err));
      }
    },
    {
      enabled: !!campaignId,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  const { data: dataInteractions, isLoading: isLoadingInteractions } = useQuery(
    [CampaignQuery.interactions_exist, campaignId],
    async () => {
      try {
        const response =
          await CampaignService.fetchExistingCampaignInteractions(campaignId);
        return response.data;
      } catch (err) {
        throw new Error(String(err));
      }
    },
    {
      enabled: !!campaignId,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const { data: dataSequence } = useQuery(
    [CampaignQuery.sequences, campaignId],
    async () => {
      try {
        const response = await CampaignService.fetchAllCampaignSequences(
          campaignId
        );
        return response.data;
      } catch (err) {
        throw new Error(String(err));
      }
    },
    {
      enabled: !!campaignId,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  const [sequenceIds, setSequenceIds] = React.useState<undefined | number[]>(
    undefined
  );

  React.useEffect(() => {
    const newSequenceIds = dataSequence?.results.map(
      (campaignSequence) => campaignSequence.sequence.id
    );
    if (newSequenceIds !== sequenceIds) {
      setSequenceIds(newSequenceIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSequence]);

  const { data: dataActions } = useQuery(
    [CampaignQuery.actions, campaignId, sequenceIds],
    async () => {
      try {
        const response = await ActionService.fetchAllActions(sequenceIds);
        return response.data;
      } catch (err) {
        throw new Error(String(err));
      }
    },
    {
      enabled: !!dataSequence && dataSequence.count > 0 && !!sequenceIds,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const handleStepRedirect = (index: number, disabled: boolean) => {
    if (!disabled) {
      history.replace(
        RouterConstants.CAMPAIGN.newTab(
          campaignId,
          CreateCampaignStepIndex[index]
        )
      );
    }
  };

  // const mutateCampaignRemove = useMutation(
  //   () => CampaignService.deleteCampaign(campaignId),
  //   {
  //     onSuccess: () => {
  //       queryClient.invalidateQueries(CampaignQuery.campaigns);
  //       queryClient.invalidateQueries([CampaignQuery.campaign, campaignId]);

  //       // No redirect as user is already leaving the window
  //     },
  //     onError: (error: IErrorResponse) => {
  //       errorHandler(error.response);
  //     },
  //   }
  // );

  // Check if for each sequence there is at least 1 action
  // Start by default as undefined, to know when it's finished checking
  const [isMissingAction, setIsMissingAction] =
    React.useState<ISequenceMissingAction>(undefined);

  React.useEffect(() => {
    let isNewMissingAction = false;

    if (!!dataSequence && dataSequence.count === 0) {
      setIsMissingAction(true);
      return;
    }

    if (dataSequence && dataActions) {
      dataSequence?.results.forEach((campaignSequence) => {
        const actionExists = !!dataActions?.results.find((action) => {
          return action.sequence === campaignSequence.sequence.id;
        });

        if (!actionExists) {
          isNewMissingAction = true;
          return;
        }
      });

      setIsMissingAction(isNewMissingAction);
    }
  }, [dataSequence, dataActions]);

  // If passed account, fetch account data otherwise no loading
  const isLoading =
    isLoadingCampaign ||
    isLoadingSearch ||
    isLoadingInteractions ||
    isMissingAction === undefined;

  // Check if campaign was ever updated, if not mark for removal
  const isCampaignUpdated = React.useRef<boolean | null>(null);

  React.useEffect(() => {
    if (
      !dataInteractions ||
      !dataSearch ||
      !dataCampaign?.updated ||
      isLoading
    ) {
      return;
    }

    if (
      dataInteractions?.count ||
      dataSearch?.count ||
      isMissingAction === false
    ) {
      isCampaignUpdated.current = true;
      return;
    }

    // If created and updated changed, there was an update made
    if (differenceInUnits(dataCampaign?.created, dataCampaign?.updated, "s")) {
      isCampaignUpdated.current = true;
      return;
    }

    // Nothing has changed, mark as not updated campaign
    isCampaignUpdated.current = false;
    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dataCampaign?.updated,
    dataInteractions,
    dataSearch,
    isMissingAction,
    isLoading,
  ]);

  // TODO: Disable for testing at least
  // // Remove campaign if created by no change made before leaving the window
  // React.useEffect(() => {
  //   // on componentWillUnmount
  //   return () => {
  //     if (isCampaignUpdated.current === false) {
  //       // Nothing has changed, remove campaign
  //       mutateCampaignRemove.mutate();
  //     }
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // Redirect to the first step if no data available
  React.useEffect(() => {
    const route = RouterConstants.CAMPAIGN.newTab(
      campaignId,
      CreateCampaignSteps.steps
    );
    if (
      campaignId === 0 &&
      !RouterUtils.isCurrentRoute(history.location.pathname, route) &&
      RouterUtils.isCurrentRoute(history.location.pathname, route)
    ) {
      setEnabledStep(0);
      history.replace(route);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId]);

  // Redirect to the correct step based on data
  // 3. step - has sequence, draft state
  // 2. step - has search or interactions, no campaign sequence
  // 1. step - campaign created, no search or interactions

  // Only redirect on the first run
  const firstUpdate = React.useRef(true);
  React.useEffect(() => {
    if (!firstUpdate.current) {
      return;
    }

    if (campaignId && !isLoading) {
      // Update firstUpdate to not run again
      firstUpdate.current = false;

      // 3. Step
      if (
        !!dataCampaign &&
        dataCampaign.execution === ExecutionKeys.DR &&
        // (!!dataSearch?.count || !!dataInteractions?.count) &&
        !!dataActions?.count &&
        !isMissingAction
      ) {
        return history.replace(
          RouterConstants.CAMPAIGN.newTab(
            campaignId,
            CreateCampaignSteps.review
          )
        );
      }

      // 2. Step
      if (!!dataCampaign && !isMissingAction && !dataSearch?.count) {
        return history.replace(
          RouterConstants.CAMPAIGN.newTab(
            campaignId,
            CreateCampaignSteps.audience
          )
        );
      }

      // 1. Step
      if (
        !!dataCampaign &&
        isMissingAction
        // && (!!dataSearch?.count || !!dataInteractions?.count)
      ) {
        return history.replace(
          RouterConstants.CAMPAIGN.newTab(campaignId, CreateCampaignSteps.steps)
        );
      }
    }
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId, isLoading, isMissingAction]);

  // Redirect on data update
  React.useEffect(() => {
    if (campaignId && !isLoading) {
      // 3. Step
      if (
        !!dataCampaign &&
        dataCampaign.execution === ExecutionKeys.DR &&
        // (!!dataSearch?.count || !!dataInteractions?.count) &&
        !!dataActions?.count &&
        !isMissingAction
      ) {
        // Go to fake launch step
        // We need to enable next step here without any action needed
        return setEnabledStep(CreateCampaignStepIndex.launch);
      }
      // 2. Step
      if (
        !!dataCampaign &&
        !isMissingAction &&
        (!!dataSearch?.count || !!dataInteractions?.count)
      ) {
        return setEnabledStep(CreateCampaignStepIndex.audience);
      }

      // 1. Step
      if (
        !!dataCampaign &&
        isMissingAction
        // && (!!dataSearch?.count || !!dataInteractions?.count)
      ) {
        return setEnabledStep(CreateCampaignStepIndex.steps);
      }
    }
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId, isLoading, dataSearch, dataCampaign, isMissingAction]);

  if (error) {
    return <NotFound label="campaign" link={RouterConstants.CAMPAIGN.ALL} />;
  }

  const stepper = (
    <Stepper
      activeStep={activeStep}
      connector={<ChevronRightIcon />}
      sx={{ mr: 6 }}
    >
      {steps.map((label, index) => {
        const stepProps: { completed?: boolean } = {};
        const labelProps: { optional?: React.ReactNode } = {};
        const disabled = getDisabledStep(enabledStep, activeStep, index);
        return (
          <Step
            key={label}
            onClick={() => handleStepRedirect(index, disabled)}
            // Disable future steps without filling current + disabled going back to rename campaign
            // completed={
            //   index < enabledStep && activeStep !== index && activeStep >= index
            // }
            disabled={disabled}
            classes={{ root: classes.stepRoot }}
            {...stepProps}
          >
            <StepLabel {...labelProps}>{label}</StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );

  return (
    <>
      <CampaignHeader
        campaignId={campaignId}
        campaignData={dataCampaign}
        titleProps={{ variant: "h6" }}
        customAction={
          <Box
            sx={{
              display: "flex",
              [theme.breakpoints.down("md")]: {
                flexDirection: "column",
                gap: 4,
              },
            }}
          >
            {stepper}
            {isLoading ? (
              <Skeleton width={130} height={40} color="action.icon" />
            ) : (
              <StepHandler
                activeStep={activeStep}
                onHandleBack={onHandleBack}
                onHandleNext={onHandleNext}
                disabledNext={enabledStep <= activeStep}
                hideBackButton={activeStep === CreateCampaignStepIndex.steps}
                {...(activeStep === CreateCampaignStepIndex.review && {
                  customNextText: "Launch",
                })}
                type="button"
              />
            )}
          </Box>
        }
      />

      {isLoading ? (
        <Loader minHeight={300} />
      ) : (
        getStepContent({
          accountId,
          campaignId,
          campaignName: dataCampaign?.name,
          activeStep,
          onHandleBack,
          onHandleNext,
          type: "button",
          isMissingAction,
        })
      )}
    </>
  );
};

export default NewCampaign;
