import React from "react";
import { Control, FieldErrors } from "react-hook-form";
import DraftEditor from "modules/Action/components/DraftEditor";
import ConfigConstant from "core/constants/ConfigConstant";
import { ActionStrategy, DirectActionStrategy } from "modules/Action/models";
import { DraftTypes } from "core/models";

interface ActionContentProps {
  control: Control;
  errors: FieldErrors;
  execution: ActionStrategy | DirectActionStrategy;
}

const ActionContent = ({
  control,
  errors,
  execution,
}: ActionContentProps): React.ReactElement | null => {
  switch (execution) {
    case ActionStrategy[
      "inevitable.strategy.action.LinkedInConnectionRequestStrategy"
    ]:
      return (
        <DraftEditor
          type={DraftTypes.multiline}
          maxlength={ConfigConstant.CHARACTER_COUNT}
          name="strategy_data"
          error={errors.strategy_data?.template}
          control={control}
          label="Message (optional)"
        />
      );
    case ActionStrategy["inevitable.strategy.action.LinkedInMessageStrategy"]:
      return (
        <DraftEditor
          type={DraftTypes.multiline}
          name="strategy_data"
          error={errors.strategy_data?.template}
          control={control}
          label="Message"
        />
      );
    case ActionStrategy[
      "inevitable.strategy.action.LinkedInGroupMessageStrategy"
    ]:
      return (
        <DraftEditor
          type={DraftTypes.multiline}
          name="strategy_data"
          error={errors.strategy_data?.template}
          control={control}
          label="Message"
        />
      );
    default:
      return null;
  }
};

export default ActionContent;
