import { Box, darken, Grid, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { CreateSearchInstructionProps } from "modules/Search/constants";
import { SearchContext } from "modules/Search/context";
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderLeft: `1px solid ${theme.app.palette.shadow.primary}`,
  },
  container: {
    boxSizing: "border-box",
    margin: "0px",
    minWidth: "0px",
    borderRadius: "5px",
    background: theme.palette.primary.light,
    padding: "18px 16px",
    minHeight: "180px",
    overflow: "hidden",
  },
  arrow: {
    boxSizing: "border-box",
    margin: "-5px 0px 0px",
    minWidth: "0px",
    position: "relative",
    left: "-65px",
    top: "27px",
    width: "137px",
  },
  wrapper: {
    boxSizing: "border-box",
    minWidth: "0px",
    flex: "1 1 0%",
    marginBottom: theme.spacing(5),
  },
  bar: {
    boxSizing: "border-box",
    minWidth: "0px",
    flex: "1 1 0%",
    marginLeft: theme.spacing(3),
  },
  browser: {
    height: "25px",
    borderRadius: "20px",
    border: `1px solid ${theme.palette.secondary.dark}`,
    whiteSpace: "nowrap",
    color: theme.app.palette.action.main,
    padding: "0px 8px",
    position: "relative",
    overflow: "hidden",
    background: theme.palette.common.white,
    marginTop: "4px !important",
    "&:after": {
      position: "absolute",
      content: '""',
      inset: "0px",
      background:
        "linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%)",
    },
  },
  browserMain: {
    boxSizing: "border-box",
    margin: "0px",
    minWidth: "0px",
    WebkitBoxAlign: "center",
    alignItems: "center",
  },
  url: {
    display: "inline-block",
    marginLeft: "8px",
    background: "rgb(185, 215, 251)",
    fontSize: "10px",
    lineHeight: "12px",
    flex: "1 1 0%",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  lockIcon: {
    display: "inline-block",
    width: 8,
  },
  link: {
    color: theme.app.palette.link,
    "&:hover": {
      color: darken(theme.app.palette.link, 0.4),
    },
  },
  logo: {
    width: 32,
    height: "auto",
  },
}));

interface SearchInstructionsProps {
  instructionProps: CreateSearchInstructionProps | undefined;
}

const SearchInstructions = ({
  instructionProps,
}: SearchInstructionsProps): React.ReactElement | null => {
  const classes = useStyles();

  const { search } = React.useContext(SearchContext);

  if (!instructionProps || !!search.columns) {
    return null;
  }

  const { icon, name, url, examples } = instructionProps;

  return (
    <Grid item md={6} pl={7.5} className={classes.root}>
      <img className={classes.arrow} src="/arrow.svg" alt="arrow points left" />
      <div className={classes.container}>
        <Box sx={{ display: "flex", alignItems: "flex-start" }}>
          <img className={classes.logo} src={icon} alt="search variant icon" />
          <div className={classes.bar}>
            <Box className={classes.wrapper}>
              <Typography component="p" sx={{ fontWeight: 600, fontSize: 10 }}>
                {name}
              </Typography>
              <div className={clsx(classes.browser, classes.browserMain)}>
                <svg
                  name="lock_solid"
                  width="8"
                  color="gray"
                  className={classes.lockIcon}
                  viewBox="0 0 16 16"
                  height="8"
                  xmlns="http://www.w3.org/2000/svg"
                  data-name="lock_solid"
                >
                  <g>
                    <path
                      fill="gray"
                      d="M13.667 6h-1V4.667A4.672 4.672 0 008 0a4.672 4.672 0 00-4.667 4.667V6h-1A.333.333 0 002 6.333v8.334C2 15.402 2.598 16 3.333 16h9.334c.735 0 1.333-.598 1.333-1.333V6.333A.333.333 0 0013.667 6zm-4.669 6.963a.334.334 0 01-.331.37H7.333a.333.333 0 01-.331-.37l.21-1.89A1.319 1.319 0 016.667 10c0-.735.598-1.333 1.333-1.333S9.333 9.265 9.333 10c0 .431-.204.824-.545 1.072l.21 1.891zM10.667 6H5.333V4.667A2.67 2.67 0 018 2a2.67 2.67 0 012.667 2.667V6z"
                      fill-rule="evenodd"
                    ></path>
                  </g>
                </svg>
                <div className={classes.url}>{url}</div>
              </div>
            </Box>
            <Box>
              {examples.map((example, index) => (
                <Box key={index} mb={index !== examples.length - 1 ? 4 : 0}>
                  <Typography
                    component="p"
                    variant="caption"
                    sx={{ fontWeight: 600 }}
                  >
                    {example.name}
                  </Typography>
                  {example.urls.map((url, index) => (
                    <Typography
                      component="p"
                      variant="caption"
                      sx={{ fontWeight: 500 }}
                      key={index}
                    >
                      <a
                        href={url}
                        target="_blank"
                        rel="noreferrer noopener"
                        className={classes.link}
                      >
                        {url}
                      </a>
                    </Typography>
                  ))}
                </Box>
              ))}
            </Box>
          </div>
        </Box>
      </div>
    </Grid>
  );
};

export default SearchInstructions;
