import { IArrayResponse, ICreateStepValues } from "core/models";
import { IPerson } from "modules/Person/models";
import { ISearch } from "modules/Search/models";
import { ISequence, ISequenceMissingAction } from "modules/Sequence/models";

export enum CampaignQuery {
  "campaign" = "campaign",
  "campaigns" = "campaigns",
  "campaigns_all" = "campaigns_all",
  "campaigns_stats" = "campaign_stats",
  "campaigns_exist" = "campaigns_exist",
  "sequences" = "campaign_sequences",
  "sequences_exist" = "campaign_sequences_exist",
  "searches" = "campaign_searches",
  "searches_exist" = "campaign_searches_exist",
  "interactions" = "campaign_interactions",
  "interactions_exist" = "campaign_interactions_exist",
  "campaigns_profile" = "campaigns_profile",
  "actions" = "campaign_actions",
  "stopped_person" = "campaign_stopped_person",
}

export enum ExecutionKeys {
  ST = "ST",
  RU = "RU",
  DR = "DR",
}

export enum ExecutionLabels {
  ST = "Paused",
  RU = "Active",
  DR = "Draft",
}

export enum CampaignDetailTabs {
  "audience" = "audience",
  "sequences" = "steps",
  "reports" = "reports",
  "settings" = "settings",
  "integrations" = "integrations",
}

export enum CreateCampaignSteps {
  "steps" = "steps",
  "audience" = "audience",
  "review" = "review",
}

export enum CreateCampaignStepIndex {
  "steps" = 0,
  "audience" = 1,
  "review" = 2,
  "launch" = 3,
}

export enum CampaignCreateStepLabels {
  "steps" = "Steps",
  "audience" = "Audience",
  "review" = "Review",
}

export enum CampaignSequenceVariant {
  "draft" = "draft",
  "finished" = "finished",
}

export enum CampaignLocale {
  "en" = "en",
  "af" = "af",
  "ar" = "ar",
  "az" = "az",
  "be" = "be",
  "bg" = "bg",
  "bn" = "bn",
  "bs" = "bs",
  "ca" = "ca",
  "cs" = "cs",
  "cy" = "cy",
  "da" = "da",
  "de" = "de",
  "el" = "el",
  "es" = "es",
  "et" = "et",
  "fa" = "fa",
  "fi" = "fi",
  "fr" = "fr",
  "ga" = "ga",
  "he" = "he",
  "hi" = "hi",
  "hr" = "hr",
  "hu" = "hu",
  "hy" = "hy",
  "id" = "id",
  "is" = "is",
  "it" = "it",
  "ja" = "ja",
  "ka" = "ka",
  "kk" = "kk",
  "ko" = "ko",
  "ky" = "ky",
  "lb" = "lb",
  "lt" = "lt",
  "lv" = "lv",
  "mk" = "mk",
  "mn" = "mn",
  "my" = "my",
  "ne" = "ne",
  "nl" = "nl",
  "no" = "no",
  "pa" = "pa",
  "pl" = "pl",
  "pt" = "pt",
  "pt-br" = "pt-br",
  "ro" = "ro",
  "ru" = "ru",
  "sk" = "sk",
  "sl" = "sl",
  "sq" = "sq",
  "sr" = "sr",
  "sv" = "sv",
  "sw" = "sw",
  "ta" = "ta",
  "te" = "te",
  "th" = "th",
  "tk" = "tk",
  "tr" = "tr",
  "uk" = "uk",
  "ur" = "ur",
  "uz" = "uz",
  "vi" = "vi",
  "zh-hans" = "zh-hans",
  "zh-hant" = "zh-hant",
}

export enum CampaignLocaleNames {
  "en" = "English (English)",
  "af" = "Afrikaans (Afrikaans)",
  "ar" = "Arabic (العربيّة)",
  "az" = "Azerbaijani (Azərbaycanca)",
  "be" = "Belarusian (беларуская)",
  "bg" = "Bulgarian (български)",
  "bn" = "Bengali (বাংলা)",
  "bs" = "Bosnian (bosanski)",
  "ca" = "Catalan (català)",
  "cs" = "Czech (česky)",
  "cy" = "Welsh (Cymraeg)",
  "da" = "Danish (dansk)",
  "de" = "German (Deutsch)",
  "el" = "Greek (Ελληνικά)",
  "es" = "Spanish (español)",
  "et" = "Estonian (eesti)",
  "fa" = "Persian (فارسی)",
  "fi" = "Finnish (suomi)",
  "fr" = "French (français)",
  "ga" = "Irish (Gaeilge)",
  "he" = "Hebrew (עברית)",
  "hi" = "Hindi (हिंदी)",
  "hr" = "Croatian (Hrvatski)",
  "hu" = "Hungarian (Magyar)",
  "hy" = "Armenian (հայերեն)",
  "id" = "Indonesian (Bahasa Indonesia)",
  "is" = "Icelandic (Íslenska)",
  "it" = "Italian (italiano)",
  "ja" = "Japanese (日本語)",
  "ka" = "Georgian (ქართული)",
  "kk" = "Kazakh (Қазақ)",
  "ko" = "Korean (한국어)",
  "ky" = "Kyrgyz (Кыргызча)",
  "lb" = "Luxembourgish (Lëtzebuergesch)",
  "lt" = "Lithuanian (Lietuviškai)",
  "lv" = "Latvian (latviešu)",
  "mk" = "Macedonian (Македонски)",
  "mn" = "Mongolian (Mongolian)",
  "my" = "Burmese (မြန်မာဘာသာ)",
  "ne" = "Nepali (नेपाली)",
  "nl" = "Dutch (Nederlands)",
  "no" = "Norwegian (norsk)",
  "pa" = "Punjabi (Punjabi)",
  "pl" = "Polish (polski)",
  "pt" = "Portuguese (Português)",
  "pt-br" = "Brazilian Portuguese (Português Brasileiro)",
  "ro" = "Romanian (Română)",
  "ru" = "Russian (Русский)",
  "sk" = "Slovak (Slovensky)",
  "sl" = "Slovenian (Slovenščina)",
  "sq" = "Albanian (shqip)",
  "sr" = "Serbian (српски)",
  "sv" = "Swedish (svenska)",
  "sw" = "Swahili (Kiswahili)",
  "ta" = "Tamil (தமிழ்)",
  "te" = "Telugu (తెలుగు)",
  "th" = "Thai (ภาษาไทย)",
  "tk" = "Turkmen (Türkmençe)",
  "tr" = "Turkish (Türkçe)",
  "uk" = "Ukrainian (Українська)",
  "ur" = "Urdu (اردو)",
  "uz" = "Uzbek (oʻzbek tili)",
  "vi" = "Vietnamese (Tiếng Việt)",
  "zh-hans" = "Simplified Chinese (简体中文)",
  "zh-hant" = "Traditional Chinese (繁體中文)",
}

export interface ICampaign {
  id: number;
  name: string;
  created: string;
  updated: string;
  start_date?: string | null;
  restrictions: {
    connection_requests: number;
  };
  execution: ExecutionKeys;
  locale?: CampaignLocale;
}

export interface INewCampaign {
  id: number;
  open: boolean;
}

export interface ICreateCampaignAudienceFormInput {
  search: ISearch;
  add_automatically: boolean;
}

export interface ICreateCampaignSequenceFormInput {
  sequence: {
    id: number;
    name: string;
  };
}

export interface ICreateCampaignLaunchFormValues {
  campaign: number;
  execution: ExecutionKeys;
  account: number;
}

export interface ICreateCampaignSequenceFormValues {
  sequence: number;
}

export interface ICreateCampaignSearchFormInput {
  search: {
    name: string;
    id: number;
    add_automatically: boolean;
  };
}

export interface ICreateCampaignSearchFormValues
  extends ICreateCampaignSearchFormInput {
  campaign: number;
}

export interface IBaseCampaignFormValues {
  account?: number;
  start_date?: {
    date: string | null;
    enabled: boolean;
  };
  execution?: ICampaign["execution"];
  restrictions?: {
    connection_requests: number;
    enabled: boolean;
  };
  sequence?: number;
  search?: ICreateCampaignSearchFormInput["search"];
}

export interface ICreateCampaignFormInput extends IBaseCampaignFormValues {
  name: ICampaign["name"];
  locale: {
    id: CampaignLocale;
    name: CampaignLocaleNames;
  };
}

export interface ICreateCampaignFormValues extends IBaseCampaignFormValues {
  name: ICampaign["name"];
  locale: CampaignLocale;
}

export interface IUpdateCampaignFormInput extends ICreateCampaignFormInput {}

export interface IUpdateCampaignFormValues extends IBaseCampaignFormValues {
  name?: ICampaign["name"];
  locale?: CampaignLocale;
}

export interface ICreateCampaignStepValues extends ICreateStepValues {
  accountId: number;
}

export interface ICreateCampaignProps extends ICreateStepValues {
  accountId: number;
  campaignId: number;
  campaignName: string | undefined;
  isMissingAction: ISequenceMissingAction;
}

export interface ICampaigns extends IArrayResponse {
  results: ICampaign[];
}

export interface IAllCampaigns extends IArrayResponse {
  results: ICampaign[];
}

export interface ICampaignParams {
  campaignName: string | undefined;
  campaignId: number;
  campaignData: ICampaign | undefined;
  pageHeaderProps?: any;
  isMissingAction: ISequenceMissingAction;
}

export interface ICampaignSequenceFormInput {
  sequence: number;
}

export interface ICampaignSequenceFormProps {
  sequence: ISequence;
}

export interface ICampaignSequenceFormValues
  extends ICampaignSequenceFormInput {
  campaign: number;
}

export interface ICampaignSequence {
  id: number;
  created: string;
  campaign: number;
  sequence: ISequence;
}

export interface ICampaignSequences extends IArrayResponse {
  results: ICampaignSequence[];
}

export interface ICampaignRefFormInput {
  campaign: number;
}

export interface ICampaignPersonFormDefault {
  people: [];
}

export interface ICampaignPersonFormInput {
  people: IPerson[];
}

export interface ICampaignPersonFormValues extends ICampaignRefFormInput {
  persons: number[];
  account: number;
}

export interface ICampaignRemovePersonFormValues extends ICampaignRefFormInput {
  persons: number[];
}

export interface ICampaignSearchResultFormValues extends ICampaignRefFormInput {
  search_results: number[];
  account: number;
}

export interface ICampaignRemoveSearchResultFormValues
  extends ICampaignRefFormInput {
  search_results: number[];
}

export interface ICampaignPerson {
  id: number;
  created: string;
  campaign: number;
  person: IPerson;
}

export interface ICampaignPersons extends IArrayResponse {
  results: ICampaignPerson[];
}

export interface ICampaignSearchFormInput {
  search: {
    id: number;
    name: string;
  };
  add_automatically: boolean;
}

export interface ICampaignSearchFormValues extends ICampaignRefFormInput {
  search: number;
}

export interface ICampaignSearch {
  id: number;
  created: string;
  campaign: number;
  search: ISearch;
  add_automatically: boolean;
}

export interface ICampaignSearches extends IArrayResponse {
  results: ICampaignSearch[];
}

export interface ISelectedCampaignFormInput {
  campaign: { id: number; label: string };
}

export interface IMovePeopleFormValues {
  campaign: number;
  interactions: number[];
}

export interface ISelectedPeopleToCampaignFormValues {
  campaign: number;
  persons: number[];
  account: number;
}

export interface ISearchPeopleToCampaignFormInput {
  campaign: { id: number; label: string };
}

export interface ISearchPeopleToCampaignFormValues {
  search: number;
  campaign: number;
  account: number;
}

export interface ICampaignStatCount {
  [key: string]: number;
}

export interface ICampaignStats {
  counts: {
    active: ICampaignStatCount;
    all: ICampaignStatCount;
    finished: ICampaignStatCount;
    invites: ICampaignStatCount;
    invites_accepted: ICampaignStatCount;
    messages: ICampaignStatCount;
    replies: ICampaignStatCount;
  };
}
