import { blue, green, orange, purple, red, yellow } from "@mui/material/colors";

const createItem = (label: string, color: string) => ({ label, color });

const alphabet = [
  createItem("A", green[500]),
  createItem("B", blue[500]),
  createItem("C", red[500]),
  createItem("D", yellow[500]),
  createItem("E", purple[500]),
  createItem("F", orange[500]),
  createItem("G", green[500]),
  createItem("H", blue[500]),
  createItem("I", red[500]),
  createItem("J", yellow[500]),
  createItem("K", purple[500]),
  createItem("L", orange[500]),
  createItem("M", green[500]),
  createItem("N", blue[500]),
  createItem("O", red[500]),
  createItem("P", yellow[500]),
  createItem("Q", purple[500]),
  createItem("R", orange[500]),
  createItem("S", green[500]),
  createItem("T", blue[500]),
  createItem("U", red[500]),
  createItem("V", yellow[500]),
  createItem("W", purple[500]),
  createItem("Y", orange[500]),
  createItem("X", green[500]),
  createItem("Z", blue[500]),
];

const SequenceConstants = {
  ALPHABET: alphabet,
};

export default SequenceConstants;
