import * as Yup from "yup";

export const ERROR_KEYS = {
  EMAIL: {
    REQUIRED: "Email address is required.",
    INVALID: "Email address is invalid.",
  },
  ACCOUNT: {
    REQUIRED: "Please select at least 1 account.",
  },
};

function inviteFormSchema(): Yup.AnyObjectSchema {
  return Yup.object().shape({
    accounts: Yup.array().min(1, ERROR_KEYS.ACCOUNT.REQUIRED),
    invitee_by_email: Yup.string().required(ERROR_KEYS.EMAIL.REQUIRED),
  });
}

function requestFormSchema(): Yup.AnyObjectSchema {
  return Yup.object().shape({
    invitee_by_email: Yup.string().required(ERROR_KEYS.EMAIL.REQUIRED),
  });
}

const TeamValidations = { inviteFormSchema, requestFormSchema };

export default TeamValidations;
