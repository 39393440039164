import AutocompleteField from "core/components/AutocompleteField";
import ConfigConstant from "core/constants/ConfigConstant";
import { AccountQuery, IAccount } from "modules/Account/models";
import AccountService from "modules/Account/services";
import { getAccountName, mergeAccountSub } from "modules/Account/utils";
import { IAccountSubscription } from "modules/Payment/models";
import PaymentService from "modules/Payment/services";
import { UserContext } from "modules/User/context";
import React from "react";
import { Control, FieldErrors } from "react-hook-form";
import { useQuery } from "react-query";

interface AccountSeatlessAutocompleteProps {
  control: Control;
  errors: FieldErrors;
}

const AccountSeatlessAutocomplete = ({
  control,
  errors,
}: AccountSeatlessAutocompleteProps): React.ReactElement | null => {
  const [search, setSearch] = React.useState("");
  const {
    user: { id: userId },
  } = React.useContext(UserContext);
  const { data, isLoading: isLoading1 } = useQuery(
    [AccountQuery.all, search],
    async () => {
      try {
        const response = await AccountService.fetchAccountsWithSearch(
          search,
          ConfigConstant.INITIAL_PAGE
        );
        return response.data;
      } catch (err) {
        throw new Error(String(err));
      }
    }
  );
  const accounts: IAccount[] = data?.results || [];

  const fetchSubscription = async () => {
    try {
      const { data } = await PaymentService.fetchAllUserSubscriptions();
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };
  const { data: dataAccountSubscriptions, isLoading: isLoading2 } = useQuery(
    ["subscriptions", userId],
    () => fetchSubscription(),
    {
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const isLoading = isLoading1 || isLoading2;

  const subscriptions: IAccountSubscription[] =
    dataAccountSubscriptions?.results || [];

  const accountSubs = mergeAccountSub(accounts, subscriptions).filter(
    (item) => !item.is_subscribed
  );

  const options = accountSubs
    ? accountSubs.map((account: IAccount) => ({
        id: account.id,
        label: getAccountName(account),
      }))
    : [];

  return (
    <AutocompleteField
      control={control}
      errors={errors}
      options={options}
      isLoading={isLoading}
      handleInputChange={(event, newInputValue) => {
        setSearch(newInputValue);
      }}
      label="Select LinkedIn account"
      name="account"
    />
  );
};

export default AccountSeatlessAutocomplete;
