import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import DateRangeIcon from "@mui/icons-material/DateRange";
import DeleteIcon from "@mui/icons-material/Delete";
import AccountService from "modules/Account/services";
import { IInactivePeriod } from "modules/Account/models";
import { formatDateToPretty } from "core/utils/dateHandler";
import ConfirmDialog from "ui-kit/components/ConfirmDialog";
import { errorHandler, IErrorResponse } from "core/utils/errorHandler";
import { snackbarHandler } from "core/utils/snackbarHandler";

const formatInactivePeriodDate = (period: IInactivePeriod) => {
  const { start_date: s, end_date: e } = period;
  return `${formatDateToPretty(s)} - ${formatDateToPretty(e)}`;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

const InactiveList = ({
  data,
  accountId,
}: {
  data: IInactivePeriod[];
  accountId: number;
}): React.ReactElement => {
  const classes = useStyles();
  const [confirmDialog, setConfirmDialog] = useState<boolean>(false);
  const [inactivePeriod, setInactivePeriod] = useState<
    IInactivePeriod | undefined
  >(undefined);

  const queryClient = useQueryClient();
  const mutateDeleteInactivePeriod = useMutation(
    () => AccountService.deleteInactivePeriod(inactivePeriod?.id as number),
    {
      onSuccess: () => {
        // Query Invalidations
        queryClient.invalidateQueries(["inactive_periods", accountId]);
        snackbarHandler.success("Inactive period removed!");
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);
      },
    }
  );

  return (
    <>
      <List dense className={classes.root}>
        {data.map((period, index) => {
          const divider = index < data.length - 1;
          const labelId = `inactive-days-${period.id}`;
          return (
            <ListItem key={period.id} divider={divider}>
              <ListItemIcon>
                <DateRangeIcon fontSize="small" color="secondary" />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary="Inactive Period"
                secondary={formatInactivePeriodDate(period)}
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => {
                    setInactivePeriod(period);
                    setConfirmDialog(true);
                  }}
                  size="large"
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
      {inactivePeriod && (
        <ConfirmDialog
          open={confirmDialog}
          handleAgree={() => mutateDeleteInactivePeriod.mutate()}
          handleDisagree={() => setConfirmDialog(false)}
          handleClose={() => setConfirmDialog(false)}
          title="Delete Inactive Period"
          dialogText={`Confirm to remove inactive period of ${formatInactivePeriodDate(
            inactivePeriod
          )}.`}
        />
      )}
    </>
  );
};

export default InactiveList;
