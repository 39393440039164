import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

export const useAppBarStyles = makeStyles((theme: Theme) => ({
  appBar: {
    boxShadow: `inset 0 -1px ${theme.app.palette.shadow.secondary}`,
    backgroundColor: theme.palette.primary.light,
  },
  toolbar: {
    height: theme.app.constants.appbarHeight,
    minHeight: "unset",
    padding: 0,
  },
  paper: {
    minWidth: 160,
    border: `1px solid ${theme.app.palette.shadow.secondary}`,
  },
  grow: {
    flexGrow: 1,
  },
  name: {
    color: theme.palette.common.white,
    letterSpacing: "-0.05em",
    fontSize: 16,
    fontWeight: 700,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  logo: {
    height: theme.app.constants.logoSize,
    width: theme.app.constants.logoSize,
  },
  iconButton: {
    color: theme.app.palette.action.icon,
    marginRight: theme.spacing(2.5),
    "&:hover": {
      backgroundColor: theme.app.palette.action.hoverBackground,
    },
  },
  user: {
    textTransform: "capitalize",
    margin: theme.spacing(0, 0.75, 0, 1.25),
  },
  appBtn: {
    padding: theme.spacing(2),
    borderColor: "transparent",
    color: theme.app.palette.action.icon,
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  menuButton: {
    color: theme.app.palette.action.color,
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  moreBtn: {
    color: theme.palette.common.white,
  },
}));
