import React from "react";
import { useMutation, useQueryClient } from "react-query";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import SaveIcon from "@mui/icons-material/Save";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { errorHandler, IErrorResponse } from "core/utils/errorHandler";
import { snackbarHandler } from "core/utils/snackbarHandler";
import { IPersonPlaceholder } from "modules/Person/models";
import { AccountContext } from "modules/Account/context";
import PlaceholderService from "modules/Placeholder/services";
import { CampaignQuery } from "modules/Campaign/models";

interface PlaceholderTextFieldProps {
  content: IPersonPlaceholder;
  personId: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  inputRoot: {
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: `2px solid ${theme.app.palette.action.placeholder}`,
    },
    "&:before": {
      borderBottom: `1px solid ${theme.app.palette.shadow.primary}`,
    },
    "&:not(.Mui-focused)": {
      "& > .MuiInputAdornment-root": {
        display: "none",
      },
    },
    marginBottom: theme.spacing(1),
  },
  input: {
    fontSize: "0.8rem !important",
    color: theme.app.palette.text.secondary,
  },
  limit: {
    fontSize: "0.8rem",
    color: theme.app.palette.action.placeholder,
  },
}));

const PlaceholderTextfield = ({
  content,
  personId,
}: PlaceholderTextFieldProps): React.ReactElement => {
  const classes = useStyles();
  const [value, setValue] = React.useState<string>(content.value);

  const {
    account: { id: currentAccountId },
  } = React.useContext(AccountContext);

  // Update value when selected person has changed
  React.useEffect(() => {
    if (content.value !== value) {
      setValue(content.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content.value]);

  const queryClient = useQueryClient();

  const mutateCreatePlaceholder = useMutation(
    (newValue: string) =>
      PlaceholderService.createPlaceholder({
        key_string: content?.key.key,
        value: newValue,
        person: personId,
        account: currentAccountId,
      }),
    {
      onSuccess: () => {
        // Dynamic placeholder list
        queryClient.invalidateQueries("placeholders");

        // On My Network page
        queryClient.invalidateQueries(["contacts"]);

        // On campaign review and start page
        queryClient.invalidateQueries(CampaignQuery.interactions);

        // Individual contact
        queryClient.invalidateQueries(["contact", personId]);
        snackbarHandler.success("Successfully updated!");
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);
      },
    }
  );

  const mutateUpdatePlaceholder = useMutation(
    (newValue: string) =>
      PlaceholderService.updatePlaceholder({
        id: Number(content.id),
        value: newValue,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("placeholders");
        queryClient.invalidateQueries(["contact", personId]);
        snackbarHandler.success("Successfully updated!");
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);
      },
    }
  );

  const mutateDeletePlaceholder = useMutation(
    (id: number) => PlaceholderService.deletePlaceholder(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("placeholders");
        queryClient.invalidateQueries(["contact", personId]);
        snackbarHandler.success("Successfully updated!");
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);
      },
    }
  );

  const handleMutation = (newValue: string) => {
    if (newValue === content.value) {
      // Exit if default placeholder not overwritten by custom or value not changed
      return false;
    }

    // Exist and notify if user tried to delete default placeholder
    if (!content?.id && !newValue) {
      setValue(content?.value);
      return snackbarHandler.error(
        "Default placeholder can't be deleted, only over-written by custom placeholder!"
      );
    }

    // Handle mutation

    // If ID and value exist, then update
    if (value && content.id) {
      // Update
      return mutateUpdatePlaceholder.mutate(newValue);
    }

    // If ID exists and value is empty that is delete
    if (!value && content.id) {
      // Delete
      return mutateDeletePlaceholder.mutate(content.id);
    }

    return mutateCreatePlaceholder.mutate(newValue);
  };

  const onSubmit = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => handleMutation(event.target.value);

  const onKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      handleMutation((event.target as HTMLTextAreaElement).value);
    }
  };

  const onSaveClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    handleMutation(value);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setValue(event.target.value);

  return (
    <TextField
      value={value}
      onChange={handleOnChange}
      onKeyDown={onKeyPress}
      placeholder={`Add ${content.label}`}
      onBlur={onSubmit}
      fullWidth
      variant="standard"
      sx={{ mb: 1.5 }}
      InputProps={{
        classes: {
          root: classes.inputRoot,
          input: classes.input,
        },
        endAdornment: (
          <InputAdornment position="end">
            <IconButton type="submit" size="small" onClick={onSaveClick}>
              <SaveIcon fontSize="small" color="inherit" />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PlaceholderTextfield;
