import * as Yup from "yup";

export const ERROR_KEYS = {
  COMPANY_NAME: {
    REQUIRED: "Company name is required.",
  },
};

function companyFormSchema(name: string): Yup.AnyObjectSchema {
  return Yup.object().shape({
    [name]: Yup.string().required(ERROR_KEYS.COMPANY_NAME.REQUIRED),
  });
}

const CompanyValidations = { companyFormSchema };

export default CompanyValidations;
