import React, { createContext, useReducer } from "react";
import { IDraftAction } from "../models";
import { DraftActionAction, actionReducer } from "../reducers";
import { defaultDraftAction } from "../utils";

interface IProps {
  children: React.ReactElement;
}

interface IActionContext {
  draftAction: IDraftAction;
  dispatch: React.Dispatch<DraftActionAction>;
}

const initialState = {
  draftAction: defaultDraftAction,
  dispatch: () => null,
};

export const ActionContext = createContext<IActionContext>(initialState);

const ActionContextProvider = (props: IProps): React.ReactElement => {
  const [draftAction, dispatch] = useReducer(
    actionReducer,
    initialState.draftAction
  );
  const { children } = props;
  return (
    <ActionContext.Provider value={{ draftAction, dispatch }}>
      {children}
    </ActionContext.Provider>
  );
};

export default ActionContextProvider;
