import ConfigConstant from "core/constants/ConfigConstant";
import ReactGA from "react-ga";
import { useEffect } from "react";
import TagManager from "react-gtm-module";

const GoogleTracking = (): null => {
  // Google Analytics
  useEffect(() => {
    const gaId = ConfigConstant.GOOGLE_ANALYTICS_ID;

    if (gaId) {
      ReactGA.initialize(gaId);
    }
  }, []);

  // Google Tag Manager
  useEffect(() => {
    const gtmId = ConfigConstant.GOOGLE_TAG_MANAGER_ID;
    if (gtmId) {
      TagManager.initialize({
        gtmId,
        auth: ConfigConstant.GOOGLE_TAG_MANAGER_AUTH,
        preview: ConfigConstant.GOOGLE_TAG_MANAGER_PREVIEW,
      });
    }
  }, []);

  return null;
};

export default GoogleTracking;
