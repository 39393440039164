/* eslint-disable import/prefer-default-export */
// eslint-disable-next-line no-control-regex
export const ASCII_REGEX = /^[\x00-\x7F]*$/;

export function hasNumber(str: string) {
  return /\d/.test(str);
}

export function hasLowercase(str: string) {
  return /[a-z]/.test(str);
}

export function hasUppercase(str: string) {
  return /[A-Z]/.test(str);
}
