import { IArrayResponse } from "core/models";
import { IAction, DirectActionStrategy } from "modules/Action/models";
import { ICampaign } from "modules/Campaign/models";
import { IContact, IPerson } from "modules/Person/models";
import { ISearchResult } from "modules/Search/models";

export enum InteractionsQuery {
  "interactions_person" = "interactions_person",
  "campaign_person" = "interaction_campaign_person",
  "account_stats" = "interaction_account_stats",
}

export enum InteractionCategories {
  "message" = 1,
  "profile_view" = 2,
  "connection_request" = 3,
  "sequence_paused" = 5,
  "sequence_resumed" = 6,
  "connected" = 8,
  "disconnected" = 9,
  "message_attempt" = 10,
  "connection_request_attempt" = 11,
  "sequence_stopped" = 12,
  "person_in_campaign_queue" = 14,
  "inMail" = 15,
  "connection_request_withdraw" = 18,
  "system_message" = 19,
  "add_to_campaign" = 100,
}

export enum InteractionCategoryNames {
  "message" = "message",
  "profile_view" = "profile_view",
  "connection_request" = "connection_request",
  "sequence_paused" = "sequence_paused",
  "sequence_resumed" = "sequence_resumed",
  "connected" = "connected",
  "disconnected" = "disconnected",
  "message_attempt" = "message_attempt",
  "connection_request_attempt" = "connection_request_attempt",
  "sequence_stopped" = "sequence_stopped",
  "person_in_campaign_queue" = "person_in_campaign_queue",
  "inMail" = "inMail",
  "connection_request_withdraw" = "connection_request_withdraw",
  "system_message" = "system_message",
  "add_to_campaign" = "add_to_campaign",
}

export enum InteractionFilters {
  "all" = "all",
  "unread" = "unread",
  "reply" = "reply",
  "accepted" = "accepted",
  "message" = "message",
  "no_reply" = "no_reply",
}

export enum InteractionStatusFilters {
  "queued" = "queued",
  "replied" = "replied",
  "awaiting_reply" = "awaiting_reply",
  "profile" = "profile",
  "pending_invitation" = "pending_invitation",
  "stopped" = "stopped",
  "paused" = "paused",
  "connected" = "connected",
  "not_connected" = "not_connected",
}

export enum InteractionStatusLabels {
  "queued" = "Scheduled",
  "replied" = "Replied",
  "awaiting_reply" = "Messaged",
  "profile" = "Visited",
  "pending_invitation" = "Pending invite",
  "stopped" = "Finished",
  "paused" = "Paused",
  "connected" = "Accepted",
  "resumed" = "Resumed",
  "not_connected" = "Not connected",
  "closed_profile" = "Failed",
}

export enum ConnectionStatusFilters {
  "connected" = "connected",
  "pending" = "pending",
  "not_connected" = "not_connected",
}

export enum ConnectionStatusLabels {
  "connected" = "Connected",
  "pending" = "Sent invite",
  "not_connected" = "No invite",
}

export enum IInteractionIssueCategory {
  "unhandled" = "unhandled", // Not handled issue yet, only true error

  // Issues when it failed correctly
  "page_not_found" = "page_not_found",
  "closed_profile" = "closed_profile",
  "connected" = "connected",
  "pending_connection_request" = "pending_connection_request",
  "too_long_content" = "too_long_content",
}

export enum InteractionViewVariant {
  "inbox" = "inbox",
  "campaign" = "campaign",
}

export interface IInteraction {
  id: number;
  account: number;
  person?: IPerson;
  contact?: IContact;
  account_is_actor: boolean;
  category: InteractionCategories;
  data: null | {
    message: "string";
    info: "string;";
  };
  campaign?: ICampaign;
  created: string;
  seen: boolean;
  failed: boolean;
  sequence_action_order_nr?: number;
  response_to_action?: IAction;
  platform_created_at: string;
  search_result: ISearchResult;
  reason_failed: string;
  other_issue: {
    category: IInteractionIssueCategory;
    info: string;
  };
}

export interface IInteractions extends IArrayResponse {
  results: IInteraction[];
}

export interface IInteractionFormInput {
  category: InteractionCategories;
}

export interface IInteractionFormValues extends IInteractionFormInput {
  contact?: number;
}

export interface IDirectActionFormInput {
  execution_strategy?: DirectActionStrategy;
  strategy_data?: {
    template: string | null;
  };
}

export interface IDirectActionFormValues extends IDirectActionFormInput {
  person: number;
}

export interface IDirectAction {
  person: number;
  execution_strategy: DirectActionStrategy;
  id: number;
  strategy_data?: {
    template: string | null;
  };
  created: string;
}

export interface IDirectActions extends IArrayResponse {
  results: IDirectAction[];
}

export interface IGroupedInteractions {
  [key: string]: IInteraction[];
}

export interface IInteractionCount {
  count: number;
}

export interface IInteractionStatItem {
  chunks: {
    [key: string]: number;
  };
  total: number;
}

export interface IInteractionStats {
  date_range: {
    first: string;
    last: string;
  };
  invite_accepted: IInteractionStatItem;
  invite_sent: IInteractionStatItem;
  msg_sent: IInteractionStatItem;
  profile_visits: IInteractionStatItem;
  replies: IInteractionStatItem;
}

export enum InteractionStatKey {
  invite_sent = "invite_sent",
  invite_accepted = "invite_accepted",
  msg_sent = "msg_sent",
  replies = "replies",
  profile_visits = "profile_visits",
}

export enum InteractionStatLabel {
  invite_sent = "Invitations sent ",
  invite_accepted = "Invitations accepted",
  msg_sent = "Messages sent",
  replies = "Replies",
  profile_visits = "Profiles visited",
}
