import RouterConstants from "core/routes/constants";
import CampaignListTable from "modules/Campaign/components/CampaignListTable";
import useCreateCampaign from "modules/Campaign/hooks/useCreateCampaign";
import React from "react";
import PageHeader from "ui-kit/components/PageHeader";

const title = "Campaigns";

const CampaignList = (): React.ReactElement => {
  const { mutate: createCampaign } = useCreateCampaign();
  return (
    <>
      <PageHeader
        title={title}
        actionProps={{
          text: "New campaign",
          onClick: createCampaign,
        }}
        body="Here you can view and manage all your campaigns."
        helperProps={{
          link: RouterConstants.DOCS.CAMPAIGNS.ALL,
        }}
        filterProps
      />

      <CampaignListTable />
    </>
  );
};

export default CampaignList;
