import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { makeStyles, useTheme } from "@mui/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyIcon from "@mui/icons-material/Key";
import { Theme } from "@mui/material/styles";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import ContentPasteGoIcon from "@mui/icons-material/ContentPasteGo";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import SettingsItem from "core/components/SettingsItem";
import ConfigConstant from "core/constants/ConfigConstant";
import { createComponents } from "core/utils/componentsHandler";
import ElementField from "core/components/ElementField";
import { formatDateTimeToFull } from "core/utils/dateHandler";
import { errorHandler, IErrorResponse } from "core/utils/errorHandler";
import {
  ICreateAccountApiKeyFormValues,
  IAccountApiKey,
  ICreatedAccountApiKey,
} from "modules/Account/models";
import AccountService from "modules/Account/services";
import PaperHeader from "ui-kit/components/PaperHeader";
import DialogHeader from "ui-kit/components/DialogHeader";
import Button from "ui-kit/atoms/Button";
import RouterConstants from "core/routes/constants";
import { AccountContext } from "modules/Account/context";
import PageHeader from "ui-kit/components/PageHeader";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3.5, 2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(4, 0),
    },
  },
  box: {
    padding: theme.spacing(4, 5, 2, 4),
    borderRadius: 5,
    border: `1px solid ${theme.app.palette.shadow.primary}`,
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(3),
  },
  input: {
    display: "block",
  },
  label: {
    fontWeight: 600,
  },
  helperText: {
    color: theme.app.palette.action.placeholder,
  },
  list: {
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      maxWidth: 400,
    },
  },
  keyLabel: {
    fontSize: 13,
    color: theme.app.palette.action.color,
    marginBottom: theme.spacing(1),
  },
  keyBox: {
    borderRadius: 4,
    border: `1px solid ${theme.app.palette.shadow.secondary}`,
  },
  key: {
    padding: theme.spacing(2.5, 4),
    backgroundColor: theme.palette.action.selected,
  },
  content: {
    backgroundColor: theme.palette.common.white,
  },
  successIcon: {
    color: theme.palette.success.light,
    fontSize: 50,
  },
  copyBtn: {
    padding: theme.spacing(3),
    borderLeft: `1px solid ${theme.app.palette.shadow.secondary}`,
    borderRadius: 0,
    backgroundColor: theme.app.palette.action.disabledBackground,
  },
  copyIcon: {
    color: theme.app.palette.action.main,
  },
}));

const detailText = `API keys allow you to integrate ${ConfigConstant.APP_NAME} with other services.`;

const IntegrationApi = (): React.ReactElement | null => {
  const classes = useStyles();
  const theme = useTheme();
  const queryClient = useQueryClient();

  const {
    account: { id: accountId },
  } = React.useContext(AccountContext);

  const [openDialog, setOpenDialog] = React.useState<string | undefined>(
    undefined
  );
  const [copySuccess, setCopySuccess] = React.useState<boolean>(false);

  const handleOpenDialog = (newKey: string) => {
    setOpenDialog(newKey);
  };

  const handleCloseDialog = () => {
    setOpenDialog(undefined);
    setCopySuccess(false);
  };

  const fetchApiKeys = async () => {
    try {
      const { data } = await AccountService.fetchApiKeys(accountId);
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };

  const { data } = useQuery(["api-keys", accountId], () => fetchApiKeys(), {
    keepPreviousData: true,
    enabled: !!accountId,
  });

  // Revoke API key
  const handleRevokeApiKey = useMutation(
    (custom_id: IAccountApiKey["custom_id"]) =>
      AccountService.updateApiKey(custom_id, {
        account: accountId,
        revoked: true,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["api-keys"]);
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);
      },
    }
  );

  const handleOnCopy = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    if (openDialog) {
      navigator.clipboard.writeText(openDialog);
      setCopySuccess(true);
    }
  };

  const handleCopyAndClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    handleOnCopy(event);
    handleCloseDialog();
  };

  return (
    <>
      <PageHeader
        title="Reverse webhooks"
        body="Reverse webhooks allow you to easily trigger LinkedIn actions from Zapier, Make (Integromat) or other services."
        backlinkProps={{
          text: "Integrations",
          link: RouterConstants.INTEGRATION.ROOT,
        }}
      />
      {data && data.count > 0 && (
        <>
          <div className={classes.root}>
            <PaperHeader
              title="Integration API keys"
              body={detailText}
              helperInfo={{
                link: RouterConstants.DOCS.INTEGRATIONS.REVERSE.API,
              }}
            />
            <List className={classes.list}>
              {data?.results.map((item, index) => (
                <ListItem
                  sx={{ px: 0 }}
                  key={item.custom_id}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="revoke"
                      onClick={() => {
                        if (
                          window.confirm(
                            "Are you sure you want to delete this API key?"
                          )
                        ) {
                          handleRevokeApiKey.mutate(item.custom_id);
                        }
                      }}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  }
                  divider={index < data?.results.length - 1}
                >
                  <ListItemAvatar>
                    <Avatar>
                      <KeyIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={item.name}
                    secondary={`Created at: ${formatDateTimeToFull(
                      item.created
                    )}`}
                  />
                </ListItem>
              ))}
            </List>
          </div>
          <Divider sx={{ mb: 4 }} />
        </>
      )}
      <SettingsItem
        FormProps={{
          id: accountId,
          name: ["api-keys", accountId],
          func: AccountService.createApiKey,
          format: (d: ICreateAccountApiKeyFormValues) => d,
          resetForm: true,
          defaultValues: {
            name: "",
          },
          onSuccess: (d: ICreatedAccountApiKey) => handleOpenDialog(d.key),
        }}
        PaperHeaderProps={{
          title: "Create an API key",
          body: detailText,
          helperInfo: {
            link: RouterConstants.DOCS.INTEGRATIONS.REVERSE.API,
          },
        }}
        components={[
          createComponents(ElementField, "name", {
            label: "Name - ex. Zapier API Key",
            placeholder: "",
            InputProps: {
              sx: {
                [theme.breakpoints.up("md")]: {
                  minWidth: theme.app.constants.inputMaxWidth,
                },
              },
            },
          }),
        ]}
        SubmitButtonProps={{
          color: "primary",
          value: "Create API key",
        }}
        // Last item in the list
        hiddenDivider
      />
      <Dialog
        open={!!openDialog}
        onClose={handleCloseDialog}
        maxWidth="sm"
        PaperProps={{ sx: { width: "100%" } }}
        keepMounted
        aria-labelledby="new-account-placeholder-dialog"
      >
        <DialogHeader title="New API Key" onHandleClose={handleCloseDialog} />
        <DialogContent dividers className={classes.content}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: 5,
            }}
          >
            <TaskAltIcon className={classes.successIcon} sx={{ my: 2 }} />
            <Typography
              variant="h4"
              sx={{ fontWeight: 500 }}
              color="textSecondary"
            >
              Your API Key is ready
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "space-between",
              mb: 2,
            }}
            className={classes.keyBox}
          >
            <Typography
              sx={{
                width: "100%",
                m: 0,
              }}
              variant="subtitle1"
              className={classes.key}
            >
              {openDialog}
            </Typography>
            <IconButton
              type="button"
              className={classes.copyBtn}
              onClick={handleOnCopy}
            >
              <ContentPasteGoIcon className={classes.copyIcon} />
            </IconButton>
          </Box>
          <Box sx={{ textAlign: "center", py: 2 }}>
            <Typography variant="body2" color="textSecondary" component="div">
              For security reasons, this API Key will not be displayed again.
            </Typography>
            <Typography variant="body2" color="textSecondary" component="div">
              Make sure to copy it now.
            </Typography>
          </Box>
          {copySuccess && (
            <Alert severity="success" sx={{ my: 2 }}>
              <AlertTitle>Successfully copied:</AlertTitle>
              {openDialog}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            variant="outlined"
            color="inherit"
            onClick={handleCopyAndClose}
          >
            Close
          </Button>
          <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={handleOnCopy}
          >
            Copy to clipboard
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default IntegrationApi;
