import React from "react";
import clsx from "clsx";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme, lighten, darken } from "@mui/material/styles";
import capitalize from "lodash/capitalize";
import MuiButton, { ButtonProps as MuiButtonProps } from "@mui/material/Button";

export type ColorTypes =
  | "primary"
  | "secondary"
  | "error"
  | "success"
  | "warning"
  | "inherit"
  | "info"
  | "filter"
  | "dark";

type ButtonProps = {
  component?: React.ReactNode;
  to?: string;
  color: ColorTypes;
} & Omit<MuiButtonProps, "color">;

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    root: {
      fontWeight: 500,
      whiteSpace: "nowrap",
      boxShadow: theme.shadows[2],
    },
    outlinedPrimary: {
      borderColor: lighten(theme.palette.primary.main, 0.6),
      color: theme.palette.primary.main,
    },
    outlinedSecondary: {
      borderColor: theme.palette.secondary.main,
      color: theme.palette.secondary.main,
    },
    outlinedSuccess: {
      borderColor: theme.palette.success.main,
      color: theme.palette.success.main,
      "&:hover": {
        borderColor: theme.palette.success.main,
      },
    },
    outlinedError: {
      borderColor: theme.palette.error.main,
      color: theme.palette.error.main,
      "&:hover": {
        borderColor: theme.palette.error.main,
      },
    },
    outlinedWarning: {
      borderColor: theme.palette.warning.main,
      color: theme.palette.warning.main,
      "&:hover": {
        borderColor: theme.palette.warning.main,
      },
    },
    outlinedInfo: {
      borderColor: theme.palette.info.main,
      color: theme.palette.info.main,
    },
    outlinedInherit: {
      borderColor: theme.palette.action.focus,
      color: theme.app.palette.action.main,
    },
    outlinedFilter: {
      borderRadius: 4,
      borderColor: "rgba(0, 0, 0, 0.23)",
      color: theme.app.palette.action.main,
      padding: theme.spacing(2.5, 4.5),
      width: "100%",
    },
    containedPrimary: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      backgroundImage: `linear-gradient(to bottom,${lighten(
        theme.palette.primary.main,
        0.0475
      )} 0%,${darken(theme.palette.primary.main, 0.07)} 100%)`,
      backgroundRepeat: "repeat-x",
      borderColor: theme.palette.primary.dark,
      "&:hover&:not(.Mui-disabled)": {
        backgroundColor: theme.palette.primary.main,
        backgroundImage: `linear-gradient(to bottom,${
          theme.palette.primary.main
        } 0%,${darken(theme.palette.primary.main, 0.195)} 100%)`,
      },
      "&.Mui-disabled": {
        color: theme.palette.primary.contrastText,
      },
    },
    containedSuccess: {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.success.contrastText,
      backgroundImage: `linear-gradient(to bottom,${lighten(
        theme.palette.success.main,
        0.0475
      )} 0%,${darken(theme.palette.success.main, 0.07)} 100%)`,
      backgroundRepeat: "repeat-x",
      borderColor: theme.palette.success.dark,
      "&:hover&:not(.Mui-disabled)": {
        backgroundColor: theme.palette.success.main,
        backgroundImage: `linear-gradient(to bottom,${
          theme.palette.success.main
        } 0%,${darken(theme.palette.success.main, 0.195)} 100%)`,
      },
    },
    containedError: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
      backgroundImage: `linear-gradient(to bottom,${lighten(
        theme.palette.error.main,
        0.0475
      )} 0%,${darken(theme.palette.error.main, 0.07)} 100%)`,
      backgroundRepeat: "repeat-x",
      borderColor: theme.palette.error.dark,
      "&:hover&:not(.Mui-disabled)": {
        backgroundColor: theme.palette.error.main,
        backgroundImage: `linear-gradient(to bottom,${
          theme.palette.error.main
        } 0%,${darken(theme.palette.error.main, 0.195)} 100%)`,
      },
      "&.Mui-disabled": {
        color: theme.palette.primary.contrastText,
      },
    },
    containedWarning: {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.warning.contrastText,
      "&:hover&:not(.Mui-disabled)": {
        backgroundColor: theme.palette.warning.dark,
      },
    },
    containedInfo: {
      backgroundColor: theme.palette.info.main,
      color: theme.palette.info.contrastText,
      "&:hover&:not(.Mui-disabled)": {
        backgroundColor: theme.palette.info.dark,
      },
    },
    containedDark: {
      backgroundColor: lighten(theme.palette.text.secondary, 0.075),
      color: theme.palette.info.contrastText,
      "&:hover&:not(.Mui-disabled)": {
        backgroundColor: theme.palette.text.secondary,
      },
    },
    disabled: {
      // borderColor: `${theme.app.palette.shadow.secondary} !important`,
      // backgroundColor: theme.app.palette.action.disabledBackground,
      // color: theme.app.palette.action.disabled,
      // backgroundImage: "none",
      // "&:hover": {
      //   backgroundColor: theme.app.palette.action.disabledBackground,
      // },
      boxShadow: "none",
      color: "currentcolor",
      opacity: 0.4,
    },
  })
);

const Button = ({
  children,
  color,
  variant,
  component,
  to,
  className: customClassName,
  ...props
}: ButtonProps): React.ReactElement => {
  const classes = useStyles();
  const className = clsx(
    classes?.[`${variant}${capitalize(color)}`],
    classes.root
  );
  const colorProp =
    ["inherit", "primary", "secondary"].indexOf(color) > -1
      ? (color as "inherit" | "primary" | "secondary")
      : undefined;

  return (
    <MuiButton
      {...props}
      {...(to && { component, to })}
      variant={variant}
      color={colorProp}
      className={clsx(className, customClassName)}
      classes={{
        disabled: classes.disabled,
      }}
    >
      {children}
    </MuiButton>
  );
};

Button.displayName = "Button";

Button.defaultProps = {
  component: Button,
  to: "",
};

export default Button;
