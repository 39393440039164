import React, { createContext, useReducer } from "react";
import { ISearchSheetFieldsFormValues } from "../models";
import { SearchAction, searchReducer } from "../reducers";

interface IProps {
  children: React.ReactElement;
}

interface ISearchContext {
  search: ISearchSheetFieldsFormValues;
  dispatch: React.Dispatch<SearchAction>;
}

export const emptySearch = {
  columns: null,
  columns_to_rename: [],
  searchName: "",
  url: "",
};

const initialState = {
  search: emptySearch,
  dispatch: () => null,
};

export const SearchContext = createContext<ISearchContext>(initialState);

const SearchContextProvider = (props: IProps): React.ReactElement => {
  const [search, dispatch] = useReducer(searchReducer, initialState.search);
  const { children } = props;
  return (
    <SearchContext.Provider value={{ search, dispatch }}>
      {children}
    </SearchContext.Provider>
  );
};

export default SearchContextProvider;
