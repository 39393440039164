import React from "react";
import { useQuery } from "react-query";
import { useParams, useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import Divider from "@mui/material/Divider";
import RouterConstants from "core/routes/constants";
import SettingsItem from "core/components/SettingsItem";
import { createComponents } from "core/utils/componentsHandler";
import ElementField from "core/components/ElementField";
import { snackbarHandler } from "core/utils/snackbarHandler";
import CompanyService from "modules/Company/services";
import CompanyValidation from "modules/Company/validations";
import { ICompany } from "modules/Company/models";
import CompanyAccountList from "modules/Company/components/CompanyAccountList";
import PaperHeader from "ui-kit/components/PaperHeader";
import PageHeader from "ui-kit/components/PageHeader";
import Loader from "ui-kit/components/Loader";

type ParamTypes = {
  companyId: string;
};

const CompanyDetail = (): React.ReactElement | null => {
  const history = useHistory();
  const params = useParams<ParamTypes>();
  const companyId: number = +params.companyId;
  const { data, isLoading } = useQuery(["company", companyId], async () => {
    try {
      const response = await CompanyService.fetchCompany(companyId);
      return response.data;
    } catch (err) {
      throw new Error(String(err));
    }
  });

  const defaultValues = {
    name: data?.name,
  };

  return (
    <>
      <PageHeader
        backlinkProps={{
          text: "Companies",
          link: RouterConstants.COMPANY.ALL,
        }}
        title={data?.name}
      />
      {isLoading && <Loader />}
      {!!data && (
        <>
          <Box sx={{ pt: 4, pb: 8 }}>
            <PaperHeader
              title="Company LinkedIn accounts"
              body="Here you can manage all LinkedIn accounts assigned to this company."
            />
            <CompanyAccountList companyId={companyId} />
          </Box>
          <Divider />
          <SettingsItem
            FormProps={{
              id: companyId,
              name: ["company", companyId],
              func: CompanyService.updateCompany,
              format: (d: ICompany) => d,
              defaultValues,
              schema: CompanyValidation.companyFormSchema("name"),
            }}
            PaperHeaderProps={{
              title: "Company name",
              body: "Edit company name",
            }}
            components={[
              createComponents(ElementField, "name", {
                InputProps: {
                  label: "Company name",
                },
              }),
            ]}
          />
          <SettingsItem
            FormProps={{
              id: companyId,
              name: ["company", companyId],
              func: (id: number) =>
                // eslint-disable-next-line no-alert
                window.confirm("Are you sure you want to delete this company?")
                  ? CompanyService.deleteCompany(id)
                  : null,
              onSuccess: () => history.push(RouterConstants.COMPANY.ALL),
              successMsg: () =>
                snackbarHandler.success("Company successfully deleted!"),
              errorMsg: ({ detail }: { detail: string }) => {
                let err = detail;
                if (
                  detail ===
                  "Could not delete object with protected references."
                ) {
                  err =
                    "Can't delete a company with attached LinkedIn accounts. Move accounts to a different company first.";
                }
                snackbarHandler.error(err);
              },
              format: (d: ICompany) => d,
              defaultValues,
            }}
            PaperHeaderProps={{
              title: "Delete company",
              body: "Deleting a company removes all the attached LinkedIn accounts. Move them to a new company in advance.",
            }}
            SubmitButtonProps={{
              value: "Delete company",
              color: "error",
              endIcon: <DeleteIcon />,
            }}
            // Last item in the list
            hiddenDivider
          />
        </>
      )}
    </>
  );
};

export default CompanyDetail;
