import axios, { AxiosResponse } from "axios";
import {
  IPerson,
  IPersons,
  IContacts,
  ITags,
  IContact,
  ITagsArray,
} from "modules/Person/models";

const fetchPerson = (personId: number): Promise<AxiosResponse<IPerson>> =>
  axios.get(`/inevitable/persons/${personId}`);

const fetchPersons = (
  currentAccountPersonId: number | undefined,
  page: number,
  search = ""
): Promise<AxiosResponse<IPersons>> =>
  axios.get(
    `/inevitable/persons/?page=${page}&search=${search}&id__not=${currentAccountPersonId}`
  );

const fetchAllPersons = (
  currentAccountPersonId: number | undefined,
  page: number,
  search = ""
): Promise<AxiosResponse<IPersons>> =>
  axios.get(
    `/inevitable/persons/all/?page=${page}&search=${search}&id__not=${currentAccountPersonId}`
  );

const fetchContacts = (
  accountId: number,
  page: number,
  pageSize: number,
  search = "",
  campaignQuery: string,
  searchQuery: string,
  tagQuery: string,
  detailQuery: string,
  connectionQuery: string
): Promise<AxiosResponse<IContacts>> =>
  axios.get(
    `/inevitable/contacts/?account_id=${accountId}&page=${page}&page_size=${pageSize}&search=${search}${campaignQuery}${searchQuery}${tagQuery}${detailQuery}${connectionQuery}`
  );

const fetchContact = (
  accountId: number,
  personId: number
): Promise<AxiosResponse<IContacts>> =>
  axios.get(
    `/inevitable/contacts/?account_id=${accountId}&person_id=${personId}`
  );

const fetchContactById = (
  contactId: number
): Promise<AxiosResponse<IContact>> =>
  axios.get(`/inevitable/contacts/${contactId}`);

const fetchUniqueTags = (): Promise<AxiosResponse<ITags>> =>
  axios.get(`/inevitable/contacts/unique_tags`);

const updateContact = (
  id: number,
  data: ITagsArray
): Promise<AxiosResponse<IContact>> => {
  return axios.patch(`/inevitable/contacts/${id}/`, data);
};

const PersonService = {
  fetchPerson,
  fetchPersons,
  fetchAllPersons,
  fetchContacts,
  fetchContact,
  fetchContactById,
  fetchUniqueTags,
  updateContact,
};

export default PersonService;
