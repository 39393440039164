import React from "react";
import { useQuery } from "react-query";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Add from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import PlaceholderService from "modules/Placeholder/services";
import { IPerson } from "modules/Person/models";
import {
  generateBlankStaticPlaceholders,
  generatePersonPlacehodlers,
} from "modules/Placeholder/utils";
import PlaceholderTextfield from "modules/Placeholder/components/PlaceholderTextfield";
import NewPlaceholderDialog from "modules/Placeholder/components/NewPlaceholderDialog";
import Loader from "ui-kit/components/Loader";

interface DetailFoldableContentProps {
  personId: number;
  person: IPerson | undefined;
}

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    padding: theme.spacing(4),
  },
  label: {
    fontSize: "0.8rem",
    color: theme.app.palette.action.placeholder,
  },
  value: {
    color: theme.palette.text.primary,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "100%",
  },
  button: {
    marginLeft: theme.spacing(-1),
    padding: theme.spacing(1),
    fontSize: "0.775rem",
    color: theme.palette.secondary.dark,
  },
  box: {
    marginBottom: theme.spacing(2.5),
  },
}));

const DetailFoldableContent = ({
  personId,
  person,
}: DetailFoldableContentProps): React.ReactElement => {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);

  const fetchPersonPlaceholder = async () => {
    try {
      const { data } = await PlaceholderService.fetchPersonPlaceholders(
        personId
      );
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };

  const { data, isLoading } = useQuery(
    ["placeholders", personId],
    () => fetchPersonPlaceholder(),
    {
      keepPreviousData: true,
    }
  );

  return (
    <div className={classes.content}>
      {!!person && !!person.email && (
        <div className={classes.box}>
          <Typography variant="caption" className={classes.label} component="p">
            Email
          </Typography>
          <Typography variant="body2" className={classes.value}>
            <a href={`mailto:${person.email}`}>{person.email}</a>
          </Typography>
        </div>
      )}
      {!!person && !!data?.results
        ? generatePersonPlacehodlers(
            person.default_placeholders,
            data.results
          ).map((placeholder) => (
            <div key={placeholder.key.id}>
              <Typography
                variant="caption"
                className={classes.label}
                component="p"
              >
                {placeholder.label}
              </Typography>
              <PlaceholderTextfield content={placeholder} personId={personId} />
            </div>
          ))
        : generateBlankStaticPlaceholders()}
      <Button
        onClick={() => {
          setOpenDialog(true);
        }}
        className={classes.button}
        size="small"
        color="inherit"
        variant="outlined"
        startIcon={<Add />}
        disableRipple
        disableTouchRipple
        disableFocusRipple
      >
        Add placeholder
      </Button>
      {!!isLoading && <Loader />}
      {!!person && (
        <NewPlaceholderDialog
          personId={personId}
          open={openDialog}
          setOpen={setOpenDialog}
        />
      )}
    </div>
  );
};

export default DetailFoldableContent;
