import axios, { AxiosResponse } from "axios";
import { IRestrictions } from "../models";

const fetchRestrictions = (
  id: number
): Promise<AxiosResponse<IRestrictions>> => {
  return axios.get(
    `/inevitable/restrictions/?account_id=${id}&page=1&page_size=1`
  );
};

const ReportService = {
  fetchRestrictions,
};

export default ReportService;
