import { errorHandler, IErrorResponse } from "core/utils/errorHandler";
import { AccountContext } from "modules/Account/context";
import React from "react";
import { useMutation } from "react-query";
import { IAccountOnboardingIds } from "../models";
import { AccountActionType } from "../reducers";
import AccountService from "../services";

const useUpdateOnboarding = () => {
  const { dispatch, account } = React.useContext(AccountContext);
  const { id: accountId } = account;

  const mutateAccount = useMutation(
    (data: { variant: IAccountOnboardingIds; value: boolean }) => {
      const meta_data = !account.meta_data
        ? { onboarding: { [data.variant]: data.value } }
        : {
            ...account.meta_data,
            onboarding: {
              ...account.meta_data.onboarding,
              [data.variant]: data.value,
            },
          };

      dispatch({
        type: AccountActionType.UPDATE_ACCOUNT,
        payload: {
          ...account,
          meta_data,
        },
      });

      return AccountService.updateAccount(accountId, {
        meta_data,
      });
    },
    {
      onSuccess: ({ data }) => {
        // queryClient.invalidateQueries([AccountQuery.account, accountId]);
        // queryClient.invalidateQueries([variant, accountId]);
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);
      },
    }
  );

  return mutateAccount;
};

export default useUpdateOnboarding;
