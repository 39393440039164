import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import { alpha, Theme } from "@mui/material/styles";
import { useTheme } from "@mui/styles";
import clsx from "clsx";
import StrategyConstant from "core/constants/StrategyConstant";
import { AccountContext } from "modules/Account/context";
import {
  DirectActionMatchStrategyToVariant,
  DirectActionStrategy,
} from "modules/Action/models";
import { useInteractionStyles } from "modules/Interaction/components/InteractionContainerItem/component";
import InteractionService from "modules/Interaction/services";
import PersonAvatar from "modules/Person/components/PersonAvatar";
import { IPerson } from "modules/Person/models";
import { getPersonName } from "modules/Person/utils";
import { interpolatePlaceholders } from "modules/Placeholder/components/PlaceholderTemplate";
import React from "react";
import { useQuery } from "react-query";

interface DirectActionListProps {
  personId: number;
  accountPerson: IPerson;
}

const DirectActionList = ({
  personId,
  accountPerson,
}: DirectActionListProps): React.ReactElement => {
  const theme: Theme = useTheme();
  const classes = useInteractionStyles();
  const directActionEndRef = React.useRef<HTMLDivElement>(null);
  const {
    account: { id: accountId },
  } = React.useContext(AccountContext);

  const scrollToBottom = () => {
    directActionEndRef.current?.scrollIntoView();
  };

  const fetchPersonDirectActions = async () => {
    try {
      const { data } = await InteractionService.fetchPersonDirectActions(
        accountId,
        personId
      );
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };
  const { data } = useQuery(
    ["direct-actions", accountId, personId],
    () => fetchPersonDirectActions(),
    {
      keepPreviousData: true,
      enabled: !!personId,
    }
  );

  // Not on the first update
  const firstUpdate = React.useRef(true);
  React.useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (data?.count) {
      setTimeout(() => {
        scrollToBottom();
      }, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.count]);

  const reversedData = data ? [...data.results].reverse() : [];
  // console.log("reversedData", reversedData);
  return (
    <>
      {reversedData.map((directAction) => {
        const directExecutionStrategy: DirectActionStrategy =
          directAction.execution_strategy;
        // console.log("directExecutionStrategy", directExecutionStrategy);
        const currentVariant =
          DirectActionMatchStrategyToVariant[directExecutionStrategy];
        // console.log("currentVariant", currentVariant);
        const currentAction = StrategyConstant.STRATEGY_OBJ[currentVariant];
        // console.log("currentAction", currentAction);
        const actorName = getPersonName(accountPerson);

        if (!currentAction) {
          return null;
        }

        return (
          <ListItem className={classes.actor} key={directAction.id}>
            <div className={classes.innerDivRight}>
              <div>
                <ListItemText
                  classes={{ root: classes.messageText }}
                  primary={currentAction?.label?.account_is_actor}
                  primaryTypographyProps={{
                    className: clsx(classes.variant, classes.directAction),
                    sx: {
                      color: currentAction.color,
                      backgroundColor: alpha(currentAction.color, 0.01),
                      border: `1px solid ${alpha(
                        currentAction.color,
                        3 * theme.palette.action.hoverOpacity
                      )}`,
                    },
                  }}
                />
                {directAction.strategy_data?.template && (
                  <ListItemText
                    secondary={interpolatePlaceholders(
                      directAction.strategy_data.template
                    )}
                    secondaryTypographyProps={{
                      variant: "body2",
                      color: "textPrimary",
                      className: clsx(classes.message, classes.messageRight),
                    }}
                  />
                )}
                <ListItemText
                  secondary="Scheduled to be send..."
                  secondaryTypographyProps={{
                    variant: "caption",
                    color: theme.app.palette.action.color,
                  }}
                  className={classes.smallMargin}
                />
              </div>
              <ListItemAvatar
                className={clsx(classes.avatar, classes.innerDivRight)}
              >
                <PersonAvatar
                  src={accountPerson?.image_base64}
                  size="small"
                  name={actorName}
                />
              </ListItemAvatar>
            </div>
          </ListItem>
        );
      })}
      <div ref={directActionEndRef} />
    </>
  );
};

export default DirectActionList;
