/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import isEmpty from "lodash/isEmpty";
import { TextFieldProps } from "@mui/material/TextField";
import { Controller, Control, FieldError, FieldErrors } from "react-hook-form";
import TextField from "ui-kit/atoms/TextField";

interface ElementFieldProps {
  control: Control;
  name: string;
  label?: string | [string, string] | React.ReactElement;
  component?: React.FunctionComponent<any>;
  error?: FieldError | FieldErrors;
  InputProps?: TextFieldProps;
  ElementProps?: any;
}

const ElementField = ({
  control,
  name,
  label,
  error,
  component: Component = TextField,
  InputProps,
  ElementProps,
  ...rest
}: ElementFieldProps): React.ReactElement => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ value, onChange }) => (
        <Component
          {...rest}
          label={label}
          value={value}
          onChange={onChange}
          {...InputProps}
          {...(InputProps && {
            ...InputProps,
            error: !!error?.message,
            helperText: error?.message,
          })}
          error={!!error?.message}
          helperText={error?.message}
          {...(!isEmpty(ElementProps) && { ElementProps })}
        />
      )}
    />
  );
};

ElementField.defaultProps = {
  error: {},
  label: "",
  InputProps: {},
  ElementProps: {},
  component: TextField,
};

export default ElementField;
