/**
 * RouterConstants changes reflect in cypress PATHS constants
 */

import ConfigConstant from "core/constants/ConfigConstant";
import { AccountTabs } from "modules/Account/models";
import {
  CampaignDetailTabs,
  CreateCampaignSteps,
} from "modules/Campaign/models";

const RouterConstants = {
  ACCOUNT: {
    ROOT: "/accounts",
    DETAIL: "/accounts/:accountId/tab/:tabName",
    detail: (accountId: number, tabName: AccountTabs): string =>
      `/accounts/${accountId}/tab/${tabName}`,
    NEW_ROOT: `/account/new`,
    NEW: "/account/new/:accountId",
    new: (accountId: number): string => `/account/new/${accountId}`,
    SELECT: "/account/select",
    RESUME: "/accounts/resume/:accountId",
    CHECKOUT: {
      SUCCESS: "/accounts/:accountId/checkout/success",
      success: (accountId: number): string =>
        `/accounts/${accountId}/checkout/success`,
      CANCEL: "/accounts/:accountId/checkout/cancel",
      cancel: (accountId: number): string =>
        `/accounts/${accountId}/checkout/cancel`,
    },
    NO_PERMISSION: "/no-permission",
    REMOVED: "/removed",
  },
  BILLING: {
    ALL: "/billing",
    DETAIL: "/billing/:subscriptionId",
    detail: (subscriptionId: string): string => `/billing/${subscriptionId}`,
    CANCEL: "/billing/:subscriptionId/cancel",
    cancel: (subscriptionId: string): string =>
      `/billing/${subscriptionId}/cancel`,
    PAYMENT: "/payment/:accountId",
    payment: (accountId: number): string => `/payment/${accountId}`,
    PLAN: "/plan",
  },
  BLACKLIST: {
    ALL: "/blacklist",
  },
  CAMPAIGN: {
    ROOT: "/campaigns",
    ALL: "/campaigns",
    NEW_START: "/campaigns/new",
    NEW: "/campaigns/:campaignId/new",
    new: (campaignId: number): string => `/campaigns/${campaignId}/new`,
    NEW_TAB: "/campaigns/:campaignId/new/:tabName",
    newTab: (campaignId: number, tabName: string): string =>
      `/campaigns/${campaignId}/new/${tabName}`,
    newSequenceAll: (campaignId: number): string =>
      `/campaigns/${campaignId}/new/${CreateCampaignSteps.steps}/sequence`,
    NEW_SEQUENCE: "/campaigns/:campaignId/new/:tabName/sequence/:sequenceId",
    newSequence: (
      campaignId: number,
      tabName: string,
      sequenceId: number
    ): string =>
      `/campaigns/${campaignId}/new/${tabName}/sequence/${sequenceId}`,
    DETAIL: "/campaigns/:campaignId/tab/:tabName",
    detail: (campaignId: number, tabName: string): string =>
      `/campaigns/${campaignId}/tab/${tabName}`,
    detailSequenceAll: (campaignId: number): string =>
      `/campaigns/${campaignId}/tab/${CampaignDetailTabs.sequences}/sequence`,
    DETAIL_SEQUENCE: "/campaigns/:campaignId/tab/:tabName/sequence/:sequenceId",
    detailSequence: (
      campaignId: number,
      tabName: string,
      sequenceId: number
    ): string =>
      `/campaigns/${campaignId}/tab/${tabName}/sequence/${sequenceId}`,
  },
  COMPANY: {
    ALL: "/companies",
    DETAIL: "/companies/:companyId",
    detail: (companyId: number): string => `/companies/${companyId}`,
  },
  DOCS: {
    ACCOUNT: {
      ALL: `${ConfigConstant.HELP_URL}/accounts/`,
      CREATE: `${ConfigConstant.HELP_URL}/accounts/add-a-linkedin-account/`,
      SETTINGS: `${ConfigConstant.HELP_URL}/accounts/account-settings/`,
    },
    INDEX: `${ConfigConstant.HELP_URL}/`,
    INTEGRATIONS: {
      ALL: `${ConfigConstant.HELP_URL}/integrations/`,
      WEBHOOKS: `${ConfigConstant.HELP_URL}/integrations/webhook/`,
      REVERSE: {
        ALL: `${ConfigConstant.HELP_URL}/integrations/reverse-webhooks/`,
        API: `${ConfigConstant.HELP_URL}/integrations/reverse-webhooks/#create-your-api-key`,
      },
    },
    CAMPAIGNS: {
      ALL: `${ConfigConstant.HELP_URL}/campaigns/`,
      CREATE: `${ConfigConstant.HELP_URL}/campaigns/create-campaign/`,
      AUDIENCE: `${ConfigConstant.HELP_URL}campaigns/create-campaign/#add-audience`,
    },
    INBOX: `${ConfigConstant.HELP_URL}/inbox/`,
    PLACEHOLDERS: `${ConfigConstant.HELP_URL}/placeholders/`,
    SEARCH: `${ConfigConstant.HELP_URL}/search/`,
    // SEQUENCES: `${ConfigConstant.HELP_URL}/templates`,
  },
  INBOX: {
    ALL: "/inbox",
    DETAIL: "/inbox/:personId",
    detail: (personId: number): string => `/inbox/${personId}`,
  },
  INTERACTION: {
    DETAIL: "/interaction/:interactionId",
    detail: (interactionId: number): string => `/interaction/${interactionId}`,
  },
  LANDING: {
    INDEX: ConfigConstant.LANDING_URL,
    GDPR: `${ConfigConstant.LANDING_URL}/gdpr`,
    PRIVACY: `${ConfigConstant.LANDING_URL}/privacy`,
    TERMS: `${ConfigConstant.LANDING_URL}/terms`,
  },
  MY_NETWORK: {
    ROOT: "/mynetwork",
    ALL: "/mynetwork/:tabName",
    all: (tabName: string): string => `/mynetwork/${tabName}`,
  },
  NOTIFICATION: {
    ALL: "/notifications",
  },
  ROOT: "/",
  SEARCH: {
    ALL: "/search",
    NEW: "/search/new",
    DETAIL: "/search/:searchId",
    detail: (searchId: number): string => `/search/${searchId}`,
  },
  SEQUENCE: {
    ALL: "/templates",
    NEW: "/templates/new",
    DETAIL: "/templates/:sequenceId/tab/:tabName",
    detail: (sequenceId: number, tabName: string): string =>
      `/templates/${sequenceId}/tab/${tabName}`,
  },
  TEAM: {
    ROOT: "/team",
    ALL: "/team",
  },
  USER: {
    DETAIL: "/user",
    FORGOT_PASSWORD: "/forgot-password",
    RESET_PASSWORD: "/reset-password/:uid/:token",
    LOGOUT: "/logout",
    SIGN_IN: "/sign-in",
    SIGN_UP: "/sign-up",
    REGISTER_EMAIL: "/register-email",
    VERIFY_EMAIL: "/verify-email",
    VERIFY_USER: "/verify-user/:key",
    LOGIN: {
      GOOGLE: {
        AUTH: `https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=${ConfigConstant.GOOGLE_OAUTH_REDIRECT_URI}&prompt=consent&response_type=code&client_id=${ConfigConstant.GOOGLE_OAUTH_CLIENT_ID}&scope=openid%20email%20profile&access_type=offline`,
        REDIRECT: "/auth/google",
      },
    },
    NOTICE: "/notice",
  },
  INTEGRATION: {
    ROOT: "/integrations",
    WEBHOOK: {
      ALL: "/integrations/webhooks",
      NEW: "/integrations/webhooks/new",
      DETAIL: "/integrations/webhooks/:webhookId",
      detail: (webhookId: number): string =>
        `/integrations/webhooks/${webhookId}`,
    },
    API: "/integrations/api",
  },
};

export default RouterConstants;
