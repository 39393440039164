import { Box, Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/styles";
import get from "lodash/get";
import { CampaignQuery, ICampaign } from "modules/Campaign/models";
import { navigateToCampaign } from "modules/Campaign/utils";
import InteractionStatusActions from "modules/Interaction/components/InteractionStatusActions";
import {
  IInteraction,
  InteractionCategories,
  InteractionsQuery,
} from "modules/Interaction/models";
import InteractionService from "modules/Interaction/services";
import { getInteractionStatusChipData } from "modules/Interaction/utils";
import React from "react";
import { useQuery } from "react-query";
import { Chip, IBaseStatusChipData } from "ui-kit/atoms/Chip";

interface ProfileCampaignListItemProps {
  campaignId: number;
  campaign: ICampaign;
  personId?: number;
  index: number;
  count: number | undefined;
}

const ProfileCampaignListItem = ({
  campaignId,
  campaign,
  personId,
  index,
  count = 0,
}: ProfileCampaignListItemProps): React.ReactElement | null => {
  const theme = useTheme();

  const [statusProps, setStatusProps] = React.useState<
    IBaseStatusChipData | undefined
  >(undefined);

  const fetchDistinctInteractionOnPerson = async () => {
    try {
      const { data } =
        await InteractionService.fetchInteractionDistinctOnPerson(
          Number(personId),
          campaignId
        );
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };

  const { data: dataInteraction } = useQuery(
    [InteractionsQuery.campaign_person, campaignId, personId],
    () => fetchDistinctInteractionOnPerson(),
    {
      keepPreviousData: true,
      enabled: !!personId && !!campaignId,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const fetchStoppedInteractionOnPerson = async () => {
    try {
      const { data } = await InteractionService.fetchStoppedInteractionOnPerson(
        Number(personId),
        campaignId
      );
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };

  const { data: dataStopped } = useQuery(
    [CampaignQuery.stopped_person, campaignId, personId],
    () => fetchStoppedInteractionOnPerson(),
    {
      keepPreviousData: true,
      enabled: !!personId && !!campaignId,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const interaction: IInteraction = get(dataInteraction, "results[0]");

  React.useEffect(() => {
    if (interaction) {
      const { icon, ...rest } = getInteractionStatusChipData(interaction);
      setStatusProps(rest);
    }
  }, [interaction]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: 1.5,
        pt: 2,
        pb: 2.5,
        px: 4,
        borderBottom:
          count - 1 === index
            ? "none"
            : `1px solid ${theme.app.palette.shadow.primary}`,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1.5,
          overflow: "hidden",
        }}
      >
        <Tooltip title="Open campaign (new tab)" placement="bottom">
          <a
            target="_blank"
            rel="noreferrer"
            href={navigateToCampaign(campaign)}
          >
            <Typography
              key={campaign.id}
              variant="body2"
              color="textSecondary"
              sx={{
                fontSize: "0.8rem",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {campaign.name}
            </Typography>
          </a>
        </Tooltip>
        <Box sx={{ display: "flex", gap: 2 }}>
          {!!interaction &&
            !!statusProps &&
            interaction.category !== InteractionCategories.sequence_stopped && (
              <Chip variant="outlined" size="small" {...statusProps} />
            )}
          {!!dataStopped?.count && (
            <Chip
              variant="outlined"
              size="small"
              color="error"
              label="Finished"
            />
          )}
        </Box>
      </Box>

      {!!interaction && !dataStopped?.count && (
        <InteractionStatusActions
          contactId={interaction.contact?.id}
          person={interaction.person}
          searchResult={interaction.search_result}
          campaignId={campaignId}
          campaignName={interaction.campaign?.name}
          category={interaction.category}
        />
      )}
    </Box>
  );
};

ProfileCampaignListItem.defaultProps = {
  personId: undefined,
};

export default ProfileCampaignListItem;
