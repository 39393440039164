import groupBy from "lodash/groupBy";
import flatten from "lodash/flatten";
import get from "lodash/get";
import { InfiniteData } from "react-query";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
import { formatDateToPretty } from "core/utils/dateHandler";
import { createChipData, IStatusChipData } from "ui-kit/atoms/Chip";
import {
  IGroupedInteractions,
  IInteraction,
  IInteractionIssueCategory,
  IInteractions,
  InteractionCategories,
  InteractionStatusLabels,
} from "../models";

const getInteractionStatusChipData = (
  interaction: IInteraction
): IStatusChipData => {
  if (
    [
      InteractionCategories.sequence_paused,
      InteractionCategories.message,
    ].includes(interaction.category) &&
    interaction.account_is_actor === false
  ) {
    return createChipData(
      "success",
      CheckIcon,
      InteractionStatusLabels.replied
    );
  }

  if (interaction.category === InteractionCategories.person_in_campaign_queue) {
    return createChipData("info", CheckIcon, InteractionStatusLabels.queued);
  }

  if (
    interaction.other_issue?.category ===
    IInteractionIssueCategory.closed_profile
  ) {
    return createChipData(
      "error",
      CheckIcon,
      InteractionStatusLabels.closed_profile
    );
  }

  if (
    interaction.category === InteractionCategories.sequence_paused &&
    interaction.account_is_actor === true
  ) {
    return createChipData("warning", CheckIcon, InteractionStatusLabels.paused);
  }

  if (interaction.category === InteractionCategories.sequence_stopped) {
    return createChipData("error", CheckIcon, InteractionStatusLabels.stopped);
  }

  if (
    interaction.category === InteractionCategories.profile_view &&
    interaction.account_is_actor === true
  ) {
    return createChipData(
      "warning",
      CheckIcon,
      InteractionStatusLabels.profile
    );
  }

  if (
    interaction.category === InteractionCategories.connected &&
    interaction.account_is_actor === false
  ) {
    return createChipData(
      "primary",
      CheckIcon,
      InteractionStatusLabels.connected
    );
  }

  if (interaction.category === InteractionCategories.sequence_resumed) {
    return createChipData(
      "primary",
      CheckIcon,
      InteractionStatusLabels.resumed
    );
  }

  if (
    interaction.category === InteractionCategories.connection_request &&
    interaction.account_is_actor === true
  ) {
    return createChipData(
      "info",
      CheckIcon,
      InteractionStatusLabels.awaiting_reply
    );
  }

  return createChipData(
    "info",
    ClearIcon,
    InteractionStatusLabels.awaiting_reply
  );
};

const getGroupDate = (interaction: IInteraction): string =>
  formatDateToPretty(interaction.platform_created_at);

const isValidInteraction = (interaction: IInteraction): boolean => {
  if (
    interaction.failed &&
    (!interaction.other_issue ||
      get(interaction, "other_issue.category") ===
        IInteractionIssueCategory.unhandled)
  ) {
    return false;
  }
  return true;
};

const getInteractions = (data: InfiniteData<IInteractions>): IInteraction[] => {
  const allInteractions = data.pages.map(
    ({ results }: IInteractions) => results
  );
  const list = flatten(allInteractions);
  const filtered = list.filter(isValidInteraction);
  return filtered;
};

const getReversedInteractions = (
  data: InfiniteData<IInteractions>
): IInteraction[] => {
  const reversedPages = [...data.pages].reverse();
  const allInteractions = reversedPages.map(({ results }: IInteractions) => {
    return [...results].reverse();
  });
  return flatten(allInteractions);
};

const getGroupedInteractions = (items: IInteraction[]): IGroupedInteractions =>
  groupBy(items, getGroupDate);

const activityCategories = [
  InteractionCategories.message,
  InteractionCategories.profile_view,
  InteractionCategories.connection_request,
  InteractionCategories.sequence_paused,
  InteractionCategories.sequence_resumed,
  InteractionCategories.connected,
  InteractionCategories.disconnected,
  InteractionCategories.inMail,
  InteractionCategories.sequence_stopped,
  InteractionCategories.connection_request_withdraw,
];
const getActivityCategoryString = (): string => {
  let str = "";
  activityCategories.forEach((categoryIndex) => {
    str += `&category=${categoryIndex}`;
  });
  return str;
};

const getInteractionStep = (
  sequence_action_order_nr:
    | IInteraction["sequence_action_order_nr"]
    | undefined,
  category: IInteraction["category"]
): string => {
  let value = "-";
  if (sequence_action_order_nr) {
    value = sequence_action_order_nr.toString();
  }
  if (category === InteractionCategories.person_in_campaign_queue) {
    value = String(0);
  }
  return value;
};

const getInteractionErrorMessage = (interaction: IInteraction): string => {
  if (get(interaction, "other_issue.info")) {
    return interaction.other_issue.info;
  }
  return "Failed to send";
};

const getInboxPrivacyQuery = (x: boolean): string =>
  x ? `&campaign_id__isnull=false` : "";

export {
  getInteractionStatusChipData,
  getReversedInteractions,
  getInteractions,
  getGroupedInteractions,
  getActivityCategoryString,
  getInteractionStep,
  getInteractionErrorMessage,
  getInboxPrivacyQuery,
};
