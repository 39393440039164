import React from "react";
import { useQuery } from "react-query";
import CampaignService from "modules/Campaign/services";
import ConfigConstant from "core/constants/ConfigConstant";
import CampaignSequenceReport from "./components/CampaignReportSequence";
import { CampaignQuery } from "modules/Campaign/models";

interface CampaignStepReportProps {
  campaignId: number;
}

const CampaignStepReport = ({
  campaignId,
}: CampaignStepReportProps): React.ReactElement => {
  const fetchSequences = async () => {
    try {
      const { data } = await CampaignService.fetchCampaignSequences(
        campaignId,
        ConfigConstant.INITIAL_PAGE
      );
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };

  const { data } = useQuery(
    [CampaignQuery.sequences, campaignId],
    () => fetchSequences(),
    {
      keepPreviousData: true,
    }
  );

  const content = data?.results?.map((sequence, index) => (
    <CampaignSequenceReport
      key={sequence.id}
      campaignSequence={sequence}
      campaignId={campaignId}
      index={index}
    />
  ));

  return <>{content || null}</>;
};

export default CampaignStepReport;
