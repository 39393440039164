import React from "react";
import clsx from "clsx";
import { useHistory, useLocation } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import { makeStyles, useTheme } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import TableSortLabel from "@mui/material/TableSortLabel";
import MuiTable from "@mui/material/Table";
import FilterAltOff from "@mui/icons-material/FilterAltOff";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { PaperProps } from "@mui/material/Paper";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import { visuallyHidden } from "@mui/utils";
import { TableCellProps } from "core/utils/tableHandler";
import RouterUtils from "core/routes/utils";
import { GlobalContext } from "core/context";
import { GlobalActionType } from "core/reducers";
import { IActionProps, IMeta, ISelectItem } from "core/models";
import Button from "ui-kit/atoms/Button";
import Checkbox from "ui-kit/atoms/Checkbox";
import TableToolbar, { BaseTableToolbarProps } from "./TableToolbar";
import TablePaginationActions from "./TablePaginationActions";
import Loader from "./Loader";

export const TABLE_ROWS_PER_PAGE = 10;

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    position: "relative",
  },
  root: {},
  scrollableRoot: {
    overflowX: "auto",
    ...theme.app.constants.scrollbar,
  },
  infiniteRoot: {
    maxHeight: "calc(100vh - 287px)",
    borderRadius: theme.spacing(1, 1, 0, 0),
    ...theme.app.constants.table.scrollableDiv,
  },
  splitRoot: {
    overflowY: "scroll",
    ...theme.app.constants.table.scrollableDiv,
  },
  loadingChild: {
    maxHeight: "calc(100vh - 404px)",
    overflowY: "scroll",
  },
  loadingRoot: {
    maxHeight: "calc(100vh - 287px)",
    paddingBottom: 118,
  },
  table: {
    tableLayout: "fixed",
    position: "relative",
    borderCollapse: "separate",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  fixedPrefixCell: {
    position: "sticky",
    zIndex: 4,
    left: 0,
    backgroundColor: theme.palette.common.white,
    borderRight: `2px solid ${theme.app.palette.shadow.primary}`,
  },
  fixedPrefixScrolled: {
    borderRight: 0,
    "&:after": {
      boxShadow: "inset 10px 0 8px -8px rgb(0 0 0 / 15%)",
      content: '""',
      transform: "translateX(100%)",
      transition: theme.transitions.create("all", {
        duration: theme.transitions.duration.short,
      }),
      position: "absolute",
      right: 0,
      top: 0,
      bottom: -1,
      width: 30,
      pointerEvents: "none",
    },
  },
  fixedPrefixCellCheckbox: {
    left: 38, // Checkbox width
  },
  fixedSuffixScrolled: {
    position: "sticky",
    zIndex: 3,
    right: 0,
    backgroundColor: theme.palette.common.white,
    paddingLeft: 20,
    "&:after": {
      boxShadow: "inset -10px 0 8px -8px rgb(0 0 0 / 15%)",
      content: '""',
      transform: "translateX(-100%)",
      transition: theme.transitions.create("all", {
        duration: theme.transitions.duration.short,
      }),
      position: "absolute",
      left: 0,
      top: 0,
      bottom: -1,
      width: 30,
      pointerEvents: "none",
    },
  },
  progress: {
    position: "absolute",
    width: "100%",
    padding: 0,
    left: 0,
    right: 0,
    top: theme.app.constants.table.headerHeight - 3,
    border: 0,
    zIndex: 2,
  },
  tableCenterRow: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  tableCenterCell: {
    border: "none",
  },
  checkboxHeadCell: {
    paddingLeft: theme.spacing(1),
  },
  splitCell: {
    height: 70,
    "&:first-of-type": {
      width: "44%",
      paddingLeft: 10,
    },
    "&:last-of-type": {
      textAlign: "center",
      paddingRight: 10,
    },
  },
  infiniteCell: {
    "&:first-of-type": {
      paddingLeft: 12,
    },
  },
  checkboxCell: {
    "&:first-of-type": {
      paddingLeft: 0,
      paddingRight: 0,
    },
    width: 38,
    borderRight: 0,
  },
  checkboxSplitCell: {
    "&:first-of-type": {
      paddingLeft: 6,
      paddingRight: 0,
    },
  },
  splitPagination: {
    "&:last-child": {
      padding: theme.spacing(0, 3),
    },
  },
  infinitePagination: {
    "&:last-child": {
      padding: theme.spacing(0, 4),
    },
  },
  splitCheckboxCell: {
    "&:nth-child(2)": {
      paddingLeft: 3,
      width: "44%",
    },
  },
}));

export enum TableOrder {
  "asc" = "asc",
  "desc" = "desc",
}

export interface ITableHead {
  id: string;
  label: string;
  percentage?: boolean;
  width: number;
  order?: string;
}

export interface IRow {
  name: string;
  meta?: IMeta;
  data: TableCellProps[];
}

export enum TableVariant {
  "default" = "default",
  "scrollable" = "scrollable",
  "lastColumnSticky" = "lastColumnSticky",
  "split" = "split",
  "checkable" = "checkable",
  "infinite" = "infinite",
}

export interface TableProps extends BaseTableToolbarProps {
  heads: ITableHead[];
  rows: IRow[];
  count: number;
  isFetching: boolean;
  isLoading?: boolean;
  page: number;
  variant?: TableVariant[];
  setPage: (page: number) => void;
  tableProps?: PaperProps;
  rowsPerPage?: number;
  actionPropsIfEmpty?: IActionProps;
  panel?: React.ReactElement;
  loader?: React.ReactElement;
  defaultOrderBy?: string;
  disableToolbar?: boolean;
  disableEmptyRows?: boolean;
  disableRowHover?: boolean;
  disablePagination?: boolean;
  definedSplitTableHeight?: string;
  selectedRowName?: string;
  definedInfiniteHeight?: string;
}

const parseDefaultOrdering = (o: string | undefined): TableOrder => {
  if (!o) {
    return TableOrder.desc;
  }
  return o.substring(0, 1) === "-" ? TableOrder.desc : TableOrder.asc;
};

function Table({
  title,
  subtitle,
  heads,
  rows,
  filters,
  actions = undefined,
  count,
  isFetching,
  isLoading,
  page,
  variant,
  tableProps,
  rowsPerPage = TABLE_ROWS_PER_PAGE,
  setPage,
  actionPropsIfEmpty,
  panel,
  loader,
  defaultOrderBy,
  disableToolbar = false,
  disableDivider = false,
  disableEmptyRows = false,
  disableRowHover = false,
  disablePagination = false,
  definedSplitTableHeight,
  selectedRowName,
  definedInfiniteHeight,
}: TableProps): React.ReactElement {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const columnCount = heads.length;
  const rowCount = rows.length;

  const {
    dispatch,
    global: { selected },
  } = React.useContext(GlobalContext);
  const rowSelected = selected.map((x) => x.name) || [];
  const selectedCount = rowSelected.length;
  const queryParams = RouterUtils.getQueryParams(location);
  const [scrolled, setScrolled] = React.useState(false);
  const [endScrolled, setEndScrolled] = React.useState(false);

  function handleScroll(event: React.SyntheticEvent<HTMLDivElement>) {
    setScrolled(event?.currentTarget.scrollLeft > 0);
    setEndScrolled(
      event.currentTarget.scrollWidth -
        event.currentTarget.offsetWidth -
        event.currentTarget.scrollLeft <
        1
    );
  }

  const handleOnSelected = (newSelected: ISelectItem[]) => {
    dispatch({
      type: GlobalActionType.SET_GLOBAL,
      payload: { selected: newSelected },
    });
  };

  const [order, setOrder] = React.useState<TableOrder>(
    parseDefaultOrdering(defaultOrderBy)
  );
  const [orderBy, setOrderBy] = React.useState<string | undefined>(
    defaultOrderBy
  );
  const firstRowRef = React.useRef<HTMLTableRowElement>(null);
  const scrollToFirstRow = () => {
    firstRowRef.current?.scrollIntoView();
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows;
      handleOnSelected(newSelected);
      return;
    }
    handleOnSelected([]);
  };

  const isSelected = (name: string) => rowSelected.indexOf(name) !== -1;

  const handleCheck = (event: React.MouseEvent<unknown>, row: IRow) => {
    const name = row.name;
    const selectedIndex = rowSelected.indexOf(name);
    let newSelected: ISelectItem[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === rowSelected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    handleOnSelected(newSelected);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    // INITIAL_PAGE starts at 1, but Pagination starts at 0
    setPage(newPage + 1);
    scrollToFirstRow();
  };

  // Only show progress bar if there are no results
  const progressBar = (
    <TableCell className={classes.progress} component="th">
      {isFetching && !isLoading && <LinearProgress />}
    </TableCell>
  );

  const handleClearFilter = () => history.push({ search: "" });

  const defaultNoResults = (
    <TableRow>
      <TableCell colSpan={columnCount} sx={{ borderBottom: "none" }}>
        <Box sx={{ my: 1, px: 1, display: "flex", alignItems: "center" }}>
          <Typography variant="body2" color="textSecondary">
            There are no results.
          </Typography>
          {!isEmpty(queryParams) && (
            <Box sx={{ my: 1, mx: 2 }}>
              <Button
                variant="outlined"
                color="inherit"
                size="small"
                type="button"
                startIcon={<FilterAltOff />}
                onClick={handleClearFilter}
              >
                Clear all filters
              </Button>
            </Box>
          )}
        </Box>
      </TableCell>
    </TableRow>
  );

  const noResultsContent = !isFetching && count === 0 && (
    <>{defaultNoResults}</>
  );

  const defaultLoader = variant?.includes(TableVariant.infinite) ? (
    <TableRow>
      <TableCell colSpan={columnCount}>
        <Loader minHeight={300} />
      </TableCell>
    </TableRow>
  ) : (
    <Loader minHeight={300} />
  );

  const getRowCells = (row: IRow, rowNumber: number) =>
    row.data.map((cell, index: number) => {
      const { component: Component } = cell;
      // Set to add different class to the main column if first is checkbox
      const indexCell = variant?.includes(TableVariant.checkable)
        ? index + 1
        : index;
      const tableCellProps = {
        ...cell,
        name: `${rowNumber}-${index}`,
        key: index,
        index: indexCell,
      };

      const last = index === row.data.length - 1;

      return (
        <Component
          className={clsx({
            [classes.infiniteCell]: variant?.includes(TableVariant.infinite),
            [classes.splitCell]: variant?.includes(TableVariant.split),
            [classes.splitCheckboxCell]:
              variant?.includes(TableVariant.split) &&
              variant?.includes(TableVariant.checkable),
            [classes.fixedPrefixCell]:
              variant?.includes(TableVariant.scrollable) && index === 0,
            [classes.fixedPrefixCellCheckbox]:
              variant?.includes(TableVariant.scrollable) &&
              index === 0 &&
              variant?.includes(TableVariant.checkable),
            [classes.fixedPrefixScrolled]:
              variant?.includes(TableVariant.scrollable) &&
              index === 0 &&
              scrolled,
            [classes.fixedPrefixScrolled]:
              variant?.includes(TableVariant.scrollable) &&
              index === 0 &&
              scrolled,
            [classes.fixedSuffixScrolled]:
              variant?.includes(TableVariant.lastColumnSticky) &&
              last &&
              !endScrolled,
          })}
          {...tableCellProps}
          key={tableCellProps.key}
        />
      );
    });

  const updateQuery = (newOrder: string, property: string) => {
    const newQuery =
      newOrder === TableOrder.desc ? `-${String(property)}` : String(property);
    RouterUtils.addQuery({ ordering: newQuery }, history);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === TableOrder.asc;
    const newOrder = isAsc ? TableOrder.desc : TableOrder.asc;
    setOrder(newOrder);
    setOrderBy(property);

    updateQuery(newOrder, property);
  };

  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, property);
    };

  return (
    <div {...tableProps} className={classes.paper}>
      {!disableToolbar && (
        <TableToolbar
          title={title}
          subtitle={subtitle}
          numSelected={selectedCount}
          filters={filters}
          actions={actions}
          disableDivider={disableDivider}
        />
      )}
      <Box sx={{ position: "relative" }}>
        <TableContainer
          className={clsx(classes.root, {
            [classes.infiniteRoot]: variant?.includes(TableVariant.infinite),
            [classes.splitRoot]: variant?.includes(TableVariant.split),
            [classes.loadingRoot]:
              variant?.includes(TableVariant.infinite) && !!loader,
          })}
          data-cy="data-table"
          {...(variant?.includes(TableVariant.split) && {
            sx: {
              height: definedSplitTableHeight,
            },
          })}
          {...(definedInfiniteHeight &&
            variant?.includes(TableVariant.infinite) && {
              sx: {
                height: definedInfiniteHeight,
              },
            })}
        >
          <div ref={firstRowRef} />
          <Box
            className={clsx({
              [classes.loadingChild]:
                variant?.includes(TableVariant.infinite) && !!loader,
              [classes.scrollableRoot]: variant?.includes(
                TableVariant.scrollable
              ),
            })}
            {...(variant?.includes(TableVariant.scrollable) && {
              onScroll: handleScroll,
            })}
          >
            <MuiTable
              stickyHeader
              className={classes.table}
              aria-label="a table"
              sx={{
                [theme.breakpoints.down("md")]: {
                  minWidth:
                    heads.length * theme.app.constants.table.columnMobileWidth,
                },
              }}
            >
              {!variant?.includes(TableVariant.split) && (
                <TableHead color="primary">
                  <TableRow>
                    {variant?.includes(TableVariant.checkable) &&
                      rowCount !== 0 && (
                        <TableCell
                          padding="checkbox"
                          className={clsx(classes.checkboxCell, {
                            [classes.fixedPrefixCell]: variant?.includes(
                              TableVariant.scrollable
                            ),
                            [classes.checkboxSplitCell]: variant?.includes(
                              TableVariant.split
                            ),
                          })}
                        >
                          <Checkbox
                            size="small"
                            disableFocusRipple
                            disableTouchRipple
                            disableRipple
                            indeterminate={
                              selectedCount > 0 && selectedCount < rowCount
                            }
                            checked={rowCount > 0 && selectedCount === rowCount}
                            onChange={handleSelectAllClick}
                            color="primary"
                          />
                        </TableCell>
                      )}
                    {heads.map((headCell, index) => {
                      const last = index === heads.length - 1;
                      return (
                        <TableCell
                          key={headCell.id}
                          className={clsx({
                            [classes.checkboxHeadCell]:
                              variant?.includes(TableVariant.checkable) &&
                              rowCount !== 0 &&
                              index === 0,
                            [classes.fixedPrefixCell]:
                              variant?.includes(TableVariant.scrollable) &&
                              index === 0,
                            [classes.fixedPrefixCellCheckbox]:
                              variant?.includes(TableVariant.scrollable) &&
                              index === 0 &&
                              variant?.includes(TableVariant.checkable),
                            [classes.infiniteCell]:
                              variant?.includes(TableVariant.infinite) &&
                              index === 0,
                            [classes.fixedPrefixScrolled]:
                              variant?.includes(TableVariant.scrollable) &&
                              index === 0 &&
                              scrolled,
                            [classes.fixedSuffixScrolled]:
                              variant?.includes(
                                TableVariant.lastColumnSticky
                              ) &&
                              last &&
                              !endScrolled,
                          })}
                          sx={{
                            width: `${headCell.width}${
                              headCell.percentage ? "%" : "px"
                            }`,
                          }}
                          variant="head"
                          sortDirection={
                            orderBy === headCell.order ? order : false
                          }
                        >
                          {headCell.order ? (
                            <TableSortLabel
                              active={orderBy === headCell.order}
                              direction={
                                orderBy === headCell.order
                                  ? order
                                  : TableOrder.asc
                              }
                              onClick={createSortHandler(headCell.order)}
                            >
                              {headCell.label}
                              {orderBy === headCell.order ? (
                                <Box component="span" sx={visuallyHidden}>
                                  {order === TableOrder.desc
                                    ? "sorted descending"
                                    : "sorted ascending"}
                                </Box>
                              ) : null}
                            </TableSortLabel>
                          ) : (
                            headCell.label
                          )}
                        </TableCell>
                      );
                    })}
                    {progressBar}
                  </TableRow>
                </TableHead>
              )}
              <TableBody>
                {noResultsContent}
                {isLoading && defaultLoader}
                {rows.map((row, index: number) => {
                  const isItemSelected = isSelected(row.name);
                  const selected =
                    isItemSelected || selectedRowName
                      ? selectedRowName === row.name
                      : false;
                  return (
                    <TableRow
                      {...(!disableRowHover && { hover: true })}
                      tabIndex={-1}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      key={row.name}
                      selected={selected}
                      sx={{ scrollMarginTop: 50 }}
                    >
                      {variant?.includes(TableVariant.checkable) && (
                        <TableCell
                          padding="checkbox"
                          className={clsx(classes.checkboxCell, {
                            [classes.fixedPrefixCell]: variant?.includes(
                              TableVariant.scrollable
                            ),
                            [classes.checkboxSplitCell]: variant?.includes(
                              TableVariant.split
                            ),
                          })}
                          onClick={(event) => {
                            return handleCheck(event, row);
                          }}
                        >
                          <Checkbox
                            disableFocusRipple
                            disableTouchRipple
                            disableRipple
                            size="small"
                            color="primary"
                            checked={isItemSelected}
                          />
                        </TableCell>
                      )}
                      {getRowCells(row, index + 1)}
                    </TableRow>
                  );
                })}
              </TableBody>
            </MuiTable>
          </Box>
        </TableContainer>
        {loader}
      </Box>
      {!!count && !disablePagination && (
        <TablePagination
          rowsPerPageOptions={[rowsPerPage]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          ActionsComponent={TablePaginationActions}
          className={clsx({
            [classes.splitPagination]: variant?.includes(TableVariant.split),
            [classes.infinitePagination]: variant?.includes(
              TableVariant.infinite
            ),
          })}
          labelDisplayedRows={({
            from,
            to,
            count,
          }: {
            from: number;
            to: number;
            count: number;
          }) =>
            `${from?.toLocaleString()}–${to?.toLocaleString()} of ${count?.toLocaleString()} results`
          }
        />
      )}
      {panel}
    </div>
  );
}

Table.defaultProps = {
  title: "",
  subtitle: "",
  variant: [TableVariant.default],
  tableProps: {
    variant: "elevation",
  },
  isLoading: undefined,
  actions: undefined,
  actionPropsIfEmpty: undefined,
  panel: undefined,
  defaultOrderBy: undefined,
  disableToolbar: false,
  disableEmptyRows: false,
  disableRowHover: false,
  disablePagination: false,
  definedSplitTableHeight: "0px",
};

export default Table;
