import React from "react";
import MuiTextField, { TextFieldProps } from "@mui/material/TextField";

const TextField = React.forwardRef<HTMLDivElement, TextFieldProps>(
  function TextField(props: TextFieldProps, ref) {
    const { InputProps, onChange, select, autoFocus } = props;
    return (
      <MuiTextField
        // Set focus instead of autoFocus
        // autoFocus didn't focus on first render
        {...(autoFocus && {
          inputRef: (input: any) => {
            if (input) {
              setTimeout(() => {
                input.focus();
              }, 0);
            }
          },
        })}
        {...props}
        onChange={(e) => {
          if (onChange) {
            onChange(e);
          }
          if (select) {
            // Fix for MUI TextField(select) component focused after selecting an option.
            setTimeout(() => {
              (document.activeElement as HTMLElement).blur();
            }, 0);
          }
        }}
        InputProps={{
          ...InputProps,
        }}
        ref={ref}
      />
    );
  }
);

export default TextField;
