import { Edit } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import MuiButton from "@mui/material/Button";
import { Theme } from "@mui/material/styles";
import { makeStyles, useTheme } from "@mui/styles";
import { ITitleDropdownList, TitleDropdownVariant } from "core/models";
import { forceStyle } from "core/utils/commonHandler";
import React from "react";
import Button from "ui-kit/atoms/Button";
import TextField from "ui-kit/atoms/TextField";
import { PageHeaderTitleProps } from "./PageHeader";

const useStyles = makeStyles((theme: Theme) => ({
  menuBtn: {
    padding: theme.spacing(0.5, 1.5, 0.75),
  },
  menuBtnEndIcon: {
    marginLeft: theme.spacing(0.75),
    color: theme.app.palette.action.color,
    "& > svg:first-of-type": {
      fontSize: 22,
    },
  },
  item: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  menu: {
    minWidth: 180,
    border: `1px solid ${theme.app.palette.shadow.secondary}`,
  },
  icon: {
    minWidth: "26px !important",
    color: theme.app.palette.action.color,
  },
  inputBox: {
    padding: theme.spacing(0.5, 0),
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
      gap: 5,
    },
  },
  input: {
    padding: forceStyle(theme.spacing(1.75, 2, 1.5)),
    minWidth: 240,
  },
  iconBtn: {
    border: `1px solid ${theme.app.palette.action.border}`,
    backgroundColor: theme.palette.common.white,
    borderRadius: 3,
    marginLeft: theme.spacing(1),
  },
}));

interface PageTitleDropdownProps {
  titleElement: PageHeaderTitleProps["title"];
  titleText: string | undefined;
  menuProps: ITitleDropdownList;
  disableEditIcon?: boolean;
}

const PageTitleDropdown = ({
  titleElement,
  titleText = "",
  menuProps,
  disableEditIcon,
}: PageTitleDropdownProps) => {
  const classes = useStyles();
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openEdit, setOpenEdit] = React.useState<boolean>(false);

  const [titleValue, setTitleValue] = React.useState<string>(titleText);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setTitleValue(newValue);
  };

  const handleOnSubmit = () => {
    const editMenuItem = menuProps.find(
      ({ id }) => id === TitleDropdownVariant.rename
    );
    if (editMenuItem) {
      handleCloseEdit();
      return editMenuItem.func({ name: titleValue });
    }
  };

  const handleOpenEdit = () => {
    setOpenEdit(true);
  };

  const firstUpdate = React.useRef(true);
  React.useEffect(() => {
    if (titleText) {
      if (!firstUpdate.current) {
        return;
      }

      // Set titleText once it's available
      setTitleValue(titleText);

      firstUpdate.current = false;
    }
  }, [titleText]);

  return (
    <>
      {openEdit ? (
        <div className={classes.inputBox}>
          <TextField
            // Set focus instead of autoFocus
            // autoFocus didn't focus on first render
            inputRef={(input) =>
              input &&
              setTimeout(() => {
                input.focus();
              }, 0)
            }
            value={titleValue}
            onChange={handleOnChange}
            variant="outlined"
            sx={{ marginBottom: 0 }}
            InputProps={{
              classes: {
                input: classes.input,
              },
            }}
          />
          <Button
            color="primary"
            variant="outlined"
            size="small"
            sx={{ [theme.breakpoints.up("md")]: { ml: 2 } }}
            onClick={handleOnSubmit}
          >
            Update
          </Button>
          <Button
            color="inherit"
            variant="outlined"
            size="small"
            sx={{ [theme.breakpoints.up("md")]: { ml: 1 } }}
            onClick={handleCloseEdit}
          >
            Cancel
          </Button>
        </div>
      ) : (
        <>
          <MuiButton
            size="small"
            className={classes.menuBtn}
            onClick={handleClick}
            color="inherit"
            endIcon={<ExpandMoreIcon />}
            classes={{ endIcon: classes.menuBtnEndIcon }}
          >
            {titleElement}
          </MuiButton>
          {!disableEditIcon && (
            <>
              <Tooltip title="Rename" arrow placement="top">
                <IconButton
                  size="small"
                  className={classes.iconBtn}
                  onClick={handleOpenEdit}
                  color="inherit"
                >
                  <Edit fontSize="small" />
                </IconButton>
              </Tooltip>
              {/* <Tooltip title="Delete" arrow placement="top">
                <IconButton
                  size="small"
                  className={classes.iconBtn}
                  onClick={handleOpenEdit}
                  color="inherit"
                >
                  <Delete fontSize="small" />
                </IconButton>
              </Tooltip> */}
            </>
          )}
        </>
      )}

      <Menu
        id="title-expand-menu"
        aria-labelledby="title-expand-button"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        MenuListProps={{
          sx: {
            padding: 0,
          },
        }}
        PaperProps={{
          elevation: 24,
          className: classes.menu,
        }}
      >
        {menuProps.map(({ id, label, icon: Icon, func }, index) => (
          <MenuItem
            key={id}
            onClick={() => {
              if (id === TitleDropdownVariant.rename) {
                handleOpenEdit();
              } else {
                // handle menu item function - ex. delete item
                func();
              }
              handleClose();
            }}
            divider={index < menuProps.length - 1}
            className={classes.item}
          >
            {!!Icon && (
              <ListItemIcon className={classes.icon}>
                <Icon sx={{ fontSize: ".9rem" }} />
              </ListItemIcon>
            )}
            <ListItemText
              primary={label}
              primaryTypographyProps={{
                variant: "body2",
                color: "textPrimary",
              }}
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default PageTitleDropdown;
