import { UserContext } from "modules/User/context";
import UserService from "modules/User/services";
import React from "react";
import { useQuery } from "react-query";

const useVerifyUser = () => {
  const { user } = React.useContext(UserContext);

  const { data } = useQuery(
    ["user-verification"],
    async () => {
      try {
        const response = await UserService.verifyUserMe();
        return response.data;
      } catch (err) {
        throw new Error(String(err));
      }
    },
    {
      // If user is logged in
      enabled: !user.anonymous,
    }
  );

  React.useEffect(() => {
    if (data && data?.r) {
      window.location.href = `${data?.r}/sign-in?q=new&d=${user.token}`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
};

export default useVerifyUser;
