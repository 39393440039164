import { Box, Paper, Typography } from "@mui/material";
import RouterConstants from "core/routes/constants";
import { useCustomerly } from "react-live-chat-customerly";
import { useHistory } from "react-router-dom";
import Button from "ui-kit/atoms/Button";

interface PageAlertProps {
  title: string | React.ReactElement;
  body: string | React.ReactElement;
}

const PageAlert = ({ title, body }: PageAlertProps) => {
  const { open } = useCustomerly();
  const history = useHistory();

  const handlePageReload = () => {
    history.push(RouterConstants.ROOT);
    // eslint-disable-next-line no-restricted-globals
    location.reload();
  };

  return (
    <Paper variant="outlined" sx={{ mt: 2, mb: 5 }}>
      <Box sx={{ p: 5 }}>
        <Typography variant="h6" paragraph>
          {title}
        </Typography>
        <Typography variant="body2" sx={{ mb: 5 }}>
          {body}
        </Typography>
        <Box mb={4}>
          <Typography variant="subtitle1" fontWeight={600} gutterBottom>
            Need more help?
          </Typography>
          <Typography variant="body2">
            If you believe this is an error, please contact our support for
            assistance.
          </Typography>
        </Box>
        <Button
          size="small"
          sx={{ mr: 2 }}
          variant="contained"
          color="primary"
          onClick={handlePageReload}
        >
          Reload page
        </Button>
        <Button color="primary" size="small" variant="outlined" onClick={open}>
          Contact support
        </Button>
      </Box>
    </Paper>
  );
};

export default PageAlert;
