import React from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { BacklinkButtonVariant } from "core/models";

type BacklinkButtonProps = {
  text: string;
  link?: string;
  active?: boolean;
  onClick?: () => void;
  variant: BacklinkButtonVariant;
  marginPosition?: "left" | "bottom";
};

const useStyles = makeStyles((theme: Theme) => ({
  backlink: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    color: theme.app.palette.action.color,
    width: "fit-content",
    border: 0,
    cursor: "pointer",
    padding: 0,
    margin: 0,
    "&:hover": {
      backgroundColor: "inherit",
      color: theme.app.palette.text.secondary,
    },
  },
  backlinkVertical: {
    flexDirection: "column",
  },
  icon: {
    transition: "all 0.2s ease",
    marginRight: theme.spacing(0.5),
  },
  active: {
    transform: "rotate(90deg)",
    marginLeft: theme.spacing(-0.5),
    marginRight: theme.spacing(1),
  },
  iconVertical: {
    marginBottom: theme.spacing(1.5),
    transform: "rotate(90deg)",
  },
  backlinkBottom: {
    marginBottom: theme.spacing(2),
  },
  backlinkLeft: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
}));

const BacklinkButton = ({
  text,
  link,
  variant,
  active = false,
  marginPosition = "bottom",
  onClick,
}: BacklinkButtonProps): React.ReactElement => {
  const classes = useStyles();
  return (
    <Button
      disableRipple
      disableFocusRipple
      className={clsx(classes.backlink, {
        [classes.backlinkBottom]: marginPosition === "bottom",
        [classes.backlinkLeft]: marginPosition === "left",
        [classes.backlinkVertical]: variant === BacklinkButtonVariant.vertical,
      })}
      // Button is a Link
      {...(link && {
        to: link,
        component: Link,
      })}
      // Button is a button
      {...(onClick && { onClick })}
    >
      {variant === BacklinkButtonVariant.back && (
        <ArrowBackIcon style={{ fontSize: 14 }} className={classes.icon} />
      )}
      {variant === BacklinkButtonVariant.content && (
        <ArrowForwardIcon
          style={{ fontSize: 14 }}
          className={clsx({ [classes.icon]: true, [classes.active]: active })}
        />
      )}
      {variant === BacklinkButtonVariant.vertical && (
        <ArrowForwardIcon
          style={{ fontSize: 14 }}
          className={clsx(classes.icon, classes.iconVertical)}
        />
      )}
      <Typography variant="body2" component="p">
        {text}
      </Typography>
    </Button>
  );
};

BacklinkButton.defaultProps = {
  link: "",
  active: false,
  onClick: () => null,
  marginPosition: "bottom",
};

export default BacklinkButton;
