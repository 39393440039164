import { ISearchSheetFieldsFormValues } from "../models";

export enum SearchActionType {
  SET_COLUMNS,
}

export type SearchAction = {
  type: SearchActionType.SET_COLUMNS;
  payload: ISearchSheetFieldsFormValues;
};

export const searchReducer = (
  state: ISearchSheetFieldsFormValues,
  action: SearchAction
): ISearchSheetFieldsFormValues => {
  switch (action.type) {
    case SearchActionType.SET_COLUMNS: {
      const newState = action.payload;
      return newState;
    }
    default:
      return state;
  }
};
