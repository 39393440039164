import * as Yup from "yup";

export const ERROR_KEYS = {
  NOTE: {
    REQUIRED: "Can't create an empty note.",
  },
};

const createNote: Yup.AnyObjectSchema = Yup.object().shape({
  content: Yup.string().required(ERROR_KEYS.NOTE.REQUIRED),
});

const NoteValidations = {
  createNote,
};

export default NoteValidations;
