import { Typography } from "@mui/material";
import Step from "@mui/material/Step";
import MuiStepConnector from "@mui/material/StepConnector";
import StepContent from "@mui/material/StepContent";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { Theme } from "@mui/material/styles";
import { makeStyles, withStyles } from "@mui/styles";
import ElementField from "core/components/ElementField";
import SettingsItem from "core/components/SettingsItem";
import { createComponents } from "core/utils/componentsHandler";
import { formatDateTimeToSmartTime } from "core/utils/dateHandler";
import { errorHandler, IErrorResponse } from "core/utils/errorHandler";
import {
  AccountLogStrategyInputName,
  AccountQuery,
  IAccountLog,
  IAccountLogStatusLabels,
} from "modules/Account/models";
import AccountService from "modules/Account/services";
import { getLogContent } from "modules/Account/utils";
import { INotification, INotifications } from "modules/Notification/models";
import NotificationService from "modules/Notification/services";
import React from "react";
import { useMutation, useQueryClient } from "react-query";
import LogIcon from "../LogIcon";

const StepConnector = withStyles((theme) => ({
  vertical: {
    marginLeft: theme.spacing(3.5),
  },
}))(MuiStepConnector);

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: 600,
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  stepper: {
    flexDirection: "column-reverse",
  },
  date: {
    color: theme.app.palette.action.color,
  },
  input: {
    [theme.breakpoints.up("md")]: {
      maxWidth: theme.app.constants.inputMaxWidth,
    },
  },
}));

interface ActionLogStepperProps {
  accountId: number;
  login: string | undefined;
  steps: IAccountLog[];
}

type NotificationData = INotifications | undefined;

const ActionLogStepper = ({
  accountId,
  login,
  steps,
}: ActionLogStepperProps): React.ReactElement => {
  const classes = useStyles();
  const queryClient = useQueryClient();

  // Mark notification as read (unread: false)
  const mutateOnClick = useMutation(
    (newData: INotification) =>
      NotificationService.updateNotification({ ...newData, unread: false }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["notifications"]);
        queryClient.invalidateQueries(["notifications-recent"]);
        queryClient.invalidateQueries(["notifications-unread-all"]);
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);
      },
    }
  );

  return (
    <div className={classes.root}>
      <Stepper
        activeStep={steps.length - 1}
        orientation="vertical"
        className={classes.stepper}
        connector={<StepConnector />}
      >
        {[...steps].reverse().map((step) => {
          const currentLogContent = getLogContent(step, login);
          let notification: INotification | undefined;
          if (step.strategy) {
            const notificationData: NotificationData = queryClient.getQueryData(
              "notifications-recent"
            );
            if (notificationData) {
              notification = notificationData.results.find(
                ({ description }) => description === currentLogContent?.key
              ) as INotification;
            }
          }
          return (
            <Step key={step.id} sx={{ padding: 0 }}>
              <StepLabel
                StepIconComponent={LogIcon}
                StepIconProps={{ icon: step.status }}
              >
                <Typography
                  variant="caption"
                  component="p"
                  className={classes.date}
                >
                  {formatDateTimeToSmartTime(step.created)}
                </Typography>
                {step.msg ||
                  currentLogContent?.key ||
                  IAccountLogStatusLabels[step.status]}
              </StepLabel>
              {!!currentLogContent && (
                <StepContent>
                  <SettingsItem
                    FormProps={{
                      id: step.id,
                      name: [AccountQuery.last_log, accountId],
                      func: AccountService.updateAccountLog,
                      onSettled: () => {
                        if (notification) {
                          mutateOnClick.mutate(notification);
                        }
                      },
                      format: (d: IAccountLog) => d,
                      defaultValues: {
                        user_input: {
                          [AccountLogStrategyInputName[
                            currentLogContent.strategy
                          ]]: step.user_input || "", // empty string makes the input controlled
                        },
                      },
                    }}
                    PaperHeaderProps={{
                      body: currentLogContent.description,
                    }}
                    {...(!currentLogContent.disableInput && {
                      components: [
                        createComponents(
                          ElementField,
                          `user_input.${
                            AccountLogStrategyInputName[
                              currentLogContent.strategy
                            ]
                          }`,
                          {
                            InputProps: {
                              // autoFocus: true,
                              label: currentLogContent.label,
                              fullWidth: true,
                              classes: {
                                root: classes.input,
                              },
                            },
                          }
                        ),
                      ],
                    })}
                    SubmitButtonProps={{
                      color: "primary",
                      value: currentLogContent.disableInput
                        ? currentLogContent.label
                        : "Continue",
                      removeDisabled: currentLogContent.disableInput,
                    }}
                    hiddenDivider
                  />
                </StepContent>
              )}
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
};

export default ActionLogStepper;
