import React from "react";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import ConfigConstant from "core/constants/ConfigConstant";
import SettingsItem from "core/components/SettingsItem";
import { createComponents } from "core/utils/componentsHandler";
import ElementField from "core/components/ElementField";
import AccountService from "modules/Account/services";
import {
  AccountQuery,
  IAccount,
  IAccountPasswordChangeFormInput,
} from "modules/Account/models";
import AccountValidation from "modules/Account/validations";
import PasswordField from "ui-kit/components/PasswordField";
import PaperHeader from "ui-kit/components/PaperHeader";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3.5, 2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(4, 0, 8),
    },
  },
  box: {
    padding: theme.spacing(4, 5, 2, 4),
    borderRadius: 5,
    border: `1px solid ${theme.app.palette.shadow.primary}`,
    maxWidth: 700,
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(3),
  },
  input: {
    display: "block",
  },
  label: {
    fontWeight: 600,
  },
  helperText: {
    color: theme.app.palette.action.placeholder,
  },
}));

interface AccountCredentialsSettingsProps {
  account: IAccount;
}

const AccountCredentialsSettings = ({
  account,
}: AccountCredentialsSettingsProps): React.ReactElement => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <div className={classes.root}>
        <PaperHeader
          title="LinkedIn credentials"
          body="Keep your credentials updated to be able to sync your LinkedIn account."
        />
        <div className={classes.box}>
          <div className={classes.row}>
            <Typography className={classes.label} variant="subtitle2">
              Login (LinkedIn)
            </Typography>
            <Typography variant="body2">{account.login}</Typography>
          </div>
          <div className={classes.row}>
            <div>
              <Typography className={classes.label} variant="subtitle2">
                Password (LinkedIn)
              </Typography>
              <Typography className={classes.helperText} variant="caption">
                If you changed your LinkedIn password, you also need to update
                it in {ConfigConstant.APP_NAME}.
              </Typography>
            </div>
            <Button size="small" onClick={() => setOpen(!open)}>
              Update password
            </Button>
          </div>
          {open && (
            <SettingsItem
              FormProps={{
                id: account.id,
                name: [AccountQuery.account, account.id],
                func: AccountService.updateAccountPassword,
                format: (d: IAccountPasswordChangeFormInput) => d,
                defaultValues: {
                  password: "",
                  password_confirm: "",
                },
                schema: AccountValidation.updatePassword(),
                resetForm: true,
              }}
              components={[
                createComponents(ElementField, "password", {
                  InputProps: {
                    label: "New password",
                    type: "password",
                    className: classes.input,
                    autoComplete: "new-password",
                  },
                  component: PasswordField,
                }),
                createComponents(ElementField, "password_confirm", {
                  InputProps: {
                    label: "Confirm new password",
                    type: "password",
                    className: classes.input,
                    autoComplete: "new-password",
                  },
                  component: PasswordField,
                }),
              ]}
              hiddenDivider
            />
          )}
        </div>
      </div>
      <Divider />
    </>
  );
};

export default AccountCredentialsSettings;
