import { yupResolver } from "@hookform/resolvers/yup";
import { DialogContentText } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import ElementField from "core/components/ElementField";
import { errorHandler, IErrorResponse } from "core/utils/errorHandler";
import { snackbarHandler } from "core/utils/snackbarHandler";
import { AccountContext } from "modules/Account/context";
import { ICreateSequenceFormPost, ISequence } from "modules/Sequence/models";
import SequenceService from "modules/Sequence/services";
import SequenceValidations from "modules/Sequence/validations";
import React from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import Button from "ui-kit/atoms/Button";
import DialogHeader from "ui-kit/components/DialogHeader";

interface SaveSequenceTemplateProps {
  sequenceId: number | undefined;
  open: boolean;
  handleClose: () => void;
  campaignName: string | undefined;
}

const SaveSequenceTemplate = ({
  sequenceId,
  open,
  handleClose,
  campaignName,
}: SaveSequenceTemplateProps): React.ReactElement => {
  const schema = SequenceValidations.sequenceFormSchema("name");
  const defaultValues = {
    name: `${campaignName} - template` || "",
  };

  const { control, errors, handleSubmit, setError, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    account: { id: accountId },
  } = React.useContext(AccountContext);

  // Create new sequence that we're replacing with
  const mutateCreateTemplate = useMutation(
    (newData: ICreateSequenceFormPost) => SequenceService.copySequence(newData),
    {
      onSuccess: (response: { data: ISequence }) => {
        snackbarHandler.success("Template successfully saved!");
        // Clean up
        reset(defaultValues);
        handleClose();
      },
      onError: (errorMutation: IErrorResponse) => {
        errorHandler(errorMutation.response, setError);
      },
    }
  );

  const onSubmit = (data: { name: string }) => {
    mutateCreateTemplate.mutate({
      name: data.name,
      sequence: sequenceId,
      account: accountId,
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="template-dialog"
      maxWidth="sm"
      PaperProps={{ sx: { width: "100%" } }}
    >
      <form>
        <DialogHeader
          title="Save as new template"
          onHandleClose={handleClose}
        />
        <DialogContent dividers>
          <DialogContentText>
            A copy of these steps will be saved in templates to re-use in your
            future campaigns.
          </DialogContentText>

          <DialogContentText marginBottom="20px !important">
            Further changes made inside this campaign won't update the template
            globally.
          </DialogContentText>

          <ElementField
            InputProps={{
              fullWidth: true,
              id: "name",
              label: "Template name",
              variant: "outlined",
            }}
            name="name"
            control={control}
            error={errors.name}
          />
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            onClick={handleSubmit(onSubmit)}
            color="primary"
            variant="contained"
          >
            Save template
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default SaveSequenceTemplate;
