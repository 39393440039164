import * as React from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { Theme, lighten } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { PlaceholderMenuProps } from "modules/Action/models";
import { cleanCurlyBraces } from "modules/Placeholder/utils";
import PlaceholderButton from "../PlaceholderButton";

const createItem = (id: string) => ({ id, name: id });

const useStyles = makeStyles((theme: Theme) => ({
  item: {
    borderRadius: 0,
  },
  placeholderItem: {
    "&:hover": {
      backgroundColor: lighten(theme.palette.warning.main, 0.75),
    },
  },
  paper: {
    width: 180,
    maxHeight: 400,
  },
  list: {
    overflowY: "scroll",
    height: 400,
    ...theme.app.constants.scrollbar,
  },
}));

function DynamicPlaceholderDialog({
  allPlaceholders,
  handleOnMultiSelect,
}: PlaceholderMenuProps): React.ReactElement {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const isMenuOpen = Boolean(anchorEl);

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <PlaceholderButton
        label="Dynamic"
        handleOnClick={handleMenuOpen}
        disabledButton
      />
      <Menu
        autoFocus={false}
        anchorEl={anchorEl}
        id="placeholder-menu"
        keepMounted
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        open={isMenuOpen}
        onClose={handleMenuClose}
        PaperProps={{
          className: classes.paper,
        }}
        classes={{
          list: classes.list,
        }}
      >
        {allPlaceholders.map((option) => (
          <MenuItem
            className={clsx(classes.item, classes.placeholderItem)}
            onClick={() => {
              const { name } = option;
              const placeholder = cleanCurlyBraces(name);
              handleOnMultiSelect([
                createItem(`{% if ${placeholder} %}`),
                createItem("{% else %}"),
                createItem("{% endif %}"),
              ]);
              handleMenuClose();
            }}
            key={option.name}
            value={option.name}
          >
            {option.id}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default DynamicPlaceholderDialog;
