import React from "react";
import { makeStyles } from "@mui/styles";
import { Theme, lighten } from "@mui/material/styles";
import {
  decorateDynamicPlaceholder,
  decorateStaticPlaceholder,
  decorateLanguagePlaceholder,
} from "modules/Placeholder/utils";
import clsx from "clsx";

enum PlaceholderTypes {
  "dynamic" = "dynamic",
  "static" = "static",
  "language" = "language",
  "weekday" = "weekday",
}

const getPlaceholderType = (text: string) => {
  if (text.includes("NOW")) {
    return PlaceholderTypes.weekday;
  }
  // Language placeholder
  if (text.includes("|") && text.includes("{{")) {
    return PlaceholderTypes.language;
  }
  if (text.includes("{{")) {
    return PlaceholderTypes.static;
  }
  return PlaceholderTypes.dynamic;
};

export const usePlaceholderStyles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.palette.text.primary,
    padding: theme.spacing(0.25, 0.5),
    fontSize: 13.5,
    borderRadius: 3,
  },
  static: {
    backgroundColor: lighten(theme.palette.secondary.light, 0.7),
  },
  dynamic: {
    backgroundColor: lighten(theme.palette.warning.light, 0.7),
  },
  language: {
    backgroundColor: lighten(theme.palette.warning.light, 0.7),
  },
  weekday: {
    backgroundColor: lighten(theme.palette.secondary.light, 0.7),
  },
}));

const decorateFunc = {
  [PlaceholderTypes.static]: decorateStaticPlaceholder,
  [PlaceholderTypes.dynamic]: decorateDynamicPlaceholder,
  [PlaceholderTypes.language]: decorateLanguagePlaceholder,
  [PlaceholderTypes.weekday]: () => "weekday",
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const PlaceholderComponent = ({
  children,
  offsetKey,
  decoratedText,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
any): React.ReactElement => {
  const type = getPlaceholderType(decoratedText);
  const decorated = decorateFunc[type](decoratedText);
  const classes = usePlaceholderStyles();

  return (
    <span
      data-offset-key={offsetKey}
      className={clsx(classes.root, classes[type])}
    >
      {decorated || children}
    </span>
  );
};

export default PlaceholderComponent;
