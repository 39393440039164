import * as Yup from "yup";

export const ERROR_KEYS = {
  ADDRESS_BOOK_ITEM: {
    REQUIRED: "Selecting at least one person is required.",
  },
};

const createAddressBookItemsSchema: Yup.AnyObjectSchema = Yup.object().shape({
  people: Yup.array().min(1, ERROR_KEYS.ADDRESS_BOOK_ITEM.REQUIRED),
});

const AddressBookValidations = {
  createAddressBookItemsSchema,
};

export default AddressBookValidations;
