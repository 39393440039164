import axios, { AxiosResponse } from "axios";
import {
  IAddressBook,
  IAccountAddressBooks,
  IAddressBookFormValues,
  IAddressBookItems,
  IAddressBookItemsFormValues,
  IAddressBookPersonIds,
} from "../models";

const fetchAccountAddressBooks = (
  accountId: number
): Promise<AxiosResponse<IAccountAddressBooks>> => {
  return axios.get(
    `/inevitable/accounts_address_books/?account_id=${accountId}`
  );
};

const createAddressBook = (
  data: IAddressBookFormValues
): Promise<AxiosResponse<IAddressBook>> => {
  return axios.post("/inevitable/address_books/", data);
};

const fetchAddressBookItems = (
  addressBookId: number,
  page: number,
  pageSize: number,
  search = ""
): Promise<AxiosResponse<IAddressBookItems>> => {
  return axios.get(
    `/inevitable/address_book_items/?address_book_id=${addressBookId}&page=${page}&page_size=${pageSize}&search=${search}`
  );
};

const createAddressBookItems = (
  data: IAddressBookItemsFormValues
): Promise<AxiosResponse<IAddressBookItems>> => {
  return axios.post(
    `/inevitable/address_books/${data.address_book}/add_persons/`,
    data
  );
};

const deleteAddressBookItems = (
  data: IAddressBookItemsFormValues
): Promise<AxiosResponse<IAddressBookItems>> => {
  return axios.post(
    `/inevitable/address_books/${data.address_book}/remove_persons/`,
    data
  );
};

const fetchAddressBookPersonIds = (
  accountId: number
): Promise<AxiosResponse<IAddressBookPersonIds>> => {
  return axios.get(
    `inevitable/address_book_items/list_person_ids/?address_book__blacklist=true&address_book__accounts=${accountId}`
  );
};

const AddressBookService = {
  fetchAccountAddressBooks,
  createAddressBook,
  fetchAddressBookItems,
  createAddressBookItems,
  deleteAddressBookItems,
  fetchAddressBookPersonIds,
};

export default AddressBookService;
