import { yupResolver } from "@hookform/resolvers/yup";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import RouterConstants from "core/routes/constants";
import { errorHandler, IErrorResponse } from "core/utils/errorHandler";
import { snackbarHandler } from "core/utils/snackbarHandler";
import get from "lodash/get";
import { AccountContext } from "modules/Account/context";
import { handleUserAccountCheck } from "modules/Account/utils";
import AuthForm from "modules/User/components/AuthForm";
import GoogleSignIn from "modules/User/components/GoogleSignIn";
import { UserContext } from "modules/User/context";
import { ISignInInput } from "modules/User/models";
import { UserActionType } from "modules/User/reducers";
import UserService from "modules/User/services";
import UserValidation from "modules/User/validations";
import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { Link, useHistory } from "react-router-dom";
import TextField from "ui-kit/atoms/TextField";
import DividerText from "ui-kit/components/DividerText";
import PasswordField from "ui-kit/components/PasswordField";

const SignIn = (): React.ReactElement => {
  const { dispatch: userDispatch } = useContext(UserContext);
  const { dispatch: accountDispatch } = useContext(AccountContext);

  const [notVerified, setNotVerified] = useState(false);

  const history = useHistory();
  const schema = UserValidation.signInSchema();

  const { register, errors, handleSubmit, setError, watch } =
    useForm<ISignInInput>({
      resolver: yupResolver(schema),
    });

  const email = watch("email");

  const mutateResendVerification = useMutation(
    () => UserService.resendVerification({ email }),
    {
      onSuccess: (response) => {
        history.push(`${RouterConstants.USER.REGISTER_EMAIL}?email=${email}`);
        snackbarHandler.success("Verification email successfully resent");
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);
      },
    }
  );

  const handleResendVerification = () => {
    mutateResendVerification.mutate();
  };

  const onSubmit = (credentials: ISignInInput) => {
    UserService.signIn(credentials)
      .then((response) => {
        userDispatch({
          type: UserActionType.SET_USER_TOKEN,
          payload: response.data,
        });
        handleUserAccountCheck(
          get(response, "data.profile.username"),
          accountDispatch
        );
        history.push(RouterConstants.ROOT);
      })
      .catch((error) => {
        // Check if user email address is not verified and let them know + btn to resent
        const errorCode = get(error, "response.data.code");
        if (errorCode === "not-activated") {
          setNotVerified(true);
          return;
        }

        errorHandler(error.response, setError);
      });
  };

  const inputProps = {
    inputRef: register,
    fullWidth: true,
    InputProps: {
      style: {
        backgroundColor: "white",
      },
    },
  };

  return (
    <AuthForm
      title="Sign in to your account."
      // subtitle="Welcome back!"
      onHandleSubmit={handleSubmit(onSubmit)}
      submitBtn="Sign In"
      action={
        <Link to={RouterConstants.USER.FORGOT_PASSWORD}>
          Forgot your password?
        </Link>
      }
      helper={
        <>
          Not an user?{" "}
          <Link to={RouterConstants.USER.SIGN_UP}>Create an account.</Link>
          {!!notVerified && (
            <Box mt={3} mb={-2}>
              <Alert variant="standard" severity="error">
                <AlertTitle>Your email address isn't verified.</AlertTitle>
                Please check your email and verify or{" "}
                <Typography
                  variant="body2"
                  component="span"
                  color="link"
                  onClick={handleResendVerification}
                  sx={{ cursor: "pointer" }}
                >
                  Resend verification email.
                </Typography>
              </Alert>
            </Box>
          )}
        </>
      }
    >
      <GoogleSignIn>Sign in with Google</GoogleSignIn>
      <DividerText text="OR" />

      <TextField
        id="email"
        name="email"
        label="Email address"
        error={!!errors.email}
        helperText={errors.email?.message}
        {...inputProps}
      />
      <PasswordField
        id="password"
        label="Password"
        name="password"
        error={!!errors.password}
        helperText={errors.password?.message}
        {...inputProps}
      />
    </AuthForm>
  );
};

export default SignIn;
