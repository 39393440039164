import React, { useState } from "react";
import { useQuery } from "react-query";
import get from "lodash/get";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import NavigateBefore from "@mui/icons-material/NavigateBefore";
import NavigateNext from "@mui/icons-material/NavigateNext";
import { snackbarHandler } from "core/utils/snackbarHandler";
import ConfigConstant from "core/constants/ConfigConstant";
import CampaignService from "modules/Campaign/services";
import { ActionContext } from "modules/Action/context";
import { DraftActionActionType } from "modules/Action/reducers";
import { getPersonName } from "modules/Person/utils";
import { IInteraction } from "modules/Interaction/models";
import SearchUtils from "modules/Search/utils";

const useStyles = makeStyles((theme: Theme) => ({
  name: {
    color: theme.app.palette.action.placeholder,
    padding: theme.spacing(0, 1),
  },
}));

const PreviewPerson = (): React.ReactElement | null => {
  const classes = useStyles();

  const {
    dispatch,
    draftAction: {
      previewMode,
      campaignId,
      personId,
      placeholders,
      previewContactName,
    },
  } = React.useContext(ActionContext);
  const [page, setPage] = useState<number>(ConfigConstant.INITIAL_PAGE);

  const fetchCampaignInteractions = async (p: number) => {
    try {
      const { data } = await CampaignService.fetchAllByOneCampaignInteractions(
        campaignId,
        p
      );
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };

  const { data } = useQuery(
    ["interactions", "campaign", campaignId, page],
    () => fetchCampaignInteractions(page),
    {
      keepPreviousData: true,
      enabled: previewMode && !!campaignId,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const handleDisablePreview = () => {
    dispatch({
      type: DraftActionActionType.SET_DRAFT_ACTION,
      payload: {
        previewMode: false,
        placeholders: undefined,
        personId: undefined,
        previewContactName: undefined,
      },
    });
  };

  const handleNextPageClick = () => {
    if (data?.next) {
      setPage(page + 1);
    }
  };

  const handlePreviousPageClick = () => {
    if (data?.previous) {
      setPage(page - 1);
    }
  };

  React.useEffect(() => {
    if (previewMode && data?.results) {
      const interaction: IInteraction = get(data, `results[0]`);

      if (!interaction) {
        snackbarHandler.warning(
          "There is no prospect in the campaign to be previewed."
        );
        handleDisablePreview();
        return;
      }

      const newPerson = interaction.person;
      const searchResult = interaction.search_result;

      // If there is no person yet, but search result is generated
      // Use the placeholders on search result
      if (!newPerson && searchResult) {
        const name = SearchUtils.getSearchResultName(searchResult);
        dispatch({
          type: DraftActionActionType.SET_DRAFT_ACTION,
          payload: {
            placeholders: searchResult.placeholders,
            previewContactName: name,
          },
        });
      }

      if (newPerson && personId !== newPerson.id) {
        const name = getPersonName(newPerson);
        dispatch({
          type: DraftActionActionType.SET_DRAFT_ACTION,
          payload: { personId: newPerson.id, previewContactName: name },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.results, previewMode]);

  // Clean up - close preview person when the component is unmounted
  React.useEffect(() => {
    return () => {
      handleDisablePreview();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const enabledPreview = previewMode && !!(personId || placeholders);

  return enabledPreview ? (
    <>
      {previewContactName ? (
        <>
          <IconButton
            size="small"
            disabled={!data?.previous}
            onClick={handlePreviousPageClick}
          >
            <NavigateBefore />
          </IconButton>
          <Typography
            className={classes.name}
            variant="caption"
            component="span"
          >
            {previewContactName}
          </Typography>
          <IconButton
            size="small"
            disabled={!data?.next}
            onClick={handleNextPageClick}
          >
            <NavigateNext />
          </IconButton>
        </>
      ) : (
        <Skeleton width={50} height={20} />
      )}
    </>
  ) : null;
};

export default PreviewPerson;
