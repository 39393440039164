import React from "react";
import {
  getPlaceholderContent,
  getPlaceholderContentNoPerson,
} from "modules/Placeholder/utils";
import { DefaultPlaceholdersKeys } from "modules/Placeholder/models";
import { IPerson } from "modules/Person/models";
import { ISearchResult } from "modules/Search/models";
import TableBodyCellPlaceholder from "ui-kit/components/TableBodyCellPlaceholder";

interface InteractionPlaceholderCellProps {
  person?: IPerson | null;
  searchResult?: ISearchResult;
  placeholderKey: DefaultPlaceholdersKeys;
}

const InteractionPlaceholderCell = ({
  person,
  searchResult,
  placeholderKey,
}: InteractionPlaceholderCellProps): React.ReactElement => {
  let content = "";

  if (!person) {
    content = getPlaceholderContentNoPerson(
      searchResult?.placeholders,
      placeholderKey
    );
  }

  if (person) {
    content = getPlaceholderContent(
      person.placeholders,
      person.default_placeholders,
      searchResult?.placeholders,
      placeholderKey
    );
  }

  return <>{content || <TableBodyCellPlaceholder />}</>;
};

export default InteractionPlaceholderCell;
