import {
  createTheme as createMuiTheme,
  responsiveFontSizes,
  ThemeOptions,
} from "@mui/material/styles";
import { Shadows } from "@mui/material/styles/shadows";
import deepmerge from "deepmerge";
import constants from "./constants";
import typography from "./typography";
import createOverrides from "./createOverrides";
import createPalette from "./createPalette";

// https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/styles/shadows.js
// inlined and altered it as material-ui, by doesn't support colored shadows by design
const shadowKeyUmbraOpacity = 0.05; // 0.2
const shadowKeyPenumbraOpacity = 0.05; // 0.14
const shadowAmbientShadowOpacity = 0.05; // 0.12

function createShadow(...px: number[]) {
  return [
    `${px[0]}px ${px[1]}px ${px[2]}px ${px[3]}px rgba(3, 36, 92, ${shadowKeyUmbraOpacity})`,
    `${px[4]}px ${px[5]}px ${px[6]}px ${px[7]}px rgba(3, 36, 92, ${shadowKeyPenumbraOpacity})`,
    `${px[8]}px ${px[9]}px ${px[10]}px ${px[11]}px rgba(3, 36, 92, ${shadowAmbientShadowOpacity})`,
  ].join(",");
}

const shadows: Shadows = [
  "none",
  createShadow(0, 2, 1, -1, 0, 1, 1, 0, 0, 1, 3, 0),
  createShadow(0, 3, 1, -2, 0, 2, 2, 0, 0, 1, 5, 0),
  createShadow(0, 3, 3, -2, 0, 3, 4, 0, 0, 1, 8, 0),
  createShadow(0, 2, 4, -1, 0, 4, 5, 0, 0, 1, 10, 0),
  createShadow(0, 3, 5, -1, 0, 5, 8, 0, 0, 1, 14, 0),
  createShadow(0, 3, 5, -1, 0, 6, 10, 0, 0, 1, 18, 0),
  createShadow(0, 4, 5, -2, 0, 7, 10, 1, 0, 2, 16, 1),
  createShadow(0, 5, 5, -3, 0, 8, 10, 1, 0, 3, 14, 2),
  createShadow(0, 5, 6, -3, 0, 9, 12, 1, 0, 3, 16, 2),
  createShadow(0, 6, 6, -3, 0, 10, 14, 1, 0, 4, 18, 3),
  createShadow(0, 6, 7, -4, 0, 11, 15, 1, 0, 4, 20, 3),
  createShadow(0, 7, 8, -4, 0, 12, 17, 2, 0, 5, 22, 4),
  createShadow(0, 7, 8, -4, 0, 13, 19, 2, 0, 5, 24, 4),
  createShadow(0, 7, 9, -4, 0, 14, 21, 2, 0, 5, 26, 4),
  createShadow(0, 8, 9, -5, 0, 15, 22, 2, 0, 6, 28, 5),
  createShadow(0, 8, 10, -5, 0, 16, 24, 2, 0, 6, 30, 5),
  createShadow(0, 8, 11, -5, 0, 17, 26, 2, 0, 6, 32, 5),
  createShadow(0, 9, 11, -5, 0, 18, 28, 2, 0, 7, 34, 6),
  createShadow(0, 9, 12, -6, 0, 19, 29, 2, 0, 7, 36, 6),
  createShadow(0, 10, 13, -6, 0, 20, 31, 3, 0, 8, 38, 7),
  createShadow(0, 10, 13, -6, 0, 21, 33, 3, 0, 8, 40, 7),
  createShadow(0, 10, 14, -6, 0, 22, 35, 3, 0, 8, 42, 7),
  createShadow(0, 11, 14, -7, 0, 23, 36, 3, 0, 9, 44, 8),
  createShadow(0, 11, 15, -7, 0, 24, 38, 3, 0, 9, 46, 8),
];

export default function createTheme(options: {
  palette: ReturnType<typeof createPalette>;
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}): ReturnType<any> {
  const { palette: customPalette } = options;

  const palette = {
    primary: {
      dark: "#142adc",
      main: "#4154ed",
      light: "#f1f3fd",
    },
    secondary: {
      dark: "#4d85ff",
      main: "#5b8fff",
      light: "#6897ff",
    },
    info: {
      dark: "#ba68c8",
      main: "#9c27b0",
      light: "#7b1fa2",
    },
    text: {
      primary: "#0B1639",
      secondary: "#1A1F36",
      textSecondary: customPalette.text.secondary,
    },
  };

  const mergedPalette = deepmerge(palette, customPalette);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const theme: ThemeOptions & { app: any } = {
    // custom typography
    typography,
    components: createOverrides({
      palette: mergedPalette,
      constants,
    }),
    spacing: 4,
    shadows,
    // custom palette
    palette: mergedPalette,
    app: {
      palette: customPalette,
      constants,
    },
  };

  const muiTheme = createMuiTheme(theme);
  return responsiveFontSizes(muiTheme);
}

declare module "@mui/material/styles/createTheme" {
  interface Theme {
    app: {
      palette: ReturnType<typeof createPalette>;
      constants: typeof constants;
      shadows: {
        outer: {
          main: string[];
          header: string;
        };
        inner: {
          main: string[];
          accent: string[];
        };
      };
    };
  }
}
