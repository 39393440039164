import React from "react";
import { styled } from "@mui/material/styles";
import MuiSlider, { SliderMarkLabel, SliderProps } from "@mui/material/Slider";
import { get } from "lodash";

interface MarkLabelProps {
  children: number;
  "data-index": number;
  ownerState: {
    min: number;
    max: number;
    value: number;
  };
}

const getHideMark = (props: MarkLabelProps): boolean => {
  const value = get(props, "ownerState.value");
  if (value) {
    const { min, max } = props.ownerState;
    if (max - min < 10) {
      return false;
    }
    const mark = props.children;
    return Math.abs(value - mark) < 7;
  }
  return false;
};

const StyledMarkLabel = styled(SliderMarkLabel)<MarkLabelProps>((props) => {
  return {
    opacity: getHideMark(props) ? 0 : 1,
  };
});

function SingleSlider(props: SliderProps): React.ReactElement {
  return (
    <MuiSlider
      {...props}
      valueLabelDisplay="on"
      components={{
        MarkLabel: StyledMarkLabel,
      }}
      aria-labelledby="single-slider"
    />
  );
}

export default SingleSlider;
