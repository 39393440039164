import { IActionSelectorOption } from "../models";

/* eslint-disable @typescript-eslint/no-explicit-any */
function createComponents(
  name: React.FunctionComponent<any>,
  key: string,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  props?: any
): any {
  return { name, key, props };
}

function createActionSelectorOptions(
  id: IActionSelectorOption["id"],
  primary: IActionSelectorOption["primary"],
  secondary: IActionSelectorOption["secondary"]
): IActionSelectorOption {
  return { id, primary, secondary };
}

export { createComponents, createActionSelectorOptions };
