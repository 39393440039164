import { IReportContext } from "../models";
import { storeReport } from "../utils";

export enum ReportActionType {
  SET_REPORT,
}

export type ReportAction = {
  type: ReportActionType.SET_REPORT;
  payload: IReportContext;
};

export const reportReducer = (
  state: IReportContext,
  action: ReportAction
): IReportContext => {
  switch (action.type) {
    case ReportActionType.SET_REPORT: {
      // Store report data to local-storage to retrieve next time
      // storeReport();

      storeReport(action.payload.defaultRange);

      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
};
