import ConfigConstant from "core/constants/ConfigConstant";
import RouterConstants from "core/routes/constants";
import { formatDateTimeToFull } from "core/utils/dateHandler";
import { errorHandler, IErrorResponse } from "core/utils/errorHandler";
import { snackbarHandler } from "core/utils/snackbarHandler";
import { createTableCell } from "core/utils/tableHandler";
import get from "lodash/get";
import { AccountContext } from "modules/Account/context";
import ActionList from "modules/Action/components/ActionList";
import {
  CampaignDetailTabs,
  CampaignQuery,
  CampaignSequenceVariant,
  CreateCampaignSteps,
  ICampaignParams,
} from "modules/Campaign/models";
import CampaignService from "modules/Campaign/services";
import CreateSequenceCopy from "modules/Sequence/components/CreateSequenceCopy";
import SaveSequenceTemplate from "modules/Sequence/components/SaveSequenceTemplate";
import SequenceConstants from "modules/Sequence/constants";
import { ICreateSequenceNew } from "modules/Sequence/models";
import SequenceService from "modules/Sequence/services";
import React, { useContext, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import AlphabetBadge from "ui-kit/components/AlphabetBadge";
import Loader from "ui-kit/components/Loader";
import Table, { TableVariant } from "ui-kit/components/Table";
import TableBodyCell from "ui-kit/components/TableBodyCell";
import CampaignSequenceAcceptanceRate from "../CampaignSequenceAcceptanceRate";
import CampaignSequenceHeader from "../CampaignSequenceHeader";
import CampaignSequenceProspectRate from "../CampaignSequenceProspectRate";
import CampaignSequenceResponseRate from "../CampaignSequenceResponseRate";

const heads = [
  {
    id: "variant",
    percentage: true,
    width: 25,
    label: "Variant",
  },
  {
    id: "distribution",
    percentage: true,
    width: 22,
    label: "Distribution",
  },
  {
    id: "accept-rate",
    percentage: true,
    width: 18,
    label: "Accepted",
  },
  {
    id: "reply-rate",
    percentage: true,
    width: 18,
    label: "Replied",
  },
  {
    id: "added",
    percentage: true,
    width: 17,
    label: "Added at",
  },
];

type ParamTypes = {
  sequenceId: string;
};

interface CampaignSequenceViewProps extends ICampaignParams {
  variant: CampaignSequenceVariant;
}

const CampaignSequenceView = ({
  campaignId,
  campaignName,
  isMissingAction,
  variant,
}: CampaignSequenceViewProps): React.ReactElement | null => {
  const [page, setPage] = useState<number>(ConfigConstant.INITIAL_PAGE);
  const [openCopyTemplate, toggleOpenCopyTemplate] = React.useState(false);
  const [openSaveTemplate, toggleOpenSaveTemplate] = React.useState(false);

  const params = useParams<ParamTypes>();
  const [sequenceId, setSequenceId] = React.useState<number | undefined>(
    !!params.sequenceId ? +params.sequenceId : undefined
  );

  const queryClient = useQueryClient();
  const history = useHistory();

  const {
    account: { id: accountId },
  } = useContext(AccountContext);

  const fetchSequences = async (p: number) => {
    try {
      const { data } = await CampaignService.fetchCampaignSequences(
        campaignId,
        p
      );
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };
  const { data, error, isLoading, isFetching } = useQuery(
    [CampaignQuery.sequences, campaignId, page],
    () => fetchSequences(page),
    {
      enabled: !!campaignId,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  // Update sequenceId if switching between sequences
  React.useEffect(() => {
    const newSequenceId = params.sequenceId;
    if (sequenceId !== Number(newSequenceId)) {
      const newId = newSequenceId ? Number(newSequenceId) : undefined;
      setSequenceId(newId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.sequenceId]);

  // If single sequence, make it selected
  React.useEffect(() => {
    if (!sequenceId && !!data && data.count === 1) {
      const newSequenceId = get(data, "results.0.sequence.id");
      if (!!newSequenceId) {
        setSequenceId(newSequenceId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleCleanSequenceId = () => {
    setSequenceId(undefined);
  };

  // Refresh the last selected
  React.useEffect(() => {
    return () => {
      handleCleanSequenceId();
    };
  }, []);

  const mutateNewSequence = useMutation(
    (newData: ICreateSequenceNew) =>
      SequenceService.createBlankSequence(newData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["sequences-all"]);
        queryClient.invalidateQueries([CampaignQuery.sequences, campaignId]);

        // Notification that new variant created
        if (data?.count && data.count > 0) {
          snackbarHandler.success(
            `${
              SequenceConstants.ALPHABET[data.count].label
            } variant successfully created.`
          );
        }

        let link;
        if (variant === CampaignSequenceVariant.draft) {
          link = RouterConstants.CAMPAIGN.newTab(
            campaignId,
            CreateCampaignSteps.steps
          );
        } else {
          link = RouterConstants.CAMPAIGN.detail(
            campaignId,
            CampaignDetailTabs.sequences
          );
        }

        handleCleanSequenceId();
        history.push(link);
      },
      onError: (errorMutation: IErrorResponse) => {
        errorHandler(errorMutation.response);
      },
    }
  );

  const handleCreateNewSequence = () => {
    mutateNewSequence.mutate({
      account: accountId,
      campaign: campaignId,
      name: campaignName || "My sequence", // Shouldn't be a case that campaignName doesn't exist, just ts
    });
  };

  const handleCopyTemplateToggle = () => {
    toggleOpenCopyTemplate(!openCopyTemplate);
  };

  const handleSaveTemplateToggle = () => {
    toggleOpenSaveTemplate(!openSaveTemplate);
  };

  React.useEffect(() => {
    if (!!data && !data.count) {
      // No sequence exists, create one
      handleCreateNewSequence();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // Render if still loading
  if (isLoading) {
    return <Loader minHeight={300} />;
  }

  // Render if no data loaded
  if (!data || error) {
    return null;
  }

  return (
    <>
      <CampaignSequenceHeader
        campaignId={campaignId}
        sequenceId={sequenceId}
        count={data.count}
        handleCopyTemplateToggle={handleCopyTemplateToggle}
        handleSaveTemplateToggle={handleSaveTemplateToggle}
        handleCreateNewSequence={handleCreateNewSequence}
        selectedVariant={data.results.findIndex(
          (item) => item.sequence.id === sequenceId
        )}
        variant={variant}
        isMissingAction={isMissingAction}
      />

      {!!sequenceId && (
        <ActionList sequenceId={sequenceId} campaignId={campaignId} />
      )}

      {!sequenceId && !!data && data.count === 1 && (
        <ActionList
          sequenceId={get(data, "results.0.sequence.id")}
          campaignId={campaignId}
        />
      )}

      {!sequenceId && !!data && data.count > 1 && (
        <Table
          heads={heads}
          rows={
            data?.results
              ? data.results.map(({ sequence, created }, index) => {
                  const getLink = () => {
                    if (variant === CampaignSequenceVariant.draft) {
                      return RouterConstants.CAMPAIGN.newSequence(
                        campaignId,
                        CreateCampaignSteps.steps,
                        sequence.id
                      );
                    } else {
                      return RouterConstants.CAMPAIGN.detailSequence(
                        campaignId,
                        CampaignDetailTabs.sequences,
                        sequence.id
                      );
                    }
                  };

                  const link = getLink();

                  return {
                    name: sequence.id.toString(),
                    data: [
                      createTableCell(
                        <AlphabetBadge index={index} size={22} />,
                        link,
                        "main",
                        TableBodyCell
                      ),
                      createTableCell(
                        <CampaignSequenceProspectRate
                          campaignId={campaignId}
                          sequenceId={sequence.id}
                        />,
                        link,
                        "element",
                        TableBodyCell
                      ),
                      createTableCell(
                        <CampaignSequenceAcceptanceRate
                          campaignId={campaignId}
                          sequenceId={sequence.id}
                        />,
                        link,
                        "element",
                        TableBodyCell
                      ),
                      createTableCell(
                        <CampaignSequenceResponseRate
                          campaignId={campaignId}
                          sequenceId={sequence.id}
                        />,
                        link,
                        "element",
                        TableBodyCell
                      ),
                      createTableCell(
                        formatDateTimeToFull(created),
                        link,
                        "default",
                        TableBodyCell
                      ),
                    ],
                  };
                })
              : []
          }
          count={data?.count || 0}
          // INITIAL_PAGE starts at 1, but Pagination starts at 0
          page={(data?.current || page) - 1}
          setPage={setPage}
          isFetching={isFetching}
          variant={[TableVariant.checkable]}
        />
      )}

      <CreateSequenceCopy
        sequenceId={sequenceId}
        open={openCopyTemplate}
        handleClose={handleCopyTemplateToggle}
        campaignId={campaignId}
      />

      <SaveSequenceTemplate
        sequenceId={sequenceId}
        open={openSaveTemplate}
        handleClose={handleSaveTemplateToggle}
        campaignName={campaignName}
      />
    </>
  );
};

export default CampaignSequenceView;
