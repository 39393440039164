import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { IconButton } from "@mui/material";
import Close from "@mui/icons-material/Close";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { formatDateTimeToFull } from "core/utils/dateHandler";
import { errorHandler, IErrorResponse } from "core/utils/errorHandler";
import { snackbarHandler } from "core/utils/snackbarHandler";
import NoteService from "modules/Note/services";
import { INote } from "modules/Note/models";

const useStyles = makeStyles((theme: Theme) => {
  return {
    close: {
      position: "absolute",
      top: 3,
      right: 3,
    },
    icon: {
      fontSize: 18,
      color: theme.app.palette.action.placeholder,
    },
    note: {
      position: "relative",
      padding: theme.spacing(2, 4),
      marginBottom: theme.spacing(2.5),
      borderRadius: 4,
      "& .close-icon": {
        visibility: "hidden",
      },
      "&:hover > .close-icon": {
        visibility: "visible",
      },
    },
    content: {
      whiteSpace: "pre-wrap",
      fontSize: "0.8rem",
    },
    date: {
      color: theme.app.palette.action.color,
      fontSize: "0.75rem",
    },
  };
});

interface ContactNotesListItemProps {
  contactId: number;
  note: INote;
}

const ContactNotesListItem = ({
  contactId,
  note,
}: ContactNotesListItemProps): React.ReactElement => {
  const classes = useStyles();
  const queryClient = useQueryClient();

  const mutateOnDelete = useMutation(
    (id: number) => NoteService.deleteNote(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["notes", contactId]);
        snackbarHandler.success("Note removed!");
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);
      },
    }
  );

  const onDelete = () => {
    mutateOnDelete.mutate(note.id);
  };

  return (
    <Paper variant="outlined" key={note.id} className={classes.note}>
      <div className="close-icon">
        <IconButton size="small" className={classes.close} onClick={onDelete}>
          <Close className={classes.icon} />
        </IconButton>
      </div>

      <Typography variant="body2" gutterBottom className={classes.content}>
        {note.content}
      </Typography>
      <Typography variant="caption" className={classes.date}>
        {formatDateTimeToFull(note.created)}
      </Typography>
    </Paper>
  );
};

export default ContactNotesListItem;
