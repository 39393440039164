import React from "react";
import { ReportNames } from "modules/Report/models";
import InteractionService from "modules/Interaction/services";
import { createCountReport } from "modules/Report/utils";
import CampaignCompareItem from "modules/Interaction/components/InteractionCompareItem";

interface CampaignAcceptanceRateProps {
  campaignId: number;
  sequenceId: Number;
}

const CampaignAcceptanceRate = ({
  campaignId,
  sequenceId,
}: CampaignAcceptanceRateProps): React.ReactElement => (
  <CampaignCompareItem
    query={`&campaign_id=${campaignId}&sequence_id=${sequenceId}`}
    items={[
      createCountReport(
        ReportNames.invitation,
        "Invitations sent",
        InteractionService.fetchRequestCount
      ),
      createCountReport(
        ReportNames.connection,
        "Connections accepted",
        InteractionService.fetchConnectedCount
      ),
    ]}
  />
);

export default CampaignAcceptanceRate;
