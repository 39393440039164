import StopIcon from "@mui/icons-material/Stop";
import Avatar from "@mui/material/Avatar";
import ListItemText from "@mui/material/ListItemText";
import { Theme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import StrategyConstant from "core/constants/StrategyConstant";
import { formatDelta } from "core/utils/dateHandler";
import { ActionMatchStrategyToVariant } from "modules/Action/models";
import ActionService from "modules/Action/services";
import { ICampaignSequence } from "modules/Campaign/models";
import InteractionService from "modules/Interaction/services";
import React from "react";
import { useQuery } from "react-query";
import { Chip } from "ui-kit/atoms/Chip";
import AlphabetBadge from "ui-kit/components/AlphabetBadge";
import Loader from "ui-kit/components/Loader";
import CampaignReportSequenceCompare from "../CampaignReportSequenceCompare";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(8, 0),
  },
  title: {
    marginBottom: theme.spacing(-7),
  },
  cell: {
    padding: theme.spacing(2, 4),
    position: "relative",
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    width: 25,
    height: 25,
    marginRight: theme.spacing(3),
  },
  avatarStop: {
    backgroundColor: theme.palette.error.main,
  },
  icon: {
    fontSize: 15,
  },
  delta: {
    position: "absolute",
    bottom: -10.5,
    left: "45%",
  },
  lastRow: {
    "* > div": {
      padding: theme.spacing(4, 4),
    },
  },
  lastCell: {
    padding: theme.spacing(2, 0),
  },
  stopRow: {
    display: "flex",
    alignItems: "center",
  },
  loader: {
    display: "flex",
    alignItems: "center",
    minHeight: 100,
    width: "100%",
  },
}));

interface CampaignSequenceReportProps {
  campaignId: number;
  campaignSequence: ICampaignSequence;
  index: number;
}

const heads = [
  {
    id: "step",
    width: 46,
    label: "",
  },
  {
    id: "sent",
    width: 18,
    label: "Sent",
  },
  {
    id: "replied",
    width: 18,
    label: "Replied",
  },
  {
    id: "accepted",
    width: 18,
    label: "Accepted",
  },
];

const CampaignSequenceReport = ({
  campaignId,
  campaignSequence,
  index,
}: CampaignSequenceReportProps): React.ReactElement => {
  const classes = useStyles();
  const { sequence } = campaignSequence;

  const fetchActions = async () => {
    try {
      const { data } = await ActionService.fetchActions(sequence.id);
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };
  const { data: dataActions, isLoading } = useQuery(
    ["actions", sequence.id],
    () => fetchActions(),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  const fetchStoppedCount = async () => {
    try {
      const { data } = await InteractionService.fetchStoppedCount(
        campaignId,
        sequence.id
      );
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };
  const { data: dataStopped } = useQuery(
    ["interactions-stopped", campaignId, sequence.id],
    () => fetchStoppedCount(),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <AlphabetBadge index={index} size={22} />
      </div>

      <TableContainer>
        <Table sx={{ minWidth: 700 }}>
          <TableHead>
            <TableRow>
              {heads.map((item) => (
                <TableCell
                  sx={{ width: `${item.width}%` }}
                  key={item.id}
                  variant="head"
                >
                  {item.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataActions?.results?.map((action) => {
              const currentVariant =
                ActionMatchStrategyToVariant[action.execution_strategy];
              const currentAction =
                StrategyConstant.STRATEGY_OBJ[currentVariant];
              const IconComponent = currentAction.icon;
              return (
                <TableRow key={action.id}>
                  <TableCell
                    className={classes.cell}
                    component="th"
                    scope="row"
                  >
                    <Avatar
                      sx={{ backgroundColor: currentAction.color }}
                      className={classes.avatar}
                      aria-label="strategy-icon"
                    >
                      {IconComponent && (
                        <IconComponent className={classes.icon} />
                      )}
                    </Avatar>
                    <ListItemText
                      primary={`Step ${action.order_nr}`}
                      primaryTypographyProps={{
                        variant: "subtitle2",
                      }}
                      secondary={currentAction?.label?.account_is_actor}
                    />
                    <div className={classes.delta}>
                      <Chip
                        size="small"
                        variant="outlined"
                        color="secondary"
                        label={`+ ${formatDelta(action.time_delta)}`}
                      />
                    </div>
                  </TableCell>
                  <CampaignReportSequenceCompare
                    campaignId={campaignId}
                    sequenceId={sequence.id}
                    actionId={action.id}
                    actionStrategy={action.execution_strategy}
                  />
                </TableRow>
              );
            })}
            {dataActions && dataActions.count > 0 && (
              <TableRow className={classes.lastRow}>
                <TableCell>
                  <Typography
                    variant="body2"
                    className={clsx(classes.lastCell, classes.stopRow)}
                  >
                    <Avatar
                      className={clsx(classes.avatar, classes.avatarStop)}
                    >
                      <StopIcon className={classes.icon} />
                    </Avatar>{" "}
                    <ListItemText
                      primary="Last step"
                      primaryTypographyProps={{
                        variant: "subtitle2",
                      }}
                      secondary="Stop sequence"
                    />
                  </Typography>
                </TableCell>
                <TableCell />
                <TableCell>Finished:</TableCell>
                <TableCell>{dataStopped?.count}</TableCell>
              </TableRow>
            )}
            {dataActions && dataActions.count === 0 && (
              <TableRow>
                <TableCell>
                  <Typography variant="body2" className={classes.lastCell}>
                    Sequence has no actions.
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {isLoading && (
          <div className={classes.loader}>
            <Loader />
          </div>
        )}
      </TableContainer>
    </div>
  );
};

export default CampaignSequenceReport;
