import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import isEmpty from "lodash/isEmpty";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import {
  IAddressBookItemFormInput,
  IAddressBookItemFormDefault,
} from "modules/AddressBook/models";
import AddressBookValidation from "modules/AddressBook/validations";
import PeopleAutocomplete from "modules/Person/components/PeopleAutocomplete";
import Button from "ui-kit/atoms/Button";
import DialogHeader from "ui-kit/components/DialogHeader";
import HelperTooltip from "ui-kit/components/HelperTooltip";

interface NewAddressBookItemsDialogProps {
  open: boolean;
  handleClose: () => void;
  handleOnSubmit: (data: IAddressBookItemFormInput) => void;
}

export const ADDRESS_BOOK_DEFAULT_VALUES = { people: undefined };

const NewAddressBookItemsDialog = ({
  open,
  handleClose,
  handleOnSubmit,
}: NewAddressBookItemsDialogProps): React.ReactElement => {
  const schema = AddressBookValidation.createAddressBookItemsSchema;
  const { control, errors, handleSubmit, getValues, reset } =
    useForm<IAddressBookItemFormDefault>({
      resolver: yupResolver(schema),
      defaultValues: ADDRESS_BOOK_DEFAULT_VALUES,
    });

  const onSubmit = (data: IAddressBookItemFormInput) => {
    handleOnSubmit(data);
  };

  React.useEffect(() => {
    if (!open && !isEmpty(getValues())) {
      reset(ADDRESS_BOOK_DEFAULT_VALUES);
    }
  }, [open, getValues, reset]);

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      PaperProps={{ sx: { width: "100%" } }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogHeader
          title="Add people to the backlist"
          onHandleClose={handleClose}
        />
        <DialogContent dividers>
          <DialogContentText sx={{ display: "flex", alignItems: "center" }}>
            Start typing a name to see suggestions and add people to the
            blacklist.
            <HelperTooltip title="You can only add people already in your network. Soon, you'll be able to block people by keywords." />
          </DialogContentText>
          <Box marginTop={4.5}>
            <PeopleAutocomplete control={control} errors={errors} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button type="submit" variant="contained" color="primary">
            Add people
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default NewAddressBookItemsDialog;
