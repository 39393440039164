import axios, { AxiosResponse } from "axios";
import { ITeamInvite, ITeamInvites, ITeamInvitesFormValues } from "../models";

const fetchTeamInvites = (
  page: number,
  pageSize: number
): Promise<AxiosResponse<ITeamInvites>> => {
  return axios.get(`/inevitable/members/?page=${page}&page_size=${pageSize}`);
};

const fetchAccountTeamInvites = (
  accountId: number
): Promise<AxiosResponse<ITeamInvites>> => {
  return axios.get(`/inevitable/members/?account_id=${accountId}`);
};

const fetchAccountInvitesFromNotMyAccount = (
  accountId: number,
  userId: number
): Promise<AxiosResponse<ITeamInvites>> => {
  return axios.get(
    `/inevitable/members/?account_id=${accountId}&inviter_id__not=${userId}`
  );
};

const createTeamInvites = (
  data: ITeamInvitesFormValues
): Promise<AxiosResponse<any>> => {
  return axios.post("/inevitable/members/bulk_create/", data);
};

const deleteTeamInvite = (id: number): Promise<AxiosResponse<ITeamInvite>> => {
  return axios.delete(`/inevitable/members/${id}/`);
};

const TeamService = {
  fetchTeamInvites,
  fetchAccountTeamInvites,
  fetchAccountInvitesFromNotMyAccount,
  createTeamInvites,
  deleteTeamInvite,
};

export default TeamService;
