import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import DialogContentText from "@mui/material/DialogContentText";
import { SearchDefaultFormProps } from "modules/Search/models";
import SearchSingleAutocomplete from "modules/Search/components/SearchSingleAutocomplete";

interface SearchExistingFormProps extends SearchDefaultFormProps {}

const SearchExistingForm = ({
  title,
  subtitle,
  control,
  errors,
}: SearchExistingFormProps): React.ReactElement => {
  return (
    <>
      <Typography variant="subtitle1" gutterBottom>
        {title}
      </Typography>
      <Box mb={4}>
        <DialogContentText>{subtitle}</DialogContentText>
      </Box>
      <SearchSingleAutocomplete control={control} errors={errors} />
    </>
  );
};

export default SearchExistingForm;
