/* eslint-disable @typescript-eslint/naming-convention */
import React from "react";
import { useQueryClient } from "react-query";
import {
  AccountQuery,
  IAccount,
  IAccountDetailItemProps,
} from "modules/Account/models";
import CompanySettings from "../CompanySettings";
import AccountCredentialsSettings from "./components/AccountCredentialsSettings";

const ProfileSettings = ({
  accountId,
}: IAccountDetailItemProps): React.ReactElement | null => {
  const queryClient = useQueryClient();
  const [account, setAccount] = React.useState<IAccount | undefined>(undefined);
  const data: IAccount | undefined = queryClient.getQueryData([
    AccountQuery.account,
    accountId,
  ]);
  React.useEffect(() => {
    if (data) {
      setAccount(data);
    }
  }, [data]);

  if (!account) return null;

  return (
    <>
      <AccountCredentialsSettings account={account} />
      <CompanySettings account={account} />
    </>
  );
};

export default ProfileSettings;
