import React from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { errorHandler, IErrorResponse } from "core/utils/errorHandler";
import { snackbarHandler } from "core/utils/snackbarHandler";
import {
  IExistingActionFormValues,
  IExistingActionFormInput,
  IAction,
} from "modules/Action/models";
import ActionService from "modules/Action/services";
import TimeDeltaPicker from "../TimeDeltaPicker";

interface TimeDeltaProps {
  action: IAction;
  sequenceId: number;
}

const TimeDelta = ({
  action,
  sequenceId,
}: TimeDeltaProps): React.ReactElement => {
  const queryClient = useQueryClient();
  const { control, handleSubmit, setError } = useForm<IAction>({
    defaultValues: {
      time_delta: action.time_delta,
    },
  });

  const mutateUpdateTimeDelta = useMutation(
    (data: IExistingActionFormValues) => ActionService.updateAction(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["actions", sequenceId]);
        snackbarHandler.success("Time delay updated!");
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response, setError);
      },
    }
  );

  const onSubmit = (data: IExistingActionFormInput) => {
    mutateUpdateTimeDelta.mutate({
      time_delta: data.time_delta,
      id: action.id,
    });
  };

  return (
    <form autoComplete="off">
      <TimeDeltaPicker
        key={2}
        name="time_delta"
        control={control}
        label="Time delay: (if no reply)"
        defaultValue={action.time_delta}
        handleOnSubmit={handleSubmit(onSubmit)}
      />
    </form>
  );
};

export default TimeDelta;
