import React from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { alpha, Theme } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useMenuStyles } from "ui-kit/styles";
import NewPlaceholderDialog from "../NewPlaceholderDialog";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "sticky",
    left: 0,
    zIndex: 1,
    background: theme.app.palette.background.light,
    padding: theme.spacing(2.128, 3, 2.128, 4),
    fontWeight: 500,
    borderRight: `2px solid ${theme.app.palette.shadow.primary}`,
  },
  wrap: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontWeight: 500,
  },
  wrapText: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  icon: {
    color: alpha(theme.app.palette.action.color, 0.8),
  },
}));

interface TableEditableCellProps {
  content: string;
  payload: number;
}

const TableNewPlaceholderCell = ({
  content,
  payload,
}: TableEditableCellProps): React.ReactElement => {
  const classes = useStyles();
  const menuClasses = useMenuStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <TableCell variant="body" className={classes.root}>
      <div className={classes.wrap}>
        <span className={classes.wrapText}>{content}</span>
        <IconButton className={classes.icon} onClick={handleClick} size="small">
          <MoreHorizIcon fontSize="small" />
        </IconButton>
      </div>
      <Menu
        anchorEl={anchorEl}
        id="new-placeholder-menu"
        keepMounted
        autoFocus={false}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          className: clsx(menuClasses.paper, menuClasses.small),
          elevation: 20,
        }}
      >
        <MenuItem
          className={menuClasses.item}
          onClick={() => {
            setAnchorEl(null);
            setOpenDialog(true);
          }}
        >
          Add new placeholder
        </MenuItem>
      </Menu>
      <NewPlaceholderDialog
        personId={payload}
        open={openDialog}
        setOpen={setOpenDialog}
      />
    </TableCell>
  );
};

export default TableNewPlaceholderCell;
