/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ItemTypes } from "core/models";
import { IAccountRestrictionRange } from "modules/Account/models";
import { ISearch } from "modules/Search/models";
import { DeepMap } from "react-hook-form";
import slugify from "slugify";

function isNumber(n: string | number | ItemTypes): boolean {
  return Number.isNaN(n);
}

const forceStyle = (str: string | number) => `${str} !important`;

const isStringNumber = (value: string) => Number.isNaN(Number(value)) === false;

// eslint-disable-next-line @typescript-eslint/ban-types
function enumToArray<T extends {}>(givenEnum: T) {
  return (Object.keys(givenEnum).filter(isStringNumber) as (keyof T)[]).map(
    (key) => ({ id: key, name: givenEnum[key] })
  );
}

const getFirstTabSlug = (tabs: { name: string }[]) =>
  slugify((tabs?.[0].name).toLowerCase());

const createOption = (id: string, label: string) => ({
  id,
  label,
});

const formatRangeArrayToObject = (
  arr: [number, number]
): { start: number; end: number } => ({
  start: arr[0],
  end: arr[1],
});

const formatRangeObjectToArray = ({
  start,
  end,
}: IAccountRestrictionRange): [number, number] => [start, end];

const formatUrlToHideProtocol = (url: string | undefined): string => {
  if (!url) return "";
  return url.replace(/(^\w+:|^)\/\//, "");
};

type IOption = { id: number; name: string }[];

const getAutocompleteOptions = (results: IOption | undefined) =>
  results
    ? results.map(({ id, name }) => ({
        id,
        label: name,
      }))
    : [];

const generateArrayQuery = (value: string | string[], name: string): string => {
  const query = `&${name}=`;
  if (Array.isArray(value)) {
    let q = "";
    value.forEach((v) => {
      q += `${query}${v}`;
    });
    return q;
  }
  return query + value;
};

const getIsDirty = (dirtyFields: {
  [key: string]:
    | string
    | boolean
    | number
    | undefined
    | DeepMap<{ template: string }, true>
    | DeepMap<ISearch, true>;
}): boolean => {
  return Object.keys(dirtyFields).length > 0;
};

const getDisabledStep = (
  enabledStep: number,
  activeStep: number,
  index: number
): boolean => {
  if (activeStep === 0 && index === 0) {
    return true;
  }
  if (activeStep !== 0 && index === 0) {
    return false;
  }
  if (index <= enabledStep && enabledStep >= activeStep) {
    return false;
  }
  return true;
};

export {
  isNumber,
  enumToArray,
  getFirstTabSlug,
  createOption,
  formatRangeArrayToObject,
  formatRangeObjectToArray,
  formatUrlToHideProtocol,
  getAutocompleteOptions,
  generateArrayQuery,
  getIsDirty,
  getDisabledStep,
  forceStyle,
};
