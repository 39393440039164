import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Radio from "@mui/material/Radio";
import { IActionSelectorOption } from "core/models";

interface ActionSelectorProps {
  checked: string;
  setChecked: (id: any) => void;
  options: IActionSelectorOption[];
}

const ActionSelector = ({
  checked,
  setChecked,
  options,
}: ActionSelectorProps): React.ReactElement => (
  <List>
    {options.map((option) => {
      const labelId = `checkbox-list-label-${option.id}`;

      return (
        <ListItem
          key={option.id}
          role={undefined}
          dense
          button
          onClick={() => setChecked(option.id)}
          sx={{ px: 2.5 }}
        >
          <ListItemIcon sx={{ minWidth: 40 }}>
            <Radio
              edge="start"
              checked={checked === option.id}
              tabIndex={-1}
              disableRipple
              inputProps={{ "aria-labelledby": labelId }}
            />
          </ListItemIcon>
          <ListItemText {...option} id={labelId} />
        </ListItem>
      );
    })}
  </List>
);

export default ActionSelector;
