import Button, { ButtonProps } from "@mui/material/Button";
import { makeStyles, useTheme } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import RouterConstants from "core/routes/constants";
import RouterUtils from "core/routes/utils";
import { snackbarHandler } from "core/utils/snackbarHandler";
import { errorHandler, IErrorResponse } from "core/utils/errorHandler";
import get from "lodash/get";
import { AccountContext } from "modules/Account/context";
import { handleUserAccountCheck } from "modules/Account/utils";
import { UserContext } from "modules/User/context";
import { ISocialUserFormInput } from "modules/User/models";
import { UserActionType } from "modules/User/reducers";
import UserService from "modules/User/services";
import React, { useContext } from "react";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import ConfigConstant from "core/constants/ConfigConstant";

const size = 34;

const useStyles = makeStyles((theme: Theme) => ({
  startIcon: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    left: 6,
    backgroundColor: "white",
    borderRadius: 6,
    height: size,
    width: size,
    margin: 0,
  },
}));

function GoogleSignIn({ children }: ButtonProps): React.ReactElement | null {
  const theme = useTheme();
  const classes = useStyles();
  const { dispatch: userDispatch } = useContext(UserContext);
  const { dispatch: accountDispatch } = useContext(AccountContext);

  const history = useHistory();

  const { code } = RouterUtils.getQueryParams(history.location);

  const mutateCreateSocialUser = useMutation(
    (data: ISocialUserFormInput) => UserService.createSocialUser(data),
    {
      onSuccess: (response) => {
        userDispatch({
          type: UserActionType.SET_USER_TOKEN,
          payload: response.data,
        });

        handleUserAccountCheck(
          get(response, "data.profile.username"),
          accountDispatch
        );

        history.push(RouterConstants.ROOT);
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);

        history.push(RouterConstants.USER.SIGN_UP);
      },
    }
  );

  // Run only on the first update
  const firstUpdateQuery = React.useRef(true);
  React.useEffect(() => {
    if (code) {
      if (!firstUpdateQuery.current) {
        return;
      }

      if (!ConfigConstant.GOOGLE_OAUTH_REDIRECT_URI) {
        snackbarHandler.error(
          "Google Login doesn't work now. Try email login."
        );
      }

      mutateCreateSocialUser.mutate({
        code,
        callback_url: String(ConfigConstant.GOOGLE_OAUTH_REDIRECT_URI),
      });

      firstUpdateQuery.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  if (code) {
    return null;
  }

  return (
    <Button
      fullWidth
      size="large"
      color="secondary"
      variant="contained"
      disabled={!!code}
      component="a"
      href={RouterConstants.USER.LOGIN.GOOGLE.AUTH}
      startIcon={<img src="/google.svg" alt="google logo" />}
      classes={{
        startIcon: classes.startIcon,
      }}
      sx={{
        paddingLeft: theme.spacing(8),
        backgroundColor: "rgb(66, 133, 244)",
        "&:hover": {
          backgroundColor: "rgb(51, 103, 214)",
        },
      }}
    >
      {children}
    </Button>
  );
}

export default GoogleSignIn;
