import React, { useContext } from "react";
import { Control, FieldErrors } from "react-hook-form";
import { useQuery } from "react-query";
import AutocompleteField from "core/components/AutocompleteField";
import ConfigConstant from "core/constants/ConfigConstant";
import PersonService from "modules/Person/services";
import { AccountContext } from "modules/Account/context";
import { getPersonName } from "modules/Person/utils";

interface PeopleAutocompleteProps {
  control: Control;
  errors: FieldErrors;
}

const PeopleAutocomplete = ({
  control,
  errors,
}: PeopleAutocompleteProps): React.ReactElement | null => {
  const [search, setSearch] = React.useState("");
  const { account } = useContext(AccountContext);

  const fetchAllPeople = async () => {
    try {
      const { data } = await PersonService.fetchAllPersons(
        account.person?.id,
        ConfigConstant.INITIAL_PAGE,
        search
      );
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };
  const { data, isLoading } = useQuery(["people-all", search], () =>
    fetchAllPeople()
  );

  const options = data?.results
    ? data?.results.map((person) => ({
        id: person.id,
        label: getPersonName(person),
      }))
    : [];

  return (
    <AutocompleteField
      control={control}
      errors={errors}
      multiple
      options={options}
      isLoading={isLoading}
      handleInputChange={(event, newInputValue) => {
        setSearch(newInputValue);
      }}
      label="Start typing a name and select"
      name="people"
    />
  );
};

export default PeopleAutocomplete;
