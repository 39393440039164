import React from "react";
import { generateValues } from "modules/Report/utils";
import { IDateReport, IChartValues, ChartType } from "modules/Report/models";
import { IInteraction } from "modules/Interaction/models";
import ReportElement from "../ReportElement";

interface ReportCountGeneratorProps {
  label: string;
  isLoading: boolean;
  count: string | number | undefined;
  results: IInteraction[] | undefined;
  DateProps: IDateReport;
  index: number;
  total: number;
  chartType?: ChartType;
  itemsPerLine: number;
}

const ReportCountGenerator = ({
  results,
  ...props
}: ReportCountGeneratorProps): React.ReactElement => {
  const { DateProps } = props;
  const [values, setValues] = React.useState<IChartValues>([]);

  React.useEffect(() => {
    if (results) {
      const newValues = generateValues(results, DateProps);
      setValues(newValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results]);

  return <ReportElement {...props} values={values} />;
};

ReportCountGenerator.defaultProps = {
  chartType: ChartType.integer,
};

export default ReportCountGenerator;
