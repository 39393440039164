const envs = {
  API_URL: process.env.REACT_APP_API_URL,
  BASE_URL: process.env.REACT_APP_BASE_URL,
  SEAT_CONTENT_TYPE_ID: process.env.REACT_APP_SEAT_CONTENT_TYPE_ID,
  INTERACTION_CONTENT_TYPE_ID:
    process.env.REACT_APP_INTERACTION_CONTENT_TYPE_ID,
  SENTRY_URL: process.env.REACT_APP_SENTRY_URL,
  STRIPE_PUB_KEY: process.env.REACT_APP_STRIPE_PUB_KEY,
  CRISP_ID: process.env.REACT_APP_CRISP_ID,
  HOTJAR_ID: process.env.REACT_APP_HOTJAR_ID,
  GOOGLE_OAUTH_CLIENT_ID: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID,
  GOOGLE_OAUTH_REDIRECT_URI: process.env.REACT_APP_GOOGLE_OAUTH_REDIRECT_URI,
  GOOGLE_ANALYTICS_ID: process.env.REACT_APP_GOOGLE_ANALYTICS_ID,
  GOOGLE_TAG_MANAGER_ID: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
  GOOGLE_TAG_MANAGER_AUTH: process.env.REACT_APP_GOOGLE_TAG_MANAGER_AUTH,
  GOOGLE_TAG_MANAGER_PREVIEW: process.env.REACT_APP_GOOGLE_TAG_MANAGER_PREVIEW,
  CUSTOMERLY_ID: process.env.REACT_APP_CUSTOMERLY_ID,
  ENV: process.env.REACT_APP_CUSTOM_NODE_ENV,
  APP_NAME: process.env.REACT_APP_APP_NAME,
  OLD_APP_NAME: process.env.REACT_APP_OLD_APP_NAME,
  DOMAIN_NAME: process.env.REACT_APP_DOMAIN_NAME,
  APP_ID: process.env.REACT_APP_APP_ID,
  DISCOUNT_CODE_ID: process.env.REACT_APP_DISCOUNT_CODE_ID,
  DISCOUNT_CODE_LABEL: process.env.REACT_APP_DISCOUNT_CODE_LABEL,
};

export default envs;
