import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import clsx from "clsx";
import ElementField from "core/components/ElementField";
import SwitchField from "core/components/SwitchField";
import { DayLabels, Days, IWorkTime } from "modules/Account/models";
import React from "react";
import { Control, Controller, useWatch as watch } from "react-hook-form";
import { Chip } from "ui-kit/atoms/Chip";
import TimePicker from "ui-kit/atoms/TimePicker";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fieldset: {
      width: "100%",
    },
    box: {
      display: "flex",
      marginTop: theme.spacing(-2),
      [theme.breakpoints.up("md")]: {
        marginLeft: theme.spacing(-3.75),
        width: "100%",
        height: 66,
        alignItems: "center",
        justifyContent: "space-between",
      },
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        marginBottom: theme.spacing(4),
      },
    },
    generalBox: {
      [theme.breakpoints.up("md")]: {
        marginBottom: theme.spacing(1),
      },
      [theme.breakpoints.down("md")]: {
        marginBottom: theme.spacing(4),
      },
    },
    time: {
      margin: theme.spacing(3, 0, 1),
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.up("md")]: {
        width: 320,
      },
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
      "& > *:first-of-type": {
        paddingRight: theme.spacing(2),
      },
    },
    stop: {
      [theme.breakpoints.up("md")]: {
        width: 500,
      },
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
      textAlign: "center",
      marginBottom: theme.spacing(2),
    },
    form: {
      [theme.breakpoints.down("md")]: {
        paddingTop: theme.spacing(4),
      },
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(2, 5),
        maxWidth: 700,
      },
    },
  })
);

interface IControllerProps {
  control: Control;
  workTimes: IWorkTime | undefined;
}

const WorkHoursField = ({
  control,
  workTimes,
}: IControllerProps): React.ReactElement => {
  const classes = useStyles();

  const generalWorkTimesEnabled = !!watch({
    name: "work_times_general.enabled",
    control,
  });

  return (
    <Box className={classes.form}>
      <FormControl component="fieldset" className={classes.fieldset}>
        <FormGroup aria-label="position">
          <div className={clsx(classes.box, classes.generalBox)}>
            <ElementField
              name="work_times_general.enabled"
              component={SwitchField}
              ElementProps={{ positionRight: true }}
              control={control}
              label="Apply same time for all active days."
            />
            {generalWorkTimesEnabled && (
              <div className={classes.time}>
                <Controller
                  name="work_times_general.start_time"
                  control={control}
                  render={({ value, onChange }) => (
                    <TimePicker
                      name="work_times_general.start_time"
                      label="From"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
                <Controller
                  name="work_times_general.end_time"
                  control={control}
                  render={({ value, onChange }) => (
                    <TimePicker
                      name="work_times_general.end_time"
                      label="To"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </div>
            )}
          </div>

          {workTimes &&
            Object.keys(workTimes).map((key) => {
              const name = `work_times.${key}.active`;
              const dayActive = watch({
                name,
                control,
              });
              return (
                <div key={key} className={classes.box}>
                  <ElementField
                    name={name}
                    component={SwitchField}
                    control={control}
                    label={DayLabels[key as Days]}
                  />
                  {dayActive ? (
                    <div className={classes.time}>
                      <Controller
                        name={`work_times.${key}.start_time`}
                        control={control}
                        render={({ value, onChange }) => (
                          <TimePicker
                            name={`work_times.${key}.start_time`}
                            label="From"
                            value={value}
                            onChange={onChange}
                            disabled={generalWorkTimesEnabled}
                          />
                        )}
                      />
                      <Controller
                        name={`work_times.${key}.end_time`}
                        control={control}
                        render={({ value, onChange }) => (
                          <TimePicker
                            name={`work_times.${key}.end_time`}
                            label="To"
                            value={value}
                            onChange={onChange}
                            disabled={generalWorkTimesEnabled}
                          />
                        )}
                      />
                    </div>
                  ) : (
                    <div className={classes.stop}>
                      <Chip
                        label="Not a work day"
                        color="error"
                        variant="outlined"
                      />
                    </div>
                  )}
                </div>
              );
            })}
        </FormGroup>
      </FormControl>
    </Box>
  );
};

export default WorkHoursField;
