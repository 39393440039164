import React from "react";
import { AxiosResponse } from "axios";

export enum GetStartedLabels {
  "user" = "user",
  "account" = "account",
  "create-campaign" = "create-campaign",
  "launch-campaign" = "launch-campaign",
  "search" = "search",
  "sequence" = "sequence",
}

export interface GetStartedListItemProps {
  label: GetStartedLabels;
  func: (accountId: number) => Promise<AxiosResponse>;
  path: string;
  tag: string;
}

export interface GetStartedItemProps extends GetStartedListItemProps {
  body: string;
  link: string;
}

export interface BaseGetStartedListItemProps {
  item: GetStartedItemProps;
  accountId: number;
  index: number;
  selected: boolean;
  setSelectedItem: (item: GetStartedItemProps) => void;
  incompleteItemLabels: GetStartedLabels[];
  setIncompleteItemLabel: React.Dispatch<
    React.SetStateAction<GetStartedLabels[]>
  >;
}

export interface IGetStartedListItem {
  id: string;
  title: string;
  body: string;
  done: boolean;
  buttonLabel: string;
  skipEnabled: boolean;
  skipHandler?: () => void;
  handler: () => void;
}
