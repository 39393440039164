import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Theme } from "@mui/material";
import { formatDateTimeToFull } from "core/utils/dateHandler";
import { getProfileName } from "core/utils/profileHandler";
import {
  IInteraction,
  InteractionCategories,
} from "modules/Interaction/models";
import { getInteractionStep } from "modules/Interaction/utils";
import PersonAvatar from "../PersonAvatar";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  name: {
    fontWeight: 500,
    color: theme.app.palette.link,
    marginBottom: theme.spacing(0.25),
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  subtext: {
    fontSize: 11,
  },
  subtitle: {
    fontWeight: 500,
  },
  avatar: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}));

interface InteractionPersonProps {
  interaction: IInteraction;
}

const InteractionPerson = ({
  interaction,
}: InteractionPersonProps): React.ReactElement => {
  const classes = useStyles();
  const name = getProfileName(interaction.search_result, interaction.person);
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 2.25 }}>
      <div className={classes.avatar}>
        <PersonAvatar
          name={name}
          src={interaction?.person?.image_base64}
          size="medium"
        />
      </div>

      <div className={classes.root}>
        <div className={classes.name}>{name}</div>
        <Box className={classes.subtext} sx={{ mb: 0.25 }}>
          <span className={classes.subtitle}>Last action:</span>{" "}
          {interaction.category ===
          InteractionCategories.person_in_campaign_queue
            ? "-"
            : formatDateTimeToFull(interaction.platform_created_at)}
        </Box>
        <div className={classes.subtext}>
          <span className={classes.subtitle}>Step:</span>{" "}
          {getInteractionStep(
            interaction.sequence_action_order_nr,
            interaction.category
          )}
        </div>
      </div>
    </Box>
  );
};

export default InteractionPerson;
