import React from "react";
import { ReportNames } from "modules/Report/models";
import InteractionService from "modules/Interaction/services";
import { createCountReport } from "modules/Report/utils";
import InteractionCompareItem from "modules/Interaction/components/InteractionCompareItem";

interface CampaignSequenceProspectRateProps {
  campaignId: number;
  sequenceId: number;
}

const CampaignSequenceProspectRate = ({
  campaignId,
  sequenceId,
}: CampaignSequenceProspectRateProps): React.ReactElement => (
  <InteractionCompareItem
    query={`&campaign_id=${campaignId}`}
    query2={`&sequence_id=${sequenceId}`}
    showTotal
    items={[
      createCountReport(
        ReportNames.invitation,
        "Sequence prospects total",
        InteractionService.fetchCampaignSequenceTotalProspects
      ),
      createCountReport(
        ReportNames.connection,
        "Campaign prospects total",
        InteractionService.fetchCampaignSequenceTotalProspects
      ),
    ]}
  />
);

export default CampaignSequenceProspectRate;
