/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useEffect, useState, SetStateAction } from "react";
import { Controller, Control } from "react-hook-form";
import clsx from "clsx";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import Popover from "@mui/material/Popover";
import Avatar from "@mui/material/Avatar";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { TIME_DELTA_OPTIONS } from "modules/Campaign/constants";
import CounterField from "ui-kit/atoms/CounterField";
import Button from "ui-kit/atoms/Button";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      border: `1px solid ${theme.app.palette.shadow.primary}`,
    },
    card: {
      [theme.breakpoints.up("md")]: {
        width: theme.app.constants.action.full,
      },
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
      padding: theme.spacing(0),
    },
    menuItem: {
      width: "100%",
      padding: theme.spacing(2.25, 5),
      borderRadius: 0,
      "&:hover": {
        borderColor: theme.app.palette.shadow.primary,
      },
    },
    menuItemDivider: {
      borderBottom: `1px solid ${theme.app.palette.shadow.primary}`,
    },
    paper: {
      width: "100%",
      maxWidth: theme.app.constants.action.full,
    },
    box: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      transition: theme.transitions.create("width"),
      [theme.breakpoints.up("md")]: {
        width:
          theme.app.constants.action.full + theme.app.constants.action.half,
      },
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
    boxAdvanced: {
      [theme.breakpoints.up("md")]: {
        width: theme.app.constants.action.full,
      },
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
    selector: {
      borderRight: `1px solid ${theme.app.palette.shadow.primary}`,
      width: theme.app.constants.action.full,
      transition: theme.transitions.create("width"),
    },
    selectorAdvanced: {
      [theme.breakpoints.up("md")]: {
        width: theme.app.constants.action.half,
      },
      [theme.breakpoints.down("md")]: {
        width: 0,
        display: "none",
      },
    },
    picker: {
      [theme.breakpoints.up("md")]: {
        width: theme.app.constants.action.half,
      },
      [theme.breakpoints.down("md")]: {
        display: "none",
        padding: theme.spacing(8, 2),
      },
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    pickerAdvanced: {
      [theme.breakpoints.down("md")]: {
        textAlign: "center",
        width: "100%",
        display: "block",
        padding: theme.spacing(11, 2),
      },
    },
    actions: {
      justifyContent: "flex-end",
    },
    view: {
      width: "100%",
      overflow: "visible",
      [theme.breakpoints.up("md")]: {
        width: 350,
      },
    },
    input: {
      fontSize: "0.875rem !important",
      color: theme.app.palette.text.secondary,
    },
    label: {
      textAlign: "center",
      marginRight: theme.spacing(1.5),
    },
    header: {
      "&:hover": {
        cursor: "pointer",
        backgroundColor: theme.palette.grey[100],
        "& $expandIcon": {
          visibility: "visible",
        },
      },
    },
    expandIcon: {
      visibility: "hidden",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    },
    avatar: {
      backgroundColor: theme.palette.secondary.dark,
    },
  })
);

const OTHER = "OTHER";
const YEAR_DAYS = 365;
const MONTH_DAYS = 30;
const HOUR = 3600;
const YEAR = YEAR_DAYS * 24 * HOUR;
const MONTH = MONTH_DAYS * 24 * HOUR;
const DAY = 24 * HOUR;
const MINUTE = 60;

interface ITimeDeltaPicker {
  label: string;
  name: string;
  defaultValue?: string;
  control: Control;
  handleOnSubmit: () => void;
}

const TimeDeltaPicker = ({
  name,
  label,
  control,
  defaultValue,
  handleOnSubmit,
}: ITimeDeltaPicker): React.ReactElement => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [years, setYears] = useState(0);
  const [months, setMonths] = useState(0);
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [timeDelta, setTimeDelta] = useState(0);
  const [timeDeltaString, setTimeDeltaString] = useState(
    defaultValue || "0 00:00:00"
  );
  const [selectedDelta, setSelectedDelta] = useState(
    defaultValue || "00:00:00"
  );
  const [timeDelayValue, setTimeDelayValue] = useState("");

  // Advanced options
  const [advanced, toggleAdvanced] = useState(false);

  useEffect(() => {
    const _years = years || 0;
    const _months = months || 0;
    const _days = days || 0;
    const _hours = hours || 0;
    const _minutes = minutes || 0;
    if (_years < 0) setYears(0);
    if (_months < 0) setMonths(0);
    if (_days < 0) setDays(0);
    if (_hours < 0) setHours(0);
    if (_minutes < 0) setMinutes(0);
    if (_months > 11) {
      setMonths(0);
      setYears(_years + 1);
    }
    if (_days > MONTH_DAYS - 1) {
      setDays(0);
      setMonths(_months + 1);
    }
    if (_hours > 23) {
      setHours(0);
      setDays(_days + 1);
    }
    if (_minutes > 59) {
      setMinutes(0);
      setHours(_hours + 1);
    }
    setTimeDelta(
      _years * YEAR +
        _months * MONTH +
        _days * DAY +
        _hours * HOUR +
        _minutes * MINUTE
    );

    const formatTimeDelta = () => {
      const arr = [
        { label: { single: "year", plural: "years" }, value: years },
        { label: { single: "month", plural: "months" }, value: months },
        { label: { single: "day", plural: "days" }, value: days },
        { label: { single: "hour", plural: "hours" }, value: hours },
        { label: { single: "min", plural: "min" }, value: minutes },
      ];
      let time = "";
      arr.forEach((a) => {
        time += a.value
          ? `${a.value} ${a.value === 1 ? a.label.single : a.label.plural} `
          : "";
      });
      return time;
    };
    setTimeDelayValue(formatTimeDelta());
  }, [years, months, days, hours, minutes]);

  useEffect(() => {
    let reminder = timeDelta;
    const _years = Math.floor(reminder / YEAR);
    reminder %= YEAR;

    const _months = Math.floor(reminder / MONTH);
    reminder %= MONTH;

    let _days = Math.floor(reminder / DAY);
    reminder %= DAY;

    const _hours = Math.floor(reminder / HOUR);
    reminder %= HOUR;

    const _minutes = Math.floor(reminder / MINUTE);
    reminder %= MINUTE;

    _days = _years * 365 + _months * 30 + _days;

    const tString = `${String(_hours).padStart(2, "0")}:${String(
      _minutes
    ).padStart(2, "0")}:00`;
    const _timeDeltaString = _days === 0 ? tString : `${_days} ${tString}`;
    setTimeDeltaString(_timeDeltaString);
  }, [timeDelta]);

  useEffect(() => {
    const found = TIME_DELTA_OPTIONS.find(
      (option) => option.id === timeDeltaString
    );
    if (!found) {
      if (selectedDelta !== OTHER) setSelectedDelta(OTHER);
      return;
    }
    if (found.id !== selectedDelta) setSelectedDelta(found.id);
  }, [timeDeltaString, selectedDelta]);

  useEffect(() => {
    if (selectedDelta === OTHER) return;
    let _days = 0;

    let timeString = selectedDelta;
    const hasDays = timeString.length > "00:00:00".length;
    if (hasDays) {
      const splitTimeDelta = timeString.split(" ");

      _days = Number(splitTimeDelta[0]);
      timeString = String(splitTimeDelta[1]);
    }
    const splitTimeString = timeString.split(":");

    const _hours = Number(splitTimeString[0]);
    const _minutes = Number(splitTimeString[1]);
    const _years = Math.floor(_days / YEAR_DAYS);
    _days %= YEAR_DAYS;
    const _months = Math.floor(_days / MONTH_DAYS);
    _days %= MONTH_DAYS;

    setMinutes(_minutes);
    setHours(_hours);
    setDays(_days);
    setMonths(_months);
    setYears(_years);
  }, [selectedDelta]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClick = (event: SetStateAction<any>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Controller
      name={name}
      control={control}
      render={({ onChange, value }) => {
        const onPickerClose = () => {
          // Only update when the value changes
          if (value !== timeDeltaString) {
            // Set form value for the API
            onChange(timeDeltaString);
            handleOnSubmit();
          }

          // Close picker
          handleClose();
        };

        return (
          <>
            <Paper
              variant="outlined"
              className={classes.view}
              onClick={handleClick}
            >
              <CardHeader
                avatar={
                  <Avatar
                    className={classes.avatar}
                    aria-label="time-delta-icon"
                  >
                    <AccessTimeIcon />
                  </Avatar>
                }
                action={
                  <Tooltip title="Edit time delay" arrow placement="top">
                    <IconButton className={classes.expandIcon} size="large">
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                }
                title={label}
                titleTypographyProps={{
                  variant: "subtitle2",
                }}
                subheader={timeDelayValue}
                subheaderTypographyProps={{ variant: "body1" }}
                className={classes.header}
              />
            </Paper>
            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={onPickerClose}
              PaperProps={{ elevation: 24, className: classes.paper }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Card className={classes.root}>
                <CardContent className={classes.card}>
                  <div
                    className={clsx({
                      [classes.box]: true,
                      [classes.boxAdvanced]: advanced,
                    })}
                  >
                    <div
                      className={clsx({
                        [classes.selector]: true,
                        [classes.selectorAdvanced]: advanced,
                      })}
                    >
                      {TIME_DELTA_OPTIONS.map((option, index) => {
                        return (
                          <MenuItem
                            selected={option.id === selectedDelta}
                            className={clsx({
                              [classes.menuItem]: true,
                              [classes.menuItemDivider]:
                                index !== TIME_DELTA_OPTIONS.length - 1,
                            })}
                            key={option.id}
                            value={option.id}
                            onClick={() => setTimeDeltaString(option.id)}
                          >
                            {option.label}
                          </MenuItem>
                        );
                      })}
                    </div>
                    <div
                      className={clsx({
                        [classes.picker]: true,
                        [classes.pickerAdvanced]: advanced,
                      })}
                    >
                      <div>
                        <CounterField
                          label="Minutes"
                          value={minutes}
                          handleIncrement={() => setMinutes(minutes + 5)}
                          handleDecrement={() => setMinutes(minutes - 5)}
                        />
                        <CounterField
                          label="Hours"
                          value={hours}
                          handleIncrement={() => setHours(hours + 1)}
                          handleDecrement={() => setHours(hours - 1)}
                        />
                        <CounterField
                          label="Days"
                          value={days}
                          handleIncrement={() => setDays(days + 1)}
                          handleDecrement={() => setDays(days - 1)}
                        />
                        <CounterField
                          label="Months"
                          value={months}
                          handleIncrement={() => setMonths(months + 1)}
                          handleDecrement={() => setMonths(months - 1)}
                        />
                      </div>
                    </div>
                  </div>
                </CardContent>
                <CardActions className={classes.actions}>
                  <Button
                    type="button"
                    variant="outlined"
                    color="inherit"
                    onClick={() => toggleAdvanced(!advanced)}
                  >
                    {advanced ? "Hide advanced" : "Advanced options"}
                  </Button>
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={onPickerClose}
                  >
                    Save delay
                  </Button>
                </CardActions>
              </Card>
            </Popover>
          </>
        );
      }}
    />
  );
};

TimeDeltaPicker.defaultProps = {
  defaultValue: "",
};

export default TimeDeltaPicker;
