/* eslint-disable @typescript-eslint/naming-convention */
import React from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { IAccountDetailItemProps } from "modules/Account/models";
import AccountUsage from "modules/Account/components/AccountUsage";

const AccountStats = ({
  accountId,
}: IAccountDetailItemProps): React.ReactElement | null => {
  return (
    <>
      <Box sx={{ py: 4, pb: 8 }}>
        <Box sx={{ pb: 5 }}>
          <Typography variant="h6" gutterBottom>
            Your usage
          </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
            Here you can check how much time you saved automating your outreach.
          </Typography>
        </Box>
        {accountId && <AccountUsage accountId={accountId} />}
      </Box>
      <Divider />
    </>
  );
};

export default AccountStats;
