import React from "react";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import Tooltip, { TooltipProps } from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "ui-kit/icons/InfoIcon";

export interface HelperTooltipProps {
  title: string | React.ReactElement;
  placement?: TooltipProps["placement"];
}

const useStyles = makeStyles((theme: Theme) => ({
  iconButton: {
    margin: theme.spacing(0, 1),
    padding: theme.spacing(1),
    cursor: "default",
    color: theme.app.palette.action.color,
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    "& > div$icon": {
      color: theme.app.palette.action.color,
    },
    "&:hover": {
      color: theme.app.palette.text.secondary,
      "& > div$icon": {
        color: theme.app.palette.text.secondary,
      },
    },
  },
}));

const HelperTooltip = ({
  title,
  placement = "top",
}: HelperTooltipProps): React.ReactElement => {
  const classes = useStyles();
  return (
    <Tooltip title={title} arrow placement={placement}>
      <IconButton
        disableRipple
        disableFocusRipple
        disableTouchRipple
        className={classes.iconButton}
      >
        <InfoIcon />
      </IconButton>
    </Tooltip>
  );
};

export default HelperTooltip;
