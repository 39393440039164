import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Paper } from "@mui/material";
import RouterConstants from "core/routes/constants";
import { useTheme } from "@mui/styles";

interface IntegrationAccordionProps {
  campaignId: number;
}

const IntegrationAccordion = ({ campaignId }: IntegrationAccordionProps) => {
  const theme = useTheme();
  return (
    <Accordion
      sx={{
        mt: 6,
        boxShadow: "none",
        "&:before": {
          display: "none",
        },
      }}
    >
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon
            sx={{ color: theme.app.palette.action.color, fontSize: 18 }}
          />
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography color="action.color" sx={{ fontSize: 13, mr: 1 }}>
          You can also add prospects automatically with{" "}
          <Box component="span" sx={{ textDecoration: "underline" }}>
            Integrations
          </Box>
          .
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Paper variant="elevation" elevation={10} sx={{ px: 5, py: 3, mt: -2 }}>
          <Typography
            component="a"
            href={RouterConstants.DOCS.INTEGRATIONS.REVERSE.ALL}
            target="_blank"
            rel="noopener noreferrer"
            variant="body2"
            sx={{ textDecoration: "underline" }}
          >
            How to automatically add prospects to the campaign
          </Typography>
          <Box sx={{ display: "flex", gap: 1, mt: 1.5 }}>
            <Typography variant="body2" color="textSecondary">
              Campaign ID:
            </Typography>

            <Typography variant="body2" color="textPrimary">
              {campaignId}
            </Typography>
          </Box>
        </Paper>
      </AccordionDetails>
    </Accordion>
  );
};

export default IntegrationAccordion;
