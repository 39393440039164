import React from "react";
import List from "@mui/material/List";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { IInteractions } from "modules/Interaction/models";
import Loader from "ui-kit/components/Loader";
import InteractionPeopleListItem from "../InteractionPeopleListItem";

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    padding: theme.spacing(0),
  },
  loadMoreBar: {
    height: 4,
  },
  noResults: {
    padding: theme.spacing(3, 4),
  },
  loadMoreScroll: {
    height: 60,
  },
}));

interface InteractionPeopleListProps {
  personId: number | null;
  pages: IInteractions[];
  hasNextPage: boolean | undefined;
  isLoading: boolean;
  loadMoreRef: (node?: Element | null | undefined) => void;
  loadMoreDisabled: boolean;
}

const InteractionPeopleList = ({
  personId,
  pages,
  isLoading,
  hasNextPage,
  loadMoreRef,
  loadMoreDisabled,
}: InteractionPeopleListProps): React.ReactElement => {
  const classes = useStyles();
  return (
    <List data-cy="interaction-list" className={classes.list}>
      <div>
        {pages.map((singlePage: IInteractions) => (
          <React.Fragment key={singlePage.current}>
            {singlePage.results.map((interaction) => (
              <InteractionPeopleListItem
                key={interaction.id}
                personId={personId}
                interaction={interaction}
              />
            ))}
          </React.Fragment>
        ))}
      </div>
      {/* Load more element */}
      {!loadMoreDisabled && (
        <div className={classes.loadMoreBar} ref={loadMoreRef} />
      )}
      {isLoading ||
        (hasNextPage && (
          <div className={classes.loadMoreScroll}>
            <Loader />
          </div>
        ))}
    </List>
  );
};

export default InteractionPeopleList;
