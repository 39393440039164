/* eslint-disable no-restricted-globals */
import { IAccount } from "modules/Account/models";
import * as Yup from "yup";
import { SALES_NAV_COMPANY } from "../constants";
import { SearchStrategy } from "../models";

const ERROR_KEYS = {
  NAME: {
    REQUIRED: "Search name is required.",
    INVALID: "Search name needs to be at least 3 characters long.",
  },
  SEARCH: {
    REQUIRED: "Selecting a search is required.",
  },
  URL: {
    REQUIRED: "Search URL is required.",
    INVALID: "Search URL doesn't seem to be a valid LinkedIn search URL",
    COMPANY:
      "Company List isn't supported as you can't message companies on LinkedIn. Try to create a Lead List of people from your Company List.",
  },
  PLACEHOLDER: {
    REQUIRED: "Placeholder is required.",
  },
};

const createSearchSchema: Yup.AnyObjectSchema = Yup.object().shape({
  searchName: Yup.string()
    .required(ERROR_KEYS.NAME.REQUIRED)
    .min(3, ERROR_KEYS.NAME.INVALID),
  url: Yup.string()
    .required(ERROR_KEYS.URL.REQUIRED)
    // .url(ERROR_KEYS.URL.INVALID)
    .test(
      "is-sales-navigator-company",
      ERROR_KEYS.URL.COMPANY,
      (value) => !!value && !value.includes(SALES_NAV_COMPANY)
    ),
});

function searchFormSchema(name: string): Yup.AnyObjectSchema {
  return Yup.object().shape({
    [name]: Yup.string().required(ERROR_KEYS.SEARCH.REQUIRED),
  });
}

function matchPlaceholderSchema(keys: string[]): Yup.AnyObjectSchema {
  const shape: any = {};
  keys.forEach((key: string) => {
    if (key) {
      shape[key] = Yup.string().required(ERROR_KEYS.PLACEHOLDER.REQUIRED);
    }
  });
  return Yup.object().shape({
    column_to_rename: Yup.object().shape(shape),
  });
}

// Check if account has active sales_nav or recruiter subscription and notify user if not before continue
function getConfirmLinkedInLicenses(
  variant: SearchStrategy,
  metadata: IAccount["system_meta_data"] | undefined
) {
  if (!metadata) {
    return true;
  }

  if (
    variant === SearchStrategy["inevitable.strategy.search.SalesNavStrategy"] &&
    !metadata.sales_nav_active
  ) {
    if (
      !confirm(
        `We couldn't find an active Sales Navigator license on your account. Do you still wish to continue?`
      )
    ) {
      return false;
    }
  }

  if (
    variant ===
      SearchStrategy["inevitable.strategy.search.RecruiterStrategy"] &&
    !metadata.recruiter_active
  ) {
    if (
      !confirm(
        `We couldn't find an active LinkedIn Recruiter license on your account. Do you still wish to continue?`
      )
    ) {
      return false;
    }
  }

  return true;
}

const SearchValidations = {
  createSearchSchema,
  searchFormSchema,
  matchPlaceholderSchema,
  ERROR_KEYS,
  getConfirmLinkedInLicenses,
};

export default SearchValidations;
