import ElementField from "core/components/ElementField";
import SwitchField from "core/components/SwitchField";
import { formatDateToFull } from "core/utils/dateHandler";
import React from "react";
import { Control, FieldError, useWatch } from "react-hook-form";
import DatePicker from "ui-kit/atoms/DatePicker";
import PaperHeader from "ui-kit/components/PaperHeader";

interface IControllerProps {
  control: Control;
  error: FieldError | undefined;
  name: string;
  label: string | [string, string];
  setValue: (n: string, v: number) => void;
}

const CampaignStartDate = ({
  control,
  error,
  name,
  label,
  setValue,
}: IControllerProps): React.ReactElement => {
  const isActive = useWatch({
    name,
    control,
  });
  const datePickerName = "start_date.date";

  const activeDate = useWatch({
    name: datePickerName,
    control,
  });

  return (
    <>
      <PaperHeader
        title={
          <ElementField
            name={name}
            component={SwitchField}
            control={control}
            label={label}
            ElementProps={{
              header: true,
              positionRight: true,
              onChangeHandler: (checked: boolean) => checked,
            }}
          />
        }
        body={
          isActive && activeDate
            ? `Your campaign will start on ${formatDateToFull(
                activeDate as string
              )}.`
            : `Your campaign starts when launched. Activate schedule start to launch at the future date.`
        }
      />
      <ElementField
        InputProps={{
          id: datePickerName,
          label: "Start date",
          variant: "outlined",
        }}
        ElementProps={{
          disablePast: true,
          disabled: !isActive,
        }}
        component={DatePicker}
        name={datePickerName}
        control={control}
        error={error}
      />
    </>
  );
};

export default CampaignStartDate;
