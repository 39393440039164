import React from "react";
import clsx from "clsx";
import Skeleton from "@mui/material/Skeleton";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import { isLastInRow, isLastLine, isFirstInRow } from "modules/Report/utils";
import { IDateReport, ChartType, IChartValues } from "modules/Report/models";
import CustomLineChart from "modules/Report/components/CustomLineChart";
import Loader from "ui-kit/components/Loader";

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    paddingBottom: theme.spacing(0),
  },
  chart: {
    minHeight: 150,
    padding: theme.spacing(0, 1.5, 2),
  },
  borderBottom: {
    borderBottom: `1px solid ${theme.app.palette.shadow.secondary}`,
  },
  borderRight: {
    borderRight: `1px solid ${theme.app.palette.shadow.secondary}`,
  },
  item: {
    [theme.breakpoints.down("sm")]: {
      "& > *": {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  },
  firstInRow: {
    "& > *": {
      paddingLeft: 0,
    },
  },
  lastInRow: {
    "& > *": {
      paddingRight: 0,
    },
  },
}));

interface ReportElementProps {
  label: string;
  isLoading: boolean;
  count: string | number | undefined;
  values: IChartValues;
  DateProps: IDateReport;
  index: number;
  total: number;
  chartType?: ChartType;
  itemsPerLine: number;
}

const ReportElement = ({
  label,
  isLoading,
  count,
  values,
  DateProps,
  index,
  total,
  chartType = ChartType.integer,
  itemsPerLine,
}: ReportElementProps): React.ReactElement | null => {
  const classes = useStyles();

  return (
    <Grid
      item
      xs={12}
      sm={4}
      lg={3}
      xl={3}
      className={clsx(classes.item, {
        [classes.borderBottom]: !isLastLine(index, total, itemsPerLine),
        [classes.borderRight]: !isLastInRow(index, itemsPerLine),
        [classes.firstInRow]: isFirstInRow(index, itemsPerLine),
        [classes.lastInRow]: isLastInRow(index, itemsPerLine),
      })}
    >
      <CardHeader
        className={classes.header}
        title={label}
        titleTypographyProps={{
          variant: "body2",
          color: "textSecondary",
        }}
        subheader={count !== undefined ? count : <Skeleton width={50} />}
        subheaderTypographyProps={{
          variant: "h6",
          color: "textPrimary",
        }}
      />
      <div className={classes.chart}>
        {isLoading ? (
          <Loader />
        ) : (
          <CustomLineChart
            label={label}
            labels={DateProps.labels}
            values={values}
            type={chartType}
          />
        )}
      </div>
    </Grid>
  );
};

ReportElement.defaultProps = {
  chartType: ChartType.integer,
};

export default ReportElement;
