import React from "react";
import ActionSelector from "ui-kit/components/ActionSelector";
import { createActionSelectorOptions } from "core/utils/componentsHandler";
import { TeamRoleLabel, TeamRoles } from "modules/Team/models";

interface MemberRolesActionSelectorProps {
  checked: TeamRoles;
  setChecked: (id: TeamRoles) => void;
}

const options = [
  createActionSelectorOptions(
    TeamRoles.MA,
    TeamRoleLabel.MA,
    "For people who can manage everything (except your subscription)."
  ),
  createActionSelectorOptions(
    TeamRoles.VI,
    TeamRoleLabel.VI,
    "For people who can see the account, but can't edit anything."
  ),
];

const MemberRolesActionSelector = ({
  checked,
  setChecked,
}: MemberRolesActionSelectorProps): React.ReactElement => (
  <ActionSelector checked={checked} setChecked={setChecked} options={options} />
);

export default MemberRolesActionSelector;
