import React from "react";
import { FieldError, FieldErrors } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import CompanyService from "modules/Company/services";
import { snackbarHandler } from "core/utils/snackbarHandler";
import { errorHandler, IErrorResponse } from "core/utils/errorHandler";
import {
  CompanyFamily,
  ICompany,
  ICompanyFormValues,
} from "modules/Company/models";
import TextField from "ui-kit/atoms/TextField";
import Button from "ui-kit/atoms/Button";
import DialogHeader from "ui-kit/components/DialogHeader";

type DialogValue = { name: string };

interface CompanyDialogProps {
  open: boolean;
  handleClose: () => void;
  dialogValue: DialogValue;
  setDialogValue: (value: DialogValue) => void;
  register: () => void;
  errors: FieldErrors;
  handleChange?: (
    _: React.SyntheticEvent<Element, Event> | undefined,
    newValue: ICompany
  ) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleSubmit: any;
  setError: ((name: string, error: FieldError) => void) | undefined;
}

const NewCompanyDialog = ({
  open,
  dialogValue,
  setDialogValue,
  handleClose,
  handleChange,
  handleSubmit,
  register,
  errors,
  setError,
}: CompanyDialogProps): React.ReactElement => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (newData: ICompanyFormValues) => CompanyService.createCompany(newData),
    {
      onSuccess: (response: { data: ICompany }) => {
        queryClient.invalidateQueries(["companies"]);
        snackbarHandler.success("Company successfully added!");
        handleClose();

        if (handleChange) {
          handleChange(undefined, response?.data);
        }
      },
      onError: (errorMuration: IErrorResponse) => {
        errorHandler(errorMuration.response, setError);
      },
    }
  );

  const onSubmit = (data: { companyName: string }) => {
    const newData = {
      name: data.companyName,
      family: CompanyFamily.CO,
    };
    mutation.mutate(newData);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <form>
        <DialogHeader title="Add a company" onHandleClose={handleClose} />
        <DialogContent dividers>
          <DialogContentText>
            A company helps you with organization of your LinkedIn accounts.
          </DialogContentText>
          <DialogContentText>
            Accounts from the same company can't simultaneously target the same
            prospect.
          </DialogContentText>
          <TextField
            autoComplete="off"
            fullWidth
            autoFocus
            name="companyName"
            variant="outlined"
            margin="dense"
            id="companyName"
            value={dialogValue.name}
            inputRef={register}
            onChange={(event) =>
              setDialogValue({
                ...dialogValue,
                name: event.target.value,
              })
            }
            error={!!errors.companyName}
            helperText={errors.companyName?.message}
            label="Company name"
            type="text"
          />
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            onClick={handleSubmit(onSubmit)}
            color="primary"
            variant="contained"
          >
            Save company
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

NewCompanyDialog.defaultProps = {
  handleChange: undefined,
};

export default NewCompanyDialog;
