import axios, { AxiosResponse } from "axios";
import {
  IAction,
  IActions,
  INewActionFormValues,
  IActionMove,
  IExistingActionFormValues,
} from "../models";

const createAction = (
  data: INewActionFormValues
): Promise<AxiosResponse<IAction>> => {
  return axios.post("/inevitable/actions/", data);
};

const insertAction = (
  data: INewActionFormValues
): Promise<AxiosResponse<IAction>> => {
  return axios.post("/inevitable/actions/insert/", data);
};

const fetchActions = (sequenceId: number): Promise<AxiosResponse<IActions>> => {
  return axios.get(
    `/inevitable/actions/?sequence_id=${sequenceId}&page=1&page_size=1000&ordering=order_nr`
  );
};

const fetchAllActions = (
  sequenceIds: number[] | undefined
): Promise<AxiosResponse<IActions>> => {
  return axios.get(
    `/inevitable/actions/?sequence_id__in=${sequenceIds}&page=1&page_size=1000`
  );
};

const moveAction = (data: IActionMove): Promise<AxiosResponse<IActionMove>> => {
  return axios.patch(`/inevitable/actions/${data.id}/move/`, data);
};

const updateAction = (
  data: IExistingActionFormValues
): Promise<AxiosResponse<IAction>> => {
  return axios.patch(`/inevitable/actions/${data.id}/`, data);
};

const deleteAction = (id: number): Promise<AxiosResponse<IAction>> => {
  return axios.delete(`/inevitable/actions/${id}/`);
};

const ActionService = {
  createAction,
  insertAction,
  fetchActions,
  fetchAllActions,
  moveAction,
  updateAction,
  deleteAction,
};

export default ActionService;
