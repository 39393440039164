import React from "react";
import { Control, FieldErrors } from "react-hook-form";
import { SelectProps } from "@mui/material";
import { WEBHOOK_EVENTS } from "modules/Webhook/constants";
import { WebhookEventLabels, WebhookEvents } from "modules/Webhook/models";
import Select from "ui-kit/components/Select";

interface IControllerProps {
  control: Control;
  errors: FieldErrors;
  sx?: SelectProps["sx"];
}

const WebhookSelectField = ({ control, errors, ...rest }: IControllerProps) => {
  return (
    <Select
      name="event"
      error={errors?.event}
      control={control}
      options={WEBHOOK_EVENTS}
      label="Select event"
      formatValue={(option: SelectProps["value"]): string => {
        return !!option ? WebhookEventLabels[option as WebhookEvents] : "";
      }}
      {...rest}
    />
  );
};

export default WebhookSelectField;
