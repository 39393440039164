import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import LastPageIcon from "@mui/icons-material/LastPage";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Skeleton from "@mui/material/Skeleton";
import { Theme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { makeStyles, useTheme } from "@mui/styles";
import { generateProfileLink, getProfileName } from "core/utils/profileHandler";
import { IInteraction } from "modules/Interaction/models";
import PersonService from "modules/Person/services";
import { getPersonName } from "modules/Person/utils";
import React from "react";
import { useQuery } from "react-query";

const useStyles = makeStyles((theme: Theme) => ({
  topbar: {
    height: theme.app.constants.inbox.headerHeight,
    padding: theme.spacing(1, 2, 1, 3),
    borderBottom: `1px solid ${theme.app.palette.shadow.secondary}`,
    zIndex: 4,
    backgroundColor: theme.palette.common.white,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  header: {
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  link: {
    color: theme.app.palette.link,
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    fontWeight: 500,
    "& > *": {
      marginRight: theme.spacing(1),
    },
    "&:hover": {
      textDecoration: "underline",
    },
  },
  occupation: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "100%",
  },
  box: {
    display: "flex",
    justifyContent: "space-between",
  },
  actions: {
    display: "flex",
    alignItems: "center",
    gap: 2,
  },
  detail: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: "60%",
  },
  item: {
    color: theme.app.palette.action.color,
    "&:hover": {
      color: theme.app.palette.action.main,
    },
  },
  active: {
    backgroundColor: theme.app.palette.action.selected,
  },
  inIcon: {
    color: theme.app.palette.link,
  },
  sidebarBtn: {
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
  },
}));

interface InteractionContainerProfileProps {
  personId: number;
  showSidebar: boolean;
  handleToggleSidebar: () => void;
  disableSidebar?: boolean;
  interaction?: IInteraction;
  handleBacklink: () => void;
}

const InteractionContainerProfile = ({
  personId,
  showSidebar,
  handleToggleSidebar,
  disableSidebar = false,
  interaction,
  handleBacklink,
}: InteractionContainerProfileProps): React.ReactElement => {
  const classes = useStyles();
  const theme: Theme = useTheme();
  const [showSidebarTooltip, setSidebarTooltip] = React.useState(false);

  const fetchPerson = async () => {
    try {
      const { data } = await PersonService.fetchPerson(personId);
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };

  const { data, isLoading } = useQuery(
    ["person", personId],
    () => fetchPerson(),
    {
      enabled: !!personId,
    }
  );

  const desktop = useMediaQuery(() => theme.breakpoints.up("md"));

  const getName = () => {
    if (interaction) {
      return getProfileName(interaction.search_result, interaction.person);
    }
    if (data) {
      return getPersonName(data);
    }
    return "-";
  };

  const onHandleToggleSidebar = () => {
    setSidebarTooltip(false);
    handleToggleSidebar();
  };

  return (
    <div className={classes.topbar}>
      <div className={classes.header}>
        {!desktop && (
          <ListItemIcon>
            <IconButton size="small" onClick={handleBacklink}>
              <KeyboardBackspaceIcon />
            </IconButton>
          </ListItemIcon>
        )}
        {isLoading ? (
          <Skeleton width={60} />
        ) : (
          <Typography variant="body2">{getName()}</Typography>
        )}
      </div>
      <div className={classes.actions}>
        <a
          href={
            personId
              ? generateProfileLink(data)
              : interaction?.search_result?.profile_url
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          <Tooltip title="Open profile (new tab)" placement="bottom">
            <IconButton size="small">
              <LinkedInIcon className={classes.inIcon} fontSize="small" />
            </IconButton>
          </Tooltip>
        </a>
        {!disableSidebar && (
          <Tooltip
            placement="bottom"
            title={showSidebar ? "Hide" : "Show"}
            open={showSidebarTooltip}
            disableHoverListener
            onMouseEnter={() => setSidebarTooltip(true)}
            onMouseLeave={() => setSidebarTooltip(false)}
          >
            <IconButton
              size="small"
              onClick={onHandleToggleSidebar}
              className={classes.sidebarBtn}
            >
              {showSidebar ? (
                <LastPageIcon fontSize="small" />
              ) : (
                <FirstPageIcon fontSize="small" />
              )}
            </IconButton>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default InteractionContainerProfile;
