import Check from "@mui/icons-material/Check";
import Pause from "@mui/icons-material/Pause";
import { createChipData, IStatusChipData } from "ui-kit/atoms/Chip";
import { IWebhook, WebhookStatusColors, WebhookStatusLabels } from "../models";

const getWebhookActiveChipData = (
  active: IWebhook["active"]
): IStatusChipData => {
  if (active) {
    return createChipData(
      WebhookStatusColors.active,
      Check,
      WebhookStatusLabels.active
    );
  }
  return createChipData(
    WebhookStatusColors.paused,
    Pause,
    WebhookStatusLabels.paused
  );
};

const getWebhookStatusLabel = (
  active: IWebhook["active"]
): WebhookStatusLabels =>
  active ? WebhookStatusLabels.active : WebhookStatusLabels.paused;

const getWebhookStatusSnackbarType = (
  active: IWebhook["active"]
): WebhookStatusColors =>
  active ? WebhookStatusColors.active : WebhookStatusColors.paused;

const WebhookUtils = {
  getWebhookActiveChipData,
  getWebhookStatusLabel,
  getWebhookStatusSnackbarType,
};

export default WebhookUtils;
