import { getAffiliateId } from "core/utils/getAffiliateId";
import React, { createContext, useEffect, useMemo, useReducer } from "react";
import useUpdateAccount from "../hooks/useUpdateAccount";
import { IAccountCurrent } from "../models";
import { AccountActions, accountReducer } from "../reducers";
import { getCurrentAccount } from "../utils";

interface IProps {
  children: React.ReactElement;
}

export interface IAccountContext {
  account: IAccountCurrent;
  dispatch: React.Dispatch<AccountActions>;
}

const initialState = {
  account: getCurrentAccount(),
  dispatch: () => null,
};

export const AccountContext = createContext<IAccountContext>(initialState);

const AccountContextProvider = (props: IProps): React.ReactElement => {
  const [account, dispatch] = useReducer(accountReducer, initialState.account);
  const { children } = props;

  const { mutate: updateAccount } = useUpdateAccount();

  const affiliateId = useMemo(() => getAffiliateId(), []);

  useEffect(() => {
    if (affiliateId && !account.meta_data?.affiliate) {
      updateAccount({
        meta_data: { ...account.meta_data, affiliate: affiliateId },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [affiliateId]);

  return (
    <AccountContext.Provider value={{ account, dispatch }}>
      {children}
    </AccountContext.Provider>
  );
};

export default AccountContextProvider;
