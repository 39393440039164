import * as Yup from "yup";

export const ERROR_KEYS = {
  NAME: {
    TOO_SHORT: "First name is too short.",
    TOO_LONG: "First name is too long.",
    REQUIRED: "First name is required.",
  },
  EMAIL: {
    REQUIRED: "Email address is required.",
    INVALID: "Email address is invalid.",
  },
  PASSWORD: {
    TOO_SHORT: "Password requires at minimum 8 characters.",
    REQUIRED: "Password is required.",
  },
  PASSWORD_CONFIRM: {
    SHOULD_BE_EQUAL: "Passwords are not equal.",
    REQUIRED: "Password confirmation is required.",
  },
  TERMS: {
    REQUIRED: "Please indicate that you accept the Terms and Conditions.",
  },
};

const email = Yup.string()
  .required(ERROR_KEYS.EMAIL.REQUIRED)
  .email(ERROR_KEYS.EMAIL.INVALID);

const password = Yup.string()
  .required(ERROR_KEYS.PASSWORD.REQUIRED)
  .min(8, ERROR_KEYS.PASSWORD.TOO_SHORT);

const name = Yup.string()
  .required(ERROR_KEYS.NAME.REQUIRED)
  .min(2, ERROR_KEYS.NAME.TOO_SHORT)
  .max(50, ERROR_KEYS.NAME.TOO_LONG);

function signInSchema(): Yup.AnyObjectSchema {
  return Yup.object().shape({
    email,
    password,
  });
}

function signUpSchema(): Yup.AnyObjectSchema {
  return Yup.object().shape({
    first_name: name,
    email,
    password,
  });
}

function updateNameSchema(): Yup.AnyObjectSchema {
  return Yup.object().shape({
    first_name: name,
    last_name: name,
  });
}

function forgotPasswordSchema(): Yup.AnyObjectSchema {
  return Yup.object().shape({
    email,
  });
}

function verifyEmail(): Yup.AnyObjectSchema {
  return Yup.object().shape({
    email,
  });
}

function resetPasswordSchema(): Yup.AnyObjectSchema {
  return Yup.object().shape({
    new_password1: password,
    new_password2: Yup.string()
      .required(ERROR_KEYS.PASSWORD_CONFIRM.REQUIRED)
      .test(
        "passwords-match",
        ERROR_KEYS.PASSWORD_CONFIRM.SHOULD_BE_EQUAL,
        function test(value) {
          return this.parent.new_password1 === value;
        }
      ),
  });
}

const schemas = {
  signInSchema,
  signUpSchema,
  forgotPasswordSchema,
  resetPasswordSchema,
  updateNameSchema,
  verifyEmail,
};

export default schemas;
