import React from "react";
import { useQuery } from "react-query";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Grid from "@mui/material/Grid";
import { formatMinutesToHoursMinutes } from "core/utils/dateHandler";
import { LimitActionNames } from "modules/Report/models";
import InteractionService from "modules/Interaction/services";
import HelperTooltip from "ui-kit/components/HelperTooltip";

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    display: "flex",
    alignItems: "center",
  },
  iconBox: {
    marginRight: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 50,
    width: 50,
    borderRadius: 4,
  },
  icon: {
    color: "inherit",
    fontSize: 26,
  },
  connect: {
    color: "#8dc559",
    backgroundColor: "#F1F8ED",
  },
  message: {
    color: "#9c27b0",
    backgroundColor: "#F5EEF5",
  },
  time: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
  },
}));

interface AccountUsageProps {
  accountId: number;
}

const query = "";

const AccountUsage = ({ accountId }: AccountUsageProps): React.ReactElement => {
  const classes = useStyles();

  const fetchDataInvitations = async () => {
    try {
      const { data } = await InteractionService.fetchRequestCount(
        accountId,
        query
      );
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };
  const { data: dataInvitations } = useQuery(
    ["limits", accountId, query, LimitActionNames.invitation],
    () => fetchDataInvitations(),
    {
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: !!accountId,
    }
  );

  const fetchDataMessages = async () => {
    try {
      const { data } = await InteractionService.fetchMessagedCount(
        accountId,
        query
      );
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };
  const { data: dataMessages } = useQuery(
    ["limits", accountId, query, LimitActionNames.message],
    () => fetchDataMessages(),
    {
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: !!accountId,
    }
  );

  return (
    <Grid container spacing={4} sx={{ maxWidth: 800 }}>
      <Grid item xs={12} sm={4}>
        <div className={classes.box}>
          <div className={clsx(classes.iconBox, classes.connect)}>
            <PersonAddIcon className={classes.icon} />
          </div>
          <div>
            <Typography variant="body2" color="textSecondary" sx={{ mb: 0.5 }}>
              Invitations sent
            </Typography>
            <Typography variant="subtitle1" color="textPrimary">
              {dataInvitations?.count}
            </Typography>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} sm={4}>
        <div className={classes.box}>
          <div className={clsx(classes.iconBox, classes.message)}>
            <MailOutlineIcon className={classes.icon} />
          </div>
          <div>
            <Typography variant="body2" color="textSecondary" sx={{ mb: 0.5 }}>
              Follow-ups sent
            </Typography>
            <Typography variant="subtitle1" color="textPrimary">
              {dataMessages?.count}
            </Typography>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} sm={4}>
        <div className={classes.box}>
          <div className={clsx(classes.iconBox, classes.time)}>
            <AccessTimeIcon className={classes.icon} />
          </div>
          <div>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{
                mb: 0.5,
                display: "flex",
                alignItems: "center",
                "& > button": { padding: 0, paddingLeft: 1.5 },
              }}
            >
              Your time saved
              <HelperTooltip title="We estimate each invitations and follow-up message to take approximately 5 minutes to prepare, write and send." />
            </Typography>
            <Typography variant="subtitle1" color="textPrimary">
              {formatMinutesToHoursMinutes(
                Number(dataInvitations?.count) * 5 +
                  Number(dataMessages?.count) * 5
              )}
            </Typography>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default AccountUsage;
