import * as React from "react";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import { DefaultPlaceholdersLabels } from "core/models";
import { usePersonPlaceholderStyles } from "modules/Person/components/PersonDetail/utils";
import { DefaultPlaceholdersKeys } from "modules/Placeholder/models";

interface PersonPlaceholderBlankProps {
  placeholderKey: DefaultPlaceholdersKeys;
}

function PersonPlaceholderBlank({
  placeholderKey,
}: PersonPlaceholderBlankProps): React.ReactElement {
  const classes = usePersonPlaceholderStyles();

  return (
    <div className={classes.placeholder}>
      <Typography variant="caption" className={classes.label} component="p">
        {DefaultPlaceholdersLabels[placeholderKey]}
      </Typography>
      <Skeleton animation="wave" width={80} height={29} />
    </div>
  );
}

export default PersonPlaceholderBlank;
