import React from "react";
import { Control, FieldErrors } from "react-hook-form";
import { useQuery } from "react-query";
import AutocompleteField from "core/components/AutocompleteField";
import { getAutocompleteOptions } from "core/utils/commonHandler";
import SequenceService from "modules/Sequence/services";
import ConfigConstant from "core/constants/ConfigConstant";

interface SequenceSingleAutocompleteProps {
  control: Control;
  errors: FieldErrors;
}

const SequenceSingleAutocomplete = ({
  control,
  errors,
}: SequenceSingleAutocompleteProps): React.ReactElement => {
  const [search, setSearch] = React.useState(ConfigConstant.EMPTY_SEARCH);
  const fetchSequences = async () => {
    try {
      const { data } = await SequenceService.fetchAllTemplateSequences();
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };
  const { data, isLoading } = useQuery(["sequences-all", search], () =>
    fetchSequences()
  );

  return (
    <AutocompleteField
      control={control}
      errors={errors}
      options={getAutocompleteOptions(data?.results)}
      isLoading={isLoading}
      handleInputChange={(event, newInputValue) => {
        setSearch(newInputValue);
      }}
      label="Select template"
      name="sequence"
    />
  );
};

export default SequenceSingleAutocomplete;
