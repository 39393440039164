import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Theme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useAppBarStyles } from "core/routes/components/AppBar/utils";
import RouterConstants from "core/routes/constants";
import {
  errorHandler,
  handleCaptureMessage,
  handleInvalidLogout,
  IErrorResponse,
} from "core/utils/errorHandler";
import { snackbarHandler } from "core/utils/snackbarHandler";
import { AccountContext } from "modules/Account/context";
import { AccountActionType } from "modules/Account/reducers";
import { UserContext } from "modules/User/context";
import { UserActionType } from "modules/User/reducers";
import UserService from "modules/User/services";
import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { Link, useHistory } from "react-router-dom";

interface ProfileMenuProps {
  anchorEl: HTMLElement | null;
  setAnchorEl: (e: HTMLElement | null) => void;
}

const ProfileMenu = ({
  anchorEl,
  setAnchorEl,
}: ProfileMenuProps): React.ReactElement => {
  const classes = useAppBarStyles();
  const history = useHistory();
  const queryClient = useQueryClient();
  const desktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));
  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  const { dispatch: dispatchUser } = React.useContext(UserContext);
  const {
    dispatch: dispatchAccount,
    account: { id: accountId },
  } = React.useContext(AccountContext);
  const isActiveAccount = !!accountId;

  const handleLogout = () => {
    dispatchUser({ type: UserActionType.LOGOUT });
    dispatchAccount({ type: AccountActionType.LOGOUT });

    history.push(RouterConstants.ROOT);
    snackbarHandler.success("Logout successful.");
    queryClient.removeQueries();
  };

  const mutateLogout = useMutation(() => UserService.createLogout(), {
    onSuccess: () => {
      handleLogout();
      handleProfileMenuClose();
    },
    onError: (error: IErrorResponse) => {
      const result = handleInvalidLogout(error);

      if (!result) {
        errorHandler(error.response);
      }

      // Mock successful logout
      handleLogout();
    },
    onMutate: () => {
      handleCaptureMessage("Logout triggered.");
    },
  });

  const handleSignOut = () => mutateLogout.mutate();

  const profileMenuId = "profile-menu";
  return (
    <Menu
      anchorEl={anchorEl}
      id={profileMenuId}
      data-cy={profileMenuId}
      keepMounted
      autoFocus={false}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      open={!!anchorEl}
      onClose={handleProfileMenuClose}
      PaperProps={{
        elevation: 12,
        className: classes.paper,
      }}
    >
      {!desktop && !isActiveAccount && (
        <MenuItem
          component={Link}
          to={{
            pathname: RouterConstants.NOTIFICATION.ALL,
            search: `?accountId=${accountId}`,
          }}
          onClick={handleProfileMenuClose}
        >
          Notifications
        </MenuItem>
      )}
      {!isActiveAccount && (
        <MenuItem component={Link} to={RouterConstants.ACCOUNT.NEW_ROOT}>
          Add LinkedIn account
        </MenuItem>
      )}
      <Box>
        <MenuItem
          component={Link}
          to={RouterConstants.USER.DETAIL}
          onClick={handleProfileMenuClose}
        >
          User settings
        </MenuItem>

        <MenuItem
          component={Link}
          to={RouterConstants.COMPANY.ALL}
          onClick={handleProfileMenuClose}
        >
          Companies
        </MenuItem>
      </Box>
      <MenuItem
        component={Link}
        to={RouterConstants.BILLING.ALL}
        onClick={handleProfileMenuClose}
      >
        Billing
      </MenuItem>
      <Divider light sx={{ my: 1.5 }} />
      <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
    </Menu>
  );
};

export default ProfileMenu;
