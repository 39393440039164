import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import { useQuery } from "react-query";
import { formatDateTimeToFull } from "core/utils/dateHandler";
import RouterConstants from "core/routes/constants";
import ConfigConstant from "core/constants/ConfigConstant";
import { createTableCell } from "core/utils/tableHandler";
import { AccountTabs } from "modules/Account/models";
import { getAccountName } from "modules/Account/utils";
import CompanyService from "modules/Company/services";
import Table, { TABLE_ROWS_PER_PAGE } from "ui-kit/components/Table";
import TableBodyCell from "ui-kit/components/TableBodyCell";

const heads = [
  {
    id: "name",
    percentage: true,
    width: 25,
    label: "name",
  },
  {
    id: "name",
    percentage: true,
    width: 25,
    label: "login",
  },
  {
    id: "connections",
    percentage: true,
    width: 18,
    label: "Invitations range",
  },
  {
    id: "messages",
    percentage: true,
    width: 18,
    label: "Follow-ups range",
  },
  {
    id: "created",
    percentage: true,
    width: 14,
    label: "Added at",
  },
];

interface CompanyAccountListProps {
  companyId: number;
}

const CompanyAccountList = ({
  companyId,
}: CompanyAccountListProps): React.ReactElement => {
  const [page, setPage] = useState<number>(ConfigConstant.INITIAL_PAGE);
  const fetchCompanyAccounts = async (p: number) => {
    try {
      const { data } = await CompanyService.fetchCompanyAccounts(companyId, p);
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };

  const { data, isFetching } = useQuery(
    ["company-accounts", page, companyId],
    () => fetchCompanyAccounts(page),
    {
      keepPreviousData: true,
    }
  );

  return !data || data?.count ? (
    <Table
      title="LinkedIn accounts"
      heads={heads}
      rows={
        data
          ? data.results.map((account) => {
              const link = RouterConstants.ACCOUNT.detail(
                account.id,
                AccountTabs.limits
              );
              return {
                name: account.login,
                data: [
                  createTableCell(
                    getAccountName(account),
                    link,
                    "main",
                    TableBodyCell
                  ),
                  createTableCell(
                    account.login,
                    link,
                    "default",
                    TableBodyCell
                  ),
                  createTableCell(
                    `${account.restrictions.connection_requests_daily_range.start} -
                      ${account.restrictions.connection_requests_daily_range.start}`,
                    link,
                    "default",
                    TableBodyCell
                  ),
                  createTableCell(
                    `${account.restrictions.follow_up_messages.start} -
                      ${account.restrictions.follow_up_messages.start}`,
                    link,
                    "default",
                    TableBodyCell
                  ),
                  createTableCell(
                    formatDateTimeToFull(account.created),
                    link,
                    "default",
                    TableBodyCell
                  ),
                ],
              };
            })
          : []
      }
      count={data?.count || 0}
      // INITIAL_PAGE starts at 1, but Pagination starts at 0
      page={data?.page || page - 1}
      setPage={setPage}
      isFetching={isFetching}
      tableProps={{
        variant: "outlined",
      }}
      rowsPerPage={
        data && data.count < TABLE_ROWS_PER_PAGE
          ? data?.count
          : TABLE_ROWS_PER_PAGE
      }
    />
  ) : (
    <Typography variant="subtitle2" color="textSecondary">
      No LinkedIn accounts attached.
    </Typography>
  );
};

export default CompanyAccountList;
