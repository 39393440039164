/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import upperFirst from "lodash/upperFirst";
import clsx from "clsx";
import {
  lighten,
  Chip as MuiChip,
  ChipProps as MuiChipProps,
  emphasize,
} from "@mui/material";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

export interface IBaseStatusChipData {
  color: IChipColors;
  label: string;
}

export interface IStatusChipData extends IBaseStatusChipData {
  icon: React.FunctionComponent<any>;
}

export const createChipData = (
  color: IChipColors,
  icon: React.FunctionComponent<any>,
  label: string
): IStatusChipData => ({
  color,
  icon,
  label,
});

export enum ChipColors {
  "primary" = "primary",
  "secondary" = "secondary",
  "error" = "error",
  "success" = "success",
  "warning" = "warning",
  "info" = "info",
}

export type IChipColors =
  | "primary"
  | "secondary"
  | "error"
  | "success"
  | "warning"
  | "info"
  | undefined;

const colorExtension = [
  "primary",
  "secondary",
  "error",
  "success",
  "warning",
  "info",
] as const;

const useStyles = makeStyles((theme: Theme) => ({
  deletable: {},
  clickable: {},
  root: {
    height: 18,
    backgroundColor: theme.palette.common.white,
    borderRadius: 4,
    flexDirection: "row-reverse",
  },
  colorDefault: {
    color: "inherit",
    borderColor: "inherit",
  },
  icon: {
    marginRight: "4px !important",
    marginLeft: "-4px !important",
    color: "inherit !important",
    fontSize: "16px !important",
  },
  label: {
    fontSize: "0.675rem",
    fontWeight: 500,
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
  noLabel: {
    padding: 0,
  },
  ...colorExtension
    .map((color) => {
      const name = upperFirst(color);
      return {
        [`color${name}`]: {
          backgroundColor: theme.palette[color].light, // main
          color: theme.palette[color].main, // contrastText
        },
        [`outlined${name}`]: {
          color: theme.palette[color].main,
          border: `1px solid ${lighten(theme.palette[color].main, 0.85)}`,
          backgroundColor: lighten(
            theme.palette[color].main,
            1 - theme.palette.action.hoverOpacity
          ),
          "$clickable:hover, $clickable&:hover, $clickable&:focus, $deletable&:focus":
            {
              backgroundColor: lighten(
                theme.palette[color].main,
                1 - theme.palette.action.hoverOpacity
              ),
            },
          "&.MuiChip-clickable:hover": {
            backgroundColor: lighten(
              theme.palette[color].main,
              1 - theme.palette.action.hoverOpacity
            ),
            border: `1px solid ${lighten(
              theme.palette[color].main,
              1 - 6 * theme.palette.action.hoverOpacity
            )}`,
          },
        },
        [`$clickable, clickableColor${name}`]: {
          "&:hover, &:focus": {
            backgroundColor: emphasize(theme.palette[color].light, 0.08),
          },
        },
      };
    })
    .reduce((acc, value) => ({ ...acc, ...value }), {}),
}));

export type ChipProps = Omit<MuiChipProps, "color" | "icon"> & {
  color?: MuiChipProps["color"] | typeof colorExtension[number];
  icon?: React.ReactElement;
};

export const Chip: React.FC<ChipProps> = React.forwardRef(
  ({ color, className, icon, label, ...rest }: ChipProps, ref) => {
    const classes: any = useStyles();
    const name = upperFirst(color || "default");
    const classNames = clsx(className, {
      [classes[`outlined${name}`]]: rest.variant === "outlined",
      [classes[`color${name}`]]: rest.variant !== "outlined",
      [classes[`clickableColor${name}`]]: rest.clickable,
    });
    const coreClasses = {
      root: classes.root,
      icon: classes.icon,
      label: clsx({
        [classes.label]: true,
        [classes.noLabel]: !label,
      }),
    };
    const isCustomColor = colorExtension.find((c) => c === color);
    return (
      <MuiChip
        ref={ref}
        className={classNames}
        component="span"
        classes={coreClasses}
        color={isCustomColor ? undefined : (color as MuiChipProps["color"])}
        icon={icon}
        label={label}
        {...rest}
      />
    );
  }
);

const useWrapperStyles = makeStyles({
  root: {
    float: "right",
    "& > *": {
      margin: "2px 2px",
    },
  },
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function ChipWrapper(props: any): React.ReactElement {
  const classes = useWrapperStyles();
  return <div className={classes.root} {...props} />;
}
