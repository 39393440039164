import React from "react";
import { useParams } from "react-router-dom";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { GlobalActionType } from "core/reducers";
import { GlobalContext } from "core/context";
import { snackbarHandler } from "core/utils/snackbarHandler";
import PlaceholderList from "modules/Placeholder/pages/PlacehodlerList";
import { MyNetworkTabs } from "modules/Person/models";
import ContactList from "modules/Person/components/ContactList";
import AddSelectedPeopleToCampaign from "modules/Campaign/components/AddSelectedPeopleToCampaign";
import CardTabs, { createTabs } from "ui-kit/components/CardTabs";
import PageHeader from "ui-kit/components/PageHeader";
import Button from "ui-kit/atoms/Button";

type ParamTypes = {
  tabName: MyNetworkTabs;
};

export const MYNETWORK_TABS = [
  createTabs(MyNetworkTabs.contacts, ContactList),
  createTabs(MyNetworkTabs.placeholders, PlaceholderList),
];

const MyNetwork = (): React.ReactElement => {
  const { tabName } = useParams<ParamTypes>();
  const [openCampaignDialog, toggleCampaignDialog] = React.useState(false);
  const {
    dispatch,
    global: { selected },
  } = React.useContext(GlobalContext);

  const resetSelected = () => {
    dispatch({
      type: GlobalActionType.SET_GLOBAL,
      payload: { selected: [] },
    });
  };

  const handleClose = () => {
    toggleCampaignDialog(false);
    resetSelected();
  };

  const handleToggleCampaignDialog = () => {
    if (selected.length === 0) {
      return snackbarHandler.warning("Please select at least 1 person.");
    }
    return toggleCampaignDialog(true);
  };

  const actions = (
    <>
      {tabName === MyNetworkTabs.contacts && (
        <Button
          variant="outlined"
          color="inherit"
          size="small"
          type="button"
          startIcon={<ControlPointIcon />}
          onClick={handleToggleCampaignDialog}
        >
          Assign to campaign
        </Button>
      )}
    </>
  );

  return (
    <>
      <PageHeader
        title="My Network"
        hiddenDivider
        filterProps
        selectedActions={actions}
      />
      <CardTabs isLoading={false} tabs={MYNETWORK_TABS} />
      <AddSelectedPeopleToCampaign
        persons={selected.map((x) => Number(x.name))}
        open={openCampaignDialog}
        handleClose={handleClose}
      />
    </>
  );
};

export default MyNetwork;
