import { withStyles } from "@mui/styles";
import MuiCheckbox from "@mui/material/Checkbox";
import { Theme } from "@mui/material/styles";

const Checkbox = withStyles((theme: Theme) => ({
  root: {
    position: "relative",
    zIndex: 0,
    color: theme.app.palette.action.check,
    padding: theme.spacing(1),
    "&:not(checked):after": {
      content: '""',
      left: 7,
      top: 7,
      height: 14,
      width: 14,
      position: "absolute",
      backgroundColor: "white",
      zIndex: -1,
    },
  },
  checked: {},
}))(MuiCheckbox);

export default Checkbox;
