import {
  Box,
  Card,
  CircularProgress,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import CampaignService from "modules/Campaign/services";
import { getFilledPercentage } from "modules/Report/utils";
import { SEARCH_STRATEGY_MAX_RESULTS } from "modules/Search/constants";
import {
  ISearch,
  SearchMatchVariantStrategy,
  SearchStatusMatchToVariant,
} from "modules/Search/models";
import SearchUtils from "modules/Search/utils";
import React from "react";
import { useQuery } from "react-query";

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    display: "flex",
    alignItems: "center",
  },
  progressBar: {
    maxWidth: 300,
    borderRadius: 50,
    height: theme.spacing(2),
    backgroundColor: theme.palette.action.focus,
    width: "100%",
  },
  filledBar: {
    backgroundColor: theme.palette.secondary.main,
    height: theme.spacing(2),
    borderRadius: 50,
  },
  subtitle: {
    color: theme.app.palette.action.main,
    fontSize: 13,
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

interface SearchLoaderProps {
  search: ISearch;
  campaignId: number;
}

const SearchLoader = ({
  search,
  campaignId,
}: SearchLoaderProps): React.ReactElement => {
  const classes = useStyles();

  const fetchInteractions = async () => {
    try {
      const { data } =
        await CampaignService.fetchCampaignInteractionsCountPerSearch(
          campaignId,
          search.id
        );
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };

  const { data } = useQuery(
    ["search-result-count", campaignId, { searchId: search.id }],
    () => fetchInteractions(),
    {
      keepPreviousData: true,
      enabled: !!campaignId,
      // Refetch every 10 seconds as loader only runs for processing search
      refetchInterval: 10000,
    }
  );

  const count = data?.count || 0;
  const totalMax = SEARCH_STRATEGY_MAX_RESULTS[search.strategy];
  const maxCount = SearchUtils.getMaxAvailableSearchResultCount(
    totalMax,
    search.strategy_data.total
  );

  const searchInQueue = search.status === SearchStatusMatchToVariant.queue;

  return (
    <>
      <Card sx={{ mb: 4 }}>
        <ListItem>
          <ListItemIcon>
            <CircularProgress color="secondary" size={32} />
          </ListItemIcon>
          <ListItemText
            sx={{ maxWidth: 340, mr: 3 }}
            primaryTypographyProps={{
              sx: {
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              },
            }}
            primary={search.name}
            secondary={SearchMatchVariantStrategy[search.strategy]}
          />
          <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
            <Typography variant="body2" color="dimmed" mb={0.75}>
              {searchInQueue
                ? `We are loading your search results...`
                : `${count.toLocaleString()} of ${maxCount.toLocaleString()} prospects are ready...`}
            </Typography>
            <div className={classes.progressBar}>
              <div
                className={classes.filledBar}
                style={{
                  width: getFilledPercentage(count, maxCount),
                }}
              />
            </div>
          </Box>
        </ListItem>
      </Card>
      <Divider light sx={{ mb: 3 }} />
    </>
  );
};

export default SearchLoader;
