import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { Theme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import AutocompleteOption from "core/components/AutocompleteOption";
import { errorHandler, IErrorResponse } from "core/utils/errorHandler";
import { snackbarHandler } from "core/utils/snackbarHandler";
import { AccountContext } from "modules/Account/context";
import { IContact, ITagsArray } from "modules/Person/models";
import PersonService from "modules/Person/services";
import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";

const useStyles = makeStyles((theme: Theme) => ({
  inputRoot: {
    display: "block",
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: `2px solid ${theme.app.palette.action.placeholder}`,
    },
    "&:before": {
      borderBottom: `1px solid ${theme.app.palette.shadow.primary}`,
    },
  },
  input: {
    fontSize: "0.8rem !important",
    color: theme.app.palette.text.secondary,
    width: "100% !important",
  },
}));
interface OptionType {
  inputValue?: string;
  id?: number;
  name: string;
}

interface TagsAutocompleteProps {
  contact: IContact;
}

const filter = createFilterOptions<OptionType>();

const name = "tags";

const getTags = (tags: string[]) =>
  tags.map((tag, index) => ({
    id: index,
    name: tag,
  }));

function TagsAutocomplete({
  contact,
}: TagsAutocompleteProps): React.ReactElement {
  const classes = useStyles();
  const { id: contactId } = contact;
  const queryClient = useQueryClient();

  const {
    account: { id: accountId },
  } = React.useContext(AccountContext);

  const fetchTags = async () => {
    try {
      const { data } = await PersonService.fetchUniqueTags();
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };

  const { data, isLoading } = useQuery(["tags-all"], () => fetchTags(), {
    keepPreviousData: true,
    refetchOnReconnect: false,
    refetchOnMount: false,
  });

  const { control, reset } = useForm({
    defaultValues: {
      tags: getTags(contact.tags),
    },
  });

  // Not on the first update
  const firstUpdate = React.useRef(true);
  // Reset default values on contact change
  React.useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    const newTags = getTags(contact.tags);
    reset({ tags: newTags });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact.tags]);

  const options: OptionType[] = data?.count ? data?.results : [];

  const mutateOnChange = useMutation(
    (newData: ITagsArray) => PersonService.updateContact(contactId, newData),
    {
      onSuccess: () => {
        snackbarHandler.success("Tags updated!");

        queryClient.invalidateQueries([
          "contact",
          contact.person.id,
          accountId,
        ]);
        queryClient.invalidateQueries(["tags-all"]);
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);
      },
    }
  );

  return (
    <Controller
      render={(_props: { onChange: (newData: OptionType[]) => void }) => (
        <Autocomplete
          {..._props}
          multiple
          onChange={(event, newValue) => {
            const tags: string[] = [];
            const values: OptionType[] = [];
            newValue.forEach((newTag) => {
              if (typeof newTag === "string") {
                return;
              }
              if (newTag && newTag.inputValue) {
                tags.push(newTag.inputValue);
                values.push(newTag);
                return;
              }
              tags.push(newTag.name);
              values.push(newTag);
            });

            _props.onChange(values);
            mutateOnChange.mutate({ tags });
          }}
          renderOption={(optionProps, option) => (
            <AutocompleteOption
              key={option.name}
              props={optionProps}
              option={option}
            />
          )}
          loading={isLoading}
          filterOptions={(o, params) => {
            const filtered = filter(o, params);

            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = o.some((option) => inputValue === option.name);
            if (inputValue !== "" && !isExisting) {
              filtered.push({
                inputValue,
                name: `Add "${inputValue}"`,
              });
            }

            const cleanCurrentOptions = filtered.filter(
              (op) => !contact.tags.includes(op.name)
            );

            return cleanCurrentOptions;
          }}
          autoHighlight
          clearOnBlur
          disableClearable
          handleHomeEndKeys
          id="tags-autocomplete"
          options={options}
          getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === "string") {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
              return option.inputValue;
            }

            // Regular option
            return option.name;
          }}
          openOnFocus
          freeSolo
          classes={{
            inputRoot: classes.inputRoot,
          }}
          blurOnSelect
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                classes: {
                  input: classes.input,
                },
              }}
              variant="standard"
              placeholder="Add tag"
            />
          )}
        />
      )}
      id={name}
      name={name}
      control={control}
    />
  );
}

export default TagsAutocomplete;
