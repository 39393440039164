import React from "react";

const appbarHeight = 55;
const menuBarHeight = 40;
const trialBarHeight = 52;
const inboxHeaderHeight = 48;
const filterHeight = 60; // Single-row filter height
const inboxPageHeight = 99; // Inbox page above filter
const campaignPageHeight = 110; // Campaign header

const constants = {
  logoSize: "26px",
  appbarHeight,
  menuBarHeight,
  trialBarHeight,
  authbarHeight: 83,
  contentBottom: 20,
  action: {
    half: 250,
    full: 500,
  },
  filter: {
    topMargin: 15,
  },
  dialog: {
    campaignWidth: 1080,
  },
  inputMaxWidth: 340,
  drawerWidth: 250,
  drawerPaddingRight: 16,
  fullDropdownWidth: 280,
  dropdownWidth: 200,
  dropdownWidthMobile: 160,
  profileWidth: 400,
  inbox: {
    boxHeight: `calc(100vh - ${appbarHeight}px - ${menuBarHeight}px - ${filterHeight}px - ${inboxPageHeight}px)`,
    inboxHeight: `calc(100vh - ${appbarHeight}px - ${menuBarHeight}px - ${filterHeight}px - ${inboxPageHeight}px - ${inboxHeaderHeight}px)`,
    searchHeight: 68.85,
    headerHeight: inboxHeaderHeight,
    filterHeight: 150,
    contactWidth: 300,
    detailWidth: 260,
  },
  profileDetail: {
    activeCampaign: 95,
    interactionList: 75,
    placeholderList: 110,
  },
  personSidebar: {
    header: 105,
  },
  campaign: {
    interactions: {
      tableWidth: 500,
      boxHeight: `calc(100vh - ${appbarHeight}px - ${menuBarHeight}px - ${filterHeight}px - ${campaignPageHeight}px)`,
      tableHeight: `calc(100vh - ${appbarHeight}px - ${menuBarHeight}px - ${filterHeight}px - ${inboxHeaderHeight}px - ${campaignPageHeight}px - 10px)`,
      inboxHeight: `calc(100vh - ${appbarHeight}px - ${menuBarHeight}px - ${filterHeight}px - ${inboxHeaderHeight}px - ${campaignPageHeight}px)`,
    },
  },
  report: {
    box: 358.5,
  },
  scrollbar: {
    "&::-webkit-scrollbar": {
      "-webkit-appearance": "none",
      width: 6,
      height: 6,
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "4px",
      backgroundColor: "rgba(0, 0, 0, 0.4)",
      boxShadow: "0 0 1px rgba(255, 255, 255, 0.4)",
    },
  },
  sequence: {
    area: 800,
  },
  table: {
    headerHeight: 40,
    bodyHeight: 50,
    bodyWidth: 800,
    columnMobileWidth: 200,
    scrollableDiv: {
      backgroundImage:
        "linear-gradient(to top, white, white), linear-gradient(to top, white, white), linear-gradient(to top, rgba(0, 0, 0, 0.15), rgba(255, 255, 255, 0)), linear-gradient(to bottom, rgba(0, 0, 0, 0.15), rgba(255, 255, 255, 0))",
      backgroundPosition:
        "bottom center, top center, bottom center, top center",
      backgroundColor: "white",
      backgroundRepeat: "no-repeat",
      backgroundSize: "100% 10px, 100% 10px, 100% 8px, 100% 8px",
      backgroundAttachment: "local, local, scroll, scroll",
    },
  },
  textOneLiner: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  } as React.CSSProperties,
  dateRangePicker: {
    button: 190,
  },
};

export default constants;
