import React from "react";
import { useForm } from "react-hook-form";
import get from "lodash/get";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQueryClient, useMutation } from "react-query";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import ElementField from "core/components/ElementField";
import { snackbarHandler } from "core/utils/snackbarHandler";
import {
  errorHandler,
  handleNonFieldErrors,
  IErrorResponse,
} from "core/utils/errorHandler";
import {
  ITeamInviteFormInput,
  ITeamInviteFormValues,
  ITeamInvitesFormValues,
  TeamRoles,
} from "modules/Team/models";
import TeamService from "modules/Team/services";
import TeamValidations from "modules/Team/validations";
import DialogHeader from "ui-kit/components/DialogHeader";
import Button from "ui-kit/atoms/Button";
import FormField from "ui-kit/components/FormField";
import { Typography } from "@mui/material";
import ConfigConstant from "core/constants/ConfigConstant";

interface CreateOwnerInvitationProps {
  open: boolean;
  toggleOpen: (open: boolean) => void;
  accountId: number;
}

const defaultValues = {
  invitee_by_email: "",
};

const CreateOwnerInvitation = ({
  open,
  toggleOpen,
  accountId,
}: CreateOwnerInvitationProps): React.ReactElement => {
  const queryClient = useQueryClient();

  const schema = TeamValidations.requestFormSchema();

  const { control, errors, handleSubmit, setError, reset } =
    useForm<ITeamInviteFormInput>({
      resolver: yupResolver(schema),
      defaultValues,
    });

  const handleClose = () => {
    toggleOpen(false);
    reset();
  };

  const mutateInvites = useMutation(
    (data: ITeamInvitesFormValues) => TeamService.createTeamInvites(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["invites"]);
        snackbarHandler.success(`Request successfully sent!`);
        handleClose();
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response, setError);

        // Custom error handling for non_field_errors
        const nonFieldErrors = get(
          error.response,
          "data.items[0].non_field_errors"
        );
        handleNonFieldErrors(nonFieldErrors);
      },
    }
  );

  const onSubmit = (data: ITeamInviteFormInput) => {
    const emails = data?.invitee_by_email.replace(/ /g, "").split(",");

    // Create invites from all emails, accounts
    const invites: ITeamInvitesFormValues = {
      items: [],
    };

    // Iterate over each email and every selected account
    emails.forEach((email) => {
      const invitation: ITeamInviteFormValues = {
        invitee_by_email: email,
        account: accountId,
        role: TeamRoles.MA,
        notify_by_email: true,
        note: `Please add a seat at ${ConfigConstant.BASE_URL}/payment/${accountId}.`,
      };

      invites.items.push(invitation);
    });

    // Bulk create invites
    mutateInvites.mutate(invites);
  };

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleClose}
      aria-labelledby="create-invitation"
      maxWidth="sm"
      PaperProps={{ sx: { width: "100%" } }}
    >
      <form autoComplete="off">
        <DialogHeader
          title="Request access to your team"
          onHandleClose={handleClose}
        />

        <DialogContent dividers>
          <Typography variant="body2" paragraph>
            You may need to ask at your organization for the email of your team
            owner.
          </Typography>
          <FormField text="Enter team owner email address">
            <ElementField
              InputProps={{
                fullWidth: true,
                id: "invitee_by_email",
                label: "Email address",
                placeholder: "ex. team@example.com",
                variant: "outlined",
                autoComplete: "new-password",
              }}
              name="invitee_by_email"
              control={control}
              error={errors.invitee_by_email}
            />
          </FormField>
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            onClick={handleSubmit(onSubmit)}
            variant="contained"
            color="primary"
          >
            Send request
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateOwnerInvitation;
