/* eslint-disable no-nested-ternary */
import React from "react";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import { TableCellProps } from "core/utils/tableHandler";

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    position: "relative",
    whiteSpace: "nowrap",
    // WebkitLineClamp: 2,
    // display: "-webkit-box",
    // WebkitBoxOrient: "vertical",
    maxHeight: 38,
    padding: theme.spacing(0),
    fontSize: "0.825rem",
  },
  pointer: {
    cursor: "pointer",
  },
  hidden: {
    [theme.breakpoints.down("md")]: {
      visibility: "hidden",
    },
  },
  main: {
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
  default: {
    color: "inherit",
  },
  action: {},
  element: {
    maxHeight: 52,
    overflow: "visible",
  },
  internal: {
    color: "inherit",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  mainSecondChild: {
    paddingLeft: theme.spacing(1),
  },
}));

interface TableBodyCellProps extends TableCellProps {
  className: string;
  index: number;
}

const TableBodyCell = ({
  className,
  content,
  payload,
  type,
  index,
}: TableBodyCellProps): React.ReactElement => {
  const classes = useStyles();
  const history = useHistory();

  const handleOnClick = () => {
    if (!payload) {
      return undefined;
    }
    if (typeof payload === "string") {
      return history.push(payload);
    }
    return payload();
  };

  const cellProps = {
    onClick: handleOnClick,
    className: clsx(className, {
      [classes.pointer]: !!payload,
      // Add different class to the main column if first is checkbox
      [classes.mainSecondChild]: type === "main" && index === 1,
    }),
  };

  return (
    <TableCell {...cellProps} variant="body">
      <div className={clsx(classes.link, classes[type])}>
        <span>{content}</span>
      </div>
    </TableCell>
  );
};

export default TableBodyCell;
