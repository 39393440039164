import React from "react";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { PlaceholderMenuProps } from "modules/Action/models";
import AccountPlaceholderMenu from "./AccountPlaceholderMenu";
import ContactPlaceholderMenu from "./ContactPlaceholderMenu";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: theme.spacing(3),
  },
  row: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  label: {
    color: theme.app.palette.action.color,
    marginRight: theme.spacing(2),
    fontSize: 12,
    width: 46,
  },
}));

const PlaceholderMenu = (props: PlaceholderMenuProps): React.ReactElement => {
  const { handleOnSelect } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <Typography variant="body2" className={classes.label}>
          Contact
        </Typography>
        <ContactPlaceholderMenu {...props} />
      </div>
      <div className={classes.row}>
        <Typography variant="body2" className={classes.label}>
          Sender
        </Typography>
        <AccountPlaceholderMenu handleOnSelect={handleOnSelect} />
      </div>
    </div>
  );
};

export default PlaceholderMenu;
