import React from "react";
import { ReportNames } from "modules/Report/models";
import InteractionService from "modules/Interaction/services";
import { createCountReport } from "modules/Report/utils";
import CampaignCompareItem from "modules/Interaction/components/InteractionCompareItem";

interface CampaignResponseRateProps {
  campaignId: number;
  sequenceId: Number;
}

const CampaignResponseRate = ({
  campaignId,
  sequenceId,
}: CampaignResponseRateProps): React.ReactElement => (
  <CampaignCompareItem
    query={`&campaign_id=${campaignId}&sequence_id=${sequenceId}`}
    items={[
      createCountReport(
        ReportNames.message,
        "Prospects contacted",
        InteractionService.fetchRequestOrMessageDistinctCount
      ),
      createCountReport(
        ReportNames.reply,
        "Replies received",
        InteractionService.fetchRepliesCount
      ),
    ]}
  />
);

export default CampaignResponseRate;
