import { CampaignLocale } from "modules/Campaign/models";

function arrayMove(
  arr: (undefined | [])[],
  oldIndex: number,
  newIndex: number
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any[] {
  if (newIndex >= arr.length) {
    let k = newIndex - arr.length + 1;
    // eslint-disable-next-line no-plusplus
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr.map((a, index) => ({ ...a, order_nr: index + 1 }));
}

const defaultDraftAction = {
  previewMode: false,
  previewContactName: undefined,
  campaignId: undefined,
  personId: undefined,
  dynamicPlaceholder: undefined,
  locale: CampaignLocale.en,
  placeholders: undefined,
};

export { arrayMove, defaultDraftAction };
