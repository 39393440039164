import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import clsx from "clsx";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  DraggableProvided,
  DroppableProvided,
  DraggableStateSnapshot,
} from "react-beautiful-dnd";
import { makeStyles, useTheme } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import { errorHandler, IErrorResponse } from "core/utils/errorHandler";
import { snackbarHandler } from "core/utils/snackbarHandler";
import { IActions, IActionMove } from "modules/Action/models";
import { arrayMove } from "modules/Action/utils";
import ActionService from "modules/Action/services";
import Loader from "ui-kit/components/Loader";
import ActionListItem from "../ActionListItem";
import CreateAction from "../CreateAction";
import TimeDeltaAction from "../TimeDeltaAction";
import LastStaticAction from "../LastStaticAction";

const useStyles = makeStyles((theme: Theme) => ({
  timeline: {
    padding: theme.spacing(10, 0),
    margin: theme.spacing(-6, 0, 0),
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
  },
  item: {
    "&:before": {
      padding: 0,
    },
  },
  separator: {
    flex: "auto",
    width: "100%",
  },
  droppableArea: {
    maxWidth: theme.app.constants.sequence.area + 80,
    width: "100%",
    margin: theme.spacing(0, "auto"),
  },
  arrow: {
    color: theme.palette.grey[400],
    margin: theme.spacing(-2, "auto", -1),
    position: "relative",
    zIndex: 4,
    fontSize: 14,
    lineHeight: 1,
  },
  visible: {
    visibility: "visible",
  },
  hidden: {
    visibility: "hidden",
  },
}));

const queryName = "actions";

interface ActionListProps {
  sequenceId: number | undefined;
  campaignId?: number;
}

const ActionList = ({
  sequenceId,
  campaignId,
}: ActionListProps): React.ReactElement | null => {
  const classes = useStyles();
  const theme: Theme = useTheme();
  const queryClient = useQueryClient();
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const handleOpen = (i: number) => setOpenIndex(i);
  const handleClose = () => setOpenIndex(null);

  const fetchActions = async () => {
    try {
      const { data } = await ActionService.fetchActions(Number(sequenceId));
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };
  const { data: dataActions, isLoading: isLoadingActions } = useQuery(
    [queryName, sequenceId],
    () => fetchActions(),
    {
      enabled: !!sequenceId,
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const mutateMoveAction = useMutation(
    (mutationData: IActionMove) => ActionService.moveAction(mutationData),
    {
      onMutate: async (mutationData) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries([queryName, sequenceId]);

        // Snapshot the previous value
        const previousActions: IActions | undefined = queryClient.getQueryData([
          queryName,
          sequenceId,
        ]);

        // Optimistically update to the new value
        const { order_nr: destinationIndex, sourceIndex } = mutationData;

        // Optimistically update to the new value
        queryClient.setQueryData(
          [queryName, sequenceId],
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (old: any) => {
            const results = arrayMove(
              old.results,
              sourceIndex - 1,
              destinationIndex - 1
            );
            const arr = { ...old, results };
            return arr;
          }
        );

        // Return a context object with the snapshotted value
        return { previousActions };
      },
      onSuccess: () => {
        snackbarHandler.success("Action moved!");
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);
      },
      onSettled: () => {
        queryClient.invalidateQueries([queryName, sequenceId]);
      },
    }
  );

  // Fail-safe if no sequence added
  if (!sequenceId) {
    return null;
  }

  const createActionProps = {
    open: openIndex,
    handleOpen,
    handleClose,
    sequenceId,
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return false;
    }

    if (result.destination.index === result.source.index) {
      return false;
    }

    const actionId: number = +result.draggableId;
    const mutationData = {
      id: actionId,
      order_nr: result.destination.index,
      sourceIndex: result.source.index,
    };
    return mutateMoveAction.mutate(mutationData);
  };

  const content = (
    <Timeline sx={{ marginTop: 7 }}>
      <TimelineItem className={classes.item}>
        <TimelineSeparator classes={{ root: classes.separator }}>
          <CreateAction
            index={0}
            count={dataActions?.count}
            {...createActionProps}
          />
        </TimelineSeparator>
      </TimelineItem>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable" direction="vertical">
          {(droppableProvided: DroppableProvided) => (
            <div
              className={classes.droppableArea}
              ref={droppableProvided.innerRef}
              {...droppableProvided.droppableProps}
            >
              {dataActions?.results.map((action, index) => (
                <Draggable
                  key={action.id}
                  draggableId={action.id.toString()}
                  index={action.order_nr}
                >
                  {(
                    draggableProvided: DraggableProvided,
                    snapshot: DraggableStateSnapshot
                  ) => {
                    const isDraggingClass = {
                      className:
                        classes[snapshot.isDragging ? "hidden" : "visible"],
                    };
                    return (
                      <TimelineItem className={classes.item}>
                        <TimelineSeparator
                          classes={{ root: classes.separator }}
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                          style={{
                            ...draggableProvided.draggableProps.style,
                            borderRadius: 5,
                            background: snapshot.isDragging
                              ? theme.palette.primary.light
                              : "none",
                          }}
                        >
                          <TimelineConnector {...isDraggingClass} />
                          <div
                            className={clsx(
                              classes.arrow,
                              isDraggingClass.className
                            )}
                          >
                            ▼
                          </div>
                          <ActionListItem
                            action={action}
                            sequenceId={sequenceId}
                            draggableProps={draggableProvided.dragHandleProps}
                          />
                          <TimelineConnector {...isDraggingClass} />
                          <TimeDeltaAction
                            action={action}
                            sequenceId={sequenceId}
                          />
                          <TimelineConnector {...isDraggingClass} />
                          <div {...isDraggingClass}>
                            <CreateAction
                              index={index + 1}
                              count={dataActions?.count}
                              {...createActionProps}
                            />
                          </div>
                        </TimelineSeparator>
                      </TimelineItem>
                    );
                  }}
                </Draggable>
              ))}
              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <LastStaticAction />
    </Timeline>
  );

  return isLoadingActions ? <Loader minHeight={400} /> : content;
};

export default ActionList;
