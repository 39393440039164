import { ISignInResponse, IUser, IUserProfileInput } from "../models";
import { storeUser, setToken, removeToken, anonymousUser } from "../utils";

export enum UserActionType {
  SET_USER_DATA,
  SET_USER_TOKEN,
  SET_ONLY_TOKEN,
  LOGOUT,
}

export type UserActions =
  | {
      type: UserActionType.SET_USER_TOKEN;
      payload: ISignInResponse;
    }
  | {
      type: UserActionType.SET_ONLY_TOKEN;
      payload: { token: string };
    }
  | {
      type: UserActionType.SET_USER_DATA;
      payload: IUserProfileInput;
    }
  | { type: UserActionType.LOGOUT };

export const userReducer = (state: IUser, action: UserActions): IUser => {
  switch (action.type) {
    case UserActionType.SET_USER_TOKEN: {
      const newState = {
        ...state,
        ...action.payload.profile,
        token: action.payload?.token || state.token,
        anonymous: false,
      };

      storeUser(newState);
      setToken(action.payload?.token);
      return newState;
    }

    case UserActionType.SET_ONLY_TOKEN: {
      const newState = {
        ...state,
        token: action.payload?.token || state.token,
        anonymous: false,
      };

      setToken(action.payload?.token);
      return newState;
    }

    case UserActionType.SET_USER_DATA: {
      const newState = {
        ...state,
        ...action.payload,
      };

      storeUser(newState);
      return newState;
    }
    case UserActionType.LOGOUT: {
      storeUser(undefined);
      removeToken();
      return anonymousUser;
    }
    default:
      return state;
  }
};
