import React from "react";
import ActionSelector from "ui-kit/components/ActionSelector";
import { CreateSequenceKeys } from "modules/Sequence/models";
import { createActionSelectorOptions } from "core/utils/componentsHandler";

interface SequenceActionSelectorProps {
  checked: CreateSequenceKeys;
  setChecked: (id: CreateSequenceKeys) => void;
}

const options = [
  createActionSelectorOptions(
    "createNew",
    "Blank",
    "Create a new set of steps."
  ),
  createActionSelectorOptions(
    "copyExisting",
    "Template",
    "Use a template to pre-compose the steps."
  ),
];

const SequenceActionSelector = ({
  checked,
  setChecked,
}: SequenceActionSelectorProps): React.ReactElement => (
  <ActionSelector checked={checked} setChecked={setChecked} options={options} />
);

export default SequenceActionSelector;
