import { getUser } from "modules/User/utils";

const localeUses24HourTime = () =>
  !new Date().toLocaleTimeString().match(/am|pm/i);

// eslint-disable-next-line @typescript-eslint/naming-convention
const locale_24 = getUser()?.frontend_state?.locale_24;
// Fetch locale_24h from the user settings if defined, otherwise get browser locale
const LOCALE_24H = locale_24 !== undefined ? locale_24 : localeUses24HourTime();

const TIME_FORMAT = LOCALE_24H ? "HH:mm" : "hh:mm A";

const DateConstant = {
  DATE_FORMAT: "YYYY-MM-DD",
  DATE_PRETTY_FORMAT: "ddd, MMM D",
  DATE_SIMPLE_PRETTY_FORMAT: "MMM D",
  DATE_PRETTY_FORMAT_YEAR: "MMM D, YYYY",
  TIME_FORMAT,
  TIME_FORMAT_24: "HH:mm",
  DATE_FULL_FORMAT: `MMM DD, YYYY`,
  DATE_TIME_FULL_FORMAT: `MMM DD, ${TIME_FORMAT}`, // MMM DD, hh:mm (A)
  DATE_TIME_FULL_YEAR_FORMAT: `MMM DD, YYYY, ${TIME_FORMAT}`, // "MMM DD, YYYY, hh:mm (A)"
  LOCALE_24H,
};

export default DateConstant;
