import { yupResolver } from "@hookform/resolvers/yup";
import ElementField from "core/components/ElementField";
import RouterConstants from "core/routes/constants";
import { errorHandler, IErrorResponse } from "core/utils/errorHandler";
import { snackbarHandler } from "core/utils/snackbarHandler";
import AuthForm from "modules/User/components/AuthForm";
import { IPasswordChange, IResetPasswordFormValues } from "modules/User/models";
import UserService from "modules/User/services";
import UserValidation from "modules/User/validations";
import React from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { Link, useHistory, useParams } from "react-router-dom";
import PasswordField from "ui-kit/components/PasswordField";

type ParamTypes = {
  uid: string;
  token: string;
};

const ResetPassword = (): React.ReactElement => {
  const params = useParams<ParamTypes>();
  const history = useHistory();
  const schema = UserValidation.resetPasswordSchema();

  const { control, errors, handleSubmit, setError } = useForm<IPasswordChange>({
    resolver: yupResolver(schema),
    defaultValues: {
      new_password1: "",
      new_password2: "",
    },
  });

  const mutateOnSubmit = useMutation(
    (newData: IResetPasswordFormValues) => UserService.resetPassword(newData),
    {
      onSuccess: () => {
        snackbarHandler.success("New password saved. You can login now.");
        history.push(RouterConstants.USER.SIGN_IN);
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response, setError);
      },
    }
  );

  const onSubmit = (newData: IPasswordChange) => {
    mutateOnSubmit.mutate({ ...params, ...newData });
  };

  const inputProps = {
    InputProps: {
      autoComplete: "off",
      fullWidth: true,
      InputProps: {
        style: {
          backgroundColor: "white",
        },
      },
    },
  };

  return (
    <AuthForm
      title="Let's fix your password!"
      subtitle="Reset password here."
      onHandleSubmit={handleSubmit(onSubmit)}
      submitBtn="Set new password"
      action={
        <>
          Not an user?{" "}
          <Link to={RouterConstants.USER.SIGN_UP}>Create an account.</Link>
        </>
      }
      helper={
        <>
          Remember password?{" "}
          <Link to={RouterConstants.USER.SIGN_IN}>Sign In.</Link>
        </>
      }
    >
      <ElementField
        component={PasswordField}
        control={control}
        error={errors.new_password1}
        label="New password"
        name="new_password1"
        {...inputProps}
      />
      <ElementField
        component={PasswordField}
        control={control}
        error={errors.new_password2}
        label="Confirm new password"
        name="new_password2"
        {...inputProps}
      />
    </AuthForm>
  );
};

export default ResetPassword;
