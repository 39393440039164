import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import RouterUtils from "core/routes/utils";
import { IOption } from "core/models";

interface IButtonOption extends IOption {
  query: {
    [key: string]: string | boolean | any;
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    border: `1px dashed ${theme.app.palette.action.check}`,
    borderRadius: 50,
    lineHeight: "22px",
    cursor: "pointer",
    padding: theme.spacing(0, 2.5),
    color: theme.app.palette.action.color,
    fontSize: "0.75rem",
    fontWeight: 600,
    textTransform: "none",
    [theme.breakpoints.down("md")]: {
      flexGrow: 1,
    },
  },
}));

interface ButtonGroupFilterProps {
  id: string;
  options: IButtonOption[];
  handleOnChange?: (value: any) => void;
}

const ButtonGroupFilter = ({
  id,
  options,
  handleOnChange,
}: ButtonGroupFilterProps): React.ReactElement => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const { [id]: query } = RouterUtils.getQueryParams(location);

  // Current query or first option
  const defaultValue = query || options[0]?.id;
  const [value, setValue] = React.useState<string>(defaultValue);

  const updateQuery = (newValue: string) => {
    if (value !== newValue) {
      return RouterUtils.addQuery({ [id]: newValue }, history);
    }
    return RouterUtils.removeQuery([id], history);
  };

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newValue: string
  ) => {
    setValue(newValue);

    if (!!handleOnChange) {
      return handleOnChange(newValue);
    }

    updateQuery(newValue);
  };

  // Update current value from the query
  // ex. when external change clear filters happen
  // Not on the first update
  const firstUpdate = React.useRef(true);
  React.useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    if (query !== value) {
      setValue(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  React.useEffect(() => {
    if (!defaultValue) {
      setValue("");
    }
  }, [defaultValue]);

  return (
    <ToggleButtonGroup
      color="primary"
      value={value}
      exclusive
      onChange={handleChange}
    >
      {options.map((option) => (
        <ToggleButton
          key={option.id}
          value={option.id}
          className={classes.button}
          // sx={{ py: 0, px: 2.5, textTransform: "none",  }}
        >
          {option.name}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default ButtonGroupFilter;
