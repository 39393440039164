import { yupResolver } from "@hookform/resolvers/yup";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { errorHandler, IErrorResponse } from "core/utils/errorHandler";
import { snackbarHandler } from "core/utils/snackbarHandler";
import { AccountContext } from "modules/Account/context";
import {
  CampaignQuery,
  ISelectedCampaignFormInput,
  ISelectedPeopleToCampaignFormValues,
} from "modules/Campaign/models";
import CampaignService from "modules/Campaign/services";
import CampaignValidation from "modules/Campaign/validations";
import React from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import Button from "ui-kit/atoms/Button";
import DialogHeader from "ui-kit/components/DialogHeader";
import CampaignAutocomplete from "../CampaignAutocomplete";

interface MovePersonToCampaignProps {
  open: boolean;
  toggleOpen: (open: boolean) => void;
  personId: number;
  name: string;
  active: boolean;
}

const defaultValues = { campaign: { id: undefined, label: "" } };

const MovePersonToCampaign = ({
  open,
  toggleOpen,
  personId,
  name,
  active,
}: MovePersonToCampaignProps): React.ReactElement => {
  const queryClient = useQueryClient();

  const handleClose = () => {
    toggleOpen(false);
  };

  const {
    account: { id: accountId },
  } = React.useContext(AccountContext);

  const schema = CampaignValidation.createCampaignPeopleSchema;
  const [isLoading, setLoading] = React.useState(false);
  const { errors, control, handleSubmit, reset, setError } =
    useForm<ISelectedCampaignFormInput>({
      resolver: yupResolver(schema),
      defaultValues,
    });

  const handleEnd = () => {
    // end the disable
    setLoading(false);
  };

  const handleSuccess = () => {
    queryClient.invalidateQueries(["interactions-person"]);
    queryClient.invalidateQueries([CampaignQuery.campaigns_profile]);
    handleClose();
    reset(defaultValues);
    snackbarHandler.success("Successfully added to campaign!");
  };

  const mutatePerson = useMutation(
    (data: ISelectedPeopleToCampaignFormValues) =>
      CampaignService.createCampaignPersons(data),
    {
      onSuccess: (_, credentials) => {
        handleSuccess();
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response, setError);
      },
      onSettled: () => {
        handleEnd();
      },
    }
  );

  const onSubmit = ({ campaign }: ISelectedCampaignFormInput) => {
    // start the disable
    setLoading(true);

    mutatePerson.mutate({
      campaign: campaign.id,
      persons: [personId],
      account: accountId,
    });
  };

  // const label = selected.length === 1 ? "person" : "people";

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      PaperProps={{ sx: { width: "100%" } }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogHeader title="Add to a campaign" onHandleClose={handleClose} />
        <DialogContent dividers>
          <DialogContentText>
            Add <b>{name}</b> to a new campaign.{" "}
            {/* {active ? "This action stops all current campaigns." : ""} */}
          </DialogContentText>
          <Box marginTop={4.5}>
            <CampaignAutocomplete control={control} errors={errors} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isLoading}
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default MovePersonToCampaign;
