import Box from "@mui/material/Box";
import { errorHandler, IErrorResponse } from "core/utils/errorHandler";
import { getProfileName } from "core/utils/profileHandler";
import { snackbarHandler } from "core/utils/snackbarHandler";
import { IPerson } from "modules/Person/models";
import { ISearchResult } from "modules/Search/models";
import SearchService from "modules/Search/services";
import React from "react";
import { useMutation, useQueryClient } from "react-query";
import ProfileAction from "ui-kit/components/ProfileAction";

interface SearchResultsActionsProps {
  onHandleEdit: () => void;
  person: IPerson | null | undefined;
  searchResult: ISearchResult | undefined;
  searchId: number;
}

const SearchResultsActions = ({
  onHandleEdit,
  person,
  searchResult,
  searchId,
}: SearchResultsActionsProps): React.ReactElement => {
  const queryClient = useQueryClient();

  // Delete person from search
  const deleteSearchResult = useMutation(
    (id: number) => SearchService.deleteSearchResult(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["search-results", searchId]);
        // Reset selected searchResults
        snackbarHandler.success("Successfully removed!");
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);
      },
    }
  );

  const handleOnRemove = () => {
    const profileName = getProfileName(searchResult, person);
    if (
      window.confirm(
        `Do you really want to remove ${profileName} from the list?`
      )
    ) {
      if (!!searchResult?.id) {
        deleteSearchResult.mutate(searchResult.id);
      }
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <ProfileAction
        label={!!person ? "Edit" : "View"}
        onClick={onHandleEdit}
      />
      <ProfileAction label="Remove" onClick={handleOnRemove} />
    </Box>
  );
};

export default SearchResultsActions;
