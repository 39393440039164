import { getCurrentTimezoneDate } from "core/utils/dateHandler";
import { getCurrentAccount } from "modules/Account/utils";
import React, { createContext, useReducer } from "react";
import { IReportContext } from "../models";
import { ReportAction, reportReducer } from "../reducers";
import { getDefaultRange, getReport } from "../utils";

interface IProps {
  children: React.ReactElement;
}

interface InterfaceReportContext {
  report: IReportContext;
  dispatch: React.Dispatch<ReportAction>;
}

const initialState = () => {
  const defaultRange = getReport();

  const accountTimezone = getCurrentAccount()?.time_zone;
  const date = getCurrentTimezoneDate(accountTimezone);

  const currentRange = getDefaultRange(date, defaultRange);

  return {
    report: {
      currentRange,
      defaultRange,
    },
    dispatch: () => null,
  };
};

export const ReportContext = createContext<InterfaceReportContext>(
  initialState()
);

const ReportContextProvider = (props: IProps): React.ReactElement => {
  const [report, dispatch] = useReducer(reportReducer, initialState().report);
  const { children } = props;
  return (
    <ReportContext.Provider value={{ report, dispatch }}>
      {children}
    </ReportContext.Provider>
  );
};

export default ReportContextProvider;
