/* eslint-disable @typescript-eslint/naming-convention */
import React from "react";
import Button from "@mui/material/Button";
import Add from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import { useQueryClient } from "react-query";
import {
  AccountQuery,
  IAccount,
  IAccountDetailItemProps,
} from "modules/Account/models";
import { usePersonPlaceholderStyles } from "modules/Person/components/PersonDetail/utils";
import PaperHeader from "ui-kit/components/PaperHeader";
import AccountPlaceholderTextfield from "./components/AccountPlaceholderTextfield";
import NewAccountPlaceholderDialog from "./components/NewAccountPlaceholderDialog";

const AccountPlaceholderSettings = ({
  accountId,
}: IAccountDetailItemProps): React.ReactElement | null => {
  const classes = usePersonPlaceholderStyles();
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const queryClient = useQueryClient();
  const [account, setAccount] = React.useState<IAccount | undefined>(undefined);
  const data: IAccount | undefined = queryClient.getQueryData([
    AccountQuery.account,
    accountId,
  ]);
  React.useEffect(() => {
    if (data) {
      setAccount(data);
    }
  }, [data]);

  if (!account) return null;

  return (
    <Box sx={{ pt: 4, pb: 6 }}>
      <PaperHeader
        title="Account placeholders"
        body={
          <Alert variant="standard" severity="info">
            Changes will be applied to message campaigns only. This does not
            change your LinkedIn profile.
          </Alert>
        }
      />

      {Object.keys(account.template_placeholders).map((key: string) => {
        return (
          <AccountPlaceholderTextfield
            key={key}
            accountId={accountId}
            id={key}
            placeholders={account.placeholders}
            defaultValue={account.template_placeholders[key]}
          />
        );
      })}
      <Button
        onClick={() => {
          setOpenDialog(true);
        }}
        className={classes.button}
        size="small"
        color="inherit"
        variant="outlined"
        startIcon={<Add />}
      >
        Add my placeholder
      </Button>
      <NewAccountPlaceholderDialog
        accountId={accountId}
        placeholders={account.placeholders}
        open={openDialog}
        setOpen={setOpenDialog}
      />
    </Box>
  );
};

export default AccountPlaceholderSettings;
