import Close from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { errorHandler, IErrorResponse } from "core/utils/errorHandler";
import { snackbarHandler } from "core/utils/snackbarHandler";
import ActionService from "modules/Action/services";
import { CampaignQuery } from "modules/Campaign/models";
import React from "react";
import { useMutation, useQueryClient } from "react-query";

interface ActionMenuListProps {
  sequenceId: number;
  actionId: number;
}

const ActionMenuList = ({
  sequenceId,
  actionId,
}: ActionMenuListProps): React.ReactElement => {
  const queryClient = useQueryClient();
  const mutateDeleteAction = useMutation(
    () => ActionService.deleteAction(actionId),
    {
      onSuccess: () => {
        // Query Invalidations
        queryClient.invalidateQueries(["actions", sequenceId]);
        queryClient.invalidateQueries(CampaignQuery.actions);

        snackbarHandler.success("Action removed!");
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);
      },
    }
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    // eslint-disable-next-line no-alert
    if (window.confirm("Do you really want to remove this step?")) {
      mutateDeleteAction.mutate();
    }
  };

  return (
    <Tooltip title="Delete" arrow placement="top">
      <IconButton
        aria-controls="action-menu"
        aria-haspopup="true"
        onClick={handleClick}
        size="large"
      >
        <Close fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};

export default ActionMenuList;
