import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import ConfigConstant from "core/constants/ConfigConstant";
import { createTableCell } from "core/utils/tableHandler";
import { formatDateTimeToSmartTime } from "core/utils/dateHandler";
import { GlobalContext } from "core/context";
import { snackbarHandler } from "core/utils/snackbarHandler";
import { errorHandler, IErrorResponse } from "core/utils/errorHandler";
import { GlobalActionType } from "core/reducers";
import AccountService from "modules/Account/services";
import TeamService from "modules/Team/services";
import { TeamRoleLabel } from "modules/Team/models";
import CreateInvitation from "modules/Team/components/CreateInvitation";
import AccountNameWithLogin from "modules/Account/components/AccountNameWithLogin";
import TableBodyCell from "ui-kit/components/TableBodyCell";
import Table, { TableVariant } from "ui-kit/components/Table";
import PageHeader from "ui-kit/components/PageHeader";
import Button from "ui-kit/atoms/Button";
import { BetaChip } from "ui-kit/components/BetaChip";
import Tooltip from "@mui/material/Tooltip";
import { AccountQuery } from "modules/Account/models";

const rowsPerPage = ConfigConstant.PAGE_SIZE.MEDIUM;
const title = "Team members";
const heads = [
  {
    id: "member",
    percentage: true,
    width: 18,
    label: "Member",
  },
  {
    id: "account",
    percentage: true,
    width: 24,
    label: "Account",
  },
  {
    id: "role",
    percentage: true,
    width: 15,
    label: "Role",
  },
  {
    id: "invited",
    percentage: true,
    width: 15,
    label: "Invited by",
  },
  {
    id: "note",
    percentage: true,
    width: 16,
    label: "Note",
  },
  {
    id: "date",
    percentage: true,
    width: 12,
    label: "Invited at",
  },
];

const TeamList = (): React.ReactElement => {
  const queryClient = useQueryClient();
  const [page, setPage] = useState<number>(ConfigConstant.INITIAL_PAGE);
  const [openDialog, toggleDialog] = useState(false);

  const {
    dispatch,
    global: { selected },
  } = React.useContext(GlobalContext);

  const fetchTeamInvites = async (p: number) => {
    try {
      const { data } = await TeamService.fetchTeamInvites(p, rowsPerPage);
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };
  const { data, isFetching } = useQuery(
    ["invites", page],
    () => fetchTeamInvites(page),
    {
      keepPreviousData: true,
    }
  );

  const { data: dataAccounts } = useQuery(
    AccountQuery.all,
    async () => {
      try {
        const response = await AccountService.fetchAllAccounts();
        return response.data;
      } catch (err) {
        throw new Error(String(err));
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  // Delete member from team
  const deleteInvitation = useMutation(
    (id: number) => TeamService.deleteTeamInvite(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["invites"]);
        // Reset selected invitations
        dispatch({
          type: GlobalActionType.SET_GLOBAL,
          payload: { selected: [] },
        });
        snackbarHandler.success("Successfully removed!");
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);
      },
    }
  );

  const actionProps = {
    text: "Invite team member",
    onClick: () => toggleDialog(true),
  };

  const handleRemove = () => {
    if (selected.length === 0) {
      return snackbarHandler.warning("Please select at least 1 member.");
    }
    if (selected.length > 0) {
      selected.forEach(({ name }) => {
        deleteInvitation.mutate(Number(name));
      });
    }
  };

  const actions = (
    <Button
      variant="outlined"
      color="inherit"
      size="small"
      type="button"
      startIcon={<DeleteIcon />}
      onClick={handleRemove}
    >
      Remove
    </Button>
  );

  return (
    <>
      <PageHeader
        title={title}
        actionProps={actionProps}
        body="Here you can invite team members and manage invitations."
        selectedActions={actions}
      />
      <Table
        title={title}
        heads={heads}
        rows={
          data
            ? data.results.map((teamInvite) => {
                const account = dataAccounts?.results.find(
                  ({ login }) => login === teamInvite.account.login
                );
                return {
                  name: teamInvite.id.toString(),
                  data: [
                    createTableCell(
                      teamInvite.invitee_by_email,
                      null,
                      "main",
                      TableBodyCell
                    ),
                    createTableCell(
                      <AccountNameWithLogin account={account} />,
                      null,
                      "default",
                      TableBodyCell
                    ),
                    createTableCell(
                      TeamRoleLabel[teamInvite.role],
                      null,
                      "default",
                      TableBodyCell
                    ),
                    createTableCell(
                      teamInvite.inviter.name,
                      null,
                      "default",
                      TableBodyCell
                    ),
                    createTableCell(
                      teamInvite.note ? (
                        <Tooltip title={teamInvite.note} arrow placement="top">
                          <Box
                            sx={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              position: "relative",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {teamInvite.note}
                          </Box>
                        </Tooltip>
                      ) : (
                        "-"
                      ),
                      null,
                      "default",
                      TableBodyCell
                    ),
                    createTableCell(
                      formatDateTimeToSmartTime(teamInvite.created),
                      null,
                      "default",
                      TableBodyCell
                    ),
                  ],
                };
              })
            : []
        }
        count={data?.count || 0}
        // INITIAL_PAGE starts at 1, but Pagination starts at 0
        page={data?.page || page - 1}
        setPage={setPage}
        isFetching={isFetching}
        rowsPerPage={rowsPerPage}
        variant={[TableVariant.checkable]}
      />
      <CreateInvitation open={openDialog} toggleOpen={toggleDialog} />
    </>
  );
};

export default TeamList;
