import {
  StyledEngineProvider,
  Theme,
  ThemeProvider,
} from "@mui/material/styles";
import GoogleTracking from "core/components/GoogleTracking";
import LiveChatProvider from "core/components/LiveChatProvider";
import SessionTracking from "core/components/SessionTracking";
import VersionUpdater from "core/components/VersionUpdater";
import GlobalContextProvider from "core/context";
import { NotistackProvider } from "core/providers/NotistackProvider";
import Routes from "core/routes";
import { SnackbarUtilsConfigurator } from "core/utils/snackbarHandler";
import AccountContextProvider from "modules/Account/context";
import ActionContextProvider from "modules/Action/context";
import ReportContextProvider from "modules/Report/context";
import SearchContextProvider from "modules/Search/context";
import UserContextProvider from "modules/User/context";
import { ReactElement } from "react";
import { HelmetProvider } from "react-helmet-async";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import { appTheme } from "ui-kit/themes";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
    },
  },
});

function App(): ReactElement {
  return (
    <QueryClientProvider client={queryClient}>
      <GlobalContextProvider>
        <UserContextProvider>
          <AccountContextProvider>
            <SearchContextProvider>
              <ActionContextProvider>
                <LiveChatProvider>
                  <ReportContextProvider>
                    <BrowserRouter
                      getUserConfirmation={() => {
                        /* Empty callback to block the default browser prompt */
                      }}
                    >
                      <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={appTheme}>
                          <NotistackProvider>
                            <SnackbarUtilsConfigurator />
                            <HelmetProvider>
                              <Routes />
                            </HelmetProvider>
                            <VersionUpdater />
                          </NotistackProvider>
                        </ThemeProvider>
                      </StyledEngineProvider>
                    </BrowserRouter>
                  </ReportContextProvider>
                </LiveChatProvider>
              </ActionContextProvider>
            </SearchContextProvider>
          </AccountContextProvider>
        </UserContextProvider>
      </GlobalContextProvider>
      <GoogleTracking />
      <SessionTracking />
    </QueryClientProvider>
  );
}

export default App;
