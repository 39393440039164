import React from "react";
import { Dayjs } from "dayjs";
import AdapterDayjs from "@mui/lab/AdapterDayjs";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import MuiTimePicker from "@mui/lab/MobileTimePicker";
import { formatTimeToStr, formatStrToTime } from "core/utils/dateHandler";
import DateConstant from "core/constants/DateConstant";
import TextField from "./TextField";
import { IPickerProps } from "./DatePicker";

const ampm = !DateConstant.LOCALE_24H;

function TimePicker({
  value,
  label,
  onChange,
  InputProps,
  ElementProps,
  ...rest
}: IPickerProps): React.ReactElement {
  const onHandleChange = (date: Dayjs) => {
    onChange(formatTimeToStr(date));
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MuiTimePicker
        {...ElementProps}
        minutesStep={5}
        label={label}
        ampm={ampm}
        value={formatStrToTime(value)}
        onChange={onHandleChange}
        renderInput={(params) => (
          <TextField {...params} {...rest} {...InputProps} />
        )}
      />
    </LocalizationProvider>
  );
}

export default TimePicker;
