import ConfigConstant from "core/constants/ConfigConstant";
import RouterConstants from "core/routes/constants";
import { COUNTRY_CODES } from "core/utils/countryHandler";
import {
  formatStrToTime,
  formatUserDateToTime,
  getUserTimezone,
  reFormatTimeZoneName,
  setDefaultTimezone,
} from "core/utils/dateHandler";
import get from "lodash/get";
import AccountConstants from "modules/Account/constants";
import { AccountActions, AccountActionType } from "modules/Account/reducers";
import { IAccountSubscription } from "modules/Payment/models";
import { getPersonName } from "modules/Person/utils";
import React from "react";
import { ChipColors } from "ui-kit/atoms/Chip";
import {
  AccountLogStrategyUniqueName,
  AccountTabs,
  IAccount,
  IAccountChipItem,
  IAccountCurrent,
  IAccountLog,
  IAccountLogStatuses,
  IAccountLogStrategy,
  IAccountStatusBadge,
  IAccountStatusChip,
  IIsOnline,
  IProxyIpsMappedData,
  IWorkDay,
  ScrapingStatusValues,
  TimeZones,
} from "../models";

// Check if username saved on currentAccount equals to username logging in
// Remove account data if it's not equal
const handleUserAccountCheck = (
  username: string,
  accountDispatch: React.Dispatch<AccountActions>
): void => {
  if (username !== getCurrentAccount().username) {
    storeCurrentAccount(AccountConstants.EMPTY_CURRENT_ACCOUNT);
    accountDispatch({
      type: AccountActionType.SET_ACCOUNT,
      payload: AccountConstants.EMPTY_CURRENT_ACCOUNT,
    });
    // accountDispatch({
    //   type: AccountActionType.SET_NEW_ACCOUNT,
    //   payload: emptyNewAccount,
    // });
  }
};

// Check if username saved on currentAccount equals to username logging in
// Remove account data if it's not equal
const handleAccountDeletion = (
  accountDispatch: React.Dispatch<AccountActions>
): void => {
  storeCurrentAccount(AccountConstants.EMPTY_CURRENT_ACCOUNT);
  accountDispatch({
    type: AccountActionType.SET_ACCOUNT,
    payload: AccountConstants.EMPTY_CURRENT_ACCOUNT,
  });
};

const TIME_ZONES = Object.keys(TimeZones).map((key) => ({
  id: key,
  name: TimeZones[key as keyof typeof TimeZones],
}));

const getAccountName = (
  account: IAccount | IAccountCurrent | undefined
): string => {
  return account?.person ? getPersonName(account.person) : account?.login || "";
};

const getFullAccountName = (
  account: IAccount | IAccountCurrent | undefined
): string => {
  return account?.person
    ? `${getPersonName(account.person)} (${account?.login})`
    : account?.login || "";
};

const chips = {
  active: {
    color: ChipColors.success,
    label: "Active",
  },
  incomplete: {
    color: ChipColors.error,
    label: "Incomplete",
  },
};

const getAccountChip = (person: boolean): IAccountChipItem =>
  person ? chips.active : chips.incomplete;

const getLogContent = (
  log: IAccountLog | undefined,
  login: string | undefined
): IAccountLogStrategy | undefined => {
  if (!log) {
    return undefined;
  }

  // If it's not action required don't show input content
  if (log.status === IAccountLogStatuses.lo) {
    return undefined;
  }

  // If it's not action required don't show input content
  if (log.status !== IAccountLogStatuses.ar) {
    return undefined;
  }
  // If it's answered don't show input field
  if (log.answered) {
    return undefined;
  }
  // If there is no strategy, don't show input field
  if (!log.strategy) {
    return undefined;
  }

  // Get strategy name
  const strategyShort = AccountLogStrategyUniqueName[log.strategy];

  const items = AccountConstants.GET_STRATEGY_ITEMS(login);
  return items[strategyShort];
};

const createBadge = (
  tooltip: string,
  color: ChipColors
): IAccountStatusBadge => ({
  tooltip,
  color,
});

const getAccountStatusBadge = (account: IAccount, data: IIsOnline) => {
  const { scraping_status } = account;
  if (data.is_inactive_day) {
    return createBadge("Inactive day", ChipColors.error);
  }

  if (!data.is_in_work_time_range) {
    return createBadge("Offline", ChipColors.error);
  }

  if (
    !!scraping_status &&
    (scraping_status === ScrapingStatusValues.stopped ||
      scraping_status === ScrapingStatusValues.restricted)
  ) {
    return createBadge("Account stopped", ChipColors.error);
  }

  return createBadge("Online", ChipColors.success);
};

const createChip = (
  tooltip: string,
  color: ChipColors,
  label: string,
  link: string
): IAccountStatusChip => ({
  tooltip,
  color,
  label,
  link,
});

const getAccountStatusChip = (
  account: IAccount,
  data: IIsOnline
): IAccountStatusChip => {
  const accountId = account.id;
  const { scraping_status } = account;
  // Not work time
  if (!account.person) {
    return createChip(
      `Continue adding this account to ${ConfigConstant.APP_NAME}.`,
      ChipColors.warning,
      "Not activated yet",
      RouterConstants.ACCOUNT.new(accountId)
    );
  }

  // If needs user input to continue sync
  if (data.input_required) {
    return createChip(
      "Can't connect to your LinkedIn account. Click to see the issue.",
      ChipColors.error,
      "Action required",
      RouterConstants.ACCOUNT.detail(accountId, AccountTabs["activity"])
    );
  }

  // Inactive day
  if (data.is_inactive_day) {
    return createChip(
      "Your inactive day.",
      ChipColors.error,
      "Inactive day",
      RouterConstants.ACCOUNT.detail(accountId, AccountTabs["schedule"])
    );
  }

  // Not work time
  if (!data.is_in_work_time_range) {
    return createChip(
      "Not in the active schedule.",
      ChipColors.error,
      "Offline",
      RouterConstants.ACCOUNT.detail(accountId, AccountTabs["schedule"])
    );
  }

  // If busy = active
  if (scraping_status && scraping_status === ScrapingStatusValues.busy) {
    const label =
      AccountConstants.MATCH_SCRAPING_STATUS_TO_LABEL[scraping_status];
    return createChip(
      `${label} your LinkedIn account.`,
      ChipColors.success,
      label,
      RouterConstants.ACCOUNT.detail(accountId, AccountTabs["schedule"])
    );
  }

  // Account not available to scrape
  if (scraping_status && scraping_status !== ScrapingStatusValues.available) {
    const label =
      AccountConstants.MATCH_SCRAPING_STATUS_TO_LABEL[scraping_status];
    return createChip(
      `Account is ${label}`,
      ChipColors.error,
      label,
      RouterConstants.ACCOUNT.detail(accountId, AccountTabs.activity)
    );
  }

  // Online
  return createChip(
    "In the active schedule.",
    ChipColors.success,
    "Online",
    RouterConstants.ACCOUNT.detail(accountId, AccountTabs["schedule"])
  );
};

const removeMyPartPlaceholder = (str: string): string => str.substring(3);

const getValueWorkHours = (
  workDay: IWorkDay | undefined
): string | React.ReactElement => {
  if (!workDay || !workDay?.active) {
    return "Not a work day";
  }
  return `${formatUserDateToTime(
    formatStrToTime(workDay.start_time)
  )} - ${formatUserDateToTime(formatStrToTime(workDay.end_time))}`;
};

const showSyncLoader = (
  recentLog: IAccountLog | undefined,
  enableAnsweredOnTime: boolean
) => {
  if (!recentLog) {
    return true;
  }

  if (IAccountLogStatuses.lo === recentLog.status) {
    return false;
  }

  if (IAccountLogStatuses.lf === recentLog.status) {
    return false;
  }

  if (IAccountLogStatuses.ls === recentLog.status) {
    return false;
  }

  if (IAccountLogStatuses.il === recentLog.status) {
    return true;
  }

  // If waiting for answer, don't show log
  if (
    IAccountLogStatuses.ar === recentLog.status &&
    recentLog.answered !== true
  ) {
    return false;
  }

  if (IAccountLogStatuses.uf === recentLog.status) {
    return false;
  }

  // If action fulfilled on time, we need loader because we wait for confirmation
  if (enableAnsweredOnTime && IAccountLogStatuses.af === recentLog.status) {
    return true;
  }

  return true;
};

const getCurrentAccount = (): IAccountCurrent => {
  const storedAccount =
    window &&
    window.localStorage &&
    localStorage.getItem(AccountConstants.STORAGE_KEYS.CURRENT);
  if (storedAccount) {
    const account = JSON.parse(storedAccount);
    // Set default timezone
    setDefaultTimezone(account.time_zone);
    return account;
  }
  return AccountConstants.EMPTY_CURRENT_ACCOUNT;
};

const storeCurrentAccount = (account?: IAccountCurrent): void => {
  if (!account) {
    localStorage.removeItem(AccountConstants.STORAGE_KEYS.CURRENT);
    return;
  }
  if (!window.localStorage) return;
  localStorage.setItem(
    AccountConstants.STORAGE_KEYS.CURRENT,
    JSON.stringify(account)
  );
  setDefaultTimezone(account.time_zone);
};

const mergeAccountSub = (arr1: IAccount[], arr2: IAccountSubscription[]) =>
  arr1.map((item, i) => Object.assign({}, item, arr2[i]));

const createDefaultCountry = (proxy: IProxyIpsMappedData | undefined) => {
  const country = get(proxy, "country");
  return {
    country: COUNTRY_CODES.find(
      (countryCode) => countryCode.id === country
    ) || {
      id: "",
      name: "",
    },
  };
};

const getAccountTimezone = (timezone: IAccount["time_zone"] | undefined) => {
  const userTimezone = getUserTimezone();
  return timezone || userTimezone
    ? {
        id: reFormatTimeZoneName(timezone) || userTimezone,
        name: timezone || userTimezone,
      }
    : {
        id: TimeZones["America/New_York"],
      };
};

const getDefaultTimezone = (): TimeZones => {
  const userTimezone = getUserTimezone();
  return userTimezone in TimeZones
    ? TimeZones[userTimezone as keyof typeof TimeZones]
    : TimeZones["America/New_York"];
};

export {
  createDefaultCountry,
  getAccountChip,
  getAccountName,
  getAccountStatusBadge,
  getAccountStatusChip,
  getAccountTimezone,
  getCurrentAccount,
  getDefaultTimezone,
  getFullAccountName,
  getLogContent,
  getValueWorkHours,
  handleAccountDeletion,
  handleUserAccountCheck,
  mergeAccountSub,
  removeMyPartPlaceholder,
  showSyncLoader,
  storeCurrentAccount,
  TIME_ZONES,
};
