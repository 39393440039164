import React from "react";
import clsx from "clsx";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Collapse from "@mui/material/Collapse";

interface FoldableContentProps {
  children: React.ReactElement | React.ReactElement[];
  title: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderTop: `1px solid ${theme.app.palette.shadow.secondary}`,
    "&:first-of-type": {
      borderTop: "none",
    },
  },
  rootFolded: {
    "&:last-of-type": {
      borderBottom: `1px solid ${theme.app.palette.shadow.secondary}`,
    },
  },
  header: {
    display: "flex",
    width: "100%",
    borderRadius: 0,
    margin: 0,
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(1.25, 2, 1.25, 4),
    backgroundColor: theme.app.palette.background.light,
    borderBottom: `1px solid ${theme.app.palette.shadow.secondary}`,
  },
  headerFolded: {
    borderBottom: `1px solid transparent`,
  },
  title: {
    textTransform: "uppercase",
    color: theme.app.palette.action.color,
    fontWeight: 500,
    fontSize: "0.775rem",
  },
  button: {
    color: theme.app.palette.action.placeholder,
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shorter,
    }),
  },
  closedBtn: {
    transform: "rotate(90deg)",
  },
}));

const FoldableContent = ({
  children,
  title,
}: FoldableContentProps): React.ReactElement => {
  const classes = useStyles();
  const [collapsed, setCollapse] = React.useState(true);

  return (
    <div className={clsx(classes.root, { [classes.rootFolded]: !collapsed })}>
      <Button
        className={clsx(classes.header, { [classes.headerFolded]: !collapsed })}
        onClick={() => setCollapse(!collapsed)}
      >
        <Typography variant="body2" className={classes.title}>
          {title}
        </Typography>
        <ArrowDropDownIcon
          className={clsx(classes.button, { [classes.closedBtn]: !collapsed })}
        />
      </Button>
      <Collapse in={collapsed}>{children}</Collapse>
    </div>
  );
};

export default FoldableContent;
