import RouterConstants from "core/routes/constants";
import { AccountContext } from "modules/Account/context";
import React, { useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import Loader from "ui-kit/components/Loader";

type ParamTypes = {
  accountId: string;
};

const AccountCheckout = (): React.ReactElement => {
  const history = useHistory();
  const params = useParams<ParamTypes>();
  const accountId: number = +params.accountId;

  const {
    account: { id: newAccountId },
  } = useContext(AccountContext);

  React.useEffect(() => {
    if (newAccountId && accountId === newAccountId) {
      history.push(RouterConstants.ACCOUNT.new(accountId));
    } else {
      history.push(RouterConstants.ROOT);
    }
  }, [history, accountId, newAccountId]);

  return <Loader />;
};

export default AccountCheckout;
