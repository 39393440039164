import React from "react";
import { useForm } from "react-hook-form";
import { useQueryClient, useMutation } from "react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { snackbarHandler } from "core/utils/snackbarHandler";
import { errorHandler, IErrorResponse } from "core/utils/errorHandler";
import CampaignService from "modules/Campaign/services";
import CampaignValidation from "modules/Campaign/validations";
import {
  CampaignQuery,
  ICampaignSequenceFormProps,
} from "modules/Campaign/models";
import SequenceAutocomplete from "modules/Sequence/components/SequenceAutocomplete";
import Button from "ui-kit/atoms/Button";
import DialogHeader from "ui-kit/components/DialogHeader";
import { DialogContentText } from "@mui/material";
import { GlobalActionType } from "core/reducers";
import { GlobalContext } from "core/context";

interface CreateSequenceProps {
  campaignId: number;
  open: boolean;
  toggleOpen: (open: boolean) => void;
}

const defaultValues = { sequence: { id: 0, name: "" } };

const AddCampaignSequence = ({
  campaignId,
  open,
  toggleOpen,
}: CreateSequenceProps): React.ReactElement => {
  const queryClient = useQueryClient();

  const { dispatch } = React.useContext(GlobalContext);

  const schema = CampaignValidation.createCampaignSequenceSchema;
  const { errors, control, handleSubmit, reset, setError } =
    useForm<ICampaignSequenceFormProps>({
      resolver: yupResolver(schema),
      defaultValues,
    });

  const resetSelected = () => {
    dispatch({
      type: GlobalActionType.SET_GLOBAL,
      payload: { selected: [] },
    });
  };

  const handleClose = () => {
    toggleOpen(false);
    reset(defaultValues);
    resetSelected();
  };

  const mutateOnSubmit = useMutation(
    (data: ICampaignSequenceFormProps) =>
      CampaignService.createCampaignSequence({
        campaign: campaignId,
        sequence: data.sequence.id,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([CampaignQuery.sequences, campaignId]);
        snackbarHandler.success("Successfully added!");
        handleClose();
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response, setError);
      },
    }
  );

  const onSubmit = (data: ICampaignSequenceFormProps) => {
    mutateOnSubmit.mutate(data);
  };

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      PaperProps={{ sx: { width: "100%" } }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogHeader title="Add sequence" onHandleClose={handleClose} />
        <DialogContent dividers>
          <DialogContentText>
            Use multiple sequences to split test your campaign.
          </DialogContentText>
          <Box marginTop={4.5}>
            <SequenceAutocomplete
              control={control}
              error={errors.sequence?.name}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button type="submit" variant="contained" color="primary">
            Add sequence
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddCampaignSequence;
