import ConfigConstant from "core/constants/ConfigConstant";
import * as Yup from "yup";

export const ERROR_KEYS = {
  REASON: {
    REQUIRED: "Please enter cancelation reason.",
    INVALID: `Please add a short reason to help make ${ConfigConstant.APP_NAME} better.`,
  },
  OPTION: {
    REQUIRED: "Please select at least 1 option.",
  },
  ACCOUNT: {
    REQUIRED: "Selecting a LinkedIn account is required.",
  },
  COUNTRY: {
    REQUIRED: "Selecting a country is required.",
  },
};

function cancelSchema(): Yup.AnyObjectSchema {
  return Yup.object().shape({
    reason: Yup.string()
      .required(ERROR_KEYS.REASON.REQUIRED)
      .min(5, ERROR_KEYS.REASON.INVALID),
    options: Yup.array().min(1, ERROR_KEYS.OPTION.REQUIRED),
  });
}

function transferAccountSchema(
  proxyExists: boolean | undefined
): Yup.AnyObjectSchema {
  return Yup.object().shape({
    account: Yup.object()
      .shape({
        id: Yup.string().required(ERROR_KEYS.ACCOUNT.REQUIRED),
      })
      .nullable()
      .required(ERROR_KEYS.ACCOUNT.REQUIRED),
    ...(!proxyExists && {
      country: Yup.object()
        .shape({
          id: Yup.string().required(ERROR_KEYS.COUNTRY.REQUIRED),
        })
        .nullable()
        .required(ERROR_KEYS.COUNTRY.REQUIRED),
    }),
  });
}

const PaymentValidations = { cancelSchema, transferAccountSchema };

export default PaymentValidations;
