import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { ListItem, ListItemText, Theme } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { makeStyles } from "@mui/styles";
import React from "react";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme: Theme) => ({
  control: {
    marginBottom: 0,
  },
  checkbox: {
    padding: theme.spacing(0.75, 0),
    marginRight: theme.spacing(2.75),
  },
  itemText: {
    ...theme.app.constants.textOneLiner,
  },
}));

interface CheckboxAutocompleteOptionProps {
  props: React.HTMLAttributes<HTMLLIElement>;
  option: {
    label?: string;
    name?: string;
    id?: number | string;
  };
  selected: boolean;
  onHandleChange?: (s: boolean) => void;
}

const CheckboxAutocompleteOption = (
  _props: CheckboxAutocompleteOptionProps
): React.ReactElement => {
  const { props, option, selected, onHandleChange } = _props;
  const classes = useStyles();
  return (
    <ListItem
      {...props}
      {...(onHandleChange && { onClick: () => onHandleChange(!selected) })}
    >
      <Checkbox
        icon={icon}
        checkedIcon={checkedIcon}
        className={classes.checkbox}
        checked={selected}
        onMouseDown={(e) => e.preventDefault()}
      />

      <ListItemText
        primary={option.name || option.label}
        primaryTypographyProps={{
          variant: "body2",
          className: classes.itemText,
        }}
      />
    </ListItem>
  );
};

export default CheckboxAutocompleteOption;
