import { MentionData } from "@draft-js-plugins/mention";
import { IArrayResponse, Platforms } from "core/models";
import { CampaignLocale } from "modules/Campaign/models";

export enum ActionStrategy {
  "inevitable.strategy.action.LinkedInConnectionRequestStrategy" = "inevitable.strategy.action.LinkedInConnectionRequestStrategy",
  "inevitable.strategy.action.LinkedInMessageStrategy" = "inevitable.strategy.action.LinkedInMessageStrategy",
  "inevitable.strategy.action.LinkedInViewProfileStrategy" = "inevitable.strategy.action.LinkedInViewProfileStrategy",
  "inevitable.strategy.action.LinkedInGroupMessageStrategy" = "inevitable.strategy.action.LinkedInGroupMessageStrategy",
  "sequence_paused" = "sequence_paused",
  "sequence_resumed" = "sequence_resumed",
  "connected" = "connected",
  "disconnected" = "disconnected",
  "message_attempt" = "message_attempt",
  "connection_request_attempt" = "connection_request_attempt",
  "sequence_stopped" = "sequence_stopped",
  "person_in_campaign_queue" = "person_in_campaign_queue",
  "inMail" = "inMail",
  "connection_request_withdraw" = "connection_request_withdraw",
  "system_message" = "system_message",
  "add_to_campaign" = "add_to_campaign",
}

export enum ActionMatchStrategyToVariant {
  "inevitable.strategy.action.LinkedInMessageStrategy" = "message",
  "inevitable.strategy.action.LinkedInViewProfileStrategy" = "profile_view",
  "inevitable.strategy.action.LinkedInConnectionRequestStrategy" = "connection_request",
  "inevitable.strategy.action.LinkedInGroupMessageStrategy" = "group_message",
  "sequence_paused" = "sequence_paused",
  "sequence_resumed" = "sequence_resumed",
  "connected" = "connected",
  "disconnected" = "disconnected",
  "message_attempt" = "message_attempt",
  "connection_request_attempt" = "connection_request_attempt",
  "sequence_stopped" = "sequence_stopped",
  "person_in_campaign_queue" = "person_in_campaign_queue",
  "inMail" = "inMail",
  "connection_request_withdraw" = "connection_request_withdraw",
  "system_message" = "system_message",
  "add_to_campaign" = "add_to_campaign",
}

export enum DirectActionStrategy {
  "inevitable.strategy.direct_action.LinkedInConnectionRequestStrategy" = "inevitable.strategy.direct_action.LinkedInConnectionRequestStrategy",
  "inevitable.strategy.direct_action.LinkedInMessageStrategy" = "inevitable.strategy.direct_action.LinkedInMessageStrategy",
  "inevitable.strategy.direct_action.LinkedInViewProfileStrategy" = "inevitable.strategy.direct_action.LinkedInViewProfileStrategy",
  "inevitable.strategy.direct_action.LinkedInAddToCampaignStrategy" = "inevitable.strategy.direct_action.LinkedInAddToCampaignStrategy",
}

export enum DirectActionMatchStrategyToVariant {
  "inevitable.strategy.direct_action.LinkedInMessageStrategy" = "message",
  "inevitable.strategy.direct_action.LinkedInViewProfileStrategy" = "profile_view",
  "inevitable.strategy.direct_action.LinkedInConnectionRequestStrategy" = "connection_request",
  "inevitable.strategy.direct_action.LinkedInAddToCampaignStrategy" = "add_to_campaign",
}

export interface INewActionFormInput {
  execution_strategy: DirectActionStrategy | string;
  time_delta: string;
  strategy_data: {
    template: string | null;
    template_metadata: string;
  };
}

export interface INewActionFormValues {
  execution_strategy: DirectActionStrategy;
  time_delta: string;
  strategy_data?: {
    template: string | null;
  };
}

export interface IAction {
  id: number;
  order_nr: number;
  time_delta: string;
  platform: Platforms.linkedin;
  sequence: number;
  execution_strategy: ActionStrategy;
  strategy_data?: {
    template: string | null;
  };
}

export interface IActions extends IArrayResponse {
  results: IAction[];
}

export interface IActionMove {
  id: number;
  order_nr: number;
  sourceIndex: number;
}

export interface IDirectAction {
  id: number;
  order_nr: number;
  time_delta: string;
  platform: Platforms.linkedin;
  sequence: number;
  execution_strategy: DirectActionStrategy;
  strategy_data?: {
    template: string | null;
  };
  created: string;
}

export interface IDirectActions extends IArrayResponse {
  results: IDirectAction[];
}

export interface IExistingActionFormInput {
  time_delta: string;
  strategy_data?: {
    template: string | null;
  };
}

export interface IExistingActionFormValues extends IExistingActionFormInput {
  id: number;
}

export interface IDraftAction {
  previewMode?: boolean;
  campaignId?: number;
  personId?: number;
  dynamicPlaceholder?: string;
  locale?: CampaignLocale;
  placeholders?: {
    [key: string]: string;
  };
  previewContactName?: string;
}

export interface BasePlaceholderMenuProps {
  allPlaceholders: MentionData[];
  customPlaceholders: MentionData[];
  handleOnSelect: (data: MentionData) => void;
}

export interface PlaceholderMenuProps extends BasePlaceholderMenuProps {
  handleOnMultiSelect: (data: MentionData[]) => void;
}
