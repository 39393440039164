import RouterConstants from "core/routes/constants";
import { History } from "history";
import { IAccount } from "modules/Account/models";
import { AccountActions, AccountActionType } from "modules/Account/reducers";

const handleAccountSelect = (
  value: IAccount,
  dispatch: React.Dispatch<AccountActions>,
  history: History
): void => {
  dispatch({
    type: AccountActionType.SET_ACCOUNT,
    payload: value,
  });

  history.push(RouterConstants.ROOT);
};

// eslint-disable-next-line import/prefer-default-export
export { handleAccountSelect };
