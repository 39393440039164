/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useState } from "react";
import { useQuery } from "react-query";
import clsx from "clsx";
import { Controller, Control, FieldError } from "react-hook-form";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import SendIcon from "@mui/icons-material/Send";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Editor from "@draft-js-plugins/editor";
import {
  convertToRaw,
  convertFromRaw,
  EditorState,
  Modifier,
  CompositeDecorator,
  RawDraftEntityRange,
  getDefaultKeyBinding,
} from "draft-js";
import createMentionPlugin, { MentionData } from "@draft-js-plugins/mention";
import "@draft-js-plugins/mention/lib/plugin.css";
import PlaceholderService from "modules/Placeholder/services";
import { AccountContext } from "modules/Account/context";
import {
  getAllPlaceholders,
  getCustomPlaceholders,
} from "modules/Placeholder/utils";
import TextField from "ui-kit/atoms/TextField";
import { ActionContext } from "modules/Action/context";
import { DraftActionActionType } from "modules/Action/reducers";
import { DraftTypes } from "core/models";
import DraftField from "./components/DraftField";
import PlaceholderComponent from "./components/PlaceholderComponent";
import PlaceholderMenu from "./components/PlaceholderMenu";
import PreviewView from "./components/PreviewView";
import PreviewPerson from "./components/PreviewPerson";

const useStyles = makeStyles((theme: Theme) => ({
  multiline: {
    "& .DraftEditor-root": {
      "& .public-DraftEditor-content": {
        minHeight: 150,
      },
    },
  },
  singleline: {
    marginBottom: theme.spacing(0),
  },
  preview: {
    "& .DraftEditor-root": {
      paddingRight: "51%",
    },
  },
  draft: {
    position: "relative",
    width: "100%",
    '& > div[role="listbox"]': {
      maxHeight: 300,
      overflowY: "scroll",
    },
    // Break long words in draft.js textfield
    '& .DraftEditor-root span[data-text="true"]': {
      overflowY: "auto",
      wordWrap: "anywhere",
    },
    "& .MuiOutlinedInput-root": {
      [theme.breakpoints.up("md")]: {
        padding: "23px 14px 10px",
      },
      [theme.breakpoints.down("md")]: {
        padding: "23px 23px 10px 10px",
      },
    },
    "& .DraftEditor-root": {
      width: "100%",
      "& .DraftEditor-editorContainer": {
        width: "100%",
        "& .public-DraftEditor-content": {
          width: "100%",
          overflow: "auto",
        },
      },
    },
  },
  actions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    right: 0,
    position: "absolute",
    height: 36.75, // Based on saveButton in ActionListItem
    transition: theme.transitions.create("all", {
      duration: theme.transitions.duration.shorter,
    }),
  },
  actionsError: {
    margin: theme.spacing(-17, 2, 3),
  },
  actionsPreview: {
    paddingRight: "50%",
  },
  counter: {
    color: theme.app.palette.action.placeholder,
    marginRight: theme.spacing(1),
  },
  counterError: {
    color: theme.palette.error.main,
  },
  inputAdornment: {
    marginLeft: theme.spacing(1.5),
    marginBottom: theme.spacing(3),
  },
}));

interface DraftEditorProps {
  defaultValue: {
    template: string;
    template_metadata: string;
  };
  onFieldChange: (value: {
    template: string;
    template_metadata: string;
  }) => void;
  error: FieldError | undefined;
  type: DraftTypes;
  maxlength: number | undefined;
  label: string;
  endAdornmentSubmit: boolean | undefined;
}

function getEntity(contentBlock: any, callback: any, contentState: any) {
  contentBlock.findEntityRanges((character: any) => {
    const entityKey = character.getEntity();
    if (entityKey === null) {
      return false;
    }
    return contentState.getEntity(entityKey).getType() === "mention";
  }, callback);
}

const decorators = [
  {
    strategy: getEntity,
    component: PlaceholderComponent,
  },
];

export const compositeDecorator = new CompositeDecorator(decorators);

const getInitialContent = (defaultValue: DraftEditorProps["defaultValue"]) => {
  if (defaultValue?.template_metadata) {
    const defaultRawContent = JSON.parse(defaultValue?.template_metadata);
    const blocks = convertFromRaw(defaultRawContent);
    return EditorState.createWithContent(blocks, compositeDecorator);
  }
  return EditorState.createEmpty(compositeDecorator);
};

const DraftEditor = ({
  onFieldChange,
  defaultValue,
  error,
  type,
  maxlength,
  label,
  endAdornmentSubmit,
}: DraftEditorProps): React.ReactElement => {
  const classes = useStyles();
  const [editorState, setEditorState] = useState<EditorState>(
    getInitialContent(defaultValue)
  );
  const {
    dispatch,
    draftAction: { previewMode, personId, placeholders },
  } = React.useContext(ActionContext);
  const [plainText, setPlainText] = useState("");
  const editorRef = useRef<Editor>(null);

  const selectionState = editorState.getSelection();
  const anchorKey = selectionState.getAnchorKey();
  const currentContent = editorState.getCurrentContent();
  const currentContentBlock = currentContent.getBlockForKey(anchorKey);
  const { blocks } = convertToRaw(editorState.getCurrentContent());

  const { plugins } = React.useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      entityMutability: "IMMUTABLE",
    });
    // eslint-disable-next-line no-shadow
    const { MentionSuggestions: DraftMentionSuggestions } = mentionPlugin;
    // eslint-disable-next-line no-shadow
    const draftPlugins = [mentionPlugin];
    return {
      plugins: draftPlugins,
      MentionSuggestions: DraftMentionSuggestions,
    };
  }, []);

  const handleOnEnterEntity = () => {
    // Current click focus offset
    const focusOffset = selectionState.getFocusOffset();

    // - 1 to not count the starting position
    const entityKey = currentContentBlock.getEntityAt(focusOffset - 1);
    if (entityKey) {
      let result: RawDraftEntityRange | undefined;
      blocks.forEach((block) => {
        const foundEntity = block.entityRanges.find(
          (range) =>
            block.key === anchorKey &&
            range.offset + range.length > focusOffset &&
            range.offset <= focusOffset
        );

        if (foundEntity) {
          result = foundEntity;
        }
      });

      if (result) {
        // If focus and result offset are equal, it's first position
        const entityOffset =
          focusOffset === result.offset
            ? focusOffset - 1
            : result.offset + result.length;
        const entitySelection = selectionState.merge({
          anchorOffset: entityOffset,
          focusOffset: entityOffset,
        });
        const newEditorStore = EditorState.forceSelection(
          editorState,
          entitySelection
        );
        setEditorState(newEditorStore);

        setTimeout(() => {
          editorRef?.current?.focus();
        }, 0);
      }
    }
  };

  // Stop Preview if active
  const handleStopPreviewOnInputFocus = () => {
    if (previewMode) {
      dispatch({
        type: DraftActionActionType.SET_DRAFT_ACTION,
        payload: { previewMode: false },
      });
    }
  };

  const handleOnClick = () => {
    handleOnEnterEntity();
  };

  const keyBindingFn = (e: any) => {
    handleOnEnterEntity();
    return getDefaultKeyBinding(e);
  };

  // Get all placeholders
  const {
    account: { id: accountId },
  } = React.useContext(AccountContext);

  const fetchPlaceholderKeys = async () => {
    try {
      const response = await PlaceholderService.fetchPlaceholderKeys(accountId);
      return response.data;
    } catch (err) {
      throw new Error(String(err));
    }
  };

  const { data: placeholderData } = useQuery(
    ["placeholder_keys", accountId],
    () => fetchPlaceholderKeys(),
    {
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: !!DraftTypes.multiline,
    }
  );

  const allPlaceholders = getAllPlaceholders(placeholderData?.results || []);
  const allCustomPlaceholders = getCustomPlaceholders(
    placeholderData?.results || []
  );

  const handleOnChange = (newEditorState: EditorState) => {
    const newCurrentContent = newEditorState.getCurrentContent();
    const newPlainValue = newCurrentContent.getPlainText();
    // Set plain text value
    setPlainText(newPlainValue);
    // Set draft.js value
    setEditorState(newEditorState);
    // Set react-hook-form value
    const raw = convertToRaw(newCurrentContent);
    const json = JSON.stringify(raw);
    onFieldChange({ template: newPlainValue, template_metadata: json });
  };

  // Reset current state
  React.useEffect(() => {
    if (defaultValue?.template === null) {
      handleOnChange(EditorState.createEmpty(compositeDecorator));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue?.template]);

  const generateEntity = (
    newEntity: MentionData,
    newEditorState: EditorState
  ) => {
    // Create entity and get its key
    const contentStateWithEntity = newEditorState
      .getCurrentContent()
      .createEntity("mention", "IMMUTABLE", newEntity);
    const newEntityKey = contentStateWithEntity.getLastCreatedEntityKey();

    // Get current cursor end position to know where to place entity
    const currentSelectionState = newEditorState.getSelection();

    let mentionReplacedContent = Modifier.replaceText(
      newEditorState.getCurrentContent(),
      currentSelectionState,
      newEntity.name,
      undefined, // no inline style needed
      newEntityKey
    );

    // Insert empty space after inserted entity
    mentionReplacedContent = Modifier.insertText(
      mentionReplacedContent,
      mentionReplacedContent.getSelectionAfter(),
      " "
    );

    // Change in editor state
    const updatedEditorState = EditorState.push(
      newEditorState,
      mentionReplacedContent,
      "insert-fragment"
    );

    // Return new editor with selection to be applied
    return EditorState.forceSelection(
      updatedEditorState,
      mentionReplacedContent.getSelectionAfter()
    );
  };

  const addEntity = (data: MentionData) => {
    const newEditorState = generateEntity(data, editorState);

    handleOnChange(newEditorState);

    // Focus in the editor
    setTimeout(() => {
      editorRef?.current?.focus();
    }, 0);
  };

  const addEntities = (newEntities: MentionData[]) => {
    let newEditorState = editorState;
    newEntities.forEach((newEntity) => {
      newEditorState = generateEntity(newEntity, newEditorState);
    });

    handleOnChange(newEditorState);

    // Focus in the editor
    setTimeout(() => {
      editorRef?.current?.focus();
    }, 0);
  };

  const enabledPreview = previewMode && !!(personId || placeholders);

  return (
    <>
      {type === DraftTypes.multiline && (
        <PlaceholderMenu
          allPlaceholders={allPlaceholders}
          customPlaceholders={allCustomPlaceholders}
          handleOnSelect={addEntity}
          handleOnMultiSelect={addEntities}
        />
      )}
      <div className={classes.draft}>
        <TextField
          className={clsx({
            [classes.preview]: enabledPreview,
            [classes.multiline]: type === DraftTypes.multiline,
            [classes.singleline]: type === DraftTypes.singleline,
          })}
          id="editor"
          fullWidth
          multiline
          label={label}
          value={plainText}
          error={!!error}
          helperText={error?.message}
          onClick={handleOnClick}
          onFocus={handleStopPreviewOnInputFocus}
          InputProps={{
            inputProps: {
              component: Editor,
              editorRef,
              editorState,
              handleOnChange,
              keyBindingFn,
              plugins,
              decorators,
            },
            inputComponent: DraftField,
            endAdornment: endAdornmentSubmit ? (
              <InputAdornment position="end" className={classes.inputAdornment}>
                <IconButton type="submit">
                  <SendIcon color={plainText.length ? "primary" : "inherit"} />
                </IconButton>
              </InputAdornment>
            ) : null,
          }}
        />
        {type === DraftTypes.multiline && <PreviewView template={plainText} />}
        {type === DraftTypes.multiline && (
          <div
            className={clsx({
              [classes.actions]: true,
              [classes.actionsPreview]: previewMode,
              [classes.actionsError]: !!error,
            })}
          >
            {maxlength && (
              <Typography
                variant="caption"
                className={clsx(classes.counter, {
                  [classes.counterError]: maxlength - plainText.length < 0,
                })}
              >
                Remaining characters: {maxlength - plainText.length}/{maxlength}
              </Typography>
            )}
          </div>
        )}
        <div className={classes.actions}>
          <PreviewPerson />
        </div>
      </div>
    </>
  );
};

interface DraftEditorContainerProps {
  control: Control;
  error: FieldError | undefined;
  type: DraftTypes;
  maxlength?: number;
  name: string;
  label: string;
  endAdornmentSubmit?: boolean;
}

const DraftEditorContainer = ({
  control,
  error,
  type,
  maxlength,
  name,
  label,
  endAdornmentSubmit,
}: DraftEditorContainerProps): React.ReactElement => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ value, onChange }) => (
        <DraftEditor
          type={type}
          maxlength={maxlength}
          onFieldChange={onChange}
          defaultValue={value}
          error={error}
          label={label}
          endAdornmentSubmit={endAdornmentSubmit}
        />
      )}
    />
  );
};

DraftEditorContainer.defaultProps = {
  maxlength: undefined,
  endAdornmentSubmit: undefined,
};

export default DraftEditorContainer;
