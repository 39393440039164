import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const EmptyInteractionContainer = (): React.ReactElement => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
    }}
  >
    <div>
      <Typography variant="h6" align="center" paragraph>
        You don&apos;t have any person selected
      </Typography>
      <Typography color="textSecondary" variant="body2" align="center">
        Select or search for a person to see interaction history.
      </Typography>
    </div>
  </Box>
);

export default EmptyInteractionContainer;
