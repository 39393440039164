import { BaseTextFieldProps } from "@mui/material";
import Autocomplete, {
  AutocompleteRenderInputParams,
} from "@mui/material/Autocomplete";
import { COUNTRY_CODES } from "core/utils/countryHandler";
import { ICountryValues } from "modules/Account/models";
import React from "react";
import { Control, Controller, FieldErrors } from "react-hook-form";
import TextField from "ui-kit/atoms/TextField";

interface CountryAutocompleteProps {
  control: Control;
  errors: FieldErrors;
  InputProps?: BaseTextFieldProps;
}

const CountryAutocomplete = ({
  control,
  errors,
  InputProps,
}: CountryAutocompleteProps): React.ReactElement => {
  return (
    <Controller
      render={(controllerProps) => (
        <Autocomplete
          {...controllerProps}
          options={COUNTRY_CODES}
          onChange={(_, newData) => controllerProps.onChange(newData)}
          getOptionLabel={(option: ICountryValues) => option.name}
          isOptionEqualToValue={(
            option: ICountryValues,
            value: ICountryValues
          ) => {
            return option.id === value.id;
          }}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <TextField
              {...InputProps}
              {...params}
              fullWidth
              label="Select country"
              variant="outlined"
              error={!!errors.country}
              helperText={
                errors.country?.message || errors.country?.id?.message
              }
            />
          )}
        />
      )}
      name="country"
      control={control}
    />
  );
};

export default CountryAutocomplete;
