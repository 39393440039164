/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Method for general API error handling outside of components
 * */
import * as Sentry from "@sentry/react";
import axios from "axios";
import { IError } from "core/models";
import { snackbarHandler } from "core/utils/snackbarHandler";
import { get } from "lodash";
import {
  anonymousUser,
  getUser,
  removeToken,
  storeUser,
} from "modules/User/utils";
import { FieldError } from "react-hook-form";

enum ErrorVariants {
  internal = 500,
  throttled = 429,
}

interface IErrorHandler {
  data: {
    non_field_errors: string[];
  };
  status: ErrorVariants;
}

export interface IErrorResponse {
  response: IErrorHandler;
}

const isLiteralObject = (a: any) => {
  return !!a && a.constructor === Object;
};

interface IBaseError {
  code: string;
  message: string;
}

export const handleNonFieldErrors = (
  non_field_errors: IBaseError[] | undefined
) => {
  if (non_field_errors) {
    non_field_errors.forEach((non_field_error: string | IBaseError) => {
      if (typeof non_field_error === "string") {
        snackbarHandler.error(non_field_error);
      } else {
        const error = get(non_field_error, "message");
        if (error) {
          snackbarHandler.error(error);
        }
      }
    });
  }
};

interface ICommonErrorHandler {
  data: {
    [key: string]: any;
  };
  status: ErrorVariants;
}

export const errorHandler = (
  response: ICommonErrorHandler,
  setError: ((name: any, error: FieldError) => void) | undefined = undefined
): void => {
  if (!response) {
    snackbarHandler.error(
      `Sorry this isn't working. We now know about this issue and working on a fix.`
    );
    return;
  }
  // Backend issue
  if (response?.status === ErrorVariants.internal) {
    snackbarHandler.error(
      `Sorry this isn't working. We now know about this issue and working on a fix.`
    );
    return;
  }

  // Too many requests
  if (response?.status === ErrorVariants.throttled) {
    snackbarHandler.error(
      `You have sent too many requests to us recently. Try again in 1 minute.`
    );
    return;
  }

  const errors = response.data;

  // const errorFieldMsg = get(errors, "message");
  // if (errorFieldMsg && typeof errorFieldMsg === "string") {
  //   snackbarHandler.error(errorFieldMsg);
  //   return;
  // }

  Object.keys(errors).forEach((key) => {
    const field = errors[key];

    if (key === "detail") {
      snackbarHandler.error(field);
    }

    if (field && key !== "non_field_errors") {
      let errorField = field;
      let errorKey = key;
      if (isLiteralObject(field)) {
        [errorField] = Object.values(field);
        errorKey += `.${Object.keys(field)[0]}`;
      }

      if (errorField.non_field_errors) {
        handleNonFieldErrors(errorField.non_field_errors);
      }

      if (Array.isArray(errorField)) {
        errorField.forEach((err: IBaseError | string) => {
          const errMsg = typeof err === "string" ? err : get(err, "message");
          if (setError && errMsg) {
            setError(errorKey, {
              message: errMsg,
              type: "error",
            });
          }
        });
      }
    }
    if (errors.non_field_errors) {
      handleNonFieldErrors(errors.non_field_errors);
    }
  });
};

export const handleFetchError = (err: any) => {
  const user = getUser();
  Sentry.captureException(err, {
    extra: { user, headers: get(err, "response.config.headers") },
  });
  throw new Error(String(err));
};

export const handleCaptureMessage = (msg: string) => {
  const user = getUser();
  Sentry.captureMessage(msg, {
    extra: { user, headers: axios.defaults.headers.common },
  });
};

export const handleInvalidToken = (err: IError) => {
  const detail = get(err, "response.data.code");

  if (detail !== "authentication_failed") {
    return;
  }

  const user = getUser();
  Sentry.captureMessage(detail, {
    extra: { user, headers: axios.defaults.headers.common },
  });

  snackbarHandler.warning(
    "You signed out in a different tab. You need to re-login."
  );
  setTimeout(() => {
    storeUser(anonymousUser);
    removeToken();
    window.location.reload();
  }, 2500);
};

export const handleInvalidLogout = (err: any) => {
  const detail = get(err, "response.data.code");

  if (detail !== "authentication_failed") {
    return false;
  }

  const user = getUser();
  Sentry.captureMessage(detail, {
    extra: { user, headers: axios.defaults.headers.common },
  });

  return true;
};
