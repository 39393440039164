import React from "react";
import { useQuery } from "react-query";
import Skeleton from "@mui/material/Skeleton";
import InteractionService from "modules/Interaction/services";
import { getConnectionChipData } from "modules/Person/utils";
import { Chip } from "ui-kit/atoms/Chip";

interface ConnectionChipProps {
  personId: number | undefined;
  accountId: number;
  active_connection: boolean | undefined;
}

const ConnectionChip = ({
  personId,
  accountId,
  active_connection,
}: ConnectionChipProps): React.ReactElement => {
  const fetchPendingInvitations = async () => {
    try {
      const { data } = await InteractionService.fetchPendingInvitation(
        accountId,
        personId
      );
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };
  const { data, isLoading } = useQuery(
    ["interactions-pending", accountId, personId],
    () => fetchPendingInvitations(),
    {
      enabled:
        active_connection !== undefined && !!personId && !active_connection,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  const { label, color } = getConnectionChipData(
    active_connection,
    !!data?.count
  );
  return isLoading ? (
    <Skeleton sx={{ maxWidth: 50 }} />
  ) : (
    <Chip variant="outlined" size="small" color={color} label={label} />
  );
};

export default ConnectionChip;
