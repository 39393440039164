import axios, { AxiosResponse } from "axios";
import { IInteractions, IInteractionCount } from "modules/Interaction/models";
import {
  ICampaign,
  ICampaigns,
  IAllCampaigns,
  ICreateCampaignFormValues,
  IUpdateCampaignFormValues,
  ICampaignSequenceFormValues,
  ICampaignSequences,
  ICampaignSequence,
  ICampaignPersonFormValues,
  ICampaignPersons,
  ICampaignSearchFormValues,
  ICampaignSearches,
  ICampaignSearch,
  ICampaignRemovePersonFormValues,
  ISearchPeopleToCampaignFormValues,
  ICampaignSearchResultFormValues,
  ICampaignRemoveSearchResultFormValues,
  ICampaignStats,
  IMovePeopleFormValues,
  ExecutionKeys,
} from "../models";

const createCampaign = (
  data: ICreateCampaignFormValues
): Promise<AxiosResponse<ICampaign>> => {
  return axios.post("/inevitable/campaigns/", data);
};

const fetchAccountCampaigns = (
  page: number,
  accountId: number,
  search = "",
  status = ""
): Promise<AxiosResponse<ICampaigns>> => {
  return axios.get(
    `/inevitable/campaigns/?page=${page}&account_id=${accountId}&search=${search}${status}`
  );
};

const fetchCampaigns = (
  page: number,
  pageSize: number,
  accountId: number,
  search = "",
  status = ""
): Promise<AxiosResponse<ICampaigns>> => {
  return axios.get(
    `/inevitable/campaigns/?page=${page}&page_size=${pageSize}&account_id=${accountId}${status}&search=${search}`
  );
};

const fetchActiveCampaignsCount = (
  accountId: number
): Promise<AxiosResponse<ICampaigns>> => {
  return axios.get(
    `/inevitable/campaigns/?page=1&page_size=1&execution=RU&execution=ST&account_id=${accountId}`
  );
};

const fetchDraftCampaigns = (
  page: number,
  pageSize: number,
  accountId: number,
  search = ""
): Promise<AxiosResponse<ICampaigns>> => {
  return axios.get(
    `/inevitable/campaigns/?page=${page}&page_size=${pageSize}&execution=DR&account_id=${accountId}&search=${search}`
  );
};

const fetchDraftCampaignsCount = (
  accountId: number
): Promise<AxiosResponse<ICampaigns>> => {
  return axios.get(
    `/inevitable/campaigns/?page=1&page_size=1&execution=DR&account_id=${accountId}`
  );
};

const fetchAllAccountCampaigns = (
  accountId: number
): Promise<AxiosResponse<ICampaigns>> => {
  return axios.get(
    `/inevitable/campaigns/?page=1&page_size=1000&account_id=${accountId}`
  );
};

const fetchCampaign = (id: number): Promise<AxiosResponse<ICampaign>> => {
  return axios.get(`/inevitable/campaigns/${id}/`);
};

const fetchAllCampaigns = (): Promise<AxiosResponse<IAllCampaigns>> => {
  return axios.get(`/inevitable/campaigns/?page=1&page_size=1000`);
};

const fetchAccountExistingCampaigns = (
  accountId: number
): Promise<AxiosResponse<IAllCampaigns>> => {
  return axios.get(
    `/inevitable/campaigns/?page=1&page_size=1&account_id=${accountId}`
  );
};

const fetchAccountLaunchedExistingCampaigns = (
  accountId: number
): Promise<AxiosResponse<IAllCampaigns>> => {
  return axios.get(
    `/inevitable/campaigns/?page=1&page_size=1&account_id=${accountId}&execution=${ExecutionKeys.RU}`
  );
};

const fetchExistingCampaigns = (): Promise<AxiosResponse<IAllCampaigns>> => {
  return axios.get(`/inevitable/campaigns/?page=1&page_size=1`);
};

const fetchExistingRunningCampaigns = (): Promise<
  AxiosResponse<IAllCampaigns>
> => {
  return axios.get(`/inevitable/campaigns/?page=1&page_size=1&execution=RU`);
};

const fetchRecentPersonCampaigns = (
  accountId: number,
  personId = "",
  searchResultId = ""
): Promise<AxiosResponse<ICampaigns>> => {
  return axios.get(
    `/inevitable/campaigns/?page=1&page_size=3&interaction__person_id=${personId}&interaction__search_id=${searchResultId}&campaign_execution__in=${ExecutionKeys.RU}&campaign_execution__in=${ExecutionKeys.ST}&account_id=${accountId}`
  );
};

const updateCampaign = (
  id: number,
  data: IUpdateCampaignFormValues
): Promise<AxiosResponse<ICampaign>> => {
  return axios.patch(`/inevitable/campaigns/${id}/`, data);
};

const deleteCampaign = (id: number): Promise<AxiosResponse<ICampaign>> => {
  return axios.delete(`/inevitable/campaigns/${id}/`);
};

const fetchCampaignStats = (
  campaignIds: number[]
): Promise<AxiosResponse<ICampaignStats>> => {
  return axios.get(
    `/inevitable/interactions/counts_per_campaign/?failed=false&campaign_id__in=${campaignIds}`
  );
};

const fetchCampaignInteractions = (
  campaignId: number,
  page: number,
  pageSize: number,
  search = "",
  statusQuery = "",
  connectionQuery = "",
  ordering = ""
): Promise<AxiosResponse<IInteractions>> => {
  return axios.get(
    `/inevitable/interactions/distinct_on_contact/?page=${page}&page_size=${pageSize}&campaign_id=${campaignId}&search=${search}&direct_action__isnull=true&failed=false&ordering=${ordering}${statusQuery}${connectionQuery}`
  );
};

const fetchDraftCampaignInteractions = (
  campaignId: number,
  page: number,
  pageSize: number,
  search = "",
  connectionQuery = ""
): Promise<AxiosResponse<IInteractions>> => {
  return axios.get(
    `/inevitable/interactions/distinct_on_contact/?page=${page}&page_size=${pageSize}&search=${search}&campaign_id=${campaignId}&direct_action__isnull=true&failed=false&ordering=platform_created_at${connectionQuery}`
  );
};

const fetchCampaignInteractionsCountPerSearch = (
  campaignId: number,
  searchId: number
): Promise<AxiosResponse<IInteractionCount>> => {
  return axios.get(
    `/inevitable/interactions/distinct_on_contact_count/?campaign_id=${campaignId}&search_id=${searchId}`
  );
};

const fetchAllByOneCampaignInteractions = (
  campaignId: number | undefined,
  page: number
): Promise<AxiosResponse<IInteractions>> => {
  return axios.get(
    `/inevitable/interactions/distinct_on_contact/?page=${page}&page_size=1&campaign_id=${campaignId}&direct_action__isnull=true&failed=false&ordering=id`
  );
};

const fetchExistingCampaignInteractions = (
  campaignId: number
): Promise<AxiosResponse<IInteractionCount>> => {
  return axios.get(
    `/inevitable/interactions/distinct_on_contact_count/?page=1&page_size=1&campaign_id=${campaignId}&direct_action__isnull=true&failed=false`
  );
};

const createCampaignSequence = (
  data: ICampaignSequenceFormValues
): Promise<AxiosResponse<ICampaignSequence>> => {
  return axios.post("/inevitable/campaigns_sequences/", data);
};

const fetchAllCampaignSequences = (
  id: number
): Promise<AxiosResponse<ICampaignSequences>> => {
  return axios.get(
    `/inevitable/campaigns_sequences/?page=1&page_size=1000&campaign_id=${id}&ordering=id`
  );
};

const fetchCampaignSequences = (
  id: number,
  page: number
): Promise<AxiosResponse<ICampaignSequences>> => {
  return axios.get(
    `/inevitable/campaigns_sequences/?page=${page}&campaign_id=${id}&ordering=id`
  );
};

const fetchCampaignSequence = (
  id: number
): Promise<AxiosResponse<ICampaignSequence>> => {
  return axios.get(`/inevitable/campaigns_sequences/${id}`);
};

const fetchExistingCampaignSequences = (
  id: number
): Promise<AxiosResponse<ICampaignSequences>> =>
  axios.get(
    `/inevitable/campaigns_sequences/?page=1&page_size=1&campaign_id=${id}`
  );

const deleteCampaignSequence = (
  id: number
): Promise<AxiosResponse<ICampaignSequence>> => {
  return axios.delete(`/inevitable/campaigns_sequences/${id}/`);
};

const createCampaignPersons = (
  data: ICampaignPersonFormValues
): Promise<AxiosResponse<ICampaignPersons>> => {
  return axios.post(
    `/inevitable/campaigns/${data.campaign}/add_persons/`,
    data
  );
};

const fetchCampaignPeople = (
  id: number,
  page: number,
  search = ""
): Promise<AxiosResponse<ICampaignPersons>> => {
  return axios.get(
    `/inevitable/campaigns_persons/?page=${page}&campaign_id=${id}&search=${search}`
  );
};

const deleteCampaignPersons = (
  data: ICampaignRemovePersonFormValues
): Promise<AxiosResponse<ICampaignPersons>> => {
  return axios.post(
    `/inevitable/campaigns/${data.campaign}/remove_persons/`,
    data
  );
};

const createCampaignSearch = (
  data: ICampaignSearchFormValues
): Promise<AxiosResponse<ICampaignSearch>> => {
  return axios.post("/inevitable/campaigns_searches/", {
    ...data,
    add_automatically: true,
  });
};

const fetchCampaignSearches = (
  id: number,
  search = ""
): Promise<AxiosResponse<ICampaignSearches>> => {
  return axios.get(
    `/inevitable/campaigns_searches/?campaign_id=${id}&search=${search}&page=1&page_size=1000`
  );
};

const fetchExistingCampaignSearches = (
  id: number
): Promise<AxiosResponse<ICampaignSearches>> =>
  axios.get(
    `/inevitable/campaigns_searches/?page=1&page_size=1&campaign_id=${id}`
  );

const deleteCampaignSearch = (
  id: string
): Promise<AxiosResponse<ICampaignSearch>> => {
  return axios.delete(`/inevitable/campaigns_searches/${id}/`);
};

const createCampaignSearchPersons = (
  data: ISearchPeopleToCampaignFormValues
): Promise<AxiosResponse<ICampaignSearch>> => {
  return axios.post(
    `/inevitable/campaigns/${data.campaign}/add_persons_from_search/`,
    data
  );
};

const createCampaignSearchResults = (
  data: ICampaignSearchResultFormValues
): Promise<AxiosResponse<void>> => {
  return axios.post(
    `/inevitable/campaigns/${data.campaign}/add_search_results/`,
    data
  );
};

const deleteCampaignSearchResults = (
  data: ICampaignRemoveSearchResultFormValues
): Promise<AxiosResponse<void>> => {
  return axios.post(
    `/inevitable/campaigns/${data.campaign}/remove_search_results/`,
    data
  );
};

const movePeopleToAnotherCampaign = (
  data: IMovePeopleFormValues
): Promise<AxiosResponse<void>> => {
  return axios.post(
    `/inevitable/campaigns/${data.campaign}/move_persons/`,
    data
  );
};

const CampaignService = {
  createCampaign,
  fetchAllAccountCampaigns,
  fetchAccountCampaigns,
  fetchAccountExistingCampaigns,
  fetchAccountLaunchedExistingCampaigns,
  fetchCampaigns,
  fetchActiveCampaignsCount,
  fetchDraftCampaigns,
  fetchDraftCampaignsCount,
  fetchAllCampaigns,
  fetchExistingCampaigns,
  fetchExistingRunningCampaigns,
  fetchRecentPersonCampaigns,
  fetchCampaign,
  updateCampaign,
  deleteCampaign,
  fetchCampaignStats,
  fetchCampaignInteractions,
  fetchDraftCampaignInteractions,
  fetchAllByOneCampaignInteractions,
  fetchExistingCampaignInteractions,
  fetchCampaignInteractionsCountPerSearch,
  createCampaignSequence,
  fetchAllCampaignSequences,
  fetchCampaignSequences,
  fetchCampaignSequence,
  fetchExistingCampaignSequences,
  deleteCampaignSequence,
  createCampaignPersons,
  fetchCampaignPeople,
  deleteCampaignPersons,
  createCampaignSearch,
  fetchCampaignSearches,
  fetchExistingCampaignSearches,
  deleteCampaignSearch,
  createCampaignSearchPersons,
  createCampaignSearchResults,
  deleteCampaignSearchResults,
  movePeopleToAnotherCampaign,
};

export default CampaignService;
