import { History, Location } from "history";
import { matchPath, match } from "react-router-dom";
import querystring, { ParsedUrlQuery } from "querystring";
import { IFilterOptions } from "core/models";
import RouterConstants from "core/routes/constants";
import { get } from "lodash";

type IQuery = {
  [x: string]: string | string[] | null;
};

// type ParsedUrlString = NodeJS.Dict<string>;

const isCurrentRoute = (
  currentPath: string,
  testPath: string,
  exactPath?: boolean
): match<boolean> | null => {
  const exact = exactPath || testPath === RouterConstants.ROOT;
  return matchPath(currentPath, {
    path: testPath,
    exact,
    strict: true,
  });
};

const isPartialRoute = (matchingPath: string, testPath: string): boolean => {
  return matchingPath.includes(testPath);
};

const parseQueryParams = (location: Location): ParsedUrlQuery => {
  return querystring.parse(location.search.substr(1));
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getQueryParams = (location: Location): any => {
  const queryParams = parseQueryParams(location);
  const params: { [key: string]: string | string[] } = {};
  Object.keys(queryParams).forEach((key) => {
    const value = queryParams[key];
    if (Array.isArray(value)) {
      [params[key]] = queryParams[key];
    } else {
      params[key] = queryParams[key];
    }
  });
  return params;
};

/**
 * @param {Object} query
 */
const addQuery = (query: IQuery, history: History): void => {
  const queryParams = parseQueryParams(history.location);
  const queryString = querystring.stringify({ ...queryParams, ...query });
  history.replace({ search: `?${queryString}` });
};

/**
 * @param {Object} queryString
 */
const addQueryString = (queryString: string, history: History): void => {
  history.replace({ search: `?${queryString}` });
};

/**
 * @param {Object} queryString
 */
const getStringifiedQuery = (queryParams: {
  [x: string]: boolean | string | string[] | number;
}): string => querystring.stringify(queryParams);

/**
 * @param {...String} queryNames
 */
const removeQuery = (queryNames: string[], history: History): void => {
  const queryParams = parseQueryParams(history.location);
  queryNames.forEach((q) => delete queryParams[q]);
  const queryString = querystring.stringify(queryParams);
  history.replace({ search: `?${queryString}` });
};

const getDataName = (
  data: any[] | undefined,
  id: string,
  queryName: string
) => {
  const value = data?.find((i) => {
    return i.id === Number(id);
  });
  return get(value, queryName);
};

const arrLimit = 1;
const getSmartArrJoin = (arr: string[]) => {
  if (arr.length > 2) {
    const arrValue = arr.slice(0, arrLimit).join(", ");
    return `${arrValue} and ${arr.length - arrLimit} more`;
  } else {
    return arr.join(", ");
  }
};

const generateFilterFormattedName = (
  filters: string | string[] | undefined,
  data: any[] | undefined,
  queryName: string
): string => {
  if (!filters) {
    return "";
  }
  let filterStr = "";

  // If data value, first find the name
  if (!!data) {
    if (Array.isArray(filters)) {
      const arrValues = filters.map((filter) =>
        getDataName(data, filter, queryName)
      );
      filterStr = getSmartArrJoin(arrValues);
    } else {
      filterStr = getDataName(data, filters, queryName);
    }
  } else {
    // Here return string values
    if (Array.isArray(filters)) {
      filterStr = getSmartArrJoin(filters);
    } else {
      filterStr = filters;
    }
  }

  return filterStr?.replace(/_/g, " ");
};

const generateFilterNameQuery = (
  filters: string | string[] | undefined,
  name: string
): string => {
  if (!filters) {
    return "";
  }

  let filterString = `&${name}=`;

  if (Array.isArray(filters)) {
    filterString += filters.join("%2C");
  } else {
    filterString += filters;
  }

  return filterString;
};

const generateFilterOptionQuery = (
  filters: string | string[] | undefined,
  options: IFilterOptions
): string => {
  if (!filters) {
    return "";
  }

  const filterQuery: { [x: string]: boolean } = {};

  if (Array.isArray(filters)) {
    filters.forEach((filter) => {
      const active = options.find((o) => o.id === filter);
      if (active) {
        Object.assign(filterQuery, active.query);
      }
    });
  } else {
    const active = options.find((o) => o.id === filters);
    if (active) {
      Object.assign(filterQuery, active.query);
    }
  }

  return filterQuery ? `&${getStringifiedQuery(filterQuery)}` : "";
};

const RouterUtils = {
  isCurrentRoute,
  isPartialRoute,
  getQueryParams,
  addQuery,
  addQueryString,
  removeQuery,
  parseQueryParams,
  getStringifiedQuery,
  generateFilterOptionQuery,
  generateFilterNameQuery,
  generateFilterFormattedName,
};

export default RouterUtils;
