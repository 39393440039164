import { ASCII_REGEX } from "core/utils/validationHandler";
import * as Yup from "yup";

export const ERROR_KEYS = {
  KEY: {
    TOO_SHORT: "Placeholder name minimum is 2 characters.",
    TOO_LONG: "Placeholder name maximum is 20 characters.",
    REQUIRED: "Placeholder name is required.",
    ASCII:
      "You may only use the characters A to Z, 0 to 9, and simple punctuation in placeholder name.",
  },
  VALUE: {
    REQUIRED: "Placeholder value is required.",
  },
  MESSAGE: {
    REQUIRED: "Message is required",
  },
};

function newPlaceholderSchema(): Yup.AnyObjectSchema {
  return Yup.object().shape({
    key: Yup.string()
      .required(ERROR_KEYS.KEY.REQUIRED)
      .min(2, ERROR_KEYS.KEY.TOO_SHORT)
      .max(20, ERROR_KEYS.KEY.TOO_LONG)
      .matches(ASCII_REGEX, ERROR_KEYS.KEY.ASCII),
    value: Yup.string().required(ERROR_KEYS.VALUE.REQUIRED),
  });
}

const newDynamicPlaceholderSchema = Yup.object().shape({
  with_placeholder: Yup.object().required(ERROR_KEYS.MESSAGE.REQUIRED),
  without_placeholder: Yup.string(),
});

const PlaceholderValidations = {
  newPlaceholderSchema,
  newDynamicPlaceholderSchema,
};

export default PlaceholderValidations;
