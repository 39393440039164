import { yupResolver } from "@hookform/resolvers/yup";
import { DialogContentText } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { errorHandler, IErrorResponse } from "core/utils/errorHandler";
import { snackbarHandler } from "core/utils/snackbarHandler";
import { AccountContext } from "modules/Account/context";
import { CampaignQuery } from "modules/Campaign/models";
import {
  ICreateSequenceCopyFormInput,
  ICreateSequenceCopyFormValue,
  ISequence,
} from "modules/Sequence/models";
import SequenceService from "modules/Sequence/services";
import SequenceValidations from "modules/Sequence/validations";
import React from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import Button from "ui-kit/atoms/Button";
import DialogHeader from "ui-kit/components/DialogHeader";
import SequenceSingleAutocomplete from "../SequenceSingleAutocomplete";

interface CreateSequenceCopyProps {
  sequenceId: number | undefined;
  open: boolean;
  handleClose: () => void;
  campaignId: number;
}

const defaultValues = {
  sequence: null,
};

const CreateSequenceCopy = ({
  sequenceId,
  open,
  handleClose,
  campaignId,
}: CreateSequenceCopyProps): React.ReactElement => {
  const queryClient = useQueryClient();

  const schema = SequenceValidations.copySequenceSchema;
  const { control, errors, handleSubmit, setError } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    account: { id: accountId },
  } = React.useContext(AccountContext);

  // Create new sequence that we're replacing with
  const mutateNewSequence = useMutation(
    (newData: ICreateSequenceCopyFormValue) =>
      SequenceService.updateSequenceCopy(newData),
    {
      onSuccess: (response: { data: ISequence }) => {
        queryClient.invalidateQueries([CampaignQuery.actions, campaignId]);
        queryClient.invalidateQueries(["actions", sequenceId]);

        snackbarHandler.success("Template successfully copied!");
        handleClose();
      },
      onError: (errorMutation: IErrorResponse) => {
        errorHandler(errorMutation.response, setError);
      },
    }
  );

  const onSubmit = (data: ICreateSequenceCopyFormInput) => {
    mutateNewSequence.mutate({
      sequence: data.sequence.id,
      name: `${data.sequence.label} - copy`,
      account: accountId,
      sequence_to_override: sequenceId,
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="template-dialog"
      maxWidth="sm"
      PaperProps={{ sx: { width: "100%" } }}
    >
      <form>
        <DialogHeader
          title="Start from a template"
          onHandleClose={handleClose}
        />
        <DialogContent dividers>
          <DialogContentText>
            Select a template to pre-compose steps in your new campaign.
          </DialogContentText>

          <DialogContentText marginBottom="20px !important">
            A copy of this template will be created and changes made inside this
            campaign will not update the template globally.
          </DialogContentText>
          <SequenceSingleAutocomplete control={control} errors={errors} />
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            onClick={handleSubmit(onSubmit)}
            color="primary"
            variant="contained"
          >
            Use template
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateSequenceCopy;
