import Box from "@mui/material/Box";
import { IGetStartedListItem } from "modules/Dashboard/models";
import React from "react";
import GetStartedListItem from "../GetStartedListItem";

interface GetStartedBoxProps {
  index: number;
  items: IGetStartedListItem[];
}

const GetStartedBox = ({
  index,
  items,
}: GetStartedBoxProps): React.ReactElement => {
  return (
    <Box>
      {items?.map((item, i) => (
        <Box key={item.id}>
          <GetStartedListItem
            item={item}
            index={i}
            selected={index === i}
            isLast={items.length - 1 === i}
          />
        </Box>
      ))}
    </Box>
  );
};

export default GetStartedBox;
