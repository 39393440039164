import GoogleSignIn from "modules/User/components/GoogleSignIn";
import React from "react";
import BackgroundOverlay from "ui-kit/components/BackgroungOverlay";
import Loader from "ui-kit/components/Loader";

const AuthLoading = (): React.ReactElement => {
  return (
    <BackgroundOverlay>
      <GoogleSignIn>Loading...</GoogleSignIn>
      <Loader minHeight={300} text="Loading your user account..." />
    </BackgroundOverlay>
  );
};

export default AuthLoading;
