import { IArrayResponse } from "core/models";

export enum CreateSequenceKeys {
  "createNew" = "createNew",
  "copyExisting" = "copyExisting",
}

export enum SequenceDetailTabs {
  "actions" = "actions",
  "settings" = "settings",
}

export interface ICreateSequenceFormInput {
  id?: number;
  inputValue?: string;
  name: string;
  sequence?: { id: number; label: string };
  sequenceName?: string;
}

export interface ICreateSequenceFormValues {
  id?: number;
  inputValue?: string;
  name: string;
  sequence?: string | number;
  sequenceName?: string;
}

export interface ICreateSequenceFormPost extends ICreateSequenceFormValues {
  account: number;
}

export interface ISequence {
  id: number;
  name: string;
  created: string;
}

export interface ICreateSequenceCopyFormInput {
  sequence: { id: number; label: string };
}

export interface ICreateSequenceCopyFormValue {
  sequence: number;
  account: number;
  name: string;
  sequence_to_override?: number;
}

export interface ISequences extends IArrayResponse {
  results: ISequence[];
}

export interface ISequenceParams {
  sequenceName: string | undefined;
  sequenceId: number;
}

export interface ICreateSequenceNew {
  name: string;
  account: number;
  campaign?: number;
}

export interface IUpdateSequenceFormValues {
  name: string;
  id: number;
}

export type ISequenceMissingAction = undefined | boolean;
