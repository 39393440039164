/* eslint-disable no-restricted-globals */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from "@hookform/resolvers/yup";
import { DialogActions, Grid, useMediaQuery } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { errorHandler, IErrorResponse } from "core/utils/errorHandler";
import { snackbarHandler } from "core/utils/snackbarHandler";
import { AccountContext } from "modules/Account/context";
import { AccountQuery, IAccount } from "modules/Account/models";
import { ISearchTabProps } from "modules/Search/constants";
import { SearchContext } from "modules/Search/context";
import { SearchTabIndex } from "modules/Search/models";
import SearchUtils from "modules/Search/utils";
import SearchValidations from "modules/Search/validations";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import Button, { ColorTypes } from "ui-kit/atoms/Button";
import ButtonTabs from "ui-kit/components/ButtonTabs";
import SearchInstructions from "ui-kit/components/SearchInstructions";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.app.palette.shadow.secondary}`,
    position: "relative",
  },
  content: {
    padding: theme.spacing(8),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(4),
    },
  },
  box: {
    padding: theme.spacing(3, 6),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(7),
    },
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  actions: {
    borderTop: `1px solid ${theme.app.palette.shadow.secondary}`,
    backgroundColor: theme.palette.common.white,
  },
}));

interface SearchItemProps {
  selectedTab: number;
  setSelectedTab: (index: number) => void;
  setDefaultValues: ({
    searchName,
    url,
    search,
  }: {
    url: string;
    searchName: string;
    search: null;
  }) => void;
  tabs: ISearchTabProps[];
  FormProps: {
    id?: number | string;
    name?: [string, number | string | null];
    func: any;
    successMsg?: (data: any) => void;
    errorMsg?: (data: any) => void;
    onSuccess?: (data: any, credentials: any) => void;
    onSettled?: () => void;
    onError?: () => void;
    format: (data: any) => any;
    schema?: any;
    defaultValues?: any;
    resetForm?: boolean;
    resetFormData?: any;
    isLoading?: boolean;
    setLoading?: (v: boolean) => void;
    submitButtonRef?: React.MutableRefObject<HTMLButtonElement | null>;
    handleLoading?: (v: boolean) => void;
    handleTabClean?: () => void;
  };
  SkipButtonProps?: {
    value?: string;
    onClick?: () => void;
    active?: boolean;
  };
  SubmitButtonProps?: {
    value?: string;
    color?: string;
    endIcon?: React.ReactElement | null;
    disabled?: boolean;
    hidden?: boolean;
    removeDisabled?: boolean;
  };
}

const SearchItem = ({
  selectedTab,
  setSelectedTab,
  setDefaultValues,
  tabs,
  SkipButtonProps,
  SubmitButtonProps,
  FormProps,
}: SearchItemProps): React.ReactElement => {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const desktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  const methods = useForm<any>({
    ...(FormProps.schema && { resolver: yupResolver(FormProps.schema) }),
    defaultValues: FormProps.defaultValues,
  });
  const { errors, control, handleSubmit, setError, reset, watch, getValues } =
    methods;

  const {
    account: { id: accountId },
  } = React.useContext(AccountContext);

  const {
    search: { columns },
  } = React.useContext(SearchContext);

  const accountData: IAccount | undefined = queryClient.getQueryData([
    AccountQuery.account,
    accountId,
  ]);

  const resetForm = () => {
    reset({
      url: "",
      searchName: "",
      name: "",
    });
  };

  const urlField = watch("url");
  React.useEffect(() => {
    // If url is google sheet and tab isn't google sheet, redirect
    if (
      !!urlField &&
      selectedTab !== SearchTabIndex.google_sheet &&
      SearchUtils.isGoogleDocsUrl(urlField)
    ) {
      resetForm();
      setSelectedTab(SearchTabIndex.google_sheet);

      setDefaultValues({ url: urlField, search: null, searchName: "" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab, urlField]);

  const mutateOnSubmit = useMutation((data) => FormProps.func(data), {
    onSuccess: ({ data }, credentials) => {
      queryClient.invalidateQueries(FormProps.name);
      if (FormProps.onSuccess) {
        FormProps.onSuccess(data, credentials);
      } else {
        snackbarHandler.success("Successfully created!");
      }

      // By default reset form on submit
      resetForm();
    },
    onError: (error: IErrorResponse) => {
      if (FormProps.errorMsg) {
        return FormProps.errorMsg(error.response?.data);
      }

      if (FormProps.onError) {
        FormProps.onError();
      }
      errorHandler(error.response, setError);

      if (FormProps?.handleLoading) {
        FormProps.handleLoading(false);
      }
    },
    onSettled: () => {
      if (FormProps.onSettled) {
        FormProps.onSettled();
      }
    },
  });

  const handleChange = (event: React.SyntheticEvent, index: number) => {
    setSelectedTab(index);

    if (FormProps.handleTabClean) {
      FormProps.handleTabClean();
    }
  };

  const onSubmit = async (data: any) => {
    if (FormProps?.handleLoading) {
      FormProps.handleLoading(true);
    }

    // Don't continue if already activated
    if (FormProps.isLoading || !!FormProps.submitButtonRef?.current?.disabled) {
      return;
    }

    const newData = FormProps.format(data);
    const variant = newData.strategy;

    // Validation if URL doesn't match any search variant
    if (variant === null) {
      setError("url", {
        message: SearchValidations.ERROR_KEYS.URL.INVALID,
        type: "error",
      });

      // Stop loading
      if (FormProps?.handleLoading) {
        return FormProps.handleLoading(false);
      }

      return;
    }

    // Check if account has active sales_nav or recruiter subscription and notify user if not before continue
    if (
      !SearchValidations.getConfirmLinkedInLicenses(
        variant,
        accountData?.system_meta_data
      )
    ) {
      // Stop loading
      if (FormProps?.handleLoading) {
        return FormProps.handleLoading(false);
      }

      return;
    }

    mutateOnSubmit.mutate(newData);
  };

  const {
    component: Component,
    componentProps,
    instructionProps,
  } = tabs[selectedTab];

  return (
    <FormProvider {...methods}>
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.content}>
          <div className={classes.root}>
            <ButtonTabs
              tabs={tabs}
              selectedTab={selectedTab}
              handleChange={handleChange}
            />
            <Grid container className={classes.box}>
              <Grid
                item
                // If Google Sheet column matching, show fullWidth
                {...(!columns &&
                  !!desktop && {
                    md: 6,
                    pr: 7.5,
                  })}
                sx={{ width: "100%" }}
              >
                <Component
                  control={control}
                  errors={errors}
                  isLoading={FormProps.isLoading}
                  {...componentProps}
                />
              </Grid>
              {!!desktop && (
                <SearchInstructions instructionProps={instructionProps} />
              )}
            </Grid>
          </div>
        </div>
        <DialogActions className={classes.actions}>
          <Button
            variant="contained"
            type="submit"
            color={(SubmitButtonProps?.color as ColorTypes) || "primary"}
            endIcon={SubmitButtonProps?.endIcon}
            disabled={FormProps.isLoading}
            ref={FormProps.submitButtonRef}
          >
            {SubmitButtonProps?.value}
          </Button>
        </DialogActions>
      </form>
    </FormProvider>
  );
};

export default SearchItem;
