import Autocomplete, {
  AutocompleteRenderInputParams,
} from "@mui/material/Autocomplete";
import { useTheme } from "@mui/styles";
import { CampaignLocale, CampaignLocaleNames } from "modules/Campaign/models";
import { CAMPAIGN_LOCALE } from "modules/Campaign/utils";
import React from "react";
import { Control, Controller, FieldError } from "react-hook-form";
import TextField from "ui-kit/atoms/TextField";

interface IControllerProps {
  control: Control;
  name: string;
  error: FieldError | undefined;
}

interface ILocaleFormInput {
  id: CampaignLocale | string;
  name: CampaignLocaleNames | string;
}

const LocaleAutocomplete = ({
  control,
  error,
  name,
}: IControllerProps): React.ReactElement => {
  const theme = useTheme();
  return (
    <Controller
      render={(props: {
        value: ILocaleFormInput;
        onChange: (newData: ILocaleFormInput) => void;
      }) => (
        <Autocomplete
          {...props}
          sx={{
            [theme.breakpoints.up("md")]: {
              maxWidth: theme.app.constants.inputMaxWidth,
            },
          }}
          options={CAMPAIGN_LOCALE}
          autoHighlight
          disableClearable={!props.value?.id}
          onChange={(_, newData) => props.onChange(newData as ILocaleFormInput)}
          getOptionLabel={(option: ILocaleFormInput) => option.name}
          isOptionEqualToValue={(option, value) => {
            return option.id === value.id;
          }}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <TextField
              {...params}
              label="Language"
              variant="outlined"
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
      )}
      // rules={{ required: true }}
      name={name}
      control={control}
    />
  );
};

export default LocaleAutocomplete;
