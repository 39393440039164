import ConfigConstant from "core/constants/ConfigConstant";
import AutocompleteSingleFilter from "core/filters/atoms/AutocompleteSingleFilter";
import KeywordFilter from "core/filters/atoms/KeywordFilter";
import RouterConstants from "core/routes/constants";
import RouterUtils from "core/routes/utils";
import { formatDateTimeToSmartTime } from "core/utils/dateHandler";
import { createTableCell } from "core/utils/tableHandler";
import CreateSearch from "modules/Search/components/CreateSearch";
import SearchProgress from "modules/Search/components/SearchProgress";
import {
  SEARCH_STATUS_FILTER_OPTIONS,
  SEARCH_TYPE_FILTER_OPTIONS,
} from "modules/Search/constants";
import { SearchMatchVariantStrategy } from "modules/Search/models";
import SearchService from "modules/Search/services";
import SearchUtils from "modules/Search/utils";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import { Chip } from "ui-kit/atoms/Chip";
import FilterButton from "ui-kit/components/FilterButton";
import PageHeader from "ui-kit/components/PageHeader";
import Table from "ui-kit/components/Table";
import TableBodyCell from "ui-kit/components/TableBodyCell";

const rowsPerPage = ConfigConstant.PAGE_SIZE.MEDIUM;
const title = "Search";
const heads = [
  {
    id: "name",
    percentage: true,
    width: 35,
    label: "Name",
  },
  {
    id: "progress",
    percentage: true,
    width: 18,
    label: "Progress",
  },
  {
    id: "status",
    percentage: true,
    width: 12,
    label: "Status",
  },
  {
    id: "type",
    percentage: true,
    width: 18,
    label: "Type",
  },
  {
    id: "created",
    percentage: true,
    width: 17,
    label: "Created at",
  },
];

const SearchList = (): React.ReactElement => {
  const [page, setPage] = useState<number>(ConfigConstant.INITIAL_PAGE);
  const [openDialog, toggleDialog] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const { search, status, type } = RouterUtils.getQueryParams(location);

  const [typeQuery, setTypeQuery] = React.useState(
    RouterUtils.generateFilterOptionQuery(type, SEARCH_TYPE_FILTER_OPTIONS)
  );

  React.useEffect(() => {
    const newTypeQuery = RouterUtils.generateFilterOptionQuery(
      type,
      SEARCH_TYPE_FILTER_OPTIONS
    );
    setTypeQuery(newTypeQuery);
  }, [type]);

  const [statusQuery, setStatusQuery] = React.useState(
    RouterUtils.generateFilterOptionQuery(status, SEARCH_STATUS_FILTER_OPTIONS)
  );

  React.useEffect(() => {
    const newStatusQuery = RouterUtils.generateFilterOptionQuery(
      status,
      SEARCH_STATUS_FILTER_OPTIONS
    );
    setStatusQuery(newStatusQuery);
  }, [status]);

  const fetchSearches = async (p: number) => {
    try {
      const { data } = await SearchService.fetchSearches(
        p,
        rowsPerPage,
        search,
        typeQuery,
        statusQuery
      );
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };
  const { data, isFetching } = useQuery(
    ["searches", page, search, typeQuery, statusQuery],
    () => fetchSearches(page),
    { keepPreviousData: true }
  );

  // Route - if goes to search/new, open dialog
  React.useEffect(() => {
    const isNewSearchRoute = RouterUtils.isCurrentRoute(
      location.pathname,
      RouterConstants.SEARCH.NEW
    );
    if (isNewSearchRoute && openDialog === false) {
      toggleDialog(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const actionProps = {
    text: "Create search",
    onClick: () => {
      history.push(RouterConstants.SEARCH.NEW);
    },
  };

  return (
    <>
      <PageHeader
        title={title}
        body="Add prospects using basic search, Sales Nav, Recruiter, Group search or import via Google Sheet."
        actionProps={actionProps}
        helperProps={{
          link: RouterConstants.DOCS.SEARCH,
        }}
        filterProps
      />
      <Table
        title={title}
        filters={
          <>
            <KeywordFilter />
            <FilterButton label="Status" id="status">
              <AutocompleteSingleFilter
                id="status"
                options={SEARCH_STATUS_FILTER_OPTIONS}
                label="Status"
              />
            </FilterButton>
            <FilterButton label="Type" id="type">
              <AutocompleteSingleFilter
                id="type"
                options={SEARCH_TYPE_FILTER_OPTIONS}
                label="Type"
              />
            </FilterButton>
          </>
        }
        heads={heads}
        rows={
          data
            ? data.results?.map((searchItem) => {
                const link = RouterConstants.SEARCH.detail(searchItem.id);
                const { label, color } = SearchUtils.getSearchStatusChipData(
                  searchItem.status
                );
                return {
                  name: searchItem.id.toString(),
                  data: [
                    createTableCell(
                      searchItem.name,
                      link,
                      "main",
                      TableBodyCell
                    ),
                    createTableCell(
                      <SearchProgress search={searchItem} />,
                      link,
                      "action",
                      TableBodyCell
                    ),
                    createTableCell(
                      <Chip
                        variant="outlined"
                        size="small"
                        label={label}
                        color={color}
                      />,
                      link,
                      "element",
                      TableBodyCell
                    ),
                    createTableCell(
                      SearchMatchVariantStrategy[searchItem.strategy],
                      link,
                      "default",
                      TableBodyCell
                    ),
                    createTableCell(
                      formatDateTimeToSmartTime(searchItem.created),
                      link,
                      "default",
                      TableBodyCell
                    ),
                  ],
                };
              })
            : []
        }
        count={data?.count || 0}
        // INITIAL_PAGE starts at 1, but Pagination starts at 0
        page={(data?.current || page) - 1}
        setPage={setPage}
        isFetching={isFetching}
        actionPropsIfEmpty={actionProps}
        rowsPerPage={rowsPerPage}
      />
      <CreateSearch open={openDialog} toggleOpen={toggleDialog} />
    </>
  );
};

export default SearchList;
