import Skeleton from "@mui/material/Skeleton";
import TableCell from "@mui/material/TableCell";
import { ActionStrategy } from "modules/Action/models";
import InteractionCompareLabel from "modules/Interaction/components/InteractionCompareLabel";
import { InteractionCategories } from "modules/Interaction/models";
import InteractionService from "modules/Interaction/services";
import React from "react";
import { useQuery } from "react-query";

interface CampaignReportSequenceCompareProps {
  campaignId: number;
  sequenceId: number;
  actionId: number;
  actionStrategy: ActionStrategy;
}

const noResponse =
  ActionStrategy["inevitable.strategy.action.LinkedInViewProfileStrategy"];

const defaultQuery = `category=${InteractionCategories.message}&category=${InteractionCategories.connection_request}&category=${InteractionCategories.profile_view}&category=${InteractionCategories.connected}`;

const CampaignReportSequenceCompare = ({
  campaignId,
  sequenceId,
  actionId,
  actionStrategy,
}: CampaignReportSequenceCompareProps): React.ReactElement => {
  const hideResponse = actionStrategy === noResponse;
  const enableAccepted =
    actionStrategy ===
    ActionStrategy[
      "inevitable.strategy.action.LinkedInConnectionRequestStrategy"
    ];

  const fetchData1 = async () => {
    try {
      const { data } = await InteractionService.fetchCampaignSequenceReport(
        campaignId,
        sequenceId,
        `account_is_actor=true&${defaultQuery}&action_id=${actionId}`
      );
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };
  const { data: data1, isLoading: isLoading1 } = useQuery(
    ["actions-sent", campaignId, sequenceId, actionId],
    () => fetchData1(),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  const fetchDataReplies = async () => {
    try {
      const { data } = await InteractionService.fetchCampaignSequenceReport(
        campaignId,
        sequenceId,
        `account_is_actor=false&category=${InteractionCategories.message}&response_to_action_id=${actionId}`
      );
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };
  const { data: data2, isLoading: isLoading2 } = useQuery(
    ["actions-reply", campaignId, sequenceId, actionId],
    () => fetchDataReplies(),
    {
      keepPreviousData: true,
      enabled: !hideResponse,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  const fetchDataAccepted = async () => {
    try {
      const { data } = await InteractionService.fetchCampaignSequenceReport(
        campaignId,
        sequenceId,
        `account_is_actor=false&category=${InteractionCategories.connected}&response_to_action_id=${actionId}`
      );
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };
  const { data: data3, isLoading: isLoading3 } = useQuery(
    ["actions-connected", campaignId, sequenceId, actionId],
    () => fetchDataAccepted(),
    {
      keepPreviousData: true,
      enabled: !hideResponse && enableAccepted,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  return (
    <>
      <TableCell>
        {isLoading1 ? <Skeleton sx={{ width: 20 }} /> : data1?.count}
      </TableCell>
      <TableCell>
        <InteractionCompareLabel
          isLoading={isLoading1 && isLoading2}
          count={data2?.count}
          total={data1?.count}
          disable={hideResponse}
        />
      </TableCell>
      <TableCell>
        <InteractionCompareLabel
          isLoading={isLoading1 && isLoading3}
          count={data3?.count}
          total={data1?.count}
          disable={hideResponse || !enableAccepted}
        />
      </TableCell>
    </>
  );
};

export default CampaignReportSequenceCompare;
