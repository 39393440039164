import React from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { StepIconProps } from "@mui/material/StepIcon";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import { IAccountLogStatuses } from "modules/Account/models";

const icons: { [index: string]: React.ReactElement } = {
  [IAccountLogStatuses.ls]: <CheckIcon />,
  [IAccountLogStatuses.il]: <CheckIcon />,
  [IAccountLogStatuses.ar]: <ManageAccountsOutlinedIcon />,
  [IAccountLogStatuses.af]: <CheckIcon />,
  [IAccountLogStatuses.uf]: <ClearIcon />,
  [IAccountLogStatuses.lf]: <ClearIcon />,
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    marginRight: theme.spacing(2),
    backgroundColor: theme.palette.secondary.main,
    zIndex: 1,
    color: theme.palette.common.white,
    width: 30,
    height: 30,
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    "& > svg": {
      fontSize: "1.3rem",
    },
  },
  active: {
    margin: theme.spacing(2, 2, 2, 0),
  },
}));

const LogIcon = (props: StepIconProps): React.ReactElement => {
  const classes = useStyles();
  const { active, icon } = props;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const status: string = icon as any;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {icons[status]}
    </div>
  );
};

export default LogIcon;
