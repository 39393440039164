import React from "react";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Control, FieldError } from "react-hook-form";
import {
  formatDateToFull,
  getTimezoneDate,
  startOfTimezoneDay,
} from "core/utils/dateHandler";
import ElementField from "core/components/ElementField";
import DatePicker from "ui-kit/atoms/DatePicker";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(3),
  },
  date: {
    marginLeft: theme.spacing(1),
    fontWeight: 400,
  },
}));

interface IControllerProps {
  control: Control;
  error: FieldError | undefined;
  activeCampaignDate: string;
  defaultStartDate: string | undefined;
}

const StartDateField = ({
  control,
  error,
  activeCampaignDate,
  defaultStartDate,
}: IControllerProps): React.ReactElement => {
  const classes = useStyles();

  const showActiveDate = (value: string) => {
    const date = formatDateToFull(value);
    return (
      <Typography variant="subtitle2" className={classes.root}>
        Campaign started: <div className={classes.date}>{date}</div>
      </Typography>
    );
  };

  // If campaign already started
  if (!!activeCampaignDate) {
    return showActiveDate(activeCampaignDate);
  }

  // If campaign has start date
  if (!!defaultStartDate) {
    const startTimeZoneDate = getTimezoneDate(defaultStartDate as string);
    const currentTimeZoneDay = startOfTimezoneDay();

    // If campaign had start date and it's in the past
    if (startTimeZoneDate < currentTimeZoneDay) {
      return showActiveDate(defaultStartDate as string);
    }
  }

  return (
    <ElementField
      InputProps={{
        label: "Start date",
      }}
      component={DatePicker}
      name="start_date"
      label="Start date"
      control={control}
      error={error}
      ElementProps={{
        disablePast: true,
      }}
    />
  );
};

export default StartDateField;
