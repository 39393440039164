import React, { useImperativeHandle } from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DraftField = React.forwardRef(function DraftField(props: any, ref) {
  const { component: Component, editorRef, handleOnChange, ...rest } = props;

  useImperativeHandle(ref, () => ({
    focus: () => {
      editorRef?.current?.focus();
    },
  }));

  return <Component {...rest} ref={editorRef} onChange={handleOnChange} />;
});

export default DraftField;
