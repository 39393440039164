import React from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import PickerField from "core/components/ElementField";
import { createComponents } from "core/utils/componentsHandler";
import SettingsItem from "core/components/SettingsItem";
import {
  IAccountRestriction,
  IAccountDetailItemProps,
} from "modules/Account/models";
import AccountService from "modules/Account/services";
import AccountValidation from "modules/Account/validations";
import DatePicker from "ui-kit/atoms/DatePicker";
import InactiveDaysList from "./components/InactiveDaysList";

interface IFormInput {
  restrictions: IAccountRestriction;
}

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  startInput: {
    [theme.breakpoints.up("md")]: {
      marginRight: theme.spacing(4),
    },
  },
}));

const InactiveDaySettings = ({
  accountId,
}: IAccountDetailItemProps): React.ReactElement => {
  const classes = useStyles();
  const defaultValues = {
    start_date: null,
    end_date: null,
  };

  return (
    <div>
      <SettingsItem
        FormProps={{
          id: accountId,
          name: ["inactive_periods", accountId],
          func: AccountService.createInactivePeriod,
          format: (data: IFormInput) => data,
          defaultValues,
          schema: AccountValidation.inactiveDaysSchema(),
          resetForm: true,
        }}
        components={[
          createComponents(PickerField, "start_date", {
            label: "From",
            component: DatePicker,
            ElementProps: {
              disablePast: true,
            },
            InputProps: {
              classes: {
                root: clsx(classes.input, classes.startInput),
              },
            },
          }),
          createComponents(PickerField, "end_date", {
            label: "To",
            component: DatePicker,
            ElementProps: {
              disablePast: true,
            },
            InputProps: {
              classes: {
                root: classes.input,
              },
            },
          }),
        ]}
        PaperHeaderProps={{
          title: "Inactive Days",
          body: "Set days, on which your account will not be active. No LinkedIn actions will run during these days.",
        }}
        SubmitButtonProps={{
          color: "primary",
          value: "Add inactive period",
        }}
        hiddenDivider
      />
      <InactiveDaysList />
    </div>
  );
};

export default InactiveDaySettings;
