import React from "react";
import unionBy from "lodash/unionBy";
import capitalize from "lodash/capitalize";
import differenceBy from "lodash/differenceBy";
import uniqBy from "lodash/uniqBy";
import find from "lodash/find";
import { MentionData } from "@draft-js-plugins/mention";
import { DeviceVariant, DefaultPlaceholdersLabels } from "core/models";
import { createTableCell } from "core/utils/tableHandler";
import ConfigConstant from "core/constants/ConfigConstant";
import { IAccount } from "modules/Account/models";
import TablePlaceholderCell from "modules/Placeholder/components/TablePlaceholderCell";
import TableNewPlaceholderCell from "modules/Placeholder/components/TableNewPlaceholderCell";
import { cleanPersonDetail, getPersonName } from "modules/Person/utils";
import {
  IPerson,
  IPersonPlaceholder,
  IPersonPlaceholders,
} from "modules/Person/models";
import PersonPlaceholderBlank from "modules/Placeholder/components/PersonPlaceholderBlank";
import {
  IPlaceholderPerson,
  IPlaceholder,
  IPlaceholderPersonData,
  ILaxPlaceholder,
  DefaultPlaceholdersKeys,
  IPlaceholderKeyArray,
  CzechLanguagePlaceholdersKeys,
  CzechLanguagePlaceholdersLabels,
  CzechLanguagePlaceholdersValues,
} from "modules/Placeholder/models";
import { ISearchResult } from "modules/Search/models";
import { TableProps, ITableHead } from "ui-kit/components/Table";

const createPlaceholder = (id: string, name: string): MentionData => ({
  id,
  name: `{{${name}}}`,
});

const removeNCharFrontEnd = (key: string, n: number): string =>
  key.substring(n, key.length - n);

const cleanCurlyBraces = (key: string): string => removeNCharFrontEnd(key, 2);

const formatPlaceholder = (key: string | undefined): string =>
  key ? capitalize(key.replace(/_/g, " ")) : "";

const DEFAULT_PLACEHOLDERS_ARR: MentionData[] = Object.keys(
  DefaultPlaceholdersKeys
).map((value) => {
  return createPlaceholder(
    DefaultPlaceholdersLabels[value as DefaultPlaceholdersKeys],
    DefaultPlaceholdersKeys[value as DefaultPlaceholdersKeys]
  );
});

const CZECH_DEFAULT_PLACEHOLDERS_ARR: MentionData[] = [
  createPlaceholder(
    CzechLanguagePlaceholdersLabels.first_name,
    cleanCurlyBraces(CzechLanguagePlaceholdersKeys.first_name)
  ),
];

const generateDynamicPlaceholders = (keys: IPlaceholderKeyArray) =>
  keys?.map((item) => {
    return createPlaceholder(
      formatPlaceholder(item?.key),
      item?.key.toLowerCase()
    );
  });

const decorateStaticPlaceholder = (key: string): string => {
  return cleanCurlyBraces(key);
};

const decorateLanguagePlaceholder = (
  key: CzechLanguagePlaceholdersKeys
): string => CzechLanguagePlaceholdersValues[key];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapObj: any = {
  if: "IF",
  else: "ELSE",
  endif: "END IF",
};

const decorateDynamicPlaceholder = (key: string): string => {
  const cleanStr = removeNCharFrontEnd(key, 3);
  return cleanStr.replace(/if|else|endif/gi, (matched) => mapObj[matched]);
};

const getCustomPlaceholders = (keys: IPlaceholderKeyArray): MentionData[] => {
  return differenceBy(
    uniqBy(generateDynamicPlaceholders(keys), "name"),
    DEFAULT_PLACEHOLDERS_ARR,
    "name"
  );
};

const getAllPlaceholders = (keys: IPlaceholderKeyArray): MentionData[] => {
  return unionBy(
    DEFAULT_PLACEHOLDERS_ARR,
    uniqBy(generateDynamicPlaceholders(keys), "name"),
    "name"
  );
};

const getCleanKey = (str: string) =>
  str.toLowerCase().replace(/[^a-zA-Z ]/g, "");

const matchAllPlaceholders = (
  key: string,
  options: string[]
): string | undefined => {
  const cleanKey = getCleanKey(key);
  return options.find((o) => {
    const cleanOption = getCleanKey(o);
    return cleanOption === cleanKey;
  });
};

type DynamicPlaceholdersKeys =
  | IPlaceholderPerson["placeholder_keys"]
  | undefined;

const getAccountPlaceholders = (
  placeholders: IAccount["template_placeholders"]
): MentionData[] => {
  const items: MentionData[] = [];
  Object.keys(placeholders).forEach((key) => {
    if (placeholders[key]) {
      items.push(createPlaceholder(formatPlaceholder(key), key));
    }
  });
  return items;
};

const generateStaticHeads = (): ITableHead[] =>
  Object.values(DefaultPlaceholdersKeys).map((key) => ({
    id: key,
    label: DefaultPlaceholdersLabels[key],
    width: ConfigConstant.PLACEHOLDER_INPUT_WIDTH,
  }));

const generateDynamicHeads = (placeholderArr: DynamicPlaceholdersKeys) => {
  return placeholderArr
    ? placeholderArr.map((placeholderObj) => ({
        id: placeholderObj.key,
        label: placeholderObj.key,
        width: ConfigConstant.PLACEHOLDER_INPUT_WIDTH,
      }))
    : [];
};

const getPredefinedHeads = (deviceSize: DeviceVariant) => [
  {
    id: "2394923dh",
    width: deviceSize === DeviceVariant.desktop ? 250 : 180,
    label: "Name",
  },
];

const generateAndMergeHeads = (
  dynamicPlaceholderKeys: DynamicPlaceholdersKeys,
  deviceSize: DeviceVariant,
  disableStatic = false
): ITableHead[] =>
  unionBy(
    getPredefinedHeads(deviceSize),
    disableStatic ? [] : generateStaticHeads(),
    generateDynamicHeads(dynamicPlaceholderKeys),
    "id"
  );

const generateAndMergeHeadsForRow = (
  dynamicPlaceholderKeys: DynamicPlaceholdersKeys
): string[] =>
  unionBy(
    generateStaticHeads(),
    generateDynamicHeads(dynamicPlaceholderKeys),
    "id"
  ).map((h) => h.id);

const generateAndMergeRow = (
  headKeys: string[],
  person: IPlaceholderPersonData,
  placeholders: IPlaceholder[]
): ILaxPlaceholder[] => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const defaultPlaceholders: any = person.default_placeholders;
  // Update person with placeholder values if exists, if not with empty string
  return headKeys.map((headKey) => {
    const placeholder = placeholders.find(
      (x: IPlaceholder) => x.key.key === headKey
    );
    return {
      id: placeholder?.id || null,
      key: headKey,
      person: person.id,
      value: placeholder?.value || defaultPlaceholders[headKey] || "",
    };
  });
};

// 1. Create heads from unique values of static and dynamic placeholders
// 2. Format each line to add empty values where there is no placeholder value
const generateRows = (
  persons: IPlaceholderPerson["persons"] | undefined,
  dynamicPlaceholderKeys: DynamicPlaceholdersKeys
): TableProps["rows"] => {
  const headKeys = generateAndMergeHeadsForRow(dynamicPlaceholderKeys);
  return persons
    ? persons.map((person) => {
        const { placeholders, id } = person;
        const dynamicRow = generateAndMergeRow(
          headKeys,
          person,
          placeholders
        ).map((individual) => {
          return createTableCell(
            individual,
            "",
            "default",
            TablePlaceholderCell
          );
        });
        return {
          name: id.toString(),
          data: [
            createTableCell(
              getPersonName(person.default_placeholders),
              id.toString(),
              "main",
              TableNewPlaceholderCell
            ),
            ...dynamicRow,
          ],
        };
      })
    : [];
};

const createPlaceholderName = (slug: string): string => {
  const words = slug.split("_");
  return words
    .map((word, index) => {
      if (index === 0) {
        return word.charAt(0).toUpperCase() + word.substring(1).toLowerCase();
      }
      return word.toLocaleLowerCase();
    })
    .join(" ");
};

const getOnlyPersonExistingPlacehodlers = (
  person: IPerson["default_placeholders"],
  placeholders: IPlaceholder[]
): IPersonPlaceholders => {
  const items: IPersonPlaceholders = [];
  Object.values(DefaultPlaceholdersKeys).forEach((key) => {
    const placeholder = placeholders.find((x) => x.key.key === key);
    const value = placeholder?.value || person[key];
    if (value) {
      items.push({
        id: placeholder?.id || null,
        key: {
          id: Number(placeholder?.key.id),
          key: key,
        },
        label: DefaultPlaceholdersLabels[key],
        value,
      });
    }
  });
  const staticPlaceholderKeys: string[] = Object.values(
    DefaultPlaceholdersKeys
  );
  placeholders
    .filter((x) => !staticPlaceholderKeys.includes(x.key.key))
    .forEach((placeholder) => {
      items.push({
        id: placeholder.id,
        key: placeholder.key,
        label: capitalize(placeholder.key.key),
        value: placeholder.value,
      });
    });
  return items;
};

const generateBlankStaticPlaceholders = (): React.ReactElement[] => {
  const staticPlaceholders = Object.values(DefaultPlaceholdersKeys).map(
    (key) => <PersonPlaceholderBlank key={key} placeholderKey={key} />
  );
  return staticPlaceholders;
};

const generatePersonPlacehodlers = (
  person: IPerson["default_placeholders"],
  placeholders: IPlaceholder[] | IPersonPlaceholder[]
): IPersonPlaceholders => {
  const items = placeholders.map(({ id, key, value }) => ({ id, key, value }));
  const staticPlaceholders = Object.values(DefaultPlaceholdersKeys).map(
    (key, index) => {
      const placeholder = items.find((x) => x.key.key === key);
      return {
        id: placeholder?.id || null,
        key: {
          id: index,
          key: key,
        },
        label: DefaultPlaceholdersLabels[key],
        value: placeholder?.value || person[key] || "",
      };
    }
  );
  const staticPlaceholderKeys: string[] = Object.values(
    DefaultPlaceholdersKeys
  );
  const dynamicPlaceholders = items
    .filter((x) => !staticPlaceholderKeys.includes(x.key.key))
    .map((placeholder) => ({
      id: placeholder.id,
      key: placeholder.key,
      label: capitalize(placeholder.key.key),
      value: placeholder.value,
    }));
  return [...staticPlaceholders, ...dynamicPlaceholders];
};

const getPlaceholderContent = (
  placeholders: IPerson["placeholders"],
  person: IPerson["default_placeholders"],
  search_result: ISearchResult["placeholders"],
  key: DefaultPlaceholdersKeys
): string => {
  const placeholder = find(placeholders, ["key.key", key]);
  return placeholder?.value || person[key] || search_result?.[key] || "";
};

const getPlaceholderContentNoPerson = (
  search_result: ISearchResult["placeholders"],
  key: DefaultPlaceholdersKeys
): string => {
  return search_result?.[key] || "";
};

const getPersonSubText = (
  props: IPerson["default_placeholders"] | undefined
): string | undefined => {
  if (!props) {
    return;
  }
  const { company_name, job_title, occupation } = props;
  if (company_name && job_title) {
    return `${job_title} at ${company_name}`;
  }
  if (occupation) {
    return cleanPersonDetail(occupation);
  }
  return undefined;
};

export {
  DEFAULT_PLACEHOLDERS_ARR,
  CZECH_DEFAULT_PLACEHOLDERS_ARR,
  generateAndMergeHeads,
  generateRows,
  cleanCurlyBraces,
  createPlaceholderName,
  generatePersonPlacehodlers,
  generateBlankStaticPlaceholders,
  getPlaceholderContent,
  getPlaceholderContentNoPerson,
  getOnlyPersonExistingPlacehodlers,
  formatPlaceholder,
  getAllPlaceholders,
  getCustomPlaceholders,
  decorateStaticPlaceholder,
  decorateDynamicPlaceholder,
  decorateLanguagePlaceholder,
  getAccountPlaceholders,
  getPersonSubText,
  matchAllPlaceholders,
  getCleanKey,
};
