import Backdrop from "@mui/material/Backdrop";
import LinearProgress from "@mui/material/LinearProgress";
import List from "@mui/material/List";
import { Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { makeStyles, useTheme } from "@mui/styles";
import clsx from "clsx";
import ConfigConstant from "core/constants/ConfigConstant";
import RouterUtils from "core/routes/utils";
import { errorHandler, IErrorResponse } from "core/utils/errorHandler";
import { get } from "lodash";
import { AccountContext } from "modules/Account/context";
import {
  IGroupedInteractions,
  InteractionsQuery,
  InteractionViewVariant,
} from "modules/Interaction/models";
import InteractionService from "modules/Interaction/services";
import {
  getGroupedInteractions,
  getReversedInteractions,
} from "modules/Interaction/utils";
import PersonSidebar from "modules/Person/components/PersonSidebar";
import { UserContext } from "modules/User/context";
import { UserActionType } from "modules/User/reducers";
import UserService from "modules/User/services";
import React, { useEffect, useRef, useState } from "react";
import { useInfiniteQuery, useMutation } from "react-query";
import { useLocation } from "react-router-dom";
import Loader from "ui-kit/components/Loader";
import CreateDirectAction from "../CreateDirectAction";
import DirectActionList from "../DirectActionList";
import InteractionContainerItem from "../InteractionContainerItem";
import InteractionContainerLoadButton from "../InteractionContainerLoadButton";
import InteractionContainerProfile from "../InteractionContainerProfile";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    height: "100%",
  },
  chat: {
    overflow: "hidden",
    width: "100%",
    position: "relative",
  },
  chatSidebar: {
    [theme.breakpoints.up("lg")]: {
      width: `calc(100% - ${theme.app.constants.inbox.detailWidth}px)`,
    },
  },
  detail: {
    height: "100%",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      maxWidth: theme.app.constants.inbox.detailWidth,
    },
    overflowY: "hidden",
    borderLeft: `1px solid ${theme.app.palette.shadow.secondary}`,
  },
  inbox: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    // backgroundColor: "#EFF3F6",
    backgroundColor: theme.app.palette.action.hoverBackground,
  },
  inboxPage: {
    height: theme.app.constants.inbox.inboxHeight,
  },
  campaignPage: {
    height: theme.app.constants.campaign.interactions.inboxHeight,
  },
  backdrop: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 1,
  },
  list: {
    padding: 0,
    overflowY: "scroll",
    height: "100%",
    boxShadow:
      "inset 0px 8px 8px -8px rgba(0,0,0,0.05), inset 0px -8px 8px -8px rgba(0,0,0,0.05)",
    // ...theme.app.constants.scrollbar,
  },
  loader: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
  },
  groupDate: {
    textAlign: "center",
    width: "100%",
    color: theme.app.palette.action.color,
    fontSize: 12.5,
    fontWeight: 500,
    margin: theme.spacing(2, 0),
  },
}));

const getSidebarDefaultState = (
  userState: boolean | undefined,
  componentState: boolean
) => {
  if (userState === undefined) {
    return componentState;
  }
  return !!userState;
};

interface InteractionContainerListProps {
  accountId: number;
  personId: number;
  variant: InteractionViewVariant;
  handleBacklink: () => void;
}

const InteractionContainerList = ({
  accountId,
  personId,
  variant,
  handleBacklink,
}: InteractionContainerListProps): React.ReactElement | null => {
  const classes = useStyles();
  const theme: Theme = useTheme();
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const lastInteractionRef = useRef<HTMLDivElement>(null);
  const { user, dispatch } = React.useContext(UserContext);

  const [showSidebar, setShowSidebar] = useState<boolean>(
    getSidebarDefaultState(
      user?.frontend_state?.show_person_sidebar?.[variant],
      !(variant === InteractionViewVariant.campaign) // disable by default if campaign view
    )
  );
  const [openActions, setOpenActions] = useState(false);
  const [interactions, setInteractions] = useState<IGroupedInteractions>({});
  const [lastInteractionId, setLastInteractionId] = useState<number>(0);
  const [firstInteractionId, setFirstInteractionId] = useState<
    number | undefined
  >(0);

  const mutateToggleSidebar = useMutation(
    (_showSidebar: boolean) =>
      UserService.updateProfile(user.id, {
        frontend_state: { show_person_sidebar: { [variant]: _showSidebar } },
      }),
    {
      onSuccess: ({ data }) => {
        dispatch({
          type: UserActionType.SET_USER_DATA,
          payload: data,
        });
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);
      },
    }
  );

  const handleToggleSidebar = () => {
    setShowSidebar(!showSidebar);
    mutateToggleSidebar.mutate(!showSidebar);
  };

  const location = useLocation();
  const { campaignId } = RouterUtils.getQueryParams(location);

  const {
    account: { person: accountPerson },
  } = React.useContext(AccountContext);

  const fetchInteractions = async ({
    pageParam = ConfigConstant.INITIAL_PAGE,
  }) => {
    try {
      const { data: d } = await InteractionService.fetchPersonInteractions(
        accountId,
        personId,
        pageParam
      );
      return d;
    } catch (err) {
      throw new Error(String(err));
    }
  };

  const {
    data,
    isFetching,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteQuery(
    [InteractionsQuery.interactions_person, campaignId, personId],
    fetchInteractions,
    {
      getNextPageParam: (lastPage) =>
        lastPage.next ? lastPage.current + 1 : false,
      keepPreviousData: true,
      enabled: !!personId,
    }
  );

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView();
  };

  useEffect(() => {
    if (data) {
      const allInteractions = getReversedInteractions(data);
      setLastInteractionId(allInteractions[0]?.id);
      const groupedInteractions = getGroupedInteractions(allInteractions);
      setInteractions(groupedInteractions);

      const newFirstInteractionId = get(data, "pages[0].results[0].id");
      if (newFirstInteractionId !== firstInteractionId) {
        setFirstInteractionId(newFirstInteractionId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // Scroll to bottom when firstInteractionId changes - ex. pause/resume
  React.useEffect(() => {
    setTimeout(() => {
      scrollToBottom();
    }, 50);
  }, [firstInteractionId]);

  const scrollVariable = !!data?.pages?.length;
  React.useEffect(() => {
    if (!isLoading && scrollVariable) {
      setTimeout(() => {
        scrollToBottom();
      }, 0);
    }
  }, [scrollVariable, isLoading, personId]);

  const desktopLarge = useMediaQuery(() => theme.breakpoints.up("lg"));

  if (!accountPerson || !personId) {
    return <Loader minHeight={300} />;
  }

  return (
    <div className={classes.root}>
      <div
        className={clsx(classes.chat, { [classes.chatSidebar]: showSidebar })}
      >
        <InteractionContainerProfile
          personId={personId}
          showSidebar={showSidebar}
          handleToggleSidebar={handleToggleSidebar}
          handleBacklink={handleBacklink}
        />
        <div
          className={clsx(classes.inbox, {
            [classes.inboxPage]: variant === InteractionViewVariant.inbox,
            [classes.campaignPage]: variant === InteractionViewVariant.campaign,
          })}
        >
          {isFetching && <LinearProgress className={classes.loader} />}
          <Backdrop className={classes.backdrop} open={openActions} />
          <List data-cy="interaction-menu" className={classes.list}>
            <InteractionContainerLoadButton
              fetchNextPage={fetchNextPage}
              loadMoreDisabled={Boolean(
                !hasNextPage || isFetchingNextPage || isLoading || !data
              )}
              lastInteractionRef={lastInteractionRef}
            />
            {Object.keys(interactions).map((key) => {
              const items = interactions[key];
              return (
                <React.Fragment key={key}>
                  <Typography className={classes.groupDate}>{key}</Typography>
                  {items.map((interaction) => (
                    <React.Fragment key={interaction.id}>
                      {lastInteractionId === interaction.id && (
                        <div ref={lastInteractionRef} />
                      )}
                      <InteractionContainerItem
                        interaction={interaction}
                        key={interaction.id}
                        accountPerson={accountPerson}
                      />
                    </React.Fragment>
                  ))}
                </React.Fragment>
              );
            })}
            <DirectActionList
              personId={personId}
              accountPerson={accountPerson}
            />
            <div ref={messagesEndRef} />
          </List>
          <CreateDirectAction
            personId={personId}
            accountId={accountId}
            open={openActions}
            setOpen={setOpenActions}
          />
        </div>
      </div>
      {showSidebar && desktopLarge && (
        <div className={classes.detail}>
          <PersonSidebar personId={personId} />
        </div>
      )}
    </div>
  );
};

export default InteractionContainerList;
