import React from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const useStyles = makeStyles((theme: Theme) => ({
  btn: {
    borderRadius: 4,
    border: `1px solid ${theme.app.palette.shadow.secondary}`,
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(0.75, 2),
    margin: theme.spacing(0.75, 1.5, 0.75, 0),
    fontSize: 11,
    color: theme.palette.text.secondary,
    cursor: "pointer",
    "&:hover": {
      transition: theme.transitions.create("all", {
        duration: theme.transitions.duration.shorter,
      }),
      borderColor: theme.palette.secondary.dark,
      backgroundColor: theme.palette.common.white,
      color: theme.palette.secondary.dark,
    },
    "& .MuiButton-endIcon": {
      marginLeft: theme.spacing(1.5),
    },
  },
  disableBtn: {
    "&:hover": {
      color: theme.palette.text.primary,
    },
  },
}));

interface PlaceholderButtonProps {
  label: string | undefined | null | number;
  handleOnClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabledButton?: boolean;
}

const PlaceholderButton = ({
  label,
  handleOnClick,
  disabledButton,
}: PlaceholderButtonProps): React.ReactElement | null => {
  const classes = useStyles();

  if (!label) {
    return null;
  }

  return (
    <Button
      onClick={handleOnClick}
      className={clsx(classes.btn, {
        [classes.disableBtn]: disabledButton,
      })}
      {...(disabledButton && { endIcon: <KeyboardArrowDownIcon /> })}
    >
      {label}
    </Button>
  );
};

PlaceholderButton.defaultProps = {
  disabledButton: false,
};

export default PlaceholderButton;
