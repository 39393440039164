import { AxiosResponse } from "axios";
import { IArrayResponse } from "core/models";
import { Dayjs } from "dayjs";
import {
  IInteractionCount,
  IInteractions,
  InteractionStatKey,
  InteractionStatLabel,
} from "modules/Interaction/models";
import { Range } from "react-date-range";

export enum ReportNames {
  "connection" = "connection",
  "reply" = "reply",
  "invitation" = "invitation",
  "message" = "message",
  "profile" = "profile",
  "pending" = "pending",
}

export enum ReportChartLabels {
  "connections" = "Invites sent",
  "accepts" = "Accepted",
  "messages" = "Messages sent",
  "replies" = "Replies",
  "profile" = "Profile visits",
}

export enum LimitActionNames {
  "invitation" = "invitation",
  "message" = "message",
  "profile" = "profile",
}

export enum LimitActionDefaultValues {
  "invitation" = "100",
  "message" = "100",
  "profile" = "100",
}

export enum ChartType {
  "integer" = 0,
  "percentage" = 1,
}

export enum DateRangesQuery {
  gt = "platform_created_at__gt",
  lt = "platform_created_at__lt",
}

export interface IDateStringRange {
  start: string;
  end: string;
}

export interface IDayjsRange {
  start: Dayjs;
  end: Dayjs;
}

export interface IRange extends Range {
  main: RangeDefaults | undefined;
}

export enum RangeDefaults {
  "last-7-days" = "last-7-days",
  "last-30-days" = "last-30-days",
  // "today" = "today",
  // "yesterday" = "yesterday",
  // "this-week" = "this-week",
  // "last-week" = "last-week",
  "this-month" = "this-month",
  "last-month" = "last-month",
  "custom-range" = "custom-range",
}

export enum RangeDefaultLabels {
  "last-7-days" = "Last 7 Days",
  "last-30-days" = "Last 30 Days",
  // "today" = "Today",
  // "yesterday" = "Yesterday",
  // "this-week" = "This Week",
  // "last-week" = "Last Week",
  "this-month" = "This Month",
  "last-month" = "Last Month",
  "custom-range" = "Custom Range",
}

export type IChunk =
  | number
  | "day"
  | "month"
  | "year"
  | "hour"
  | "minute"
  | "second";

export interface IReportContext {
  currentRange: IDateStringRange;
  defaultRange: RangeDefaults | undefined;
}

export interface IDateReport {
  labels: string[];
  chunks: IDayjsRange[];
  diff: number;
}

export type IChartValues = (number | null)[];

export interface ILocaleProps {
  sameDay?: string;
  lastDay?: string;
  lastWeek?: string;
  sameElse?: string;
}

export interface IReport {
  name: ReportNames;
  count: number;
  values: IChartValues;
}

export interface IReportItem {
  name: ReportNames;
  label: string;
  func: (
    accountId: number,
    queryString: string
  ) => Promise<AxiosResponse<IInteractions>>;
}

export interface IReportElement {
  key: InteractionStatKey;
  count: number;
  values: number[];
}

export interface ICountReportItem {
  name: ReportNames;
  label: string;
  func: (
    accountId: number,
    queryString: string
  ) => Promise<AxiosResponse<IInteractionCount>>;
}

export interface ILimitItem {
  name: LimitActionNames;
  label: string;
  func: (
    accountId: number,
    queryString: string
  ) => Promise<AxiosResponse<IInteractionCount>>;
  limit: string | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon: React.FunctionComponent<any>;
  color: string;
  restrictionKey: string | undefined;
}

export interface IDatasetItem {
  index: number;
  count: number;
  range: [number, number];
  restriction: number | undefined;
  total: number;
}

export interface IRestriction {
  connection_requests: number;
  warm_up: boolean;
  follow_up_messages: number;
  account: number;
}

export interface IRestrictions extends IArrayResponse {
  results: IRestriction[];
}
