import React from "react";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import DialogContentText from "@mui/material/DialogContentText";
import ElementField from "core/components/ElementField";
import { SearchDefaultFormProps } from "modules/Search/models";
import Loader from "ui-kit/components/Loader";

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    marginBottom: theme.spacing(3),
  },
  body: {
    display: "flex",
    alignItems: "center",
    fontSize: "0.8rem",
    marginBottom: theme.spacing(4),
  },
}));

const SearchDefaultForm = ({
  title,
  subtitle,
  subtitleTooltip,
  label,
  control,
  errors,
  isLoading,
}: SearchDefaultFormProps): React.ReactElement => {
  const classes = useStyles();

  return (
    <>
      {isLoading && <Loader minHeight={200} position="absolute" />}
      <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 4 }}>
        {title}
      </Typography>
      <div className={classes.formControl}>
        <DialogContentText className={classes.body}>
          {subtitle}
        </DialogContentText>
        <ElementField
          error={errors.url}
          control={control}
          name="url"
          InputProps={{
            label,
            fullWidth: true,
            variant: "outlined",
            id: "url",
          }}
        />
      </div>
      <div className={classes.formControl}>
        <DialogContentText className={classes.body}>
          Enter a name for your search
        </DialogContentText>
        <ElementField
          error={errors.searchName}
          control={control}
          name="searchName"
          InputProps={{
            label: "Search name",
            fullWidth: true,
            variant: "outlined",
            id: "searchName",
          }}
        />
      </div>
    </>
  );
};

export default SearchDefaultForm;
