import React from "react";
import Typography, { TypographyProps } from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import HelperTooltip, { HelperTooltipProps } from "./HelperTooltip";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
}));

interface HelperTypographyProps {
  text: string;
  typographyProps?: TypographyProps;
  helperProps?: HelperTooltipProps;
}

const HelperTypography = ({
  text,
  typographyProps,
  helperProps,
}: HelperTypographyProps): React.ReactElement => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography color="textSecondary" variant="body2" {...typographyProps}>
        {text}
      </Typography>
      {helperProps && <HelperTooltip {...helperProps} />}
    </div>
  );
};

HelperTypography.defaultProps = {
  typographyProps: undefined,
  helperProps: undefined,
};

export default HelperTypography;
