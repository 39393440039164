import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "ui-kit/atoms/Button";
import DialogHeader from "./DialogHeader";
interface ConfirmDialogProps {
  open: boolean;
  handleClose: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleAgree: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleDisagree: (event: React.MouseEvent<HTMLButtonElement>) => void;
  title: string;
  dialogText: string | React.ReactElement;
}

const ConfirmDialog = ({
  open,
  handleClose,
  handleAgree,
  handleDisagree,
  title,
  dialogText,
}: ConfirmDialogProps): React.ReactElement => {
  const onHandleAgree = (event: React.MouseEvent<HTMLButtonElement>) => {
    handleAgree(event);
    handleClose(event);
  };
  const onHandleDisagree = (event: React.MouseEvent<HTMLButtonElement>) => {
    handleDisagree(event);
    handleClose(event);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
      maxWidth="sm"
      fullWidth
    >
      <DialogHeader title={title} onHandleClose={onHandleDisagree} />
      <DialogContent dividers>
        <DialogContentText id="confirm-dialog-description">
          {dialogText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={onHandleAgree}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
