import { Alert, AlertTitle, Box } from "@mui/material";
import { CountryCodes } from "core/models";
import { matchCountry } from "core/utils/countryHandler";
import { errorHandler, IErrorResponse } from "core/utils/errorHandler";
import { snackbarHandler } from "core/utils/snackbarHandler";
import get from "lodash/get";
import { AccountContext } from "modules/Account/context";
import {
  AccountQuery,
  ICountryFormInput,
  IProxyIpsMappedData,
} from "modules/Account/models";
import CountryAutocomplete from "modules/Account/pages/CreateAccount/components/CountryAutocomplete";
import AccountService from "modules/Account/services";
import PaymentService from "modules/Payment/services";
import { UserContext } from "modules/User/context";
import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Button from "ui-kit/atoms/Button";

const ProxyAlert = (): React.ReactElement | null => {
  const { user } = React.useContext(UserContext);
  const { account } = useContext(AccountContext);
  const { id: accountId, proxy_id: isProxyAvailable } = account;
  const queryClient = useQueryClient();

  const { control, errors, handleSubmit, reset } = useForm<ICountryFormInput>({
    defaultValues: { country: null },
  });

  const { data } = useQuery(
    [AccountQuery.account, accountId],
    async () => {
      try {
        const response = await AccountService.fetchAccount(accountId);
        return response.data;
      } catch (err) {
        throw new Error(String(err));
      }
    },
    {
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: !!accountId && !user?.anonymous && !isProxyAvailable,
    }
  );

  const { data: dataSubscription } = useQuery(
    [AccountQuery.account_subscription, accountId],
    async () => {
      try {
        const response = await PaymentService.fetchAccountSubscription(
          accountId
        );
        return response.data;
      } catch (err) {
        throw new Error(String(err));
      }
    },
    {
      // If synced
      enabled: !user?.anonymous && !!accountId && !!account?.person,
    }
  );

  // Update default value based on account's country
  React.useEffect(() => {
    const country = matchCountry(get(data, "country"));
    reset({ country: country ? country : null });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.country]);

  const mutateOnClick = useMutation(
    (data: IProxyIpsMappedData) => AccountService.createProxyIp(data),
    {
      onSuccess: async (response) => {
        queryClient.invalidateQueries([AccountQuery.account, accountId]);

        snackbarHandler.success("New IP address successfully created");
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);
      },
    }
  );

  const onSubmit = (data: ICountryFormInput) => {
    const countryId = get(data, "country.id");

    if (!countryId) {
      return snackbarHandler.error("No country selected");
    }

    mutateOnClick.mutate({
      account: accountId,
      country: countryId as CountryCodes,
    });
  };

  if (
    !data ||
    !!data?.proxy_id ||
    !!isProxyAvailable ||
    !dataSubscription?.is_subscribed
  ) {
    return null;
  }

  return (
    <Alert severity="error" sx={{ marginBottom: 6 }}>
      <AlertTitle>
        Problem creating IP address for your LinkedIn account
      </AlertTitle>
      We're sorry, but there was a problem assigning an unique IP address to
      your account. Click on the button below to retry.
      <Box mt={4}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              height: get(data, "country") ? 0 : "auto",
              overflow: "hidden",
            }}
          >
            <CountryAutocomplete
              control={control}
              errors={errors}
              InputProps={{
                sx: {
                  maxWidth: 400,
                  "& > .MuiInputBase-root": { backgroundColor: "#FFF" },
                },
              }}
            />
          </Box>
          <Button color="primary" type="submit" variant="contained">
            Add IP address
          </Button>
        </form>
      </Box>
    </Alert>
  );
};

export default ProxyAlert;
