import { IArrayResponse } from "core/models";
import { IPerson } from "modules/Person/models";

export enum DefaultPlaceholdersKeys {
  first_name = "first_name",
  last_name = "last_name",
  company_name = "company_name",
  job_title = "job_title",
  job_industry = "job_industry",
  location = "location",
  // occupation = "occupation",
}

export enum CzechLanguagePlaceholdersKeys {
  first_name = "{{first_name|case_ending}}",
}

export enum CzechLanguagePlaceholdersValues {
  "{{first_name|case_ending}}" = "jméno_skloňování",
}

export enum CzechLanguagePlaceholdersLabels {
  first_name = "Jméno (skloňování)",
}

export interface IDefaultPlaceholders {
  first_name: string;
  last_name: string;
  occupation: string;
  company_name: string;
  job_title: string;
  job_industry: string;
  location: string;
}

export interface IPlaceholderKey {
  id: number;
  key: string;
}

export interface IPlaceholder {
  id: number | null;
  key: IPlaceholderKey;
  value: string;
  person: number;
}

export interface IPlaceholders extends IArrayResponse {
  results: IPlaceholder[];
}

export interface IPlaceholderCreateInput {
  key: string;
  value: string;
}

export interface IPlaceholderCreateValues {
  key_string: string;
  value: string;
  person: number;
  account: number;
}

export interface IPlaceholderCreateResponse extends IPlaceholderCreateValues {
  id: number;
}

export interface IPlaceholderUpdateValues {
  id: number;
  value: string;
}

export interface ILaxPlaceholder {
  id: number | null;
  key: string;
  value: string;
}

export interface IPlaceholderKeyIndividual {
  id: number;
  key: string;
  account: number;
}

export type IPlaceholderKeyArray = IPlaceholderKeyIndividual[];
export interface IPlaceholderKeys extends IArrayResponse {
  results: IPlaceholderKeyArray;
}

export interface IPlaceholderPersonData {
  id: number;
  default_placeholders: IPerson;
  placeholders: IPlaceholder[];
}

export interface IPlaceholderPerson {
  placeholder_keys: { key: string }[];
  persons: IPlaceholderPersonData[];
}

export interface IPlaceholderPersons extends IArrayResponse {
  results: IPlaceholderPerson;
}
