import React from "react";
import { FetchNextPageOptions, InfiniteQueryObserverResult } from "react-query";
import Button from "@mui/material/Button";
import { IInteractions } from "modules/Interaction/models";

interface InteractionContainerLoadButtonProps {
  fetchNextPage: (
    options?: FetchNextPageOptions | undefined
  ) => Promise<InfiniteQueryObserverResult<IInteractions, unknown>>;
  loadMoreDisabled: boolean;
  lastInteractionRef: React.RefObject<HTMLDivElement>;
}

const InteractionContainerLoadButton = ({
  fetchNextPage,
  loadMoreDisabled,
  lastInteractionRef,
}: InteractionContainerLoadButtonProps): React.ReactElement | null => {
  const loadMoreButtonRef = React.useRef<HTMLButtonElement>(null);

  if (loadMoreDisabled) {
    return null;
  }

  return (
    <Button
      fullWidth
      ref={loadMoreButtonRef}
      onClick={() => {
        lastInteractionRef.current?.scrollIntoView();
        fetchNextPage();
      }}
    >
      Load more
    </Button>
  );
};

export default InteractionContainerLoadButton;
