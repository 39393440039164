import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import Button from "ui-kit/atoms/Button";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import clsx from "clsx";
import { CardContent } from "@mui/material";

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    padding: theme.spacing(4, 0),
  },
  content: {
    padding: theme.spacing(3, 0),
    marginLeft: 56,
  },
  divider: {
    borderBottom: `1px solid ${theme.app.palette.shadow.primary}`,
    marginBottom: theme.spacing(3),
  },
}));

interface NavItemProps {
  title: string;
  subtitle: string;
  icon: React.FunctionComponent<any>;
  color: string;
  link: string;
  divider?: boolean;
}

const NavItem = ({
  title,
  subtitle,
  icon: Icon,
  color,
  link,
  divider,
}: NavItemProps): React.ReactElement => {
  const classes = useStyles();

  return (
    <div className={clsx({ [classes.divider]: divider })}>
      <CardHeader
        className={classes.card}
        avatar={
          <Avatar>
            <Icon />
          </Avatar>
        }
        title={title}
        titleTypographyProps={{
          variant: "h6",
          gutterBottom: true,
        }}
        subheader={subtitle}
        subheaderTypographyProps={{
          color: "textSecondary",
          variant: "body2",
        }}
      />
      <CardContent className={classes.content}>
        <Button
          component={Link}
          to={link}
          variant="contained"
          color="primary"
          size="small"
        >
          Setup {title}
        </Button>
      </CardContent>
    </div>
  );
};

NavItem.defaultProps = {};

export default NavItem;
