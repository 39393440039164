import React from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import LockClockIcon from "@mui/icons-material/LockClock";
import RouterConstants from "core/routes/constants";
import ConfigConstant from "core/constants/ConfigConstant";
import { isLessThanNHoursInThePast } from "core/utils/dateHandler";
import { AccountContext } from "modules/Account/context";
import { UserContext } from "modules/User/context";
import { getAccountName } from "modules/Account/utils";
import AccountService from "modules/Account/services";
import { AccountQuery, AccountTabs } from "modules/Account/models";

const useStyles = makeStyles((theme: Theme) => ({
  action: {
    paddingTop: 0,
  },
}));

const FuseLimitAlert = (): React.ReactElement | null => {
  const classes = useStyles();
  const { user } = React.useContext(UserContext);
  const { account } = React.useContext(AccountContext);
  const { id: accountId } = account;
  const [open, setOpen] = React.useState(false);

  const fetchOnline = async () => {
    try {
      const { data } = await AccountService.fetchAccountIsOnline(accountId);
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };
  const { data: accountData } = useQuery(
    [AccountQuery.is_online, accountId],
    () => fetchOnline(),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: !!accountId && !user.anonymous,
    }
  );

  if (
    !accountId ||
    !accountData ||
    // Fuse limit as valid for 24 hours
    !isLessThanNHoursInThePast(
      ConfigConstant.HOURS.FUSE_LIMIT,
      accountData.fuse_limit_at
    )
  ) {
    return null;
  }

  return (
    <Alert
      sx={{ mb: 5, alignItems: "center" }}
      variant="standard"
      severity="warning"
      classes={{
        action: classes.action,
      }}
      icon={<LockClockIcon fontSize="inherit" />}
      action={
        <Button
          color="inherit"
          size="small"
          onClick={() => {
            setOpen(!open);
          }}
        >
          {open ? "Hide" : "Learn more"}
        </Button>
      }
    >
      <Typography variant="subtitle2">
        {getAccountName(account)} sent maximum invitations for now.
      </Typography>
      <Collapse in={open}>
        <Box sx={{ mt: open ? 2 : 0 }}>
          <Typography variant="body2" gutterBottom>
            LinkedIn has set a weekly limit on sending invitations to prevent
            spam, between 100-200.
          </Typography>
          <Typography variant="body2">
            Don't worry, the limit refreshes daily, and your invites will be
            sent once it's lifted. You can update your max. invites per day in{" "}
            <Link
              to={RouterConstants.ACCOUNT.detail(
                accountId,
                AccountTabs["limits"]
              )}
            >
              Limits
            </Link>
            .
          </Typography>
        </Box>
      </Collapse>
    </Alert>
  );
};

export default FuseLimitAlert;
