import { Theme } from "@mui/material";
import Autocomplete, {
  AutocompleteRenderInputParams,
} from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from "@mui/material/InputAdornment";
import { makeStyles } from "@mui/styles";
import { IOption } from "core/models";
import RouterUtils from "core/routes/utils";
import isEmpty from "lodash/isEmpty";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import TextField from "ui-kit/atoms/TextField";
import CheckboxAutocompleteOption from "ui-kit/components/CheckboxAutocompleteOption";

interface AutocompleteFilterProps {
  id: string;
  label: string;
  options: IOption[] | undefined;
  isLoading?: boolean;
  disableCloseOnSelect?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  control: {
    marginBottom: 0,
  },
  checkbox: {
    padding: theme.spacing(0.75, 0),
    marginRight: theme.spacing(2.75),
  },
  itemText: {
    ...theme.app.constants.textOneLiner,
  },
}));

const getDefaultValue = (
  query: string | string[],
  options: IOption[] | undefined
) => {
  const defaultValue: IOption[] = [];
  if (Array.isArray(query)) {
    query.forEach((queryId) => {
      const selected = options?.find((option) => {
        const result = option.id === Number(queryId);
        return result;
      });
      if (selected) {
        defaultValue.push(selected);
      }
    });
  } else {
    const selected = options?.find((option) => {
      if (option.id === Number(query)) {
        return option.id === Number(query);
      }
      return option.id === query;
    });
    if (selected) {
      defaultValue.push(selected);
    }
  }

  return defaultValue;
};

const AutocompleteFilter = ({
  options,
  id,
  label: defaultLabel,
  isLoading = false,
  disableCloseOnSelect = false,
}: AutocompleteFilterProps): React.ReactElement => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { [id]: query } = RouterUtils.parseQueryParams(location);

  const [value, setValue] = React.useState<IOption[]>([]);

  // Run only on the first update
  const firstUpdateQuery = React.useRef(true);
  React.useEffect(() => {
    if (options) {
      if (!firstUpdateQuery.current) {
        return;
      }

      const defaultValue = getDefaultValue(query, options);
      setValue(defaultValue);

      firstUpdateQuery.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  // Fail on the first update
  const firstUpdateState = React.useRef(true);
  React.useEffect(() => {
    if (firstUpdateState.current) {
      firstUpdateState.current = false;
      return undefined;
    }

    const ids = value.map((v) => String(v.id));

    if (ids.length === 0) {
      return RouterUtils.removeQuery([id], history);
    }

    return RouterUtils.addQuery({ [id]: ids }, history);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleOnChange = (
    event: React.SyntheticEvent<Element, Event>,
    values: IOption[]
  ) => {
    setValue(values);
  };

  const getPlaceholder = () => {
    if (isLoading) {
      return "Loading...";
    }

    if (isEmpty(value)) {
      return defaultLabel;
    }

    return undefined;
  };

  return (
    <Autocomplete
      multiple
      value={value}
      autoHighlight
      limitTags={1}
      disableClearable
      options={options || []}
      disableCloseOnSelect={disableCloseOnSelect}
      onChange={handleOnChange}
      disabled={isLoading}
      loading={isLoading}
      getOptionLabel={(option: IOption) => option.name}
      isOptionEqualToValue={(option, v) => {
        return option.id === v.id;
      }}
      renderOption={(props, option, { selected }) => (
        <CheckboxAutocompleteOption
          props={props}
          option={option}
          selected={selected}
        />
      )}
      renderInput={(params: AutocompleteRenderInputParams) => {
        return (
          <TextField
            {...params}
            placeholder={getPlaceholder()}
            disabled={false}
            fullWidth
            // Set focus instead of autoFocus
            // autoFocus didn't focus on first render
            inputRef={(input) =>
              input &&
              setTimeout(() => {
                input.focus();
              }, 0)
            }
            variant="outlined"
            className="multiple"
            classes={{ root: classes.control }}
            InputProps={{
              ...params.InputProps,
              className: `${params.InputProps.className} Mui-TextField-filter`,
              endAdornment: isLoading ? (
                <InputAdornment position="start">
                  <CircularProgress size={20} color="secondary" />
                </InputAdornment>
              ) : (
                params.InputProps.endAdornment
              ),
            }}
          />
        );
      }}
    />
  );
};

AutocompleteFilter.defaultProps = {
  isLoading: false,
  disableCloseOnSelect: false,
};

export default AutocompleteFilter;
