import DownloadIcon from "@mui/icons-material/Download";
import axios, { AxiosResponse } from "axios";
import RouterUtils from "core/routes/utils";
import { errorHandler, IErrorResponse } from "core/utils/errorHandler";
import { AccountContext } from "modules/Account/context";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import slugify from "slugify";
import Button from "ui-kit/atoms/Button";

interface ExportSearchButtonProps {
  searchId: number;
  searchName: string | undefined;
}

const getQuery = (
  searchId: number,
  accountId: number,
  searchQuery: string,
  campaignQuery: string
) => {
  return `/inevitable/search_results/list_csv/?page=1&page_size=2500&search_id=${searchId}&tag_account_id=${accountId}${searchQuery}${campaignQuery}`;
};

function ExportSearchButton({ searchId, searchName }: ExportSearchButtonProps) {
  const [loading, setLoading] = useState(false);

  const location = useLocation();

  const { search } = RouterUtils.getQueryParams(location);
  const { campaignId } = RouterUtils.parseQueryParams(location);

  const {
    account: { id: accountId },
  } = React.useContext(AccountContext);

  const handleClick = () => {
    setLoading(true);

    const query = getQuery(
      searchId,
      accountId,
      RouterUtils.generateFilterNameQuery(search, "search"),
      RouterUtils.generateFilterNameQuery(
        campaignId,
        "interaction__campaign_id"
      )
    );

    axios
      .get<ArrayBuffer>(query, { responseType: "arraybuffer" })
      .then((response: AxiosResponse<ArrayBuffer>) => {
        const fileName = searchName ? slugify(searchName) : "data";
        const extension = ".csv";
        const fullName = fileName + extension;
        const fileBlob = new Blob([response.data], { type: "text/csv" });

        // Create a link element to trigger the download
        const linkElement = document.createElement("a");
        linkElement.href = window.URL.createObjectURL(fileBlob);
        linkElement.download = fullName;

        // Append the link element to the document and click it to trigger the download
        document.body.appendChild(linkElement);
        linkElement.click();

        // Clean up the link element
        document.body.removeChild(linkElement);
      })
      .catch((error: IErrorResponse) => {
        errorHandler(error.response);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // Render the download button with a click event handler that enables the query
  return (
    <Button
      color="primary"
      size="small"
      variant="contained"
      type="button"
      startIcon={<DownloadIcon />}
      onClick={handleClick}
      disabled={loading}
    >
      Export
    </Button>
  );
}

export default ExportSearchButton;
