import {
  ContactDetailFilters,
  ContactDetailMatchFilterLabel,
  ContactConnectionFilters,
  ContactConnectionMatchFilterLabel,
} from "../models";

const createContactDetailFilterOptions = (id: ContactDetailFilters) => ({
  id: ContactDetailFilters[id],
  name: ContactDetailMatchFilterLabel[id],
  query: { person__email__isnull: false },
});

const CONTACT_DETAIL_FILTER_OPTIONS = [
  createContactDetailFilterOptions(ContactDetailFilters.email),
];

const CONTACT_CONNECTION_FILTER_OPTIONS = [
  {
    id: ContactConnectionFilters.connected,
    name: ContactConnectionMatchFilterLabel.connected,
    query: { active_connection: true },
  },
  {
    id: ContactConnectionFilters.not_connected,
    name: ContactConnectionMatchFilterLabel.not_connected,
    query: { active_connection: false },
  },
];

export { CONTACT_DETAIL_FILTER_OPTIONS, CONTACT_CONNECTION_FILTER_OPTIONS };
