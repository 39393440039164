/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from "@hookform/resolvers/yup";
import Box from "@mui/material/Box";
import { ButtonProps } from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import RouterPrompt from "core/routes/components/RouterPrompt";
import { getIsDirty } from "core/utils/commonHandler";
import { errorHandler, IErrorResponse } from "core/utils/errorHandler";
import { snackbarHandler } from "core/utils/snackbarHandler";
import get from "lodash/get";
import React from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import Button, { ColorTypes } from "ui-kit/atoms/Button";
import PaperHeader, {
  PaperHeaderProps as IPaperHeaderProps,
} from "ui-kit/components/PaperHeader";

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    padding: theme.spacing(3.5, 2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(4, 0, 6),
    },
  },
}));

interface SettingsFormProps {
  FormProps: {
    id?: number | string | undefined;
    name?: [string, number | string | null];
    func: any;
    successMsg?: (data: any, credentials: any) => void;
    errorMsg?: (data: any) => void;
    onSuccess?: (data: any) => void;
    onSettled?: () => void;
    onError?: () => void;
    format: (data: any) => any;
    schema?: any;
    defaultValues?: any;
    resetForm?: boolean;
    resetFormData?: any;
    resetHandler?: boolean;
  };
  components?: {
    name: React.FunctionComponent<any>;
    key: string;
    props?: any;
  }[];
  PaperHeaderProps?: IPaperHeaderProps;
  hiddenDivider?: boolean;
  SubmitButtonProps?: {
    value?: string;
    color?: string;
    endIcon?: React.ReactElement;
    disabled?: boolean;
    hidden?: boolean;
    removeDisabled?: boolean;
    ButtonProps?: ButtonProps;
  };
}

const defaultProps = {
  hiddenDivider: false,
  components: [],
  SubmitButtonProps: {
    value: "Save changes",
    color: "primary",
    disabled: false,
    hidden: false,
    variant: "contained",
    removeDisabled: false,
    ButtonProps: {},
  },
  PaperHeaderProps: false,
};

const SettingsItem = ({
  components,
  PaperHeaderProps,
  SubmitButtonProps,
  FormProps,
  hiddenDivider,
}: SettingsFormProps): React.ReactElement => {
  const classes = useStyles();
  const {
    errors,
    control,
    handleSubmit,
    getValues,
    setValue,
    setError,
    reset,
    watch,
    formState,
  } = useForm<any>({
    ...(FormProps.schema && { resolver: yupResolver(FormProps.schema) }),
    defaultValues: FormProps.defaultValues,
  });
  const isDirty = getIsDirty(formState.dirtyFields);

  const queryClient = useQueryClient();
  const mutateOnSubmit = useMutation(
    (data) => FormProps.func(FormProps.id, data),
    {
      onSuccess: ({ data }, credentials) => {
        reset(getValues());
        queryClient.invalidateQueries(FormProps.name);
        if (FormProps.successMsg) {
          FormProps.successMsg(data, credentials);
        } else {
          snackbarHandler.success("Successfully updated!");
        }
        if (FormProps.onSuccess) {
          FormProps.onSuccess(data);
        }
        if (FormProps.resetForm) {
          reset(FormProps.resetFormData || FormProps.defaultValues);
        }
      },
      onError: (error: IErrorResponse) => {
        if (FormProps.errorMsg) {
          return FormProps.errorMsg(error.response?.data);
        }

        if (FormProps.onError) {
          FormProps.onError();
        }

        errorHandler(error.response, setError);
      },
      onSettled: () => {
        if (FormProps.onSettled) {
          FormProps.onSettled();
        }
      },
    }
  );

  const onSubmit = (data: any) => {
    const newData = FormProps.format(data);
    mutateOnSubmit.mutate(newData);
  };

  // If reset handler, update data with new values
  // Using for User localStorage update
  React.useEffect(() => {
    if (FormProps.resetHandler) {
      reset(FormProps.defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [FormProps.resetHandler]);

  return (
    <>
      <div className={classes.box}>
        {PaperHeaderProps && <PaperHeader {...PaperHeaderProps} />}
        <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <div>
            {components?.map(({ name: Component, key, props }) => {
              return (
                <Component
                  key={key}
                  name={key}
                  control={control}
                  error={get(errors, key)}
                  watch={watch}
                  setValue={setValue}
                  {...props}
                />
              );
            })}
          </div>
          {!SubmitButtonProps?.hidden && (
            <Box sx={{ pt: 2 }}>
              <Button
                {...SubmitButtonProps?.ButtonProps}
                variant={SubmitButtonProps?.ButtonProps?.variant || "contained"}
                color={SubmitButtonProps?.color as ColorTypes}
                type="submit"
                endIcon={SubmitButtonProps?.endIcon}
                {...(!SubmitButtonProps?.removeDisabled && {
                  disabled:
                    // Check if "save changes" button
                    SubmitButtonProps?.color === "primary" &&
                    (!isDirty || SubmitButtonProps?.disabled),
                })}
              >
                {SubmitButtonProps?.value}
              </Button>
            </Box>
          )}
        </form>
      </div>
      {hiddenDivider || <Divider />}
      <RouterPrompt when={isDirty} />
    </>
  );
};

SettingsItem.defaultProps = defaultProps;

export default SettingsItem;
