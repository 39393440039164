import Autocomplete, {
  AutocompleteInputChangeReason,
  AutocompleteRenderInputParams,
} from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from "@mui/material/InputAdornment";
import { IAutocompleteOption, IAutocompleteOptionItem } from "core/models";
import React from "react";
import { Control, Controller, FieldErrors } from "react-hook-form";
import TextField from "ui-kit/atoms/TextField";

interface AutocompleteFieldProps {
  control: Control;
  errors: FieldErrors;
  disableCloseOnSelect?: boolean;
  RenderOptionComponent?: React.FunctionComponent<any>;
  name: string;
  label: string;
  multiple?: boolean;
  options: IAutocompleteOptionItem[];
  handleInputChange: (
    event: React.SyntheticEvent<Element, Event>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => void;
  isLoading: boolean;
}

const AutocompleteField = ({
  control,
  errors,
  name,
  label,
  options,
  isLoading,
  handleInputChange,
  RenderOptionComponent,
  multiple = false,
  ...rest
}: AutocompleteFieldProps): React.ReactElement => (
  <Controller
    render={(props: {
      value: any;
      onChange: (newData: IAutocompleteOption) => void;
    }) => {
      const handleChangeAll = (s: boolean) => {
        // If selected, we need to add ALL
        if (s) {
          props.onChange(options);
          // If not selected, we need to erase all
        } else {
          props.onChange([]);
        }
      };

      return (
        <Autocomplete
          {...props}
          {...rest}
          multiple={multiple}
          options={options}
          autoHighlight
          onChange={(_, newData) => {
            props.onChange(newData as IAutocompleteOption);
          }}
          {...(RenderOptionComponent && {
            renderOption: (_props, option, state) => {
              const renderOptionProps = {
                key: option.label,
                props: _props,
                option: option,
                selected: state.selected,
                onHandleChange:
                  option.label === "Select all" ? handleChangeAll : undefined,
              };

              return <RenderOptionComponent {...renderOptionProps} />;
            },
          })}
          onInputChange={handleInputChange}
          loading={isLoading}
          getOptionLabel={(option: { label: string }) => option.label}
          // isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <TextField
              {...params}
              error={!!errors[name] || !!errors[name]?.id}
              helperText={errors[name]?.message || errors[name]?.id?.message}
              {...(multiple && { className: "multiple" })}
              fullWidth
              variant="outlined"
              label={label}
              InputProps={{
                ...params.InputProps,
                endAdornment: isLoading ? (
                  <InputAdornment position="start" sx={{ marginTop: -3 }}>
                    <CircularProgress size={20} color="secondary" />
                  </InputAdornment>
                ) : (
                  params.InputProps.endAdornment
                ),
              }}
            />
          )}
        />
      );
    }}
    id={name}
    name={name}
    control={control}
  />
);

AutocompleteField.defaultProps = {
  multiple: false,
  disableCloseOnSelect: false,
  RenderOptionComponent: undefined,
};

export default AutocompleteField;
