import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import ConfigConstant from "core/constants/ConfigConstant";
import RouterConstants from "core/routes/constants";
import {
  errorHandler,
  handleCaptureMessage,
  handleInvalidLogout,
  IErrorResponse,
} from "core/utils/errorHandler";
import { snackbarHandler } from "core/utils/snackbarHandler";
import { AccountContext } from "modules/Account/context";
import { AccountActionType } from "modules/Account/reducers";
import { getAccountName, handleAccountDeletion } from "modules/Account/utils";
import { UserContext } from "modules/User/context";
import { UserActionType } from "modules/User/reducers";
import UserService from "modules/User/services";
import { useContext } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import PageAlert from "ui-kit/components/PageAlert";
import TextButtonBox from "ui-kit/components/TextButtonBox";

const AccountPermission = (): React.ReactElement | null => {
  const history = useHistory();

  const { dispatch: dispatchAccount, account } = useContext(AccountContext);
  const { dispatch: dispatchUser } = useContext(UserContext);

  const queryClient = useQueryClient();

  const accountName = getAccountName(account);

  const handleLogout = () => {
    dispatchUser({ type: UserActionType.LOGOUT });
    dispatchAccount({ type: AccountActionType.LOGOUT });

    history.push(RouterConstants.ROOT);
    snackbarHandler.success("Logout successful.");
    queryClient.removeQueries();

    // Clear localStorage
    handleAccountDeletion(dispatchAccount);
  };

  const mutateLogout = useMutation(() => UserService.createLogout(), {
    onSuccess: () => {
      handleLogout();
    },
    onError: (error: IErrorResponse) => {
      const result = handleInvalidLogout(error);

      if (!result) {
        errorHandler(error.response);
      }

      // Mock successful logout
      handleLogout();
    },
    onMutate: () => {
      handleCaptureMessage("Logout triggered.");
    },
  });

  const handleSignOut = () => mutateLogout.mutate();

  return (
    <>
      <PageAlert
        title={
          <>
            The account <b>{accountName}</b> can't be accessed. 🚫
          </>
        }
        body={
          <>
            This could be due to one of the reasons:
            <Box mt={3}>
              <Typography variant="subtitle1" fontWeight={600} gutterBottom>
                1. Account moved
              </Typography>
              If your account has been moved under another{" "}
              {ConfigConstant.APP_NAME} user (likely to a team account),
              consider the following steps:
              <ol>
                <li>Contact your team manager for the new login credentials</li>
                <li>
                  <TextButtonBox
                    endText={`of your current ${ConfigConstant.APP_NAME} user`}
                    buttonProps={{ onClick: handleSignOut, children: "Logout" }}
                  />
                </li>
                <li>
                  Log in to your new {ConfigConstant.APP_NAME} team account
                </li>
              </ol>
            </Box>
            <Box mt={3}>
              <Typography variant="subtitle1" fontWeight={600} gutterBottom>
                2. Viewer access
              </Typography>
              If you're not the owner of this account and someone invited you,
              they can give you only Viewer access. If you want to add or edit
              data, you need manager access to this account. Request it from the
              account owner.
            </Box>
          </>
        }
      />
    </>
  );
};

export default AccountPermission;
