import ElementField from "core/components/ElementField";
import SettingsItem from "core/components/SettingsItem";
import SwitchField from "core/components/SwitchField";
import ConfigConstant from "core/constants/ConfigConstant";
import { createComponents } from "core/utils/componentsHandler";
import { get } from "lodash";
import { AccountContext } from "modules/Account/context";
import {
  AccountQuery,
  IAccount,
  IAccountDetailItemProps,
} from "modules/Account/models";
import { AccountActionType } from "modules/Account/reducers";
import AccountService from "modules/Account/services";
import React from "react";
import { useQueryClient } from "react-query";

const PrivacySettings = ({
  accountId,
}: IAccountDetailItemProps): React.ReactElement | null => {
  const queryClient = useQueryClient();

  const [account, setAccount] = React.useState<IAccount | undefined>(undefined);
  const data: IAccount | undefined = queryClient.getQueryData([
    AccountQuery.account,
    accountId,
  ]);
  React.useEffect(() => {
    if (data) {
      setAccount(data);
    }
  }, [data]);

  const { account: currentAccount, dispatch } =
    React.useContext(AccountContext);

  if (!account) return null;

  return (
    <>
      <SettingsItem
        FormProps={{
          id: accountId,
          name: [AccountQuery.account, accountId],
          func: AccountService.updateAccount,
          format: ({ inbox_privacy }: { inbox_privacy: boolean }) => ({
            meta_data: { ...account.meta_data, inbox_privacy },
          }),
          onSuccess: (data: IAccount) => {
            dispatch({
              type: AccountActionType.UPDATE_ACCOUNT,
              payload: {
                ...currentAccount,
                meta_data: data.meta_data,
              },
            });
          },
          defaultValues: {
            inbox_privacy: get(account, "meta_data.inbox_privacy") || false,
          },
        }}
        PaperHeaderProps={{
          title: "Inbox privacy",
          body: `You can hide your private conversations in ${ConfigConstant.APP_NAME} and only show messages with people in your campaigns.`,
        }}
        components={[
          createComponents(ElementField, "inbox_privacy", {
            InputProps: {
              label: `Show only campaign messages`,
              sx: { marginBottom: 4, marginLeft: 1 },
            },
            component: SwitchField,
          }),
        ]}
        // Last item in the list
        hiddenDivider
      />
    </>
  );
};

export default PrivacySettings;
