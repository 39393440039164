import React from "react";
import { makeStyles } from "@mui/styles";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  single: {
    width: 12,
    height: 12,
  },
  docs: {
    width: 11,
    height: 11,
  },
  icon: {
    color: "inherit",
    cursor: "inherit",
    "&:hover": {
      color: "inherit",
    },
  },
}));

interface InfoIconProps extends SvgIconProps {
  type?: "single" | "docs";
}

const InfoIcon: React.FC<InfoIconProps> = React.forwardRef<SVGSVGElement>(
  function Icon(props: SvgIconProps, ref): React.ReactElement {
    const { type = "single" } = props;
    const classes = useStyles();
    return (
      <SvgIcon
        {...props}
        ref={ref}
        className={clsx({
          [classes.single]: type === "single",
          [classes.docs]: type === "docs",
        })}
      >
        <svg
          className={clsx(classes.icon)}
          height="30"
          width="30"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9 8a1 1 0 0 0-1-1H5.5a1 1 0 1 0 0 2H7v4a1 1 0 0 0 2 0zM4 0h8a4 4 0 0 1 4 4v8a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4zm4 5.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"
          />
        </svg>
      </SvgIcon>
    );
  }
);

export default InfoIcon;
