import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import TextField from "@mui/material/TextField";
import { errorHandler, IErrorResponse } from "core/utils/errorHandler";
import { snackbarHandler } from "core/utils/snackbarHandler";
import { AccountContext } from "modules/Account/context";
import PlaceholderService from "modules/Placeholder/services";
import { Box } from "@mui/material";

const useStyles = makeStyles((theme: Theme) => ({
  textfield: {
    marginBottom: 0,
  },
  input: {
    fontSize: "0.875rem !important",
    color: theme.app.palette.text.secondary,
  },
  limit: {
    fontSize: 11,
    color: theme.app.palette.action.placeholder,
  },
  fakeInput: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    borderBottom: `1px solid ${theme.app.palette.action.main}`,
    "&:hover": {
      cursor: "pointer",
      borderBottomWidth: 2,
      borderBottomColor: theme.palette.text.primary,
    },
  },
}));

interface TableEditableCellProps {
  className: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  content: any;
}

const TablePlaceholderCell = ({
  className,
  content,
}: TableEditableCellProps): React.ReactElement => {
  const classes = useStyles();
  const [value, setValue] = React.useState<string>(content?.value || "");

  // Use fake input that on click changes into a textfield to improve performance
  const [isEditable, setIsEditable] = React.useState<boolean>(false);
  const inputRef = React.useRef<HTMLInputElement | null>(null);

  const {
    account: { id: currentAccountId },
  } = React.useContext(AccountContext);

  const queryClient = useQueryClient();

  const handleOnEditable = () => {
    setIsEditable(true);

    setTimeout(() => {
      if (!!inputRef.current) {
        inputRef.current.focus();
      }
    }, 100);
  };

  const mutateCreatePlaceholder = useMutation(
    (newValue: string) =>
      PlaceholderService.createPlaceholder({
        key_string: content?.key,
        value: newValue,
        person: content.person,
        account: currentAccountId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("placeholders");
        snackbarHandler.success("Successfully updated!");
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);
      },
    }
  );

  const mutateUpdatePlaceholder = useMutation(
    (newValue: string) =>
      PlaceholderService.updatePlaceholder({ id: content.id, value: newValue }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("placeholders");
        snackbarHandler.success("Successfully updated!");
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);
      },
    }
  );

  const mutateDeletePlaceholder = useMutation(
    () => PlaceholderService.deletePlaceholder(content.id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("placeholders");
        snackbarHandler.success("Successfully updated!");
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);
      },
    }
  );

  const handleMutation = (newValue: string) => {
    if (newValue === content?.value) {
      // Exit if default placeholder not overwritten by custom or value not changed
      return false;
    }

    // Exist and notify if user tried to delete default placeholder
    if (!content?.id && !newValue) {
      setValue(content?.value);
      return snackbarHandler.error(
        "Default placeholder can't be deleted, only over-written by custom placeholder!"
      );
    }

    // Handle mutation

    // If ID and value exist, then update
    if (value && content.id) {
      // Update
      return mutateUpdatePlaceholder.mutate(newValue);
    }

    // If ID exists and value is empty that is delete
    if (!value && content.id) {
      // Delete
      return mutateDeletePlaceholder.mutate();
    }

    return mutateCreatePlaceholder.mutate(newValue);
  };

  const onSubmit = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => handleMutation(event.target.value);

  const onKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      handleMutation((event.target as HTMLTextAreaElement).value);
    }
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setValue(event.target.value);

  return (
    <TableCell variant="body" className={className}>
      {isEditable ? (
        <TextField
          value={value}
          inputRef={inputRef}
          onChange={handleOnChange}
          className={classes.textfield}
          variant="standard"
          InputProps={{
            classes: {
              input: classes.input,
            },
          }}
          onKeyDown={onKeyPress}
          onBlur={onSubmit}
        />
      ) : (
        <Box
          sx={{
            paddingTop: value ? 1 : 3.5,
            paddingBottom: value ? 1 : 3.5,
          }}
          className={classes.fakeInput}
          onClick={handleOnEditable}
        >
          {value}
        </Box>
      )}
    </TableCell>
  );
};

export default TablePlaceholderCell;
