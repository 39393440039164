import Dialog from "@mui/material/Dialog";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import backgroundImg from "assets/images/background-app.png";
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    width: "100%",
    maxWidth: 900,
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(0, 4),
    },
    borderRadius: 10,
  },
  backgroundImg: {
    filter: "blur(4px)",
    height: "100vh",
    width: "100%",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundImage: `url(${backgroundImg})`,
  },
  backgroundOverlay: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 100,
    background: "#161631",
    opacity: 0.4,
  },
}));

interface BackgroundOverlayProps {
  children: React.ReactElement | React.ReactElement[];
}

const BackgroundOverlay = ({
  children,
}: BackgroundOverlayProps): React.ReactElement => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.backgroundImg}></div>
      <div className={classes.backgroundOverlay}></div>
      <Dialog open PaperProps={{ className: classes.dialog }}>
        {children}
      </Dialog>
    </div>
  );
};

export default BackgroundOverlay;
