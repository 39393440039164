import React from "react";
import { getPlaceholderContent } from "modules/Placeholder/utils";
import { IPerson } from "modules/Person/models";
import { DefaultPlaceholdersKeys } from "modules/Placeholder/models";
import TableBodyCellPlaceholder from "ui-kit/components/TableBodyCellPlaceholder";

interface ContactPlaceholderCellProps {
  person: IPerson;
  placeholderKey: DefaultPlaceholdersKeys;
}

const ContactPlaceholderCell = ({
  person,
  placeholderKey,
}: ContactPlaceholderCellProps): React.ReactElement => {
  let content = "";

  if (person) {
    content = getPlaceholderContent(
      person.placeholders,
      person.default_placeholders,
      undefined, // Contact doesn't have search result and already has person
      placeholderKey
    );
  }

  return <>{content || <TableBodyCellPlaceholder />}</>;
};

export default ContactPlaceholderCell;
