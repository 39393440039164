import { Box } from "@mui/material";
import ReportList, {
  ReportListProps,
} from "modules/Report/components/ReportList";
import SelectDateRange from "modules/Report/components/SelectDateRange";
import React from "react";
import PageHeader from "ui-kit/components/PageHeader";

interface ReportContainerProps extends ReportListProps {}

const ReportContainer = (props: ReportContainerProps): React.ReactElement => {
  return (
    <>
      <PageHeader
        title="Analytics"
        disableBodyComponent
        customAction={<SelectDateRange />}
      />
      <Box mt={-4}>
        <ReportList {...props} />
      </Box>
    </>
  );
};

export default ReportContainer;
