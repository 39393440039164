import {
  CampaignSequenceVariant,
  ICampaignParams,
} from "modules/Campaign/models";
import React from "react";
import CampaignSequenceView from "../CampaignSequenceView";

const CreateSteps = (props: ICampaignParams): React.ReactElement => {
  return (
    <CampaignSequenceView {...props} variant={CampaignSequenceVariant.draft} />
  );
};

export default CreateSteps;
