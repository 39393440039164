import PersonIcon from "@mui/icons-material/Person";
import { Box } from "@mui/material";
import Avatar, { AvatarProps } from "@mui/material/Avatar";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/system";
import clsx from "clsx";
import { PersonAvatarVariants } from "modules/Person/models";
import { encodeImage } from "modules/Person/utils";
import React from "react";

const getBackgroundInitials = (s: string) =>
  s
    ?.split(" ")
    .map((i) => i.charAt(0))
    .slice(0, 2);

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    textTransform: "uppercase",
    backgroundColor: theme.app.palette.action.selectedBackground,
    border: `1px solid ${theme.app.palette.shadow.primary}`,
    color: theme.app.palette.action.color,
    lineHeight: 1.5,
    fontWeight: 500,
  },
  avatarMain: {
    boxShadow: `rgba(65, 69, 82, 0.08) 0px 2px 5px 0px, rgba(0,0,0,0.12) 0px 1px 1px 0px`,
  },
  mini: {
    fontSize: 10,
    width: theme.spacing(6.5),
    height: theme.spacing(6.5),
  },
  small: {
    fontSize: 12,
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
  medium: {
    fontSize: 14,
    width: theme.spacing(9.25),
    height: theme.spacing(9.25),
  },
  large: {
    fontSize: 16,
    width: theme.spacing(10.25),
    height: theme.spacing(10.25),
  },
}));

interface PersonAvatarProps extends AvatarProps {
  name: string;
  size?: "mini" | "small" | "medium" | "large";
  src: string | undefined;
  placeholderVariant?: PersonAvatarVariants;
  enableMain?: boolean;
}

const PersonAvatar = ({
  name,
  size = "medium",
  src,
  placeholderVariant = PersonAvatarVariants.contact,
  enableMain = false,
  ...rest
}: PersonAvatarProps): React.ReactElement => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const classes: any = useStyles();
  const theme: Theme = useTheme();
  let component = null;

  if (!!src) {
    component = (
      <Box
        component="img"
        src={encodeImage(src)}
        alt="author"
        className={clsx(classes[size])}
      />
    );
  }

  if (!src && placeholderVariant === PersonAvatarVariants.contact) {
    component = getBackgroundInitials(name);
  }

  if (!src && placeholderVariant === PersonAvatarVariants.account) {
    component = <PersonIcon sx={{ color: theme.app.palette.action.check }} />;
  }

  return (
    <Avatar
      {...rest}
      className={clsx(classes.avatar, classes[size], {
        [classes.avatarMain]: enableMain,
      })}
    >
      {component}
    </Avatar>
  );
};

PersonAvatar.defaultProps = {
  size: "medium",
  enableMain: false,
};

export default PersonAvatar;
