import { IGlobalContext, INewGlobalContext } from "../models";

export enum GlobalActionType {
  SET_GLOBAL,
}

export type GlobalAction = {
  type: GlobalActionType.SET_GLOBAL;
  payload: INewGlobalContext;
};

export const globalReducer = (
  state: IGlobalContext,
  action: GlobalAction
): IGlobalContext => {
  switch (action.type) {
    case GlobalActionType.SET_GLOBAL: {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
};
