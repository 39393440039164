import ConfigConstant from "core/constants/ConfigConstant";
import KeywordFilter from "core/filters/atoms/KeywordFilter";
import RouterConstants from "core/routes/constants";
import RouterUtils from "core/routes/utils";
import { formatDateTimeToFull } from "core/utils/dateHandler";
import { createTableCell } from "core/utils/tableHandler";
import CreateSequence from "modules/Sequence/components/CreateSequence";
import { SequenceDetailTabs } from "modules/Sequence/models";
import SequenceService from "modules/Sequence/services";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import NotFound from "ui-kit/components/NotFound";
import PageHeader from "ui-kit/components/PageHeader";
import Table from "ui-kit/components/Table";
import TableBodyCell from "ui-kit/components/TableBodyCell";

const rowsPerPage = ConfigConstant.PAGE_SIZE.MEDIUM;
const title = "Templates";
const heads = [
  {
    id: "name",
    percentage: true,
    width: 83,
    label: "Name",
  },
  {
    id: "created",
    percentage: true,
    width: 17,
    label: "Created at",
  },
];

const SequenceList = (): React.ReactElement => {
  const [openNewDialog, toggleNewDialog] = React.useState(false);
  const [page, setPage] = useState<number>(ConfigConstant.INITIAL_PAGE);
  const history = useHistory();
  const location = useLocation();
  const { search } = RouterUtils.getQueryParams(location);

  const fetchSequences = async (p: number) => {
    try {
      const { data } = await SequenceService.fetchTemplateSequences(
        p,
        rowsPerPage,
        search
      );
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };
  const { data, error, isFetching } = useQuery(
    ["sequences", page, search],
    () => fetchSequences(page),
    {
      keepPreviousData: true,
    }
  );

  // Route - if goes to sequence/new, open dialog
  React.useEffect(() => {
    const isNewSequenceRoute = RouterUtils.isCurrentRoute(
      location.pathname,
      RouterConstants.SEQUENCE.NEW
    );
    if (isNewSequenceRoute && openNewDialog === false) {
      toggleNewDialog(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const tabSlug = SequenceDetailTabs.actions;
  const actionProps = {
    text: "Create template",
    onClick: () => {
      history.push(RouterConstants.SEQUENCE.NEW);
    },
  };

  if (error) {
    return <NotFound label="templates" link={RouterConstants.ROOT} />;
  }

  return (
    <>
      <PageHeader
        title={title}
        actionProps={actionProps}
        body={`Use templates to quickly pre-compose the steps in your new campaigns.`}
        // helperProps={{
        //   link: RouterConstants.DOCS.SEQUENCES,
        // }}
        filterProps
      />
      <CreateSequence open={openNewDialog} toggleOpen={toggleNewDialog} />
      <Table
        title={title}
        filters={
          <>
            <KeywordFilter />
          </>
        }
        heads={heads}
        rows={
          data
            ? data.results.map((sequence) => {
                return {
                  name: sequence.id.toString(),
                  data: [
                    createTableCell(
                      sequence.name,
                      RouterConstants.SEQUENCE.detail(sequence.id, tabSlug),
                      "main",
                      TableBodyCell
                    ),
                    createTableCell(
                      formatDateTimeToFull(sequence.created),
                      RouterConstants.SEQUENCE.detail(sequence.id, tabSlug),
                      "default",
                      TableBodyCell
                    ),
                  ],
                };
              })
            : []
        }
        count={data?.count || 0}
        // INITIAL_PAGE starts at 1, but Pagination starts at 0
        page={(data?.current || page) - 1}
        setPage={setPage}
        isFetching={isFetching}
        actionPropsIfEmpty={actionProps}
        rowsPerPage={rowsPerPage}
      />
    </>
  );
};

export default SequenceList;
