import { useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import { Theme } from "@mui/material/styles";
import { makeStyles, useTheme } from "@mui/styles";
import ConfigConstant from "core/constants/ConfigConstant";
import AutocompleteMultipleFilter from "core/filters/atoms/AutocompleteMultipleFilter";
import ButtonGroupFilter from "core/filters/atoms/ButtonGroupFilter";
import KeywordFilter from "core/filters/atoms/KeywordFilter";
import RouterUtils from "core/routes/utils";
import { createTableCell } from "core/utils/tableHandler";
import { AccountContext } from "modules/Account/context";
import { CampaignQuery, ICampaignParams } from "modules/Campaign/models";
import CampaignService from "modules/Campaign/services";
import InteractionContainerList from "modules/Interaction/components/InteractionContainerList";
import InteractionSearchResultView from "modules/Interaction/components/InteractionSearchResultView";
import InteractionStatusActions from "modules/Interaction/components/InteractionStatusActions";
import {
  INTERACTION_CONNECTION_FILTER_OPTIONS,
  INTERACTION_STATUS_FILTER_OPTIONS,
} from "modules/Interaction/constants";
import {
  IInteraction,
  InteractionFilters,
  InteractionViewVariant,
} from "modules/Interaction/models";
import { getInteractionStatusChipData } from "modules/Interaction/utils";
import ConnectionChip from "modules/Person/components/ConnectionChip";
import InteractionPerson from "modules/Person/components/InteractionPerson";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { Chip } from "ui-kit/atoms/Chip";
import FilterButton from "ui-kit/components/FilterButton";
import Table, { TableVariant } from "ui-kit/components/Table";
import TableBodyCell from "ui-kit/components/TableBodyCell";
import TableToolbar from "ui-kit/components/TableToolbar";

const rowsPerPage = ConfigConstant.PAGE_SIZE.MEDIUM;
const heads = [
  {
    id: "name",
    percentage: true,
    width: 100,
    label: "Name",
  },
];

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    display: "flex",
    marginBottom: theme.spacing(-theme.app.constants.contentBottom),
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4,
    borderTop: `1px solid ${theme.app.palette.shadow.primary}`,
    borderRight: `1px solid ${theme.app.palette.shadow.primary}`,
    borderLeft: `1px solid ${theme.app.palette.shadow.primary}`,
    overflow: "hidden",
    height: theme.app.constants.campaign.interactions.boxHeight,
  },
  inbox: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${theme.app.constants.campaign.interactions.tableWidth}px)`,
    },
  },
  table: {
    borderRight: `1px solid ${theme.app.palette.shadow.secondary}`,
    width: theme.app.constants.campaign.interactions.tableWidth,
  },
}));

const CampaignInteractions = ({
  campaignId,
}: ICampaignParams): React.ReactElement => {
  const classes = useStyles();
  const theme: Theme = useTheme();
  const desktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));

  const [page, setPage] = useState<number>(ConfigConstant.INITIAL_PAGE);
  const [selectedPersonId, setSelectedPersonId] = React.useState<number>(0);
  const [selectedInteractionId, setSelectedInteractionId] =
    React.useState<number>(0);
  const [selectedInteraction, setSelectedInteraction] = React.useState<
    IInteraction | undefined
  >(undefined);
  const location = useLocation();

  const [interactionId, setInteractionId] = React.useState<number | undefined>(
    undefined
  );

  const {
    account: { id: accountId },
  } = React.useContext(AccountContext);

  const { search, ordering } = RouterUtils.getQueryParams(location);
  const { status, connection } = RouterUtils.parseQueryParams(location);

  const [statusQuery, setStatusQuery] = React.useState(
    RouterUtils.generateFilterOptionQuery(
      status,
      INTERACTION_STATUS_FILTER_OPTIONS
    )
  );

  React.useEffect(() => {
    const newStatusQuery = RouterUtils.generateFilterOptionQuery(
      status,
      INTERACTION_STATUS_FILTER_OPTIONS
    );
    setStatusQuery(newStatusQuery);
  }, [status]);

  const [connectionQuery, setConnectionQuery] = React.useState(
    RouterUtils.generateFilterOptionQuery(
      connection,
      INTERACTION_CONNECTION_FILTER_OPTIONS
    )
  );

  React.useEffect(() => {
    const newConnectionQuery = RouterUtils.generateFilterOptionQuery(
      connection,
      INTERACTION_CONNECTION_FILTER_OPTIONS
    );
    setConnectionQuery(newConnectionQuery);
  }, [connection]);

  const fetchCampaignInteractions = async (p: number) => {
    try {
      const { data } = await CampaignService.fetchCampaignInteractions(
        campaignId,
        p,
        rowsPerPage,
        search,
        statusQuery,
        connectionQuery,
        ordering
      );
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };
  const { data, isFetching, isLoading } = useQuery(
    [
      CampaignQuery.interactions,
      campaignId,
      page,
      search,
      statusQuery,
      connectionQuery,
      ordering,
    ],
    () => fetchCampaignInteractions(page),
    { keepPreviousData: true }
  );

  const handleInteractionClick = (
    newInteraction: IInteraction | undefined,
    activateInteractionId: boolean
  ) => {
    if (!newInteraction) {
      return;
    }

    if (activateInteractionId) {
      setInteractionId(newInteraction.id);
    }

    setSelectedInteractionId(newInteraction.id);

    const newPersonId = newInteraction?.person?.id;
    if (newPersonId) {
      setSelectedInteraction(undefined);
      return setSelectedPersonId(newPersonId);
    }

    // If no person created
    setSelectedPersonId(0);
    return setSelectedInteraction(newInteraction);
  };

  React.useEffect(() => {
    if (!data?.results) {
      return;
    }
    const newInteraction = data.results[0];
    handleInteractionClick(newInteraction, false);
  }, [data?.results]);

  // Reset to audience view on back-click
  const handleBacklink = () => {
    setInteractionId(undefined);
  };

  const table = (
    <div className={classes.table}>
      <Table
        heads={heads}
        disableDivider
        rows={
          data
            ? data.results?.map((interaction) => {
                const {
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  icon: iconStatus,
                  ...statusChipData
                } = getInteractionStatusChipData(interaction);

                const handleOnClick = () => {
                  handleInteractionClick(interaction, true);
                };

                return {
                  name: interaction.id.toString(),
                  meta: {
                    person: interaction.person?.id?.toString(),
                    searchResult: interaction.search_result?.id?.toString(),
                    contact: interaction.contact?.id?.toString(),
                  },
                  data: [
                    createTableCell(
                      <InteractionPerson interaction={interaction} />,
                      handleOnClick,
                      "element",
                      TableBodyCell
                    ),
                    createTableCell(
                      <Chip
                        variant="outlined"
                        size="small"
                        {...statusChipData}
                      />,
                      handleOnClick,
                      "element",
                      TableBodyCell
                    ),
                    createTableCell(
                      <ConnectionChip
                        personId={interaction.person?.id}
                        accountId={accountId}
                        active_connection={
                          interaction.contact?.active_connection
                        }
                      />,
                      handleOnClick,
                      desktop ? "element" : "hidden",
                      TableBodyCell
                    ),
                    createTableCell(
                      <InteractionStatusActions
                        contactId={interaction.contact?.id}
                        person={interaction.person}
                        searchResult={interaction.search_result}
                        campaignId={campaignId}
                        campaignName={interaction.campaign?.name}
                        category={interaction.category}
                      />,
                      null,
                      "action",
                      TableBodyCell
                    ),
                  ],
                };
              })
            : []
        }
        count={data?.count || 0}
        // INITIAL_PAGE starts at 1, but Pagination starts at 0
        page={(data?.current || page) - 1}
        setPage={setPage}
        isFetching={isFetching}
        isLoading={isLoading}
        defaultOrderBy={ordering || "-platform_created_at"}
        rowsPerPage={rowsPerPage}
        selectedRowName={String(selectedInteractionId)}
        definedSplitTableHeight={
          theme.app.constants.campaign.interactions.tableHeight
        }
        variant={[TableVariant.split, TableVariant.checkable]}
      />
    </div>
  );

  const inbox = (
    <div className={classes.inbox}>
      {!!selectedPersonId && (
        <InteractionContainerList
          accountId={accountId}
          personId={selectedPersonId}
          variant={InteractionViewVariant.campaign}
          handleBacklink={handleBacklink}
        />
      )}
      {!!selectedInteraction && (
        <InteractionSearchResultView
          accountId={accountId}
          interaction={selectedInteraction}
          handleBacklink={handleBacklink}
        />
      )}
    </div>
  );

  return (
    <Box>
      <TableToolbar
        title="Inbox"
        numSelected={0}
        disableDivider
        filters={
          <>
            <ButtonGroupFilter
              id="status"
              options={[
                {
                  id: InteractionFilters.all,
                  name: "All",
                  query: {},
                },
                ...INTERACTION_STATUS_FILTER_OPTIONS,
              ]}
            />
            <KeywordFilter />
            <FilterButton label="Connection" id="connection">
              <AutocompleteMultipleFilter
                id="connection"
                options={INTERACTION_CONNECTION_FILTER_OPTIONS}
                label="Connection"
              />
            </FilterButton>
          </>
        }
      />
      <div className={classes.content}>
        {desktop ? (
          <>
            {table}
            {inbox}
          </>
        ) : interactionId ? (
          inbox
        ) : (
          table
        )}
      </div>
    </Box>
  );
};

export default CampaignInteractions;
