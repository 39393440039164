import SettingsItem from "core/components/SettingsItem";
import { createComponents } from "core/utils/componentsHandler";
import { formatTimeZoneName, setDefaultTimezone } from "core/utils/dateHandler";
import TimeZoneField from "modules/Account/components/TimeZoneField";
import { AccountContext } from "modules/Account/context";
import {
  AccountQuery,
  IAccount,
  IAccountCurrent,
  IAccountFormValues,
  IAccountDetailItemProps,
  IWorkTime,
  TimeZones,
} from "modules/Account/models";
import { AccountActionType } from "modules/Account/reducers";
import AccountService from "modules/Account/services";
import React from "react";
import { useQueryClient } from "react-query";

interface IFormInput {
  time_zone: IAccountFormValues["time_zone"];
  work_times: IWorkTime;
  work_times_general: {
    enabled: boolean;
    start_time: string;
    end_time: string;
  };
}

const TimeZoneSettings = (
  props: IAccountDetailItemProps
): React.ReactElement | null => {
  const { accountId } = props;
  const queryClient = useQueryClient();
  const [account, setAccount] = React.useState<IAccount | undefined>(undefined);
  const { account: currentAccount, dispatch } =
    React.useContext(AccountContext);

  const [formState, setFormState] = React.useState<IFormInput | null>(null);

  const data: IAccount | undefined = queryClient.getQueryData([
    AccountQuery.account,
    accountId,
  ]);
  React.useEffect(() => {
    if (data) {
      setAccount(data);
    }
  }, [data]);

  if (!account) return null;

  const defaultValues = {
    time_zone: {
      id: account.time_zone,
      name: formatTimeZoneName(account.time_zone as string),
    },
  };

  return (
    <>
      <SettingsItem
        FormProps={{
          id: accountId,
          name: [AccountQuery.account, accountId],
          func: AccountService.updateAccount,
          resetForm: true,
          resetFormData: formState,
          format: (d: IFormInput) => {
            setFormState(d);

            return {
              login: account.login,
              time_zone: d.time_zone?.id as TimeZones,
            };
          },
          onSuccess: (d: IAccountCurrent) => {
            const timezone = d.time_zone;
            setDefaultTimezone(timezone);
            dispatch({
              type: AccountActionType.UPDATE_ACCOUNT,
              payload: {
                ...currentAccount,
                time_zone: timezone,
              },
            });
            queryClient.invalidateQueries([AccountQuery.is_online, accountId]);
          },
          defaultValues,
        }}
        PaperHeaderProps={{
          title: "Timezone",
          body: "Your timezone was set automatically. If you want to change it please select one from the list below.",
        }}
        components={[
          createComponents(TimeZoneField, "time_zone", {
            disableFullWidth: true,
          }),
        ]}
      />
    </>
  );
};

export default TimeZoneSettings;
