import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

const useMenuStyles = makeStyles((theme: Theme) => ({
  item: {
    fontWeight: 500,
    color: theme.palette.primary.main,
  },
  paper: {
    overflowX: "unset",
    overflowY: "unset",
    "&::before": {
      content: '""',
      position: "absolute",
      top: -10,
      marginRight: 11,
      right: 0,
      width: 10,
      height: 10,
      backgroundColor: theme.palette.common.white,
      boxShadow: theme.shadows[4],
      transform: "translate(-50%, 50%) rotate(-45deg)",
      clipPath:
        "polygon(-5px -5px, calc(100% + 5px) -5px, calc(100% + 5px) calc(100% + 5px))",
    },
  },
  small: {
    "&::before": {
      marginRight: 4,
    },
  },
}));

// eslint-disable-next-line import/prefer-default-export
export { useMenuStyles };
