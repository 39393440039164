import axios, { AxiosResponse } from "axios";
import {
  IPlaceholder,
  IPlaceholderCreateValues,
  IPlaceholderCreateResponse,
  IPlaceholderUpdateValues,
  IPlaceholders,
  IPlaceholderPersons,
  IPlaceholderKeys,
} from "modules/Placeholder/models";

const createPlaceholder = (
  data: IPlaceholderCreateValues
): Promise<AxiosResponse<IPlaceholderCreateResponse>> =>
  axios.post("/inevitable/placeholders/", data);

const updatePlaceholder = (
  data: IPlaceholderUpdateValues
): Promise<AxiosResponse<IPlaceholder>> =>
  axios.patch(`/inevitable/placeholders/${data.id}/`, data);

const fetchPlaceholders = (
  accountId: number,
  currentAccountPersonId: number | undefined,
  page: number,
  pageSize: number,
  search = "",
  campaignQuery: string,
  searchQuery: string
): Promise<AxiosResponse<IPlaceholderPersons>> =>
  axios.get(
    `/inevitable/persons/get_placeholders/?account_id=${accountId}&page=${page}&page_size=${pageSize}&search=${search}&id__not=${currentAccountPersonId}${campaignQuery}${searchQuery}`
  );

const fetchPersonPlaceholders = (
  personId: number | undefined
): Promise<AxiosResponse<IPlaceholders>> =>
  axios.get(`/inevitable/placeholders/?person_id=${personId}`);

const fetchPlaceholderKeys = (
  accountId: number
): Promise<AxiosResponse<IPlaceholderKeys>> => {
  return axios.get(
    `/inevitable/placeholder_keys/?account_id=${accountId}&page_size=1000`
  );
};

const deletePlaceholder = (
  placeholderId: number
): Promise<AxiosResponse<IPlaceholder>> =>
  axios.delete(`/inevitable/placeholders/${placeholderId}/`);

const PlaceholderService = {
  createPlaceholder,
  updatePlaceholder,
  fetchPlaceholders,
  fetchPersonPlaceholders,
  fetchPlaceholderKeys,
  deletePlaceholder,
};

export default PlaceholderService;
