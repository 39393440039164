import React, { createContext, useReducer } from "react";
import { IGlobalContext } from "../models";
import { GlobalAction, globalReducer } from "../reducers";

interface IProps {
  children: React.ReactElement;
}

interface InterfaceGlobalContext {
  global: IGlobalContext;
  dispatch: React.Dispatch<GlobalAction>;
}

const initialState = {
  global: {
    selected: [],
    cancelDialog: false,
  },
  dispatch: () => null,
};

export const GlobalContext =
  createContext<InterfaceGlobalContext>(initialState);

const GlobalContextProvider = (props: IProps): React.ReactElement => {
  const [global, dispatch] = useReducer(globalReducer, initialState.global);

  const { children } = props;

  return (
    <GlobalContext.Provider value={{ global, dispatch }}>
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;
