import DeleteIcon from "@mui/icons-material/Delete";
import ElementField from "core/components/ElementField";
import LocaleAutocomplete from "core/components/LocaleAutocomplete";
import SettingsItem from "core/components/SettingsItem";
import RouterConstants from "core/routes/constants";
import { createComponents } from "core/utils/componentsHandler";
import { snackbarHandler } from "core/utils/snackbarHandler";
import get from "lodash/get";
import { AccountContext } from "modules/Account/context";
import { IAccountOnboardingIds } from "modules/Account/models";
import {
  CampaignLocale,
  CampaignLocaleNames,
  CampaignQuery,
  ExecutionLabels,
  ICampaignParams,
  ICreateCampaignFormInput,
  ICreateCampaignFormValues,
} from "modules/Campaign/models";
import CampaignService from "modules/Campaign/services";
import InteractionService from "modules/Interaction/services";
import React from "react";
import { useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import Loader from "ui-kit/components/Loader";
import CampaignStatusField from "./components/CampaignStatusField";
import StartDateField from "./components/StartDateField";

const InputProps = {
  sx: { width: 300 },
};

const CampaignSettings = ({
  campaignId,
}: ICampaignParams): React.ReactElement => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const {
    account: { id: accountId },
  } = React.useContext(AccountContext);

  const { data, isLoading: isLoadingCampaign } = useQuery(
    [CampaignQuery.campaign, campaignId],
    async () => {
      try {
        const response = await CampaignService.fetchCampaign(campaignId);
        return response.data;
      } catch (err) {
        throw new Error(String(err));
      }
    }
  );

  const fetchCampaignInteractions = async () => {
    try {
      const result = await InteractionService.fetchCampaignActivated(
        campaignId
      );
      return result.data;
    } catch (err) {
      throw new Error(String(err));
    }
  };
  const { data: dataActivated, isLoading: isLoadingActivated } = useQuery(
    ["campaign-activated", campaignId],
    () => fetchCampaignInteractions(),
    {
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const defaultValues = {
    execution: data?.execution,
    name: data?.name,
    start_date: data?.start_date,
    restrictions: {
      connection_requests: data?.restrictions.connection_requests,
      enabled: !!data?.restrictions.connection_requests,
    },
    locale: {
      id: data?.locale,
      name: CampaignLocaleNames[data?.locale as CampaignLocale],
    },
  };

  const activeCampaignDate = get(
    dataActivated,
    "results[0].platform_created_at"
  );

  const content = (
    <>
      <SettingsItem
        FormProps={{
          id: campaignId,
          name: [CampaignQuery.campaign, campaignId],
          func: CampaignService.updateCampaign,
          successMsg: (d: ICreateCampaignFormValues) =>
            d?.execution &&
            snackbarHandler.success(
              `Campaign status changed to ${ExecutionLabels[d.execution]}!`
            ),
          format: (d: ICreateCampaignFormValues) => d,
          defaultValues,
        }}
        PaperHeaderProps={{
          title: "Status",
          body: "Update campaign status. Run or Pause all the campaign's activity.",
        }}
        components={[
          createComponents(CampaignStatusField, "execution", {
            execution: data?.execution,
            campaignId,
            disableHeader: true,
          }),
        ]}
        SubmitButtonProps={{
          hidden: true,
        }}
      />
      <SettingsItem
        FormProps={{
          id: campaignId,
          name: [CampaignQuery.campaign, campaignId],
          func: CampaignService.updateCampaign,
          format: (d: ICreateCampaignFormInput) => {
            return { locale: d?.locale?.id };
          },
          defaultValues,
        }}
        PaperHeaderProps={{
          title: "Language",
          body: "Select language of your campaign for extra personalization in your messages.",
          helperProps: {
            title: `Example: You can utilize "Day of the week" custom placeholder, to write "Happy {weekday}" => "Happy Monday" in your message.`,
          },
        }}
        components={[createComponents(LocaleAutocomplete, "locale")]}
      />
      <SettingsItem
        FormProps={{
          id: campaignId,
          name: [CampaignQuery.campaign, campaignId],
          func: CampaignService.updateCampaign,
          format: (d: ICreateCampaignFormValues) => d,
          defaultValues,
        }}
        PaperHeaderProps={{
          title: "Schedule start",
          body: "Set the start date to launch the campaign at the different date in the future.",
          helperProps: {
            title: "When launched, campaign starts immediately by default.",
          },
        }}
        components={[
          createComponents(StartDateField, "start_date", {
            activeCampaignDate: activeCampaignDate,
            defaultStartDate: defaultValues.start_date,
          }),
        ]}
        SubmitButtonProps={{
          value: "Save changes",
          color: "primary",
          hidden: !!activeCampaignDate,
        }}
      />

      {/* <SettingsItem
        FormProps={{
          id: campaignId,
          name: [CampaignQuery.campaign, campaignId],
          func: CampaignService.updateCampaign,
          format: (d: ICreateCampaignFormValues) => d,
          defaultValues,
        }}
        components={[
          createComponents(CampaignLimits, "restrictions.enabled", {
            label: "Limits",
          }),
        ]}
      /> */}

      <SettingsItem
        FormProps={{
          id: campaignId,
          name: [CampaignQuery.campaign, campaignId],
          func: CampaignService.updateCampaign,
          format: (d: ICreateCampaignFormValues) => d,
          defaultValues,
        }}
        PaperHeaderProps={{
          title: "Name",
          body: "Edit campaign name.",
        }}
        components={[
          createComponents(ElementField, "name", {
            InputProps: {
              label: "Campaign name",
              InputProps,
            },
          }),
        ]}
      />
      <SettingsItem
        FormProps={{
          id: campaignId,
          name: [CampaignQuery.campaigns, null],
          func: () =>
            // eslint-disable-next-line no-alert
            window.confirm("Are you sure you want to delete this campaign?")
              ? CampaignService.deleteCampaign(campaignId)
              : null,
          onSuccess: () => {
            // Remove campaign could affect onboarding
            queryClient.invalidateQueries([
              IAccountOnboardingIds.campaign_created,
              accountId,
            ]);
            history.push(RouterConstants.CAMPAIGN.ROOT);
          },
          format: (d: ICreateCampaignFormValues) => d,
          defaultValues,
        }}
        PaperHeaderProps={{
          title: "Delete campaign",
          body: "Deleting a campaign stops all the campaign's activity. Warning: This is a destructive action that cannot be reverted.",
        }}
        SubmitButtonProps={{
          value: "Delete campaign",
          color: "error",
          endIcon: <DeleteIcon />,
        }}
        hiddenDivider
      />
    </>
  );

  const isLoading = isLoadingCampaign || isLoadingActivated;
  return isLoading ? <Loader /> : content;
};

export default CampaignSettings;
