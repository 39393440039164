import React from "react";
import { useQuery } from "react-query";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Slide from "@mui/material/Slide";
import { ActionContext } from "modules/Action/context";
import AccountService from "modules/Account/services";
import { AccountContext } from "modules/Account/context";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "absolute",
    overflow: "hidden",
    right: 1,
    top: 1,
    bottom: 13,
    zIndex: 10,
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "50%",
    },
  },
  paper: {
    height: "100%",
    width: "100%",
    zIndex: 10,
    borderRadius: theme.spacing(0, 1, 1, 0),
    borderLeft: "1px solid rgba(0, 0, 0, 0.23)",
  },
  content: {
    padding: theme.spacing(5.5, 2.5, 3, 3),
    whiteSpace: "pre-wrap",
    fontSize: "0.95rem",
    lineHeight: "1.4375em",
  },
  header: {
    padding: theme.spacing(2, 3),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  actions: {
    "& > *": {
      marginLeft: theme.spacing(1),
    },
  },
}));

interface PreviewViewProps {
  template: string;
}

const PreviewView = ({
  template,
}: PreviewViewProps): React.ReactElement | null => {
  const classes = useStyles();
  const containerRef = React.useRef<HTMLDivElement>(null);

  const {
    draftAction: { previewMode, personId, locale, placeholders },
  } = React.useContext(ActionContext);

  const {
    account: { id: accountId },
  } = React.useContext(AccountContext);

  const enabledPreview =
    !!template && previewMode && !!(personId || placeholders);

  const fetchPreviewTemplate = async () => {
    try {
      const { data } = await AccountService.fetchPreviewTemplate(accountId, {
        template,
        person: personId,
        locale,
        placeholders,
      });
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };

  const { data } = useQuery(
    ["preview", template, personId],
    () => fetchPreviewTemplate(),
    {
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: enabledPreview,
    }
  );

  const content = (
    <Paper elevation={10} className={classes.paper}>
      <div className={classes.content}>{data?.content}</div>
    </Paper>
  );

  return enabledPreview ? (
    <div className={classes.root} ref={containerRef}>
      <Slide in={previewMode} direction="left" container={containerRef.current}>
        {content}
      </Slide>
    </div>
  ) : null;
};

export default PreviewView;
