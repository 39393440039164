import React from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import RouterConstants from "core/routes/constants";
import AccountService from "modules/Account/services";
import ReportService from "modules/Report/services";
import { LIMITS_ARR } from "modules/Report/utils";
import { AccountQuery, AccountTabs } from "modules/Account/models";
import LimitReportItem from "modules/Report/components/LimitReportItem";
import { AccountContext } from "modules/Account/context";
import LimitPieChart from "../LimitPieChart";

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    padding: theme.spacing(0, 0, 4),
  },
  action: {
    marginTop: theme.spacing(-1.25),
    marginBottom: theme.spacing(-1.25),
  },
  actionBtn: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  list: {
    width: "100%",
  },
  content: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(6.25, 1),
  },
  chart: {
    "@media (max-width: 945px)": {
      display: "none",
    },
    [theme.breakpoints.only("md")]: {
      maxWidth: 120,
      maxHeight: 135,
      marginLeft: 30,
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: 240,
      maxHeight: 135,
      marginLeft: 20,
      marginRight: -30,
    },
  },
}));

const LimitReportList = (): React.ReactElement | null => {
  const classes = useStyles();

  const { account } = React.useContext(AccountContext);
  const { id: accountId } = account;

  const { data: restrictions } = useQuery(
    ["restrictions", accountId],
    async () => {
      try {
        const response = await ReportService.fetchRestrictions(accountId);
        return response.data;
      } catch (err) {
        throw new Error(String(err));
      }
    },
    {
      enabled: !!accountId,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  const { data: accountData } = useQuery(
    [AccountQuery.account, accountId],
    async () => {
      try {
        const response = await AccountService.fetchAccount(accountId);
        return response.data;
      } catch (err) {
        throw new Error(String(err));
      }
    },
    {
      enabled: !!accountId,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  if (!account?.time_zone) {
    return null;
  }

  return (
    <div>
      <CardHeader
        className={classes.header}
        classes={{
          action: classes.action,
        }}
        title="Today"
        titleTypographyProps={{
          variant: "h4",
        }}
        action={
          <Button
            className={classes.actionBtn}
            size="small"
            component={Link}
            to={RouterConstants.ACCOUNT.detail(accountId, AccountTabs.limits)}
            color="primary"
            variant="text"
            endIcon={<ArrowForwardIcon />}
          >
            Manage limits
          </Button>
        }
      />
      <Divider />
      <CardContent className={classes.content}>
        <List className={classes.list}>
          {LIMITS_ARR.map((limit) => (
            <LimitReportItem
              key={limit.name}
              {...limit}
              restrictions={accountData?.restrictions}
              restriction={restrictions?.results[0]}
            />
          ))}
        </List>
        <div className={classes.chart}>
          <LimitPieChart
            restrictions={accountData?.restrictions}
            restriction={restrictions?.results[0]}
          />
        </div>
      </CardContent>
      <Divider light />
    </div>
  );
};

export default LimitReportList;
