import Autocomplete, {
  AutocompleteRenderInputParams,
} from "@mui/material/Autocomplete";
import { useTheme } from "@mui/styles";
import { ITimeZoneFormInput } from "modules/Account/models";
import { TIME_ZONES } from "modules/Account/utils";
import React from "react";
import { Control, Controller, FieldError } from "react-hook-form";
import TextField from "ui-kit/atoms/TextField";

interface IControllerProps {
  control: Control;
  name: string;
  error: FieldError | undefined;
  disableFullWidth?: boolean;
}

const TimeZoneField = ({
  control,
  error,
  name,
  disableFullWidth = false,
}: IControllerProps): React.ReactElement => {
  const theme = useTheme();
  return (
    <Controller
      render={(props: {
        value: { id: string; name: string };
        onChange: (newData: ITimeZoneFormInput) => void;
      }) => (
        <Autocomplete
          {...props}
          options={TIME_ZONES}
          autoHighlight
          disableClearable={!props.value?.id}
          onChange={(_, newData) =>
            props.onChange(newData as ITimeZoneFormInput)
          }
          getOptionLabel={(option: ITimeZoneFormInput) => option.id}
          isOptionEqualToValue={(option, value) => {
            return option.id === value.id;
          }}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <TextField
              {...params}
              fullWidth
              label="Timezone"
              variant="outlined"
              error={!!error}
              sx={{
                [theme.breakpoints.up("md")]: {
                  maxWidth: disableFullWidth
                    ? theme.app.constants.inputMaxWidth
                    : "none",
                },
              }}
              helperText={error?.message}
            />
          )}
        />
      )}
      rules={{ required: true }}
      name={name}
      control={control}
    />
  );
};

export default TimeZoneField;
