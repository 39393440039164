import ClearIcon from "@mui/icons-material/Close";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import IconButton from "@mui/material/IconButton";
import { alpha, Theme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from "@mui/styles";
import { errorHandler, IErrorResponse } from "core/utils/errorHandler";
import { snackbarHandler } from "core/utils/snackbarHandler";
import { CampaignQuery } from "modules/Campaign/models";
import CampaignService from "modules/Campaign/services";
import {
  IInteraction,
  InteractionCategories,
  InteractionsQuery,
} from "modules/Interaction/models";
import InteractionService from "modules/Interaction/services";
import { IPerson } from "modules/Person/models";
import { ISearchResult } from "modules/Search/models";
import React from "react";
import { useMutation, useQueryClient } from "react-query";

const useStyles = makeStyles((theme: Theme) => ({
  paused: {
    backgroundColor: alpha(theme.palette.primary.main, 0.075),
    color: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: alpha(theme.palette.primary.main, 0.15),
    },
  },
  running: {
    backgroundColor: alpha(theme.palette.warning.main, 0.075),
    color: theme.palette.warning.main,
    "&:hover": {
      backgroundColor: alpha(theme.palette.warning.main, 0.15),
    },
  },
  queued: {
    backgroundColor: alpha(theme.palette.info.main, 0.075),
    color: theme.palette.info.main,
    "&:hover": {
      backgroundColor: alpha(theme.palette.info.main, 0.15),
    },
  },
  stopAction: {
    marginLeft: theme.spacing(1),
  },
  item: {
    "&:hover": {
      backgroundColor: `${theme.palette.action.hover} !important`,
    },
  },
}));

enum VariantTypes {
  "paused" = "paused",
  "running" = "running",
  "queued" = "queued",
}

const variants = {
  paused: {
    icon: PlayArrowIcon,
    tooltip: "Resume campaign",
    newCategory: InteractionCategories.sequence_resumed,
    label: "resumed",
    type: VariantTypes.paused,
  },
  running: {
    icon: PauseIcon,
    tooltip: "Pause campaign",
    newCategory: InteractionCategories.sequence_paused,
    label: "paused",
    type: VariantTypes.running,
  },
  queued: {
    icon: ClearIcon,
    tooltip: "Remove from campaign",
    newCategory: InteractionCategories.person_in_campaign_queue,
    label: "removed",
    type: VariantTypes.queued,
  },
};

const getVariant = (category: IInteraction["category"]) => {
  if (category === InteractionCategories.sequence_paused) {
    return variants.paused;
  }
  if (category === InteractionCategories.person_in_campaign_queue) {
    return variants.queued;
  }
  return variants.running;
};

interface InteractionStatusActionsProps {
  category: IInteraction["category"];
  contactId: number | undefined;
  person: IPerson | undefined;
  searchResult: ISearchResult | undefined;
  campaignId: number;
  campaignName: string | undefined;
}

const InteractionStatusActions = ({
  category,
  contactId,
  person,
  searchResult,
  campaignId,
  campaignName,
}: InteractionStatusActionsProps): React.ReactElement | null => {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const {
    tooltip,
    icon: Icon,
    newCategory,
    type,
    label,
  } = getVariant(category);
  const stoppedInteraction =
    category === InteractionCategories.sequence_stopped;

  const invalidateQuery = () => {
    queryClient.invalidateQueries([CampaignQuery.interactions, campaignId]);
    queryClient.invalidateQueries([
      InteractionsQuery.campaign_person,
      campaignId,
      person?.id,
    ]);
    queryClient.invalidateQueries([
      CampaignQuery.stopped_person,
      campaignId,
      person?.id,
    ]);
    queryClient.invalidateQueries([InteractionsQuery.interactions_person]);

    // Profile campaigns
    queryClient.invalidateQueries([CampaignQuery.campaigns_profile]);
  };

  const mutateOnStatusChange = useMutation(
    () =>
      InteractionService.createInteraction({
        category: newCategory,
        contact: contactId,
      }),
    {
      onSuccess: () => {
        invalidateQuery();
        snackbarHandler.success(`Successfully ${label}!`);
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);
      },
    }
  );

  const mutateRemovePersonFromCampaign = useMutation(
    (persons: number[]) =>
      CampaignService.deleteCampaignPersons({
        persons,
        campaign: campaignId,
      }),
    {
      onSuccess: () => {
        invalidateQuery();
        snackbarHandler.success("Successfully removed!");
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);
      },
    }
  );

  const mutateRemoveSearchResultFromCampaign = useMutation(
    (search_results: number[]) =>
      CampaignService.deleteCampaignSearchResults({
        search_results,
        campaign: campaignId,
      }),
    {
      onSuccess: () => {
        invalidateQuery();
        snackbarHandler.success("Successfully removed!");
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);
      },
    }
  );

  return stoppedInteraction ? null : (
    <Tooltip placement="top" title={tooltip}>
      <IconButton
        size="small"
        className={classes[type]}
        onClick={() => {
          if (type === VariantTypes.queued) {
            if (person?.id) {
              return mutateRemovePersonFromCampaign.mutate([person.id]);
            }
            if (searchResult?.id) {
              return mutateRemoveSearchResultFromCampaign.mutate([
                searchResult.id,
              ]);
            }
          }
          return mutateOnStatusChange.mutate();
        }}
      >
        <Icon style={{ fontSize: 20 }} />
      </IconButton>
    </Tooltip>
  );
};

export default InteractionStatusActions;
