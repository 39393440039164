import React, { ReactElement } from "react";
import { Controller, Control, FieldError } from "react-hook-form";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import { SelectProps } from "@mui/material/Select";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import TextField from "../atoms/TextField";
import { IOptionProps } from "core/models";

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    width: 30,
    height: 30,
  },
  divider: {
    borderBottom: `1px solid ${theme.app.palette.shadow.primary}`,
  },
}));

interface ISelectProps {
  disabled?: boolean;
  control: Control;
  error?: FieldError;
  name: string;
  label?: string;
  required?: boolean;
  options: IOptionProps[];
  disableMargin?: boolean;
  variant?: SelectProps["variant"];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formatValue?: (option: SelectProps["value"]) => string;
}

const Select = ({
  control,
  error,
  name,
  label,
  options,
  formatValue,
  variant = "outlined",
  disableMargin = false,
  ...rest
}: ISelectProps): ReactElement => {
  const classes = useStyles();
  return (
    <Controller
      render={(props) => {
        return (
          <TextField
            {...rest}
            {...props}
            select
            fullWidth
            name={name}
            label={label}
            variant={variant}
            error={!!error}
            helperText={error?.message}
            {...(disableMargin && { sx: { mb: 0 } })}
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  elevation: 24,
                },
                MenuListProps: {
                  sx: {
                    paddingTop: 1,
                    paddingBottom: 1,
                  },
                },
              },
              ...(formatValue && {
                renderValue: (option: SelectProps["value"]) =>
                  formatValue(option),
              }),
            }}
          >
            {options.map(
              ({ id, label: itemLabel, icon: Icon, color }, index) => {
                const divider = index < options.length - 1;
                return (
                  <MenuItem
                    key={id}
                    value={id}
                    className={clsx({
                      [classes.divider]: divider,
                    })}
                  >
                    {Icon && (
                      <ListItemAvatar>
                        <Avatar
                          className={classes.avatar}
                          sx={{ backgroundColor: color }}
                          aria-label="strategy-icon"
                        >
                          <Icon fontSize="small" />
                        </Avatar>
                      </ListItemAvatar>
                    )}
                    <ListItemText
                      primary={itemLabel}
                      // primaryTypographyProps={{ variant: "body2" }}
                    />
                  </MenuItem>
                );
              }
            )}
          </TextField>
        );
      }}
      name={name}
      control={control}
    />
  );
};

Select.defaultProps = {
  disabled: false,
  label: "",
  disableMargin: false,
  required: false,
  error: undefined,
  formatValue: undefined,
};

export default Select;
