import ErrorIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { Alert, AlertTitle, Typography } from "@mui/material";
import ConfigConstant from "core/constants/ConfigConstant";
import { GlobalContext } from "core/context";
import { IProfileClicked } from "core/models";
import { GlobalActionType } from "core/reducers";
import RouterUtils from "core/routes/utils";
import { AccountContext } from "modules/Account/context";
import { AccountQuery, IAccount } from "modules/Account/models";
import AccountService from "modules/Account/services";
import { getAccountName } from "modules/Account/utils";
import SearchInfo from "modules/Search/components/SearchInfo";
import {
  SEARCH_RESULT_STATUS_FILTER_OPTIONS,
  SEARCH_STATUS_FILTER_OPTIONS,
} from "modules/Search/constants";
import {
  ISearchParams,
  SearchLicense,
  SearchLicenseLabel,
  SearchMatchVariantStrategy,
  SearchStatusMatchToVariant,
  SearchStrategy,
} from "modules/Search/models";
import SearchService from "modules/Search/services";
import SearchUtils from "modules/Search/utils";
import React, { useState } from "react";
import { useCustomerly } from "react-live-chat-customerly";
import { useQuery, useQueryClient } from "react-query";
import { Link, useLocation } from "react-router-dom";
import Loader from "ui-kit/components/Loader";
import GoogleSheetList from "../GoogleSheetList";
import LinkedInSearchList from "../LinkedInSearchList";

const rowsPerPage = ConfigConstant.PAGE_SIZE.MEDIUM;

interface LicenseErrorInfoProps {
  variant: SearchLicense;
  account: IAccount | undefined;
  license: boolean | undefined;
  triggerChat: () => void;
}

const LicenseErrorInfo = ({
  variant,
  account,
  license,
  triggerChat,
}: LicenseErrorInfoProps) => {
  return (
    <Typography variant="body2" color="textSecondary">
      {!license ? (
        <>
          We didn't detect an active {SearchLicenseLabel[variant]} plan on{" "}
          <b>{getAccountName(account)}</b> account. Please check if you have an
          active plan or{" "}
          <Link to="#" onClick={triggerChat}>
            chat with us
          </Link>
          .
        </>
      ) : (
        <>
          There was an issue collecting data from your search. Our team got
          notified and is looking into your issue. You can reach out to us{" "}
          <Link to="#" onClick={triggerChat}>
            here
          </Link>
          .
        </>
      )}
    </Typography>
  );
};

const SearchResultList = ({
  search: searchData,
}: ISearchParams): React.ReactElement | null => {
  const queryClient = useQueryClient();
  const { open } = useCustomerly();
  const { id: searchId, status: searchStatus } = searchData;
  const [page, setPage] = useState<number>(ConfigConstant.INITIAL_PAGE);
  const [dataClicked, setDataClicked] = React.useState<
    IProfileClicked | undefined
  >(undefined);

  const { dispatch } = React.useContext(GlobalContext);

  const {
    account: { id: accountId },
  } = React.useContext(AccountContext);

  const handleClicked = (newDataClicked: IProfileClicked) => {
    setDataClicked(newDataClicked);
  };

  const triggerChat = () => {
    open();
  };

  const location = useLocation();
  const { search, status } = RouterUtils.getQueryParams(location);
  const { campaignId } = RouterUtils.parseQueryParams(location);
  const activeFilter = !!search || !!status || !!campaignId;

  const [statusQuery, setStatusQuery] = React.useState(
    RouterUtils.generateFilterOptionQuery(status, SEARCH_STATUS_FILTER_OPTIONS)
  );

  const accountData: IAccount | undefined = queryClient.getQueryData([
    AccountQuery.account,
    accountId,
  ]);

  const { data: accountSearchAuthor } = useQuery(
    [AccountQuery.account, accountId],
    async () => {
      try {
        const response = await AccountService.fetchAccount(accountId);
        return response.data;
      } catch (err) {
        throw new Error(String(err));
      }
    },
    {
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: searchData.account !== accountId,
    }
  );

  React.useEffect(() => {
    const newStatusQuery = RouterUtils.generateFilterOptionQuery(
      status,
      SEARCH_RESULT_STATUS_FILTER_OPTIONS
    );
    setStatusQuery(newStatusQuery);
  }, [status]);

  const resetSelected = () => {
    dispatch({
      type: GlobalActionType.SET_GLOBAL,
      payload: { selected: [] },
    });
  };

  const handleClose = () => {
    resetSelected();
    setDataClicked(undefined);
  };

  const fetchSearchResults = async (p: number) => {
    try {
      const { data } = await SearchService.fetchSearchResults(
        searchId,
        p,
        rowsPerPage,
        search,
        RouterUtils.generateFilterNameQuery(
          campaignId,
          "interaction__campaign_id"
        ),
        statusQuery
      );
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };
  const { data, isLoading, isFetching } = useQuery(
    [
      "search-results",
      searchId,
      page,
      rowsPerPage,
      search,
      campaignId,
      statusQuery,
    ],
    () => fetchSearchResults(page),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
      refetchInterval: [
        SearchStatusMatchToVariant.queue,
        SearchStatusMatchToVariant.processing,
      ].includes(searchData.status)
        ? 60000 // Refetch every 1 minute
        : 0,
    }
  );

  if (isLoading) {
    return <Loader />;
  }

  const ifDisplayContent = data && (data.results?.length || activeFilter);

  if (
    searchStatus === SearchStatusMatchToVariant.processing &&
    !ifDisplayContent
  ) {
    return (
      <SearchInfo
        title="We're processing your target audience data..."
        body="This can take a while. You can leave this page and we will send you a notification when the search is processed."
        loader
      />
    );
  }

  const accountSearchData = !!accountSearchAuthor
    ? accountSearchAuthor
    : accountData;

  if (searchStatus === SearchStatusMatchToVariant.failed && !ifDisplayContent) {
    return (
      <Alert color="error" icon={<ErrorIcon></ErrorIcon>}>
        <AlertTitle sx={{ mb: 2 }} color="textPrimary">
          Your {SearchMatchVariantStrategy[searchData.strategy]} search failed
        </AlertTitle>
        {searchData.strategy ===
          SearchStrategy["inevitable.strategy.search.SalesNavStrategy"] && (
          <LicenseErrorInfo
            variant={SearchLicense.sales_nav}
            account={accountSearchData}
            license={accountData?.system_meta_data.sales_nav_active}
            triggerChat={triggerChat}
          />
        )}
        {searchData.strategy ===
          SearchStrategy["inevitable.strategy.search.RecruiterStrategy"] && (
          <LicenseErrorInfo
            variant={SearchLicense.recruiter}
            account={accountSearchData}
            license={accountData?.system_meta_data.recruiter_active}
            triggerChat={triggerChat}
          />
        )}
        {![
          SearchStrategy["inevitable.strategy.search.RecruiterStrategy"],
          SearchStrategy["inevitable.strategy.search.SalesNavStrategy"],
        ].includes(searchData.strategy) && (
          <>
            There was an issue collecting data from your search. Our team got
            notified and is looking into your issue. You can reach out to us{" "}
            <Link to="#" onClick={triggerChat}>
              here
            </Link>
            .
          </>
        )}
      </Alert>
    );
  }

  const resultProps = {
    data,
    isLoading,
    isFetching,
    page,
    setPage,
    dataClicked,
    handleClose,
    handleClicked,
    searchId,
  };

  // Specific result only for google sheet
  if (SearchUtils.isGoogleSheet(searchData.strategy)) {
    return <GoogleSheetList {...resultProps} searchData={searchData} />;
  }

  // Return values with LinkedIn result view
  return <LinkedInSearchList {...resultProps} />;
};

export default SearchResultList;
