import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Warning from "@mui/icons-material/WarningAmberSharp";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import Button from "ui-kit/atoms/Button";
import DialogHeader from "ui-kit/components/DialogHeader";

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  warning: {
    padding: theme.spacing(4, 4, 5),
  },
  icon: {
    color: theme.palette.error.light,
    fontSize: 50,
  },
}));

interface RouterPromptProps {
  when: boolean;
}

function RouterPrompt({ when }: RouterPromptProps): React.ReactElement {
  const onConfirm = () => true;
  const onCancel = () => false;

  const classes = useStyles();
  const history = useHistory();

  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState("");

  useEffect(() => {
    if (when) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      history.block((prompt: any) => {
        setCurrentPath(prompt.pathname);
        setShowPrompt(true);
        return "true";
      });
    } else {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      history.block(() => {});
    }

    return () => {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      history.block(() => {});
    };
  }, [history, when]);

  const handleConfirm = useCallback(async () => {
    if (onConfirm) {
      const canRoute = await Promise.resolve(onConfirm());
      if (canRoute) {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        history.block(() => {});
        history.push(currentPath);
      }
    }
  }, [currentPath, history]);

  const handleCancel = useCallback(async () => {
    if (onCancel) {
      const canRoute = await Promise.resolve(onCancel());
      if (canRoute) {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        history.block(() => {});
        history.push(currentPath);
      }
    }
    setShowPrompt(false);
  }, [currentPath, history]);

  return (
    <Dialog
      open={showPrompt}
      keepMounted
      onClose={handleCancel}
      PaperProps={{ sx: { maxWidth: 580, width: "100%" } }}
    >
      <DialogHeader title="Unsaved changes" onHandleClose={handleCancel} />
      <DialogContent className={classes.content} dividers>
        <div className={classes.warning}>
          <Warning className={classes.icon} />
        </div>
        <DialogContentText>
          This page contains <b>unsaved changes</b>. Do you still wish to leave
          the page?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          type="button"
          variant="outlined"
          color="inherit"
          onClick={handleCancel}
        >
          Keep editing
        </Button>
        <Button
          type="button"
          variant="contained"
          color="error"
          onClick={handleConfirm}
        >
          Leave the page
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default RouterPrompt;
