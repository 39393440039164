/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-underscore-dangle */
import Chartjs, { ChartConfiguration } from "chart.js";
import { ChartType } from "modules/Report/models";
import { formatPercentage, getNthWord } from "modules/Report/utils";

const paddingSide = 8;
Chartjs.defaults.LineWithLine = Chartjs.defaults.line;
Chartjs.controllers.LineWithLine = Chartjs.controllers.line.extend({
  draw(ease: string) {
    Chartjs.controllers.line.prototype.draw.call(this, ease);

    if (this.chart.tooltip._active && this.chart.tooltip._active.length) {
      const activePoint = this.chart.tooltip._active[0];
      const { ctx } = this.chart;
      const { x } = activePoint.tooltipPosition();
      const topY = this.chart.scales["y-axis-0"].top;
      const bottomY = this.chart.scales["y-axis-0"].bottom;

      // draw line
      ctx.save();
      ctx.beginPath();
      ctx.moveTo(x, topY);
      ctx.lineTo(x, bottomY);
      ctx.lineWidth = 0.75;
      ctx.strokeStyle = "rgba(0, 0, 0, 0.15)";
      ctx.stroke();
      ctx.restore();
    }
  },
});

export const options: ChartConfiguration = {
  options: {
    animation: {
      duration: 1,
      // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
      onProgress(context: any): void {
        const { controller, ctx } = context.chart;
        const { chart: controllerChart } = controller;
        const xAxis = controller.scales["x-axis-0"];

        ctx.font = "normal 11px 'Helvetica Neue', Helvetica, Arial, sans-serif";
        ctx.fillStyle = "#666";

        xAxis.ticks.forEach((value: string, index: number) => {
          if (value) {
            let xOffset = 0;
            if (index === 0) {
              xOffset = index + paddingSide;
            } else {
              const valueSize = ctx.measureText(value);
              xOffset = controllerChart.width - valueSize.width - paddingSide;
            }
            const yOffset = controllerChart.height - 5;
            ctx.fillText(value, xOffset, yOffset);
          }
        });
      },
    },
    layout: {
      padding: {
        top: 1,
        left: paddingSide,
        right: paddingSide,
        bottom: 10,
      },
    },
    legend: {
      labels: {
        boxWidth: 0,
      },
      display: false,
    },
    elements: {
      line: {
        borderWidth: 2.5,
        tension: 0.2,
      },
      point: {
        radius: 0,
        hoverRadius: 10,
      },
    },
    tooltips: {
      callbacks: {
        label(item: Chartjs.ChartTooltipItem, data: Chartjs.ChartData): string {
          const { datasets } = data;
          if (!datasets) {
            return "";
          }
          const [dataset] = datasets;
          if (item.index === undefined) {
            return "";
          }

          const { barPercentage: chartType } = dataset;

          const label = dataset?.label || "";
          const count = Number(dataset?.data?.[item.index]);
          // If a number
          if (chartType === ChartType.integer) {
            return `${String(count)} ${getNthWord(label, 1)}`;
          }

          // If a percentage
          let value = Math.round(count * 100);
          // Don't go over 100% for rates
          if (value > 100) {
            value = 100;
          }
          const percentage = formatPercentage(value);
          return `${percentage} ${getNthWord(label, 2)}`;
        },
      },
      displayColors: false,
      intersect: false,
      axis: "x",
      backgroundColor: "white",
      titleFontColor: "#434B52",
      titleFontStyle: "normal",
      titleFontSize: 11,
      bodyFontColor: "#1A1F36",
      bodyFontSize: 13,
      bodyFontStyle: "bold",
      borderColor: "rgba(0, 0, 0, 0.2)",
      borderWidth: 1,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            display: false,
            min: 0,
          },
          gridLines: {
            lineWidth: 0,
            zeroLineWidth: 1.5,
            drawTicks: false,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            display: false,
            fontSize: 11,
            // Show only first and last tick
            callback(
              value: string,
              index: number,
              values: string[]
            ): string | null {
              if (index === 0 || index === values.length - 1) {
                return value;
              }
              return null;
            },
            autoSkip: false,
            maxRotation: 0,
            minRotation: 0,
          },
          gridLines: {
            display: false,
          },
        },
      ],
    },
  },
};

export default Chartjs;
