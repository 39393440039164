import React from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import IconButton from "@mui/material/IconButton";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import MailIcon from "@mui/icons-material/Mail";
import ChatOutlined from "@mui/icons-material/ChatOutlined";
import Slide from "@mui/material/Slide";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { generateProfileLink } from "core/utils/profileHandler";
import RouterConstants from "core/routes/constants";
import { IProfileClicked } from "core/models";
import PersonService from "modules/Person/services";
import PersonDetail from "modules/Person/components/PersonDetail";
import SearchResultDetail from "modules/Search/components/SearchResultDetail";
import SearchService from "modules/Search/services";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "fixed",
    overflow: "hidden",
    right: 0,
    bottom: 0,
    top: theme.app.constants.appbarHeight + theme.app.constants.menuBarHeight,
    zIndex: 10,
    width: "100%",
    boxShadow: "0 2px 5px 0 rgba(0,0,0,0.16),0 1px 6px rgba(0,0,0,0.12)",
    [theme.breakpoints.up("md")]: {
      width: theme.app.constants.profileWidth,
    },
    backgroundColor: theme.app.palette.background.default,
  },
  content: {
    height: "100%",
    width: "100%",
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2, 0),
    zIndex: 10,
  },
  header: {
    padding: theme.spacing(2, 3),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  actions: {
    "& > *": {
      marginLeft: theme.spacing(1),
    },
  },
  inIcon: { color: theme.app.palette.link },
}));

interface ProfileDetailProps extends IProfileClicked {
  onHandleClose: () => void;
}

const ProfileDetail = ({
  searchResultId,
  personId,
  contactId,
  connection,
  onHandleClose,
}: ProfileDetailProps): React.ReactElement | null => {
  const classes = useStyles();
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [open, setOpen] = React.useState(false);
  const [currentPersonId, setCurrentPersonId] = React.useState<
    number | undefined
  >(undefined);
  const [currentSearchResultId, setCurrentSearchResultId] = React.useState<
    number | undefined
  >(undefined);

  React.useEffect(() => {
    setOpen(!!(searchResultId || personId));
  }, [searchResultId, personId]);

  const handleClickAway = () => {
    // Disable click away if different person is selected
    if (personId && personId !== currentPersonId) {
      return;
    }
    // Disable click away if different person is selected
    if (searchResultId && searchResultId !== currentSearchResultId) {
      return;
    }

    setOpen(false);
    onHandleClose();
  };

  const fetchPerson = async () => {
    try {
      const { data } = await PersonService.fetchPerson(Number(personId));
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };

  const { data: dataPerson } = useQuery(
    ["person", personId],
    () => fetchPerson(),
    {
      keepPreviousData: true,
      enabled: !!personId,
    }
  );

  // Update current open modal view
  React.useEffect(() => {
    setTimeout(() => {
      setCurrentPersonId(dataPerson?.id);
    }, 0);
  }, [dataPerson?.id]);

  const fetchSearchResult = async () => {
    try {
      const { data } = await SearchService.fetchSearchResult(
        Number(searchResultId)
      );
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };

  const { data: dataSearchResult } = useQuery(
    ["search-result", searchResultId],
    () => fetchSearchResult(),
    {
      keepPreviousData: true,
      enabled: !!searchResultId && !personId,
    }
  );

  // Update current open modal view
  React.useEffect(() => {
    setTimeout(() => {
      setCurrentSearchResultId(dataSearchResult?.id);
    }, 0);
  }, [dataSearchResult?.id]);

  const content = (
    <div className={classes.content}>
      <div className={classes.header}>
        <div>
          <Tooltip arrow title="Close">
            <IconButton onClick={onHandleClose} size="medium">
              <ArrowForwardIcon />
            </IconButton>
          </Tooltip>
        </div>
        <div className={classes.actions}>
          {!!personId && !!dataPerson?.email && (
            <Tooltip arrow title="Send email">
              <IconButton
                component="a"
                href={`mailto:${dataPerson.email}`}
                size="medium"
              >
                <MailIcon color="success" />
              </IconButton>
            </Tooltip>
          )}
          {!!personId && !!dataPerson && (
            <Tooltip arrow title="Open LinkedIn">
              <IconButton
                component="a"
                href={generateProfileLink(dataPerson)}
                target="_blank"
                rel="noopener noreferrer"
                size="medium"
              >
                <LinkedInIcon className={classes.inIcon} />
              </IconButton>
            </Tooltip>
          )}
          {!!dataSearchResult && (
            <Tooltip arrow title="Open LinkedIn">
              <IconButton
                component="a"
                href={dataSearchResult?.profile_url}
                target="_blank"
                rel="noopener noreferrer"
                size="medium"
              >
                <LinkedInIcon color="primary" />
              </IconButton>
            </Tooltip>
          )}
          {!!personId && (
            <Tooltip arrow title="Inbox">
              <IconButton
                component={Link}
                to={RouterConstants.INBOX.detail(personId)}
                size="medium"
              >
                <ChatOutlined />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
      {!!personId && (
        <PersonDetail
          data={dataPerson}
          connection={connection}
          contactId={contactId}
          personId={personId}
        />
      )}
      {!!searchResultId && (
        <SearchResultDetail
          data={dataSearchResult}
          searchResultId={searchResultId}
        />
      )}
    </div>
  );

  return open ? (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={classes.root} ref={containerRef}>
        <Slide in={open} direction="left" container={containerRef.current}>
          {content}
        </Slide>
      </div>
    </ClickAwayListener>
  ) : null;
};

export default ProfileDetail;
