import React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import UserService from "modules/User/services";
import CardContent from "@mui/material/CardContent";
import RouterUtils from "core/routes/utils";
import RouterConstants from "core/routes/constants";
import AuthForm from "modules/User/components/AuthForm";
import { useMutation } from "react-query";
import { errorHandler, IErrorResponse } from "core/utils/errorHandler";
import { snackbarHandler } from "core/utils/snackbarHandler";

const VerifyEmail = (): React.ReactElement => {
  const location = useLocation();
  const history = useHistory();
  const { email } = RouterUtils.getQueryParams(location);

  const mutateResendVerification = useMutation(
    () => UserService.resendVerification({ email }),
    {
      onSuccess: (response) => {
        history.push(`${RouterConstants.USER.REGISTER_EMAIL}?email=${email}`);
        snackbarHandler.success("Verification email successfully resent");
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);
      },
    }
  );

  const handleResendVerification = () => {
    mutateResendVerification.mutate();
  };

  return (
    <AuthForm disableForm title="">
      <Card sx={{ my: -8 }}>
        <CardContent>
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="body1" paragraph>
              We&apos;ve sent a verification email
              {email ? (
                <>
                  {" "}
                  to{" "}
                  <Typography
                    variant="body1"
                    style={{ fontWeight: 600 }}
                    component="span"
                  >
                    {email}
                  </Typography>
                </>
              ) : (
                ""
              )}
            </Typography>
            <Typography variant="body1" component="p" mb={5}>
              Please click the link to confirm your email address.
            </Typography>
          </Box>
          <Box sx={{ mt: 8, mb: 5, textAlign: "center" }}>
            <Typography variant="body2" component="p" paragraph>
              Can&apos;t see the email? Please check the spam folder.
            </Typography>
            <Typography variant="body2" component="p" paragraph>
              Didn't receive the email?{" "}
              <Typography
                variant="body2"
                component="span"
                color="link"
                onClick={handleResendVerification}
                sx={{ cursor: "pointer" }}
              >
                Resend verification email.
              </Typography>
            </Typography>
            <Typography variant="body2" component="p" paragraph>
              Wrong email?{" "}
              <Link to={RouterConstants.USER.SIGN_UP}>
                Please re-enter your email.
              </Link>
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </AuthForm>
  );
};

export default VerifyEmail;
