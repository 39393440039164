import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import ElementField from "core/components/ElementField";
import SettingsItem from "core/components/SettingsItem";
import SwitchField from "core/components/SwitchField";
import ConfigConstant from "core/constants/ConfigConstant";
import RouterConstants from "core/routes/constants";
import { createComponents } from "core/utils/componentsHandler";
import { snackbarHandler } from "core/utils/snackbarHandler";
import TestResponseDialog from "modules/Webhook/components/TestResponseDialog";
import WebhookSelectField from "modules/Webhook/components/WebhookSelectField";
import {
  IWebhook,
  IWebhookFormInput,
  IWebhookResponse,
  WebhookQuery,
} from "modules/Webhook/models";
import WebhookService from "modules/Webhook/services";
import WebhookUtils from "modules/Webhook/utils";
import React from "react";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { Chip } from "ui-kit/atoms/Chip";
import Loader from "ui-kit/components/Loader";
import PageHeader, { PageHeaderTitle } from "ui-kit/components/PageHeader";

type ParamTypes = {
  webhookId: string;
};

const getChipContent = (active: IWebhook["active"]) => {
  const {
    label,
    icon: Icon,
    color,
  } = WebhookUtils.getWebhookActiveChipData(active);
  return (
    <Chip
      variant="outlined"
      size="small"
      label={label}
      color={color}
      icon={<Icon />}
    />
  );
};

const WebhookDetail = (): React.ReactElement => {
  const history = useHistory();
  const params = useParams<ParamTypes>();
  const webhookId: number = +params.webhookId;
  const [testResponse, setTestResponse] =
    React.useState<IWebhookResponse | null>(null);

  const { data, isLoading } = useQuery(
    [WebhookQuery.webhook, webhookId],
    async () => {
      try {
        const response = await WebhookService.fetchWebhook(webhookId);
        return response.data;
      } catch (err) {
        throw new Error(String(err));
      }
    },
    { enabled: !!webhookId, refetchOnMount: false, refetchOnWindowFocus: false }
  );

  return (
    <>
      <PageHeader
        backlinkProps={{
          text: "Webhooks",
          link: RouterConstants.INTEGRATION.WEBHOOK.ALL,
        }}
        title={
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <PageHeaderTitle
              title={data ? `Webhook: ${data.name}` : "Webhook"}
            />
            <Box ml={1.5}>
              {data ? getChipContent(data?.active) : <Skeleton width={40} />}
            </Box>
          </Box>
        }
        body="We'll send a POST request to the URL below with details of a subscribed event."
      />
      <TestResponseDialog
        data={testResponse}
        handleClose={() => setTestResponse(null)}
      />
      {!isLoading && data ? (
        <>
          <SettingsItem
            FormProps={{
              id: webhookId,
              name: [WebhookQuery.webhook, webhookId],
              func: WebhookService.testWebhook,
              format: (d: IWebhookFormInput) => d,
              onSuccess: (d: IWebhookResponse) => {
                setTestResponse(d);
              },
            }}
            PaperHeaderProps={{
              title: "Test your webhook",
              body: `Here you can view an example of data your endpoint receives from ${ConfigConstant.APP_NAME}.`,
            }}
            SubmitButtonProps={{
              value: "Test webhook",
              color: "secondary",
            }}
          />
          <SettingsItem
            FormProps={{
              id: webhookId,
              name: [WebhookQuery.webhook, webhookId],
              func: WebhookService.updateWebhook,
              format: (d: IWebhookFormInput) => d,
              defaultValues: {
                endpoint: data?.endpoint,
              },
            }}
            PaperHeaderProps={{
              title: "Endpoint",
              body: "Edit Zapier endpoint URL.",
            }}
            components={[
              createComponents(ElementField, "endpoint", {
                InputProps: {
                  placeholder: "https://hooks.zapier.com/hooks/catch/8753640/",
                  label: "Endpoint",
                  fullWidth: true,
                },
              }),
            ]}
          />
          <SettingsItem
            FormProps={{
              id: webhookId,
              name: [WebhookQuery.webhook, webhookId],
              func: WebhookService.updateWebhook,
              format: (d: IWebhookFormInput) => d,
              defaultValues: {
                event: data?.event,
              },
            }}
            PaperHeaderProps={{
              title: "Choose when to push data",
              body: "Edit when to push the data to your endpoint.",
            }}
            components={[createComponents(WebhookSelectField, "event")]}
          />
          <SettingsItem
            FormProps={{
              id: webhookId,
              name: [WebhookQuery.webhook, webhookId],
              func: WebhookService.updateWebhook,
              successMsg: (d: IWebhookFormInput) =>
                snackbarHandler[
                  WebhookUtils.getWebhookStatusSnackbarType(d.active)
                ](
                  `Webhook status ${WebhookUtils.getWebhookStatusLabel(
                    d.active
                  )}!`
                ),
              format: (d: IWebhookFormInput) => d,
              defaultValues: {
                active: data?.active,
              },
            }}
            PaperHeaderProps={{
              title: "Status",
              body: "We will deliver event details when this hook is triggered.",
            }}
            components={[
              createComponents(ElementField, "active", {
                InputProps: {
                  label: ["Active", "Paused"],
                },
                component: SwitchField,
              }),
            ]}
          />
          <SettingsItem
            FormProps={{
              id: webhookId,
              name: [WebhookQuery.webhook, webhookId],
              func: () =>
                // eslint-disable-next-line no-alert
                window.confirm("Are you sure you want to delete this webhook?")
                  ? WebhookService.deleteWebhook(webhookId)
                  : null,
              onSuccess: () =>
                history.push(RouterConstants.INTEGRATION.WEBHOOK.ALL),
              format: (d: IWebhookFormInput) => d,
            }}
            PaperHeaderProps={{
              title: "Delete webhook",
              body: "Deleting a webhook stops all its activity. Warning: This is a destructive action that cannot be reverted.",
            }}
            SubmitButtonProps={{
              value: "Delete webhook",
              color: "error",
              endIcon: <DeleteIcon />,
            }}
            hiddenDivider
          />
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default WebhookDetail;
