import { Person } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { Box } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Badge from "@mui/material/Badge";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import { Theme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/styles";
import NavTabs from "core/components/NavTabs";
import TrialBar from "core/components/TrialBar";
import ConfigConstant from "core/constants/ConfigConstant";
import RouterConstants from "core/routes/constants";
import AccountDropdown from "modules/Account/components/AccountDropdown";
import { AccountContext } from "modules/Account/context";
import NotificationService from "modules/Notification/services";
import { UserContext } from "modules/User/context";
import React, { useContext } from "react";
import { useQuery } from "react-query";
import NotificationMenu from "./components/NotificationMenu";
import ProfileMenu from "./components/ProfileMenu";
import { useAppBarStyles } from "./utils";

interface PrimaryAppBarProps {
  disabledNavBar: boolean;
  drawerMobileOpen: boolean;
  handleDrawerMobileToggle: () => void;
}

const PrimaryAppBar = ({
  disabledNavBar,
  drawerMobileOpen,
  handleDrawerMobileToggle,
}: PrimaryAppBarProps): React.ReactElement => {
  const theme = useTheme();
  const { user } = useContext(UserContext);
  const {
    account: { id: accountId, person: isSyncedAccount },
  } = useContext(AccountContext);
  const classes = useAppBarStyles();
  const [profileAnchorEl, setProfileAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const [notificationAnchorEl, setNotificationAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const desktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setProfileAnchorEl(event.currentTarget);
  };

  const handleNotificationMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setNotificationAnchorEl(event.currentTarget);
  };

  const handleNotificationMenuClose = () => {
    setNotificationAnchorEl(null);
  };

  const fetchRecentUnreadNotifications = async () => {
    try {
      const { data } =
        await NotificationService.fetchRecentAccountUnreadNotifications(
          accountId
        );
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };
  const { data } = useQuery(
    ["notifications-recent", accountId],
    () => fetchRecentUnreadNotifications(),
    {
      keepPreviousData: true,
      enabled: !!accountId && !user.anonymous,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <>
      <AppBar position="sticky" sx={{ height: "unset", boxShadow: "unset" }}>
        <TrialBar />

        <Box className={classes.appBar}>
          <Container maxWidth="xl" sx={{ paddingRight: 2, paddingLeft: 2 }}>
            <Toolbar className={classes.toolbar}>
              {!!isSyncedAccount && (
                <IconButton
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerMobileToggle}
                  className={classes.menuButton}
                  size="large"
                >
                  <MenuIcon color="inherit" />
                </IconButton>
              )}

              {desktop && (
                <a href={RouterConstants.ROOT} className={classes.logo}>
                  <img
                    src={`/${ConfigConstant.DOMAIN_NAME}/logo.svg`}
                    className={classes.logo}
                    alt={`${ConfigConstant.APP_NAME} logo`}
                  />
                </a>
              )}

              <Box
                sx={{
                  [theme.breakpoints.up("md")]: {
                    marginLeft: theme.spacing(5),
                  },
                }}
              >
                <AccountDropdown />
              </Box>

              <div className={classes.grow} />

              {desktop && (
                <Tooltip
                  placement="bottom-end"
                  title={
                    data?.count
                      ? "Unread notifications"
                      : "No unread notifications"
                  }
                >
                  <IconButton
                    edge="end"
                    aria-label="account of current user"
                    aria-controls="profile-menu"
                    aria-haspopup="true"
                    onClick={handleNotificationMenuOpen}
                    color="inherit"
                    sx={{ mr: 1.5 }}
                  >
                    <Badge badgeContent={data?.count || 0} color="secondary">
                      <NotificationsIcon aria-label="unread" />
                    </Badge>
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip placement="bottom" title="Menu">
                <Button
                  aria-owns="profile-menu"
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  variant="text"
                  className={classes.appBtn}
                  data-cy="profile-button"
                >
                  <Person />
                  <ExpandMoreIcon fontSize="small" />
                </Button>
              </Tooltip>
            </Toolbar>
          </Container>
        </Box>

        {!disabledNavBar && (
          <NavTabs
            drawerMobileOpen={drawerMobileOpen}
            handleDrawerMobileToggle={handleDrawerMobileToggle}
          />
        )}
      </AppBar>

      <ProfileMenu
        anchorEl={profileAnchorEl}
        setAnchorEl={setProfileAnchorEl}
      />
      <NotificationMenu
        data={data?.results || []}
        anchorEl={notificationAnchorEl}
        handleClose={handleNotificationMenuClose}
      />
    </>
  );
};

export default PrimaryAppBar;
