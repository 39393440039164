import { yupResolver } from "@hookform/resolvers/yup";
import Add from "@mui/icons-material/Add";
import { Box } from "@mui/material";
import ElementField from "core/components/ElementField";
import { formatDateToFull } from "core/utils/dateHandler";
import { errorHandler, IErrorResponse } from "core/utils/errorHandler";
import { snackbarHandler } from "core/utils/snackbarHandler";
import { AccountContext } from "modules/Account/context";
import {
  IWebhookFormInput,
  IWebhookFormValues,
  WebhookQuery,
} from "modules/Webhook/models";
import WebhookService from "modules/Webhook/services";
import WebhookValidations from "modules/Webhook/validations";
import React from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import Button from "ui-kit/atoms/Button";
import WebhookSelectField from "../WebhookSelectField";

interface CreateCampaignWebhookProps {
  campaignId: number;
}

const defaultValues = {
  active: true,
  event: null,
  endpoint: "",
};

const CreateCampaignWebhook = ({
  campaignId,
}: CreateCampaignWebhookProps): React.ReactElement => {
  const queryClient = useQueryClient();

  const {
    account: { id: accountId },
  } = React.useContext(AccountContext);

  const { control, errors, handleSubmit, setError, reset } =
    useForm<IWebhookFormInput>({
      resolver: yupResolver(WebhookValidations.webhookFormSchema),
      defaultValues,
    });

  const mutateSubmit = useMutation(
    (data: IWebhookFormValues) => WebhookService.createWebhook(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([WebhookQuery.webhooks, campaignId]);
        reset();
        snackbarHandler.success("Successfully added!");
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response, setError);
      },
    }
  );

  const onSubmit = (data: IWebhookFormInput) => {
    if (data.event) {
      const newData = {
        ...data,
        name: `Webhook ${campaignId} - ${formatDateToFull(new Date())}`,
        event: data.event,
        account: accountId,
        campaign: campaignId,
      };
      mutateSubmit.mutate(newData);
    }
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ display: "flex", gap: 2, mb: 1 }}>
        <ElementField
          InputProps={{
            fullWidth: true,
            id: "endpoint",
            label: "Endpoint URL",
            placeholder: "https://hooks.zapier.com/hooks/catch/8753640/",
            variant: "outlined",
            sx: { maxWidth: 400 },
          }}
          name="endpoint"
          control={control}
          error={errors.endpoint}
        />
        <WebhookSelectField
          control={control}
          errors={errors}
          sx={{ maxWidth: 260 }}
        />
      </Box>
      <Button
        variant="contained"
        color="primary"
        size="small"
        type="submit"
        startIcon={<Add />}
      >
        Save webhook
      </Button>
    </form>
  );
};

export default CreateCampaignWebhook;
