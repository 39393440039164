import React from "react";
import { Control, FieldErrors } from "react-hook-form";
import { useQuery } from "react-query";
import AutocompleteField from "core/components/AutocompleteField";
import { getAutocompleteOptions } from "core/utils/commonHandler";
import ConfigConstant from "core/constants/ConfigConstant";
import SearchService from "modules/Search/services";

interface SearchSingleAutocompleteProps {
  control: Control;
  errors: FieldErrors;
}

const SearchSingleAutocomplete = ({
  control,
  errors,
}: SearchSingleAutocompleteProps): React.ReactElement => {
  const [search, setSearch] = React.useState("");
  const fetchAllSearches = async () => {
    try {
      const { data } = await SearchService.fetchNotFailedSearches(
        ConfigConstant.INITIAL_PAGE,
        ConfigConstant.PAGE_SIZE.MEDIUM,
        search
      );
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };
  const { data, isLoading } = useQuery(["searches", "all", search], () =>
    fetchAllSearches()
  );

  return (
    <AutocompleteField
      control={control}
      errors={errors}
      options={getAutocompleteOptions(data?.results)}
      isLoading={isLoading}
      handleInputChange={(event, newInputValue) => {
        setSearch(newInputValue);
      }}
      label="Select search"
      name="search"
    />
  );
};

export default SearchSingleAutocomplete;
