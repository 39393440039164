import React from "react";
import FilterButton from "ui-kit/components/FilterButton";
import TextSearchFilter from "../TextSearchFilter";

const KeywordFilter = (props: any): React.ReactElement => {
  return (
    <FilterButton label="Keyword" id="search" enableSearchIcon>
      <TextSearchFilter {...props} />
    </FilterButton>
  );
};

export default KeywordFilter;
