import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import { alpha, darken, Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { makeStyles, useTheme } from "@mui/styles";
import clsx from "clsx";
import StrategyConstant, {
  MATCH_CATEGORY_TO_VARIANT_OBJ,
} from "core/constants/StrategyConstant";
import RouterConstants from "core/routes/constants";
import { formatDateTimeToTime } from "core/utils/dateHandler";
import upperFirst from "lodash/upperFirst";
import { CampaignDetailTabs } from "modules/Campaign/models";
import { IInteraction } from "modules/Interaction/models";
import { getInteractionErrorMessage } from "modules/Interaction/utils";
import PersonAvatar from "modules/Person/components/PersonAvatar";
import { IPerson } from "modules/Person/models";
import { getPersonName } from "modules/Person/utils";
import React from "react";
import { Link } from "react-router-dom";

export const useInteractionStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2, 4),
    display: "block",
  },
  variant: {
    fontSize: "0.75rem",
    display: "inline-block",
    padding: theme.spacing(1.75, 3),
    borderRadius: theme.spacing(1),
    boxShadow: "0 1px 2px 0 rgb(0 0 0 / 5%)",
    WebkitFontSmoothing: "subpixel-antialiased",
  },
  variantLeft: {
    borderRadius: theme.spacing(1, 1, 1, 0),
  },
  variantRight: {
    borderRadius: theme.spacing(1, 1, 0, 1),
  },
  message: {
    maxWidth: 360,
    whiteSpace: "pre-wrap",
    display: "inline-block",
    textAlign: "left",
    padding: theme.spacing(2, 4),
    margin: theme.spacing(0.25, 0),
    borderRadius: theme.spacing(0, 5, 5, 5),
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "0.8rem",
    fontWeight: 400,
    WebkitFontSmoothing: "subpixel-antialiased",
    lineHeight: 1.5,
    boxShadow: "0 1px 2px 0 rgb(0 0 0 / 15%)",
  },
  messageText: {
    marginTop: 0,
  },
  messageLeft: {
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.spacing(0, 5, 5, 5),
  },
  messageRight: {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.common.white,
    borderRadius: theme.spacing(5, 0, 5, 5),
  },
  smallMargin: {
    margin: theme.spacing(0.5, 0),
  },
  content: {
    width: "100%",
  },
  avatar: {
    minWidth: 40,
  },
  actor: {
    textAlign: "right",
    display: "block",
  },
  time: {
    fontSize: 11,
    margin: theme.spacing(0.75, 1.5),
    color: theme.app.palette.action.color,
    WebkitFontSmoothing: "subpixel-antialiased",
  },
  subtitle: {
    fontSize: 11,
    color: theme.app.palette.action.color,
  },
  item: {
    display: "flex",
    flexDirection: "row",
    padding: theme.spacing(2, 5),
  },
  directAction: {
    borderRadius: theme.spacing(20, 20, 0, 20),
  },
  header: {
    display: "inline-block",
    marginBottom: theme.spacing(1),
  },
  title: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "baseline",
  },
  innerDivLeft: {
    display: "flex",
    alignItems: "flex-start",
  },
  innerDivRight: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

interface InteractionContainerItemProps {
  interaction: IInteraction;
  accountPerson: IPerson;
}

const InteractionContainerItem = ({
  interaction,
  accountPerson,
}: InteractionContainerItemProps): React.ReactElement | null => {
  const theme: Theme = useTheme();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const classes: any = useInteractionStyles();
  const variantName = MATCH_CATEGORY_TO_VARIANT_OBJ[interaction.category];
  const strategyObj = StrategyConstant.STRATEGY_OBJ[variantName];
  const side = interaction.account_is_actor ? "right" : "left";
  const sideClass = upperFirst(side);

  const actor = interaction.account_is_actor
    ? accountPerson
    : interaction.person;
  const actorName = getPersonName(actor);

  if (!strategyObj) return null;

  const avatar = (
    <ListItemAvatar
      className={clsx(classes.avatar, classes[`innerDiv${sideClass}`])}
    >
      <PersonAvatar src={actor?.image_base64} size="small" name={actorName} />
    </ListItemAvatar>
  );

  return (
    <ListItem
      className={classes.root}
      sx={{
        textAlign: side,
      }}
    >
      <div className={classes[`innerDiv${sideClass}`]}>
        {!interaction.account_is_actor && avatar}
        <div>
          <Box
            sx={{
              color: darken(strategyObj.color, 0.4),
              backgroundColor: alpha(
                strategyObj.color,
                theme.palette.action.hoverOpacity * 3
              ),
              border: `1px solid ${alpha(
                strategyObj.color,
                5 * theme.palette.action.hoverOpacity
              )}`,
            }}
            className={classes.variant}
          >
            <Typography
              component="span"
              variant="inherit"
              sx={{ fontWeight: 500 }}
            >
              {!!interaction.account_is_actor
                ? strategyObj.label.account_is_actor
                : strategyObj.label.account_is_not_actor}
            </Typography>{" "}
            {interaction.campaign && (
              <>
                <Typography
                  component="span"
                  variant="inherit"
                  color="textSecondary"
                >
                  in{" "}
                </Typography>
                <>
                  <Link
                    to={RouterConstants.CAMPAIGN.detail(
                      interaction.campaign.id,
                      CampaignDetailTabs.audience
                    )}
                  >
                    {interaction.campaign.name}
                  </Link>
                  <Typography
                    component="span"
                    variant="inherit"
                    color="textSecondary"
                  >
                    {interaction.sequence_action_order_nr &&
                      `, step ${interaction.sequence_action_order_nr}`}
                  </Typography>
                </>
              </>
            )}
          </Box>
          {interaction.data?.message && (
            <ListItemText
              secondary={interaction.data?.message}
              secondaryTypographyProps={{
                color: "textPrimary",
                className: clsx(
                  classes.message,
                  classes[`message${sideClass}`]
                ),
              }}
            />
          )}

          {(interaction.failed || interaction.other_issue?.category) && (
            <ListItemText
              secondary={getInteractionErrorMessage(interaction)}
              secondaryTypographyProps={{
                variant: "caption",
                color: "error",
              }}
              className={classes.smallMargin}
            />
          )}
          <Typography className={classes.time}>
            {formatDateTimeToTime(interaction.platform_created_at)}
          </Typography>
        </div>
        {!!interaction.account_is_actor && avatar}
      </div>
    </ListItem>
  );
};

export default InteractionContainerItem;
