import Grid from "@mui/material/Grid";
import React from "react";

interface ClientLogoItemProps {
  children: React.ReactElement;
}

const ClientLogoItem = ({
  children,
}: ClientLogoItemProps): React.ReactElement => (
  <Grid
    item
    xs={12}
    sm={6}
    md={4}
    sx={{ display: "flex", alignItems: "center" }}
  >
    {children}
  </Grid>
);

export default ClientLogoItem;
