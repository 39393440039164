import React from "react";
import { Control, FieldErrors } from "react-hook-form";
import { useQuery } from "react-query";
import AutocompleteField from "core/components/AutocompleteField";
import { getAutocompleteOptions } from "core/utils/commonHandler";
import { AccountContext } from "modules/Account/context";
import CampaignService from "modules/Campaign/services";
import ConfigConstant from "core/constants/ConfigConstant";
import { CampaignQuery } from "modules/Campaign/models";

interface CampaignAutocompleteProps {
  control: Control;
  errors: FieldErrors;
}

const CampaignAutocomplete = ({
  control,
  errors,
}: CampaignAutocompleteProps): React.ReactElement => {
  const [search, setSearch] = React.useState("");
  const {
    account: { id: accountId },
  } = React.useContext(AccountContext);

  const fetchAccountCampaigns = async () => {
    try {
      const { data } = await CampaignService.fetchAccountCampaigns(
        ConfigConstant.INITIAL_PAGE,
        accountId,
        search
      );
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };
  const { data, isLoading } = useQuery(
    [CampaignQuery.campaigns_all, search],
    () => fetchAccountCampaigns()
  );

  return (
    <AutocompleteField
      control={control}
      errors={errors}
      options={getAutocompleteOptions(data?.results)}
      isLoading={isLoading}
      handleInputChange={(event, newInputValue) => {
        setSearch(newInputValue);
      }}
      label="Select campaign"
      name="campaign"
    />
  );
};

export default CampaignAutocomplete;
