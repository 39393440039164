import React from "react";
import { useForm } from "react-hook-form";
import { useQueryClient, useMutation } from "react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHistory } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import ElementField from "core/components/ElementField";
import { errorHandler, IErrorResponse } from "core/utils/errorHandler";
import { snackbarHandler } from "core/utils/snackbarHandler";
import RouterConstants from "core/routes/constants";
import { AccountContext } from "modules/Account/context";
import {
  IWebhookFormInput,
  IWebhookFormValues,
  WebhookQuery,
} from "modules/Webhook/models";
import WebhookService from "modules/Webhook/services";
import WebhookValidations from "modules/Webhook/validations";
import Button from "ui-kit/atoms/Button";
import DialogHeader from "ui-kit/components/DialogHeader";
import WebhookSelectField from "../WebhookSelectField";
import { formatDateToFull } from "core/utils/dateHandler";
interface CreateWebhookDialogProps {
  open: boolean;
  toggleOpen: (open: boolean) => void;
}

const CreateWebhookDialog = ({
  open,
  toggleOpen,
}: CreateWebhookDialogProps): React.ReactElement => {
  const queryClient = useQueryClient();
  const history = useHistory();

  const {
    account: { id: accountId },
  } = React.useContext(AccountContext);

  const { control, errors, handleSubmit, setError } =
    useForm<IWebhookFormInput>({
      resolver: yupResolver(WebhookValidations.webhookFormSchema),
      defaultValues: {
        name: "",
        active: true,
        event: null,
        endpoint: "",
      },
    });

  const handleClose = () => {
    toggleOpen(false);
    history.push(RouterConstants.INTEGRATION.WEBHOOK.ALL);
  };

  const mutateSubmit = useMutation(
    (data: IWebhookFormValues) => WebhookService.createWebhook(data),
    {
      onSuccess: (response) => {
        const { data } = response;
        queryClient.invalidateQueries([WebhookQuery.webhooks]);
        snackbarHandler.success("Successfully added!");
        toggleOpen(false);
        history.push(RouterConstants.INTEGRATION.WEBHOOK.detail(data.id));
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response, setError);
      },
    }
  );

  const onSubmit = (data: IWebhookFormInput) => {
    if (data.event) {
      const newData = {
        ...data,
        event: data.event,
        account: accountId,
      };
      mutateSubmit.mutate(newData);
    }
  };

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleClose}
      aria-labelledby="webhook-dialog"
      maxWidth="sm"
      PaperProps={{ sx: { width: "100%" } }}
    >
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <DialogHeader title="Add a webhook" onHandleClose={handleClose} />
        <DialogContent dividers>
          <Typography variant="subtitle2" paragraph>
            Enter name for webhook
          </Typography>
          <ElementField
            InputProps={{
              fullWidth: true,
              id: "name",
              label: "Name",
              variant: "outlined",
              placeholder: `ex. Webhook - ${formatDateToFull(new Date())}`,
            }}
            name="name"
            control={control}
            error={errors.name}
          />
          <br />
          <br />
          <Typography variant="subtitle2" paragraph>
            Choose when to push data
          </Typography>
          <WebhookSelectField control={control} errors={errors} />
          <br />
          <br />
          <Typography variant="subtitle2" paragraph>
            Paste endpoint URL
          </Typography>
          <ElementField
            InputProps={{
              fullWidth: true,
              id: "endpoint",
              label: "Endpoint URL",
              placeholder: "https://hooks.zapier.com/hooks/catch/8753640/",
              variant: "outlined",
            }}
            name="endpoint"
            control={control}
            error={errors.endpoint}
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit" variant="contained" color="primary">
            Save webhook
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateWebhookDialog;
