import Box from "@mui/material/Box";
import { ICampaignParams } from "modules/Campaign/models";
import RecentActivityReport from "modules/Report/components/RecentActivityReport";
import ReportContainer from "modules/Report/components/ReportContainer";
import React from "react";
import CampaignStepReport from "../CampaignStepReport";

const CampaignReports = ({
  campaignId,
}: ICampaignParams): React.ReactElement => {
  return (
    <>
      <Box marginBottom={8}>
        <CampaignStepReport campaignId={campaignId} />
      </Box>

      <Box marginBottom={8}>
        <ReportContainer defaultQueryString={`&campaign_id=${campaignId}`} />
      </Box>

      <RecentActivityReport campaignId={campaignId.toString()} />
    </>
  );
};

export default CampaignReports;
