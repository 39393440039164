import ProfileDetail from "core/components/ProfileDetail";
import ConfigConstant from "core/constants/ConfigConstant";
import { GlobalContext } from "core/context";
import KeywordFilter from "core/filters/atoms/KeywordFilter";
import { IProfileClicked } from "core/models";
import { GlobalActionType } from "core/reducers";
import RouterUtils from "core/routes/utils";
import { formatDateTimeToFull } from "core/utils/dateHandler";
import { createTableCell } from "core/utils/tableHandler";
import CreateAddressBookItems from "modules/AddressBook/components/CreateAddressBookItems";
import AddressBookService from "modules/AddressBook/services";
import { cleanPersonDetail, getPersonName } from "modules/Person/utils";
import React from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import Table, { TableVariant } from "ui-kit/components/Table";
import TableBodyCell from "ui-kit/components/TableBodyCell";

export const BLACKLIST_PROPS = {
  title: "Blacklisted people",
  subtitle: "Here you can view, add or remove people in the blacklist.",
  heads: [
    {
      id: "name",
      percentage: true,
      width: 30,
      label: "Name",
    },
    {
      id: "occupation",
      percentage: true,
      width: 50,
      label: "Occupation",
    },
    {
      id: "added",
      percentage: true,
      width: 20,
      label: "Added at",
    },
  ],
  rowsPerPage: ConfigConstant.PAGE_SIZE.MEDIUM,
};

interface AddressBookDetailProps {
  addressBookId: number;
  open: boolean;
  toggleOpen: (open: boolean) => void;
}

const AddressBookDetail = (
  props: AddressBookDetailProps
): React.ReactElement => {
  const { addressBookId, toggleOpen } = props;
  const [page, setPage] = React.useState<number>(ConfigConstant.INITIAL_PAGE);
  // If existing query, set active filters
  const location = useLocation();
  const { search } = RouterUtils.getQueryParams(location);

  const { dispatch } = React.useContext(GlobalContext);

  const [dataClicked, setDataClicked] = React.useState<
    IProfileClicked | undefined
  >(undefined);

  const handleClose = () => {
    toggleOpen(false);
    dispatch({
      type: GlobalActionType.SET_GLOBAL,
      payload: { selected: [] },
    });
    setDataClicked(undefined);
  };

  const handleClicked = (newDataClicked: IProfileClicked) => {
    setDataClicked(newDataClicked);
  };

  const fetchAddressBookItems = async (p: number) => {
    try {
      const { data } = await AddressBookService.fetchAddressBookItems(
        addressBookId,
        p,
        BLACKLIST_PROPS.rowsPerPage,
        search
      );
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };
  // NOTE: There is always only a single account blacklist - {unique: true}
  const { data, isFetching } = useQuery(
    ["addressBook-items", addressBookId, page, search],
    () => fetchAddressBookItems(page),
    {
      keepPreviousData: true,
    }
  );

  return (
    <>
      <Table
        title={BLACKLIST_PROPS.title}
        subtitle={BLACKLIST_PROPS.subtitle}
        heads={BLACKLIST_PROPS.heads}
        rows={
          data?.results
            ? data.results.map(({ person, created }) => {
                const handleOnClick = () =>
                  handleClicked({
                    personId: person.id,
                  });
                return {
                  name: person.id.toString(),
                  data: [
                    createTableCell(
                      getPersonName(person),
                      handleOnClick,
                      "main",
                      TableBodyCell
                    ),
                    createTableCell(
                      cleanPersonDetail(person.occupation || "-"),
                      handleOnClick,
                      "default",
                      TableBodyCell
                    ),
                    createTableCell(
                      formatDateTimeToFull(created),
                      handleOnClick,
                      "default",
                      TableBodyCell
                    ),
                  ],
                };
              })
            : []
        }
        filters={
          <>
            <KeywordFilter />
          </>
        }
        count={data?.count || 0}
        // INITIAL_PAGE starts at 1, but Pagination starts at 0
        page={data?.page || page - 1}
        setPage={setPage}
        isFetching={isFetching}
        variant={[TableVariant.checkable]}
        actionPropsIfEmpty={
          !search
            ? {
                text: "Add people to blacklist",
                onClick: () => toggleOpen(true),
              }
            : undefined
        }
        panel={<ProfileDetail {...dataClicked} onHandleClose={handleClose} />}
        rowsPerPage={BLACKLIST_PROPS.rowsPerPage}
      />
      <CreateAddressBookItems {...props} handleClose={handleClose} />
    </>
  );
};

export default AddressBookDetail;
