import React from "react";
import { makeStyles, createStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { GlobalContext } from "core/context";
import { useHistory, useLocation } from "react-router-dom";
import { GlobalActionType } from "core/reducers";
import { Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import clsx from "clsx";
import isEmpty from "lodash/isEmpty";
import RouterUtils from "core/routes/utils";

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(1, 3.5, 0),
      [theme.breakpoints.up("sm")]: {
        minHeight: 50,
      },
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        alignItems: "flex-start",
      },
    },
    containerDense: {
      padding: theme.spacing(3, 4, 3.5),
    },
    highlight: {
      color: theme.palette.secondary.main,
    },
    title: {
      flex: "1 1 100%",
    },
    titleDense: {
      marginBottom: theme.spacing(1.25),
    },
    filterBox: {
      paddingBottom: theme.spacing(3.75),
      borderBottom: `1px solid ${theme.app.palette.shadow.primary}`,
      [theme.breakpoints.up("sm")]: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      },
    },
    filterBoxDisable: {
      borderBottom: "none",
    },
    filters: {
      gap: 6,
      [theme.breakpoints.down("sm")]: {
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
      },
      [theme.breakpoints.up("sm")]: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
      },
    },
    wrap: {
      transition: theme.transitions.create("all", {
        duration: theme.transitions.duration.standard,
      }),
    },
    body: {
      display: "flex",
      [theme.breakpoints.up("md")]: {
        alignItems: "center",
      },
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
      },
    },
    clearBtn: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      gap: theme.spacing(0.5),
      color: theme.app.palette.action.color,
      "&:hover": {
        color: theme.app.palette.action.main,
      },
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    clearBtnDisabled: {
      cursor: "default",
      color: theme.app.palette.action.placeholder,
      "&:hover": {
        color: theme.app.palette.action.placeholder,
      },
    },
    clearIcon: { fontSize: 16 },
    label: {
      fontSize: "0.85rem",
      fontWeight: 500,
    },
  })
);

export interface BaseTableToolbarProps {
  title?: string | React.ReactElement | undefined;
  subtitle?: string | React.ReactElement | undefined;
  filters?: React.ReactElement;
  actions?: React.ReactElement;
  disableDivider?: boolean;
}

interface TableToolbarProps extends BaseTableToolbarProps {
  numSelected: number;
}

const TableToolbar = (props: TableToolbarProps): React.ReactElement | null => {
  const classes = useToolbarStyles();
  const { filters, disableDivider } = props;
  const { dispatch } = React.useContext(GlobalContext);

  const location = useLocation();
  const history = useHistory();
  const queryParams = RouterUtils.getQueryParams(location);

  const firstUpdate = React.useRef(true);
  React.useEffect(() => {
    if (location) {
      if (!firstUpdate.current) {
        return;
      }

      if (location.search) {
        dispatch({
          type: GlobalActionType.SET_GLOBAL,
          payload: { selected: [] },
        });
      }

      firstUpdate.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleClearFilter = () => history.push({ search: "" });

  return !!filters ? (
    <div
      className={clsx(classes.filterBox, {
        [classes.filterBoxDisable]: disableDivider,
      })}
    >
      <div className={classes.filters}>{filters}</div>
      <Box
        className={clsx(classes.clearBtn, {
          [classes.clearBtnDisabled]: isEmpty(queryParams),
        })}
        onClick={handleClearFilter}
      >
        <CloseIcon className={classes.clearIcon} />
        <div className={classes.label}>Clear Filters</div>
      </Box>
    </div>
  ) : null;
};

export default TableToolbar;
