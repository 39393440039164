import CheckIcon from "@mui/icons-material/Check";
import { ConnectionStatusLabels } from "modules/Interaction/models";
import { DefaultPlaceholdersKeys } from "modules/Placeholder/models";
import { getPlaceholderContent } from "modules/Placeholder/utils";
import { createChipData, IStatusChipData } from "ui-kit/atoms/Chip";
import { IPerson, IContact } from "../models";

const getPersonName = (person: IPerson | undefined): string => {
  if (!person) return "";

  const { first_name: firstName, last_name: lastName } = person;

  let name = "";
  if (firstName) {
    name += firstName;
  }
  if (firstName && lastName) {
    name += " ";
  }
  if (lastName) {
    name += lastName;
  }
  return name;
};

const getContactName = (person: IPerson | undefined): string => {
  if (!person) return "";

  const firstName = getPlaceholderContent(
    person.placeholders,
    person.default_placeholders,
    undefined,
    DefaultPlaceholdersKeys.first_name
  );
  const lastName = getPlaceholderContent(
    person.placeholders,
    person.default_placeholders,
    undefined,
    DefaultPlaceholdersKeys.last_name
  );

  let name = "";
  if (firstName) {
    name += firstName;
  }
  if (firstName && lastName) {
    name += " ";
  }
  if (lastName) {
    name += lastName;
  }
  return name;
};

const cleanPersonDetail = (str: string) =>
  str ? str.replace(/&amp;/g, "&") : "";

const getConnectionChipData = (
  active_connection: IContact["active_connection"] | undefined,
  interaction: boolean
): IStatusChipData => {
  if (active_connection) {
    return createChipData(
      "success",
      CheckIcon,
      ConnectionStatusLabels.connected
    );
  }
  if (!active_connection && interaction) {
    return createChipData(
      "secondary",
      CheckIcon,
      ConnectionStatusLabels.pending
    );
  }
  return createChipData(
    "warning",
    CheckIcon,
    ConnectionStatusLabels.not_connected
  );
};

const encodeImage = (imgSrc: string | undefined) =>
  imgSrc ? `data:;base64,${imgSrc}` : "";

export {
  getPersonName,
  getContactName,
  getConnectionChipData,
  cleanPersonDetail,
  encodeImage,
};
