import { InteractionStatLabel } from "modules/Interaction/models";
import { ChartType, IDateReport, IReportElement } from "modules/Report/models";
import React from "react";
import ReportElement from "../ReportElement";
import ReportPercentageGenerator from "../ReportPercentageGenerator";

interface ReportCompareItemProps {
  items: [IReportElement, IReportElement];
  DateProps: IDateReport;
  compareLabel: string;
  indexes: [number, number, number];
  total: number;
  isLoading: boolean;
  itemsPerLine: number;
}

const ReportCompareItem = ({
  items,
  DateProps,
  compareLabel,
  indexes,
  total,
  isLoading,
  itemsPerLine,
}: ReportCompareItemProps): React.ReactElement => {
  return (
    <>
      <ReportElement
        label={InteractionStatLabel[items[0].key]}
        DateProps={DateProps}
        isLoading={isLoading}
        count={items[0].count}
        values={items[0].values}
        index={indexes[0]}
        total={total}
        itemsPerLine={itemsPerLine}
      />
      <ReportElement
        label={InteractionStatLabel[items[1].key]}
        DateProps={DateProps}
        isLoading={isLoading}
        count={items[1].count}
        values={items[1].values}
        index={indexes[1]}
        total={total}
        itemsPerLine={itemsPerLine}
      />
      <ReportPercentageGenerator
        label={compareLabel}
        DateProps={DateProps}
        isLoading={isLoading}
        count1={items[0].count}
        count2={items[1].count}
        values1={items[0].values}
        values2={items[1].values}
        index={indexes[2]}
        total={total}
        chartType={ChartType.percentage}
        itemsPerLine={itemsPerLine}
      />
    </>
  );
};

export default ReportCompareItem;
