import { ButtonProps } from "@mui/material/Button";
import { useTheme } from "@mui/styles";
import React from "react";
import Button from "ui-kit/atoms/Button";

interface StepHandlerProps {
  activeStep: number;
  disabledNext?: boolean;
  noBackButton?: boolean;
  hideBackButton?: boolean;
  hideButtons?: boolean;
  customNextText?: string;
  customButton?: React.ReactElement | string;
  onHandleBack: () => void;
  onHandleNext?: () => void;
  NextButtonProps?: ButtonProps;
  type: "button" | "submit";
}

const StepHandler = ({
  activeStep,
  disabledNext,
  noBackButton,
  hideBackButton,
  hideButtons,
  customNextText,
  customButton,
  onHandleBack,
  onHandleNext,
  NextButtonProps,
  type,
}: StepHandlerProps): React.ReactElement => {
  const theme = useTheme();
  return (
    <>
      {!hideButtons && (
        <>
          {!noBackButton && (
            <Button
              color="inherit"
              variant="outlined"
              disabled={activeStep === 0}
              onClick={onHandleBack}
              sx={{
                marginRight: 1.5,
                [theme.breakpoints.up("md")]: {
                  visibility: hideBackButton ? "hidden" : "visible",
                },
                [theme.breakpoints.down("md")]: {
                  display: hideBackButton ? "none" : "initial",
                },
              }}
            >
              Back
            </Button>
          )}
          {customButton}
          <Button
            {...NextButtonProps}
            disabled={disabledNext}
            variant="contained"
            color="primary"
            // If onHandleNext exists = next step by onHandleNext
            // If it doesn't exist, submit on parent form
            type={type}
            {...(onHandleNext &&
              type !== "submit" && { onClick: onHandleNext })}
          >
            {customNextText || "Next"}
          </Button>
        </>
      )}
    </>
  );
};

StepHandler.defaultProps = {
  NextButtonProps: {},
  disabledNext: false,
  noBackButton: false,
  hideBackButton: false,
  customNextText: "",
  customButton: undefined,
  hideButtons: false,
};

export default StepHandler;
