import React from "react";
import { createComponents } from "core/utils/componentsHandler";
import SettingsItem from "core/components/SettingsItem";
import AccountService from "modules/Account/services";
import AccountValidation from "modules/Account/validations";
import {
  AccountQuery,
  IAccount,
  IAccountFormInput,
} from "modules/Account/models";
import CompanyAutocomplete from "modules/Company/components/CompanyAutocomplete";

interface IFormInput {
  company: IAccountFormInput["company"];
}

interface CompanySettingsProps {
  account: IAccount;
}

const CompanySettings = ({
  account,
}: CompanySettingsProps): React.ReactElement | null => {
  if (!account) return null;
  const { id: accountId } = account;
  const defaultValues = {
    company: account.company,
  };

  return (
    <>
      <SettingsItem
        FormProps={{
          id: accountId,
          name: [AccountQuery.account, accountId],
          func: AccountService.updateAccount,
          format: (d: IFormInput) => ({ company: d?.company?.id }),
          defaultValues,
          schema: AccountValidation.updateCompanySchema(),
        }}
        PaperHeaderProps={{
          title: "Change company",
          // body: "Assign this LinkedIn account to another company. Warning: This action moves all of your campaigns to a new company.",
          body: "Assign this LinkedIn account to another company.",
        }}
        components={[createComponents(CompanyAutocomplete, "company")]}
        // Last item in the list
        hiddenDivider
      />
    </>
  );
};

export default CompanySettings;
