import Content from "core/components/Content";
import ConfigConstant from "core/constants/ConfigConstant";
import RouterConstants from "core/routes/constants";
import get from "lodash/get";
import doSaveUser from "modules/Payment/hooks/useSaveUser";
import useVerifySubscription from "modules/Payment/hooks/useVerifySubscription";
import doVerifyUser from "modules/Payment/hooks/useVerifyUser";
import React from "react";
import { Helmet } from "react-helmet-async";
import { Redirect, Route, RouteProps } from "react-router-dom";

export interface ProtectedRouteProps extends RouteProps {
  isAuthenticated: boolean;
  isCurrentAccount?: boolean;
  isRequiredCheck?: boolean;
  disableAccountValidation?: boolean;
  isAllowed?: boolean;
  restrictedPath?: string;
  authenticationPath?: string;
  noticePath?: string;
  activeAccountPath?: string;
  ContentProps?: {
    disableOnboarding?: boolean;
    disabledNavBar?: boolean;
  };
}

export interface IConditionalWrapProps {
  condition: boolean;
  wrap: (children: React.ReactElement) => React.ReactElement;
  children: React.ReactElement;
}

const ConditionalWrap = ({
  condition,
  wrap,
  children,
}: IConditionalWrapProps) => {
  return condition ? wrap(children) : children;
};

interface CustomRouteProps extends ProtectedRouteProps {
  title: string;
}

const ProtectedRoute = (props: CustomRouteProps): React.ReactElement => {
  const {
    title,
    isAuthenticated,
    isAllowed,
    isCurrentAccount,
    isRequiredCheck,
    disableAccountValidation,
    noticePath = "",
    restrictedPath = "",
    authenticationPath = "",
    activeAccountPath = "",
    ContentProps,
    ...rest
  } = props;
  let redirectPath = "";

  // Verify user
  if (ConfigConstant.APP_ID === 1) {
    doVerifyUser();
  }

  // Save user based on token
  if (ConfigConstant.APP_ID === 2) {
    doSaveUser();
  }

  // User not logged in
  if (!isAuthenticated) {
    redirectPath = authenticationPath;
  }

  // User logged in, path not allowed (ex. sign in)
  if (isAuthenticated && !isAllowed) {
    redirectPath = restrictedPath;
  }

  // User logged in, path not allowed (ex. sign in)
  if (
    ConfigConstant.APP_ID === 1 &&
    !isAllowed &&
    !isAuthenticated &&
    !!noticePath
  ) {
    redirectPath = noticePath;
  }

  const { is_subscribed, ltd, exception } = useVerifySubscription();

  // User logged in, current LinkedIn account exists, but not complete
  if (isAuthenticated && activeAccountPath && !exception) {
    let newRedirectPath = redirectPath;
    // if (!isSyncedAccount) {
    //   redirectPath = syncAccountPath;
    // }

    if (disableAccountValidation) {
      redirectPath = newRedirectPath;
    }
  }

  // User logged in, current LinkedIn account exists, but not active plan
  if (
    isCurrentAccount &&
    isRequiredCheck &&
    isAuthenticated &&
    activeAccountPath &&
    !disableAccountValidation &&
    is_subscribed !== undefined &&
    !(is_subscribed || ltd || exception)
  ) {
    redirectPath = RouterConstants.BILLING.PLAN;
  }

  // User logged in, no LinkedIn account selected
  if (isAuthenticated && activeAccountPath && !isCurrentAccount) {
    redirectPath = activeAccountPath;
  }

  if (redirectPath && redirectPath !== get(rest, "location.pathname")) {
    const renderComponent = () => <Redirect to={{ pathname: redirectPath }} />;
    return <Route {...rest} component={renderComponent} render={undefined} />;
  }

  return (
    <ConditionalWrap
      condition={isAuthenticated}
      wrap={(children: React.ReactElement) => (
        <Content {...ContentProps}>{children}</Content>
      )}
    >
      <>
        <Helmet>
          <title>{`${title} | ${ConfigConstant.APP_NAME}`} </title>
        </Helmet>
        <Route {...rest} />
      </>
    </ConditionalWrap>
  );
};

export default ProtectedRoute;
