import { yupResolver } from "@hookform/resolvers/yup";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import ConfigConstant from "core/constants/ConfigConstant";
import RouterConstants from "core/routes/constants";
import { errorHandler, IErrorResponse } from "core/utils/errorHandler";
import { AccountContext } from "modules/Account/context";
import { GetStartedLabels } from "modules/Dashboard/models";
import {
  CreateSequenceKeys,
  ICreateSequenceFormInput,
  ICreateSequenceFormValues,
  ISequence,
  SequenceDetailTabs,
} from "modules/Sequence/models";
import SequenceService from "modules/Sequence/services";
import SequenceValidation from "modules/Sequence/validations";
import React from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import Button from "ui-kit/atoms/Button";
import TextField from "ui-kit/atoms/TextField";
import DialogHeader from "ui-kit/components/DialogHeader";
import Loader from "ui-kit/components/Loader";
import SequenceActionSelector from "../SequenceActionSelector";
import SequenceSingleAutocomplete from "../SequenceSingleAutocomplete";

interface CreateSequenceProps {
  open: boolean;
  toggleOpen: (open: boolean) => void;
}

const CreateSequence = ({
  open,
  toggleOpen,
}: CreateSequenceProps): React.ReactElement => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const [checked, setChecked] = React.useState<CreateSequenceKeys>(
    CreateSequenceKeys.createNew
  );

  const {
    account: { id: accountId },
  } = React.useContext(AccountContext);

  const schema = SequenceValidation.createSequenceSchema(checked);
  const { register, errors, control, handleSubmit, setError } =
    useForm<ICreateSequenceFormInput>({
      resolver: yupResolver(schema),
      defaultValues: { name: "", sequence: { id: undefined, label: "" } },
    });

  const fetchExistingSequences = async () => {
    try {
      const { data } = await SequenceService.fetchExistingSequences();
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };
  const { data: sequence, isLoading } = useQuery(
    ["sequences", "existing"],
    () => fetchExistingSequences(),
    {
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const handleClose = () => {
    toggleOpen(false);
    history.push(RouterConstants.SEQUENCE.ALL);
  };

  const sequenceNameField = (
    <TextField
      autoComplete="off"
      autoFocus
      id="name"
      fullWidth
      name="name"
      inputRef={register}
      label="Template name"
      variant="outlined"
      error={!!errors.name}
      helperText={errors.name?.message}
    />
  );

  const FormProps = {
    createNew: {
      components: sequenceNameField,
      mutateFunc: SequenceService.createSequence,
    },
    copyExisting: {
      components: (
        <>
          {sequenceNameField}
          <SequenceSingleAutocomplete control={control} errors={errors} />
        </>
      ),
      mutateFunc: SequenceService.copySequence,
    },
  };

  const mutateOnSubmit = useMutation(
    (data: ICreateSequenceFormValues) =>
      FormProps[checked]?.mutateFunc({
        ...data,
        account: accountId,
      }),
    {
      onSuccess: ({ data }: { data: ISequence }) => {
        history.push(
          RouterConstants.SEQUENCE.detail(data.id, SequenceDetailTabs.actions)
        );
        queryClient.invalidateQueries([
          "get-started",
          accountId,
          GetStartedLabels.sequence,
        ]);
        queryClient.invalidateQueries([
          "sequences",
          ConfigConstant.INITIAL_PAGE,
        ]);
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response, setError);
      },
    }
  );

  const onSubmit = (data: ICreateSequenceFormInput) => {
    mutateOnSubmit.mutate({
      name: data.name,
      sequence: data.sequence?.id,
    });
  };

  const content = (
    <>
      {!!sequence?.count && (
        <SequenceActionSelector checked={checked} setChecked={setChecked} />
      )}
      <Box sx={{ py: 2 }}>{FormProps[checked]?.components}</Box>
    </>
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="create-template-dialog"
      maxWidth="sm"
      PaperProps={{ sx: { width: "100%" } }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogHeader
          title="Create a new template"
          onHandleClose={handleClose}
        />
        <DialogContent dividers>
          {isLoading ? <Loader /> : content}
        </DialogContent>
        <DialogActions>
          <Button type="submit" variant="contained" color="primary">
            Next: Add steps
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateSequence;
