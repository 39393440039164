import { Alert, AlertTitle } from "@mui/material";
import ConfigConstant from "core/constants/ConfigConstant";
import AuthForm from "modules/User/components/AuthForm";
import React from "react";

const Notice = (): React.ReactElement | null => {
  if (ConfigConstant.APP_ID === 2) {
    return null;
  }

  return (
    <AuthForm disableForm title="">
      <Alert severity="error">
        <AlertTitle>
          {ConfigConstant.APP_NAME} service isn't available.
        </AlertTitle>
        You can contact us at{" "}
        <a href={ConfigConstant.EMAIL_LINK}>{ConfigConstant.EMAIL}</a> with your
        question.
      </Alert>
    </AuthForm>
  );
};

export default Notice;
