import React, { useContext } from "react";
import { useQuery } from "react-query";
import { useTheme } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import { ICountReportItem } from "modules/Report/models";
import { AccountContext } from "modules/Account/context";
import { getPercentage } from "modules/Report/utils";

interface InteractionCompareItemProps {
  query: string;
  query1?: string;
  query2?: String;
  showTotal?: boolean;
  items: [ICountReportItem, ICountReportItem];
}

const InteractionCompareItem = ({
  query,
  query1 = "",
  query2 = "",
  showTotal,
  items,
}: InteractionCompareItemProps): React.ReactElement => {
  const [firstItem, secondItem] = items;
  const theme: Theme = useTheme();

  const {
    account: { id: accountId },
  } = useContext(AccountContext);

  const fetch1 = async () => {
    try {
      const { data } = await firstItem.func(accountId, query + query1);
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };

  const { data: data1 } = useQuery(
    ["reports", firstItem.name, accountId, query, query1],
    () => fetch1(),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  const fetch2 = async () => {
    try {
      const { data } = await secondItem.func(accountId, query + query2);
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };

  const { data: data2 } = useQuery(
    ["reports", secondItem.name, accountId, query, query2],
    () => fetch2(),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  const isLoading = !(data1 && data2);
  const displayCount = showTotal
    ? `${data2?.count}/${data1?.count}`
    : data2?.count;

  return isLoading ? (
    <Skeleton width={20} />
  ) : (
    <>
      <Typography
        variant="body2"
        color="textSecondary"
        component="span"
        sx={{ mr: 1, fontSize: "0.85rem" }}
      >
        {getPercentage(data2?.count, data1?.count)}
      </Typography>
      <Typography
        variant="body2"
        component="span"
        sx={{ color: theme.app.palette.action.color, fontSize: "0.85rem" }}
      >
        ({displayCount})
      </Typography>
    </>
  );
};

export default InteractionCompareItem;
