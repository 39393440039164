import { IArrayResponse } from "core/models";
import { IAccount } from "modules/Account/models";

export enum TargetContentTypeModels {
  "person" = "person",
  "search" = "search",
  "accountlog" = "accountlog",
  "interaction" = "interaction",
}

export enum NotificationNavigation {
  "account_warmup" = "account_warmup",
}

export interface INotificationData {
  nav: NotificationNavigation;
}

export interface INotification {
  id: number;
  description: string;
  timestamp: string;
  actor: IAccount;
  actor_content_type: {
    app_label: string;
    model: "account";
  };
  actor_object_id: number;
  target_object_id: number;
  target_content_type: {
    app_label: string;
    model: TargetContentTypeModels;
  };
  action_object_object_id?: number;
  action_object_content_type?: {
    app_label: string;
    model: TargetContentTypeModels;
  };
  unread: boolean;
  data: INotificationData;
}

export interface INotificationFormInput {
  id: number;
  unread: boolean;
}

export interface INotifications extends IArrayResponse {
  results: INotification[];
}
