import { yupResolver } from "@hookform/resolvers/yup";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import ElementField from "core/components/ElementField";
import RouterConstants from "core/routes/constants";
import { errorHandler, IErrorResponse } from "core/utils/errorHandler";
import { snackbarHandler } from "core/utils/snackbarHandler";
import AuthForm from "modules/User/components/AuthForm";
import { IEmailFormInput } from "modules/User/models";
import UserService from "modules/User/services";
import UserValidation from "modules/User/validations";
import React from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { Link, useHistory, useParams } from "react-router-dom";
import Button from "ui-kit/atoms/Button";
import Loader from "ui-kit/components/Loader";

type ParamTypes = {
  key: string;
};

const VerifyRegistration = (): React.ReactElement => {
  const params = useParams<ParamTypes>();
  const history = useHistory();

  const schema = UserValidation.verifyEmail();

  const { errors, control, handleSubmit } = useForm<IEmailFormInput>({
    resolver: yupResolver(schema),
  });

  const [verified, setVerified] = React.useState<boolean | undefined>(
    undefined
  );

  const mutateVerifyRegistration = useMutation(
    () => UserService.verifyRegistration(params),
    {
      onSuccess: (response) => {
        snackbarHandler.success(response?.data?.detail);
        setVerified(true);
        history.push(RouterConstants.USER.SIGN_IN);
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);
        setVerified(false);
      },
    }
  );

  React.useEffect(() => {
    mutateVerifyRegistration.mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mutateResendVerification = useMutation(
    (newData: IEmailFormInput) => UserService.resendVerification(newData),
    {
      onSuccess: (response, credentials) => {
        history.push(
          `${RouterConstants.USER.REGISTER_EMAIL}?email=${credentials.email}`
        );
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);
        setVerified(false);
      },
    }
  );

  const onSubmit = (data: IEmailFormInput) => {
    mutateResendVerification.mutate(data);
  };

  const content = verified ? (
    <>
      <Box sx={{ textAlign: "center" }}>
        <Typography variant="h6" paragraph>
          Email address was successfully verified.
        </Typography>
        <Typography variant="body2" component="p">
          You can now sign in to your user account.
        </Typography>
      </Box>
      <Box sx={{ mt: 8, textAlign: "center" }}>
        <Button
          color="primary"
          variant="contained"
          component={Link}
          to={RouterConstants.USER.SIGN_IN}
        >
          Go to Sign In
        </Button>
      </Box>
    </>
  ) : (
    <>
      <Box sx={{ textAlign: "center" }}>
        <Box mb={6}>
          <Typography variant="h6" paragraph>
            Email address failed to verify.
          </Typography>

          <form onSubmit={handleSubmit(onSubmit)}>
            <ElementField
              InputProps={{
                fullWidth: true,
                id: "email",
                label: "Email address",
                variant: "outlined",
              }}
              name="email"
              control={control}
              error={errors.email}
            />
            <Button color="primary" variant="contained" type="submit">
              Resend verification email
            </Button>
          </form>
        </Box>

        <Typography variant="body2" component="p" marginBottom={-4}>
          If you need help with email verification, reach out to support.
        </Typography>
      </Box>
      <Box sx={{ mt: 8, textAlign: "center" }}></Box>
    </>
  );

  return (
    <AuthForm disableForm title="">
      <Card sx={{ my: -8 }}>
        <CardContent>
          {verified === undefined ? (
            <Loader text="Verifying your email account." />
          ) : (
            content
          )}
        </CardContent>
      </Card>
    </AuthForm>
  );
};

export default VerifyRegistration;
