import List from "@mui/material/List";
import { Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { formatDateToPretty } from "core/utils/dateHandler";
import { AccountContext } from "modules/Account/context";
import { IInteraction } from "modules/Interaction/models";
import React from "react";
import Loader from "ui-kit/components/Loader";
import InteractionContainerItem from "../InteractionContainerItem";
import InteractionContainerProfile from "../InteractionContainerProfile";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    height: "100%",
  },
  chat: {
    overflow: "hidden",
    width: "100%",
    position: "relative",
  },
  inbox: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: theme.app.palette.action.hoverBackground,
    height: theme.app.constants.campaign.interactions.inboxHeight,
  },
  list: {
    padding: 0,
    overflowY: "scroll",
    height: "100%",
    boxShadow:
      "inset 0px 8px 8px -8px rgba(0,0,0,0.05), inset 0px -8px 8px -8px rgba(0,0,0,0.05)",
    // ...theme.app.constants.scrollbar,
  },
  groupDate: {
    textAlign: "center",
    width: "100%",
    color: theme.app.palette.action.color,
    fontSize: 12.5,
    fontWeight: 500,
    margin: theme.spacing(2, 0),
  },
}));

interface InteractionSearchResultViewProps {
  accountId: number;
  interaction: IInteraction;
  handleBacklink: () => void;
}

const InteractionSearchResultView = ({
  accountId,
  interaction,
  handleBacklink,
}: InteractionSearchResultViewProps): React.ReactElement | null => {
  const classes = useStyles();

  const {
    account: { person: accountPerson },
  } = React.useContext(AccountContext);

  if (!accountPerson) {
    return <Loader minHeight={300} />;
  }

  return (
    <div className={classes.root}>
      <div className={classes.chat}>
        <InteractionContainerProfile
          personId={0}
          interaction={interaction}
          showSidebar={false}
          handleToggleSidebar={() => undefined}
          disableSidebar
          handleBacklink={handleBacklink}
        />
        <div className={classes.inbox}>
          <List data-cy="interaction-menu" className={classes.list}>
            <Typography className={classes.groupDate}>
              {formatDateToPretty(interaction?.platform_created_at)}
            </Typography>
            <InteractionContainerItem
              interaction={interaction}
              key={interaction.id}
              accountPerson={accountPerson}
            />
          </List>
        </div>
      </div>
    </div>
  );
};

export default InteractionSearchResultView;
