import React from "react";
import ConfigConstant from "core/constants/ConfigConstant";
import Table from "ui-kit/components/Table";
import { BLACKLIST_PROPS } from "modules/AddressBook/components/AddressBookDetail/component";
import CreateAccountAddressBook from "modules/AddressBook/components/CreateAccountAddressBook";

interface CreateAddressBookProps {
  open: boolean;
  handleClose: () => void;
  toggleOpen: (open: boolean) => void;
}

const CreateAddressBook = ({
  open,
  handleClose,
  toggleOpen,
}: CreateAddressBookProps): React.ReactElement => {
  const [page, setPage] = React.useState<number>(ConfigConstant.INITIAL_PAGE);
  return (
    <>
      <Table
        title={BLACKLIST_PROPS.title}
        subtitle={BLACKLIST_PROPS.subtitle}
        heads={BLACKLIST_PROPS.heads}
        rows={[]}
        count={0}
        // INITIAL_PAGE starts at 1, but Pagination starts at 0
        page={page - 1}
        setPage={setPage}
        isFetching={false}
        actionPropsIfEmpty={{
          text: "Add people to blacklist",
          onClick: () => toggleOpen(true),
        }}
        rowsPerPage={BLACKLIST_PROPS.rowsPerPage}
      />
      <CreateAccountAddressBook open={open} handleClose={handleClose} />
    </>
  );
};

export default CreateAddressBook;
