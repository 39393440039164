import InteractionService from "modules/Interaction/services";
import React from "react";
import { useQuery } from "react-query";
import ProgressBar, {
  BaseProgressBarProps,
} from "ui-kit/components/ProgressBar";

interface CampaignProgressProps extends BaseProgressBarProps {
  campaignId: number;
}

const CampaignProgress = ({
  campaignId,
  ...rest
}: CampaignProgressProps): React.ReactElement => {
  const fetchCampaignTotalDistinctInteractionsCount = async () => {
    try {
      const { data } =
        await InteractionService.fetchCampaignTotalDistinctInteractionsCount(
          campaignId
        );
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };

  const { data: dataTotal } = useQuery(
    ["interactions-distinct", campaignId],
    () => fetchCampaignTotalDistinctInteractionsCount(),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  const fetchCampaignProcessedDistinctInteractions = async () => {
    try {
      const { data } =
        await InteractionService.fetchCampaignProcessedDistinctInteractions(
          campaignId
        );
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };

  const { data: dataCount } = useQuery(
    ["interactions-processed", campaignId],
    () => fetchCampaignProcessedDistinctInteractions(),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  const isLoading = !(dataTotal && dataCount);

  return (
    <ProgressBar
      count={dataCount?.count}
      total={dataTotal?.count}
      isLoading={isLoading}
      {...rest}
    />
  );
};

export default CampaignProgress;
