import React from "react";
import { useQuery } from "react-query";
import Box from "@mui/material/Box";
import AccountService from "modules/Account/services";
import { AccountContext } from "modules/Account/context";
import { getAccountPlaceholders } from "modules/Placeholder/utils";
import PlaceholderButton from "modules/Action/components/DraftEditor/components/PlaceholderButton";
import { PlaceholderMenuProps } from "modules/Action/models";
import { AccountQuery } from "modules/Account/models";

interface AccountPlaceholderMenuProps {
  handleOnSelect: PlaceholderMenuProps["handleOnSelect"];
}

const AccountPlaceholderMenu = ({
  handleOnSelect,
}: AccountPlaceholderMenuProps): React.ReactElement => {
  const {
    account: { id: accountId },
  } = React.useContext(AccountContext);

  const { data } = useQuery(
    [AccountQuery.account, accountId],
    async () => {
      try {
        const response = await AccountService.fetchAccount(accountId);
        return response.data;
      } catch (err) {
        throw new Error(String(err));
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  const accountPlaceholders = data?.template_placeholders
    ? getAccountPlaceholders(data.template_placeholders)
    : [];

  return (
    <Box
      sx={{
        display: "inline-block",
      }}
    >
      {accountPlaceholders.map(({ id, name }) => (
        <PlaceholderButton
          key={id}
          label={id}
          handleOnClick={() => handleOnSelect({ id, name })}
        />
      ))}
    </Box>
  );
};

export default AccountPlaceholderMenu;
