import React from "react";
import Typography, { TypographyProps } from "@mui/material/Typography";
import Box, { BoxProps } from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import HelperTooltip, { HelperTooltipProps } from "./HelperTooltip";
import HelperInfo, { HelperInfoProps } from "./HelperInfo";

const useStyles = makeStyles((theme: Theme) => ({
  body: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      alignItems: "center",
    },
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
}));

export interface PaperHeaderProps {
  title?: string | React.ReactElement;
  titleProps?: TypographyProps;
  titleContainerProps?: BoxProps["sx"];
  body?: string | React.ReactElement;
  bodyProps?: BoxProps["sx"];
  helperProps?: HelperTooltipProps;
  helperInfo?: HelperInfoProps;
  boxStyles?: BoxProps["sx"];
}

const PaperHeader = ({
  title,
  titleProps,
  titleContainerProps = {},
  body,
  bodyProps = {},
  helperProps,
  helperInfo,
  boxStyles = {},
}: PaperHeaderProps): React.ReactElement => {
  const classes = useStyles();

  return (
    <Box sx={{ pb: 2, ...boxStyles }}>
      {title && (
        <Box
          sx={{
            display: "flex",
            alignItems: "baseline",
            ...titleContainerProps,
          }}
        >
          <Typography
            mb={2.75}
            {...titleProps}
            variant={titleProps?.variant || "h6"}
          >
            {title}
          </Typography>
          {/* {helperInfo && <HelperInfo {...helperInfo} />} */}
        </Box>
      )}

      {body && (
        <Box className={classes.body} sx={{ mb: 4, ...bodyProps }}>
          <Typography color="textSecondary" variant="body2">
            {body}
          </Typography>
          {helperProps && <HelperTooltip {...helperProps} />}
        </Box>
      )}
    </Box>
  );
};

PaperHeader.defaultProps = {
  title: "",
  body: "",
  helperProps: undefined,
};

export default PaperHeader;
