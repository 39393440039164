import Skeleton from "@mui/material/Skeleton";
import { Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/styles";
import { getPercentage } from "modules/Report/utils";
import React from "react";

interface InteractionCompareLabelProps {
  isLoading: boolean;
  count: number | undefined;
  total: number | undefined;
  disable?: boolean;
}

const InteractionCompareLabel = ({
  isLoading,
  count = 0,
  total = 0,
  disable,
}: InteractionCompareLabelProps): React.ReactElement => {
  const theme: Theme = useTheme();

  if (disable) {
    return <>-</>;
  }

  if (isLoading) {
    return <Skeleton width={20} />;
  }

  return (
    <>
      <Typography
        variant="body2"
        color="textSecondary"
        component="span"
        sx={{ mr: 1, fontSize: "0.85rem" }}
      >
        {getPercentage(count, total)}
      </Typography>
      <Typography
        variant="body2"
        component="span"
        sx={{ color: theme.app.palette.action.color, fontSize: "0.85rem" }}
      >
        ({count})
      </Typography>
    </>
  );
};

export default InteractionCompareLabel;
