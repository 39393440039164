interface IContentProps {
  id: number | null;
  key: string;
  value: string;
}

export interface TableCellProps {
  content:
    | IContentProps
    | React.ReactElement
    | string
    | number
    | Date
    | null
    | undefined;
  payload: string | null | (() => void);
  type: "main" | "default" | "element" | "internal" | "action" | "hidden";
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: React.FunctionComponent<any>;
}

export function createTableCell(
  content: TableCellProps["content"],
  payload: TableCellProps["payload"],
  type: TableCellProps["type"],
  component: TableCellProps["component"]
): TableCellProps {
  return { content, payload, type, component };
}
