import axios, { AxiosResponse } from "axios";
import {
  ISignInInput,
  ISignUpValues,
  IVerifyRegistrationInput,
  IUser,
  IUserProfileInput,
  IPasswordChange,
  IResetPasswordFormValues,
  IEmailFormInput,
  ISignInResponse,
  ISocialUserFormInput,
  IUserLocation,
} from "../models";

const signUp = (data: ISignUpValues): Promise<AxiosResponse<IUser>> =>
  axios.post("/users/registration/", data);

const signIn = (data: ISignInInput): Promise<AxiosResponse<ISignInResponse>> =>
  axios.post("/users/login/", data);

// Returns just current user if logged in
const fetchExistingUsers = (): Promise<AxiosResponse<IUser>> =>
  axios.get("/users/");

const verifyRegistration = (
  data: IVerifyRegistrationInput
): Promise<AxiosResponse<{ detail: string }>> =>
  axios.post("/users/registration/verify-email/", data);

const verifyEmail = (
  data: IVerifyRegistrationInput
): Promise<AxiosResponse<{ detail: string }>> =>
  axios.post("/users/verify-email/", data);

const resendVerification = (
  data: IEmailFormInput
): Promise<AxiosResponse<{ detail: string }>> =>
  axios.post("/users/registration/resend-email/", data);

const forgotPassword = (
  data: IEmailFormInput
): Promise<AxiosResponse<IEmailFormInput>> =>
  axios.post("/users/password/reset/", data);

const fetchProfile = (userId: number): Promise<AxiosResponse<IUser>> =>
  axios.get(`/users/${userId}`);

const verifyUserMe = (): Promise<AxiosResponse<IUser>> =>
  axios.get(`/users/me`);

const updateProfile = (
  id: number,
  data: IUserProfileInput
): Promise<AxiosResponse<IUser>> => {
  return axios.patch(`/users/${id}`, data);
};

const changePassword = (
  id: undefined, // SettingsItem general call,
  data: IPasswordChange
): Promise<AxiosResponse<IUser>> => axios.post("/users/password/change/", data);

const updateEmailAddress = (
  id: undefined, // SettingsItem general call,
  data: IEmailFormInput
): Promise<AxiosResponse<{ detail: string }>> =>
  axios.post("/users/register-email/", data);

const resetPassword = (
  data: IResetPasswordFormValues
): Promise<AxiosResponse<IUser>> =>
  axios.post("/users/password/reset/confirm/", data);

const createLogout = (): Promise<AxiosResponse<IUser>> =>
  axios.post("/users/logout/");
// axios.post("/users/logout/", { revoke_token: true });

const createSocialUser = (
  data: ISocialUserFormInput
): Promise<AxiosResponse<ISignInResponse>> =>
  axios.post("/users/google/", data);

const fetchUserLocation = (): Promise<AxiosResponse<IUserLocation>> =>
  axios.get("/users/ip_location/");

const UserService = {
  signUp,
  signIn,
  fetchExistingUsers,
  verifyRegistration,
  verifyEmail,
  forgotPassword,
  fetchProfile,
  verifyUserMe,
  updateProfile,
  changePassword,
  updateEmailAddress,
  createLogout,
  resetPassword,
  resendVerification,
  createSocialUser,
  fetchUserLocation,
};

export default UserService;
