import axios, { AxiosResponse } from "axios";
import { IAccounts } from "modules/Account/models";
import { ICompany, ICompanies, ICompanyFormValues } from "../models";

const createCompany = (
  data: ICompanyFormValues
): Promise<AxiosResponse<ICompany>> => {
  return axios.post("/authorized/groups/", data);
};

const fetchAllCompanies = (): Promise<AxiosResponse<ICompanies>> => {
  return axios.get(`/authorized/groups/?page=1&page_size=1000&family=CO`);
};

const fetchCompanies = (page: number): Promise<AxiosResponse<ICompanies>> => {
  return axios.get(`/authorized/groups/?page=${page}&family=CO`);
};

const fetchCompany = (id: number): Promise<AxiosResponse<ICompany>> => {
  return axios.get(`/authorized/groups/${id}/`);
};

const fetchCompanyAccounts = (
  companyId: number,
  page: number
): Promise<AxiosResponse<IAccounts>> => {
  return axios.get(
    `/inevitable/accounts/?page=${page}&company_id=${companyId}`
  );
};

const updateCompany = (
  id: number,
  data: ICompany
): Promise<AxiosResponse<ICompany>> => {
  return axios.patch(`/authorized/groups/${id}/`, data);
};

const deleteCompany = (id: number): Promise<AxiosResponse<ICompany>> => {
  return axios.delete(`/authorized/groups/${id}/`);
};

const CompanyService = {
  createCompany,
  fetchCompany,
  fetchAllCompanies,
  fetchCompanies,
  fetchCompanyAccounts,
  updateCompany,
  deleteCompany,
};

export default CompanyService;
