import createTheme from "./base-theme/createTheme";
import createPalette from "./base-theme/createPalette";

export default createTheme({
  name: "appTheme",
  palette: createPalette({
    primaryBrand: {
      dark: "#1A1F36",
      main: "#2A3959",
      light: "#001da6",
    },
    secondaryBrand: {
      dark: "#cdd7fd",
      main: "#e6eaff",
    },
    grey: {
      1: "#434B52",
      2: "#798199",
      3: "#ebeef1",
      4: "#eff2f5",
      5: "#f8f9fb",
      6: "rgb(243, 248, 251)",
    },
  }),
});
