import React from "react";
import Typography from "@mui/material/Typography";
import MuiSlider, { SliderProps } from "@mui/material/Slider";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

const generateMarks = (marks: number[]) => {
  return marks.map((mark) => ({ value: mark, label: String(mark) }));
};

export enum SliderVariant {
  toValue = "toValue",
  fromValue = "fromValue",
}

interface IControllerProps {
  value: number | number[];
  onChange: (v: number | number[]) => void;
  name: string;
  label: string | React.ReactElement;
  ElementProps?: {
    min?: number;
    max?: number;
    marks?: number[];
    maxDistance?: number;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component?: React.FunctionComponent<any>;
    disabled?: boolean;
    alert?: {
      component: React.ReactElement;
      count: number;
      variant: SliderVariant;
    };
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3, 0),
  },
  slider: {
    maxWidth: 300,
    width: "100%",
    margin: theme.spacing(10, 1, 2),
  },
}));

const validateValue = (
  count: number | undefined,
  value: SliderProps["value"],
  variant: SliderVariant | undefined
) => {
  if (!value || !count) return false;
  if (Array.isArray(value)) {
    const [from, to] = value;
    if (variant === SliderVariant.toValue) {
      return count < to;
    }
    if (variant === SliderVariant.fromValue) {
      return count > from;
    }
  }
  return count > value;
};

const SliderField = React.forwardRef<HTMLDivElement, IControllerProps>(
  function TextField(
    { value, onChange, name, label, ElementProps }: IControllerProps,
    ref
  ): React.ReactElement {
    const classes = useStyles();
    const SliderComponent = ElementProps?.component || MuiSlider;

    return (
      <div className={classes.root}>
        {ElementProps?.alert &&
          validateValue(
            ElementProps?.alert?.count,
            value,
            ElementProps?.alert?.variant
          ) &&
          ElementProps?.alert?.component}
        {label && (
          <Typography id={name} gutterBottom variant="subtitle2">
            {label}
          </Typography>
        )}
        <SliderComponent
          ref={ref}
          className={classes.slider}
          aria-labelledby={name}
          value={value}
          color="secondary"
          onChange={(_, v) => {
            onChange(v);
          }}
          disabled={ElementProps?.disabled}
          {...(ElementProps?.marks && {
            marks: generateMarks(ElementProps.marks),
          })}
          {...(ElementProps?.maxDistance && {
            maxDistance: ElementProps.maxDistance,
          })}
          min={ElementProps?.min}
          max={ElementProps?.max}
          step={1}
          valueLabelDisplay="on"
        />
      </div>
    );
  }
);

SliderField.defaultProps = {
  ElementProps: { max: 100, min: 1, marks: [] },
};

export default SliderField;
