import React from "react";
import reactStringReplace from "react-string-replace";
import { createPlaceholderName } from "modules/Placeholder/utils";
import { usePlaceholderStyles } from "modules/Action/components/DraftEditor/components/PlaceholderComponent";

interface PlaceholderTemplateProps {
  match: string;
}

const PlaceholderTemplate = ({
  match,
}: PlaceholderTemplateProps): React.ReactElement => {
  const classes = usePlaceholderStyles();
  return <span className={classes.root}>{createPlaceholderName(match)}</span>;
};

export const interpolatePlaceholders = (
  context: string | undefined
): React.ReactNodeArray | undefined => {
  if (!context) {
    return undefined;
  }
  return reactStringReplace(context, /{{(.*?)}}/g, (match, i) => (
    <PlaceholderTemplate key={String(i)} match={match} />
  ));
};

export default PlaceholderTemplate;
