import StrategyConstant from "core/constants/StrategyConstant";
import {
  dayjs,
  formatDateToStr,
  formatDateWithFormatString,
  formatDayjsToStr,
  getCurrentTimezoneDate,
  getTimezoneDate,
  startOfTimezoneDay,
} from "core/utils/dateHandler";
import { Dayjs } from "dayjs";
import { inRange } from "lodash";
import times from "lodash/times";
import { IAccountRestrictionRange } from "modules/Account/models";
import { IInteraction, IInteractionStatItem } from "modules/Interaction/models";
import InteractionService from "modules/Interaction/services";
import {
  DateRangesQuery,
  IChartValues,
  ICountReportItem,
  IDatasetItem,
  IDateStringRange,
  IDateReport,
  IDayjsRange,
  ILimitItem,
  ILocaleProps,
  IReportItem,
  LimitActionDefaultValues,
  LimitActionNames,
  IReportContext,
} from "modules/Report/models";
import ReportConstants from "../constants";
import { defaultDates } from "../defaultRanges";

const createReport = (
  name: IReportItem["name"],
  label: IReportItem["label"],
  func: IReportItem["func"]
): IReportItem => ({
  name,
  label,
  func,
});

const createCountReport = (
  name: ICountReportItem["name"],
  label: ICountReportItem["label"],
  func: ICountReportItem["func"]
): ICountReportItem => ({
  name,
  label,
  func,
});

const createLimit = (
  name: ILimitItem["name"],
  label: ILimitItem["label"],
  func: ILimitItem["func"],
  limit: ILimitItem["limit"],
  icon: ILimitItem["icon"],
  color: ILimitItem["color"],
  restrictionKey: ILimitItem["restrictionKey"]
): ILimitItem => ({
  name,
  label,
  func,
  limit,
  icon,
  color,
  restrictionKey,
});

const LIMITS_ARR = [
  createLimit(
    LimitActionNames.invitation,
    "Invited to connect",
    InteractionService.fetchRequestCount,
    "connection_requests_daily_range",
    StrategyConstant.STRATEGY_OBJ.connection_request.icon,
    StrategyConstant.STRATEGY_OBJ.connection_request.color,
    "connection_requests"
  ),
  createLimit(
    LimitActionNames.message,
    "Messages sent",
    InteractionService.fetchMessagedCount,
    "follow_up_messages",
    StrategyConstant.STRATEGY_OBJ.message.icon,
    StrategyConstant.STRATEGY_OBJ.message.color,
    "follow_up_messages"
  ),
  createLimit(
    LimitActionNames.profile,
    "Profiles viewed",
    InteractionService.fetchProfilesCount,
    undefined,
    StrategyConstant.STRATEGY_OBJ.profile_view.icon,
    StrategyConstant.STRATEGY_OBJ.profile_view.color,
    undefined
  ),
];

const getLimitText = (
  v: IAccountRestrictionRange | undefined,
  name: LimitActionNames
): string => {
  if (!v) return LimitActionDefaultValues[name];
  return `${v.start} - ${v.end}`;
};

const getLimitDatasetData = (item: IDatasetItem): number[] => {
  // 4 colors as the chart: 3 action colors + disabled
  const data = [0, 0, 0, 0];

  // Check if there is restriction generated
  // We pre-generate these for each account on the first active outreach
  if (item.restriction) {
    data[item.total] = item.restriction - item.count;
  }

  // If there isn't restriction generated
  // Fallback to max. limits
  if (!item.restriction) {
    // Max restrictions - low/high
    const [low, high] = item.range;

    // Check if current count in the max restrictions
    const value = inRange(item.count, low, high);
    if (!value) {
      data[item.total] = low - item.count;
    }
  }

  data[item.index] = item.count;
  return data;
};

const createDateRange = ({ start, end }: IDateStringRange): IDayjsRange => ({
  start: dayjs(start).tz().startOf("day"),
  end: dayjs(end).tz().endOf("day"),
});

const getDefaultRange = (
  date: Dayjs,
  defaultRange: IReportContext["defaultRange"]
): IDateStringRange => {
  const mainRange = {
    start: formatDayjsToStr(date.subtract(7, "day")),
    end: formatDayjsToStr(date),
  };

  if (!!defaultRange) {
    const range = defaultDates[defaultRange];

    if (!!range) {
      return {
        start: formatDayjsToStr(range.startDate || date),
        end: formatDayjsToStr(range.endDate || date),
      };
    }

    return mainRange;
  }

  return mainRange;
};

const getDateRangeQuery = ({ start, end }: IDayjsRange): string =>
  `${DateRangesQuery.gt}=${start.toISOString()}&${
    DateRangesQuery.lt
  }=${end.toISOString()}`;

const getTodayRangeQuery = (): string =>
  `${DateRangesQuery.gt}=${startOfTimezoneDay().toISOString()}`;

const getDateRangeString = (start: Date | undefined, end: Date | undefined) => {
  if (!start || !end) {
    return "";
  }
  return `start=${formatDateToStr(start)}&end=${formatDateToStr(end)}`;
};

const getChunks = (
  count: number,
  unit: "hour" | "day" | "month",
  date: Dayjs
): IDayjsRange[] => {
  const chunks: IDayjsRange[] = [];
  let currentDate = date;
  times(count, () => {
    const start = currentDate;
    currentDate = start.add(1, unit);
    chunks.push({ start, end: currentDate });
  });
  return chunks;
};

const daysInMonth = 31;
const daysInYear = 365;

const getRangeDiffUnit = (diff: number) => {
  if (diff === 0) {
    return "hour";
  }
  if (diff >= daysInMonth) {
    return "month";
  }
  return "day";
};

const getDateChunks = (
  { start, end }: IDayjsRange,
  diff: number
): IDayjsRange[] => {
  if (diff === 0) {
    return getChunks(24, "hour", start);
  }
  if (diff > daysInMonth && diff < daysInYear) {
    return getChunks(Math.ceil(diff / daysInMonth), "month", start);
  }
  if (diff > daysInYear) {
    return getChunks(12, "month", start);
  }
  return getChunks(diff + 1, "day", start);
};

const getLocale = (diff: number): ILocaleProps => {
  let sameDay = "[Today]";
  let lastDay = "[Yesterday]";
  let lastWeek = "MMM D";
  let sameElse = "MMM D";

  if (diff === 0) {
    sameDay = "[Today], h:mm A";
    lastDay = "[Yesterday], h:mm A";
  } else if (diff > 1 && diff <= daysInMonth) {
    sameDay = "MMM D";
    lastDay = "MMM D";
  } else if (diff > daysInMonth) {
    sameDay = "MMM YYYY";
    lastDay = "MMM YYYY";
    lastWeek = "MMM YYYY";
    sameElse = "MMM YYYY";
  }

  return {
    sameDay,
    lastDay,
    lastWeek,
    sameElse,
  };
};

// const getDateLabels = (labels: IDateStringRange[], diff: number): string[] => {
//   const locale = getLocale(diff);
//   return labels.map(({ start }) => {
//     const date = start;
//     return formatDateWithFormatString(date, locale);
//   });
// };

const getDateLabels = (labels: IDayjsRange[], diff: number): string[] => {
  const locale = getLocale(diff);
  return labels.map(({ start }) => formatDateWithFormatString(start, locale));
};

const generateValues = (
  items: IInteraction[] | undefined,
  date: IDateReport
): IChartValues => {
  const newValues: IChartValues = [];
  const currentTimezoneDate = getCurrentTimezoneDate();

  if (!items) {
    return newValues;
  }

  // Check if in the past and generate value
  const diffUnit = getRangeDiffUnit(date.diff);

  let isAfterNow = false;
  date.chunks.forEach(({ start, end }) => {
    // If isAfterNow - no need to generate values
    if (isAfterNow) {
      return newValues.push(null);
    }

    if (start.isSameOrBefore(currentTimezoneDate, diffUnit)) {
      const valuesInChunk = items.filter((item: IInteraction) => {
        const createdAt = getTimezoneDate(item.platform_created_at);
        return start < createdAt && createdAt < end;
      });
      return newValues.push(valuesInChunk.length);
    }

    // Update isAfter now if later then now. No need to generate values
    isAfterNow = true;
    return newValues.push(null);
  });
  return newValues;
};

const getValues = (total: IChartValues, amount: IChartValues): IChartValues => {
  if (!total || !amount) {
    return [0];
  }

  return total?.map((count, index) => {
    const amountCount = amount[index];
    if (count === 0 || count === null) {
      return 0;
    }
    if (amountCount === 0 && count > 0) {
      return 1;
    }
    return Number(count) / Number(amountCount);
  });
};

const formatPercentage = (n: number | string): string => `${n}%`;

const getFilledPercentage = (
  count: number | undefined,
  total: number | undefined
): string => {
  if (
    count === undefined ||
    total === undefined ||
    count === 0 ||
    total === 0
  ) {
    return "0%";
  }

  return formatPercentage((count / total) * 100);
};

function getPercentage(
  number1: number | undefined, // amount
  number2: number | undefined // total
): string | undefined {
  let value = 0;
  if (number1 && number2) {
    value = Math.ceil((number1 / number2) * 100);
  }
  // Don't go over 100% for rates
  if (value > 100) {
    value = 100;
  }
  return formatPercentage(value);
}

function isLastInRow(index: number, itemsInRow: number): boolean {
  return (index + 1) % itemsInRow === 0;
}

function isFirstInRow(index: number, itemsInRow: number): boolean {
  return (index + 1) % itemsInRow === 1;
}

function isLastLine(index: number, total: number, itemsInRow: number): boolean {
  const lastLineItemCount = total % itemsInRow || itemsInRow;
  return index + 1 > total - lastLineItemCount;
}

const getNthWord = (str: string, n: number): string => {
  const words = str.toLowerCase().split(" ");
  return words[n - 1];
};

const storeReport = (report?: IReportContext["defaultRange"]): void => {
  if (!report) {
    localStorage.removeItem(ReportConstants.STORAGE_KEY);
    return;
  }
  if (!window.localStorage) return;
  localStorage.setItem(ReportConstants.STORAGE_KEY, JSON.stringify(report));
};

const getReport = (): IReportContext["defaultRange"] | undefined => {
  const storedReport =
    window &&
    window.localStorage &&
    localStorage.getItem(ReportConstants.STORAGE_KEY);
  if (storedReport) return JSON.parse(storedReport);
  return undefined;
};

const getStatChunkCount = (x: IInteractionStatItem["chunks"]) =>
  Object.values(x);

export {
  createReport,
  createCountReport,
  createLimit,
  getLimitText,
  createDateRange,
  getDateRangeQuery,
  getDateChunks,
  getDateLabels,
  generateValues,
  getPercentage,
  formatPercentage,
  getFilledPercentage,
  getTodayRangeQuery,
  getValues,
  isFirstInRow,
  isLastInRow,
  isLastLine,
  getNthWord,
  getLimitDatasetData,
  getDateRangeString,
  LIMITS_ARR,
  storeReport,
  getReport,
  getDefaultRange,
  getStatChunkCount,
};
