import { CountryCodes } from "core/models";

const COUNTRY_CODES = Object.entries(CountryCodes).map(([id, name]) => ({
  id,
  name,
}));

const matchCountry = (countryCode: string | undefined) => {
  if (!countryCode) {
    return undefined;
  }
  return COUNTRY_CODES.find((x) => {
    return x.id === countryCode.toLowerCase();
  });
};

export { COUNTRY_CODES, matchCountry };
