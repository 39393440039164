import React from "react";
import Paper from "@mui/material/Paper";
import CardContent from "@mui/material/CardContent";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Loader from "ui-kit/components/Loader";
import PaperHeader from "ui-kit/components/PaperHeader";
import Button from "ui-kit/atoms/Button";

interface SearchInfoProps {
  title: string;
  body: string | React.ReactElement;
  loader?: boolean;
  SubmitButton?: {
    value: string;
  };
  customAction?: React.ReactElement;
}

const SearchInfo = ({
  title,
  body,
  loader,
  SubmitButton,
  customAction,
}: SearchInfoProps): React.ReactElement => {
  return (
    <Paper>
      <CardContent>
        <PaperHeader title={title} body={body} />
        {loader && <Loader minHeight={100} />}
        {customAction}
        {SubmitButton && (
          <Button
            variant="contained"
            color="primary"
            type="submit"
            endIcon={<ArrowForwardIcon />}
          >
            {SubmitButton?.value}
          </Button>
        )}
      </CardContent>
    </Paper>
  );
};

export default SearchInfo;
