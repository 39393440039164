import React from "react";
import { useQuery } from "react-query";
import { Link, useLocation } from "react-router-dom";
import clsx from "clsx";
import { makeStyles, useTheme } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import RouterConstants from "core/routes/constants";
import RouterUtils from "core/routes/utils";
import ConfigConstant from "core/constants/ConfigConstant";
import PersonService from "modules/Person/services";
import { cleanPersonDetail, getPersonName } from "modules/Person/utils";
import { AccountContext } from "modules/Account/context";
import PersonAvatar from "../PersonAvatar";

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    padding: theme.spacing(2),
  },
  input: {
    marginBottom: theme.spacing(0),
  },
  more: {
    padding: theme.spacing(4, 4, 2),
  },
  list: {
    padding: theme.spacing(0),
  },
  avatar: {
    minWidth: 42,
  },
  item: {
    padding: theme.spacing(3, 2.5, 3, 3.5),
    "&.Mui-selected": {
      paddingLeft: theme.spacing(2.75),
      borderLeft: `3px solid ${theme.palette.primary.main}`,
    },
  },
  subText: {
    color: theme.app.palette.action.placeholder,
    fontSize: 11,
    width: 220,
    "& span": {
      fontWeight: 500,
      color: theme.app.palette.action.color,
    },
  },
  ellipsis: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  noResults: {
    padding: theme.spacing(3, 4),
  },
}));

interface SearchPersonProps {
  personId: number | null;
}

const SearchPerson = ({ personId }: SearchPersonProps): React.ReactElement => {
  const classes = useStyles();
  const theme: Theme = useTheme();

  const { account } = React.useContext(AccountContext);

  const location = useLocation();
  const { search } = RouterUtils.getQueryParams(location);

  const fetchPersons = async () => {
    try {
      const { data } = await PersonService.fetchPersons(
        account.person?.id,
        ConfigConstant.INITIAL_PAGE,
        search
      );
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };

  const { data } = useQuery(["person-search", search], () => fetchPersons(), {
    enabled: Boolean(search),
    keepPreviousData: true,
  });

  return (
    <>
      {!!search && (
        <>
          {data?.results.length !== 0 && (
            <Typography
              className={classes.more}
              color="textSecondary"
              variant="subtitle2"
            >
              All people
            </Typography>
          )}
          <List data-cy="person-results" className={classes.list}>
            {data
              ? data.results.map((person, index) => {
                  const contactName = getPersonName(person);
                  const isSelected = person.id === personId;
                  return (
                    <ListItem
                      button
                      selected={isSelected}
                      divider={index !== data.results.length - 1}
                      to={RouterConstants.INBOX.detail(person.id)}
                      component={Link}
                      key={person.id}
                      className={classes.item}
                    >
                      <ListItemAvatar className={classes.avatar}>
                        <PersonAvatar
                          src={person.image_base64}
                          size="small"
                          name={contactName}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={contactName}
                        primaryTypographyProps={{
                          variant: "body2",
                          color: "textPrimary",
                          className: classes.ellipsis,
                        }}
                        secondary={cleanPersonDetail(person.occupation)}
                        secondaryTypographyProps={{
                          variant: "caption",
                          color: theme.app.palette.action.color,
                          className: clsx(classes.subText, classes.ellipsis),
                        }}
                      />
                    </ListItem>
                  );
                })
              : []}
          </List>
        </>
      )}
    </>
  );
};

export default SearchPerson;
