import ProfileDetail from "core/components/ProfileDetail";
import { IProfileClicked } from "core/models";
import { createTableCell } from "core/utils/tableHandler";
import get from "lodash/get";
import compact from "lodash/compact";
import flatten from "lodash/flatten";
import uniq from "lodash/uniq";
import { ICampaignSearches } from "modules/Campaign/models";
import { IInteractions } from "modules/Interaction/models";
import ExternalProfile from "modules/Person/components/ExternalProfile";
import SearchUtils from "modules/Search/utils";
import React from "react";
import Table, { TableProps, TableVariant } from "ui-kit/components/Table";
import TableBodyCell from "ui-kit/components/TableBodyCell";
import InteractionActions from "../InteractionActions";

const pageSize = 20;
const title = "Prospects";

interface GoogleSheetInteractionListProps {
  data: IInteractions | undefined;
  searchData: ICampaignSearches;
  isLoading: TableProps["isLoading"];
  isFetching: TableProps["isFetching"];
  page: TableProps["page"];
  setPage: TableProps["setPage"];
  dataClicked: IProfileClicked | undefined;
  handleClose: () => void;
  handleClicked: (n: IProfileClicked) => void;
  campaignId: number;
  filters: React.ReactElement;
}

const GoogleSheetInteractionList = ({
  data,
  searchData,
  isLoading,
  isFetching,
  page,
  setPage,
  dataClicked,
  handleClose,
  handleClicked,
  campaignId,
  filters,
}: GoogleSheetInteractionListProps): React.ReactElement | null => {
  const uniqColumns = SearchUtils.getColumnsFromCampaignSearches(searchData);
  const columns = SearchUtils.generateHeads(uniqColumns);
  const customColumns = SearchUtils.getCustomHeads(columns);
  const heads = SearchUtils.formatHeads(columns);

  return (
    <Table
      title={title}
      heads={heads}
      filters={filters}
      rows={
        data?.results
          ? data.results?.map((interaction) => {
              const handleOnClick = () => {
                const newDataClicked = interaction.person
                  ? {
                      personId: interaction?.person?.id,
                      contactId: interaction?.contact?.id,
                    }
                  : { searchResultId: interaction.search_result?.id };
                handleClicked({
                  ...newDataClicked,
                  connection: interaction.contact?.active_connection,
                });
              };

              const cellProps = {
                person: interaction.person,
                searchResult: interaction.search_result,
              };

              return {
                name: interaction?.id.toString(),
                data: [
                  createTableCell(
                    <ExternalProfile {...cellProps} />,
                    handleOnClick,
                    "main",
                    TableBodyCell
                  ),
                  ...customColumns.map((column) => {
                    return createTableCell(
                      get(interaction, `search_result.placeholders.${column}`),
                      handleOnClick,
                      "default",
                      TableBodyCell
                    );
                  }),
                  createTableCell(
                    <InteractionActions
                      onHandleEdit={handleOnClick}
                      person={interaction.person}
                      searchResult={interaction.search_result}
                      campaignId={campaignId}
                    />,
                    null,
                    "action",
                    TableBodyCell
                  ),
                ],
              };
            })
          : []
      }
      count={data?.count || 0}
      rowsPerPage={pageSize}
      // INITIAL_PAGE starts at 1, but Pagination starts at 0
      page={data?.page || page - 1}
      setPage={setPage}
      isLoading={isLoading}
      isFetching={isFetching}
      variant={
        !SearchUtils.validateCustomHeads(customColumns.length)
          ? [
              TableVariant.scrollable,
              TableVariant.checkable,
              TableVariant.lastColumnSticky,
            ]
          : [TableVariant.checkable]
      }
      panel={<ProfileDetail {...dataClicked} onHandleClose={handleClose} />}
    />
  );
};

export default GoogleSheetInteractionList;
