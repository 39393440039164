import { Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { getProfileName } from "core/utils/profileHandler";
import PersonAvatar from "modules/Person/components/PersonAvatar";
import {
  usePersonPlaceholderStyles,
  useProfileStyles,
} from "modules/Person/components/PersonDetail/utils";
import { formatPlaceholder } from "modules/Placeholder/utils";
import { ISearchResult } from "modules/Search/models";
import SearchUtils from "modules/Search/utils";
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  list: {
    padding: theme.spacing(3, 6),
    height: "100%",
    overflowY: "scroll",
    ...theme.app.constants.scrollbar,
  },
}));

interface SearchResultDetailProps {
  data: ISearchResult | undefined;
  searchResultId: number;
}

const SearchResultDetail = ({
  data,
  searchResultId,
}: SearchResultDetailProps): React.ReactElement => {
  const classes = useStyles();
  const classesProfile = useProfileStyles();
  const classesPlaceholder = usePersonPlaceholderStyles();

  const name = getProfileName(data, data?.person);
  const subText = SearchUtils.getSearchResultSubText(data?.placeholders);
  const placeholders = Object.keys(data?.placeholders || []);

  return (
    <div className={classesProfile.root}>
      <div className={classesProfile.header}>
        <div className={classesProfile.avatar}>
          <PersonAvatar src="" size="large" name={name} />
        </div>
        <div className={classesProfile.person}>
          <div className={classesProfile.title}>
            <Typography
              variant="h6"
              className={classesProfile.name}
              color="inherit"
            >
              {name}
            </Typography>
          </div>
          {!!subText && (
            <Typography className={classesProfile.subtitle}>
              {subText}
            </Typography>
          )}
        </div>
      </div>
      <div className={classes.list}>
        {placeholders.length !== 0 ? (
          placeholders.map((key) => (
            <div key={key} className={classesPlaceholder.placeholder}>
              <Typography
                variant="caption"
                className={classesPlaceholder.label}
                component="p"
              >
                {formatPlaceholder(key)}
              </Typography>
              <Typography variant="body2" className={classesPlaceholder.value}>
                {data?.placeholders?.[key]}
              </Typography>
            </div>
          ))
        ) : (
          <div className={classesPlaceholder.placeholder}>
            <Typography
              variant="caption"
              className={classesPlaceholder.label}
              component="p"
            >
              Profile url
            </Typography>
            <Typography variant="body2" className={classesPlaceholder.value}>
              {data?.profile_url}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchResultDetail;
