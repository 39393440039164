import { IArrayResponse, Platforms } from "core/models";

export enum TeamRoles {
  MA = "MA",
  VI = "VI",
}

export enum TeamRoleLabel {
  MA = "Manager",
  VI = "Viewer",
}

export interface ITeamInvite {
  id: number;
  created: string;
  updated: string;
  accepted_at: null | string;
  declined_at: null | string;
  role: TeamRoles;
  inviter: {
    id: number;
    name: string;
  };
  invitee: number;
  invitee_by_email: string;
  notify_by_email: boolean;
  account: {
    id: number;
    platform: Platforms.linkedin;
    login: string;
    proxy_id: number;
  };
  note: string | null;
}

export interface ITeamInvites extends IArrayResponse {
  results: ITeamInvite[];
}

export interface ITeamInviteFormInput {
  invitee_by_email: string;
  accounts: number[];
  note: string;
}

export interface ITeamInviteFormValues {
  role: TeamRoles;
  invitee_by_email: string;
  notify_by_email: boolean;
  account: number;
  note: string;
}

export interface ITeamInvitesFormValues {
  items: ITeamInviteFormValues[];
}
