import { yupResolver } from "@hookform/resolvers/yup";
import SaveIcon from "@mui/icons-material/Save";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import ElementField from "core/components/ElementField";
import { errorHandler, IErrorResponse } from "core/utils/errorHandler";
import { snackbarHandler } from "core/utils/snackbarHandler";
import ContactNotesListItem from "modules/Note/components/ContactNoteListItem";
import { INoteFormInput } from "modules/Note/models";
import NoteService from "modules/Note/services";
import NoteValidations from "modules/Note/validations";
import React from "react";
import { useForm, useWatch } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Loader from "ui-kit/components/Loader";

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      overflowY: "scroll",
      height: "100%",
      ...theme.app.constants.scrollbar,
      padding: theme.spacing(2, 4),
    },
    notes: {
      marginTop: theme.spacing(3),
    },
    inputRoot: {
      "&:hover:not(.Mui-disabled):before": {
        borderBottom: `2px solid ${theme.app.palette.action.placeholder}`,
      },
      "&:before": {
        borderBottom: `1px solid ${theme.app.palette.shadow.secondary}`,
      },
      "&:not(.Mui-focused)": {
        "& > .MuiInputAdornment-root": {
          opacity: 0,
        },
      },
    },
    input: {
      fontSize: "0.8rem !important",
      color: theme.app.palette.text.secondary,
      "&:placeholder": {
        color: theme.app.palette.text.secondary,
      },
    },
  };
});

interface ContactNotesListProps {
  contactId: number;
}

const initialValues = { content: "" };

const ContactNotesList = ({
  contactId,
}: ContactNotesListProps): React.ReactElement => {
  const classes = useStyles();
  const queryClient = useQueryClient();

  const { control, errors, handleSubmit, setError, reset } =
    useForm<INoteFormInput>({
      resolver: yupResolver(NoteValidations.createNote),
      defaultValues: initialValues,
    });

  const fetchContactNotes = async () => {
    try {
      const { data } = await NoteService.fetchNotes(contactId);
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };

  const { data, isLoading } = useQuery(
    ["notes", contactId],
    () => fetchContactNotes(),
    {
      keepPreviousData: true,
      enabled: !!contactId,
    }
  );

  const mutateCreateNote = useMutation(
    (newData: INoteFormInput) => NoteService.createNote(contactId, newData),
    {
      onSuccess: () => {
        reset(initialValues);
        queryClient.invalidateQueries(["notes", contactId]);
        snackbarHandler.success("Note added!");
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response, setError);
      },
    }
  );

  const onSubmit = (newData: INoteFormInput) => {
    mutateCreateNote.mutate(newData);
  };

  const value = useWatch({ name: "content", control });

  if (isLoading) {
    <div className={classes.root}>
      <Loader />
    </div>;
  }

  if (!contactId && !isLoading) {
    return (
      <div className={classes.root}>
        <div className={classes.root}>
          <Typography variant="body2" color="textSecondary">
            Can&apos;t add notes to this person. You need to schedule them for a
            campaign first.
          </Typography>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ElementField
          InputProps={{
            multiline: true,
            fullWidth: true,
            id: "content",
            placeholder: "Add note",
            variant: "standard",
            sx: { mb: 1.5 },
            autoComplete: "new-password",
            InputProps: {
              classes: {
                root: classes.inputRoot,
                input: classes.input,
              },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton type="submit" size="small">
                    <SaveIcon
                      fontSize="small"
                      color={value ? "primary" : "inherit"}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            },
          }}
          name="content"
          control={control}
          error={errors.content}
        />
      </form>
      <div className={classes.notes}>
        {data?.notes.map((note) => (
          <ContactNotesListItem
            key={note.id}
            note={note}
            contactId={contactId}
          />
        ))}
      </div>
    </div>
  );
};

export default ContactNotesList;
