import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import { Theme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  calculatePrependedDays,
  differenceInUnits,
  getCurrentTimezoneDate,
} from "core/utils/dateHandler";
import get from "lodash/get";
import { AccountContext } from "modules/Account/context";
import {
  InteractionsQuery,
  InteractionStatKey,
  InteractionStatLabel,
} from "modules/Interaction/models";
import InteractionService from "modules/Interaction/services";
import { ReportContext } from "modules/Report/context";
import { IDayjsRange, ReportNames } from "modules/Report/models";
import {
  createDateRange,
  getDateChunks,
  getDateLabels,
  getDateRangeQuery,
} from "modules/Report/utils";
import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import ReportCompareItem from "../ReportCompareItem";
import ReportCountItem from "../ReportCountItem";
import ReportElement from "../ReportElement";

const total = 8;

const getValues = (input: number[], prependedDays: number): number[] => {
  const prependedArray = new Array(prependedDays).fill(0);
  return prependedArray.concat(input);
};

export interface ReportListProps {
  defaultQueryString?: string;
}

const ReportList = ({
  defaultQueryString,
}: ReportListProps): React.ReactElement | null => {
  const { account } = useContext(AccountContext);
  const { id: accountId } = account;

  const {
    report: { currentRange: range },
  } = useContext(ReportContext);

  // Object { start: Dayjs, end: Dayjs} used for graphs, querystring
  const [dateRange, setDateRangeObj] = useState<IDayjsRange | undefined>(
    undefined
  );

  useEffect(() => {
    if (range) {
      setDateRangeObj(createDateRange(range));
    }
  }, [range]);

  // Use to fetch data
  const [queryString, setQueryString] = useState<string>("");

  useEffect(() => {
    if (!!dateRange) {
      const newQueryString = getDateRangeQuery(dateRange);
      setQueryString(newQueryString);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);

  // Create labels based on dateRange
  const [dateProps, setDateProps] = useState({
    labels: [""],
    chunks: [
      { start: getCurrentTimezoneDate(), end: getCurrentTimezoneDate() },
    ],
    diff: 0,
  });

  useEffect(() => {
    if (!!dateRange) {
      const diff = differenceInUnits(dateRange.end, dateRange.start, "d");
      const newChunks = getDateChunks(dateRange, diff);
      const newLabels = getDateLabels(newChunks, diff);

      setDateProps({
        chunks: newChunks,
        labels: newLabels,
        diff,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);

  const itemsPerLineSizes = [
    {
      count: 4,
      current: useMediaQuery((theme: Theme) => theme.breakpoints.only("xl")),
    },
    {
      count: 4,
      current: useMediaQuery((theme: Theme) => theme.breakpoints.only("lg")),
    },
    {
      count: 3,
      current: useMediaQuery((theme: Theme) => theme.breakpoints.only("md")),
    },
    {
      count: 3,
      current: useMediaQuery((theme: Theme) => theme.breakpoints.only("sm")),
    },
    {
      count: 1,
      current: useMediaQuery((theme: Theme) => theme.breakpoints.only("xs")),
    },
  ];

  const fetchAccountStats = async () => {
    try {
      const { data } = await InteractionService.fetchAccountStats(
        accountId,
        queryString
      );
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };

  const { data, isLoading, error } = useQuery(
    [InteractionsQuery.account_stats, accountId, queryString],
    () => fetchAccountStats(),
    {
      enabled: !!accountId && !!queryString,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  if (!account?.time_zone) {
    return null;
  }

  const itemsPerLine =
    itemsPerLineSizes.find((i) => i.current === true)?.count || 2;

  if (!queryString) return null;

  if (error) {
    return <Alert severity="error">{String(error)}</Alert>;
  }

  const prependedDays = calculatePrependedDays(
    dateRange?.start,
    data?.date_range?.first
  );

  return (
    <Grid container>
      <ReportCompareItem
        items={[
          {
            key: InteractionStatKey.invite_sent,
            count: get(data, `${InteractionStatKey.invite_sent}.total`) || 0,
            values: getValues(
              get(data, `${InteractionStatKey.invite_sent}.chunks`),
              prependedDays
            ),
          },
          {
            key: InteractionStatKey.invite_accepted,
            count:
              get(data, `${InteractionStatKey.invite_accepted}.total`) || 0,
            values: getValues(
              get(data, `${InteractionStatKey.invite_accepted}.chunks`),
              prependedDays
            ),
          },
        ]}
        DateProps={dateProps}
        compareLabel="Acceptance rate"
        indexes={[0, 1, 2]}
        total={total}
        isLoading={isLoading}
        itemsPerLine={itemsPerLine}
      />
      <ReportCompareItem
        items={[
          {
            key: InteractionStatKey.msg_sent,
            count: get(data, `${InteractionStatKey.msg_sent}.total`) || 0,
            values: getValues(
              get(data, `${InteractionStatKey.msg_sent}.chunks`),
              prependedDays
            ),
          },
          {
            key: InteractionStatKey.replies,
            count: get(data, `${InteractionStatKey.replies}.total`) || 0,
            values: getValues(
              get(data, `${InteractionStatKey.replies}.chunks`),
              prependedDays
            ),
          },
        ]}
        DateProps={dateProps}
        compareLabel="Reply rate"
        indexes={[3, 4, 5]}
        total={total}
        isLoading={isLoading}
        itemsPerLine={itemsPerLine}
      />
      <ReportElement
        key={InteractionStatKey.profile_visits}
        label={InteractionStatLabel[InteractionStatKey.profile_visits]}
        index={6}
        total={total}
        count={get(data, `${InteractionStatKey.profile_visits}.total`) || 0}
        values={getValues(
          get(data, `${InteractionStatKey.profile_visits}.chunks`),
          prependedDays
        )}
        isLoading={isLoading}
        DateProps={dateProps}
        itemsPerLine={itemsPerLine}
      />
      <ReportCountItem
        key={ReportNames.pending}
        index={7}
        total={total}
        label={"Invitations pending"}
        name={ReportNames.pending}
        DateProps={dateProps}
        QueryProps={{
          func: InteractionService.fetchAllPendingInvitations,
          query: queryString + defaultQueryString,
        }}
        itemsPerLine={itemsPerLine}
      />
    </Grid>
  );
};

ReportList.defaultProps = {
  defaultQueryString: "",
};

export default ReportList;
