import axios, { AxiosResponse } from "axios";
import {
  IAccountSubscription,
  IAccountSubscriptions,
  ICheckoutSession,
  ICheckoutSessionFormValues,
  ICustomerPortal,
  ICustomerPortalFormValues,
  ICustomers,
  IDiscountInputValues,
  IPrices,
  ISeatFormValues,
  ISubscription,
  ISubscriptions,
} from "../models";

const fetchPrices = (): Promise<AxiosResponse<IPrices>> =>
  axios.get("/stripe/prices/?page=1&page_size=1000");

const fetchSubscriptionCount = (): Promise<
  AxiosResponse<{ distinct_seat_count: number }>
> => axios.get("/stripe/subscriptions/count/");

const createSubscriptionSession = (
  data: ICheckoutSessionFormValues
): Promise<AxiosResponse<ICheckoutSession>> => {
  return axios.post(`/stripe/subscriptions/get_subscription_session/`, data);
};

const createCustomerPortalSession = (
  customerId: number,
  data: ICustomerPortalFormValues
): Promise<AxiosResponse<ICustomerPortal>> => {
  return axios.post(`/stripe/customers/${customerId}/portal/`, data);
};

const fetchSubscriptions = (
  accountId: number
): Promise<AxiosResponse<ISubscriptions>> => {
  return axios.get(`/stripe/subscriptions/?seat_object_id=${accountId}`);
};

const fetchAccountSubscription = (
  accountId: number
): Promise<AxiosResponse<IAccountSubscription>> => {
  return axios.get(`/inevitable/accounts/${accountId}/subscriptions/`);
};

const fetchAllUserSubscriptions = (): Promise<
  AxiosResponse<IAccountSubscriptions>
> => {
  return axios.get(`/inevitable/accounts/subscriptions/?page=1&page_size=1000`);
};

const fetchUserSubscriptions = (
  page: number,
  pageSize: number
): Promise<AxiosResponse<IAccountSubscriptions>> => {
  return axios.get(
    `/inevitable/accounts/subscriptions/?page=${page}&page_size=${pageSize}`
  );
};

const fetchExistingSubscriptions = (): Promise<AxiosResponse<ISubscriptions>> =>
  axios.get(`/stripe/subscriptions/?page=1&page_size=1`);

const fetchSubscription = (
  subId: string
): Promise<AxiosResponse<ISubscription>> => {
  return axios.get(`/stripe/subscriptions/${subId}`);
};

const cancelSubscription = (subId: string): Promise<AxiosResponse> => {
  return axios.patch(
    `/stripe/subscriptions/${subId}/cancel_at_period_end/`,
    {}
  );
};

const renewSubscription = (subId: string): Promise<AxiosResponse> => {
  return axios.patch(`/stripe/subscriptions/${subId}/renew/`, {});
};

const changeSubscription = (
  subId: string,
  data: ISeatFormValues
): Promise<AxiosResponse> => {
  return axios.patch(`/stripe/subscriptions/${subId}/seat/`, data);
};

const fetchCustomers = (): Promise<AxiosResponse<ICustomers>> => {
  return axios.get("/stripe/customers/");
};

const applyDiscount = (
  subId: string,
  data: IDiscountInputValues
): Promise<AxiosResponse<IDiscountInputValues>> => {
  return axios.post(`/stripe/subscriptions/${subId}/apply_discount/`, data);
};

const PaymentService = {
  fetchPrices,
  createSubscriptionSession,
  createCustomerPortalSession,
  fetchSubscriptions,
  fetchExistingSubscriptions,
  fetchUserSubscriptions,
  fetchSubscription,
  fetchAccountSubscription,
  fetchAllUserSubscriptions,
  cancelSubscription,
  renewSubscription,
  changeSubscription,
  fetchSubscriptionCount,
  fetchCustomers,
  applyDiscount,
};

export default PaymentService;
