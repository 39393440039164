import { alpha, darken, SimplePaletteColorOptions } from "@mui/material";

export type HeadPalette = {
  primaryBrand: SimplePaletteColorOptions;
  secondaryBrand: SimplePaletteColorOptions;
  grey: { [key: number]: string };
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function createPalette(head: HeadPalette) {
  if (Object.values(head.grey).length < 6)
    throw new Error("you have to provide at least 6 shades of gray");

  /**
   * Primary surface color for app.
   */
  const primeSurface = {
    dark: head.primaryBrand.dark,
    main: head.primaryBrand.main,
    light: head.primaryBrand.light,
  };

  const secondSurface = {
    dark: head.secondaryBrand.dark,
    main: head.secondaryBrand.main,
  };

  /**
   * System colors (currently 6)
   *
   * This is one of the most important color sets in theme.
   * This colors used for layout, borders, backgrounds and other
   * important decorations.
   */
  const system = head.grey;

  /**
   * Text colors
   *
   * The purpose of this colors is to make
   * text colors independent from main colors and
   * allow text-only theming.
   * In our case we just using `primary` color for main
   * color of texts.
   *
   * primary - main text color
   * secondary - complementary text color
   */
  const text = {
    contrastText: "#fff",
    secondary: "#333f48",
  };

  const divider = system[3];

  const background = {
    primary: primeSurface.main,
    default: system[6],
    light: "#fbfbfd",
  };

  /**
   * Action colors
   *
   * Main usage is for buttons, and interactive elements.
   * Colors is neutral and should be used only to style "default"
   * variant of element.
   *
   * Currently this colors is used only for Fab.
   * I hope that we will find more use cases for this
   * colors.
   */
  // const hoverOpacity = 0.075;

  const action = {
    main: system[1],
    color: system[2],
    icon: darken(system[2], 0.25),
    selected: alpha(system[2], 0.1),
    border: alpha(system[2], 0.3),
    disabled: alpha(system[2], 0.5),
    check: alpha(system[2], 0.6),
    placeholder: alpha(system[2], 0.8),
    disabledBackground: alpha(system[4], 0.3),
    selectedBackground: system[6],
    hoverBackground: system[5],
  };

  /**
   * Shadow colors
   *
   * This colors used to create box-shadow variants.
   *
   * Warning: Please, don't use this directly.
   * If you need one more shadow -- add it to shadows
   * theme property.
   */
  const shadow = {
    primary: system[4],
    secondary: system[3],
  };

  const link = "#1976d2";
  const shield = "#2BDE72";

  return {
    primeSurface,
    secondSurface,
    system,
    text,
    divider,
    background,
    action,
    shadow,
    link,
    shield,
  };
}
