import ProfileDetail from "core/components/ProfileDetail";
import KeywordFilter from "core/filters/atoms/KeywordFilter";
import CampaignFilter from "core/filters/components/CampaignFilter";
import { IProfileClicked } from "core/models";
import { createTableCell } from "core/utils/tableHandler";
import { get } from "lodash";
import ExternalProfile from "modules/Person/components/ExternalProfile";
import { ISearch, ISearchResults } from "modules/Search/models";
import SearchUtils from "modules/Search/utils";
import React from "react";
import Table, { TableProps, TableVariant } from "ui-kit/components/Table";
import TableBodyCell from "ui-kit/components/TableBodyCell";
import SearchResultsActions from "../SearchResultsActions";

const pageSize = 20;
const title = "Search results";

interface GoogleSheetListProps {
  data: ISearchResults | undefined;
  searchData: ISearch;
  isLoading: TableProps["isLoading"];
  isFetching: TableProps["isFetching"];
  page: TableProps["page"];
  setPage: TableProps["setPage"];
  dataClicked: IProfileClicked | undefined;
  handleClose: () => void;
  handleClicked: (n: IProfileClicked) => void;
  searchId: number;
}

const GoogleSheetList = ({
  data,
  searchData,
  isLoading,
  isFetching,
  page,
  setPage,
  dataClicked,
  handleClose,
  handleClicked,
  searchId,
}: GoogleSheetListProps): React.ReactElement | null => {
  const columns = SearchUtils.generateHeads(
    searchData.strategy_data.columns_to_rename
  );
  const customColumns = SearchUtils.getCustomHeads(columns);
  const heads = SearchUtils.formatHeads(columns);

  return (
    <Table
      title={title}
      heads={heads}
      filters={
        <>
          <KeywordFilter />
          <CampaignFilter multiple />
        </>
      }
      rows={
        data?.results
          ? data.results?.map((searchResult) => {
              const handleOnClick = () => {
                const newDataClicked = searchResult.person
                  ? { personId: searchResult.person.id }
                  : { searchResultId: searchResult.id };
                handleClicked(newDataClicked);
              };

              const cellProps = {
                person: searchResult.person,
                searchResult,
              };

              return {
                name: searchResult?.id.toString(),
                data: [
                  createTableCell(
                    <ExternalProfile {...cellProps} />,
                    handleOnClick,
                    "main",
                    TableBodyCell
                  ),
                  ...customColumns.map((column) => {
                    return createTableCell(
                      get(searchResult, `placeholders.${column}`),
                      handleOnClick,
                      "default",
                      TableBodyCell
                    );
                  }),
                  createTableCell(
                    <SearchResultsActions
                      onHandleEdit={handleOnClick}
                      person={searchResult.person}
                      searchResult={searchResult}
                      searchId={searchId}
                    />,
                    null,
                    "action",
                    TableBodyCell
                  ),
                ],
              };
            })
          : []
      }
      count={data?.count || 0}
      rowsPerPage={pageSize}
      // INITIAL_PAGE starts at 1, but Pagination starts at 0
      page={data?.page || page - 1}
      setPage={setPage}
      isLoading={isLoading}
      isFetching={isFetching}
      variant={
        !SearchUtils.validateCustomHeads(customColumns.length)
          ? [
              TableVariant.scrollable,
              TableVariant.checkable,
              TableVariant.lastColumnSticky,
            ]
          : [TableVariant.checkable]
      }
      panel={<ProfileDetail {...dataClicked} onHandleClose={handleClose} />}
    />
  );
};

export default GoogleSheetList;
