import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import Button from "ui-kit/atoms/Button";

interface NotFoundProps {
  label: string;
  link: string;
}

const NotFound = ({ label, link }: NotFoundProps) => {
  return (
    <>
      <Typography paragraph>
        This {label} has been deleted or you don't have access to it
      </Typography>
      <Button component={Link} variant="outlined" to={link} color="primary">
        Go back
      </Button>
    </>
  );
};

export default NotFound;
