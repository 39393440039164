/* eslint-disable consistent-return */
import RouterConstants from "core/routes/constants";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import Loader from "ui-kit/components/Loader";

type ParamTypes = {
  accountId: string;
};

const ResumeAccount = (): React.ReactElement => {
  const history = useHistory();
  const params = useParams<ParamTypes>();

  const accountId = +params.accountId;

  React.useEffect(() => {
    history.push(RouterConstants.ACCOUNT.new(accountId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Loader minHeight={300} />;
};

export default ResumeAccount;
