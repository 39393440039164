import React from "react";
import { useQuery } from "react-query";
import AutocompleteSingleFilter from "core/filters/atoms/AutocompleteSingleFilter";
import AutocompleteMultipleFilter from "core/filters/atoms/AutocompleteMultipleFilter";
import SearchService from "modules/Search/services";
import FilterButton from "ui-kit/components/FilterButton";

interface SearchFilterProps {
  multiple?: boolean;
}

const SearchFilter = ({
  multiple = false,
}: SearchFilterProps): React.ReactElement => {
  const fetchAllSearches = async () => {
    try {
      const { data } = await SearchService.fetchAllSearches();
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };
  const { data, isLoading } = useQuery(["search-all"], () =>
    fetchAllSearches()
  );

  const props = {
    id: "searchId",
    options: data?.results,
    isLoading,
    label: "Search",
  };

  return (
    <FilterButton {...props} data={data}>
      {multiple ? (
        <AutocompleteMultipleFilter {...props} />
      ) : (
        <AutocompleteSingleFilter {...props} />
      )}
    </FilterButton>
  );
};

SearchFilter.defaultProps = {
  multiple: false,
};

export default SearchFilter;
