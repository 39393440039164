import React from "react";
import clsx from "clsx";
import useMediaQuery from "@mui/material/useMediaQuery";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { Tab, Tabs } from "@mui/material";

const useStyles = makeStyles((theme: Theme) => ({
  tabs: {
    borderBottom: 0,
    backgroundColor: theme.app.palette.background.default,
  },
  indicator: {
    top: 0,
  },
  tab: {
    textTransform: "initial",
    fontSize: 14,
    fontWeight: 500,
    color: theme.app.palette.action.color,
    borderRadius: 0,
    borderBottom: `1px solid ${theme.app.palette.shadow.secondary}`,
    marginRight: 0,
    minHeight: 50,
    "&:not(:last-of-type)": {
      borderRight: `1px solid ${theme.app.palette.shadow.secondary}`,
    },
    "&:hover": {
      backgroundColor: theme.palette.common.white,
      color: theme.app.palette.action.main,
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: 220,
      width: "100%",
    },
  },
  tabActive: {
    borderBottomColor: "transparent",
    backgroundColor: theme.palette.common.white,
  },
}));

interface ButtonTabProps {
  title: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon: React.FunctionComponent<any>;
}

interface ButtonTabsProps {
  tabs: ButtonTabProps[];
  selectedTab: number;
  handleChange: (event: React.SyntheticEvent, newValue: number) => void;
}

const ButtonTabs = ({
  tabs,
  selectedTab,
  handleChange,
}: ButtonTabsProps): React.ReactElement => {
  const classes = useStyles();
  const desktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));
  return (
    <Tabs
      value={selectedTab}
      onChange={handleChange}
      classes={{ root: classes.tabs, indicator: classes.indicator }}
      variant={desktop ? "fullWidth" : "scrollable"}
      scrollButtons="auto"
      allowScrollButtonsMobile
    >
      {tabs.map((tab, index) => {
        const { title, icon: Icon } = tab;
        const activeTab = selectedTab === index;
        return (
          <Tab
            key={title}
            icon={
              <Icon {...(activeTab && { color: "primary" })} fontSize="small" />
            }
            iconPosition="start"
            label={title}
            aria-label={title}
            className={clsx(classes.tab, {
              [classes.tabActive]: activeTab,
            })}
          />
        );
      })}
    </Tabs>
  );
};

export default ButtonTabs;
