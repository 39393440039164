import { Theme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { makeStyles } from "@mui/styles";
import ConfigConstant from "core/constants/ConfigConstant";
import ButtonGroupFilter from "core/filters/atoms/ButtonGroupFilter";
import KeywordFilter from "core/filters/atoms/KeywordFilter";
import CampaignFilter from "core/filters/components/CampaignFilter";
import RouterConstants from "core/routes/constants";
import RouterUtils from "core/routes/utils";
import { get } from "lodash";
import { AccountContext } from "modules/Account/context";
import EmptyInteractionContainer from "modules/Interaction/components/EmptyInteractionContainer";
import InteractionContainerList from "modules/Interaction/components/InteractionContainerList";
import InteractionPeopleList from "modules/Interaction/components/InteractionPeopleList";
import { INTERACTION_FILTER_OPTIONS } from "modules/Interaction/constants";
import {
  InteractionFilters,
  InteractionViewVariant,
} from "modules/Interaction/models";
import InteractionService from "modules/Interaction/services";
import SearchPerson from "modules/Person/components/SearchPerson";
import React from "react";
import { useInView } from "react-intersection-observer";
import { useInfiniteQuery } from "react-query";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Loader from "ui-kit/components/Loader";
import PageHeader from "ui-kit/components/PageHeader";
import TableToolbar from "ui-kit/components/TableToolbar";

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    display: "flex",
    marginBottom: theme.spacing(-theme.app.constants.contentBottom),
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderRight: `1px solid ${theme.app.palette.shadow.primary}`,
    borderLeft: `1px solid ${theme.app.palette.shadow.primary}`,
    height: theme.app.constants.inbox.boxHeight,
    borderTop: `1px solid ${theme.app.palette.shadow.primary}`,
  },
  list: {
    height: "100%",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      maxWidth: theme.app.constants.inbox.contactWidth,
    },
    overflowY: "scroll",
    borderRight: `1px solid ${theme.app.palette.shadow.secondary}`,
  },
  inbox: {
    overflow: "hidden",
    width: "100%",
    position: "relative",
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${theme.app.constants.inbox.contactWidth}px)`,
    },
  },
  title: {
    flex: "1 1 100%",
  },
}));

type ParamTypes = {
  personId?: string;
};

const InteractionList = (): React.ReactElement => {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams<ParamTypes>();
  const personId = params.personId ? +params.personId : null;

  const location = useLocation();
  const { search } = RouterUtils.getQueryParams(location);
  const { filter, campaignId } = RouterUtils.parseQueryParams(location);
  const [filterQuery, setFilterQuery] = React.useState(
    RouterUtils.generateFilterOptionQuery(filter, INTERACTION_FILTER_OPTIONS)
  );

  const handleBacklink = () => {
    history.push(RouterConstants.INBOX.ALL);
  };

  React.useEffect(() => {
    const newFilterQuery = RouterUtils.generateFilterOptionQuery(
      filter,
      INTERACTION_FILTER_OPTIONS
    );
    setFilterQuery(newFilterQuery);
  }, [filter]);

  const {
    account: { id: accountId, meta_data },
  } = React.useContext(AccountContext);

  const fetchInteractions = async ({
    pageParam = ConfigConstant.INITIAL_PAGE,
  }) => {
    try {
      const { data: d } =
        await InteractionService.fetchInteractionsDistinctOnContact(
          accountId,
          pageParam,
          RouterUtils.generateFilterNameQuery(campaignId, "campaign_id__in"),
          !!get(meta_data, "inbox_privacy"),
          search,
          filterQuery
        );
      return d;
    } catch (err) {
      throw new Error(String(err));
    }
  };

  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery(
      ["interactions-distinct", accountId, campaignId, search, filterQuery],
      fetchInteractions,
      {
        getNextPageParam: (lastPage) =>
          lastPage.next ? lastPage.current + 1 : false,
        keepPreviousData: true,
      }
    );

  const { ref, inView } = useInView({
    threshold: 0,
  });

  const loadMoreDisabled = Boolean(!hasNextPage || isFetchingNextPage);
  React.useEffect(() => {
    if (inView && !loadMoreDisabled) {
      fetchNextPage();
    }
  }, [inView, loadMoreDisabled, fetchNextPage]);

  const interactionList = (
    <div className={classes.list}>
      <InteractionPeopleList
        isLoading={isLoading}
        hasNextPage={hasNextPage}
        personId={personId}
        pages={data ? data.pages : []}
        loadMoreRef={ref}
        loadMoreDisabled={loadMoreDisabled}
      />
      <SearchPerson personId={personId} />
    </div>
  );

  const inbox = (
    <div className={classes.inbox}>
      {personId ? (
        <InteractionContainerList
          accountId={accountId}
          personId={personId}
          variant={InteractionViewVariant.inbox}
          handleBacklink={handleBacklink}
        />
      ) : (
        <EmptyInteractionContainer />
      )}
    </div>
  );

  const desktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  const content = desktop ? (
    <>
      {interactionList}
      {inbox}
    </>
  ) : (
    <>{personId ? inbox : interactionList}</>
  );

  const title = "Inbox";
  const bodyDefault =
    "Here you can view your inbox and send messages or invitations.";

  return (
    <>
      <PageHeader
        title={title}
        body={bodyDefault}
        helperProps={{
          link: RouterConstants.DOCS.INBOX,
        }}
        filterProps
      />
      <>
        <TableToolbar
          title="Inbox"
          numSelected={0}
          disableDivider
          filters={
            <>
              <ButtonGroupFilter
                id="filter"
                options={[
                  {
                    id: InteractionFilters.all,
                    name: "All",
                    query: {},
                  },
                  {
                    id: InteractionFilters.reply,
                    name: "Replies",
                    query: { account_is_actor: false },
                  },
                  {
                    id: InteractionFilters.unread,
                    name: "Unread",
                    query: { account_is_actor: false, seen: false },
                  },
                ]}
              />
              <KeywordFilter />
              <CampaignFilter multiple />
            </>
          }
        />
        <div className={classes.content}>
          {isLoading ? <Loader /> : content}
        </div>
      </>
    </>
  );
};

export default InteractionList;
