import React from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Control, FieldError, FieldErrors } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import { errorHandler, IErrorResponse } from "core/utils/errorHandler";
import { snackbarHandler } from "core/utils/snackbarHandler";
import {
  CreateSequenceKeys,
  ICreateSequenceFormInput,
  ICreateSequenceFormValues,
} from "modules/Sequence/models";
import { AccountContext } from "modules/Account/context";
import SequenceService from "modules/Sequence/services";
import TextField from "ui-kit/atoms/TextField";
import Button from "ui-kit/atoms/Button";
import DialogHeader from "ui-kit/components/DialogHeader";
import Loader from "ui-kit/components/Loader";
import RouterConstants from "core/routes/constants";
import SequenceSingleAutocomplete from "../SequenceSingleAutocomplete";
import SequenceActionSelector from "../SequenceActionSelector";

type DialogValue = { name: string };

interface NewSequenceDialogProps {
  open: boolean;
  handleClose: () => void;
  dialogValue: DialogValue;
  setDialogValue: (value: DialogValue) => void;
  register: () => void;
  errors: FieldErrors;
  handleChange?: (
    _: React.SyntheticEvent<Element, Event> | undefined,
    newValue: ICreateSequenceFormValues
  ) => void;
  setError: ((name: string, error: FieldError) => void) | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleSubmit: any;
  control: Control;
  disableRedirectOnClose?: boolean;
}

const NewSequenceDialog = ({
  open,
  dialogValue,
  setDialogValue,
  handleChange,
  handleClose,
  handleSubmit,
  register,
  control,
  errors,
  setError,
  disableRedirectOnClose = false,
}: NewSequenceDialogProps): React.ReactElement => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const [checked, setChecked] = React.useState<CreateSequenceKeys>(
    CreateSequenceKeys.createNew
  );

  const {
    account: { id: accountId },
  } = React.useContext(AccountContext);

  const fetchExistingSequences = async () => {
    try {
      const { data } = await SequenceService.fetchExistingSequences();
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };
  const { data: sequence, isLoading } = useQuery(
    ["sequences", "existing"],
    () => fetchExistingSequences(),
    {
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const sequenceNameField = (
    <TextField
      autoComplete="off"
      fullWidth
      autoFocus
      name="sequenceName"
      variant="outlined"
      margin="dense"
      id="sequenceName"
      value={dialogValue.name}
      inputRef={register}
      onChange={(event) =>
        setDialogValue({
          ...dialogValue,
          name: event.target.value,
        })
      }
      error={!!errors.sequenceName}
      helperText={errors.sequenceName?.message}
      label="Sequence name"
      type="text"
    />
  );
  const FormProps = {
    createNew: {
      func: SequenceService.createSequence,
      components: sequenceNameField,
    },
    copyExisting: {
      func: SequenceService.copySequence,
      components: (
        <>
          {sequenceNameField}
          <SequenceSingleAutocomplete control={control} errors={errors} />
        </>
      ),
    },
  };

  const mutateNewSequence = useMutation(
    (newData: ICreateSequenceFormValues) => {
      return FormProps[checked]?.func({ ...newData, account: accountId });
    },
    {
      onSuccess: (response: { data: ICreateSequenceFormValues }) => {
        queryClient.invalidateQueries(["sequences-all"]);
        snackbarHandler.success("Sequence successfully added!");
        handleClose();

        if (handleChange) {
          handleChange(undefined, response?.data);
        }
      },
      onError: (errorMutation: IErrorResponse) => {
        errorHandler(errorMutation.response, setError);
      },
    }
  );

  const handleOnClose = () => {
    handleClose();
    if (!disableRedirectOnClose) {
      history.push(RouterConstants.SEQUENCE.ALL);
    }
  };

  const onSubmit = (data: ICreateSequenceFormInput) => {
    const { sequence: item, sequenceName: name } = data;
    if (name) {
      mutateNewSequence.mutate({
        sequence: String(item?.id),
        name,
      });
    }
  };

  const content = (
    <>
      <DialogContentText>
        Sequence is a set of automated actions executed with delays.
      </DialogContentText>
      {!!sequence?.count && (
        <SequenceActionSelector checked={checked} setChecked={setChecked} />
      )}
      {FormProps[checked]?.components}
    </>
  );

  return (
    <Dialog
      open={open}
      onClose={handleOnClose}
      aria-labelledby="new-sequence-dialog-title"
      maxWidth="sm"
      PaperProps={{ sx: { width: "100%" } }}
    >
      <form>
        <DialogHeader title="Create a sequence" onHandleClose={handleClose} />
        <DialogContent dividers>
          {isLoading ? <Loader /> : content}
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            onClick={handleSubmit(onSubmit)}
            color="primary"
            variant="contained"
          >
            Save sequence
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

NewSequenceDialog.defaultProps = {
  handleChange: undefined,
  disableRedirectOnClose: false,
};

export default NewSequenceDialog;
