import Add from "@mui/icons-material/Add";
import { Box, Skeleton } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/styles";
import { CampaignQuery } from "modules/Campaign/models";
import CampaignService from "modules/Campaign/services";
import React from "react";
import { useQuery } from "react-query";
import Button from "@mui/material/Button";
import FoldableContent from "ui-kit/components/FoldableContent";
import ProfileCampaignListItem from "./components/ProfileCampaignListItem";
import MovePersonToCampaign from "modules/Campaign/components/MovePersonToCampaign";
import { AccountContext } from "modules/Account/context";

interface ProfileCampaignListProps {
  searchResultId?: number;
  personId?: number;
  name: string;
}

const ProfileCampaignList = ({
  searchResultId,
  personId,
  name,
}: ProfileCampaignListProps): React.ReactElement | null => {
  const theme = useTheme();
  const [openAddCampaignDialog, toggleAddCampaignDialog] =
    React.useState(false);
  const {
    account: { id: accountId },
  } = React.useContext(AccountContext);

  const fetchPersonCampaigns = async () => {
    try {
      const { data } = await CampaignService.fetchRecentPersonCampaigns(
        accountId,
        personId?.toString(),
        searchResultId?.toString()
      );
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };

  const { data, isLoading, error } = useQuery(
    [CampaignQuery.campaigns_profile, accountId, personId, searchResultId],
    () => fetchPersonCampaigns(),
    {
      enabled: !!(personId || searchResultId),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const loader = (
    <Box sx={{ py: 2, px: 4 }}>
      <Skeleton width={50} />
    </Box>
  );

  const handleAddCampaign = () => {
    toggleAddCampaignDialog(!openAddCampaignDialog);
  };

  if (!!error) {
    return <Typography variant="body2">Can't get campaign data.</Typography>;
  }

  return (
    <div>
      <FoldableContent title="Campaigns">
        <>
          {data?.results.map((campaign, index) => (
            <ProfileCampaignListItem
              campaign={campaign}
              campaignId={campaign.id}
              personId={personId}
              key={campaign.id}
              index={index}
              count={data?.results.length}
            />
          ))}
          {!!data && !isLoading && data.count === 0 && (
            <Typography
              variant="body2"
              sx={{ py: 3, px: 4, fontSize: "0.8rem" }}
              color="action.main"
            >
              No campaigns
            </Typography>
          )}
          {(!data || isLoading) && loader}
          {!!data && !isLoading && (
            <Box sx={{ pb: 2, px: 4 }}>
              <Button
                onClick={handleAddCampaign}
                size="small"
                color="inherit"
                variant="outlined"
                startIcon={<Add />}
                disableRipple
                disableTouchRipple
                disableFocusRipple
                sx={{
                  marginLeft: theme.spacing(-1),
                  padding: theme.spacing(1),
                  fontSize: "0.775rem",
                  color: theme.palette.secondary.dark,
                }}
              >
                Add to campaign
              </Button>
            </Box>
          )}
          {!!data && !!personId && (
            <MovePersonToCampaign
              open={openAddCampaignDialog}
              toggleOpen={toggleAddCampaignDialog}
              personId={personId}
              name={name}
              active={!!data?.count}
            />
          )}
        </>
      </FoldableContent>
    </div>
  );
};

ProfileCampaignList.defaultProps = {
  searchResultId: undefined,
  personId: undefined,
};

export default ProfileCampaignList;
