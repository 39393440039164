import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { TitleDropdownVariant } from "core/models";
import RouterConstants from "core/routes/constants";
import { errorHandler, IErrorResponse } from "core/utils/errorHandler";
import ActionList from "modules/Action/components/ActionList";
import { IUpdateSequenceFormValues } from "modules/Sequence/models";
import SequenceService from "modules/Sequence/services";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import NotFound from "ui-kit/components/NotFound";
import PageHeader from "ui-kit/components/PageHeader";

type ParamTypes = {
  sequenceId: string;
};

const SequenceDetail = (): React.ReactElement => {
  const params = useParams<ParamTypes>();
  const sequenceId: number = +params.sequenceId;

  const queryClient = useQueryClient();
  const history = useHistory();

  const { data, error } = useQuery(["sequence", sequenceId], async () => {
    try {
      const response = await SequenceService.fetchSequence(sequenceId);
      return response.data;
    } catch (err) {
      throw new Error(String(err));
    }
  });

  const mutateRemove = useMutation(
    () => SequenceService.deleteSequence(sequenceId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("sequences");
        history.push(RouterConstants.SEQUENCE.ALL);
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);
      },
    }
  );

  const handleCampaignRemove = () => {
    // eslint-disable-next-line no-alert
    if (window.confirm("Are you sure you want to delete this template?")) {
      mutateRemove.mutate();
    }
  };

  const mutateUpdateName = useMutation(
    (data: IUpdateSequenceFormValues) => SequenceService.updateSequence(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["sequence", sequenceId]);
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);
      },
    }
  );

  const handleCampaignNameUpdate = (data: IUpdateSequenceFormValues) => {
    mutateUpdateName.mutate({ id: sequenceId, name: data.name });
  };

  if (error) {
    return <NotFound label="template" link={RouterConstants.SEQUENCE.ALL} />;
  }

  return (
    <>
      <PageHeader
        backlinkProps={{
          text: "Templates",
          link: RouterConstants.SEQUENCE.ALL,
        }}
        title={data?.name}
        body="Here you can view and manage all actions for this template."
        menuProps={[
          {
            id: TitleDropdownVariant.rename,
            label: "Rename",
            icon: EditIcon,
            func: handleCampaignNameUpdate,
          },
          {
            id: TitleDropdownVariant.delete,
            label: "Delete",
            icon: DeleteIcon,
            func: handleCampaignRemove,
          },
        ]}
      />
      <ActionList sequenceId={sequenceId} />
    </>
  );
};

export default SequenceDetail;
