import React from "react";
import { useMutation, useQueryClient } from "react-query";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { errorHandler, IErrorResponse } from "core/utils/errorHandler";
import { snackbarHandler } from "core/utils/snackbarHandler";
import AccountService from "modules/Account/services";
import { formatPlaceholder } from "modules/Placeholder/utils";
import { AccountQuery, IAccountPlaceholders } from "modules/Account/models";
import { removeMyPartPlaceholder } from "modules/Account/utils";

interface AccountPlaceholderTextfieldProps {
  accountId: number;
  placeholders: IAccountPlaceholders;
  id: string;
  defaultValue: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.up("md")]: {
      maxWidth: theme.app.constants.inputMaxWidth,
    },
  },
  inputRoot: {
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    "&:before": {
      borderBottom: `1px solid  transparent`,
    },
    "&:not(.Mui-focused)": {
      "& > .MuiInputAdornment-root": {
        visibility: "hidden",
      },
    },
  },
  input: {
    fontSize: "0.875rem !important",
    color: theme.app.palette.text.secondary,
  },
  limit: {
    fontSize: 11,
    color: theme.app.palette.action.placeholder,
  },
}));

const AccountPlaceholderTextfield = ({
  accountId,
  placeholders,
  id,
  defaultValue,
}: AccountPlaceholderTextfieldProps): React.ReactElement => {
  const classes = useStyles();
  const [value, setValue] = React.useState<string>(defaultValue || "");

  // Update value on mutation for those that have default values
  React.useEffect(() => {
    if (defaultValue && value !== defaultValue) {
      setValue(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const queryClient = useQueryClient();
  const mutateOnSubmit = useMutation(
    (newData: IAccountPlaceholders) =>
      AccountService.updateAccountPlaceholders(accountId, newData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([AccountQuery.account, accountId]);
        snackbarHandler.success("Successfully updated!");
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);
      },
    }
  );

  const handleMutation = (newValue: string) => {
    // Stop if no changes made
    if (newValue === defaultValue) {
      return false;
    }

    const key = removeMyPartPlaceholder(id);

    const newPlaceholders = { ...placeholders, [key]: newValue };

    // Remove key: value pair if empty input value
    if (!newValue) {
      delete newPlaceholders[key];
    }

    return mutateOnSubmit.mutate(newPlaceholders);
  };

  const onSubmit = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => handleMutation(event.target.value);

  const onKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      handleMutation((event.target as HTMLTextAreaElement).value);
    }
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setValue(event.target.value);

  return (
    <div className={classes.root}>
      <TextField
        value={value}
        onChange={handleOnChange}
        onKeyDown={onKeyPress}
        label={formatPlaceholder(id)}
        onBlur={onSubmit}
        fullWidth
        variant="outlined"
        InputProps={{
          classes: {
            root: classes.inputRoot,
            input: classes.input,
          },
        }}
      />
    </div>
  );
};

export default AccountPlaceholderTextfield;
