import React, { useContext } from "react";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { useQuery, useMutation, useQueryClient } from "react-query";
import DeleteIcon from "@mui/icons-material/Delete";
import { GlobalContext } from "core/context";
import get from "lodash/get";
import { GlobalActionType } from "core/reducers";
import { errorHandler, IErrorResponse } from "core/utils/errorHandler";
import { snackbarHandler } from "core/utils/snackbarHandler";
import AddressBookService from "modules/AddressBook/services";
import AddressBookDetail from "modules/AddressBook/components/AddressBookDetail";
import CreateAddressBook from "modules/AddressBook/components/CreateAddressBook";
import CreateAddressBookItems from "modules/AddressBook/components/CreateAddressBookItems";
import CreateAccountAddressBook from "modules/AddressBook/components/CreateAccountAddressBook";
import { AccountContext } from "modules/Account/context";
import { IAddressBookItemsFormValues } from "modules/AddressBook/models";
import Loader from "ui-kit/components/Loader";
import PageHeader from "ui-kit/components/PageHeader";
import Button from "ui-kit/atoms/Button";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3.5, 2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(4, 0, 8),
    },
  },
}));

const AddressBookList = (): React.ReactElement => {
  const {
    account: { id: accountId },
  } = useContext(AccountContext);
  const classes = useStyles();

  const {
    dispatch,
    global: { selected },
  } = useContext(GlobalContext);

  const queryClient = useQueryClient();
  const [openDialog, toggleDialog] = React.useState(false);

  const fetchAccountAddressBooks = async () => {
    try {
      const { data } = await AddressBookService.fetchAccountAddressBooks(
        accountId
      );
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };
  // NOTE: There is always only a single account blacklist - {unique: true}
  const { data, isLoading } = useQuery(
    ["account-addressBooks", accountId],
    () => fetchAccountAddressBooks(),
    {
      keepPreviousData: true,
    }
  );

  const addressBookId = get(data, "results[0].address_book");

  const handleClose = () => toggleDialog(false);

  // Delete person from blacklist
  const deleteAddressBookItem = useMutation(
    (newData: IAddressBookItemsFormValues) =>
      AddressBookService.deleteAddressBookItems(newData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["addressBook-items"]);
        // Reset selected people
        dispatch({
          type: GlobalActionType.SET_GLOBAL,
          payload: { selected: [] },
        });
        snackbarHandler.success("Successfully removed!");
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);
      },
    }
  );

  const handleRemove = () => {
    if (selected.length === 0) {
      return snackbarHandler.warning("Please select at least 1 person.");
    }
    if (addressBookId) {
      return deleteAddressBookItem.mutate({
        persons: selected.map((x) => Number(x.name)),
        address_book: addressBookId,
      });
    }
  };

  const actions = (
    <Button
      variant="outlined"
      color="inherit"
      size="small"
      type="button"
      startIcon={<DeleteIcon />}
      onClick={handleRemove}
    >
      Remove
    </Button>
  );

  const content = addressBookId ? (
    <AddressBookDetail
      addressBookId={addressBookId}
      open={openDialog}
      toggleOpen={toggleDialog}
    />
  ) : (
    <CreateAddressBook
      open={openDialog}
      toggleOpen={toggleDialog}
      handleClose={handleClose}
    />
  );

  return (
    <div className={classes.root}>
      <PageHeader
        title="Blacklist"
        titleProps={{
          variant: "h6",
        }}
        body="People in the blacklist will not be contacted in any of your campaigns."
        actionProps={{
          text: "Add people",
          onClick: () => toggleDialog(true),
        }}
        filterProps
        selectedActions={actions}
      />
      {isLoading ? <Loader /> : content}
      {addressBookId ? (
        <CreateAddressBookItems
          addressBookId={addressBookId}
          open={openDialog}
          handleClose={handleClose}
        />
      ) : (
        <CreateAccountAddressBook open={openDialog} handleClose={handleClose} />
      )}
    </div>
  );
};

export default AddressBookList;
