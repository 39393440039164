import React, { useState } from "react";
import { useQuery } from "react-query";
import RouterConstants from "core/routes/constants";
import ConfigConstant from "core/constants/ConfigConstant";
import { createTableCell } from "core/utils/tableHandler";
import CompanyService from "modules/Company/services";
import CompanyDialog from "modules/Company/components/CompanyDialog";
import Table from "ui-kit/components/Table";
import TableBodyCell from "ui-kit/components/TableBodyCell";
import PageHeader from "ui-kit/components/PageHeader";

const title = "Companies";
const heads = [
  {
    id: "name",
    percentage: true,
    width: 100,
    label: "Company name",
  },
];

const CompanyList = (): React.ReactElement => {
  const [openDialog, toggleDialog] = React.useState(false);
  const [page, setPage] = useState<number>(ConfigConstant.INITIAL_PAGE);
  const fetchCompanies = async (p: number) => {
    try {
      const { data } = await CompanyService.fetchCompanies(p);
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };
  const { data, isFetching } = useQuery(
    ["companies", page],
    () => fetchCompanies(page),
    {
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <>
      <PageHeader
        title={title}
        actionProps={{
          text: "Add company",
          onClick: () => toggleDialog(true),
        }}
        body="Here you can manage all your created companies and LinkedIn accounts attached to them."
      />
      <CompanyDialog open={openDialog} toggleOpen={toggleDialog} />
      <Table
        title={title}
        heads={heads}
        rows={
          data?.results
            ? data.results.map((company) => ({
                name: company.id.toString(),
                data: [
                  createTableCell(
                    company.name,
                    RouterConstants.COMPANY.detail(company?.id as number),
                    "main",
                    TableBodyCell
                  ),
                ],
              }))
            : []
        }
        count={data?.count || 0}
        // INITIAL_PAGE starts at 1, but Pagination starts at 0
        page={data?.page || page - 1}
        setPage={setPage}
        isFetching={isFetching}
      />
    </>
  );
};

export default CompanyList;
