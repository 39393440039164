import React from "react";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

const useStyles = makeStyles((theme: Theme) => ({
  // Add divider and space too last "create" list item only if it's not only shown
  item: {
    // padding: theme.spacing(1.5, 3),
    "&:not(:first-child)": {
      borderTop: `1px solid ${theme.app.palette.shadow.secondary}`,
      marginTop: theme.spacing(1.5),
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
      position: "absolute",
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: theme.palette.common.white,
      borderRadius: theme.spacing(0, 0, 1, 1),
    },
  },
}));

interface AutocompleteOptionProps {
  props: React.HTMLAttributes<HTMLLIElement>;
  option: {
    name: string;
    id?: number;
  };
}

const AutocompleteOption = ({
  props,
  option,
}: AutocompleteOptionProps): React.ReactElement => {
  const classes = useStyles();
  return (
    <>
      <div className={option.id === 0 ? classes.item : undefined}>
        <li {...props}>{option.name}</li>
      </div>
    </>
  );
};

export default AutocompleteOption;
