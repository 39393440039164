import React from "react";
import { FieldError, Control } from "react-hook-form";
import SequenceService from "modules/Sequence/services";
import SequenceValidation from "modules/Sequence/validations";
import AutocompleteCreateField from "core/components/AutocompleteCreateField/component";
import NewSequenceDialog from "../NewSequenceDialog";

interface SequenceAutocompleteProps {
  control: Control;
  error: FieldError | undefined;
}

const SequenceAutocomplete = ({
  control,
  error,
}: SequenceAutocompleteProps): React.ReactElement => {
  const schema = SequenceValidation.sequenceFormSchema("sequenceName");

  return (
    <AutocompleteCreateField
      control={control}
      error={error}
      name="sequence"
      queryName="sequences"
      queryFunc={SequenceService.fetchAllTemplateSequences}
      schema={schema}
      dialog={NewSequenceDialog}
      defaultValues={{
        sequenceName: "",
        sequence: undefined,
      }}
    />
  );
};

export default SequenceAutocomplete;
