import { RouteComponentProps } from "react-router-dom";
import RouterConstants from "core/routes/constants";
import { errorHandler } from "core/utils/errorHandler";
import { AccountActionType } from "modules/Account/reducers";
import AccountService from "modules/Account/services";
import { AccountTabs } from "modules/Account/models";
import { MyNetworkTabs } from "modules/Person/models";
import {
  TargetContentTypeModels,
  INotification,
  NotificationNavigation,
} from "../models";
import { IAccountContext } from "modules/Account/context";

export const NOTIFICATION_MODELS = Object.values(TargetContentTypeModels);

const getNotificationTargetLink = (
  id: number,
  actorId: number,
  currentAccountId: number
) => ({
  person: RouterConstants.MY_NETWORK.all(MyNetworkTabs.contacts),
  search: RouterConstants.SEARCH.detail(id),
  accountlog: currentAccountId
    ? RouterConstants.ACCOUNT.detail(currentAccountId, AccountTabs.activity)
    : RouterConstants.ACCOUNT.new(actorId),
  interaction: RouterConstants.INTERACTION.detail(id),
});

const getNotificationDataLink = (currentAccountId: number) => ({
  [NotificationNavigation.account_warmup]: RouterConstants.ACCOUNT.detail(
    currentAccountId,
    AccountTabs.limits
  ),
});

const handleNotificationClick = (
  notification: INotification,
  history: RouteComponentProps["history"],
  dispatch: IAccountContext["dispatch"],
  currentAccountId: number
): void => {
  if (!notification) return;

  if (
    notification.actor.login &&
    notification.actor_object_id &&
    Number(notification.actor_object_id) !== currentAccountId
  ) {
    AccountService.fetchAccount(notification.actor_object_id)
      .then(({ data }) => {
        dispatch({
          type: AccountActionType.SET_ACCOUNT,
          payload: data,
        });
      })
      .catch((error) => {
        errorHandler(error.response);
      });
  }

  if (NOTIFICATION_MODELS.includes(notification.target_content_type?.model)) {
    const links = getNotificationTargetLink(
      notification.target_object_id,
      notification.actor_object_id,
      currentAccountId
    );
    const link = links[notification.target_content_type.model];
    history.push(link);
    return;
  }

  const nav = notification?.data?.nav;
  if (NotificationNavigation[nav]) {
    const links = getNotificationDataLink(currentAccountId);
    const link = links[NotificationNavigation[nav]];
    history.push(link);
  }
};

// eslint-disable-next-line import/prefer-default-export
export { handleNotificationClick };
