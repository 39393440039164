import axios, { AxiosResponse } from "axios";
import {
  INotification,
  INotificationFormInput,
  INotifications,
} from "modules/Notification/models";

const fetchRecentAccountUnreadNotifications = (
  accountId: number
): Promise<AxiosResponse<INotifications>> =>
  axios.get(
    `/notifications/?unread=true&page=1&page_size=3&actor_object_id=${accountId}`
  );

const fetchAllUnreadNotifications = (
  accountId: number
): Promise<AxiosResponse<INotifications>> =>
  axios.get(
    `/notifications/?unread=true&page=1&page_size=1&actor_object_id__not=${accountId}`
  );

const fetchNotifications = (
  page: number,
  unread = "",
  accountQuery: string
): Promise<AxiosResponse<INotifications>> =>
  axios.get(`/notifications/?page=${page}&unread=${unread}${accountQuery}`);

const updateNotification = (
  data: INotificationFormInput
): Promise<AxiosResponse<INotification>> => {
  return axios.patch(`/notifications/${data.id}/`, data);
};

const updateAllNotificationsMarkAsRead = (
  accountQuery: string
): Promise<AxiosResponse<INotifications>> => {
  return axios.get(
    `/notifications/bulk_mark_as_read/?unread=true${accountQuery}`
  );
};

const updateNotificationsMarkAsRead = (
  accountQuery: string,
  target_content_type: string | undefined,
  target_object_id: string
): Promise<AxiosResponse<INotifications>> => {
  return axios.get(
    `/notifications/bulk_mark_as_read/?unread=true&target_content_type=${target_content_type}&target_object_id=${target_object_id}${accountQuery}`
  );
};

const NotificationService = {
  fetchRecentAccountUnreadNotifications,
  fetchAllUnreadNotifications,
  fetchNotifications,
  updateNotification,
  updateAllNotificationsMarkAsRead,
  updateNotificationsMarkAsRead,
};

export default NotificationService;
