import ConfigConstant from "core/constants/ConfigConstant";
import {
  InteractionCategories,
  InteractionFilters,
  InteractionStatusFilters,
  InteractionStatusLabels,
  ConnectionStatusLabels,
  ConnectionStatusFilters,
} from "modules/Interaction/models";

const INTERACTION_FILTER_OPTIONS = [
  {
    id: InteractionFilters.reply,
    name: "Replied",
    query: { account_is_actor: false },
  },
  {
    id: InteractionFilters.unread,
    name: "Unread",
    query: { account_is_actor: false, seen: false },
  },
];

const INTERACTION_STATUS_FILTER_OPTIONS = [
  {
    id: InteractionStatusFilters.replied,
    name: InteractionStatusLabels.replied,
    query: {
      account_is_actor: false,
      category: InteractionCategories.message, // Not true because it can also be reply to connection request
    },
  },
  {
    id: InteractionStatusFilters.awaiting_reply,
    name: InteractionStatusLabels.awaiting_reply,
    query: { account_is_actor: true, sequence_action_order_nr__isnull: false },
  },
  // {
  //   id: InteractionStatusFilters.stopped,
  //   name: InteractionStatusLabels.stopped,
  //   query: { category: InteractionCategories.sequence_stopped },
  // },
];

const INTERACTION_CONNECTION_FILTER_OPTIONS = [
  {
    id: ConnectionStatusFilters.pending,
    name: ConnectionStatusLabels.pending,
    query: {
      account_is_actor: true,
      category: InteractionCategories.connection_request,
      contact__active_connection: false,
      sequence_action_order_nr__isnull: false,
    },
  },
  {
    id: ConnectionStatusFilters.connected,
    name: ConnectionStatusLabels.connected,
    query: { contact__active_connection: true },
  },
  {
    id: ConnectionStatusFilters.not_connected,
    name: InteractionStatusLabels.not_connected,
    query: { contact__active_connection: false },
  },
];

const LINKEDIN_PENDING_INVITATIONS_URL = `${ConfigConstant.LINKEDIN_ROOT}mynetwork/invitation-manager/sent/`;

export {
  INTERACTION_FILTER_OPTIONS,
  INTERACTION_STATUS_FILTER_OPTIONS,
  INTERACTION_CONNECTION_FILTER_OPTIONS,
  LINKEDIN_PENDING_INVITATIONS_URL,
};
