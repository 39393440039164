import React from "react";
import { useForm, FieldError, Controller, Control } from "react-hook-form";
import { AnyObjectSchema } from "yup";
import { useQuery } from "react-query";
import { AxiosResponse } from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import capitalize from "@mui/utils/capitalize";
import Autocomplete, {
  createFilterOptions,
  AutocompleteRenderInputParams,
} from "@mui/material/Autocomplete";
import Skeleton from "@mui/material/Skeleton";
import Add from "@mui/icons-material/Add";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";
import { ItemTypes } from "core/models";
import { isNumber } from "core/utils/commonHandler";
import AutocompleteOption from "core/components/AutocompleteOption";
import TextField from "ui-kit/atoms/TextField";
import Button from "ui-kit/atoms/Button";

const initialValues = { id: 0, name: "" };

const useStyles = makeStyles((theme: Theme) => ({
  listbox: {
    marginBottom: theme.spacing(12),
    overflow: "scroll",
  },
}));

interface AutocompleteCreateFieldProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dialog: React.FunctionComponent<any>;
  schema: AnyObjectSchema;
  name: string;
  queryName: string;
  control: Control;
  error: FieldError | undefined;
  defaultValues: {
    [key: string]: string | undefined;
  };
  queryFunc: () => Promise<AxiosResponse>;
  disableCreateButton?: boolean;
}

const filter = createFilterOptions<ItemTypes>();

const AutocompleteCreateField = ({
  dialog: DialogComponent,
  schema,
  name,
  queryName,
  control,
  error,
  defaultValues,
  queryFunc,
  disableCreateButton,
}: AutocompleteCreateFieldProps): React.ReactElement => {
  const classes = useStyles();
  const [open, toggleOpen] = React.useState(false);
  const [dialogValue, setDialogValue] = React.useState({ name: "" });
  const [inputValue, setInputValue] = React.useState("");

  const { data, isLoading } = useQuery(
    [queryName, "all"],
    async () => {
      try {
        const response = await queryFunc();
        return response.data;
      } catch (err) {
        throw new Error(String(err));
      }
    },
    {
      keepPreviousData: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
  const options = data?.results || [];

  const {
    register,
    control: dialogControl,
    errors,
    handleSubmit,
    setError,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const handleClose = () => {
    toggleOpen(false);
  };

  return (
    <Controller
      render={(props: {
        onChange: (newData: ItemTypes) => void;
        value: ItemTypes;
      }) => {
        const handleFullClose = () => {
          handleClose();
          props.onChange(initialValues);
        };

        const parseValue = () => {
          const { value } = props;
          if (typeof value?.inputValue === "string") {
            return { ...value, name: value?.inputValue };
          }
          return value;
        };

        const handleOnChange = (
          _: React.SyntheticEvent<Element, Event>,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          newData: string | any | null
        ) => {
          if (typeof newData === "string") {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              toggleOpen(true);
              setDialogValue({
                name: newData,
              });
            });
          } else if (newData && newData.inputValue !== undefined) {
            toggleOpen(true);
            const value = newData.inputValue || "";
            setDialogValue({
              name: value,
            });
            props.onChange(initialValues);
          } else {
            props.onChange(newData);
          }
        };

        const handleKeyDown = (event: React.KeyboardEvent) => {
          switch (event.key) {
            case "Tab": {
              if (!props.value?.id) {
                event.preventDefault();
                handleOnChange(event, inputValue);
              }
              break;
            }
            default:
          }
        };

        const handleInputChange = (
          _: React.SyntheticEvent<Element, Event>,
          newInputValue: string
        ) => {
          setInputValue(newInputValue);
        };

        const parsedValue = parseValue();

        const dialog = (
          <DialogComponent
            open={open}
            control={dialogControl}
            dialogValue={dialogValue}
            setDialogValue={setDialogValue}
            handleSubmit={handleSubmit}
            handleChange={handleOnChange}
            handleClose={handleFullClose}
            register={register}
            errors={errors}
            setError={setError}
            disableRedirectOnClose
          />
        );

        if (!disableCreateButton && options.length === 0) {
          if (isLoading) {
            return <Skeleton width={100} height={50} />;
          }

          return (
            <>
              <Button
                size="small"
                color="primary"
                variant="contained"
                startIcon={<Add />}
                onClick={() => toggleOpen(true)}
              >
                Create {name}
              </Button>
              {dialog}
            </>
          );
        }

        return (
          <>
            <Autocomplete
              {...props}
              value={parsedValue}
              autoHighlight
              disableClearable={!parsedValue?.id}
              disabled={isLoading}
              loading={isLoading}
              onChange={handleOnChange}
              onInputChange={handleInputChange}
              ListboxProps={{
                className: options.length > 0 ? classes.listbox : undefined,
              }}
              filterOptions={(_filteredOptions, params) => {
                const filtered = _filteredOptions
                  ? filter(_filteredOptions, params)
                  : [];

                const initialName =
                  params.inputValue === ""
                    ? `+ Create a new ${name}`
                    : `+ Create ${name}  ${params.inputValue}`;

                filtered.push({
                  inputValue: params.inputValue,
                  name: initialName,
                  id: 0,
                });

                return filtered;
              }}
              getOptionLabel={(option) => {
                let o = option.name;
                // e.g value selected with enter, right from the input
                if (typeof option === "string") {
                  o = option;
                }
                if (isNumber(option)) {
                  o = option.toString();
                }
                if (option.inputValue) {
                  o = `+ Create ${name} ${option.inputValue}`;
                }
                return o;
              }}
              isOptionEqualToValue={(option, value) => {
                return option.name === value.name;
              }}
              options={options}
              renderOption={(optionProps, option) => (
                <AutocompleteOption
                  key={option.id}
                  props={optionProps}
                  option={option}
                />
              )}
              renderInput={(params: AutocompleteRenderInputParams) => {
                const newParams = params;
                newParams.inputProps.onKeyDown = handleKeyDown;
                return (
                  <TextField
                    {...newParams}
                    fullWidth
                    disabled={false}
                    label={capitalize(name)}
                    variant="outlined"
                    error={!!error}
                    helperText={error?.message}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: isLoading ? (
                        <InputAdornment position="start">
                          <CircularProgress size={20} color="secondary" />
                        </InputAdornment>
                      ) : (
                        params.InputProps.endAdornment
                      ),
                    }}
                  />
                );
              }}
            />
            {dialog}
          </>
        );
      }}
      id={name}
      name={name}
      control={control}
    />
  );
};

AutocompleteCreateField.defaultProps = {
  disableCreateButton: false,
};

export default AutocompleteCreateField;
