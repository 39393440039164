import ConfigConstant from "core/constants/ConfigConstant";
import RouterConstants from "core/routes/constants";
import {
  errorHandler,
  handleCaptureMessage,
  handleInvalidLogout,
  IErrorResponse,
} from "core/utils/errorHandler";
import { snackbarHandler } from "core/utils/snackbarHandler";
import { AccountContext } from "modules/Account/context";
import { AccountQuery, IAccount } from "modules/Account/models";
import { AccountActionType } from "modules/Account/reducers";
import AccountService from "modules/Account/services";
import { getAccountName, handleAccountDeletion } from "modules/Account/utils";
import { UserContext } from "modules/User/context";
import { UserActionType } from "modules/User/reducers";
import UserService from "modules/User/services";
import { useContext } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import PageAlert from "ui-kit/components/PageAlert";
import TextButtonBox from "ui-kit/components/TextButtonBox";

const AccountRemoved = (): React.ReactElement | null => {
  const history = useHistory();

  const { dispatch: dispatchAccount, account } = useContext(AccountContext);
  const { dispatch: dispatchUser } = useContext(UserContext);

  const queryClient = useQueryClient();

  const accountName = getAccountName(account);

  const { data } = useQuery(
    [AccountQuery.all],
    async () => {
      try {
        const response = await AccountService.fetchAllAccounts();
        return response.data;
      } catch (err) {
        throw new Error(String(err));
      }
    },
    {
      keepPreviousData: true,
    }
  );
  const accounts: IAccount[] = data?.results || [];

  const handleClear = () => {
    // Clear localStorage
    handleAccountDeletion(dispatchAccount);

    queryClient.removeQueries();
  };

  const handleLogout = () => {
    handleClear();

    dispatchUser({ type: UserActionType.LOGOUT });
    dispatchAccount({ type: AccountActionType.LOGOUT });

    snackbarHandler.success("Logout successful.");
    history.push(RouterConstants.ROOT);
  };

  const mutateLogout = useMutation(() => UserService.createLogout(), {
    onSuccess: () => {
      handleLogout();
    },
    onError: (error: IErrorResponse) => {
      const result = handleInvalidLogout(error);

      if (!result) {
        errorHandler(error.response);
      }

      // Mock successful logout
      handleLogout();
    },
    onMutate: () => {
      handleCaptureMessage("Logout triggered.");
    },
  });

  const handleSignOut = () => mutateLogout.mutate();

  const handleNewAccount = () => {
    history.push(RouterConstants.ACCOUNT.NEW_ROOT);
  };

  const handleSwitch = () => {
    handleClear();
    history.push(RouterConstants.ACCOUNT.SELECT);
  };

  // TODO: Add handler to verify if the account was really removed and navigate

  return (
    <>
      <PageAlert
        title={
          <>
            The account <b>{accountName}</b> has been deleted. 🚫
          </>
        }
        body={
          <>
            You have the following options:
            <ul>
              {!!accounts.length && (
                <li>
                  <TextButtonBox
                    buttonProps={{
                      onClick: handleSwitch,
                      children: "Switch",
                    }}
                    endText=" to a different LinkedIn account"
                  />
                </li>
              )}
              <li>
                <TextButtonBox
                  buttonProps={{
                    onClick: handleNewAccount,
                    children: "Add new",
                  }}
                  endText="LinkedIn account"
                />
              </li>
              <li>
                <TextButtonBox
                  endText={`of your current ${ConfigConstant.APP_NAME} user`}
                  buttonProps={{ onClick: handleSignOut, children: "Logout" }}
                />
              </li>
            </ul>
          </>
        }
      />
    </>
  );
};

export default AccountRemoved;
