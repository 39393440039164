import AddIcon from "@mui/icons-material/Add";
import { Box, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { BacklinkButtonVariant, IBacklinkProps } from "core/models";
import React from "react";
import Button from "ui-kit/atoms/Button";
import BacklinkButton from "./BacklinkButton";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(12),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  mainIcon: {
    fontSize: 56,
    marginBottom: theme.spacing(2),
    color: theme.app.palette.action.check,
  },
  btnIcon: {
    fontSize: 52,
    marginBottom: theme.spacing(5),
    color: theme.palette.secondary.main,
  },
  btn: {
    flexDirection: "column",
    padding: theme.spacing(7, 4, 5),
    maxWidth: 190,
    borderRadius: 5,
  },
  btnGroup: {
    marginTop: theme.spacing(5),
    display: "flex",
    alignItems: "center",
    gap: 24,
  },
  btnSubtitle: {
    whiteSpace: "normal",
    color: theme.app.palette.action.icon,
  },
  backlink: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    color: theme.app.palette.action.color,
    width: "fit-content",
    border: 0,
    cursor: "pointer",
    padding: 0,
    margin: 0,
    "&:hover": {
      backgroundColor: "inherit",
      color: theme.app.palette.text.secondary,
    },
  },
}));

interface ButtonGroupItem {
  title: string;
  subtitle: string;
  icon: React.FunctionComponent<any>;
  onClick: () => void;
}

interface BlankStepViewProps {
  icon?: React.FunctionComponent<any>;
  title: string;
  subtitle?: string;
  footer?: string | React.ReactElement;
  BacklinkProps?: IBacklinkProps;
  ButtonProps?: {
    text: string;
    onClick: () => void;
  };
  customBtn?: React.ReactElement;
  ButtonGroupProps?: ButtonGroupItem[];
}

const BlankStepView = ({
  icon: Icon,
  title,
  subtitle,
  footer,
  BacklinkProps,
  ButtonProps,
  customBtn,
  ButtonGroupProps,
}: BlankStepViewProps): React.ReactElement => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {!!Icon && <Icon className={classes.mainIcon} />}
      {!!BacklinkProps && (
        <BacklinkButton
          {...BacklinkProps}
          variant={BacklinkButtonVariant.back}
        />
      )}
      <Typography variant="h5" gutterBottom>
        {title}
      </Typography>
      {!!subtitle && (
        <Typography variant="body2" color="textSecondary" mb={6}>
          {subtitle}
        </Typography>
      )}
      {!!ButtonProps && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 2,
          }}
        >
          <Button
            color="primary"
            variant="contained"
            startIcon={<AddIcon />}
            onClick={ButtonProps.onClick}
          >
            {ButtonProps.text}
          </Button>
          {customBtn}
        </Box>
      )}
      {!!ButtonGroupProps && (
        <div className={classes.btnGroup}>
          {ButtonGroupProps.map(
            ({
              icon: ButtonIcon,
              title: buttonTitle,
              subtitle: buttonSubtitle,
              onClick,
            }) => (
              <Button
                key={buttonTitle}
                color="inherit"
                className={classes.btn}
                onClick={onClick}
              >
                <ButtonIcon className={classes.btnIcon} />
                <Typography variant="subtitle1" gutterBottom>
                  {buttonTitle}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  gutterBottom
                  className={classes.btnSubtitle}
                >
                  {buttonSubtitle}
                </Typography>
              </Button>
            )
          )}
        </div>
      )}
      {!!footer && footer}
    </div>
  );
};

export default BlankStepView;
