import RouterConstants from "core/routes/constants";
import { formatDateToFull } from "core/utils/dateHandler";
import { errorHandler, IErrorResponse } from "core/utils/errorHandler";
import { AccountContext } from "modules/Account/context";
import useUpdateOnboarding from "modules/Account/hooks/useUpdateOnboarding";
import { IAccountOnboardingIds } from "modules/Account/models";
import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import {
  CampaignLocale,
  CampaignQuery,
  ExecutionKeys,
  ICampaign,
} from "../models";
import CampaignService from "../services";

const useCreateCampaign = () => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const { mutate: updateOnboarding } = useUpdateOnboarding();

  const { account } = React.useContext(AccountContext);

  const { id: accountId } = account;

  const mutateCampaign = useMutation(
    () =>
      CampaignService.createCampaign({
        name: `New campaign - ${formatDateToFull(new Date())}`,
        locale: CampaignLocale.en,
        // Start campaign as draft
        execution: ExecutionKeys.DR,
        account: accountId,
      }),
    {
      onSuccess: (response: { data: ICampaign }) => {
        // Add campaign could affect onboarding
        queryClient.invalidateQueries([
          IAccountOnboardingIds.campaign_created,
          accountId,
        ]);

        queryClient.invalidateQueries(CampaignQuery.campaigns);

        history.push(RouterConstants.CAMPAIGN.new(response.data.id));

        // Update campaign created
        if (!account.meta_data?.onboarding?.campaign_created) {
          updateOnboarding({
            variant: IAccountOnboardingIds.campaign_created,
            value: true,
          });
        }
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);
      },
    }
  );

  return mutateCampaign;
};

export default useCreateCampaign;
