import * as Yup from "yup";

export const ERROR_KEYS = {
  NAME: {
    REQUIRED: "Campaign name is required.",
    INVALID: "Campaign name needs to be at least 3 characters long.",
  },
  URL: {
    REQUIRED: "Search URL is required.",
  },
  CAMPAIGN_SEQUENCE: {
    REQUIRED: "Selecting a sequence is required.",
  },
  CAMPAIGN: {
    REQUIRED: "Selecting a campaign is required.",
  },
  PEOPLE: {
    REQUIRED: "Selecting at least one person is required.",
  },
  SEARCH: {
    REQUIRED: "Selecting a search is required.",
  },
  LIST: {
    REQUIRED: "Selecting a list of people is required.",
  },
};

const sequence = Yup.object()
  .shape({
    id: Yup.number().required(),
    name: Yup.string().required(ERROR_KEYS.CAMPAIGN_SEQUENCE.REQUIRED),
  })
  .required(ERROR_KEYS.CAMPAIGN_SEQUENCE.REQUIRED)
  .nullable();

const createCampaignSequenceSchema: Yup.AnyObjectSchema = Yup.object().shape({
  sequence,
});

const createCampaignListSchema: Yup.AnyObjectSchema = Yup.object().shape({
  address_book: Yup.string().required(ERROR_KEYS.LIST.REQUIRED),
});

const createCampaignSchema: Yup.AnyObjectSchema = Yup.object().shape({
  name: Yup.string()
    .required(ERROR_KEYS.NAME.REQUIRED)
    .min(3, ERROR_KEYS.NAME.INVALID),
});

const createCampaignPeopleSchema: Yup.AnyObjectSchema = Yup.object().shape({
  campaign: Yup.object()
    .shape({
      id: Yup.number().required(ERROR_KEYS.CAMPAIGN.REQUIRED),
      label: Yup.string().required(ERROR_KEYS.CAMPAIGN.REQUIRED),
    })
    .required(ERROR_KEYS.CAMPAIGN.REQUIRED)
    .nullable(),
});

const createCampaignSearchSchema: Yup.AnyObjectSchema = Yup.object().shape({
  search: Yup.object()
    .shape({
      id: Yup.number().required(ERROR_KEYS.SEARCH.REQUIRED),
      label: Yup.string().required(ERROR_KEYS.SEARCH.REQUIRED),
    })
    .required(ERROR_KEYS.SEARCH.REQUIRED)
    .nullable(),
});
const createCampaignAudienceSchema: Yup.AnyObjectSchema = Yup.object().shape({
  search: Yup.object()
    .shape({
      id: Yup.number().required(ERROR_KEYS.SEARCH.REQUIRED),
      label: Yup.string().required(ERROR_KEYS.SEARCH.REQUIRED),
    })
    .required(ERROR_KEYS.SEARCH.REQUIRED)
    .nullable(),
});

const CampaignValidations = {
  createCampaignSchema,
  createCampaignListSchema,
  createCampaignPeopleSchema,
  createCampaignSequenceSchema,
  createCampaignAudienceSchema,
  createCampaignSearchSchema,
};

export default CampaignValidations;
