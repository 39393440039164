import Badge from "@mui/material/Badge";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import { Theme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import ConfigConstant from "core/constants/ConfigConstant";
import StrategyConstant, {
  MATCH_CATEGORY_TO_VARIANT_OBJ,
} from "core/constants/StrategyConstant";
import RouterConstants from "core/routes/constants";
import RouterUtils from "core/routes/utils";
import { formatDateTimeToFull } from "core/utils/dateHandler";
import { errorHandler, IErrorResponse } from "core/utils/errorHandler";
import { getProfileName } from "core/utils/profileHandler";
import { IInteraction } from "modules/Interaction/models";
import InteractionService from "modules/Interaction/services";
import NotificationService from "modules/Notification/services";
import PersonAvatar from "modules/Person/components/PersonAvatar";
import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { useHistory, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
  item: {
    flexDirection: "column",
    alignItems: "flex-start",
    padding: theme.spacing(3.5, 3),
    borderBottom: `1px solid ${theme.app.palette.shadow.primary}`,
    "&:hover": {
      backgroundColor: theme.app.palette.action.hoverBackground,
      borderBottom: `1px solid ${theme.app.palette.shadow.primary}`,
    },
    "&.Mui-selected": {
      backgroundColor: theme.app.palette.action.selectedBackground,
      paddingLeft: theme.spacing(2.5),
      // borderLeft: `2px solid ${theme.palette.primary.main}`,
      borderBottom: `1px solid ${theme.app.palette.shadow.secondary}`,
      "&:hover": {
        borderBottom: `1px solid ${theme.app.palette.shadow.secondary}`,
        backgroundColor: theme.app.palette.background.default,
      },
    },
  },
  header: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: theme.spacing(3),
  },
  headerBox: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: theme.spacing(0.5),
  },
  itemText: {
    margin: 0,
  },
  seenItem: {
    "& .MuiListItemText-secondary": {
      color: theme.app.palette.action.color,
    },
  },
  newItem: {
    "& .MuiListItemText-secondary": {
      color: theme.palette.text.primary,
    },
  },
  avatar: {
    minWidth: 38,
  },
  name: {
    color: theme.palette.text.primary,
    fontSize: "0.825rem",
    fontWeight: 500,
    lineHeight: 1.2,
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 145,
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "75%",
    },
  },
  selectedName: {
    // color: theme.palette.primary.main,
  },
  subText: {
    lineHeight: 1.2,
    color: theme.app.palette.action.color,
    fontSize: "0.65rem",
    [theme.breakpoints.up("md")]: {
      maxWidth: "70%",
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "75%",
    },
    "& span": {
      fontWeight: 500,
      color: theme.app.palette.action.main,
    },
  },
  selectedSubText: {
    // color: theme.app.palette.action.main,
  },
  text: {
    color: theme.app.palette.action.main,
    fontSize: "0.685rem",
    lineHeight: 1.2,
    paddingLeft: theme.spacing(0.5),
    [theme.breakpoints.up("md")]: {
      width: theme.app.constants.inbox.contactWidth - 30,
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "75%",
    },
  },
  ellipsis: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  date: {
    textAlign: "right",
    color: theme.app.palette.action.color,
    fontSize: "0.65rem",
    lineHeight: 1,
  },
  context: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    position: "relative",
  },
  listItem: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
  },
  reply: {
    color: theme.palette.secondary.main,
  },
  dot: {
    position: "absolute",
    right: 18,
    top: "calc(50% - 8px)",
  },
}));

const getInteractionContent = (i: IInteraction) => {
  const category = MATCH_CATEGORY_TO_VARIANT_OBJ[i.category];
  if (!category) return null;

  const {
    label: { account_is_actor: label },
  } = StrategyConstant.STRATEGY_OBJ[category];
  const content = i.data?.message || label;
  return (
    <>
      {i.account_is_actor ? <span>You: </span> : null}
      {content}
    </>
  );
};

interface InteractionPeopleListItemProps {
  personId: number | null;
  interaction: IInteraction;
}

const InteractionPeopleListItem = ({
  personId,
  interaction,
}: InteractionPeopleListItemProps): React.ReactElement => {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const accountQuery = RouterUtils.generateFilterNameQuery(
    interaction.account.toString(),
    "actor_object_id"
  );

  const history = useHistory();
  const location = useLocation();

  // Mark notification as read (unread: false)
  const mutateNotificationAsRead = useMutation(
    () =>
      NotificationService.updateNotificationsMarkAsRead(
        accountQuery,
        ConfigConstant.INTERACTION_CONTENT_TYPE_ID,
        interaction.id.toString()
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["notifications-recent"]);
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);
      },
      onSettled: () => {
        queryClient.invalidateQueries(["interactions-distinct"]);
        queryClient.invalidateQueries(["sidebar-count", "Inbox"]);
      },
    }
  );

  const markInteractionAsSeen = useMutation(
    () => InteractionService.markInteractionAsSeen(interaction.contact?.id),
    {
      onSuccess: () => {
        // Notification - also mark the notification as read
        mutateNotificationAsRead.mutate();
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);
      },
      onSettled: () => {
        queryClient.invalidateQueries(["interactions-distinct"]);
        queryClient.invalidateQueries(["sidebar-count", "Inbox"]);
      },
    }
  );

  const personName = getProfileName(
    interaction.search_result,
    interaction.person
  );
  const isSelected = interaction.person?.id === personId;

  const firstUpdate = React.useRef(true);
  React.useEffect(() => {
    if (isSelected && !interaction.seen) {
      if (!firstUpdate.current) {
        return;
      }

      markInteractionAsSeen.mutate();
      firstUpdate.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelected, interaction.seen]);

  const handleRouteToPerson = (id: number | undefined) => {
    if (!id) {
      return;
    }

    history.push(
      RouterConstants.INBOX.detail(id) + location.search // Preserve query params
    );
  };

  return (
    <ListItem
      button
      selected={isSelected}
      divider
      onClick={() => handleRouteToPerson(interaction.person?.id)}
      key={interaction.id}
      className={classes.item}
    >
      <>
        <div className={classes.header}>
          <ListItemAvatar className={classes.avatar}>
            <PersonAvatar
              src={interaction.person?.image_base64}
              size="small"
              name={personName}
            />
          </ListItemAvatar>
          <ListItemText
            className={clsx(
              classes.itemText,
              interaction.seen ? classes.seenItem : classes.newItem
            )}
            primary={
              <div className={classes.headerBox}>
                <div
                  className={clsx(classes.name, classes.ellipsis, {
                    [classes.selectedName]: !!isSelected,
                  })}
                >
                  {personName}
                </div>
                <Typography className={classes.date}>
                  {formatDateTimeToFull(interaction.platform_created_at)}
                </Typography>
              </div>
            }
            secondary={interaction.person?.default_placeholders.company_name}
            secondaryTypographyProps={{
              variant: "caption",
              className: clsx(classes.subText, classes.ellipsis, {
                [classes.selectedSubText]: !!isSelected,
              }),
            }}
          />
        </div>
        <Typography
          className={clsx(classes.text, classes.ellipsis, {
            [classes.reply]: !interaction.account_is_actor && !interaction.seen,
          })}
          variant="caption"
        >
          {getInteractionContent(interaction)}
        </Typography>
      </>
      <div className={classes.dot}>
        {!interaction.seen &&
          !interaction.failed &&
          !interaction.account_is_actor && (
            <Tooltip
              arrow
              placement="bottom-end"
              title={
                interaction.account_is_actor
                  ? "Unread interaction"
                  : "Unread reply"
              }
            >
              <Badge color="secondary" variant="dot" />
            </Tooltip>
          )}
      </div>
    </ListItem>
  );
};

export default InteractionPeopleListItem;
