import { IDraftAction } from "../models";

export enum DraftActionActionType {
  SET_DRAFT_ACTION,
}

export type DraftActionAction = {
  type: DraftActionActionType.SET_DRAFT_ACTION;
  payload: IDraftAction;
};

export const actionReducer = (
  state: IDraftAction,
  action: DraftActionAction
): IDraftAction => {
  switch (action.type) {
    case DraftActionActionType.SET_DRAFT_ACTION: {
      const newState = action.payload;
      return { ...state, ...newState };
    }
    default:
      return state;
  }
};
