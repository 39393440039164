import React from "react";
import { useQueryClient, useMutation } from "react-query";
import { errorHandler, IErrorResponse } from "core/utils/errorHandler";
import { snackbarHandler } from "core/utils/snackbarHandler";
import {
  IAddressBookFormValues,
  IAddressBookItemFormInput,
  IAddressBookItemsFormValues,
} from "modules/AddressBook/models";
import AddressBookService from "modules/AddressBook/services";
import NewAddressBookItemsDialog from "modules/AddressBook/components/NewAddressBookItemsDialog";
import { AccountContext } from "modules/Account/context";

interface CreateAccountAddressBookProps {
  open: boolean;
  handleClose: () => void;
}

const CreateAccountAddressBook = ({
  open,
  handleClose,
}: CreateAccountAddressBookProps): React.ReactElement => {
  const queryClient = useQueryClient();
  const {
    account: { id: accountId },
  } = React.useContext(AccountContext);

  const mutateBulkAddressBookItems = useMutation(
    (data: IAddressBookItemsFormValues) =>
      AddressBookService.createAddressBookItems(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["addressBook-items"]);
        snackbarHandler.success("Successfully added!");
        handleClose();
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);
      },
    }
  );

  const mutateAddressBook = useMutation(
    (data: IAddressBookFormValues) =>
      AddressBookService.createAddressBook(data),
    {
      onSuccess: (response, data) => {
        mutateBulkAddressBookItems.mutate({
          persons: data.people.map((person) => person.id),
          address_book: response.data.id,
        });
        queryClient.invalidateQueries(["account-addressBooks"]);
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);
      },
    }
  );

  const onSubmit = ({ people }: IAddressBookItemFormInput) => {
    const newData = {
      people,
      account: accountId,
      blacklist: true,
      name: "Blacklist",
    };
    mutateAddressBook.mutate(newData);
  };

  return (
    <NewAddressBookItemsDialog
      open={open}
      handleClose={handleClose}
      handleOnSubmit={onSubmit}
    />
  );
};

export default CreateAccountAddressBook;
