import React from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import TextField from "ui-kit/atoms/TextField";
import AuthForm from "modules/User/components/AuthForm";
import UserService from "modules/User/services";
import { IEmailFormInput } from "modules/User/models";
import RouterConstants from "core/routes/constants";
import { errorHandler } from "core/utils/errorHandler";
import UserValidation from "modules/User/validations";
import Button from "ui-kit/atoms/Button";

const ForgotPassword = (): React.ReactElement => {
  const schema = UserValidation.forgotPasswordSchema();
  const [requested, setRequested] = React.useState<boolean | undefined>(
    undefined
  );

  const { register, errors, handleSubmit, setError } = useForm<IEmailFormInput>(
    {
      resolver: yupResolver(schema),
    }
  );

  const onSubmit = (credentials: IEmailFormInput) => {
    UserService.forgotPassword(credentials)
      .then((response) => {
        setRequested(true);
      })
      .catch((error) => {
        errorHandler(error.response, setError);
      });
  };

  return requested ? (
    <AuthForm disableForm title="">
      <Card sx={{ my: -8 }}>
        <CardContent>
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="h6" paragraph>
              We sent reset password instructions to your email.
            </Typography>
            <Typography variant="body2" component="p">
              Password reset was successfully requested.
            </Typography>
          </Box>
          <Box sx={{ mt: 8, textAlign: "center" }}>
            <Button
              color="primary"
              variant="contained"
              component={Link}
              to={RouterConstants.USER.SIGN_IN}
            >
              Go to Sign In
            </Button>
          </Box>
        </CardContent>
      </Card>
    </AuthForm>
  ) : (
    <AuthForm
      title="Forgot password? It happens."
      subtitle="Get reset instructions."
      onHandleSubmit={handleSubmit(onSubmit)}
      submitBtn="Send reset instructions"
      helper={
        <>
          Remember password?{" "}
          <Link to={RouterConstants.USER.SIGN_IN}>Sign In.</Link>
        </>
      }
      action={
        <>
          Not an user?{" "}
          <Link to={RouterConstants.USER.SIGN_UP}>Create an account.</Link>
        </>
      }
    >
      <TextField
        fullWidth
        name="email"
        inputRef={register}
        label="Email address"
        variant="outlined"
        error={!!errors.email}
        helperText={errors.email?.message}
        InputProps={{
          style: {
            backgroundColor: "white",
          },
        }}
      />
    </AuthForm>
  );
};

export default ForgotPassword;
