import ExpandMore from "@mui/icons-material/ExpandMore";
import { Box, Popover, Theme } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import clsx from "clsx";
import {
  dayjs,
  formatDayjsToStr,
  formatRangeDate,
  getCurrentTimezoneDate,
} from "core/utils/dateHandler";
import { ReportContext } from "modules/Report/context";
import { defaultStaticRanges } from "modules/Report/defaultRanges";
import { IRange } from "modules/Report/models";
import { ReportActionType } from "modules/Report/reducers";
import React, { useContext, useState } from "react";
import { DateRangePicker, Range, RangeKeyDict } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import Button from "ui-kit/atoms/Button";

const useStyles = makeStyles((theme: Theme) => ({
  dateRangePicker: {
    flexDirection: "row-reverse",
    fontFamily: theme.typography.fontFamily,
    "& .rdrStaticRangeLabel": {
      fontFamily: theme.typography.fontFamily,
    },
    "& .rdrInputRanges": {
      display: "none",
    },
    "& .rdrMonthName": {
      display: "none",
    },
    "& .rdrMonthAndYearWrapper": {
      height: 40,
      paddingTop: 0,
    },
    "& .rdrDayToday.rdrDayDisabled .rdrDayNumber span:after": {
      display: "none",
    },
    "& .rdrDateRangeWrapper": {
      transition: theme.transitions.create("all", {
        duration: theme.transitions.duration.short,
      }),
    },
    "& .rdrDefinedRangesWrapper": {
      width: theme.app.constants.dateRangePicker.button,
      borderLeft: `1px solid ${theme.app.palette.shadow.secondary}`,
      borderRight: 0,
    },
  },
  custom: {
    "& .rdrDateRangeWrapper": {
      display: "none",
    },
  },
}));

const SelectDateRange = (): React.ReactElement => {
  const theme = useTheme();
  const classes = useStyles();
  const {
    dispatch,
    report: { currentRange },
  } = useContext(ReportContext);

  const [currentTimeZoneDate] = useState(getCurrentTimezoneDate());
  const [customRange, setCustomRange] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUpdateRange = ({ startDate, endDate, main }: IRange) => {
    if (startDate === null && endDate === null) {
      setCustomRange(!customRange);
      return;
    }

    // If end date null to enable auto-close, set the end-date to start-date
    if (endDate === null) {
      endDate = startDate;
    }

    const start = formatDayjsToStr(dayjs(startDate));
    const end = formatDayjsToStr(dayjs(endDate));

    dispatch({
      type: ReportActionType.SET_REPORT,
      payload: {
        currentRange: {
          start,
          end,
        },
        defaultRange: main,
      },
    });
  };

  // Auto-close on selection
  // Edge-case select a single date - it won't auto-close
  const handleOnSelect = (range: Range) => {
    // Custom range
    if (range.startDate === null && range.endDate === null) {
      return;
    }

    if (!dayjs(range.startDate).isSame(range.endDate, "day")) {
      handleClose();
    }
  };

  return (
    <>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleClose}
        PaperProps={{
          sx: {
            marginTop: 1,
          },
        }}
      >
        <DateRangePicker
          ranges={[
            {
              startDate: dayjs(currentRange.start).toDate(),
              endDate: dayjs(currentRange.end).toDate(),
              key: "selection",
            },
          ]}
          weekStartsOn={1}
          onChange={(item: RangeKeyDict) => {
            const range = item.selection;

            // Update range in global context
            handleUpdateRange(range as IRange);

            // Trigger auto-close on select
            handleOnSelect(range);
          }}
          showPreview
          rangeColors={[theme.palette.secondary.dark]}
          editableDateInputs={false}
          moveRangeOnFirstSelection={false}
          months={1}
          direction="horizontal"
          maxDate={dayjs(formatDayjsToStr(currentTimeZoneDate)).toDate()}
          minDate={dayjs(currentTimeZoneDate).subtract(2, "year").toDate()}
          showDateDisplay={false}
          inputRanges={[]}
          staticRanges={defaultStaticRanges}
          // onShownDateChange={
          //   // Check if need to disable next month button
          //   (startDate: Date) => handleDisableNext(startDate)
          // }
          className={clsx(classes.dateRangePicker, {
            [classes.custom]: !customRange,
          })}
        />
      </Popover>
      <Button
        endIcon={
          <ExpandMore
            sx={{
              color: theme.app.palette.action.icon,
              fontSize: `22px !important`,
            }}
          />
        }
        color="inherit"
        onClick={handleClick}
        variant="outlined"
        size="medium"
        sx={{
          width: theme.app.constants.dateRangePicker.button,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginLeft: "none !important",
          marginBottom: 1,
          cursor: "pointer",
          ...(Boolean(anchorEl) && {
            borderColor: "#0366d6 !important",
          }),
          ...(Boolean(anchorEl) && {
            boxShadow: "0px 0px 0px 3px rgba(3, 102, 214, 0.3) !important",
          }),
          "&:active": {
            borderColor: "#0366d6",
            boxShadow: "0px 0px 0px 3px rgba(3, 102, 214, 0.3)",
          },
        }}
      >
        <Box
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {formatRangeDate(currentRange)}
        </Box>
      </Button>
    </>
  );
};

export default SelectDateRange;
