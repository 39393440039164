import React, { useContext } from "react";
import MuiBadge, { BadgeProps, badgeClasses } from "@mui/material/Badge";
import { styled, useTheme } from "@mui/system";
import { AccountContext } from "modules/Account/context";
import { AccountQuery, IAccount } from "modules/Account/models";
import AccountService from "modules/Account/services";
import { UserContext } from "modules/User/context";
import { useQuery } from "react-query";
import { getAccountStatusBadge } from "modules/Account/utils";
import { Tooltip } from "@mui/material";

interface StatusBadgeProps {
  children: React.ReactElement;
  account: IAccount | undefined;
}

const StatusBadge = styled(({ children, account }: StatusBadgeProps) => {
  const { user } = React.useContext(UserContext);
  const theme = useTheme();

  const {
    account: { id: accountId },
  } = useContext(AccountContext);

  const fetchIsWorkHour = async () => {
    try {
      const { data } = await AccountService.fetchAccountIsOnline(accountId);
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };

  const { data } = useQuery(
    [AccountQuery.is_online, accountId],
    () => fetchIsWorkHour(),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: !!accountId && !user.anonymous,
    }
  );

  if (!data || !account) {
    return children;
  }

  const { color: statusColor, tooltip } = getAccountStatusBadge(account, data);
  const color = statusColor ? theme.palette[statusColor].main : "none";

  return (
    <Tooltip title={tooltip} placement="bottom">
      <MuiBadge
        overlap="circular"
        variant="dot"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        sx={{
          [`& .${badgeClasses.badge}`]: {
            color: color,
            backgroundColor: color,
            boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
            "&::after": {
              position: "absolute",
              top: -1,
              left: -1,
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              animation: "ripple 1.2s infinite ease-in-out",
              border: "1px solid currentColor",
              content: '""',
            },
          },
          "@keyframes ripple": {
            "0%": {
              transform: "scale(.9)",
              opacity: 1,
            },
            "100%": {
              transform: "scale(1.5)",
              opacity: 0,
            },
          },
        }}
      >
        {children}
      </MuiBadge>
    </Tooltip>
  );
})<BadgeProps>();

export default StatusBadge;
